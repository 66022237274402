import { Box, Flex, Select, Switch } from '@mantine/core';
import { _t } from '../../../lang';
import WeekControls from '../../inputs/WeekControls';

/**
 * This function renders a set of TimeEntriesFilters controls.
 *
 * @param {{
 *   timeEntriesFilters: {
 *    showWeekends: boolean,
 *    orderBy: string,
 *   },
 *   onChange: (timeEntriesFilters: { showWeekends: boolean, orderBy: string }) => void,
 *   setShift: (value: (prev: number) => number) => void,
 *   onWeekChange: (value: 'prev' | 'next' | 'reset') => void,
 * }} props - The component props.
 *
 * @return {JSX.Element} - The rendered component.
 */
export default function TimeEntriesFilters({ timeEntriesFilters, onChange, onWeekChange }) {
  /**
   * Updates the value of the "orderBy" property in the "timeEntriesFilters" object
   * with the specified value. Then, invokes the "onChange" function with the updated object.
   *
   * @param {*} value - The new value for the "orderBy" property.
   */
  const handleSelectChange = (value) => onChange({ ...timeEntriesFilters, orderBy: value });
  /**
   * Updates the `showWeekends` property of `timeEntriesFilters` based on the value of the switch.
   *
   * @param {Object} checked - The value indicating whether the switch is checked.
   * @param {boolean} checked.target.checked - The boolean value indicating whether the switch is checked.
   *
   * @returns {void}
   */
  const handleSwitchChange = (checked) => onChange({ ...timeEntriesFilters, showWeekends: checked?.target?.checked });

  return (
    <Flex justify="space-between">
      <Flex px={16} py={4} align="center" gap={16}>
        <Box style={{ fontSize: '15px', lineHeight: '16px' }}>{_t('Order by')}</Box>
        <Select
          data={[
            {
              value: 'date',
              label: _t('Date'),
            },
          ]}
          value={timeEntriesFilters.orderBy}
          defaultValue={'date'}
          onChange={handleSelectChange}
        />
        <Switch
          className="[&_label]:text-grey-500"
          label={_t('Show Weekends')}
          labelPosition="left"
          checked={timeEntriesFilters.showWeekends}
          onChange={handleSwitchChange}
        />
      </Flex>

      <WeekControls
        stroke="#4D4D4D"
        onCalendarClick={() => () => {
          onWeekChange('reset');
        }}
        onClick={(value) => () => onWeekChange(value === -1 ? 'prev' : 'next')}
      />
    </Flex>
  );
}
