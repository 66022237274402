/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['TypographyStylesProvider']} TypographyStylesProviderTheme */

/** @type {TypographyStylesProviderTheme} */
export const TypographyStylesProvider = {
  styles: () => ({
    root: {
      fontSize: '15px',
      lineHeight: '18px',
      ul: {
        listStyleType: 'disc',
      },
      ol: {
        listStyleType: 'decimal',
      },
      p: {
        marginBottom: '12px',
      },
      'p:last-of-type': {
        marginBottom: 0,
      },
    },
  }),
};
