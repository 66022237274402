/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./role-create-action-body').RoleCreateActionBody} RoleCreateActionBody
 */

/**
 * @typedef {import('./role-create-action-response').RoleCreateActionResponse} RoleCreateActionResponse
 */

/**
 * The path of the RoleCreateAction action.
 */
export const roleCreateActionPath = new SweetPath('/api/user/role/', { path: '{param}' });

/**
 * Creates a contextualized RoleCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createRoleCreateAction(axios) {
  /**
   * The roleCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: RoleCreateActionBody } }
   *
   * @returns {Promise<RoleCreateActionResponse>}
   */
  function roleCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = roleCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return roleCreateAction;
}
