/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./price-list-update-action-body').PriceListUpdateActionBody} PriceListUpdateActionBody
 */

/**
 * @typedef {import('./price-list-update-action-response').PriceListUpdateActionResponse} PriceListUpdateActionResponse
 */

/**
 * The path of the PriceListUpdateAction action.
 */
export const priceListUpdateActionPath = new SweetPath('/api/finance/price_list/{price_list_id}', { path: '{param}' });

/**
 * Creates a contextualized PriceListUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPriceListUpdateAction(axios) {
  /**
   * The priceListUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PriceListUpdateActionBody } }
   *
   * @returns {Promise<PriceListUpdateActionResponse>}
   */
  function priceListUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = priceListUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return priceListUpdateAction;
}
