/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./role-update-action-body').RoleUpdateActionBody} RoleUpdateActionBody
 */

/**
 * @typedef {import('./role-update-action-response').RoleUpdateActionResponse} RoleUpdateActionResponse
 */

/**
 * The path of the RoleUpdateAction action.
 */
export const roleUpdateActionPath = new SweetPath('/api/user/role/{role_id}', { path: '{param}' });

/**
 * Creates a contextualized RoleUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createRoleUpdateAction(axios) {
  /**
   * The roleUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: RoleUpdateActionBody } }
   *
   * @returns {Promise<RoleUpdateActionResponse>}
   */
  function roleUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = roleUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return roleUpdateAction;
}
