/** @typedef {import('components/comments/data/types').ICommentReaction} ICommentReaction */

import { noop } from 'lodash';
import LikeReactionIcon from 'components/comments/reaction/icons/LIkeReactionIcon';
import SadReactionIcon from 'components/comments/reaction/icons/SadReactionIcon';
import LaughReactionIcon from 'components/comments/reaction/icons/LaughReactionIcon';
import HeartReactionIcon from 'components/comments/reaction/icons/HeartReactionIcon';
import DislikeReactionIcon from 'components/comments/reaction/icons/DislikeReactionIcon';
import UsersListTooltip from 'components/UsersListTooltip';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useState } from 'react';
import { nanoid } from 'nanoid';

/**
 * Computes the width of the badge based on the number of reactions.
 *
 * @param {number} count
 */
function computeWidth(count) {
  if (count >= 100) {
    return 'min-w-[22px]';
  }

  if (count >= 10) {
    return 'min-w-[15px]';
  }

  return 'min-w-[8px]';
}

/**
 * The icons for the different reaction types.
 */
const reactionIcons = {
  like: <LikeReactionIcon size={16} />,
  dislike: <DislikeReactionIcon size={16} />,
  heart: <HeartReactionIcon size={16} />,
  laugh: <LaughReactionIcon size={16} />,
  sad: <SadReactionIcon size={16} />,
};

/**
 * Displays a badge with the number of reactions of a specific type for a comment.
 *
 * @param {{
 *   commentId: string;
 *   reaction: ICommentReaction;
 *   onClick?: () => void;
 * }}
 */
export default function CommentReaction({ commentId, reaction, onClick = noop }) {
  const { getAction } = useApi();
  const [uuid] = useState(nanoid);

  /**
   * Fetches the list of users notified by the comment.
   */
  async function fetchUsers() {
    const commentGetReactedPeopleAction = getAction('CommentGetReactedPeopleAction');

    try {
      const users = await commentGetReactedPeopleAction({
        parameters: { comment_id: commentId, reaction_type: reaction.type },
      });

      return users.map((user) => ({ userId: user.user_id, fullName: user.full_name }));
    } catch (error) {
      panic(error);
      return null;
    }
  }

  const icon = reactionIcons[reaction.type];
  const width = computeWidth(reaction.count);

  const colors = reaction.active
    ? 'bg-main-secondary text-neutral-700 border border-complementary-ametyst'
    : 'text-neutral-700 bg-neutral-100 border border-neutral-100';

  return (
    <UsersListTooltip key={uuid + reaction.active} action={fetchUsers}>
      <div
        onClick={onClick}
        className={`flex w-fit cursor-pointer flex-row items-center gap-1 rounded-lg p-[3px] text-xs ${colors}`}
      >
        <span>{icon}</span>
        <span className={width}>
          <span className="select-none">{reaction.count}</span>
        </span>
      </div>
    </UsersListTooltip>
  );
}
