/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "FiletypeDefault" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function FiletypeDefaultIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00003 6C5.00003 4.61929 6.11932 3.5 7.50003 3.5H14.0925C14.8134 3.5 15.4992 3.8112 15.9739 4.35374L18.3815 7.10521C18.7802 7.56094 19 8.14591 19 8.75147V11C19 11.2761 18.7762 11.5 18.5 11.5C18.2239 11.5 18 11.2761 18 11V8.75147C18 8.6667 17.9929 8.5826 17.9788 8.5H15.5C14.6716 8.5 14 7.82843 14 7V4.5H7.50003C6.6716 4.5 6.00003 5.17157 6.00003 6V19C6.00003 19.8284 6.6716 20.5 7.50003 20.5H13.5C13.7762 20.5 14 20.7239 14 21C14 21.2761 13.7762 21.5 13.5 21.5H7.50003C6.11932 21.5 5.00003 20.3807 5.00003 19V6ZM15.2214 5.01224L17.3981 7.5H15.5C15.2239 7.5 15 7.27614 15 7V4.80568C15.0799 4.86639 15.1542 4.93545 15.2214 5.01224Z"
        fill={fill}
      />
      <path
        d="M12 20.5H16.5C17.3284 20.5 18 19.8284 18 19V10C18 9.72386 18.2239 9.5 18.5 9.5C18.7761 9.5 19 9.72386 19 10V19C19 20.3807 17.8807 21.5 16.5 21.5H12V20.5Z"
        fill={fill}
      />
    </svg>
  );
}
