/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./price-list-default-get-action-body').PriceListDefaultGetActionBody} PriceListDefaultGetActionBody
 */

/**
 * @typedef {import('./price-list-default-get-action-response').PriceListDefaultGetActionResponse} PriceListDefaultGetActionResponse
 */

/**
 * The path of the PriceListDefaultGetAction action.
 */
export const priceListDefaultGetActionPath = new SweetPath('/api/finance/default_price_list', { path: '{param}' });

/**
 * Creates a contextualized PriceListDefaultGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPriceListDefaultGetAction(axios) {
  /**
   * The priceListDefaultGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<PriceListDefaultGetActionResponse>}
   */
  function priceListDefaultGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = priceListDefaultGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return priceListDefaultGetAction;
}
