/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-password-set-action-body').UserPasswordSetActionBody} UserPasswordSetActionBody
 */

/**
 * @typedef {import('./user-password-set-action-response').UserPasswordSetActionResponse} UserPasswordSetActionResponse
 */

/**
 * The path of the UserPasswordSetAction action.
 */
export const userPasswordSetActionPath = new SweetPath('/api/user/password/set', { path: '{param}' });

/**
 * Creates a contextualized UserPasswordSetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserPasswordSetAction(axios) {
  /**
   * The userPasswordSetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserPasswordSetActionBody } }
   *
   * @returns {Promise<UserPasswordSetActionResponse>}
   */
  function userPasswordSetAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userPasswordSetActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return userPasswordSetAction;
}
