import { Center, Group, Stack, Text, Tooltip } from '@mantine/core';
import { noop } from 'lodash';
import { TimeInput } from '@mantine/dates';
import { addDays, addMinutes, differenceInDays, differenceInMinutes, subMinutes } from 'date-fns';
import { _t } from 'lang';
import { useMemo, useState } from 'react';
import DatePicker from './DatePicker';
import HoursAndMinutesInput from './HoursAndMinutesInput';
import TimeIcon from 'components/icons/TimeIcon';

/**
 * Lock icon used exclusively in this component.
 */
function LockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M6 7.5V8.5M3 10.5H9C9.55228 10.5 10 10.0523 10 9.5V6.5C10 5.94772 9.55228 5.5 9 5.5H3C2.44772 5.5 2 5.94772 2 6.5V9.5C2 10.0523 2.44772 10.5 3 10.5ZM8 5.5V3.5C8 2.39543 7.10457 1.5 6 1.5C4.89543 1.5 4 2.39543 4 3.5V5.5H8Z"
        stroke="#4D4D4D"
        strokeLinecap="round"
      />
    </svg>
  );
}

/**
 * Display a label with a lock icon.
 *
 * @param {{
 *   label: string;
 *   locked?: boolean;
 * }}
 */
function LabelWithLock({ label, locked = false }) {
  return (
    <Group spacing={4}>
      <Text>{label}</Text>
      {locked && (
        <Tooltip openDelay={300} label={_t('Change in duration will not modify this time')}>
          <Center w={12} h={12} style={{ cursor: 'help' }}>
            <LockIcon />
          </Center>
        </Tooltip>
      )}
    </Group>
  );
}

const defaultStartLabel = (
  <Text c="#808080" fz={12} lh={16 / 12}>
    {_t('Start time')}
  </Text>
);

const defaultEndLabel = (
  <Text c="#808080" fz={12} lh={16 / 12}>
    {_t('End time')}
  </Text>
);

const defaultHoursLabel = (
  <Text c="#4D4D4D" fz={12} lh={16 / 12}>
    {_t('Hours')}
  </Text>
);

const defaultMinutesLabel = (
  <Text c="#4D4D4D" fz={12} lh={16 / 12}>
    {_t('Minutes')}
  </Text>
);

/**
 * Used to input a start and end time in human readable format.
 *
 * @typedef {{
 *   start: Date;
 *   end: Date;
 * }} IValue
 *
 * @param {{
 *   value?: IValue;
 *   onChange?: (value: IValue) => void;
 *   initialLocked?: 'start' | 'end';
 *   startLabel?: string|JSX.Element;
 *   endLabel?: string|JSX.Element;
 *   hoursLabel?: string|JSX.Element;
 *   minutesLabel?: string|JSX.Element;
 *   withDateInput?: boolean;
 *   alwaysShowDates?: boolean;
 * }}
 */
export default function StartEndDurationInput({
  value: { start, end } = { start: new Date(), end: new Date() },
  onChange = noop,
  initialLocked = 'end',
  startLabel = defaultStartLabel,
  endLabel = defaultEndLabel,
  hoursLabel = defaultHoursLabel,
  minutesLabel = defaultMinutesLabel,
  withDateInput = false,
  alwaysShowDates = false,
}) {
  const [locked, setLocked] = useState(initialLocked);
  const duration = useMemo(() => Math.max(0, differenceInMinutes(end, start)), [start, end]);
  const sameDay = useMemo(() => start.toDateString() === end.toDateString(), [start, end]);

  /**
   * Modifies the start time.
   *
   * @param {Date} value
   */
  const modifyStart = (value) => {
    value.setFullYear(start.getFullYear(), start.getMonth(), start.getDate());
    onChange({ start: value, end });
    setLocked('start');
  };

  /**
   * Modifies the end time.
   *
   * @param {Date} value
   */
  const modifyEnd = (value) => {
    value.setFullYear(end.getFullYear(), end.getMonth(), end.getDate());
    onChange({ start, end: value });
    setLocked('end');
  };

  /**
   * Modifies the duration and keeps the locked boundary.
   *
   * @param {number} duration
   */
  const modifyDuration = (duration) => {
    if (locked === 'start') {
      onChange({ start, end: addMinutes(start, duration) });
    } else {
      onChange({ start: subMinutes(end, duration), end });
    }
  };

  /**
   * Modifies the date.
   *
   * @param {Date} value
   */
  const modifyDate = (value) => {
    const newStart = new Date(start);
    newStart.setFullYear(value.getFullYear(), value.getMonth(), value.getDate());

    const diff = differenceInDays(newStart, start);

    onChange({
      start: newStart,
      end: addDays(end, diff),
    });
  };

  const isStartLocked = locked === 'start';
  const isEndLocked = locked === 'end';
  const hasError = end <= start;
  const startHasError = !isStartLocked && hasError;
  const endHasError = !isEndLocked && hasError;
  const showStartDate = alwaysShowDates || !sameDay;
  const showEndDate = alwaysShowDates || !sameDay;

  return (
    <Group position="apart" align="start">
      <Group spacing={32} py={8} align="start">
        {withDateInput && (
          <DatePicker
            w={170}
            label={
              <Text c="#808080" fz={12} lh={16 / 12}>
                {_t('Date')}
              </Text>
            }
            value={start}
            onChange={modifyDate}
            showDayControls
            maxDate={new Date()}
          />
        )}

        <Group spacing={8}>
          <Stack w={100} spacing={2}>
            <TimeInput
              value={start}
              onChange={modifyStart}
              label={<LabelWithLock label={startLabel} locked={isStartLocked} />}
              error={startHasError}
              rightSection={<TimeIcon width={16} height={16} stroke="#000" />}
            />
            {showStartDate && (
              <Text px={4} fz={12} lh={16 / 12} c="neutral300">
                {start.toLocaleDateString('sk-SK')}
              </Text>
            )}
          </Stack>
          <Stack w={100} spacing={2}>
            <TimeInput
              value={end}
              onChange={modifyEnd}
              label={<LabelWithLock label={endLabel} locked={isEndLocked} />}
              error={endHasError}
              rightSection={<TimeIcon width={16} height={16} stroke="#000" />}
            />
            {showEndDate && (
              <Text px={4} fz={12} lh={16 / 12} c="neutral300">
                {end.toLocaleDateString('sk-SK')}
              </Text>
            )}
          </Stack>
        </Group>
      </Group>

      <Group bg="#CECDFF" style={{ borderRadius: '8px' }} pt={8} px={16} pb={16}>
        <HoursAndMinutesInput
          w={100}
          hoursLabel={hoursLabel}
          minutesLabel={minutesLabel}
          value={duration}
          onChange={modifyDuration}
        />
      </Group>
    </Group>
  );
}
