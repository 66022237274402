import { Tooltip as MantineTooltip } from '@mantine/core';
import HintIcon from 'components/icons/HintIcon';
import { useMemo } from 'react';

/**
 * Tooltip component.
 *
 * @param {{
 *   text?: string,
 *   icon: React.ReactNode,
 * }}
 */
const Tooltip = ({ text, icon = <HintIcon /> }) => {
  return (
    <MantineTooltip label={text}>
      <div>{icon}</div>
    </MantineTooltip>
  );
};

/**
 * Creates input with label and tooltip (optional)
 * - with tooltip if the tooltipText is provided
 * - in row if align is 'row' or in column if align is 'column'
 * - the wrapper accepts additional class names for the whole div
 *
 * @param {{
 *   leftTooltipText: string|undefined,
 *   rightTooltipText: string|undefined,
 *   input: JSX.Element,
 *   align: 'row' | 'column',
 *   className: string,
 *   label?: JSX.Element | string,
 *   inputSize: 'small' | 'medium' | 'full'
 * }}
 */
export default function InputWrapper({
  leftTooltipText,
  rightTooltipText,
  input,
  label,
  align = 'row',
  className = '',
  inputSize = align === 'row' ? 'small' : 'medium',
}) {
  const inputSizeMap = useMemo(() => ({
    small: 'w-[278px]',
    medium: 'w-[500px]',
    full: 'w-full',
  }));

  const inputClass = inputSizeMap[inputSize];

  const alignClasses = align === 'row' ? 'flex items-center justify-between gap-[16px]' : 'flex flex-col';

  return (
    <div className={`${alignClasses} ${className}`}>
      {label && <div className="py-2 text-[15px] leading-[18px] text-nautral-700">{label}</div>}
      <div className="flex items-center gap-[10px]">
        {leftTooltipText && <Tooltip text={leftTooltipText} />}
        <div className={inputClass}>{input}</div>
        {rightTooltipText && <Tooltip text={rightTooltipText} />}
      </div>
    </div>
  );
}
