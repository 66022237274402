import { useForm } from '@mantine/form';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import { _t } from 'lang';
import ClientSelect from 'components/selects/ClientSelect';
import { Box, Button, Group, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import UserSelect from 'components/selects/UserSelect';
import FormRow from 'components/forms/FormRow';
import WysiwygEditor from 'components/inputs/wysiwyg/WysiwygEditor';
import useLoadingAction from 'hooks/use-loading-action';
import { useEffect, useMemo, useRef, useState } from 'react';
import FormFooter from 'components/forms/FormFooter';
import PlusSmallIcon from 'components/icons/PlusSmallIcon';
import { nanoid } from 'nanoid';
import DatePicker from 'components/inputs/DatePicker';
import { useApi } from 'api/ApiContext';
import { isBefore } from 'date-fns';

/**
 * @typedef {{
 *   clientId: string;
 *   projectName: string;
 *   start?: Date;
 *   end?: Date;
 *   ownerId: string;
 *   tasks?: { title: string; uuid: string }[];
 *   projectPrefix: string;
 * }} ProjectFormData
 */

/**
 * The Add / Edit Project form.
 *
 * @param {{
 *   initialValues: ProjectFormData,
 *   onSubmit: (values: ProjectFormData) => void | Promise<void>,
 *   hide?: Partial<Record<keyof ProjectFormData, boolean>>,
 *   disabled?: Partial<Record<keyof ProjectFormData, boolean>>,
 * }}
 */
export default function ProjectForm({ initialValues, onSubmit, hide = {}, disabled = {} }) {
  const { hasPermission, accessibleClients, getAction } = useApi();
  const [loading, submit] = useLoadingAction(onSubmit);
  const descriptionRef = useRef(null);

  const [displayStartTooltip, setDisplayStartTooltip] = useState(false);
  const [displayEndTooltip, setDisplayEndTooltip] = useState(false);

  const form = useForm({
    initialValues,

    validate: {
      clientId: createValidator([required]),
      projectName: createValidator([required]),
      ownerId: createValidator([required]),
    },
  });

  // if the user has global permission, the filter is not needed
  const clientFilter = useMemo(
    () => (hasPermission('PROJECTS_MANAGE_PROJECT') ? {} : { 'client_id.in': accessibleClients }),
    [accessibleClients, hasPermission]
  );

  const projectHolderFilter = useMemo(
    () => ({
      'permission.has': [
        { permission_slug: 'PROJECTS_VIEW_PROJECT', client_id: Number(form.values.clientId) },
        { permission_slug: 'PROJECTS_MANAGE_PROJECT', client_id: Number(form.values.clientId) },
      ],
    }),
    [form.values.clientId]
  );

  useEffect(() => {
    if (form.values.clientId && initialValues.projectPrefix === '') {
      const clientGetListAction = getAction('ClientGetListAction');
      clientGetListAction({ query: { filter: { 'client_id.eq': form.values.clientId } } }).then(([client]) => {
        const shortcutSlug = client.shortcut_slug;
        const nextProjectNum = client.next_project_num.toString().padStart(3, '0');
        const year = String(new Date().getFullYear()).substring(2);
        form.setFieldValue('projectPrefix', `${shortcutSlug} ${year}-${nextProjectNum}`);
      });
    }
  }, [form.values.clientId, getAction]);

  // close the tooltips after 3 seconds
  useEffect(() => {
    if (displayStartTooltip) {
      setTimeout(() => {
        setDisplayStartTooltip(false);
      }, 3000);
    }
  }, [displayStartTooltip]);

  useEffect(() => {
    if (displayEndTooltip) {
      setTimeout(() => {
        setDisplayEndTooltip(false);
      }, 3000);
    }
  }, [displayEndTooltip]);

  return (
    <form onSubmit={form.onSubmit(() => submit({ ...form.values, description: descriptionRef.current?.getHTML() }))}>
      <Stack spacing={16}>
        <Stack spacing={16}>
          <FormRow
            label={_t('Client')}
            input={
              <ClientSelect
                placeholder={_t('Select client')}
                disabled={disabled.clientId}
                actionFilter={clientFilter}
                {...form.getInputProps('clientId')}
              />
            }
          />
          <FormRow
            label={_t('Project name')}
            input={
              <Group w="100%" className="grid grid-cols-[auto_1fr] gap-2">
                <Box>{form.values.projectPrefix}</Box>
                <TextInput
                  type="text"
                  disabled={disabled.projectName}
                  autoFocus={form.values.clientId !== ''}
                  {...form.getInputProps('projectName')}
                />
              </Group>
            }
            tooltipText={_t('Name of the project')}
          />
          <FormRow
            label={_t('Description')}
            input={
              <WysiwygEditor
                clientId={form.values.clientId}
                editorRef={descriptionRef}
                initialContent={form.values.description}
              />
            }
          />
          <FormRow
            label={_t('Start date')}
            inputSize="small"
            input={
              <Tooltip
                label={_t('Updated to match end date')}
                opened={displayStartTooltip}
                events={{ hover: false, focus: false, touch: false }}
                position="bottom"
                styles={{
                  tooltip: {
                    backgroundColor: '#FFC700',
                  },
                }}
              >
                <Box>
                  <DatePicker
                    disabled={disabled.start}
                    {...form.getInputProps('start')}
                    onChange={(val) => {
                      if (val) {
                        form.getInputProps('start').onChange(val);

                        const end = form.values.end ? new Date(form.values.end.toLocaleDateString()) : null;
                        const start = new Date(val.toLocaleDateString());

                        if (form.values.end && isBefore(end, start)) {
                          form.setFieldValue('end', val);
                          setDisplayEndTooltip(true);
                        }
                      }
                    }}
                  />
                </Box>
              </Tooltip>
            }
          />
          <FormRow
            label={_t('Deadline (Due date)')}
            inputSize="small"
            input={
              <Tooltip
                label={_t('Updated to match start date')}
                opened={displayEndTooltip}
                events={{ hover: false, focus: false, touch: false }}
                position="bottom"
                styles={{
                  tooltip: {
                    backgroundColor: '#FFC700',
                  },
                }}
              >
                <Box>
                  <DatePicker
                    disabled={disabled.end}
                    {...form.getInputProps('end')}
                    onChange={(val) => {
                      if (val) {
                        form.getInputProps('end').onChange(val);

                        const start = form.values.start ? new Date(form.values.start.toLocaleDateString()) : null;
                        const end = new Date(val.toLocaleDateString());

                        if (form.values.start && isBefore(end, start)) {
                          form.setFieldValue('start', val);
                          setDisplayStartTooltip(true);
                        }
                      }
                    }}
                  />
                </Box>
              </Tooltip>
            }
          />
          <FormRow
            label={_t('Project holder')}
            input={
              <UserSelect
                disabled={disabled.ownerId}
                placeholder={_t('Select project holder')}
                actionFilter={projectHolderFilter}
                {...form.getInputProps('ownerId')}
              />
            }
          />
          {!hide.tasks && (
            <>
              <Stack py={18}>
                <Text fz={22} lh={32 / 22}>
                  {_t('TASKS')}
                </Text>
              </Stack>
              {(form.values.tasks || []).map(({ uuid }, i) => (
                <FormRow
                  key={uuid}
                  label={_t('Task name')}
                  input={
                    <Group spacing={0} w="100%">
                      <Box w="15%">{form.values.projectPrefix}</Box>
                      <TextInput disabled={disabled.tasks} {...form.getInputProps(`tasks.${i}.title`)} w="85%" />
                    </Group>
                  }
                  tooltipText={_t('Name of the task')}
                />
              ))}
              <Group position="left">
                <Button
                  disabled={disabled.tasks}
                  variant="link"
                  leftIcon={<PlusSmallIcon height={24} width={24} />}
                  onClick={() => form.insertListItem('tasks', { title: '', uuid: nanoid() })}
                >
                  {_t('Add task')}
                </Button>
              </Group>
            </>
          )}
        </Stack>
      </Stack>

      <FormFooter
        loading={loading}
        skipCancelConfirmation={() => !form.isDirty() && !descriptionRef.current?.isDirty()}
      />
    </form>
  );
}
