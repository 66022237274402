/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-set-active-action-body').UserSetActiveActionBody} UserSetActiveActionBody
 */

/**
 * @typedef {import('./user-set-active-action-response').UserSetActiveActionResponse} UserSetActiveActionResponse
 */

/**
 * The path of the UserSetActiveAction action.
 */
export const userSetActiveActionPath = new SweetPath('/api/user/{user_id}/set_active', { path: '{param}' });

/**
 * Creates a contextualized UserSetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserSetActiveAction(axios) {
  /**
   * The userSetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserSetActiveActionBody } }
   *
   * @returns {Promise<UserSetActiveActionResponse>}
   */
  function userSetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userSetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return userSetActiveAction;
}
