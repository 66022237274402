import { useCallback, useState } from 'react';
import useAsyncAction from 'hooks/use-async-action';

/**
 * Decorates a function to dispatch a loading action before and after the
 * function is called.
 *
 * @template TArgs
 * @template TReturn
 *
 * @param {(...args: TArgs) => TReturn | Promise<TReturn>} action
 *
 * @returns {[boolean, (...args: TArgs) => Promise<TReturn>]}
 */
export default function useLoadingAction(action) {
  const asyncAction = useAsyncAction(action);
  const [loading, setLoading] = useState(false);
  const stopLoading = useCallback(() => setLoading(false), []);

  /**
   * Handle the form submission.
   */
  const decoratedAction = useCallback(
    (...args) =>
      new Promise((resolve, reject) => {
        setLoading((loading) => {
          if (!loading) {
            asyncAction(...args)
              .then(resolve)
              .catch(reject)
              .finally(stopLoading);
          }

          return true;
        });
      }),
    [asyncAction]
  );

  return [loading, decoratedAction];
}
