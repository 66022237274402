import { _t } from 'lang';
import FormRow from 'components/forms/FormRow';
import { useProject } from 'providers/project/ProjectProvider';
import ProjectSelect from 'components/selects/ProjectSelect';
import { useClient } from 'providers/client/ClientProvider';
import { useMemo } from 'react';
import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import { Skeleton } from '@mantine/core';

/**
 * The project of a cost estimate.
 */
export default function CostEstimateProject() {
  const { project, projectId, setProjectId } = useProject();
  const { clientId } = useClient();
  const { data } = useData();

  const actionFilter = useMemo(() => ({ 'client_id.eq': clientId }), [clientId]);

  let select = <></>;

  if (clientId) {
    select = <Skeleton w="full" h={36} animate radius={8} />;

    // Either this is a new cost estimate or the project is already fetched.
    if (!data.costEstimateId || project) {
      select = (
        <ProjectSelect
          disabled={!!data.costEstimateId}
          actionFilter={actionFilter}
          value={String(projectId)}
          onChange={(projectId) => setProjectId(Number(projectId))}
        />
      );
    }
  }

  return <FormRow label={_t('Project')} input={select} />;
}
