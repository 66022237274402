import { Box, Button, Notification } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';
import { _t } from 'lang';
import { useVersion } from './VersionProvider';
import { FEATURE_TOGGLE_SHOW_VERSION } from 'environment';
import { useMemo } from 'react';

/**
 * Notification that a new version of the application is available.
 */
export default function NewVersionNotification() {
  const { currentVersion, latestVersion } = useVersion();

  const versionHtml = useMemo(
    () => ({ __html: _t('You are currently using version <b>%s</b>, the latest version is <b>%s.</b>', currentVersion, latestVersion) }), // prettier-ignore
    [currentVersion, latestVersion]
  );

  return (
    <Notification
      pos="fixed"
      style={{ zIndex: 1000 }}
      bottom={16}
      right={16}
      disallowClose
      color="accent"
      title={_t('An update is available')}
      icon={<IconRefresh />}
    >
      <Box c="neutral700">
        <span>{_t('Please')} </span>
        <Button display="inline" variant="link" onClick={() => location.reload()}>
          {_t('refresh')}
        </Button>
        <span> {_t('the page to get the newest version of Toolio (Ctrl+R).')}</span>
        {FEATURE_TOGGLE_SHOW_VERSION && (
          <Box sx={{ '& b': { fontWeight: 500 } }} dangerouslySetInnerHTML={versionHtml} />
        )}
      </Box>
    </Notification>
  );
}
