import SidebarItem from 'layouts/dashboard-layout/sidebars/settings-sidebar/SidebarItem';
import { _t } from 'lang';
import {
  ROLES_PAGE_PATH,
  USERS_PAGE_PATH,
  TEAMS_PAGE_PATH,
  JOB_POSITIONS_PAGE_PATH,
  DEPARTMENTS_PAGE_PATH,
  SUPPLIERS_PAGE_PATH,
  CLIENTS_PAGE_PATH,
  PRICE_LISTS_PAGE_PATH,
  GLOBAL_SETTINGS_PAGE_PATH,
} from 'routes/paths';
import PricelistIcon from 'components/icons/PricelistIcon';
import SupplierIcon from 'components/icons/SupplierIcon';
import ClientIcon from 'components/icons/ClientIcon';
import DepartmentIcon from 'components/icons/DepartmentIcon';
import JobPositionIcon from 'components/icons/JobPositionIcon';
import TeamIcon from 'components/icons/TeamIcon';
import RoleIcon from 'components/icons/RoleIcon';
import UserIcon from 'components/icons/UserIcon';
import GlobalIcon from 'components/icons/GlobalIcon';
import { useApi } from 'api/ApiContext';
import { Stack, Group, ScrollArea } from '@mantine/core';
import { useSettingsSidebar } from './SettingsSidebarProvider';

/**
 * The sidebar of the settings module.
 */
export default function SettingsSidebar() {
  const { workspaceConfig, hasPermission } = useApi();
  const { users, roles, teams, positions, departments, clients, priceLists, suppliers } = useSettingsSidebar();

  return (
    <Stack spacing={0} p={8} pb={0} h="100%" bg="#FAFAFA">
      <Group pl={16} pr={8} pt={16} pb={16} h={86}>
        <div className="flex h-[54px] items-center justify-start">
          <span className="text-[20px] leading-[24px] text-neutral-700">{_t('Settings')}</span>
        </div>
      </Group>
      <ScrollArea h="100%" pb={16}>
        <Stack spacing={0} color="#4D4D4D" style={{ position: 'relative' }}>
          <SidebarItem
            pagePath={USERS_PAGE_PATH.original}
            modelSlug="users"
            linkIcon={<UserIcon stroke="#4D4D4D" />}
            linkText={_t('Users')}
            titleKey="full_name"
            primaryKey="user_id"
            data={users}
          />
          {workspaceConfig.features.roles && hasPermission('SETTINGS_MANAGE_ROLE') && (
            <SidebarItem
              pagePath={ROLES_PAGE_PATH.original}
              modelSlug="roles"
              linkIcon={<RoleIcon stroke="#4D4D4D" />}
              linkText={_t('Roles')}
              primaryKey="role_id"
              titleKey="role_name"
              data={roles}
            />
          )}
          {workspaceConfig.features.teams && (
            <SidebarItem
              pagePath={TEAMS_PAGE_PATH.original}
              modelSlug="teams"
              linkIcon={<TeamIcon stroke="#4D4D4D" />}
              linkText={_t('Teams')}
              primaryKey="team_id"
              titleKey="name"
              data={teams}
            />
          )}
          <SidebarItem
            pagePath={JOB_POSITIONS_PAGE_PATH.original}
            modelSlug="job-positions"
            linkIcon={<JobPositionIcon stroke="#4D4D4D" />}
            linkText={_t('Job Positions')}
            primaryKey="position_in_company_id"
            titleKey="position_name"
            data={positions}
          />
          {workspaceConfig.features.departments && (
            <SidebarItem
              pagePath={DEPARTMENTS_PAGE_PATH.original}
              modelSlug="departments"
              linkIcon={<DepartmentIcon stroke="#4D4D4D" />}
              linkText={_t('Departments')}
              primaryKey="department_id"
              titleKey="name"
              data={departments}
            />
          )}
          <SidebarItem
            pagePath={CLIENTS_PAGE_PATH.original}
            modelSlug="clients"
            linkIcon={<ClientIcon stroke="#4D4D4D" />}
            linkText={_t('Clients')}
            primaryKey="client_id"
            titleKey="client_name"
            data={clients}
          />
          {workspaceConfig.features.priceLists && (
            <SidebarItem
              pagePath={PRICE_LISTS_PAGE_PATH.original}
              modelSlug="price-lists"
              linkIcon={<PricelistIcon fill="#4D4D4D" />}
              linkText={_t('Pricelists')}
              primaryKey="price_list_id"
              titleKey="price_list_name"
              data={priceLists}
            />
          )}
          {workspaceConfig.features.suppliers && (
            <SidebarItem
              pagePath={SUPPLIERS_PAGE_PATH.original}
              modelSlug="suppliers"
              linkIcon={<SupplierIcon fill="#4D4D4D" />}
              linkText={_t('Suppliers')}
              primaryKey="supplier_id"
              titleKey="supplier_name"
              data={suppliers}
            />
          )}
          {workspaceConfig.features.globalSettings && (
            <SidebarItem
              pagePath={GLOBAL_SETTINGS_PAGE_PATH.original}
              linkIcon={<GlobalIcon stroke="#4D4D4D" />}
              linkText={_t('Global settings')}
            />
          )}
        </Stack>
      </ScrollArea>
    </Stack>
  );
}
