/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['RangeCalendar']} RangeCalendarTheme */

import { ErrorStyles } from 'theme/components/Inputs/shared/Error';

/** @type {RangeCalendarTheme} */
export const RangeCalendar = {
  styles: {
    label: {
      fontWeight: 400,
    },
    cell: {
      paddingBottom: '2px',
      height: '32px',
      width: '32px',
    },
    day: {
      height: '32px',
      width: '100%',
      color: '#808080',
      lineHeight: '16px',
      fontSize: '14px',
      '&[data-weekend]': {
        color: '#808080',
      },
      '&[data-outside]': {
        color: '#C6C6C6',
      },
      '&:hover': {
        borderRadius: '50%',
        color: '#38298B',
        backgroundColor: '#908EFB',
      },
      '&[data-in-range]': {
        backgroundColor: '#CECDFF',
        color: '#808080',
        '&:hover': {
          borderRadius: 0,
          backgroundColor: '#908EFB',
          color: '#38298B',
        },
      },
      '&[data-first-in-range]': {
        color: '#FFFFFF',
        backgroundColor: '#38298B',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        '&:hover': {
          borderRadius: 0,
          backgroundColor: '#908EFB',
          color: '#FFFFFF',
          borderTopLeftRadius: '50%',
          borderBottomLeftRadius: '50%',
        },
      },
      '&[data-last-in-range]': {
        color: '#FFFFFF',
        backgroundColor: '#38298B',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        '&:hover': {
          borderRadius: 0,
          backgroundColor: '#908EFB',
          color: '#FFFFFF',
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
        },
      },
      '&[data-in-range][data-last-in-range][data-first-in-range]:hover': {
        borderRadius: '50%',
        color: '#38298B',
        backgroundColor: '#908EFB',
      },
    },
    weekdayCell: {
      paddingBottom: '8px',
      height: '32px',
      width: '32px',
    },
    weekday: {
      color: '#808080',
      fontSize: '12px',
      lineHeight: '14px',
    },
    calendarHeader: {
      marginBottom: '8px',
    },
    calendarHeaderLevel: {
      color: '#38298B',
      fontSize: '15px',
      lineHeight: '16px',
    },
    calendarHeaderControl: {
      color: '#38298B',
    },
    monthPickerControlActive: {
      backgroundColor: '#38298B !important',
    },
    yearPickerControlActive: {
      backgroundColor: '#38298B !important',
    },
    rightSection: {
      paddingRight: '10px',
      width: '22px',
    },
    dropdown: {
      padding: '16px',
    },
    error: ErrorStyles,
  },
};
