import { Breadcrumbs, ScrollArea, Stack } from '@mantine/core';
import { ProjectProvider, useProject } from 'providers/project/ProjectProvider';
import TaskMenuItem from 'pages/tasks/task-detail/sidebar/TaskMenuItem';
import { _t } from 'lang';
import ProjectActions from 'components/project/ProjectActions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PROJECT_DASHBOARD_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH } from 'routes/paths';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';

/**
 * The sidebar of the task detail page.
 */
function TaskDetailSidebarImpl() {
  const { getAction } = useApi();
  const { projectId, project, refresh } = useProject();
  const { sidebarRefreshToken } = useTaskDetail();
  const [tasks, setTasks] = useState([]);

  const fetchTasks = useCallback(() => {
    const taskGetListAction = getAction('TaskGetListAction');

    taskGetListAction({ parameters: { project_id: projectId } })
      .then(setTasks)
      .catch(panic);
  }, [projectId, getAction]);

  const suffix = useMemo(() => {
    if (project?.deleted_at) {
      return _t('(Deleted)');
    } else if (project?.closed_at) {
      return _t('(Closed)');
    } else {
      return '';
    }
  }, [project]);

  // Fetch tasks when the projectId changes or a task is updated.
  useEffect(fetchTasks, [projectId, sidebarRefreshToken]);

  return (
    <div className="h-full bg-neutral-20 p-2 pb-4">
      <Breadcrumbs>
        <Link to={PROJECT_DASHBOARD_PAGE_PATH.original}>{_t('Projects')}</Link>
        {project && (
          <Link to={`${PROJECT_DASHBOARD_PAGE_PATH.original}?clientId=${project.client.client_id}`}>
            {project.client.client_name}
          </Link>
        )}
        <span></span>
      </Breadcrumbs>
      <div className="flex flex-col gap-2">
        <div className="group/project-name flex flex-row items-center justify-between p-4 pr-2">
          <Link to={PROJECT_DETAIL_PAGE_PATH.insert({ projectId })} className="inline-block">
            <span className="text-[20px] leading-[24px] text-neutral-700 hover:text-primary-dark-blue">
              {project?.project_full_name ?? ''} {suffix}
            </span>
          </Link>
          {project && (
            <ProjectActions
              project={project}
              onProjectClose={refresh}
              onProjectDelete={refresh}
              onProjectReopen={refresh}
              onProjectRestore={refresh}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <ScrollArea h="calc(100vh - 165px)">
            <Stack spacing={8} pb={4}>
              {tasks
                .filter(({ deleted_at }) => !deleted_at)
                .map(({ task_id }) => (
                  <TaskMenuItem key={task_id} taskId={task_id} projectId={projectId} />
                ))}
            </Stack>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}

/**
 * The sidebar of the project module.
 *
 * @param {{
 *   projectId: number;
 * }}
 */
export default function TaskDetailSidebar({ projectId }) {
  return (
    <ProjectProvider initialProjectId={projectId}>
      <TaskDetailSidebarImpl />
    </ProjectProvider>
  );
}
