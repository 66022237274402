/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-create-action-body').TaskCreateActionBody} TaskCreateActionBody
 */

/**
 * @typedef {import('./task-create-action-response').TaskCreateActionResponse} TaskCreateActionResponse
 */

/**
 * The path of the TaskCreateAction action.
 */
export const taskCreateActionPath = new SweetPath('/api/project/project/{project_id}/task', { path: '{param}' });

/**
 * Creates a contextualized TaskCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskCreateAction(axios) {
  /**
   * The taskCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskCreateActionBody } }
   *
   * @returns {Promise<TaskCreateActionResponse>}
   */
  function taskCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return taskCreateAction;
}
