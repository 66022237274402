import { Breadcrumbs, Tabs } from '@mantine/core';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import TimeEntriesExportForm from './TimeEntriesExportForm';
import { createDateRangeFromType } from 'components/inputs/date-range/date-range';
import { useApi } from 'api/ApiContext';
import { exportFile } from './export-file';

/**
 * Page for exporting time entries.
 *
 * @see {@link https://www.figma.com/design/CQQ1mMQRmo9H7dsJSejbRd/Toolio---Design?node-id=3261-2562&m=dev Figma}
 */
export default function TimeEntriesExportPage() {
  const { getAction } = useApi();

  /**
   * Retrieves the export from the API.
   * The export is in the CSV format.
   *
   * @param {import('./TimeEntriesExportForm').TimeEntriesExportFormData} values
   */
  async function handleExport(values) {
    const timeLogExportAction = getAction('TimeLogExportAction');

    const filter = {
      'start.gte': values.dateRange.start.toISOString(),
      'end.lte': values.dateRange.end.toISOString(),
      'client_id.eq': values.clientId && values.clientId !== '' ? Number(values.clientId) : undefined,
      'project_id.eq': values.projectId && values.projectId !== '' ? Number(values.projectId) : undefined,
      'task_id.eq': values.taskId && values.taskId !== '' ? Number(values.taskId) : undefined,
      'user_id.in': values.userId && Array.isArray(values.userId) ? values.userId.map(Number) : undefined,
    };

    const { file_contents: contents } = await timeLogExportAction({ query: { filter } });

    exportFile({ contents, fileName: 'time-entries.xlsx' });
  }

  return (
    <DashboardLayout contentClass="bg-neutral-50 px-8 pb-8">
      <PageHeading
        heading={_t('Time Entry Report')}
        breadcrumbs={
          <Breadcrumbs>
            <span>{_t('Reports')}</span>
          </Breadcrumbs>
        }
      />

      <Tabs defaultValue="time-entries">
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="time-entries">{_t('Time entries')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="time-entries">
          <TimeEntriesExportForm
            initialValues={{ dateRange: createDateRangeFromType('this_month') }}
            onSubmit={handleExport}
          />
        </Tabs.Panel>
      </Tabs>
    </DashboardLayout>
  );
}
