/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./client-set-active-action-body').ClientSetActiveActionBody} ClientSetActiveActionBody
 */

/**
 * @typedef {import('./client-set-active-action-response').ClientSetActiveActionResponse} ClientSetActiveActionResponse
 */

/**
 * The path of the ClientSetActiveAction action.
 */
export const clientSetActiveActionPath = new SweetPath('/api/project/client/{client_id}/set_active', {
  path: '{param}',
});

/**
 * Creates a contextualized ClientSetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createClientSetActiveAction(axios) {
  /**
   * The clientSetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ClientSetActiveActionBody } }
   *
   * @returns {Promise<ClientSetActiveActionResponse>}
   */
  function clientSetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = clientSetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return clientSetActiveAction;
}
