import StatusSwitch from 'components/inputs/status-switch/StatusSwitch';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';

/**
 * Status switch for the department model.
 */
export default function DepartmentStatusSwitch({ departmentId, name, isActive }) {
  const { getAction } = useApi();
  const departmentSetActiveAction = getAction('DepartmentSetActiveAction');

  return (
    <StatusSwitch
      entity={_t('Department')}
      name={name}
      action={departmentSetActiveAction}
      primaryKey={departmentId}
      pathParameterName="department_id"
      initialValue={isActive}
    />
  );
}
