/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */
/** @typedef {import('pages/projects/project-overview/ClientProjects').DraggableProjects} DraggableProjects */

import { noop } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import panic from 'errors/Panic';
import { useApi } from 'api/ApiContext';
import ProjectTable from 'pages/projects/project-overview/project-table/ProjectTable';
import { StrictModeDroppable } from 'pages/projects/project-overview/StrictModeDroppable';
import { Stack } from '@mantine/core';
import { _t } from 'lang';

/**
 * The projects displayed in the client table.
 *
 * @param {{
 *   showDeleted: boolean;
 *   showOnlyMyProjects: boolean;
 *   state: DraggableProjects;
 *   setState: (state: DraggableProjects) => void;
 *   onProjectUpdate?: (projectId: number) => void;
 *   onDragStart?: () => void;
 *   onDragEnd?: () => void;
 *   onCanDropChange?: (canDrop: boolean) => void;
 * }}
 */
export default function ProjectsTable({
  showDeleted,
  showOnlyMyProjects,
  state,
  setState,
  onProjectUpdate = noop,
  onDragStart = noop,
  onDragEnd = noop,
  onCanDropChange = noop,
}) {
  const { getAction, userId } = useApi();

  /**
   * Handles the drag start event.
   */
  const onDragStartImpl = () => {
    onDragStart();
  };

  /**
   * Handles the drag end event.
   */
  const onDragEndImpl = (result) => {
    onDragEnd();

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const projectId = draggableId.split('-')[1];
    const newIndex = destination.index;

    const column = state.columns[source.droppableId];
    const newProjectIds = Array.from(column.projectIds);
    newProjectIds.splice(source.index, 1);
    newProjectIds.splice(newIndex, 0, draggableId);

    const newColumn = {
      ...column,
      projectIds: newProjectIds,
    };

    const projectsAbove = newProjectIds.slice(0, newIndex);

    // count deleted projects
    // -1 since we included the dropped project
    const deletedProjectCount =
      Object.keys(state.projects)
        .filter((projectKey) => projectsAbove.includes(projectKey))
        .filter((projectKey) => !state.projects[projectKey]['content']['project']['ord']).length - 1 || 0;

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newColumn.id]: newColumn,
      },
    };

    setState(newState);

    const projectUpdateOrdAction = getAction('ProjectUpdateOrdAction');

    projectUpdateOrdAction({
      parameters: { project_id: projectId },
      body: { new_ord: newIndex - deletedProjectCount },
    }).catch(panic);
  };

  return (
    <Stack spacing={0}>
      <div className="h-[18px] pl-2 pr-2 text-[12px] leading-[18px] text-neutral-500">
        <div className="grid grid-cols-[24px_24px_1fr_60px_128px_128px_122px_24px] gap-2">
          <div></div> {/* drag and drop icon */}
          <div></div> {/* toggle icon */}
          <div>{_t('Name')}</div>
          <div>{_t('Assignee')}</div>
          <div className="pr-2 text-right">{_t('Logged hours')}</div>
          <div className="pr-2 text-right">{_t('Due date')}</div>
          <div>{_t('Status')}</div>
          <div className="flex justify-end" />
        </div>
      </div>

      <div className="relative flex flex-col overflow-hidden pb-4">
        <DragDropContext
          onDragStart={onDragStartImpl}
          onDragEnd={onDragEndImpl}
          onDragUpdate={({ destination }) => onCanDropChange(!!destination)}
        >
          {state.columnOrder.map((columnId) => {
            const column = state.columns[columnId];
            const projects = column.projectIds.map((projectId) => state.projects[projectId]);

            return (
              <div key={column.id} style={{ backgroundColor: '#F2F2F2' }}>
                <StrictModeDroppable droppableId={column.id}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      // disable hover on other rows when dragging over
                      style={{ pointerEvents: snapshot.isDraggingOver ? 'none' : undefined }}
                    >
                      {projects
                        .filter(({ content: { project } }) => showDeleted || !project.deleted_at)
                        .filter(({ content: { project } }) => !showOnlyMyProjects || project.owner.user_id === userId)
                        .map(({ id: draggableId, content: { project } }, index) => (
                          <ProjectTable
                            key={draggableId}
                            draggableId={draggableId}
                            index={index}
                            project={project}
                            onProjectDelete={() => onProjectUpdate(project.project_id)}
                            onProjectClose={() => onProjectUpdate(project.project_id)}
                            onProjectReopen={() => onProjectUpdate(project.project_id)}
                            onProjectRestore={() => onProjectUpdate(project.project_id)}
                            showDeleted={showDeleted}
                          />
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </Stack>
  );
}
