/** @typedef {import('./DiscountData').DiscountData} DiscountData */

import { _t } from 'lang';
import { Group, Menu, NumberInput, Select } from '@mantine/core';
import { useState, useEffect, useMemo } from 'react';
import CommentIcon from 'components/icons/CommentIcon';
import CommentModal from 'pages/finance/cost-estimate/add-cost-estimate/CommentModal';
import createDiscountData from 'pages/finance/cost-estimate/add-cost-estimate/discount/DiscountData';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import useImmutable from 'hooks/use-immutable';
import CommentTooltip from 'components/comments/CommentTooltip';
import { useDisclosure } from '@mantine/hooks';
import PriceDisplay from 'components/PriceDisplay';
import { debounce } from 'lodash';

/**
 * A form for adding a discount to a cost estimate.
 *
 * @param {{
 *   onChange: (data: DiscountData) => void,
 *   onDelete: () => void,
 *   initialData?: Partial<DiscountData>,
 *   discountAmount?: number,
 *   currency: string,
 * }}
 */
export default function DiscountForm({ onChange, onDelete, initialData, discountAmount, currency }) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isCommentModalOpened, { open: openCommentModal, close: closeCommentModal }] = useDisclosure(false);
  const [data, updateData] = useImmutable(initialData, { createFn: createDiscountData });

  const step = data.type === 'relative' ? 0.25 : 1;
  const max = data.type === 'relative' ? 100 : undefined;

  const [propagateChanges] = useState(() => debounce(onChange, 100, { maxWait: 500 }));

  /**
   * The discount types.
   */
  const DISCOUNT_TYPES = useMemo(
    () => [
      { label: currency, value: 'absolute' },
      { label: '%', value: 'relative' },
    ],
    [currency]
  );

  // Ensure that the discount is not greater than 100%.
  useEffect(() => {
    if (data.type === 'relative' && data.amount > 100) {
      updateData({ amount: 0 });
    }
  }, [data.type]);

  // Propagate changes to the parent component.
  useEffect(() => {
    propagateChanges(data);
  }, [data]);

  return (
    <div className="grid grid-cols-[auto_120px_70px_168px_200px] items-center justify-end gap-2">
      <span className="text-[15px] leading-[18px]">{_t('Discount:')}</span>

      <NumberInput
        styles={{ input: { textAlign: 'right' } }}
        hideControls
        precision={2}
        decimalSeparator=","
        min={0}
        max={max}
        step={step}
        value={data.amount}
        onChange={(amount) => updateData({ amount })}
      />

      <Select value={data.type} data={DISCOUNT_TYPES} onChange={(type) => updateData({ type })} />

      <PriceDisplay className="text-right" value={-discountAmount} currency={currency} />

      <Group position="right" spacing={8}>
        <div className="flex items-center justify-center">
          {data.comment && <CommentTooltip comment={data.comment} />}
        </div>

        <div className="flex items-stretch">
          <div className={isMenuOpened ? 'flex' : 'invisible group-hover:visible'}>
            <Menu width={200} offset={15} onChange={setIsMenuOpened}>
              <Menu.Target>
                <div className="flex cursor-pointer items-center justify-center">
                  <OptionsDotsIcon />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={onDelete} icon={<DeleteIcon />}>
                  <span>{_t('Remove discount')}</span>
                </Menu.Item>
                <Menu.Divider />
                {data.comment ? (
                  <>
                    <Menu.Item onClick={openCommentModal} icon={<EditIcon />}>
                      <span>{_t('Edit comment')}</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => updateData({ comment: null })} icon={<DeleteIcon />}>
                      <span>{_t('Remove comment')}</span>
                    </Menu.Item>
                  </>
                ) : (
                  <Menu.Item onClick={openCommentModal} icon={<CommentIcon />}>
                    <span>{_t('Add comment')}</span>
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </Group>

      <CommentModal
        opened={isCommentModalOpened}
        initialComment={data.comment}
        onClose={(comment) => {
          closeCommentModal();

          if (comment) {
            updateData({ comment });
          }
        }}
      />
    </div>
  );
}
