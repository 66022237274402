import { Grid, Text } from '@mantine/core';
import { _t } from '../lang';
import { formattedTime } from './tables/time-entries/TimeEntriesTable';

/**
 * Renders the Time Entries Summary component.
 *
 * @param {{
 *   data: {
 *     loggedHours: number,
 *     date: Date,
 *   }[]
 * }} props - The component props.
 *
 * @returns {JSX.Element} The Time Entries Summary component as a React element.
 */
export default function TimeEntriesSummary({ data }) {
  const totalLoggedMinutes = data.reduce((acc, { loggedHours }) => acc + loggedHours, 0).toFixed(2) * 60;

  return (
    <Grid columns={5}>
      <Grid.Col display="flex" className="flex-col" span={2}>
        <Text className="text-grey-500">{_t('Total logged hours')}</Text>
        <Text fz={40} lh={'48px'} color="#000" weight={300} className="text-black tracking-[-0.8px]">
          {formattedTime(totalLoggedMinutes)}
        </Text>
      </Grid.Col>
      <Grid.Col display="flex" className="flex-col" span={1}>
        <Text className="text-grey-500">{_t('Minimum Required')}</Text>
        <Text fz={40} lh={'48px'} weight={300} className="tracking-[-0.8px] text-grey-500">
          140h 0m
        </Text>
      </Grid.Col>
      <Grid.Col display="flex" className="flex-col" span={1}>
        <Text className="text-grey-500">{_t('Vacation and Holidays')}</Text>
        <Text fz={40} lh={'48px'} weight={300} className="tracking-[-0.8px] text-grey-500">
          16h 0m
        </Text>
      </Grid.Col>
      <Grid.Col display="flex" className="flex-col" span={1}>
        <Text className="text-grey-500">{_t('Remaining')}</Text>
        <Text fz={40} lh={'48px'} weight={300} className="tracking-[-0.8px] text-grey-500">
          62h 0m
        </Text>
      </Grid.Col>
    </Grid>
  );
}
