/** @typedef {import('../CostEstimateData').CostEstimateData} CostEstimateData */

import { Box, Stack, Text, Textarea } from '@mantine/core';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';

/**
 * The note of a cost estimate.
 */
export default function CostEstimateNote() {
  const { data, updateData } = useData();
  const [note, setNote] = useState(data.note);

  // Debounce can take longer here because change in cost estimate note
  // does not cause changes in the price calculation.
  const [propagateChanges] = useState(() => debounce((note) => updateData({ note }), 300));

  // Propagate changes when the note changes.
  useEffect(() => {
    propagateChanges(note);
  }, [note]);

  return (
    <Stack spacing={0}>
      <Box py={8}>
        <Text component="label" htmlFor="cost-estimate-note" fz={15} lh={18 / 15} color="neutral700">
          {_t('Footer note (visible)')}
        </Text>
      </Box>
      <Textarea
        id="cost-estimate-note"
        minRows={4}
        value={note}
        onChange={({ target: { value: note } }) => setNote(note)}
      />
    </Stack>
  );
}
