import { useDisclosure } from '@mantine/hooks';
import { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useTimeLog } from 'pages/time-entries/time-log/TimeLogProvider';
import { subSeconds } from 'date-fns';

/**
 * @typedef {{
 *   timer_id: number;
 *   task_id: number;
 *   task_full_name: string;
 *   description?: string;
 *   project_id: number;
 *   project_full_name: string
 *  }} Timer
 */

/**
 * The timer context for the Timer component.
 *
 * @type {React.Context<{
 *   isRunning: boolean,
 *   isPaused: boolean,
 *   formattedTime: string[],
 *   activeTimer?: Timer,
 *   pausedTimers: Timer[],
 *   excludeTaskIds: number[],
 *   setIsRunning: (value: boolean) => void,
 *   togglePaused: () => void,
 *   stopPause: () => void,
 *   setActiveTimer: (timer: Timer) => void,
 *   setPausedTimers: (timers: Timer[]) => void,
 *   removeTimer: (timerId: number) => void,
 *   addTimer: ({taskId: number, projectId: number}) => void,
 *   restartTimer: (timerId: number) => void,
 *   saveTimer: () => void,
 *   refresh: () => void
 * }>}
 */
const TimerContext = createContext();

// TODO remove the test timers
const testActiveTimer = {
  timer_id: 1,
  task_id: 1000012,
  task_full_name: 'Ut venenatis posuere egestas', // cspell:disable-line
  project_id: 1000001,
  project_full_name: 'Lunys',
  description: 'Redesign the website to make it more modern and user-friendly.',
  time: 0,
};

const testPausedTimers = [
  {
    timer_id: 2,
    task_id: 1000001,
    task_full_name: 'Development',
    project_id: 1000001,
    project_full_name: 'Lunys',
    time: 1800,
  },
];

/**
 * Provides the timer context to the Timer component.
 *
 * @param {{children: React.ReactNode}} props
 */
export function TimerProvider({ children }) {
  const { openTimeLogModal } = useTimeLog();

  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, { toggle: togglePaused, close: stopPause }] = useDisclosure(false);

  const [activeTimer, setActiveTimer] = useState(undefined);
  const [pausedTimers, setPausedTimers] = useState([]);

  const excludeTaskIds = useMemo(() => {
    const ids = pausedTimers.map((timer) => timer.task_id);
    if (activeTimer) {
      ids.push(activeTimer.task_id);
    }

    return ids;
  }, [activeTimer, pausedTimers]);

  /**
   * Returns the formatted time of the active timer.
   */
  const formattedTime = useMemo(() => {
    const time = activeTimer ? activeTimer.time : 0;

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return [hours, minutes, seconds].map((time) => String(time));
  }, [activeTimer]);

  /**
   * Stops the running of the active timer and removes it.
   */
  const removeTimer = useCallback(() => {
    setIsRunning(false);
    stopPause();
    setActiveTimer(undefined);
    // TODO call API to delete the timer
  }, [setIsRunning, stopPause, setActiveTimer]);

  /**
   * Creates a timer for selected task and project.
   * Stops the active timer if exists, moves it to the paused timers.
   * Starts the new timer.
   */
  const addTimer = useCallback(
    /** @param {{taskId: number; projectId: number}} */
    ({ taskId, projectId }) => {
      // firstly pause the active timer if exists
      if (activeTimer) {
        const lastActiveTimer = activeTimer;
        setIsRunning(false);
        // TODO call to API to change the state

        // move the timer to paused timers
        setPausedTimers((prev) => [...prev, lastActiveTimer]);
      }

      // TODO call to API to create a new timer
      setActiveTimer({
        timer_id: Math.floor(Math.random() * 1000),
        task_id: taskId,
        task_full_name: `New task ${taskId}`,
        project_id: projectId,
        project_full_name: `Project name ${projectId}`,
        time: 0,
      });
      // start the timer
      setIsRunning(true);
    },
    [activeTimer, setIsRunning, setActiveTimer, setPausedTimers]
  );

  /**
   * Restarts (from paused to running) the timer with the given ID.
   */
  const restartTimer = useCallback(
    (timerId) => {
      const currentPausedTimers = [...pausedTimers];
      const lastActiveTimer = activeTimer;

      if (lastActiveTimer) {
        setIsRunning(false);
        // TODO call to API to change the state

        // include the last active timer in the paused timers
        currentPausedTimers.push(lastActiveTimer);
      }

      // TODO call to API to start the new timer
      const newActiveTimer = currentPausedTimers.find((timer) => timer.timer_id === timerId);
      if (newActiveTimer) {
        setActiveTimer(newActiveTimer);
        setIsRunning(true);

        const newPausedTimers = currentPausedTimers.filter((timer) => timer.timer_id !== timerId);

        setPausedTimers(newPausedTimers);
      }
    },
    [activeTimer, setIsRunning, setActiveTimer, setPausedTimers]
  );

  /**
   * Opens the time log modal allowing the user to save the active timer.
   */
  const saveTimer = useCallback(() => {
    stopPause();
    setIsRunning(false);

    // + 60 so that the timer is rounded up
    const start = subSeconds(new Date(), activeTimer.time + 60);
    const end = new Date();

    openTimeLogModal({
      projectId: activeTimer.project_id,
      taskId: activeTimer.task_id,
      start,
      end,
      description: activeTimer.description,
      onTimeLogged: () => {
        // TODO call API to save the timer
        // clear the active timer
        // TODO give the user option to clear the active timer or remove it from the list of timers
        setActiveTimer((prev) => ({ ...prev, time: 0 }));
      },
      onTimeCancelled: () => {
        stopPause();
        setIsRunning(true);
      },
      // TODO add the option to delete the timer
      hideAddNext: true,
    });
  }, [activeTimer, stopPause, setIsRunning]);

  /**
   * Refreshes the data.
   */
  const refresh = useCallback(() => {
    // TODO call API to refresh the data

    setActiveTimer({ ...testActiveTimer, time: 3600, description: 'New description' });
  }, [setActiveTimer]);

  useEffect(() => {
    if (activeTimer && isRunning && !isPaused) {
      // TODO periodically retrieve time from the API
      const interval = setInterval(() => {
        setActiveTimer((prev) => ({
          ...prev,
          time: prev.time + 1,
        }));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [activeTimer, isRunning, isPaused]);

  useEffect(() => {
    // TODO retrieve time from the API (active timer) and paused timers

    setActiveTimer(testActiveTimer);
    setIsRunning(true);
    setPausedTimers(testPausedTimers);
  }, [setActiveTimer, setPausedTimers]);

  // TODO stop the timer when the day changes

  const value = useMemo(
    () => ({
      isRunning,
      isPaused,
      formattedTime,
      activeTimer,
      pausedTimers,
      excludeTaskIds,
      setIsRunning,
      togglePaused,
      stopPause,
      setActiveTimer,
      setPausedTimers,
      removeTimer,
      addTimer,
      restartTimer,
      saveTimer,
      refresh,
    }),
    [
      isRunning,
      isPaused,
      formattedTime,
      activeTimer,
      pausedTimers,
      excludeTaskIds,
      setIsRunning,
      togglePaused,
      stopPause,
      setActiveTimer,
      setPausedTimers,
      removeTimer,
      addTimer,
      restartTimer,
      saveTimer,
      refresh,
    ]
  );

  return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>;
}

/**
 * Uses the timer context.
 */
export function useTimer() {
  return useContext(TimerContext);
}
