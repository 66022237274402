/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "PinItemActive" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function PinItemActiveIcon({ className = '', height = 24, width = 24, fill = '#BF0D38' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="State=Active">
        <path
          id="Vector"
          d="M12 3C7.02953 3 3 7.02953 3 12C3 16.9705 7.02953 21 12 21C16.9705 21 21 16.9705 21 12C21 7.02953 16.9705 3 12 3ZM17.5607 11.693L14.0135 13.6466L14.3049 15.77C14.2631 16.218 13.8887 16.5 13.5 16.5C13.3016 16.5 12.6807 15.6726 12.5185 15.5098L10.7065 13.6978L7.7084 16.6959C7.6173 16.787 7.4975 16.8332 7.3777 16.8332C7.2579 16.8332 7.1381 16.7876 7.047 16.6959C6.86418 16.5131 6.86418 16.2167 7.047 16.0339L10.0451 13.0358L7.5 10.6154C7.01893 10.1344 7.31656 9.31073 7.99355 9.24771L10.0963 9.72879L12.0206 6.15225C12.1653 5.82779 12.4848 5.63124 12.8236 5.63124C12.9147 5.63124 13.0064 5.64559 13.0975 5.67492L13.213 5.71298C15.4798 6.45487 17.2581 8.23315 18 10.5L18.0381 10.6154C18.1785 11.0435 17.9719 11.5089 17.5607 11.693Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
