/** @typedef {import('components/comments/data/types').CommentReactionType} CommentReactionType */
/** @typedef {import("components/comments/data/types").ICommentReaction} ICommentReaction */
/** @typedef {import("components/comments/data/types").IComment} IComment */

import { Box, Collapse, Group, Stack, Text } from '@mantine/core';
import Avatar from 'components/avatars/Avatar';
import CommentReply from 'components/comments/CommentReply';
import CommentControls from 'components/comments/CommentControls';
import AttachmentsSimpleGrid from 'components/files/AttachmentsSimpleGrid';
import CommentNotifiedPeopleCount from 'components/comments/info/CommentNotifiedPeopleCount';
import CommentOptions from 'components/comments/CommentOptions';
import { noop } from 'lodash';
import CommentAttachmentsCount from 'components/comments/info/CommentAttachmentsCount';
import CommentTimestamp from 'components/comments/info/CommentTimestamp';
import { useCallback, useEffect, useState } from 'react';
import { _t } from 'lang';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { useInterval } from '@mantine/hooks';
import FormattedTextDisplay from '../FormattedTextDisplay';

/**
 * A single comment.
 *
 * @param {{
 *   comment: IComment;
 *   beingDeleted?: boolean;
 *   attachmentBeingDeleted?: string | null;
 *   hasFocus?: boolean;
 *   focusToken?: number;
 *   canModify: boolean | (() => boolean);
 *   onFocus?: (commentId: string) => void;
 *   onReact?: (type: CommentReactionType | null) => void;
 *   onReply?: () => void;
 *   onEdit?: () => void;
 *   onDelete?: () => void;
 *   onAttachmentDelete?: (fileId: string) => void;
 * }}
 */
export default function Comment({
  comment,
  beingDeleted = false,
  attachmentBeingDeleted = null,
  hasFocus = false,
  focusToken = 0,
  canModify,
  onFocus = noop,
  onReact = noop,
  onReply = noop,
  onEdit = noop,
  onDelete = noop,
  onAttachmentDelete = noop,
}) {
  const { confirm } = useConfirm();
  const [bgOpacity, setBgOpacity] = useState(hasFocus ? 1 : 0);
  const bgInterval = useInterval(() => setBgOpacity((bgOpacity) => Math.max(0, bgOpacity - 0.02)), 100);

  /**
   * Confirms the deletion of the comment.
   */
  const confirmDelete = useCallback(() => {
    confirm({
      title: _t('Delete comment'),
      message: _t('Are you sure you want to delete this comment? This action cannot be undone.'),
      onConfirm: onDelete,
    });
  }, [confirm, onDelete]);

  useEffect(() => {
    bgInterval.start();
    return bgInterval.stop;
  }, []);

  useEffect(() => {
    setBgOpacity(hasFocus ? 1 : 0);
  }, [hasFocus, focusToken]);

  const hasAttachments = comment.attachments.length > 0;
  const hasNotifiedPeople = comment.notifiedPeopleCount > 0;

  const linearGradient = `linear-gradient(to bottom, rgba(206,205,255,${bgOpacity}) 0%,rgba(206,205,255,0) 300px)`;

  return (
    <Collapse in={!beingDeleted} transitionDuration={600} className="w-full">
      <Box style={{ background: linearGradient }} className="group/comment border-t border-t-neutral-200 py-4">
        <Box className="gap-x-4 md:grid md:grid-cols-[40px_1fr]">
          <Box className="hidden md:block">
            <Avatar size={40} label={comment.author.fullName} />
          </Box>
          <Stack spacing={16}>
            <Group position="apart" align="center">
              <Text fz={15} lh={18 / 15} weight={500}>
                {comment.author.fullName}
              </Text>
              <Group spacing={16}>
                <CommentTimestamp createdAt={comment.createdAt} />
                {hasAttachments && (
                  <CommentAttachmentsCount commentId={comment.commentId} count={comment.attachments.length} />
                )}
                {hasNotifiedPeople && (
                  <CommentNotifiedPeopleCount commentId={comment.commentId} count={comment.notifiedPeopleCount} />
                )}
                <CommentOptions
                  commentId={comment.commentId}
                  canDelete={canModify}
                  canEdit={canModify}
                  onEdit={onEdit}
                  onDelete={confirmDelete}
                />
              </Group>
            </Group>

            {comment.reply && (
              <CommentReply
                author={comment.reply.author}
                excerpt={comment.reply.excerpt}
                onClick={() => onFocus(comment.reply.commentId)}
              />
            )}

            <FormattedTextDisplay pr={40} text={comment.text} />

            <CommentControls
              commentId={comment.commentId}
              reactions={comment.reactions}
              onReply={onReply}
              onReact={onReact}
            />

            {hasAttachments && (
              <Box pt={16}>
                <AttachmentsSimpleGrid
                  attachments={comment.attachments}
                  attachmentBeingDeleted={attachmentBeingDeleted}
                  canDelete={canModify}
                  onDelete={onAttachmentDelete}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Collapse>
  );
}
