import { Box, Group, Overlay, Skeleton } from '@mantine/core';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import panic from 'errors/Panic';
import { useEffect, useMemo, useState } from 'react';
import FileTypeIcon from './FileTypeIcon';
import { IconEyeOff } from '@tabler/icons';
import ImageExpandIcon from 'components/icons/ImageExpandIcon';
import { noop } from 'lodash';

/**
 * Displays a file thumbnail.
 *
 * @param {{
 *   fileId: string;
 *   fileType: string;
 *   width: number;
 *   height: number;
 *   menuOpacity: string;
 *   onOpenSlider: () => void;
 *   inList?: boolean;
 *   allowedTypes?: string[];
 * }}
 */
export default function FileThumbnail({
  fileId,
  fileType,
  width,
  height,
  menuOpacity,
  onOpenSlider = noop,
  inList = false,
  allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml', 'image/bmp'],
}) {
  const { getFileThumbnail } = useFileManager();
  const [src, setSrc] = useState(null);
  const hasThumbnail = useMemo(() => allowedTypes.includes(fileType), [allowedTypes, fileType]);

  useEffect(() => {
    setSrc(null);

    if (hasThumbnail) {
      getFileThumbnail({ fileId, size: '96x96' })
        .then((blob) => setSrc(URL.createObjectURL(blob)))
        .catch(panic);
    }
  }, [fileId]);

  useEffect(() => {
    return () => {
      if (src) {
        URL.revokeObjectURL(src);
      }
    };
  }, [src]);

  if (hasThumbnail && !src) {
    return <Skeleton height={height} width={width} radius={0} />;
  }

  const overlayIcon = hasThumbnail ? (
    <ImageExpandIcon width={24} height={24} stroke="#FFF" />
  ) : (
    <IconEyeOff width={24} height={24} stroke={1} color="#FFF" />
  );

  const thumbnail = hasThumbnail ? (
    <Group w={width} h={height}>
      <img src={src} alt="File thumbnail" className="h-full w-full object-cover" width={width} height={height} />
    </Group>
  ) : (
    <FileTypeIcon type={fileType} width={inList ? width : 36} height={inList ? width : 36} />
  );

  const overlayCursor = hasThumbnail ? 'cursor-pointer' : 'cursor-default';
  const wrapperRadius = inList ? 'rounded-lg' : 'rounded-s-lg';

  return (
    <Box className={`relative flex items-center justify-center overflow-hidden bg-neutral-200 ${wrapperRadius}`}>
      {thumbnail}
      <Overlay
        color="#000"
        zIndex={5}
        opacity={0.5}
        className={`flex items-center justify-center transition-opacity group-hover/attachment-list:opacity-50 group-hover/file-preview:opacity-50 ${menuOpacity} ${overlayCursor}`}
        onClick={hasThumbnail ? onOpenSlider : noop}
      >
        {overlayIcon}
      </Overlay>
    </Box>
  );
}
