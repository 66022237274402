/** @typedef {import('api/actions/task-get-list-action/task-get-list-action-response').TaskGetListActionResponse[number]} Task */

import { useApi } from 'api/ApiContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ADD_SUBTASK_PAGE_PATH, TASK_DETAIL_PAGE_PATH } from 'routes/paths';
import SubtaskRow from 'pages/projects/project-overview/project-table/task-table/SubtaskRow';
import DragIcon from 'components/icons/DragIcon';
import CollapseArrow from 'components/CollapseArrow';
import TaskIcon from 'components/icons/TaskIcon';
import TaskStatusBubble from 'pages/projects/project-overview/project-table/task-table/TaskStatusBubble';
import { _t } from 'lang';
import { Box, Button, Collapse, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import UserView from 'components/avatars/UserView';
import DueDateDisplay from 'pages/projects/project-overview/project-table/DueDateDisplay';
import AddIcon from 'components/icons/AddIcon';
import panic from 'errors/Panic';
import { isEmpty, noop } from 'lodash';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import TaskActions from 'components/task/TaskActions';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../StrictModeDroppable';
import DurationDisplay from 'components/DurationDisplay';
import Truncate from 'components/Truncate';
import { nanoid } from 'nanoid';
import { useDisclosure } from '@mantine/hooks';
import DashboardLayoutOverlay from 'layouts/dashboard-layout/DashboardLayoutOverlay';
import Preloader from 'components/Preloader';

/**
 * The table of tasks in the Project overview page.
 *
 * @param {{
 *   draggableTask: any,
 *   projectId: number;
 *   clientId: number,
 *   task: Task;
 *   index: number;
 *   showDeleted: boolean;
 *   onTaskDelete?: () => void;
 *   onTaskClose?: () => void;
 *   onTaskReopen?: () => void;
 *   onTaskRestore?: () => void;
 *   onSubtaskDelete?: (subtaskId: number) => void;
 *   onSubtaskClose?: (subtaskId: number) => void;
 *   onSubtaskReopen?: (subtaskId: number) => void;
 *   onSubtaskRestore?: (subtaskId: number) => void;
 *   onReorder?: () => void;
 *   onReady?: () => void;
 *   forceOpened?: boolean;
 *   hideTaskRow?: boolean;
 * }}
 */
export default function TaskTable({
  draggableTask,
  projectId,
  clientId,
  task,
  index,
  showDeleted,
  onTaskDelete = noop,
  onTaskClose = noop,
  onTaskReopen = noop,
  onTaskRestore = noop,
  onSubtaskDelete = noop,
  onSubtaskClose = noop,
  onSubtaskReopen = noop,
  onSubtaskRestore = noop,
  onReorder = noop,
  onReady = noop,
  forceOpened = false,
  hideTaskRow = false,
}) {
  const taskId = task.task_id;

  const { getAction, hasPermission } = useApi();
  const key = useMemo(() => `toolio.project.overview.task.${taskId}.opened`, [taskId]);
  const hasCreateTaskPermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );
  const [openedLocal, { toggle: toggleOpened, close: closeOpened }] = useLocalStorageDisclosure(key);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [subtasks, setSubtasks] = useState(null);

  const [state, setState] = useState({});
  const [dragging, { open: startDragging, close: stopDragging }] = useDisclosure(false);
  const [canDrop, setCanDrop] = useState(false);

  const opened = openedLocal || forceOpened;

  /**
   * Performs a partial update on the specified subtask.
   */
  const updateSubtask = useCallback(
    (taskId, update) =>
      setSubtasks((subtasks) =>
        subtasks.map((subtask) => (subtask.task_id === taskId ? { ...subtask, ...update } : subtask))
      ),
    [setSubtasks]
  );

  /**
   * Deletes the subtask.
   */
  const deleteSubtask = useCallback(
    (subtaskId) => {
      updateSubtask(subtaskId, { deleted_at: new Date().toISOString() });
      onSubtaskDelete(subtaskId);
    },
    [updateSubtask, onSubtaskDelete]
  );

  /**
   * Close the subtask.
   */
  const closeSubtask = useCallback(
    (subtaskId) => {
      updateSubtask(subtaskId, { closed_at: new Date().toISOString() });
      onSubtaskClose(subtaskId);
    },
    [updateSubtask, onSubtaskClose]
  );

  /**
   * Restores the subtask.
   */
  const restoreSubtask = useCallback(
    (subtaskId) => {
      updateSubtask(subtaskId, { deleted_at: null });
      onSubtaskRestore(subtaskId);
    },
    [updateSubtask, onSubtaskRestore]
  );

  /**
   * Reopens the subtask.
   */
  const reopenSubtask = useCallback(
    (subtaskId) => {
      updateSubtask(subtaskId, { closed_at: null });
      onSubtaskReopen(subtaskId);
    },
    [updateSubtask, onSubtaskReopen]
  );

  /**
   * Handles the drag end event.
   */
  const onDragEnd = (result) => {
    stopDragging();

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const target = state.columns[destination.droppableId].subtaskIds[destination.index];
    const currentTarget = document.querySelector(`[data-rbd-draggable-id=${target}]`);
    currentTarget.classList.remove('bg-neutral-50', 'opacity-20');

    const subtaskId = draggableId.split('-')[1];
    const newIndex = destination.index;

    const column = state.columns[source.droppableId];
    const newSubtaskIds = Array.from(column.subtaskIds);
    newSubtaskIds.splice(source.index, 1);
    newSubtaskIds.splice(newIndex, 0, draggableId);

    const newColumn = {
      ...column,
      subtaskIds: newSubtaskIds,
    };

    const subtasksAbove = newSubtaskIds.slice(0, newIndex);

    // count the deleted subtasks
    // -1 since we included the dropped subtask
    const deletedSubtasksCount =
      Object.keys(state.subtasks)
        .filter((subtaskKey) => subtasksAbove.includes(subtaskKey))
        .filter((subtaskKey) => !state.subtasks[subtaskKey]['content']['subtask']['ord']).length - 1 || 0;

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newColumn.id]: newColumn,
      },
    };

    const taskUpdateOrdAction = getAction('TaskUpdateOrdAction');
    taskUpdateOrdAction({
      parameters: { task_id: subtaskId },
      body: { new_ord: newIndex - deletedSubtasksCount },
    })
      .then(onReorder)
      .catch(panic);

    setState(newState);
  };

  useEffect(() => {
    if (subtasks !== null) {
      const groupedSubtasks = subtasks.reduce((acc, subtask) => {
        const id = `subtask-${subtask.task_id}`;
        return { ...acc, [id]: { id, content: { subtask } } };
      }, {});

      const subtaskIds = subtasks.map((subtask) => `subtask-${subtask.task_id}`);

      const draggable = {
        subtasks: groupedSubtasks,
        columns: {
          'column-3': {
            id: 'column-3',
            subtaskIds,
          },
        },
        columnOrder: ['column-3'],
      };

      setState(draggable);
    }
  }, [subtasks, showDeleted]);

  useEffect(() => {
    if (opened && subtasks === null) {
      const taskGetSubtasksAction = getAction('TaskGetSubtasksAction');

      taskGetSubtasksAction({
        parameters: { task_id: taskId },
        query: { sort: [{ by: 'ord', order: 'ASC' }] },
      })
        .then(setSubtasks)
        .catch(panic);
    }
  }, [opened, taskId, getAction, subtasks]);

  const loading = opened && subtasks === null;

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';
  const taskNameColor = loading ? 'text-neutral-500' : 'text-neutral-700';

  const taskNameClass = task.closed_at || task.deleted_at ? 'line-through' : '';

  const hasSubtasks = showDeleted
    ? task.all_subtask_count > 0
    : task.all_subtask_count - task.deleted_subtask_count > 0;

  const canCollapse = task.closed_at || task.deleted_at ? hasSubtasks : hasSubtasks || hasCreateTaskPermission;

  useEffect(() => {
    if (!isEmpty(state)) {
      onReady();
    }
  }, [state]);

  useEffect(() => {
    if ((task.closed_at || task.deleted_at) && task.all_subtask_count === 0) {
      closeOpened();
    }
  }, [task.closed_at, task.deleted_at, task.all_subtask_count]);

  return (
    <Draggable
      key={draggableTask.id}
      draggableId={draggableTask.id}
      index={index}
      isDragDisabled={task.deleted_at !== null}
    >
      {(provided, snapshot) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${
            snapshot.isDragging && !snapshot.isDropAnimating ? 'pl-6 pr-2 ' : 'border-t border-t-neutral-100'
          }`}
        >
          {dragging && <DashboardLayoutOverlay cursor={canDrop ? 'grabbing' : 'not-allowed'} />}
          <Box
            className={`bg-white ${
              snapshot.isDragging && !snapshot.isDropAnimating
                ? 'border-main-primary-dark-blue rounded-[8px] border border-primary-dark-blue bg-neutral-20 shadow-modal-shadow'
                : ''
            }`}
          >
            <div
              className={`group grid h-[56px] grid-cols-[44px_1fr_60px_128px_128px_122px_24px] items-center gap-2 px-2 text-[20px] text-neutral-700 hover:bg-neutral-20 ${
                hideTaskRow ? 'hidden' : ''
              }`}
            >
              {/* DRAG & DROP ICON */}
              <div
                className="flex cursor-grab items-center opacity-0 group-hover:opacity-100"
                {...provided.dragHandleProps}
              >
                {task.deleted_at ? <></> : <DragIcon />}
              </div>

              {/* TOGGLE ICON & TASK NAME */}
              <div className="flex flex-row items-center gap-2 py-3">
                <div
                  className={`h-6 w-6 ${canCollapse ? 'cursor-pointer' : ''}`}
                  onClick={() => {
                    if (canCollapse) {
                      toggleOpened();
                    }
                  }}
                >
                  {/* toggle icon */}
                  {canCollapse && (
                    <CollapseArrow opened={snapshot.isDragging && !snapshot.isDropAnimating ? false : opened} />
                  )}
                </div>
                <Group maw={590}>
                  <Link
                    maw={590}
                    to={TASK_DETAIL_PAGE_PATH.insert({ taskId })}
                    className={`${taskNameColor} group/task-name stroke-neutral-700 text-[15px] leading-[18px] transition-all hover:text-hyperlink`}
                  >
                    <Group noWrap spacing={4} maw={590}>
                      <TaskIcon width={24} height={24} stroke="" className="group-hover/task-name:!stroke-hyperlink" />
                      <Truncate className={`text-[15px] leading-[18px] ${taskNameClass}`} text={task.task_full_name} />
                      {loading && <Preloader ml={4} />}
                    </Group>
                  </Link>
                </Group>
              </div>

              {/* ASSIGNEE */}
              <div className="py-2">
                <UserView users={task.assignee} avatarSize={32} max={1} overlap={4} />
              </div>

              {/* LOGGED HOURS */}
              <DurationDisplay
                duration={task.minutes_total}
                className="py-2 pr-2 text-right text-[12px] leading-[16px] text-neutral-700"
              />

              {/* DUE DATE */}
              <div className="py-2 pr-2">
                <DueDateDisplay dueDate={task.end} />
              </div>

              {/* STATUS */}
              <div className="py-3">
                <TaskStatusBubble task={task} variant="small" />
              </div>

              {/* MENU */}
              <div className={`flex justify-end py-3 ${menuClasses}`}>
                <TaskActions
                  projectId={projectId}
                  task={task}
                  onMenuChange={setIsMenuOpened}
                  onTaskClose={onTaskClose}
                  onTaskDelete={onTaskDelete}
                  onTaskReopen={onTaskReopen}
                  onTaskRestore={onTaskRestore}
                  // TODO re-render the row after the time has been logged
                  onTaskLogTime={noop}
                  clientId={clientId}
                />
              </div>
            </div>

            <Collapse
              in={snapshot.isDragging && !snapshot.isDropAnimating ? false : (opened && !loading) || hideTaskRow}
            >
              <Box style={{ position: dragging ? 'relative' : 'static', zIndex: dragging ? 51 : 3 }}>
                <DragDropContext
                  onDragStart={startDragging}
                  onDragEnd={onDragEnd}
                  onDragUpdate={({ destination }) => setCanDrop(!!destination)}
                >
                  {!loading &&
                    !isEmpty(state) &&
                    state.columnOrder.map((columnId) => {
                      const column = state.columns[columnId];
                      const draggableSubtasks = column.subtaskIds.map((subtaskId) => state.subtasks[subtaskId]);

                      if (draggableSubtasks.length === 0) {
                        return (
                          <Group
                            pl={hideTaskRow ? 40 : 105}
                            h={56}
                            p={8}
                            key={nanoid()}
                            style={{ borderTop: '1px solid #E6E6E6' }}
                          >
                            {_t('No subtasks found!')}
                          </Group>
                        );
                      }

                      return (
                        <div key={column.id} style={{ backgroundColor: '#F2F2F2' }}>
                          <StrictModeDroppable droppableId={column.id}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={snapshot.isDraggingOver ? { pointerEvents: 'none' } : {}}
                              >
                                {draggableSubtasks
                                  .filter(({ content: { subtask } }) => showDeleted || !subtask.deleted_at)
                                  .map((draggableSubtask, index) => (
                                    <SubtaskRow
                                      key={draggableSubtask.id}
                                      draggableId={draggableSubtask.id}
                                      index={index}
                                      subtask={draggableSubtask.content.subtask}
                                      projectId={projectId}
                                      subtaskId={draggableSubtask.content.subtask.task_id}
                                      onTaskClose={() => closeSubtask(draggableSubtask.content.subtask.task_id)}
                                      onTaskDelete={() => deleteSubtask(draggableSubtask.content.subtask.task_id)}
                                      onTaskReopen={() => reopenSubtask(draggableSubtask.content.subtask.task_id)}
                                      onTaskRestore={() => restoreSubtask(draggableSubtask.content.subtask.task_id)}
                                      hideTaskRow={hideTaskRow}
                                      clientId={clientId}
                                    />
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </StrictModeDroppable>
                        </div>
                      );
                    })}
                </DragDropContext>
              </Box>
              {!loading && !isEmpty(state) && !task.deleted_at && !task.closed_at && hasCreateTaskPermission && (
                <div
                  className={`flex h-12 items-center border-t border-t-neutral-100 py-3 ${
                    hideTaskRow ? 'pl-10' : 'pl-[105px]'
                  }`}
                >
                  <Button
                    component={Link}
                    to={ADD_SUBTASK_PAGE_PATH.insert({ taskId })}
                    variant="link"
                    leftIcon={<AddIcon stroke="#38298B" />}
                  >
                    {_t('Add subtask')}
                  </Button>
                </div>
              )}
            </Collapse>
          </Box>
        </Box>
      )}
    </Draggable>
  );
}
