/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./invoice-create-action-body').InvoiceCreateActionBody} InvoiceCreateActionBody
 */

/**
 * @typedef {import('./invoice-create-action-response').InvoiceCreateActionResponse} InvoiceCreateActionResponse
 */

/**
 * The path of the InvoiceCreateAction action.
 */
export const invoiceCreateActionPath = new SweetPath('/api/finance/invoice', { path: '{param}' });

/**
 * Creates a contextualized InvoiceCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createInvoiceCreateAction(axios) {
  /**
   * The invoiceCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: InvoiceCreateActionBody } }
   *
   * @returns {Promise<InvoiceCreateActionResponse>}
   */
  function invoiceCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = invoiceCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return invoiceCreateAction;
}
