/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-bulk-change-cost-estimate-action-body').TimeLogBulkChangeCostEstimateActionBody} TimeLogBulkChangeCostEstimateActionBody
 */

/**
 * @typedef {import('./time-log-bulk-change-cost-estimate-action-response').TimeLogBulkChangeCostEstimateActionResponse} TimeLogBulkChangeCostEstimateActionResponse
 */

/**
 * The path of the TimeLogBulkChangeCostEstimateAction action.
 */
export const timeLogBulkChangeCostEstimateActionPath = new SweetPath(
  '/api/time_log/time_log/bulk_change_cost_estimate',
  { path: '{param}' }
);

/**
 * Creates a contextualized TimeLogBulkChangeCostEstimateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogBulkChangeCostEstimateAction(axios) {
  /**
   * The timeLogBulkChangeCostEstimateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TimeLogBulkChangeCostEstimateActionBody } }
   *
   * @returns {Promise<TimeLogBulkChangeCostEstimateActionResponse>}
   */
  function timeLogBulkChangeCostEstimateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = timeLogBulkChangeCostEstimateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return timeLogBulkChangeCostEstimateAction;
}
