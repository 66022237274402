/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./external-cost-get-action-body').ExternalCostGetActionBody} ExternalCostGetActionBody
 */

/**
 * @typedef {import('./external-cost-get-action-response').ExternalCostGetActionResponse} ExternalCostGetActionResponse
 */

/**
 * The path of the ExternalCostGetAction action.
 */
export const externalCostGetActionPath = new SweetPath('/api/finance/external_cost/{external_cost_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized ExternalCostGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createExternalCostGetAction(axios) {
  /**
   * The externalCostGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<ExternalCostGetActionResponse>}
   */
  function externalCostGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = externalCostGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return externalCostGetAction;
}
