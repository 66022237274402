/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */
/** @typedef {import('components/files/upload/types').IFileState} IFileState */

import InputWrapper from 'components/inputs/InputWrapper';
import { _t } from 'lang';
import { Box, Button, Group, Modal, Stack, Text, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import MoneyInput from 'components/inputs/MoneyInput';
import FormRow from 'components/forms/FormRow';
import UploadFiles from 'components/files/upload/UploadFiles';
import { useDisclosure } from '@mantine/hooks';
import formatPrice from 'utils/format-price';
import FormFooter from 'components/forms/FormFooter';
import useLoadingAction from 'hooks/use-loading-action';
import { noop } from 'lodash';
import DatePicker from 'components/inputs/DatePicker';

/**
 * The form for creating or editing a invoice request.
 *
 * @param {{
 *   initialValues: object,
 *   onSubmit: (values: object) => void | Promise<void>,
 *   costEstimate: CostEstimate,
 *   mainActionText: string,
 *   initialFiles: IFileState[],
 * }}
 */
export default function InvoiceRequestForm({
  initialValues,
  onSubmit,
  costEstimate,
  mainActionText,
  initialFiles = [],
}) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const [confirm, { open: openConfirm, close: closeConfirm }] = useDisclosure(false);

  const form = useForm({
    initialValues,

    validate: {
      poNumber: createValidator([required]),
      fileId: createValidator([required]),
      textInvoice: createValidator([required]),
      supplyDate: createValidator([required]),
    },
  });

  return (
    <>
      <form onSubmit={form.onSubmit(openConfirm)}>
        <Stack spacing={16}>
          <Stack py={16} spacing={16}>
            <span className="text-[20px] leading-[36px]">{_t('Upload a purchase order')}</span>
            <FormRow
              label={_t('Purchase order number')}
              input={<TextInput autoFocus {...form.getInputProps('poNumber')} />}
            />
            <FormRow
              label={_t('PO file or other confirmation')}
              input={<UploadFiles maxFiles={1} {...form.getInputProps('fileId')} initialFiles={initialFiles} />}
            />
          </Stack>

          <Stack py={16} spacing={16}>
            <span className="text-[20px] leading-[36px]">
              {mainActionText} {_t('a invoice request')}
            </span>
            <FormRow label={_t('Text on the invoice')} input={<TextInput {...form.getInputProps('textInvoice')} />} />
            <FormRow label={_t('Date of supply')} input={<DatePicker {...form.getInputProps('supplyDate')} />} />
            <FormRow
              label={_t('Invoiced amount')}
              input={<MoneyInput disabled currency={costEstimate.currency} {...form.getInputProps('invoicedAmount')} />}
            />
          </Stack>

          <Box pt={16}>
            <InputWrapper
              label={_t('Invoice description or note')}
              align="column"
              inputSize="full"
              input={<Textarea placeholder={_t('Enter note')} minRows={5} {...form.getInputProps('note')} />}
            />
          </Box>

          <FormFooter
            saveText={`${mainActionText} ${_t('invoice request')}`}
            skipCancelConfirmation={() => !form.isDirty()}
          />
        </Stack>
      </form>

      <Modal
        opened={confirm}
        onClose={loading ? noop : closeConfirm}
        centered
        padding={16}
        title={`${mainActionText} ${_t('an invoice')}`}
      >
        <Stack spacing={8}>
          <Text fz={15} lh={20 / 15}>
            {_t('You´re about to generate an invoice')}
            <br />
            {_t('Client:')} <b>{costEstimate.client.client_name}</b>
            <br />
            {_t('Cost estimate:')} <b>{costEstimate.cost_estimate_name}</b>
            <br />
            {_t('Amount:')} <b>{formatPrice(costEstimate.amount_after_discount, costEstimate.currency)}</b>
            <br />
            {_t('The date of supply:')} <b>{form.values.supplyDate?.toLocaleDateString()}</b>
            <br />
          </Text>
          <Text fz={15} lh={20 / 15} pt={32} pb={16}>
            {_t('I confirm that attached PO is for the stated amount.')}
          </Text>
          <Group position="right" pt={8} pb={16}>
            <Button onClick={closeConfirm} variant="link" w={76} h={36} disabled={loading}>
              {_t('Back')}
            </Button>
            <Button onClick={form.onSubmit(submit)} variant="primary" loading={loading}>
              {_t('Continue')}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
