import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for departments.
 *
 * @param {import('react').RefAttributes<HTMLInputElement> & import('@mantine/core').SelectProps}
 */
export default function DepartmentSelect({ ...props }) {
  const { getAction } = useApi();
  const departmentGetListAction = getAction('DepartmentGetListAction');

  return <DataSelect {...props} action={departmentGetListAction} labelProperty="name" valueProperty="department_id" />;
}
