/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { _t } from 'lang';
import { useMemo } from 'react';
import FinanceStatusBubble from 'components/FinanceStatusBubble';

/**
 * Displays the FinanceStatusBubble component with the correct status.
 *
 * @param {{
 *   costEstimate: CostEstimate;
 *   variant?: 'small' | 'default',
 * }}
 */
export default function CostEstimateStatusBubble({ costEstimate, variant = 'default' }) {
  const [status, color] = useMemo(() => {
    if (costEstimate.closed_at) {
      return [_t('Closed'), ''];
    }

    if (costEstimate.declined_at) {
      return [_t('Declined'), 'Orange'];
    }

    if (costEstimate.is_draft) {
      return [_t('Draft'), 'Draft'];
    }

    if (!costEstimate.approved_at) {
      return [_t('Waiting for approval'), ''];
    }

    if (!costEstimate.purchase_order) {
      return [_t('Waiting for PO'), ''];
    }

    if (!costEstimate.invoice) {
      return [_t('Waiting for invoice'), ''];
    }

    if (!costEstimate.closed_at) {
      return [_t('Invoiced'), ''];
    }

    return [_t('Unknown state'), ''];
  });

  return <FinanceStatusBubble status={status} color={color} variant={variant} />;
}
