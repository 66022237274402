import { Header } from '@mantine/core';
import SearchBar from 'layouts/dashboard-layout/headers/search/SearchBar';
import Timer from 'components/timer/Timer';
import { FEATURE_TOGGLE_STOPWATCH } from 'environment';
import { DashboardUserMenu } from './DashboardUserMenu';

/**
 * The header of the dashboard layout.
 */
export default function DashboardHeader() {
  return (
    <div className="h-full">
      <Header className="flex h-full items-center justify-between border-0 bg-complementary-indigo pb-2 pl-[28px] pr-[22px] pt-2">
        <SearchBar />
        {FEATURE_TOGGLE_STOPWATCH && <Timer />}
        <DashboardUserMenu />
      </Header>
    </div>
  );
}
