/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-get-action-body').ProjectGetActionBody} ProjectGetActionBody
 */

/**
 * @typedef {import('./project-get-action-response').ProjectGetActionResponse} ProjectGetActionResponse
 */

/**
 * The path of the ProjectGetAction action.
 */
export const projectGetActionPath = new SweetPath('/api/project/project/{project_id}', { path: '{param}' });

/**
 * Creates a contextualized ProjectGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectGetAction(axios) {
  /**
   * The projectGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<ProjectGetActionResponse>}
   */
  function projectGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = projectGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return projectGetAction;
}
