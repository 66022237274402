/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./price-list-get-list-action-body').PriceListGetListActionBody} PriceListGetListActionBody
 */

/**
 * @typedef {import('./price-list-get-list-action-response').PriceListGetListActionResponse} PriceListGetListActionResponse
 */

/**
 * The path of the PriceListGetListAction action.
 */
export const priceListGetListActionPath = new SweetPath('/api/finance/price_list', { path: '{param}' });

/**
 * Creates a contextualized PriceListGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPriceListGetListAction(axios) {
  /**
   * The priceListGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<PriceListGetListActionResponse>}
   */
  function priceListGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = priceListGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return priceListGetListAction;
}
