/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Checkbox']} CheckboxTheme */

/** @type {CheckboxTheme} */
export const Checkbox = {
  // todo: modify checkbox styles
  styles: {
    input: {
      '&:checked': { borderColor: '#2FBB67 !important', backgroundColor: '#2FBB67' },
      '&:disabled': { borderColor: '#B3B3B3 !important', backgroundColor: '#E6E6E6' },
    },
    icon: {
      color: '#B3B3B3',
      '&:checked': { color: '#FFFFFF !important' },
      '&:disabled': { color: '#B3B3B3 !important' },
    },
  },
};
