import { _t } from 'lang';
import { ADD_PROJECT_PAGE_PATH, FINANCE_ADD_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import { Link, useSearchParams } from 'react-router-dom';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import ClientTable from 'pages/finance/finance-project-overview/ClientTable';
import { Breadcrumbs, Button, Group, Menu, Skeleton } from '@mantine/core';
import EditIcon from 'components/icons/EditIcon';
import AddIcon from 'components/icons/AddIcon';
import { APP_HEADER_HEIGHT } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useEffect, useMemo, useState } from 'react';
import XIcon from 'components/icons/XIcon';

/**
 * Displays finance overview for projects.
 *
 * @see https://www.figma.com/file/CQQ1mMQRmo9H7dsJSejbRd/Toolio---Design?node-id=803%3A14021&mode=dev
 */
export default function FinanceProjectOverviewPage() {
  const { getAction } = useApi();
  const [searchParams] = useSearchParams();
  const focusedClientId = useMemo(
    () => (searchParams.get('clientId') ? Number(searchParams.get('clientId')) : null),
    [searchParams]
  );

  const [clientFilter, setClientFilter] = useState(focusedClientId);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const clientGetListAction = getAction('ClientGetListAction');
    setLoading(true);

    clientGetListAction({
      query: {
        filter: { 'is_active.eq': 1 },
        sort: [{ by: 'client_name', order: 'asc' }],
      },
    })
      .then(setClients)
      .catch(panic)
      .finally(() => setLoading(false));
  }, [getAction]);

  return (
    <DashboardLayout>
      <div className="bg-neutral-50 px-8 pb-8" style={{ minHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)` }}>
        <PageHeading
          heading={_t('Finance')}
          breadcrumbs={
            <Breadcrumbs>
              <span>{_t('Finance')}</span>
            </Breadcrumbs>
          }
          actionButton={
            <Button
              leftIcon={<AddIcon />}
              variant="primary"
              component={Link}
              to={FINANCE_ADD_COST_ESTIMATE_PAGE_PATH.original}
            >
              {_t('Cost estimate')}
            </Button>
          }
          menuDropdown={
            <Menu.Dropdown>
              <Menu.Item component={Link} to={ADD_PROJECT_PAGE_PATH.original} icon={<EditIcon fill="#4D4D4D" />}>
                {_t('Create project')}
              </Menu.Item>
            </Menu.Dropdown>
          }
        />
        {clientFilter && (
          <Group>
            <div className="text-[15px] leading-[18px]">
              {_t('Active filter')}: {clients?.find((client) => client.client_id === clientFilter)?.client_name}
            </div>
            <Button
              leftIcon={<XIcon />}
              onClick={() => {
                setClientFilter(null);
              }}
            >
              {_t('Remove filter')}
            </Button>
          </Group>
        )}
        <div className="flex flex-col gap-4 pb-4 pt-4">
          {loading ? (
            <>
              <Skeleton width="full" height={68} animate />
              <Skeleton width="full" height={68} animate />
              <Skeleton width="full" height={68} animate />
            </>
          ) : (
            (clients || [])
              .filter((client) => (clientFilter ? client.client_id === clientFilter : true))
              .map((client) => (
                <ClientTable key={client.client_id} clientId={client.client_id} clientName={client.client_name} />
              ))
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
