/** @typedef {import("components/comments/data/types").IComment} IComment */
/** @typedef {import('api/actions/comment-thread-get-list-action/comment-thread-get-list-action-response').CommentThreadGetListActionResponse[number]} IRawComment */

import { COMMENT_REACTION_TYPES } from 'components/comments/data/reactions';

/**
 * Parses a single comment from API response into IComment.
 *
 * @param {IRawComment} raw
 */
export default function parseComment(raw) {
  /** @type {IComment} */
  const comment = {
    commentId: raw.comment_id,
    author: {
      userId: raw.author.user_id,
      fullName: raw.author.full_name,
    },
    text: raw.text,
    excerpt: raw.excerpt,
    createdAt: new Date(raw.created_at),
    notifiedPeopleCount: raw.notified_count,
    reactions: (raw.reactions || []).map((reaction) => ({
      type: reaction.type,
      count: reaction.count,
      active: reaction.active,
    })),
    attachments: (raw.attachments || []).map((attachment) => ({
      fileId: attachment.file_id,
    })),
    notified: raw.notified,
  };

  if (raw.reply_to) {
    comment.reply = {
      commentId: raw.reply_to.comment_id,
      author: {
        userId: raw.reply_to.author.user_id,
        fullName: raw.reply_to.author.full_name,
      },
      excerpt: raw.reply_to.excerpt,
    };
  }

  for (const type of COMMENT_REACTION_TYPES) {
    if (!comment.reactions.find((reaction) => reaction.type === type)) {
      comment.reactions.push({ type, count: 0, active: false });
    }
  }

  return comment;
}
