import { Box, Tooltip } from '@mantine/core';
import CommentAddedIcon from 'components/icons/CommentAddedIcon';

/**
 * Tooltip used to display a tooltip with the comment in a cost estimate.
 *
 * @param {{
 *   comment: string,
 *   className?: string,
 *   width?: number,
 *   height?: number
 * }}
 */
export default function CommentTooltip({ comment, className, width = 24, height = 24 }) {
  return (
    <Box className={`text-left ${className}`}>
      <Tooltip withArrow width={200} multiline label={comment}>
        <Box w={width} h={height}>
          <CommentAddedIcon width={width} height={height} />
        </Box>
      </Tooltip>
    </Box>
  );
}
