/** @typedef {import('./part/CostEstimatePartData').CostEstimatePartData} CostEstimatePartData */
/** @typedef {import('./discount/DiscountData').DiscountData} DiscountData */

import { round } from 'lodash';

/**
 * Creates a new instance of CostEstimateData.
 *
 * @param {{
 *   costEstimateId: number,
 *   name: string,
 *   description: string,
 *   note: string,
 *   inHouse: CostEstimatePartData,
 *   outSource: CostEstimatePartData,
 *   discount: DiscountData,
 *   isDraft: boolean,
 *   currency: string,
 * }}
 */
export default function createCostEstimateData({
  costEstimateId,
  name = '',
  description = '',
  note = '',
  inHouse,
  outSource,
  discount,
  isDraft = true,
  currency = 'EUR',
} = {}) {
  // Price before discount.
  const subtotal = round(inHouse?.total + outSource?.total, 2);

  // Price after discount.
  const total = discount ? discount.apply(subtotal) : subtotal;

  // Price after discount minus price before discount.
  const discountAmount = round(subtotal - total, 2);

  // Sum of external costs.
  const externalCosts = round(inHouse?.externalCosts + outSource?.externalCosts, 2);

  return Object.freeze({
    costEstimateId,
    name,
    description,
    note,
    inHouse,
    outSource,
    discount,
    subtotal,
    total,
    discountAmount,
    externalCosts,
    isDraft,
    currency,
  });
}

/** @typedef {ReturnType<typeof createCostEstimateData>} CostEstimateData */
