/**
 * Icon used by the OrderedList control component.
 */
export default function IconOrderedList() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.0835 13.3337H3.75016V13.7503H2.91683V14.5837H3.75016V15.0003H2.0835V15.8337H4.5835V12.5003H2.0835V13.3337ZM2.91683 7.50033H3.75016V4.16699H2.0835V5.00033H2.91683V7.50033ZM2.0835 9.16699H3.5835L2.0835 10.917V11.667H4.5835V10.8337H3.0835L4.5835 9.08366V8.33366H2.0835V9.16699ZM6.25016 5.00033V6.66699H17.9168V5.00033H6.25016ZM6.25016 15.0003H17.9168V13.3337H6.25016V15.0003ZM6.25016 10.8337H17.9168V9.16699H6.25016V10.8337Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
