import CheckIcon from 'components/icons/CheckIcon';
import XIcon from 'components/icons/XIcon';
import { Box, Text } from '@mantine/core';

/**
 * PasswordRequirement component.
 *
 * @param {{
 *   meets: boolean,
 *   label: string,
 * }}
 */

// TODO: code review

/**
 * Password requirement component.
 */
export default function PasswordRequirement({ meets, label }) {
  return (
    <Text color={meets ? 'teal' : 'red'} sx={{ display: 'flex', alignItems: 'center' }} mt={7} size="sm">
      {meets ? <CheckIcon stroke="#087f5b" /> : <XIcon stroke="#c92a2a" />} <Box ml={10}>{label}</Box>
    </Text>
  );
}
