import { Text, Tooltip } from '@mantine/core';
import { _t } from 'lang';
import { useMemo } from 'react';

/**
 * Displays a relative timestamp of the comment with a tooltip showing the
 * exact date and time.
 *
 * @param {{
 *   createdAt: Date;
 * }}
 */
export default function CommentTimestamp({ createdAt }) {
  const diff = useMemo(() => {
    const now = new Date();

    const isThisYear = now.getFullYear() === createdAt.getFullYear();
    const isThisMonth = isThisYear && now.getMonth() === createdAt.getMonth();
    const isToday = isThisMonth && now.getDate() === createdAt.getDate();

    const timeString = createdAt.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h24',
    });

    const dateString = createdAt.toLocaleDateString(undefined, {
      year: isThisYear ? undefined : 'numeric',
      month: 'short',
      day: 'numeric',
    });

    if (isToday) {
      return _t('Today %s', timeString);
    }

    return `${dateString} ${timeString}`;
  }, [createdAt]);

  const precise = useMemo(() => {
    const dateTimeString = createdAt.toLocaleString(undefined, {
      hourCycle: 'h24',
    });

    return _t('Published at %s', dateTimeString);
  }, [createdAt]);

  return (
    <Tooltip
      withArrow
      arrowSize={10}
      color="neutral200"
      label={
        <Text fz={12} lh={16 / 12} color="neutral700">
          {precise}
        </Text>
      }
    >
      <Text color="neutral300" fz={12} lh={16 / 12}>
        {diff}
      </Text>
    </Tooltip>
  );
}
