/**
 * Formats a price to be displayed in the UI.
 *
 * @param {number} price
 * @param {string} currency
 * @param {string} [locale='sk-SK']
 */
export default function formatPrice(price, currency, locale = 'sk-SK') {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
  });

  return formatter.format(price);
}
