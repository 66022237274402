/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-login-action-body').UserLoginActionBody} UserLoginActionBody
 */

/**
 * @typedef {import('./user-login-action-response').UserLoginActionResponse} UserLoginActionResponse
 */

/**
 * The path of the UserLoginAction action.
 */
export const userLoginActionPath = new SweetPath('/api/user/login', { path: '{param}' });

/**
 * Creates a contextualized UserLoginAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserLoginAction(axios) {
  /**
   * The userLoginAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserLoginActionBody } }
   *
   * @returns {Promise<UserLoginActionResponse>}
   */
  function userLoginAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userLoginActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return userLoginAction;
}
