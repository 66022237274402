import { Stack } from '@mantine/core';
import NotProfitableAlert from 'pages/finance/cost-estimate/detail/alerts/warning/NotProfitableAlert';
import BudgetOverflowAlert from 'pages/finance/cost-estimate/detail/alerts/warning/BudgetOverflowAlert';
import ExternalCostsOverflowAlert from 'pages/finance/cost-estimate/detail/alerts/warning/ExternalCostsOverflowAlert';
import DiscountAlert from 'pages/finance/cost-estimate/detail/alerts/info/DiscountAlert';
import RatesChangedAlert from 'pages/finance/cost-estimate/detail/alerts/info/RatesChangedAlert';
import CustomerNoteAlert from 'pages/finance/cost-estimate/detail/alerts/note/CustomerNoteAlert';

/**
 * Displays the alerts of the cost estimate.
 */
export default function CostEstimateAlerts() {
  return (
    <Stack spacing={16} className="[&:not(:empty)]:mt-8">
      {/* Warning */}
      <NotProfitableAlert />
      <BudgetOverflowAlert />
      <ExternalCostsOverflowAlert />

      {/* Information */}
      <DiscountAlert />
      <RatesChangedAlert />

      {/* Note */}
      <CustomerNoteAlert />
    </Stack>
  );
}
