import { Collapse, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import ProjectInformationRow from 'components/project/ProjectInformationRow';
import UserNameWithAvatar from 'components/avatars/UserNameWithAvatar';
import UserView from 'components/avatars/UserView';
import { _t } from 'lang';
import { useProject } from 'providers/project/ProjectProvider';
import { useMemo } from 'react';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';

/**
 * Displays the project details section.
 */
export default function ProjectDetails() {
  const { project } = useProject();
  const key = useMemo(() => `toolio.project.detail.${project.project_id}.details.opened`, [project]);
  const [opened, { toggle }] = useLocalStorageDisclosure(key, false);

  return (
    <Stack spacing={16}>
      <GroupHeadline heading={_t('DETAILS')} opened={opened} setOpened={toggle} />
      <Collapse in={opened}>
        <Stack spacing={16}>
          <ProjectInformationRow label={_t('Client')} value={project.client.client_name} />
          <ProjectInformationRow
            label={_t('Project holder')}
            value={<UserNameWithAvatar name={project.owner.full_name} avatar={project.owner.avatar} size={32} />}
          />
          <ProjectInformationRow label={_t('Members')} value={<UserView max={5} users={project.members} size={32} />} />
          <ProjectInformationRow
            label={_t('Start date')}
            value={project.start ? new Date(project.start).toLocaleDateString() : '-'}
          />
          <ProjectInformationRow
            label={_t('Deadline (due date)')}
            value={project.start ? new Date(project.end).toLocaleDateString() : '-'}
          />
          <ProjectInformationRow label={_t('Type')} value={project.project_type} />
          <ProjectInformationRow label={_t('Project ID')} value={project.project_id} />
          <ProjectInformationRow
            label={_t('Author')}
            value={<UserNameWithAvatar name={project.author.full_name} avatar={project.author.avatar} size={32} />}
          />
          <ProjectInformationRow
            label={_t('Created')}
            value={project.start ? new Date(project.created_at).toLocaleDateString() : '-'}
          />
        </Stack>
      </Collapse>
    </Stack>
  );
}
