import { identity, isFunction } from 'lodash';
import { useState } from 'react';

/**
 * Hook to store immutable state with partial update support and debounce
 * effect.
 *
 * todo: move this to a separate package.
 *
 * @template TParams
 * @template TObject
 *
 * @param {TParams | (() => TParams)} initialData
 *
 * @param {{
 *   createFn?: (data: TParams) => TObject;
 * }}
 *
 * @return {[TObject, (update: Partial<TParams>) => void]}
 */
export default function useImmutable(initialData, { createFn = identity } = {}) {
  const [data, setData] = useState(() => createFn(isFunction(initialData) ? initialData() : initialData));

  /**
   * Updates one or more fields in the data.
   *
   * @type {(data: Partial<TParams>) => void}
   */
  const updateData = (update) => setData((data) => createFn({ ...data, ...update }));

  return [data, updateData];
}
