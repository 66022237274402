/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-update-action-body').TimeLogUpdateActionBody} TimeLogUpdateActionBody
 */

/**
 * @typedef {import('./time-log-update-action-response').TimeLogUpdateActionResponse} TimeLogUpdateActionResponse
 */

/**
 * The path of the TimeLogUpdateAction action.
 */
export const timeLogUpdateActionPath = new SweetPath('/api/time_log/update/{time_log_id}', { path: '{param}' });

/**
 * Creates a contextualized TimeLogUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogUpdateAction(axios) {
  /**
   * The timeLogUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TimeLogUpdateActionBody } }
   *
   * @returns {Promise<TimeLogUpdateActionResponse>}
   */
  function timeLogUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = timeLogUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return timeLogUpdateAction;
}
