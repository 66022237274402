/** @typedef {import('api/actions/client-get-list-action/client-get-list-action-response').ClientGetListActionResponse[number]} Client */

import { _t } from 'lang';
import DataTable from 'components/tables/DataTable';
import Avatar from 'components/avatars/Avatar';
import ClientStatusSwitch from 'components/inputs/status-switch/ClientStatusSwitch';
import { useApi } from 'api/ApiContext';
import { EDIT_CLIENT_PAGE_PATH } from 'routes/paths';
import { useMemo } from 'react';
import { Box, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import UserView from 'components/avatars/UserView';

/**
 * A row in the Clients table.
 *
 * @param {{
 *   data: Client;
 *   hover: boolean;
 * }}
 */
function ClientTableRow({ data: client, hover }) {
  return (
    <>
      <Group noWrap>
        <Box w={60}>
          <Avatar image={client.avatar} label={client.client_name} size={60} />
        </Box>
        <Link to={EDIT_CLIENT_PAGE_PATH.insert({ clientId: client.client_id })}>
          <div className="flex items-center transition-all hover:text-main-primary">
            <span className="text-[20px] leading-[20px]">{client.client_name}</span>
            {hover && <small className="ml-[10px] text-[12px] text-grey-500">(ID: {client.client_id})</small>}
          </div>
        </Link>
      </Group>
      <div>
        <div>{client.language}</div>
        <div>{client.currency}</div>
      </div>
      <div className="flex">
        <UserView
          max={9}
          users={client.members || []}
          editUsersPath={`${EDIT_CLIENT_PAGE_PATH.insert({ clientId: client.client_id })}?tab=members`}
        />
      </div>
      <ClientStatusSwitch clientId={client.client_id} isActive={client.is_active} fullName={client.client_name} />
    </>
  );
}

/**
 * The table in the Clients page.
 */
export default function ClientTable() {
  const { getAction } = useApi();
  const clientGetListAction = getAction('ClientGetListAction');

  const columns = useMemo(
    () => [
      { title: _t('Client name'), width: 6 },
      { title: _t('Language/Currency'), width: 3 },
      { title: _t('Members'), width: 3 },
      { title: _t('Status'), width: 1 },
    ],
    []
  );

  return (
    <DataTable
      action={clientGetListAction}
      columns={columns}
      rowKey={(client) => client.client_id}
      editLink={(client) => EDIT_CLIENT_PAGE_PATH.insert({ clientId: client.client_id })}
      RowComponent={ClientTableRow}
    />
  );
}
