/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse['groups'][number]['items'][number]} SearchObject */

import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  EDIT_CLIENT_PAGE_PATH,
  EDIT_SUPPLIER_PAGE_PATH,
  FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH,
  PROJECT_DETAIL_PAGE_PATH,
  TASK_DETAIL_PAGE_PATH,
} from 'routes/paths';

/**
 * Constructs the path to the detail page of the specified object. Considers the parent of the object when creating the link.
 */
export function getObjectDetailPath({ type, id, parent }) {
  switch (type) {
    case 'project':
      return PROJECT_DETAIL_PAGE_PATH.insert({ projectId: id });
    case 'task':
      return TASK_DETAIL_PAGE_PATH.insert({ taskId: id });
    case 'cost_estimate':
      return FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId: id });
    case 'client':
      return EDIT_CLIENT_PAGE_PATH.insert({ clientId: id });
    case 'supplier':
      return EDIT_SUPPLIER_PAGE_PATH.insert({ supplierId: id });
    case 'comment':
      return getObjectDetailPath(parent) + `?tab=comments&c=${id}`;
    case 'file':
      switch (parent.type) {
        case 'purchase_order':
          return getObjectDetailPath(parent.parent) + '?tab=purchase-order';
        case 'invoice':
          return getObjectDetailPath(parent.parent) + '?tab=invoice';
        case 'external_cost':
          return getObjectDetailPath(parent.parent) + `?tab=external-cost&externalCostId=${parent.id}`;
        case 'task':
          return getObjectDetailPath(parent) + `?tab=files&f=${id}`;
        case 'comment':
          return getObjectDetailPath(parent.parent) + `?tab=comments&c=${parent.id}`;
        default:
          return '';
      }
    // return getObjectDetailPath(parent) + `?tab=files&f=${id}`;
    // case 'external_cost':
    //   return getObjectDetailPath(parent) + `?tab=external-cost&externalCostId=${id}`;
    // case 'invoice':
    //   return getObjectDetailPath(parent) + '?tab=invoice';
    // case 'purchase_order':
    //   return getObjectDetailPath(parent) + '?tab=purchase-order';
    default:
      return '';
  }
}

/**
 * Link to the detail of the specified object.
 *
 * @param {SearchObject & {children?: React.ReactNode;}}
 */
export default function ObjectDetailLink({ type, id, parent, children }) {
  const to = useMemo(() => getObjectDetailPath({ type, id, parent }), [type, id, parent]);

  return (
    <Link to={to} state={{ showResults: false }}>
      {children}
    </Link>
  );
}
