/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./team-create-action-body').TeamCreateActionBody} TeamCreateActionBody
 */

/**
 * @typedef {import('./team-create-action-response').TeamCreateActionResponse} TeamCreateActionResponse
 */

/**
 * The path of the TeamCreateAction action.
 */
export const teamCreateActionPath = new SweetPath('/api/user/team/', { path: '{param}' });

/**
 * Creates a contextualized TeamCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTeamCreateAction(axios) {
  /**
   * The teamCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TeamCreateActionBody } }
   *
   * @returns {Promise<TeamCreateActionResponse>}
   */
  function teamCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = teamCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return teamCreateAction;
}
