/**
 * Icon used by the AlignCenter control component.
 */
export default function IconAlignCenter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="m 7.6774036,15.011205 h 5.0000004 v -1.6667 H 7.6774036 Z M 4.9474114,9.1832452 V 10.849876 H 14.947412 V 9.1832452 Z M 2.5,5 v 1.66667 h 15 V 5 Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
