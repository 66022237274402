/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Textarea']} TextareaTheme */

import { ErrorStyles } from 'theme/components/Inputs/shared/Error';

/** @type {TextareaTheme} */
export const Textarea = {
  styles: {
    error: ErrorStyles,
  },
};
