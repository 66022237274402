import { useParams } from 'react-router-dom';
import { TaskProvider, useTask } from 'providers/task/TaskProvider';
import { useEffect } from 'react';

/**
 * Watches the taskId path parameter and updates the task context.
 */
function TaskPathParamWatcher({ children }) {
  const { taskId } = useParams();
  const { setTaskId } = useTask();

  useEffect(() => {
    setTaskId(Number(taskId));
  }, [taskId]);

  return children;
}

/**
 * Provides the task id from the path to the task context.
 */
export default function TaskPathParamProvider({ children }) {
  const { taskId } = useParams();

  return (
    <TaskProvider initialTaskId={Number(taskId)}>
      <TaskPathParamWatcher>{children}</TaskPathParamWatcher>
    </TaskProvider>
  );
}
