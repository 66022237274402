import { Flex, Text } from '@mantine/core';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useReducer } from 'react';
import TimeEntriesTableHeader from './TimeEntriesTableHeader';
import TimeEntriesTableRow from './TimeEntriesTableRow';
import TimeEntriesTableTotalRow from './TimeEntriesTableTotalRow';
import TimeEntriesTableFooter from './TimeEntriesTableFooter';
import { useApi } from '../../../api/ApiContext';
import panic from '../../../errors/Panic';
import fullTextSearch from '../../../utils/full-text-search';
import { usePickTaskSubtask } from 'providers/pick-task-subtask/PickTaskSubtaskProvider';

/**
 * Returns an array containing the week days of a specific week.
 *
 * @param {number} shift - The number of weeks to shift from the current week. Negative values indicate past weeks, positive values indicate future weeks.
 * @returns {Date[]} The array of week days (Date objects) for the specified week.
 */
const getWeekDays = (shift = 0) => {
  const weekDays = [];
  const date = new Date();
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) + shift * 7;
  const weekStart = new Date(date.setDate(diff));
  for (let i = 0; i < 7; i++) {
    const newDate = new Date(weekStart);
    newDate.setDate(weekStart.getDate() + i);
    weekDays.push(newDate);
  }
  return weekDays;
};

/**
 * Returns a formatted time string.
 *
 * Converts minutes to a time string e.g. 90 minutes to 1h 30m.
 *
 * @param {number} minutes - The number of minutes.
 *
 * @returns {string} The formatted time string (e.g. 1h 30m).
 */
export const formattedTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = (minutes % 60).toFixed(0);
  return `${hours}h ${remainingMinutes}m`;
};

const initialState = {
  shift: 0,
  actualWeek: getWeekDays(0),
  dateRange: { start: getWeekDays(0)[0], end: getWeekDays(0)[6] },
  data: [],
  loading: false,
  search: '',
  weekendExists: false,
};

/**
 * Reducer function to update the state based on the given action.
 *
 * @param {object} state - The current state.
 * @param {object} action - The action object containing the type and payload.
 * @returns {object} - The updated state.
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SHIFT':
      return { ...state, shift: action.payload };
    case 'SET_ACTUAL_WEEK':
      return { ...state, actualWeek: action.payload };
    case 'SET_DATE_RANGE':
      return { ...state, dateRange: action.payload };
    case 'SET_DATA':
      return { ...state, data: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_SEARCH':
      return { ...state, search: action.payload };
    case 'SET_WEEKEND_EXISTS':
      return { ...state, weekendExists: action.payload };
    default:
      return state;
  }
};

/**
 * Represents a table component for displaying time entries.
 *
 * @param {{
 *   filters: {
 *     showWeekends: boolean,
 *     orderBy: string,
 *   },
 *   onChange: () => void,
 * }} props - The component props.
 *
 * @return {JSX.Element} - The time entries table component.
 */
const TimeEntriesTable = forwardRef(({ filters, onChange }, ref) => {
  const { getAction, userId } = useApi();
  const [state, dispatch] = useReducer(reducer, initialState, () => initialState);
  const userTaskActivityGetAction = getAction('UserTaskActivityGetAction');
  const userTaskActivityCreateAction = getAction('UserTaskActivityCreateAction');
  const { pickTaskSubtask } = usePickTaskSubtask();

  const fetchData = useCallback(
    () =>
      userTaskActivityGetAction({
        query: {
          filter: {
            'user_id.eq': userId,
            'start.gte': state.dateRange.start.toISOString().split('T')[0],
            'start.lte': state.dateRange.end.toISOString().split('T')[0],
          },
        },
      }),
    [userId, state.dateRange]
  );

  const refresh = useCallback(() => {
    if (state.dateRange) {
      fetchData()
        .then((data) => dispatch({ type: 'SET_DATA', payload: data }))
        .catch(panic);
      onChange();
    }
  }, [fetchData, state.dateRange]);

  useEffect(() => {
    if (state.dateRange) {
      dispatch({ type: 'SET_LOADING', payload: true });
      fetchData()
        .then((data) => dispatch({ type: 'SET_DATA', payload: data }))
        .catch(panic)
        .finally(() => dispatch({ type: 'SET_LOADING', payload: false }));
    }
  }, [state.dateRange]);

  useEffect(() => {
    dispatch({ type: 'SET_ACTUAL_WEEK', payload: getWeekDays(state.shift) });
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: { start: getWeekDays(state.shift)[0], end: getWeekDays(state.shift)[6] },
    });
  }, [state.shift]);

  useEffect(() => {
    // Check if the tasks contain weekend entries
    const weekendExists = state.data.tasks?.some((task) =>
      task.time_logs.some((timeLog) => {
        const date = new Date(timeLog?.start);
        return date.getDay() === 0 || date.getDay() === 6;
      })
    );

    dispatch({ type: 'SET_WEEKEND_EXISTS', payload: weekendExists });
  }, [state.data.tasks]);

  useImperativeHandle(ref, () => ({
    nextWeek: () => dispatch({ type: 'SET_SHIFT', payload: state.shift + 1 }),
    prevWeek: () => dispatch({ type: 'SET_SHIFT', payload: state.shift - 1 }),
    resetWeek: () => dispatch({ type: 'SET_SHIFT', payload: 0 }),
    refresh,
  }));

  /**
   * Handles the event when a task is added on submit.
   *
   * @param {Object} options - The options for handling the event.
   * @param {string} options.taskId - The id of the task to be added.
   */
  const handleOnAddTaskSubmit = ({ taskId }) => {
    userTaskActivityCreateAction({
      body: {
        task_id: taskId,
        points: 0.01,
        note: 'Added task/subtask from time entries table.',
      },
    })
      .then(refresh)
      .catch(panic);
  };

  /**
   * Function to handle on adding a task.
   * Opens something.
   *
   * @function handleOnAddTask
   * @returns {undefined}
   */
  const handleOnAddTask = () => {
    pickTaskSubtask({
      onPick: handleOnAddTaskSubmit,
      excludeTaskIds: state.data.tasks.map((task) => task.task_id),
    });
  };

  return (
    <>
      <Flex direction="column" w="100%" className="rounded-lg bg-white">
        <TimeEntriesTableHeader
          getWeekDays={getWeekDays}
          setShift={(value) => dispatch({ type: 'SET_SHIFT', payload: value })}
          actualWeek={state.actualWeek}
          filters={filters}
          shift={state.shift}
          setSearch={(value) => dispatch({ type: 'SET_SEARCH', payload: value })}
        />

        {!state.loading && state.data.tasks?.length !== 0 && (
          <>
            {state.data.tasks?.map((task) => {
              if (
                state.search &&
                !fullTextSearch(state.search, task.task_full_name) &&
                !fullTextSearch(state.search, task.project?.project_full_name) &&
                !fullTextSearch(state.search, task.client?.client_name)
              ) {
                return null;
              }

              return (
                <TimeEntriesTableRow
                  key={task.task_id}
                  task={task}
                  filters={filters}
                  actualWeek={state.actualWeek}
                  refresh={refresh}
                />
              );
            })}

            <TimeEntriesTableTotalRow filters={filters} tasks={state.data.tasks} actualWeek={state.actualWeek} />
          </>
        )}

        <TimeEntriesTableFooter
          setShift={(value) => dispatch({ type: 'SET_SHIFT', payload: value })}
          state={state}
          onAddTask={handleOnAddTask}
        />
      </Flex>

      {state.weekendExists && (
        <Flex pt={16}>
          <Text fz={15} lh="24px" align="right" w="100%" weight="400" display="flex">
            <Text lh="16px" className="text-notification-status-warning" align="right">
              *
            </Text>
            Includes weekend entries
          </Text>
        </Flex>
      )}
    </>
  );
});

TimeEntriesTable.displayName = 'TimeEntriesTable';
export default TimeEntriesTable;
