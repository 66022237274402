/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-get-me-action-body').UserGetMeActionBody} UserGetMeActionBody
 */

/**
 * @typedef {import('./user-get-me-action-response').UserGetMeActionResponse} UserGetMeActionResponse
 */

/**
 * The path of the UserGetMeAction action.
 */
export const userGetMeActionPath = new SweetPath('/api/user/me', { path: '{param}' });

/**
 * Creates a contextualized UserGetMeAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserGetMeAction(axios) {
  /**
   * The userGetMeAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<UserGetMeActionResponse>}
   */
  function userGetMeAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = userGetMeActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return userGetMeAction;
}
