import { Group, Switch } from '@mantine/core';
import { _t } from 'lang';

/**
 * Show projects where the current user is a project holder switch.
 *
 * @param {{value: boolean; onChange: (val: boolean) => void}} props
 */
export default function ShowMyProjectsSwitch({ value, onChange }) {
  return (
    <Group position="right">
      <Switch
        checked={value}
        onChange={(e) => {
          onChange(e.currentTarget.checked);
        }}
        label={_t('My projects')}
        labelPosition="left"
      />
    </Group>
  );
}
