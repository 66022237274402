/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { useApi } from 'api/ApiContext';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE, SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import InvoiceForm from 'components/forms/invoice-form/InvoiceForm';
import { Box, Group, Skeleton, Text } from '@mantine/core';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import Alert from 'components/Alert';
import { useEffect, useState } from 'react';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The contents of the edit invoice page.
 *
 * @param {{
 *   costEstimate: CostEstimate;
 * }}
 */
export default function EditInvoicePageContent({ costEstimate }) {
  const navigate = useNavigate();
  const { costEstimateId, invoiceId } = useParams();

  const { getAction } = useApi();
  const { getFileMetadata } = useFileManager();

  const [initialFiles, setInitialFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  const detailPath = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId });
  const detailTab = `${detailPath}?tab=invoice`;

  useEffect(() => {
    /**
     * Retrieves the data from the API.
     */
    async function fetchData() {
      const invoiceGetAction = getAction('InvoiceGetAction');

      setLoading(true);

      try {
        const data = await invoiceGetAction({ parameters: { invoice_id: invoiceId } });

        const fileId = data.file_id;
        const initialValues = {
          invoiceNumber: data.invoice_number,
          issued: new Date(data.issued),
          supplied: new Date(data.supplied),
          dueDate: new Date(data.due_date),
          note: data.note,
          fileId,
          amount: costEstimate.amount_after_discount,
        };

        setInitialValues(initialValues);

        if (fileId) {
          const file = await getFileMetadata({ fileId });
          setInitialFiles([
            {
              uuid: file.fileId,
              fileName: file.basename,
              fileType: file.mimeType,
              remoteId: fileId,
              progress: 1,
              finalized: true,
            },
          ]);
        }
      } catch (e) {
        if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
          setInitialValues(null);
        } else {
          panic(e);
        }
      }

      setLoading(false);
    }

    fetchData();
  }, [getAction, getFileMetadata, invoiceId, costEstimate.amount_after_discount]);

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ invoiceNumber, issued, supplied, dueDate, note, fileId }) => {
    const invoiceUpdateAction = getAction('InvoiceUpdateAction');

    return invoiceUpdateAction({
      parameters: {
        invoice_id: invoiceId,
      },
      body: {
        invoice_number: invoiceNumber,
        issued: new Date(issued).toLocaleDateString(),
        supplied: new Date(supplied).toLocaleDateString(),
        due_date: new Date(dueDate).toLocaleDateString(),
        note,
        file_id: fileId[0].fileId,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Invoice updated'),
          message: _t('Invoice has been updated successfully.'),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(detailTab, { replace: true });
      })
      .catch(panic);
  };

  if (costEstimate.closed_at) {
    return <Navigate to={detailTab} replace />;
  }

  if (!loading && !initialValues) {
    return <NotFoundPageContent />;
  }

  return (
    <div>
      <Box pt={24} pb={32}>
        <Alert severity="notification" primaryText={costEstimate.client.note_invoice ?? ''} />
      </Box>
      <Group mb={32} h={34} spacing={16} align="center">
        <Text fz={15} lh={18 / 15}>
          {_t('Status:')}
        </Text>
        <Group spacing={4} align="center">
          <Skeleton bg="#DCDCDC" w={12} h={12} radius={6} animate={false} />
          <Text fz={15} lh={18 / 15}>
            {/* TODO status */}
            {_t('Editing invoice')}
          </Text>
        </Group>
      </Group>
      <Box mt={16}>
        {!loading && (
          <InvoiceForm
            initialValues={initialValues}
            onSubmit={submit}
            currency={costEstimate.currency}
            initialFiles={initialFiles}
          />
        )}
      </Box>
    </div>
  );
}
