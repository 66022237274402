/** @typedef {import('api/actions/supplier-get-list-action/supplier-get-list-action-response').SupplierGetListActionResponse[number]} Supplier */

import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';

/**
 * Uses the supplier context.
 *
 * @param {number} [initialSupplierId]
 *
 * @returns {{
 *   supplierId: number | null,
 *   setSupplierId: (supplierId: number) => void,
 *   supplier: Supplier | null,
 *   loading: boolean,
 * }}
 */
export function useSupplier(initialSupplierId = null) {
  const [supplierId, setSupplierId] = useState(initialSupplierId);
  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(!!initialSupplierId);

  const { getAction } = useApi();
  const supplierGetListAction = getAction('SupplierGetListAction');

  // Fetch supplier when the supplierId changes.
  useEffect(() => {
    if (supplierId) {
      setSupplier(null);
      setLoading(true);

      supplierGetListAction({ query: { filter: { 'supplier_id.eq': supplierId } } })
        .then(([supplier]) => {
          if (!supplier) {
            throw new Error(_t('Unable to find the supplier.')); // This should never happen.
          }

          setSupplier(supplier);
        })
        .catch((error) => panic(error.message))
        .finally(() => setLoading(false));
    }
  }, [supplierId]);

  return { supplierId, setSupplierId, supplier, loading };
}
