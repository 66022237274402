import ApiError from 'api/api-error';
import { useApi } from 'api/ApiContext';
import LoginForm from 'components/forms/login-form/LoginForm';
import ToolioLogo from 'components/ToolioLogo';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HOME_PAGE_PATH, RESET_PASSWORD_PAGE_PATH } from 'routes/paths';
import { Group, Stack } from '@mantine/core';
import { INCORRECT_CREDENTIALS_ERROR_CODE, USER_DEACTIVATED_ERROR_CODE } from 'utils/constants';

/**
 * Login page content.
 */
export default function LoginContent() {
  const { login, workspaceConfig } = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const initialValues = useMemo(
    () => ({
      email: '',
      password: '',
      keepMeLogged: false,
    }),
    []
  );

  /**
   * Handles the request to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = async ({ email, password }) => {
    try {
      await login({ email, password });
      navigate(location.state?.from || HOME_PAGE_PATH.original);
    } catch (error) {
      if (error instanceof ApiError && error.code === INCORRECT_CREDENTIALS_ERROR_CODE) {
        setError(_t('Invalid email or password'));
      } else if (error instanceof ApiError && error.code === USER_DEACTIVATED_ERROR_CODE) {
        setError(_t('Account has been deactivated, contact administrator.'));
      } else {
        panic(error);
      }
    }
  };

  return (
    <div className="toolio-login-background flex h-full items-center">
      <div className="ml-auto mr-auto w-[278px] text-center">
        <Group
          pr={24}
          pb={2}
          pl={1}
          position="center"
          style={{ position: 'absolute', top: '105px', left: 0, right: 0 }}
        >
          <ToolioLogo className="ml-auto mr-auto" width="109px" height="80px" variant="dark" />
        </Group>
        <Stack spacing={16} h={284}>
          <Group position="center" style={{ fontSize: '22px', lineHeight: '32px', color: '#38298B' }}>
            {_t('Welcome to')} {workspaceConfig.name}
          </Group>
          <div className="w-[278px] text-left">
            <LoginForm onSubmit={submit} initialValues={initialValues} errorMessage={error} />
          </div>
          <Group
            position="center"
            style={{ color: '#38298B', fontSize: '15px', lineHeight: '20px', textDecorationLine: 'underline' }}
          >
            <Link to={RESET_PASSWORD_PAGE_PATH.original}>{_t('Forgot password?')}</Link>
          </Group>
        </Stack>
      </div>
    </div>
  );
}
