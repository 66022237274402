/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "SubaskClosed" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function SubaskClosedIcon({ className = '', height = 24, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Document type/Subask closed">
        <path
          id="Vector"
          d="M11 15.5L10.6464 15.8536C10.8336 16.0408 11.1343 16.0496 11.3322 15.8737L11 15.5ZM15.8322 11.8737C16.0386 11.6902 16.0572 11.3742 15.8737 11.1678C15.6902 10.9614 15.3742 10.9428 15.1678 11.1263L15.8322 11.8737ZM9.35355 13.1464C9.15829 12.9512 8.84171 12.9512 8.64645 13.1464C8.45118 13.3417 8.45118 13.6583 8.64645 13.8536L9.35355 13.1464ZM18 7.5V18.5H19V7.5H18ZM16.5 20H7.5V21H16.5V20ZM6 18.5V7.5H5V18.5H6ZM7.5 6H9V5H7.5V6ZM15 6H16.5V5H15V6ZM7.5 20C6.67157 20 6 19.3284 6 18.5H5C5 19.8807 6.11929 21 7.5 21V20ZM18 18.5C18 19.3284 17.3284 20 16.5 20V21C17.8807 21 19 19.8807 19 18.5H18ZM19 7.5C19 6.11929 17.8807 5 16.5 5V6C17.3284 6 18 6.67157 18 7.5H19ZM6 7.5C6 6.67157 6.67157 6 7.5 6V5C6.11929 5 5 6.11929 5 7.5H6ZM11.3322 15.8737L15.8322 11.8737L15.1678 11.1263L10.6678 15.1263L11.3322 15.8737ZM11 4H13V3H11V4ZM13 7H11V8H13V7ZM11 7C10.1716 7 9.5 6.32843 9.5 5.5H8.5C8.5 6.88071 9.61929 8 11 8V7ZM14.5 5.5C14.5 6.32843 13.8284 7 13 7V8C14.3807 8 15.5 6.88071 15.5 5.5H14.5ZM13 4C13.8284 4 14.5 4.67157 14.5 5.5H15.5C15.5 4.11929 14.3807 3 13 3V4ZM11 3C9.61929 3 8.5 4.11929 8.5 5.5H9.5C9.5 4.67157 10.1716 4 11 4V3ZM11.3536 15.1464L9.35355 13.1464L8.64645 13.8536L10.6464 15.8536L11.3536 15.1464Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
