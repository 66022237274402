/** @typedef {import('components/inputs/contact-person/types').IContactPerson} IContactPerson */

import { ActionIcon, Box, Menu } from '@mantine/core';
import Avatar from 'components/avatars/Avatar';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { _t } from 'lang';
import { Fragment } from 'react';

/**
 * Displays a single row of the contact person table.
 *
 * @param {{
 *   contactPerson: IContactPerson;
 *   onEdit: (contactPerson: IContactPerson) => void;
 *   onRemove: (contactPerson: IContactPerson) => void;
 * }}
 */
export default function ContactPersonRow({ contactPerson, onEdit, onRemove }) {
  return (
    <Fragment>
      <Avatar size={24} label={contactPerson.name} />
      <Box>{contactPerson.name}</Box>
      <Box pl={16}>{contactPerson.email}</Box>
      <Box pl={16}>{contactPerson.phone}</Box>
      <Menu>
        <Menu.Target>
          <ActionIcon variant="transparent" ml={16} w={24} h={24}>
            <OptionsDotsIcon width={24} height={24} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<EditIcon />} onClick={() => onEdit(contactPerson)}>
            {_t('Edit')}
          </Menu.Item>
          <Menu.Item icon={<DeleteIcon />} onClick={() => onRemove(contactPerson)}>
            {_t('Remove')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Fragment>
  );
}
