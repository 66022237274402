import { ceil, isNumber } from 'lodash';
import parse from 'parse-duration';

/**
 * Parses a duration string and returns the parsed duration in the specified format.
 *
 * @param {string | number} duration - The duration string to be parsed.
 *
 * @returns {number} The parsed duration in the specified format.
 */
export default function parseDuration(duration) {
  if (isNumber(duration)) {
    return duration;
  }

  duration = duration.replace(/,/g, '.').replace(/[^0-9hms.]/g, '');

  const hasSuffix = /[hms]$/.test(duration);
  const hasDecimal = /\./.test(duration);

  if (!hasSuffix) {
    const suffix = hasDecimal || duration <= 4 ? 'h' : 'm';
    duration += suffix;
  }

  return ceil(parse(duration, 'm'));
}
