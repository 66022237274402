import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { Fragment, useMemo } from 'react';
import { Box, Collapse, Skeleton, Stack } from '@mantine/core';
import CollapseArrow from 'components/CollapseArrow';
import { Link, useLocation } from 'react-router-dom';
import SidebarSubItem from './SidebarSubItem';
import { times } from 'lodash';
import { useSettingsSidebar } from './SettingsSidebarProvider';
import Truncate from 'components/Truncate';

/**
 * One item in the module sidebar.
 *
 * @param {{
 *   pagePath: string,
 *   modelSlug?: string,
 *   linkIcon: React.ReactNode,
 *   linkText: string,
 *   primaryKey?: string,
 *   titleKey?: string,
 *   data?: any[],
 * }}
 */
export default function SidebarItem({ pagePath, modelSlug, linkIcon, linkText, primaryKey, titleKey, data }) {
  const { loading } = useSettingsSidebar();
  const location = useLocation();

  const localStorageKey = useMemo(() => `toolio.settings.sidebar.opened.${linkText}`, [linkText]);
  const [opened, { toggle }] = useLocalStorageDisclosure(localStorageKey, false);

  const bgColor = location.pathname === pagePath ? '#D4D4D4' : '#FAFAFA';
  const count = data?.length ?? 0;

  return (
    <Fragment>
      <Box h={36} bg="#FAFAFA" style={{ position: 'sticky', top: '0px', borderRadius: '8px' }}>
        <Box
          h={32}
          bg={bgColor}
          className="flex items-center justify-between rounded-[8px] px-2 py-1 hover:bg-neutral-200"
        >
          <Box className="flex flex-row items-center">
            {count > 0 ? (
              <div onClick={toggle} className="cursor-pointer">
                <CollapseArrow opened={opened} stroke="#4D4D4D" />
              </div>
            ) : (
              <div className="h-6 w-6"></div>
            )}
            <div className="w-[270px]">
              <Link to={pagePath} className="flex w-full flex-row items-center gap-1 text-neutral-700">
                <div className="h-6 w-6">{linkIcon}</div>
                <Truncate className="text-[15px] leading-[18px]" text={linkText} />
              </Link>
            </div>
          </Box>

          {count > 0 && (
            <div className="pl-2">
              <span className="rounded-full bg-neutral-50 px-1 text-[12px] leading-[16px] text-neutral-500">
                {count}
              </span>
            </div>
          )}
        </Box>
      </Box>

      {count > 0 && (
        <Collapse in={opened}>
          <Stack pb={4} spacing={4}>
            {loading
              ? times(count, (num) => <Skeleton key={num} h={32} />)
              : (data || []).map((item) => {
                  const id = item[primaryKey];
                  const title = item[titleKey];

                  const key = `${modelSlug}-${id}`;
                  const path = `/settings/${modelSlug}/edit/${id}`;

                  return <SidebarSubItem key={key} title={title} path={path} />;
                })}
          </Stack>
        </Collapse>
      )}
    </Fragment>
  );
}
