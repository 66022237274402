import { Box, Group, Switch } from '@mantine/core';
import PriceDisplay from 'components/PriceDisplay';
import MoneyInput from 'components/inputs/MoneyInput';
import { _t } from 'lang';
import { useState } from 'react';

// TODO code review

/**
 * One row in the PriceListItemsDefinition.
 *
 * @param {{
 *    positionInCompanyId: number,
 *    positionName: string,
 *    projectBasedRate: number,
 *    flatFeeRate: number,
 *    isCustom: boolean,
 *    handleChange: (newVal: number, positionInCompanyId: number, key: string) => void,
 *    isDefaultPriceList: boolean,
 *    currency?: string,
 *    defaultProjectBasedRate: number,
 *    defaultFlatFeeRate: number,
 *  }}
 */
export default function PriceListItemRow({
  positionInCompanyId,
  positionName,
  projectBasedRate,
  flatFeeRate,
  isCustom,
  handleChange,
  isDefaultPriceList,
  currency = 'EUR',
  defaultProjectBasedRate,
  defaultFlatFeeRate,
}) {
  const [isChecked, setIsChecked] = useState(isDefaultPriceList || isCustom);

  const suffix = `${currency}/ ${_t('h')}`;
  const gridLayout = isDefaultPriceList ? 'grid-cols-[1fr_110px_110px]' : 'grid-cols-[1fr_110px_110px_128px]';

  return (
    <Box pt={8} pb={8} h={52} className={`grid items-center gap-2 ${gridLayout}`}>
      <Group pt={8} pb={8} style={{ fontSize: '15px', lineHeight: '18px', color: '#4D4D4D' }}>
        {positionName}
      </Group>
      {/* Project-based rate */}
      {isChecked ? (
        <div className="flex justify-end">
          <MoneyInput
            maw={200}
            value={projectBasedRate}
            onChange={(val) => handleChange(val, positionInCompanyId, 'projectBasedRate')}
            currency={suffix}
          />
        </div>
      ) : (
        <PriceDisplay className="pr-2 text-right" value={defaultProjectBasedRate} currency={suffix} />
      )}
      {/* Flat fee rate */}
      {isChecked ? (
        <div className="flex justify-end">
          <MoneyInput
            maw={200}
            value={flatFeeRate}
            onChange={(val) => handleChange(val, positionInCompanyId, 'flatFeeRate')}
            currency={suffix}
          />
        </div>
      ) : (
        <PriceDisplay className="pr-2 text-right" value={defaultFlatFeeRate} currency={suffix} />
      )}
      {!isDefaultPriceList ? (
        <Group position="right">
          <Switch
            checked={isCustom}
            onChange={(event) => {
              setIsChecked(event.currentTarget.checked);
              handleChange(event.currentTarget.checked, positionInCompanyId, 'isCustom');
            }}
          />
        </Group>
      ) : (
        <></>
      )}
    </Box>
  );
}
