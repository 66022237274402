import { useApi } from 'api/ApiContext';
import ProgressGroup from 'components/progress/ProgressGroup';
import { _t } from 'lang';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';

/**
 * One box in the financial summary.
 *
 * @param {{title: string, progressGroup: React.JSX.Element}}
 */
function FinancialSummaryBox({ title, progressGroup }) {
  return (
    <div className="w-full">
      <div className="flex h-9 flex-row items-center text-[15px] leading-[18px] text-neutral-700">{title}</div>
      {progressGroup}
    </div>
  );
}

/**
 * Displays the financial summary of the cost estimate.
 */
export default function CostEstimateFinancialSummary() {
  const { costEstimate, isApproved, isDeclined } = useCostEstimateDetail();
  const { workspaceConfig } = useApi();

  if (!costEstimate.is_draft || isApproved) {
    return (
      <div className="grid grid-cols-4 gap-[64px] px-8 pt-6">
        <FinancialSummaryBox
          title={_t('Invoiced')}
          progressGroup={
            <ProgressGroup
              showNumbers={true}
              variant="negative"
              total={isDeclined ? 0 : costEstimate.budget}
              part={costEstimate.invoiced_sum}
              currency={workspaceConfig.currency}
            />
          }
        />
        <FinancialSummaryBox
          title={_t('Budget')}
          progressGroup={
            <ProgressGroup
              showNumbers={true}
              variant="positive"
              total={isDeclined ? 0 : costEstimate.budget}
              part={costEstimate.external_cost_sum_actual}
              currency={workspaceConfig.currency}
            />
          }
        />
        <FinancialSummaryBox
          title={_t('External costs')}
          progressGroup={
            <ProgressGroup
              showNumbers={true}
              variant="positive"
              total={isDeclined ? 0 : costEstimate.budget_external}
              part={costEstimate.external_cost_sum_actual}
              currency={workspaceConfig.currency}
            />
          }
        />
      </div>
    );
  }

  return <></>;
}
