/**
 * The row for the  details in the cost estimate tab pages.
 *
 * @param {{title: string, value: string|React.JSX.Element}}
 */
export default function TabDetailRow({ title, value }) {
  return (
    <div className="grid h-6 grid-cols-[240px_464px] items-center gap-4 text-[15px] leading-[18px] text-neutral-700">
      <div>{title}</div>
      <div>{value}</div>
    </div>
  );
}
