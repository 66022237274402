/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Menu']} MenuTheme */

/** @type {MenuTheme} */
export const Menu = {
  defaultProps: {
    position: 'bottom-end',
  },
  styles: () => ({
    dropdown: {
      borderRadius: '8px',
      padding: '0 !important',
      boxShadow: '8px 8px 16px 0px rgba(0, 0, 0, 0.10)',
    },
    item: {
      padding: '8px 12px !important',
      borderRadius: '0 !important',
    },
    divider: {
      margin: '0 !important',
    },
    itemLabel: {
      fontSize: '14px !important',
      lineHeight: '16px !important',
    },
    itemIcon: {
      width: '16px !important',
      height: '16px !important',
    },
  }),
};
