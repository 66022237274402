/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-get-my-list-action-body').TimeLogGetMyListActionBody} TimeLogGetMyListActionBody
 */

/**
 * @typedef {import('./time-log-get-my-list-action-response').TimeLogGetMyListActionResponse} TimeLogGetMyListActionResponse
 */

/**
 * The path of the TimeLogGetMyListAction action.
 */
export const timeLogGetMyListActionPath = new SweetPath('/api/time_log/my_time_logs', { path: '{param}' });

/**
 * Creates a contextualized TimeLogGetMyListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogGetMyListAction(axios) {
  /**
   * The timeLogGetMyListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<TimeLogGetMyListActionResponse>}
   */
  function timeLogGetMyListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = timeLogGetMyListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return timeLogGetMyListAction;
}
