/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Stop" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function StopIcon({ className = '', height = 24, width = 24, fill = '#5351CE' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="stop">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM11.948 7.25H12.052C12.9505 7.24997 13.6997 7.24995 14.2945 7.32991C14.9223 7.41432 15.4891 7.59999 15.9445 8.05546C16.4 8.51093 16.5857 9.07773 16.6701 9.70552C16.7501 10.3003 16.75 11.0495 16.75 11.948V12.052C16.75 12.9505 16.7501 13.6997 16.6701 14.2945C16.5857 14.9223 16.4 15.4891 15.9445 15.9445C15.4891 16.4 14.9223 16.5857 14.2945 16.6701C13.6997 16.7501 12.9505 16.75 12.052 16.75H11.948C11.0495 16.75 10.3003 16.7501 9.70552 16.6701C9.07773 16.5857 8.51093 16.4 8.05546 15.9445C7.59999 15.4891 7.41432 14.9223 7.32991 14.2945C7.24995 13.6997 7.24997 12.9505 7.25 12.052V11.948C7.24997 11.0495 7.24995 10.3003 7.32991 9.70552C7.41432 9.07773 7.59999 8.51093 8.05546 8.05546C8.51093 7.59999 9.07773 7.41432 9.70552 7.32991C10.3003 7.24995 11.0495 7.24997 11.948 7.25ZM9.90539 8.81654C9.44393 8.87858 9.24643 8.9858 9.11612 9.11612C8.9858 9.24643 8.87858 9.44393 8.81654 9.90539C8.75159 10.3884 8.75 11.036 8.75 12C8.75 12.964 8.75159 13.6116 8.81654 14.0946C8.87858 14.5561 8.9858 14.7536 9.11612 14.8839C9.24643 15.0142 9.44393 15.1214 9.90539 15.1835C10.3884 15.2484 11.036 15.25 12 15.25C12.964 15.25 13.6116 15.2484 14.0946 15.1835C14.5561 15.1214 14.7536 15.0142 14.8839 14.8839C15.0142 14.7536 15.1214 14.5561 15.1835 14.0946C15.2484 13.6116 15.25 12.964 15.25 12C15.25 11.036 15.2484 10.3884 15.1835 9.90539C15.1214 9.44393 15.0142 9.24643 14.8839 9.11612C14.7536 8.9858 14.5561 8.87858 14.0946 8.81654C13.6116 8.75159 12.964 8.75 12 8.75C11.036 8.75 10.3884 8.75159 9.90539 8.81654Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
