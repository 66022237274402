import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';

/**
 * One item in the app sidebar.
 *
 * @param {{
 *   pagePath: string,
 *   linkIcon: React.ReactNode,
 *   base: string,
 *   activeStrokeColor: string,
 *   activeFillColor: string,
 * }}
 */
export default function AppSidebarItem({ pagePath, linkIcon, base, activeStrokeColor, activeFillColor }) {
  const match = useMatch(`${base}/*`);

  const activeClasses = match ? 'bg-white rounded-[50%] text-center' : 'opacity-100';

  return (
    <NavLink to={pagePath}>
      <div className={`flex h-[36px] w-[36px] items-center justify-center  ${activeClasses}`}>
        {React.cloneElement(linkIcon, {
          stroke: match ? activeStrokeColor : linkIcon.props.stroke,
          fill: match ? activeFillColor : linkIcon.props.fill,
        })}
      </div>
    </NavLink>
  );
}
