import { Text } from '@mantine/core';
import { _t } from 'lang';
import { useMemo } from 'react';

/**
 * Displays the specified duration in a human-readable format. The duration is
 * expected to be in minutes.
 *
 * @param {import('@mantine/core').TextProps & {
 *   duration: number;
 *   showMinutes?: boolean;
 * }}
 */
export default function DurationDisplay({ duration, showMinutes = false, ...props }) {
  const hours = useMemo(() => Math.floor(duration / 60), [duration]);
  const minutes = useMemo(() => duration % 60, [duration]);

  const text = useMemo(() => {
    if (!showMinutes) {
      return _t('%d hrs.', hours);
    }

    return _t('%dh %dm', hours, minutes);
  }, [showMinutes, hours, minutes]);

  return <Text {...props}>{text}</Text>;
}
