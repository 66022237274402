/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-deactivate-action-body').UserDeactivateActionBody} UserDeactivateActionBody
 */

/**
 * @typedef {import('./user-deactivate-action-response').UserDeactivateActionResponse} UserDeactivateActionResponse
 */

/**
 * The path of the UserDeactivateAction action.
 */
export const userDeactivateActionPath = new SweetPath('/api/user/{user_id}/deactivate', { path: '{param}' });

/**
 * Creates a contextualized UserDeactivateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserDeactivateAction(axios) {
  /**
   * The userDeactivateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserDeactivateActionBody } }
   *
   * @returns {Promise<UserDeactivateActionResponse>}
   */
  function userDeactivateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userDeactivateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return userDeactivateAction;
}
