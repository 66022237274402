/**
 * Returns the dimensions of the image specified as base64.
 *
 * @param {string} base64
 *
 * @returns {Promise<{ width: number, height: number }>}
 */
export default function getImageDimensions(base64) {
  return new Promise((resolve) => {
    const image = new window.Image();

    image.onload = () =>
      resolve({
        width: image.width,
        height: image.height,
      });

    image.src = base64;
  });
}
