/** @typedef {import('api/actions/task-get-action/task-get-action-response').TaskGetActionResponse Task} */

import GroupedTimeEntriesTable from 'components/tables/time-log/GroupedTimeEntriesTable';
import { useTaskDetail } from '../context/TaskDetailProvider';
import { Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import { useState } from 'react';

/**
 * Displays the time logs for given task.
 *
 * @param {{refreshToken?: string}} props
 */
export default function TaskTimeLogsTab({ refreshToken }) {
  const { taskId } = useTaskDetail();
  const [number, setNumber] = useState(-1);

  return (
    <Stack spacing={16}>
      <GroupHeadline heading={_t('Time log')} collapsible={false} loadingNumber={number === -1} number={number} />
      <GroupedTimeEntriesTable taskId={taskId} withHeadline onLoaded={setNumber} refreshToken={refreshToken} />
    </Stack>
  );
}
