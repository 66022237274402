/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Supplier" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function SupplierIcon({ className = '', height = 24, width = 24, fill = '#999999' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 16H13.5H13ZM13 8H12.5V8L13 8ZM17.2929 7.29289L17.6464 6.93934L17.6464 6.93934L17.2929 7.29289ZM20.7071 10.7071L21.0607 10.3536L20.7071 10.7071ZM4 5.5H12V4.5H4V5.5ZM12.5 6V16H13.5V6H12.5ZM3.5 16V6H2.5V16H3.5ZM5 16.5H4V17.5H5V16.5ZM12 16.5H9V17.5H12V16.5ZM2.5 16C2.5 16.8284 3.17157 17.5 4 17.5V16.5C3.72386 16.5 3.5 16.2761 3.5 16H2.5ZM12.5 16C12.5 16.2761 12.2761 16.5 12 16.5V17.5C12.8284 17.5 13.5 16.8284 13.5 16H12.5ZM12 5.5C12.2761 5.5 12.5 5.72386 12.5 6H13.5C13.5 5.17157 12.8284 4.5 12 4.5V5.5ZM4 4.5C3.17157 4.5 2.5 5.17157 2.5 6H3.5C3.5 5.72386 3.72386 5.5 4 5.5V4.5ZM13.5 16L13.5 8L12.5 8L12.5 16H13.5ZM14 7.5H16.5858V6.5H14V7.5ZM20.5 11.4142V16H21.5V11.4142H20.5ZM16.9393 7.64645L20.3536 11.0607L21.0607 10.3536L17.6464 6.93934L16.9393 7.64645ZM15 16.5H14V17.5H15V16.5ZM20 16.5H19V17.5H20V16.5ZM21.5 11.4142C21.5 11.0164 21.342 10.6349 21.0607 10.3536L20.3536 11.0607C20.4473 11.1544 20.5 11.2816 20.5 11.4142H21.5ZM16.5858 7.5C16.7184 7.5 16.8456 7.55268 16.9393 7.64645L17.6464 6.93934C17.3651 6.65804 16.9836 6.5 16.5858 6.5V7.5ZM12.5 16C12.5 16.8284 13.1716 17.5 14 17.5V16.5C13.7239 16.5 13.5 16.2761 13.5 16H12.5ZM20.5 16C20.5 16.2761 20.2761 16.5 20 16.5V17.5C20.8284 17.5 21.5 16.8284 21.5 16H20.5ZM13.5 8C13.5 7.72386 13.7239 7.5 14 7.5V6.5C13.1716 6.5 12.5 7.17157 12.5 8H13.5ZM8.5 17C8.5 17.8284 7.82843 18.5 7 18.5V19.5C8.38071 19.5 9.5 18.3807 9.5 17H8.5ZM7 18.5C6.17157 18.5 5.5 17.8284 5.5 17H4.5C4.5 18.3807 5.61929 19.5 7 19.5V18.5ZM5.5 17C5.5 16.1716 6.17157 15.5 7 15.5V14.5C5.61929 14.5 4.5 15.6193 4.5 17H5.5ZM7 15.5C7.82843 15.5 8.5 16.1716 8.5 17H9.5C9.5 15.6193 8.38071 14.5 7 14.5V15.5ZM18.5 17C18.5 17.8284 17.8284 18.5 17 18.5V19.5C18.3807 19.5 19.5 18.3807 19.5 17H18.5ZM17 18.5C16.1716 18.5 15.5 17.8284 15.5 17H14.5C14.5 18.3807 15.6193 19.5 17 19.5V18.5ZM15.5 17C15.5 16.1716 16.1716 15.5 17 15.5V14.5C15.6193 14.5 14.5 15.6193 14.5 17H15.5ZM17 15.5C17.8284 15.5 18.5 16.1716 18.5 17H19.5C19.5 15.6193 18.3807 14.5 17 14.5V15.5Z"
        fill={fill}
      />
    </svg>
  );
}
