import DataSelect from 'components/selects/DataSelect';

/**
 * The available languages.
 */
const LANGUAGES = [
  { code: 'sk', name: 'Slovak' },
  { code: 'en', name: 'English' },
  { code: 'cz', name: 'Czech' },
  { code: 'pl', name: 'Polish' },
  { code: 'hu', name: 'Hungarian' },
];

/**
 * Language select.
 *
 * @param {import('react').RefAttributes<HTMLInputElement> & import('@mantine/core').SelectProps}
 */
export default function LanguageSelect({ ...props }) {
  return <DataSelect {...props} action={() => Promise.resolve(LANGUAGES)} labelProperty="name" valueProperty="code" />;
}
