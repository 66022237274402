import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import ProcessMessageWithAction from 'components/ProcessMessageWithAction';
import CloseIcon from 'components/icons/CloseIcon';
import ConfirmIcon from 'components/icons/ConfirmIcon';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { useState } from 'react';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import { useDisclosure } from '@mantine/hooks';

/**
 * Contains information about the state of the Cost Estimate with buttons to
 * change it when the state is `draft`.
 */
export default function StateDraft() {
  const { costEstimate, setIsApproved, setIsDeclined } = useCostEstimateDetail();

  const [loading, setLoading] = useState(false);
  const [confirm, { close: closeConfirm, open: openConfirm }] = useDisclosure(false);

  const { getAction } = useApi();
  const costEstimateApproveAction = getAction('CostEstimateApproveAction');
  const costEstimateDeclineAction = getAction('CostEstimateDeclineAction');

  /**
   * Approves the cost estimate.
   */
  function approve() {
    if (!loading) {
      setLoading(true);

      costEstimateApproveAction({ parameters: { cost_estimate_id: costEstimate.cost_estimate_id } })
        .then(() => setIsApproved(true))
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }

  /**
   * Declines the cost estimate.
   */
  function decline() {
    if (!loading) {
      setLoading(true);

      costEstimateDeclineAction({ parameters: { cost_estimate_id: costEstimate.cost_estimate_id } })
        .then(() => setIsDeclined(true))
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <ProcessMessageWithAction
        heading={_t('Waiting for approval')}
        message={_t('Export cost estimate to PDF and send if to the client for approval or feedback. Change to approved by the client once cost estimate is approved or edit if changes are required. Decline it, if this project was canceled.')} // prettier-ignore
        buttons={
          <div className="flex flex-row items-center gap-4">
            <Button variant="secondary" leftIcon={<CloseIcon stroke="#38298B" />} onClick={openConfirm}>
              {_t('Decline')}
            </Button>
            <Button variant="primary" leftIcon={<ConfirmIcon stroke="#FFFFFF" />} onClick={approve} loading={loading}>
              {_t('Approve')}
            </Button>
          </div>
        }
      />
      <Modal centered opened={confirm} onClose={closeConfirm} padding={12} title={_t('Decline cost estimate')}>
        <Stack spacing={8}>
          <Text fz={15} lh={20 / 15}>
            {_t('Are you sure to decline this cost estimate?')}
          </Text>
          <Group spacing={16} position="right" pt={8} pb={16}>
            <Button variant="link" w={76} h={36} onClick={closeConfirm}>
              {_t('Cancel')}
            </Button>
            <Button variant="primary" onClick={decline}>
              {_t('Yes, decline')}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
