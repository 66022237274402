/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "WarningV2" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function WarningV2Icon({ className = '', height = 24, width = 24, fill = '#BF0D38' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Message type/warning_v2">
        <g id="Vector">
          <path
            d="M12.5 8C12.5 7.72386 12.2762 7.5 12 7.5C11.7239 7.5 11.5 7.72386 11.5 8V15C11.5 15.2761 11.7239 15.5 12 15.5C12.2762 15.5 12.5 15.2761 12.5 15V8Z"
            fill={fill}
          />
          <path
            d="M12.0341 17.0006C12.5706 17.0185 13 17.4591 13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17H12.01C12.0181 17 12.0261 17.0002 12.0341 17.0006Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5263 19.5L12.8661 4.5C12.4812 3.83333 11.5189 3.83333 11.134 4.5L2.47375 19.5C2.08885 20.1667 2.56998 21 3.33978 21H20.6603C21.4301 21 21.9112 20.1667 21.5263 19.5ZM13.7321 4C12.9623 2.66667 11.0378 2.66667 10.268 4L1.60772 19C0.837925 20.3333 1.80017 22 3.33978 22H20.6603C22.1999 22 23.1621 20.3333 22.3923 19L13.7321 4Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
