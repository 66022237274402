import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconBold from 'components/inputs/wysiwyg/controls/bold/IconBold';

/**
 * Used to make text bold.
 */
export default function ControlBold() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Bold" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleBold().run()}
        active={editor?.isActive('bold')}
      >
        <IconBold />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
