/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "ExportPdf" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function ExportPdfIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5094 3.55688L13.5094 6.55691C13.5102 7.39024 14.1761 8.09501 15.0094 8.08439C15.8427 8.07378 17.815 8.06576 18.5094 8.05691M11.5 20.0844L7.47622 20.0844C6.38101 20.0844 5.49995 19.2032 5.49996 18.1078L5.49997 5.08063C5.49997 3.96683 6.40976 3.05688 7.52338 3.05688L12.5094 3.05688L12.8088 3.05688C13.2623 3.05688 13.7006 3.21111 14.0517 3.49424L17.7704 6.49361C18.2341 6.86758 18.5033 7.43176 18.5038 8.03093L18.5039 8.08439L18.5039 12.2651M16.5 20.1793L16.5 15.2295M14 18.1138L15.7929 20.1793C16.1834 20.5698 16.8166 20.5698 17.2071 20.1793L19 18.1138"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
