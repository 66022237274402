/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-reopen-action-body').ProjectReopenActionBody} ProjectReopenActionBody
 */

/**
 * @typedef {import('./project-reopen-action-response').ProjectReopenActionResponse} ProjectReopenActionResponse
 */

/**
 * The path of the ProjectReopenAction action.
 */
export const projectReopenActionPath = new SweetPath('/api/project/project/{project_id}/reopen', { path: '{param}' });

/**
 * Creates a contextualized ProjectReopenAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectReopenAction(axios) {
  /**
   * The projectReopenAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ProjectReopenActionBody } }
   *
   * @returns {Promise<ProjectReopenActionResponse>}
   */
  function projectReopenAction({ headers = {}, parameters = {}, body } = {}) {
    const path = projectReopenActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return projectReopenAction;
}
