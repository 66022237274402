/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-react-action-body').CommentReactActionBody} CommentReactActionBody
 */

/**
 * @typedef {import('./comment-react-action-response').CommentReactActionResponse} CommentReactActionResponse
 */

/**
 * The path of the CommentReactAction action.
 */
export const commentReactActionPath = new SweetPath('/api/comment/comment_react/{comment_id}', { path: '{param}' });

/**
 * Creates a contextualized CommentReactAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentReactAction(axios) {
  /**
   * The commentReactAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CommentReactActionBody } }
   *
   * @returns {Promise<CommentReactActionResponse>}
   */
  function commentReactAction({ headers = {}, parameters = {}, body } = {}) {
    const path = commentReactActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return commentReactAction;
}
