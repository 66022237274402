/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Tabs']} TabsTheme */

/** @type {TabsTheme} */
export const Tabs = {
  styles: (theme) => {
    const primary = theme.fn.primaryColor();
    const [neutral300] = theme.colors.neutral300;
    const [neutral700] = theme.colors.neutral700;

    return {
      tabsList: {
        gap: '24px',
        borderBottom: `1px solid ${neutral300}`,
      },
      tab: {
        paddingTop: '16px',
        paddingBottom: '4px',
        borderBottom: '4px solid transparent',
        paddingLeft: '0',
        paddingRight: '0',
        marginBottom: '0',
        color: neutral700,
        transition: 'color 100ms ease, border-color 100ms ease, background-color 100ms ease',
        fontSize: '15px',
        lineHeight: '18px',
        '&:hover': {
          borderBottom: '4px solid transparent',
          backgroundColor: 'transparent',
          color: primary,
        },
        '&[data-active="true"]': {
          borderBottom: `4px solid ${primary}`,
          backgroundColor: 'transparent',
          color: primary,
        },
      },
    };
  },
};
