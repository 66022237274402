import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { DEPARTMENTS_PAGE_PATH } from 'routes/paths';
import { _t } from 'lang';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import { isEmpty } from 'lodash';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import DepartmentForm from 'components/forms/department-form/DepartmentForm';
import panic from 'errors/Panic';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the EditDepartmentPage.
 *
 * @param {{ departmentId: string }}
 */
function EditDepartmentImpl({ departmentId }) {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshDepartments } = useSettingsSidebar();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const departmentGetListAction = getAction('DepartmentGetListAction');

    setLoading(true);

    departmentGetListAction({
      query: {
        filter: { 'department_id.eq': departmentId },
        limit: 1,
      },
    })
      .then((data) => {
        if (data.length === 0) {
          setInitialValues({});
          return;
        }

        const department = data[0];

        const rawTeams = department.teams.map((team) => String(team.team_id));

        const initialValues = {
          departmentName: department.name,
          leaderId: String(department.leader.user_id),
          color: `#${department.color}`,
          avatar: department.avatar,
          teams: rawTeams,
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, departmentId]);

  if (!departmentId || (!loading && isEmpty(initialValues))) {
    return <NotFoundPageContent />;
  }

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ departmentName, leaderId, color, avatar }) => {
    const departmentUpdateAction = getAction('DepartmentUpdateAction');

    return departmentUpdateAction({
      parameters: { department_id: departmentId },
      body: {
        name: departmentName,
        color: color.replace(/^#/, ''),
        avatar,
        leader_id: leaderId,
      },
    })
      .then(() => {
        refreshDepartments();

        showNotification({
          title: _t('Department updated'),
          message: _t("Department '%s' has been updated successfully.", `${departmentName}`),
          color: SUCCESS_NOTIFICATION_COLOR,
        });
      })
      .catch((e) => {
        panic(e);

        navigate(DEPARTMENTS_PAGE_PATH.original);
      });
  };

  return (
    <>
      <PageHeading heading={_t('Edit department')} />
      {!loading && <DepartmentForm initialValues={initialValues} onSubmit={submit} />}
    </>
  );
}

/**
 * The Edit Department page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A26731
 */
export default function EditDepartmentPage() {
  const { departmentId } = useParams();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <EditDepartmentImpl key={departmentId} departmentId={departmentId} />
      </div>
    </DashboardLayout>
  );
}
