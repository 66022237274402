import { Progress } from '@mantine/core';

/**
 * Displays a progress bar colored according to the percentage of the consumed value.
 *
 * @param {{
 *   variant: 'positive' | 'negative' | 'constant',
 *   value: number
 * }}
 */
export default function ProgressBar({ variant, value }) {
  let barColor = '';

  if (variant === 'positive') {
    if (value < 100) {
      barColor = 'bg-complementary-positive';
    } else if (value === 100) {
      barColor = 'bg-complementary-action';
    } else {
      barColor = 'bg-complementary-danger';
    }
  } else if (variant === 'negative') {
    if (value <= 75) {
      barColor = 'bg-complementary-danger';
    } else if (value < 100) {
      barColor = 'bg-complementary-action';
    } else {
      barColor = 'bg-complementary-positive';
    }
  } else if (variant === 'constant') {
    barColor = 'bg-complementary-positive';
  }

  return (
    <Progress
      value={Math.min(100, value)}
      classNames={{ bar: barColor, root: 'bg-neutral-100 h-1 rounded-[4px] w-full transition-all' }}
    />
  );
}
