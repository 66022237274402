import { read, utils, writeFile } from 'xlsx';

/**
 * Formats the given column to be a number with two decimal places.
 *
 * @param {import('xlsx').WorkBook} wb
 * @param {number} col column to format
 */
function formatToDecimalColumn(wb, col) {
  const range = utils.decode_range(wb.Sheets['Sheet1']['!ref']);

  // skip header
  for (let row = range.s.r + 1; row <= range.e.r; row++) {
    const ref = utils.encode_cell({ r: row, c: col });
    if (wb.Sheets['Sheet1'][ref]) {
      wb.Sheets['Sheet1'][ref].t = 'n';
      wb.Sheets['Sheet1'][ref].z = '0.00';
    }
  }

  return wb;
}

/**
 * Converts CSV to XLSX and opens system window
 * so that the user can save the file.
 *
 * @param {{
 *  contents: string;
 *  fileName: string;
 * }}
 */
export function exportFile({ contents, fileName }) {
  let wb = read(contents, { type: 'string', cellDates: true, cellFormula: false, cellNF: true });

  // column 11 = L is the duration in hours
  wb = formatToDecimalColumn(wb, 11);

  writeFile(wb, fileName, { type: 'file', bookType: 'xlsx' });
}
