/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Breadcrumbs']} BreadcrumbsTheme */

/** @type {BreadcrumbsTheme} */
export const Breadcrumbs = {
  classNames: {
    root: 'font-normal text-main-primary',
    separator: 'text-neutral-700 text-[14px] leading-[14px] mx-2 mb-[-4px]',
    breadcrumb: 'text-[12px] leading-[16px]',
  },
};
