const smallText = 'text-[12px] leading-[16px]';
const normalText = 'text-[15px] leading-[18px]';
const largeText = 'text-[22px] leading-[24px]';
const extraLargeText = 'text-[40px] leading-[36px] font-light';

/**
 * Displays price with currency.
 *
 * @param {{
 *   value: number,
 *   currency?: string,
 *   precision?: number,
 *   size?: 'sm' | 'md' | 'lg' | 'xl' | '',
 *   className?: string,
 *   integralClassName?: string,
 *   decimalClassName?: string,
 * }}
 */
export default function PriceDisplay({
  value,
  currency = 'EUR',
  precision = 2,
  size = 'md',
  className = '',
  valueClassName = '',
  integralClassName = '',
  decimalClassName = '',
}) {
  const formatter = new Intl.NumberFormat('sk-SK', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  const [integral, decimal] = formatter.format(value).split(',');

  let integralText;
  let decimalText = normalText;

  if (size === 'sm') {
    integralText = smallText;
    decimalText = smallText;
  } else if (size === 'lg') {
    integralText = largeText;
  } else if (size === 'xl') {
    integralText = extraLargeText;
  } else {
    integralText = normalText;
  }

  return (
    <span className={className}>
      <span className={valueClassName}>
        {/* Integral value */}
        <span className={integralClassName}>
          <span className={integralText}>{integral}</span>
        </span>

        {/* Decimal value */}
        {precision > 0 && (
          <span className={decimalClassName}>
            <span className={decimalText}>,{decimal}</span>
          </span>
        )}
      </span>

      {/* Currency */}
      <span className="ml-[4px] text-[12px] leading-[16px]">{currency}</span>
    </span>
  );
}
