import { Box, Button, Group, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import WysiwygEditor from 'components/inputs/wysiwyg/WysiwygEditor';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from 'components/forms/FormFooter';
import FormRow from 'components/forms/FormRow';
import UploadFiles from 'components/files/upload/UploadFiles';
import MoneyInput from 'components/inputs/MoneyInput';
import PlusSmallIcon from 'components/icons/PlusSmallIcon';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useRef, useState } from 'react';
import UserTagSelect from 'components/selects/UserTagSelect';
import { noop } from 'lodash';
import DatePicker from 'components/inputs/DatePicker';
import { useTask } from 'providers/task/TaskProvider';
import { isBefore } from 'date-fns';

const defaultLabels = {
  name: _t('Task name'),
  description: _t('Description'),
  attachments: _t('Attachments'),
  start: _t('Start date'),
  deadline: _t('Deadline (Due date)'),
  assignee: _t('Assignee'),
  estimatedHours: _t('Estimated hours'),
};

/**
 * The Add/Edit task/subtask form.
 *
 * @param {{
 *   initialValues?: object,
 *   onSubmit?: (values: object) => void | Promise<void>,
 *   overrideLabels?: Partial<typeof defaultLabels>,
 *   withSubtasks?: boolean,
 *   withAttachments?: boolean,
 * }}
 */
export default function TaskForm({
  initialValues = {
    name: '',
    description: '',
    attachments: undefined,
    assignee: [],
    subtasks: [],
    start: new Date(),
    taskPrefix: '',
  },
  onSubmit = noop,
  overrideLabels = {},
  withSubtasks = false,
  withAttachments = false,
}) {
  const { project } = useTask();
  const labels = useMemo(() => ({ ...defaultLabels, ...overrideLabels }), [overrideLabels]);
  const [loading, submit] = useLoadingAction(onSubmit);
  const descriptionRef = useRef(null);

  const [displayStartTooltip, setDisplayStartTooltip] = useState(false);
  const [displayEndTooltip, setDisplayEndTooltip] = useState(false);

  const clientId = useMemo(() => project?.client.client_id, [project]);

  const form = useForm({
    initialValues,

    validate: {
      name: createValidator([required]),
    },
  });

  useEffect(() => {
    if (project && form.values.taskPrefix === '') {
      form.setFieldValue('taskPrefix', project.project_prefix);
    }
  }, [form.values.taskPrefix, project]);

  // close tooltips after 3 seconds
  useEffect(() => {
    if (displayStartTooltip) {
      setTimeout(() => {
        setDisplayStartTooltip(false);
      }, 3000);
    }
  }, [displayStartTooltip]);

  useEffect(() => {
    if (displayEndTooltip) {
      setTimeout(() => {
        setDisplayEndTooltip(false);
      }, 3000);
    }
  }, [displayEndTooltip]);

  return (
    <form onSubmit={form.onSubmit(() => submit({ ...form.values, description: descriptionRef.current?.getHTML() }))}>
      <Stack spacing={16}>
        <FormRow
          label={labels.name}
          input={
            <Group w="100%" className="grid grid-cols-[auto_1fr] gap-2">
              <Box>{form.values.taskPrefix}</Box>
              <TextInput autoFocus {...form.getInputProps('name')} />
            </Group>
          }
        />
        <FormRow
          label={labels.description}
          input={
            <WysiwygEditor clientId={clientId} initialContent={form.values.description} editorRef={descriptionRef} />
          }
        />
        {withAttachments && (
          <FormRow label={labels.attachments} input={<UploadFiles {...form.getInputProps('attachments')} />} />
        )}
        <FormRow
          label={labels.start}
          input={
            <Tooltip
              label={_t('Updated to match end date')}
              opened={displayStartTooltip}
              events={{ hover: false, focus: false, touch: false }}
              position="bottom"
              styles={{
                tooltip: {
                  backgroundColor: '#FFC700',
                },
              }}
            >
              <Box>
                <DatePicker
                  {...form.getInputProps('start')}
                  onChange={(val) => {
                    form.getInputProps('start').onChange(val);

                    const end = form.values.deadline ? new Date(form.values.deadline.toLocaleDateString()) : null;
                    const start = new Date(val.toLocaleDateString());

                    // deadline date should be after start date
                    if (form.values.deadline && isBefore(end, start)) {
                      form.setFieldValue('deadline', val);
                      setDisplayEndTooltip(true);
                    }
                  }}
                />
              </Box>
            </Tooltip>
          }
        />
        <FormRow
          label={labels.deadline}
          input={
            <Tooltip
              label={_t('Updated to match start date')}
              opened={displayEndTooltip}
              events={{ hover: false, focus: false, touch: false }}
              position="bottom"
              styles={{
                tooltip: {
                  backgroundColor: '#FFC700',
                },
              }}
            >
              <Box>
                <DatePicker
                  {...form.getInputProps('deadline')}
                  onChange={(val) => {
                    form.getInputProps('deadline').onChange(val);

                    const start = form.values.start ? new Date(form.values.start.toLocaleDateString()) : null;
                    const end = new Date(val.toLocaleDateString());

                    if (form.values.start && isBefore(end, start)) {
                      form.setFieldValue('start', val);
                      setDisplayStartTooltip(true);
                    }
                  }}
                />
              </Box>
            </Tooltip>
          }
        />
        <FormRow
          label={labels.assignee}
          input={
            <UserTagSelect
              fullWidth
              placeholder={_t('Add assignee')}
              clientId={clientId}
              {...form.getInputProps('assignee')}
            />
          }
        />
        <FormRow
          label={labels.estimatedHours}
          input={<MoneyInput precision={2} step={0.25} currency="hrs." {...form.getInputProps('estimatedHours')} />}
        />
        {withSubtasks && (
          <>
            <Stack py={18}>
              <Text fz={22} lh={32 / 22}>
                {_t('SUBTASKS')}
              </Text>
            </Stack>
            {form.values.subtasks.map(({ uuid }, i) => (
              <FormRow
                key={uuid}
                label={_t('Subtask name')}
                input={
                  <Group w="100%" className="grid grid-cols-[auto_1fr] gap-2">
                    <Box>{form.values.taskPrefix}</Box>
                    <TextInput {...form.getInputProps(`subtasks.${i}.title`)} />
                  </Group>
                }
                tooltipText={_t('Name of the subtask')}
              />
            ))}
            <Group position="left">
              <Button
                variant="link"
                leftIcon={<PlusSmallIcon height={24} width={24} />}
                onClick={() => form.insertListItem('subtasks', { title: '', uuid: nanoid() })}
              >
                {_t('Add subtask')}
              </Button>
            </Group>
          </>
        )}
      </Stack>

      <FormFooter
        loading={loading}
        skipCancelConfirmation={() => !form.isDirty() && !descriptionRef.current?.isDirty()}
      />
    </form>
  );
}
