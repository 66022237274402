import { round } from 'lodash';

/**
 * Creates a new instance of AgencyFeeData.
 *
 * @param {{
 *   amount: number,
 *   comment: string | null,
 * }}
 */
export default function createAgencyFeeData({ amount = 0, comment = null } = {}) {
  /**
   * Applies the agency fee to the given price.
   *
   * @param {number} price
   *
   * @returns {number}
   */
  function apply(price) {
    const multiplier = 1 + amount / 100;
    const applied = price * multiplier;

    return round(applied, 2);
  }

  const res = {
    amount,
    comment,
  };

  Object.defineProperty(res, 'apply', {
    value: apply,
    enumerable: false,
    configurable: false,
    writable: false,
  });

  return Object.freeze(res);
}

/** @typedef {ReturnType<typeof createAgencyFeeData>} AgencyFeeData */
