import { Box, Skeleton } from '@mantine/core';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import panic from 'errors/Panic';
import { useEffect, useState } from 'react';
import FilePreview from 'components/files/FilePreview';
import { noop } from 'lodash';
import { useIntersection } from '@mantine/hooks';

/**
 * Component used to load a file preview.
 *
 * @param {{
 *   fileId: string;
 *   beingDeleted?: boolean;
 *   canDelete?: boolean | ((fileId: string) => boolean);
 *   onDelete?: () => void;
 * }}
 */
export default function FilePreviewLoader({
  fileId,
  beingDeleted = false,
  canDelete = false,
  onDelete = noop,
  onOpenSlider = noop,
}) {
  const { getFileMetadata } = useFileManager();

  const { ref, entry } = useIntersection({
    root: window.document,
    rootMargin: '200px',
    threshold: 1,
  });

  const [shouldLoad, setShouldLoad] = useState(false);
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setShouldLoad(true);
    }
  }, [entry?.isIntersecting]);

  useEffect(() => {
    if (shouldLoad) {
      getFileMetadata({ fileId }).then(setMetadata).catch(panic);
    }
  }, [shouldLoad, fileId]);

  if (!metadata) {
    return <Skeleton ref={ref} h={48} />;
  }

  return (
    <Box ref={ref}>
      <FilePreview
        fileId={fileId}
        name={metadata.name}
        extension={metadata.extension}
        size={metadata.size}
        type={metadata.mimeType}
        beingDeleted={beingDeleted}
        canDelete={canDelete}
        onDelete={onDelete}
        onOpenSlider={onOpenSlider}
      />
    </Box>
  );
}
