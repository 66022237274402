import { Box, Group, Loader, Switch, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useState } from 'react';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * The switch component.
 *
 * @param {{
 *   entity: string,
 *   name: string,
 *   initialValue: boolean,
 *   action: ({ body: { is_active: boolean, parameters: any } }) => Promise<{ is_active: boolean }>,
 *   primaryKey: number|string,
 *   pathParameterName: string,
 *   onSuccess: (newState: boolean) => void,
 *   disabled?: boolean,
 *   disabledReason?: string,
 * }}
 */
export default function StatusSwitch({
  entity,
  name,
  initialValue,
  action,
  primaryKey,
  pathParameterName,
  onSuccess = noop,
  disabled = false,
  disabledReason,
}) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue);

  /**
   * Handles the switch value change and makes the call to the API.
   *
   * @param {Event} e
   */
  function onChange(e) {
    if (disabled) {
      return; // Do nothing.
    }

    setLoading(true);

    const newValue = e.currentTarget.checked;

    setValue((oldValue) => {
      action({
        body: { is_active: newValue },
        parameters: { [pathParameterName]: primaryKey },
      })
        .then(({ is_active }) => {
          setValue(is_active);

          const message = is_active ? _t('activated') : _t('deactivated');

          showNotification({
            title: _t('%s %s', entity, message),
            message: _t("%s '%s' was successfully %s.", entity, name, message),
            color: SUCCESS_NOTIFICATION_COLOR,
          });

          onSuccess(is_active);
        })
        .catch((e) => {
          setValue(oldValue);
          panic(e.message);
        })
        .finally(() => {
          setLoading(false);
        });

      return newValue;
    });
  }

  const cursor = disabled ? 'not-allowed' : 'default';
  const opacity = disabled ? 0.5 : 1;

  return (
    <Group position="left" spacing="md">
      <Tooltip width={200} multiline withArrow label={disabledReason} disabled={!disabled}>
        <Box>
          <Switch checked={value} onChange={onChange} sx={{ label: { cursor, opacity } }} />
        </Box>
      </Tooltip>
      {loading && <Loader variant="dots" size={24} />}
    </Group>
  );
}
