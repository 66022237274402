/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./position-in-company-set-active-action-body').PositionInCompanySetActiveActionBody} PositionInCompanySetActiveActionBody
 */

/**
 * @typedef {import('./position-in-company-set-active-action-response').PositionInCompanySetActiveActionResponse} PositionInCompanySetActiveActionResponse
 */

/**
 * The path of the PositionInCompanySetActiveAction action.
 */
export const positionInCompanySetActiveActionPath = new SweetPath(
  '/api/user/position_in_company/{position_in_company_id}/set_active',
  { path: '{param}' }
);

/**
 * Creates a contextualized PositionInCompanySetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPositionInCompanySetActiveAction(axios) {
  /**
   * The positionInCompanySetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PositionInCompanySetActiveActionBody } }
   *
   * @returns {Promise<PositionInCompanySetActiveActionResponse>}
   */
  function positionInCompanySetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = positionInCompanySetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return positionInCompanySetActiveAction;
}
