/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Dropzone']} DropzoneTheme */

/** @type {DropzoneTheme} */
export const Dropzone = {
  styles: (theme) => {
    const [neutral50] = theme.colors.neutral50;
    const [neutral300] = theme.colors.neutral300;
    const [secondary] = theme.colors.secondary;
    const [primary] = theme.colors.primary;
    const [complementaryIndigo] = theme.colors.complementaryIndigo;
    const [red] = theme.colors.red;

    return {
      root: {
        borderRadius: '8px',
        '&[data-idle]': {
          backgroundColor: 'transparent !important',
          borderWidth: '1px',
          borderStyle: 'dashed',
          borderColor: neutral300,
          transition: 'border-color 150ms ease, background-color 150ms ease',
        },
        '&[data-idle]:hover:not([aria-disabled="true"])': {
          backgroundColor: `${neutral50} !important`,
          borderColor: complementaryIndigo,
        },
        '&[data-accept]': {
          backgroundColor: `${secondary} !important`,
          borderColor: complementaryIndigo,
          color: primary,
          display: 'flex',
          justifyContent: 'center',
        },
        '&[aria-disabled="true"]': {
          cursor: 'default',
        },
        '&[aria-errormessage]': {
          borderColor: red,
        },
      },
    };
  },
};
