import { Box } from '@mantine/core';
import { _t } from 'lang';

/**
 * Icon for sad reaction.
 *
 * @param {{
 *   size?: number
 *   useEmoji?: boolean
 * }}
 */
export default function SadReactionIcon({ size = 16, useEmoji = false } = {}) {
  if (useEmoji) {
    const emojiSize = size * 0.8;

    return (
      <Box w={emojiSize} h={emojiSize} fz={emojiSize} lh={1} aria-label={_t('sad')}>
        😢
      </Box>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#FFC700" />
      <path
        d="M16 15.728C15.0715 14.7917 13.7436 14 12 14C10.2564 14 8.92848 14.7917 7.99999 15.728"
        stroke="#4D4D4D"
        strokeLinecap="round"
      />
      <circle cx="15" cy="9" r="1" fill="#4D4D4D" />
      <circle cx="9" cy="9" r="1" fill="#4D4D4D" />
    </svg>
  );
}
