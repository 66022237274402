/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./invoice-update-action-body').InvoiceUpdateActionBody} InvoiceUpdateActionBody
 */

/**
 * @typedef {import('./invoice-update-action-response').InvoiceUpdateActionResponse} InvoiceUpdateActionResponse
 */

/**
 * The path of the InvoiceUpdateAction action.
 */
export const invoiceUpdateActionPath = new SweetPath('/api/finance/invoice/{invoice_id}', { path: '{param}' });

/**
 * Creates a contextualized InvoiceUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createInvoiceUpdateAction(axios) {
  /**
   * The invoiceUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: InvoiceUpdateActionBody } }
   *
   * @returns {Promise<InvoiceUpdateActionResponse>}
   */
  function invoiceUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = invoiceUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return invoiceUpdateAction;
}
