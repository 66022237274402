/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./supplier-update-action-body').SupplierUpdateActionBody} SupplierUpdateActionBody
 */

/**
 * @typedef {import('./supplier-update-action-response').SupplierUpdateActionResponse} SupplierUpdateActionResponse
 */

/**
 * The path of the SupplierUpdateAction action.
 */
export const supplierUpdateActionPath = new SweetPath('/api/project/supplier/{supplier_id}', { path: '{param}' });

/**
 * Creates a contextualized SupplierUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createSupplierUpdateAction(axios) {
  /**
   * The supplierUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: SupplierUpdateActionBody } }
   *
   * @returns {Promise<SupplierUpdateActionResponse>}
   */
  function supplierUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = supplierUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return supplierUpdateAction;
}
