import { Collapse, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import CollapseArrow from 'components/CollapseArrow';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import PurchaseOrderIcon from 'components/icons/PurchaseOrderIcon';
import { _t } from 'lang';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import EmptyTab from 'pages/finance/cost-estimate/detail/EmptyTab';
import TabDetailRow from 'pages/finance/cost-estimate/detail/TabDetailRow';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import FileDownloadLink from 'components/files/FileDownloadLink';
import PriceDisplay from 'components/PriceDisplay';

/**
 * The tab for the purchase order in the cost estimate detail page.
 */
export default function PurchaseOrderTab() {
  const { costEstimate, links, currency, isClosed } = useCostEstimateDetail();

  const [opened, { toggle: toggleOpened }] = useDisclosure(true);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const purchaseOrder = costEstimate.purchase_order;
  const invoice = costEstimate.invoice;

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';

  return (
    <div className="px-8 py-4">
      {purchaseOrder ? (
        <div className="rounded-[8px] bg-white py-4">
          <div>
            <div className="grid grid-cols-[1fr_1fr_120px_32px] gap-2 px-2 text-[12px] leading-[18px] text-neutral-500">
              <div className="pl-9">{_t('Document name')}</div>
              <div>{_t('Paired invoice')}</div>
              <div className="text-right">{_t('Created')}</div>
              <div></div>
            </div>
            <div className="group grid h-12 grid-cols-[1fr_1fr_120px_32px] items-center justify-between gap-2 border-b border-b-neutral-100 bg-white px-2 hover:bg-neutral-20">
              <div
                className="flex w-full cursor-pointer items-center gap-x-2 py-3 text-[20px] leading-[36px] text-neutral-700"
                onClick={toggleOpened}
              >
                <div className="h-6 w-6">
                  <CollapseArrow opened={opened} />
                </div>
                <div className="flex flex-row items-center gap-1 text-[15px] leading-[18px] text-neutral-700">
                  <PurchaseOrderIcon fill="#4D4D4D" />
                  <span>{purchaseOrder.purchase_order_name}</span>
                </div>
              </div>
              {invoice ? (
                <Link
                  className="-ml-2 w-fit py-3 pl-2 pr-2 text-[15px] leading-[18px] text-main-primary"
                  replace
                  to={links.invoice.detail}
                >
                  {invoice.invoice_name}
                </Link>
              ) : (
                <div className="py-3 text-[15px] leading-[18px]">-</div>
              )}
              <div className="py-3 text-right text-[12px] leading-[16px] text-neutral-700">
                {new Date(purchaseOrder.created_at).toLocaleDateString()}
              </div>
              <div className={`flex justify-end py-3 ${menuClasses}`}>
                <Menu className="hidden" width={220} position="bottom-end" offset={15} onChange={setIsMenuOpened}>
                  <Menu.Target>
                    <div className="-my-2 cursor-pointer py-2">
                      <OptionsDotsIcon />
                    </div>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item icon={<EditIcon fill="#4D4D4D" />}>{_t('Edit / view')}</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </div>
            <Collapse in={opened} className="border-b border-b-neutral-100 bg-white py-4 pl-[68px]">
              <div className="flex flex-col gap-y-2">
                <TabDetailRow
                  title={_t('Purchase order')}
                  value={
                    <FileDownloadLink h={18} fileId={purchaseOrder.file_id}>
                      {purchaseOrder.purchase_order_name}
                    </FileDownloadLink>
                  }
                />
                <TabDetailRow
                  title={_t('For amount')}
                  value={<PriceDisplay value={costEstimate.amount_after_discount} currency={currency} />}
                />
                <TabDetailRow
                  title={_t('Paired invoice')}
                  value={
                    invoice ? (
                      <Link to={links.invoice.detail} replace className="text-main-primary">
                        {invoice.invoice_name}
                      </Link>
                    ) : (
                      '-'
                    )
                  }
                />
                <TabDetailRow title={_t('Processed by')} value={purchaseOrder.full_name} />
                <TabDetailRow
                  title={_t('Date of processing')}
                  value={new Date(purchaseOrder.created_at).toLocaleString()}
                />
              </div>
            </Collapse>
          </div>
        </div>
      ) : !isClosed ? (
        <EmptyTab title={_t('Add purchase order')} path={links.purchaseOrder.add} />
      ) : (
        <></>
      )}
    </div>
  );
}
