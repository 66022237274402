/** @typedef {import('api/actions/role-get-list-action/role-get-list-action-response').RoleGetListActionResponse[number]} Role */

import { _t } from 'lang';
import DataTable from 'components/tables/DataTable';
import RoleStatusSwitch from 'components/inputs/status-switch/RoleStatusSwitch';
import UserView from 'components/avatars/UserView';
import { useApi } from 'api/ApiContext';
import { EDIT_ROLE_PAGE_PATH } from 'routes/paths';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

/**
 * A row in the Roles table.
 *
 * @param {{
 *   data: Role;
 *   hover: boolean;
 * }}
 */
function RoleTableRow({ data: role, hover }) {
  return (
    <>
      <Link to={EDIT_ROLE_PAGE_PATH.insert({ roleId: role.role_id })} className="block w-fit">
        <div className="flex items-center transition-all hover:text-main-primary">
          <span className="text-[20px] leading-[20px]">{role.role_name}</span>
          {hover && <small className="ml-[10px] text-[12px] text-grey-500">(ID: {role.role_id})</small>}
        </div>
      </Link>
      <div className="flex">
        <UserView
          max={9}
          users={role.members || []}
          editUsersPath={`${EDIT_ROLE_PAGE_PATH.insert({ roleId: role.role_id })}?tab=members`}
        />
      </div>
      <RoleStatusSwitch role={role} />
    </>
  );
}

/**
 * The table in the Roles page.
 */
export default function RoleTable() {
  const { getAction } = useApi();
  const roleGetListAction = getAction('RoleGetListAction');

  const columns = useMemo(
    () => [
      { title: _t('Role'), width: 5 },
      { title: _t('Members'), width: 3 },
      { title: _t('Status'), width: 1 },
    ],
    []
  );

  return (
    <DataTable
      action={roleGetListAction}
      columns={columns}
      rowKey={(role) => role.role_id}
      editLink={(role) => EDIT_ROLE_PAGE_PATH.insert({ roleId: role.role_id })}
      RowComponent={RoleTableRow}
    />
  );
}
