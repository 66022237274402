import { Stack, Tabs, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import FormRow from 'components/forms/FormRow';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import { useMemo } from 'react';
import LanguageSelect from 'components/selects/LanguageSelect';
import CurrencySelect from 'components/selects/CurrencySelect';
import Alert from 'components/Alert';
import ContactPersonsInput from 'components/inputs/contact-person/ContactPersonsInput';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';

/** @typedef {import('components/inputs/contact-person/types').IContactPerson} IContactPerson */

/**
 * @typedef {{
 *   name: string;
 *   ico: string;
 *   dic: string;
 *   icDph: string;
 *   streetAndNumber: string;
 *   city: string;
 *   zipCode: string;
 *   country: string;
 *   note: string;
 *   contactPerson: IContactPerson[];
 *   language: string;
 *   currency: string;
 *   supplierType: string;
 * }} SupplierFormData
 */

/**
 * The Add / Edit Supplier form.
 *
 * @param {{
 *   initialValues: SupplierFormData,
 *   onSubmit: (values: SupplierFormData) => void | Promise<void>,
 * }}
 */
export default function SupplierForm({ initialValues, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);

  const form = useForm({
    initialValues,

    validate: {
      name: createValidator([required]),
      ico: createValidator([required]),
      dic: createValidator([required]),
      streetAndNumber: createValidator([required]),
      city: createValidator([required]),
      zipCode: createValidator([required]),
      country: createValidator([required]),
      language: createValidator([required]),
      currency: createValidator([required]),
    },
  });

  const infoError = useMemo(
    () =>
      form.errors.name ||
      form.errors.ico ||
      form.errors.dic ||
      form.errors.streetAndNumber ||
      form.errors.city ||
      form.errors.zipCode ||
      form.errors.country,
    [form.errors]
  );

  return (
    <form onSubmit={form.onSubmit(submit)}>
      {infoError && <Alert severity="error" primaryText={_t('Some errors are present in the "Information" tab.')} />}

      <Tabs defaultValue="information">
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="information">{_t('Information')}</Tabs.Tab>
          <Tabs.Tab value="contact">{_t('Contact')}</Tabs.Tab>
          <Tabs.Tab value="invoicing">{_t('Invoicing')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="information">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Name')}
              input={<TextInput placeholder={_t('Supplier name')} autoFocus {...form.getInputProps('name')} />}
            />
            <FormRow label={_t('IČO')} input={<TextInput placeholder={_t('IČO')} {...form.getInputProps('ico')} />} />
            <FormRow label={_t('DIČ')} input={<TextInput placeholder={_t('DIČ')} {...form.getInputProps('dic')} />} />
            <FormRow
              label={_t('IČ DPH')}
              input={<TextInput placeholder={_t('IČ DPH')} {...form.getInputProps('icDph')} />}
            />
            <FormRow
              label={_t('Street and number')}
              input={<TextInput placeholder={_t('Street and number')} {...form.getInputProps('streetAndNumber')} />}
            />
            <FormRow
              label={_t('City')}
              input={<TextInput placeholder={_t('City')} {...form.getInputProps('city')} />}
            />
            <FormRow
              label={_t('ZIP code')}
              input={<TextInput placeholder={_t('Zip code')} {...form.getInputProps('zipCode')} />}
            />
            <FormRow
              label={_t('Country')}
              input={<TextInput placeholder={_t('Country')} {...form.getInputProps('country')} />}
            />
            <FormRow label={_t('Note')} input={<Textarea placeholder={_t('Note')} {...form.getInputProps('note')} />} />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="contact">
          <ContactPersonsInput {...form.getInputProps('contactPerson')} />
        </Tabs.Panel>

        <Tabs.Panel value="invoicing">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Language')}
              input={<LanguageSelect placeholder={_t('Language')} {...form.getInputProps('language')} />}
            />
            <FormRow
              label={_t('Currency')}
              input={<CurrencySelect disabled placeholder={_t('Currency')} {...form.getInputProps('currency')} />}
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
