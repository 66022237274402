/**
 * Returns a promise that resolves after a given time.
 *
 * @param {number} ms - The time to wait in milliseconds.
 *
 * @returns {Promise<void>} - A promise that resolves after the given time.
 */
export default function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
