/** @typedef {import("components/comments/data/types").ICommentAuthor} ICommentAuthor */

import { Box } from '@mantine/core';
import CommentAddedIcon from 'components/icons/CommentAddedIcon';
import { noop } from 'lodash';

/**
 * Displays an excerpt of the comment this is a reply to.
 *
 * @param {{
 *   author: ICommentAuthor;
 *   excerpt: string;
 *   onClick?: () => void;
 * }}
 */
export default function CommentReply({ author, excerpt, onClick = noop }) {
  return (
    <Box className="grid grid-cols-[24px_1fr] items-center gap-2 pr-10 text-xs text-neutral-500 md:grid-cols-[24px_auto_1fr]">
      <CommentAddedIcon width={24} height={24} stroke="#808080" />
      <Box>Replied to</Box>
      <Box
        onClick={onClick}
        className="col-span-2 mr-10 grid w-full cursor-pointer grid-cols-[auto_1fr] items-center gap-2 rounded-lg bg-white p-2 text-main-primary md:col-span-1"
      >
        <Box className="max-w-fit rounded-lg bg-neutral-100 px-2 py-1 text-neutral-700">{author.fullName}</Box>
        <div className="max-w-[800px] truncate">
          <span dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
      </Box>
    </Box>
  );
}
