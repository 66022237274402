/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-thread-get-list-action-body').CommentThreadGetListActionBody} CommentThreadGetListActionBody
 */

/**
 * @typedef {import('./comment-thread-get-list-action-response').CommentThreadGetListActionResponse} CommentThreadGetListActionResponse
 */

/**
 * The path of the CommentThreadGetListAction action.
 */
export const commentThreadGetListActionPath = new SweetPath('/api/comment/comment/{comment_thread_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized CommentThreadGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentThreadGetListAction(axios) {
  /**
   * The commentThreadGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<CommentThreadGetListActionResponse>}
   */
  function commentThreadGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = commentThreadGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return commentThreadGetListAction;
}
