import { Button } from '@mantine/core';
import ProcessMessageWithAction from 'components/ProcessMessageWithAction';
import UploadFileIcon from 'components/icons/UploadFileIcon';
import { _t } from 'lang';
import { Link } from 'react-router-dom';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';

/**
 * Contains information about the state of the Cost Estimate with buttons to
 * change it when the state is `waiting-for-po`.
 */
export default function StateWaitingForPO() {
  const { links } = useCostEstimateDetail();

  return (
    <ProcessMessageWithAction
      heading={_t('Waiting for PO')}
      message={_t('Upload a Purchase order or proof for invoice in order to invoice the job.')}
      buttons={
        <Button
          variant="primary"
          leftIcon={<UploadFileIcon stroke="#FFFFFF" />}
          component={Link}
          to={links.purchaseOrder.add}
        >
          {_t('Upload PO')}
        </Button>
      }
    />
  );
}
