/**
 * Matches file type against type template.
 *
 * @param {string} fileType
 * @param {string} template
 */
function isFileType(fileType, template) {
  const [type, subtype] = fileType.split('/', 2);
  const [templateType, templateSubtype] = template.split('/', 2);

  if (templateType === '*') {
    return true; // Any type is allowed
  }

  if (templateType !== type) {
    return false; // Main type mismatch
  }

  if (templateSubtype === '*') {
    return true; // Any subtype is allowed (types match)
  }

  return templateSubtype === subtype; // Type and subtype match
}

/**
 * Check if the file type is allowed.
 *
 * @param {string} fileType
 * @param {string[]} accept
 */
export default function matchFileType(fileType, accept) {
  for (const acceptedType of accept) {
    if (isFileType(fileType, acceptedType)) {
      return true;
    }
  }

  return false;
}
