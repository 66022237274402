import { Tooltip } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';

/**
 * Used to create a link.
 */
export default function ControlCreateLink() {
  return (
    <Tooltip label="Link" openDelay={100} closeDelay={50}>
      <RichTextEditor.Link sx={{ svg: { strokeWidth: '2.5', stroke: '#4D4D4D !important' } }} />
    </Tooltip>
  );
}
