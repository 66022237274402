import { Text } from '@mantine/core';
import { useMemo } from 'react';

/**
 * Displays the specified date in a human-readable format.
 *
 * @param {import('@mantine/core').TextProps & {
 *   date: Date | string;
 *   locale?: string;
 * }}
 */
export default function DateDisplay({ date, locale = 'sk', ...props }) {
  const dateObject = useMemo(() => new Date(date), [date]);
  const isValid = useMemo(() => !isNaN(dateObject.getTime()), [dateObject]);

  if (!isValid) {
    return <Text {...props}>Invalid date</Text>;
  }

  return <Text {...props}>{dateObject.toLocaleDateString(locale)}</Text>;
}
