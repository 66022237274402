import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for price lists.
 *
 * @param { import('react').RefAttributes<HTMLInputElement> &
 *   import('@mantine/core').SelectProps
 * }
 */
export default function PriceListSelect({ ...props }) {
  const { getAction } = useApi();
  const PriceListGetListAction = getAction('PriceListGetListAction');

  return (
    <DataSelect
      {...props}
      action={PriceListGetListAction}
      labelProperty="price_list_name"
      valueProperty="price_list_id"
    />
  );
}
