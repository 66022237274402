import { merge } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * Reads an object from local storage.
 *
 * @template T
 *
 * @param {string} key
 *
 * @returns {T}
 */
function readObjectFromLocalStorageSafe(key) {
  try {
    const value = localStorage.getItem(key) || '{}';
    return JSON.parse(value);
  } catch (e) {
    return {};
  }
}

/**
 * Reads an object from local storage.
 *
 * @template T
 *
 * @param {string} key
 * @param {T} defaultValue
 *
 * @returns {T}
 */
function readObjectFromLocalStorage(key, defaultValue = {}) {
  return merge(defaultValue, readObjectFromLocalStorageSafe(key));
}

/**
 * Uses local storage to store the specified value.
 *
 * @template T
 *
 * @param {string} key
 * @param {T} defaultValue
 *
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]}
 */
export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => readObjectFromLocalStorage(key, defaultValue));

  // Update the local storage when the value changes.
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value]);

  // Update the value when the key changes.
  useEffect(() => {
    setValue(readObjectFromLocalStorage(key, defaultValue));
  }, [key]);

  return useMemo(() => [value, setValue], [value, setValue]);
}
