import { MultiSelect, Select } from '@mantine/core';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { isFunction, noop } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

/**
 * Computes value-label pairs from the data.
 *
 * The value should always be string, otherwise the Select will behave unexpectedly
 *
 * @template TRow
 *
 * @param {Array<TRow>} data
 * @param {keyof TRow} valueProperty
 * @param {keyof TRow} labelProperty
 * @param {keyof TRow | (row: TRow) => string} groupProperty
 */
function computePairs(data, valueProperty, labelProperty, groupProperty) {
  return data
    .map((item) => {
      const res = {
        value: String(item[valueProperty]),
        label: String(item[labelProperty]),
        disabled: Boolean(item.disabled) || false,
      };

      if (groupProperty) {
        res.group = isFunction(groupProperty) ? groupProperty(item) : String(item[groupProperty]);
      }

      return res;
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * Used to fetch objects from the database and display them in a select.
 *
 * @template TRow
 * @template {Record<string,number|string>} TFilter
 *
 * @param {{
 *     action: (params: { query?: { filter: TFilter } }) => Promise<Array<TRow>>,
 *     actionFilter?: TFilter,
 *     valueProperty: keyof TRow,
 *     labelProperty: keyof TRow,
 *     placeholder: string,
 *     multiple: boolean,
 *     groupProperty: string | (row: TRow) => string,
 *     additionalValues?: { label: string; value: string }[],
 *   }
 *   & import('react').RefAttributes<HTMLInputElement>
 *   & import('@mantine/core').SelectProps
 * }
 */
export default function DataSelect({
  action,
  value,
  actionFilter,
  valueProperty,
  labelProperty,
  placeholder,
  multiple,
  groupProperty,
  readOnly,
  disabled,
  additionalValues,
  onChange = noop,
  ...otherProps
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const valueLabelPairs = useMemo(() => {
    const pairs = computePairs(data, valueProperty, labelProperty, groupProperty);

    if (additionalValues) {
      pairs.push(...additionalValues);
    }

    return pairs;
  }, [data, valueProperty, labelProperty, groupProperty, additionalValues]);

  // Filter out inactive objects by default (unless explicitly requested otherwise)
  const augmentedFilter = useMemo(() => ({ 'is_active.eq': 1, ...actionFilter }), [actionFilter]);

  // Check if one of the filters is xxx.in=[] (empty array)
  const containsEmptyInFilter = useMemo(
    () =>
      Object.entries(augmentedFilter).some(
        ([filterName, value]) => filterName.endsWith('.in') && Array.isArray(value) && value.length === 0
      ),
    [augmentedFilter]
  );

  if (!placeholder) {
    placeholder = readOnly || disabled ? '' : _t('Select option');
  }

  const props = {
    ...otherProps,
    value,
    data: valueLabelPairs,
    placeholder,
    searchable: true,
    nothingFound: loading ? _t('Loading ...') : _t('No options'),
    readOnly,
    disabled,
    onChange,
  };

  // Fetch data
  useEffect(() => {
    setData([]);

    if (containsEmptyInFilter) {
      setLoading(false);
      onChange('');
    } else {
      setLoading(true);

      action({ query: { filter: augmentedFilter } })
        .then((res) => {
          setData(res);

          if (!multiple) {
            const valueIsPresent = res.some((item) => String(item[valueProperty]) === String(value));

            if (!valueIsPresent) {
              onChange('');
            }
          }
        })
        .catch((err) => panic(err))
        .finally(() => setLoading(false));
    }
  }, [action, augmentedFilter, containsEmptyInFilter]);

  return multiple ? <MultiSelect {...props} withinPortal /> : <Select {...props} withinPortal />;
}
