/** @typedef {import('components/inputs/contact-person/types').IContactPerson} IContactPerson */

import { Box, Button, Group, Modal, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import PlusIcon from 'components/icons/PlusIcon';
import { _t } from 'lang';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import ContactPersonForm from 'components/inputs/contact-person/ContactPersonForm';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import ContactPersonRow from 'components/inputs/contact-person/ContactPersonRow';

/**
 * Input used to define one or more contact persons.
 *
 * @param {{
 *   value: IContactPerson[];
 *   onChange: (value: IContactPerson[]) => void;
 * }}
 */
export default function ContactPersonsInput({ value, onChange }) {
  const { confirm } = useConfirm();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [initialValues, setInitialValues] = useState({ email: '', name: '', phone: '', uuid: nanoid() });
  const [modalTitle, setModalTitle] = useState('');

  /**
   * Removes a contact person from the list.
   *
   * @param {IContactPerson} contactPerson
   */
  const removeContactPerson = ({ uuid }) =>
    confirm({
      title: _t('Remove contact person'),
      message: _t('Are you sure you want to remove this contact person?'),
      onConfirm: () => onChange(value.filter((contactPerson) => contactPerson.uuid !== uuid)),
    });

  /**
   * Adds a new contact person to the list.
   */
  const addContactPerson = () => {
    setModalTitle(_t('Add contact person'));
    setInitialValues({ email: '', name: '', phone: '', uuid: nanoid() });
    openModal();
  };

  /**
   * Starts editing a contact person.
   *
   * @param {IContactPerson} contactPerson
   */
  const editContactPerson = (contactPerson) => {
    setModalTitle(_t('Edit contact person'));
    setInitialValues(contactPerson);
    openModal();
  };

  /**
   * Adds a contact person to the list.
   */
  const saveContactPerson = (person) => {
    const index = value.findIndex((contactPerson) => contactPerson.uuid === person.uuid);
    const exists = index !== -1;

    if (exists) {
      onChange([...value.slice(0, index), person, ...value.slice(index + 1)]);
    } else {
      onChange([...value, person]);
    }

    closeModal();
  };

  return (
    <>
      <Stack spacing={16} py={32}>
        {value.length === 0 ? (
          <Box className="text-center text-neutral-500">{_t('No contact persons added yet.')}</Box>
        ) : (
          <Box className="grid grid-cols-[24px_max-content_max-content_max-content_36px] items-center gap-x-2 gap-y-4">
            <div className="text-[12px] leading-[16px] text-neutral-500">{_t('NAME')}</div>
            <div></div>
            <div className="pl-3 text-[12px] leading-[16px] text-neutral-500">{_t('EMAIL')}</div>
            <div className="pl-3 text-[12px] leading-[16px] text-neutral-500">{_t('PHONE')}</div>
            <div></div>
            {value.map((contactPerson) => (
              <ContactPersonRow
                key={contactPerson.uuid}
                contactPerson={contactPerson}
                onEdit={editContactPerson}
                onRemove={removeContactPerson}
              />
            ))}
          </Box>
        )}
        <Group pt={value.length === 0 ? 8 : 32} position={value.length === 0 ? 'center' : 'left'}>
          <Button type="button" variant="primary" leftIcon={<PlusIcon stroke="#F2F2F2" />} onClick={addContactPerson}>
            {_t('Add contact person')}
          </Button>
        </Group>
      </Stack>

      <Modal
        title={modalTitle}
        centered
        size={640}
        opened={modalOpened}
        onClose={closeModal}
        closeOnClickOutside={false}
      >
        <ContactPersonForm
          opened={modalOpened}
          initialValues={initialValues}
          onSubmit={saveContactPerson}
          onCancel={closeModal}
        />
      </Modal>
    </>
  );
}
