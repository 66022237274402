import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconStrikethrough from 'components/inputs/wysiwyg/controls/strikethrough/IconStrikethrough';

/**
 * Used to strikethrough text.
 */
export default function ControlStrikethrough() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Strikethrough" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleStrike().run()}
        active={editor?.isActive('strike')}
      >
        <IconStrikethrough />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
