import { Overlay, Portal } from '@mantine/core';
import { APP_HEADER_HEIGHT, APP_SIDEBAR_WIDTH } from 'utils/constants';

/**
 * Overlay for the dashboard layout.
 *
 * @param {{
 *   cursor?: string;
 * }}
 */
export default function DashboardLayoutOverlay({ zIndex = 50, cursor } = {}) {
  return (
    <Portal>
      <Overlay pos="fixed" top={0} left={0} opacity={0} zIndex={zIndex} style={{ cursor }} />
      <Overlay pos="fixed" top={0} left={0} h={APP_HEADER_HEIGHT} opacity={0} zIndex={300} style={{ cursor }} />
      <Overlay pos="fixed" top={0} left={0} w={APP_SIDEBAR_WIDTH} opacity={0} zIndex={300} style={{ cursor }} />
    </Portal>
  );
}
