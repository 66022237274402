/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse['groups'][number]} ResultGroup */

import { Button, Collapse, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import { noop } from 'lodash';
import AdvancedSearchSectionResult from './AdvancedSearchSectionResult';
import { getSectionName } from 'layouts/dashboard-layout/headers/search/SectionResults';
import { useMemo } from 'react';

/**
 * Displays the advanced search results for a given section.
 *
 * @param {{
 *   group: ResultGroup;
 *   moreResults: ResultGroup;
 *   search: string;
 *   loadMore?: ({searchType: string, startFrom: number}) => void;
 * }}
 */
export default function AdvancedSearchSection({
  group: { name, total, items, more },
  search,
  moreResults,
  loadMore = noop,
}) {
  const [opened, { toggle }] = useDisclosure(true);

  const heading = useMemo(() => getSectionName(name), [name]);

  return (
    <Stack spacing={16}>
      <GroupHeadline
        heading={heading}
        number={`${total} ${total === 1 ? _t('result') : _t('results')}`}
        opened={opened}
        setOpened={toggle}
      />
      <Collapse in={opened}>
        <Stack spacing={0} pl={24} pr={24}>
          {items.map((item) => (
            <AdvancedSearchSectionResult search={search} key={item.id} item={item} />
          ))}
        </Stack>

        {moreResults && moreResults.items.length > 0 && (
          <Stack spacing={0} pl={24} pr={24}>
            {moreResults.items.map((item) => (
              <AdvancedSearchSectionResult search={search} key={item.id} item={item} />
            ))}
          </Stack>
        )}
        {more > 0 && (
          <Group pl={24}>
            <Button
              variant="link"
              m={8}
              onClick={() => {
                loadMore({ searchType: name, startFrom: items.length });
              }}
            >
              {_t('Load another')} {more}
            </Button>
          </Group>
        )}
      </Collapse>
    </Stack>
  );
}
