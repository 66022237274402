/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Invoice" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function InvoiceIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 10.5C11.2905 10.5 10.2816 11.3589 10.05 12.5H12.5C12.7761 12.5 13 12.7239 13 13C13 13.2761 12.7761 13.5 12.5 13.5H10.05C10.2816 14.6411 11.2905 15.5 12.5 15.5C13.1814 15.5 13.7982 15.2281 14.2496 14.7857C14.4468 14.5925 14.7634 14.5957 14.9567 14.7929C15.15 14.9901 15.1467 15.3067 14.9495 15.5C14.3188 16.1181 13.4535 16.5 12.5 16.5C10.567 16.5 9 14.933 9 13C9 11.067 10.567 9.5 12.5 9.5C13.4792 9.5 14.3653 9.90281 15 10.5505C15.1932 10.7477 15.19 11.0643 14.9928 11.2576C14.7956 11.4508 14.479 11.4476 14.2857 11.2504C13.8314 10.7867 13.1996 10.5 12.5 10.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.5C5.89543 3.5 5 4.39543 5 5.5V19.5C5 20.6046 5.89543 21.5 7 21.5H17C18.1046 21.5 19 20.6046 19 19.5V8.25147C19 7.76702 18.8242 7.29904 18.5052 6.93446L16.0976 4.18299C15.7178 3.74896 15.1692 3.5 14.5925 3.5H7ZM7 4.5H14V7C14 7.82843 14.6716 8.5 15.5 8.5H18V19.5C18 20.0523 17.5523 20.5 17 20.5H7C6.44772 20.5 6 20.0523 6 19.5V5.5C6 4.94772 6.44772 4.5 7 4.5ZM15.345 4.8415L17.6712 7.5H15.5C15.2239 7.5 15 7.27614 15 7V4.58681C15.1306 4.6451 15.2487 4.73139 15.345 4.8415Z"
        fill={fill}
      />
    </svg>
  );
}
