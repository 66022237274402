import { _t } from 'lang';
import { Group, Skeleton, Switch } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useCommentData } from './providers/CommentDataProvider';

/**
 * CommentThreadNotifyMe Switch
 *
 * @param {{
 *   threadId: string
 *   loading?: boolean
 * }}
 */
export default function CommentThreadNotifyMeSwitch({ threadId, loading = false }) {
  const { getAction } = useApi();
  const { notifyMe, updateNotifyMe } = useCommentData();

  /**
   * Updates the notifyMe setting.
   */
  function updateNotifyMeValue(newNotifyMe) {
    const commentThreadSetNotifyMe = getAction('CommentThreadSetNotifyMeAction');

    commentThreadSetNotifyMe({
      parameters: { comment_thread_id: threadId },
      body: { notify: newNotifyMe },
    })
      .then(() => updateNotifyMe(newNotifyMe))
      .catch(panic);
  }

  if (loading) {
    return <Skeleton w={120} h={20} />;
  }

  return (
    <Group spacing={8}>
      <Switch
        checked={notifyMe}
        label={_t('Notify me')}
        labelPosition="left"
        onChange={(e) => updateNotifyMeValue(e.currentTarget.checked)}
      />
    </Group>
  );
}
