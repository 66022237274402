import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Box, Stack } from '@mantine/core';

/**
 * The input for phone numbers.
 *
 * @param {{
 *   placeholder: string;
 *   value: string;
 *   onChange: (value: string) => void;
 *   defaultCountry?: string;
 * }}
 */
export default function PhoneNumberInput({ placeholder, value, onChange, error, defaultCountry = 'SK' }) {
  return (
    <Stack spacing={4}>
      <PhoneInput
        placeholder={placeholder}
        international={true}
        defaultCountry={defaultCountry}
        value={value}
        onChange={onChange}
        className={error ? 'PhoneInput--error' : undefined}
      />
      {error && (
        <Box fz={15} lh={18 / 15} c="#BF0D38">
          {error}
        </Box>
      )}
    </Stack>
  );
}
