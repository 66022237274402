import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import { ERROR_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * This function should be called when an unexpected error occurs.
 *
 * @param {string} message
 * @param {*} [meta]
 */
export default function panic(message) {
  showNotification({
    title: _t('Unexpected Error'),
    message: `${message}\n${_t('Please contact the administrator.')}`,
    color: ERROR_NOTIFICATION_COLOR,
    style: { whiteSpace: 'pre-line' },
  });

  // todo: send error to server.
}
