// Layout
export const APP_HEADER_HEIGHT = 60;
export const APP_SIDEBAR_WIDTH = 60;
export const MODULE_SIDEBAR_WIDTH = 360;
export const BOTTOM_BAR_HEIGHT = 76;

// Password
export const MIN_PASSWORD_LENGTH = 8; // todo: move to env

// Time
export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;

export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * HOURS_IN_DAY;
export const MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * DAYS_IN_WEEK;

export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY;
export const SECONDS_IN_WEEK = SECONDS_IN_DAY * DAYS_IN_WEEK;

export const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;
export const MINUTES_IN_WEEK = MINUTES_IN_DAY * DAYS_IN_WEEK;

export const HOURS_IN_WEEK = HOURS_IN_DAY * DAYS_IN_WEEK;

export const MAX_LOGGED_MINUTES = MINUTES_IN_DAY - 1;

// Notifications
export const SUCCESS_NOTIFICATION_COLOR = 'teal';
export const ERROR_NOTIFICATION_COLOR = 'red';
export const WARNING_NOTIFICATION_COLOR = 'orange';

// Error codes from REST API
export const DUPLICATE_USER_EMAIL_ERROR_CODE = 4000108;
export const PASSWORD_SAME_AS_OLD_ONE_ERROR_CODE = 4000109;
export const PROJECT_CANNOT_BE_CLOSED_ERROR_CODE = 4000401;
export const TASK_CANNOT_BE_CLOSED_ERROR_CODE = 4000403;
export const TIME_LOG_CANNOT_BE_CREATED_ERROR_CODE = 4000603;
export const TIME_LOG_CANNOT_BE_UPDATED_ERROR_CODE = 4030602;
export const USER_DEACTIVATED_ERROR_CODE = 4000110;
export const OBJECT_DOES_NOT_EXIST_ERROR_CODE = 4040401;
export const INCORRECT_CREDENTIALS_ERROR_CODE = 4010102;
export const DUPLICATE_CLIENT_SHORTCUT_SLUG_ERROR_CODE = 4000405;
export const USER_IS_NOT_ALLOWED_ERROR_CODE = 4030101;
export const BAD_REQUEST_ERROR_CODE = 4000005;

// Size in bytes
export const KILOBYTE = 1024;
export const MEGABYTE = 1024 * KILOBYTE;
export const GIGABYTE = 1024 * MEGABYTE;

// Comments
export const COMMENTS_MAX_TIME_TO_EDIT = 15 * MILLISECONDS_IN_MINUTE;

// Wysiwyg
export const WYSIWYG_INLINE_IMAGE_MAX_WIDTH = 480;
export const WYSIWYG_INLINE_IMAGE_MAX_HEIGHT = 360;

// Next project number
export const MAX_NEXT_PROJECT_NUM = 9999;
export const NEXT_PROJECT_NUM_MODAL_THRESHOLD = 100;
