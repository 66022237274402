/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./price-list-get-action-body').PriceListGetActionBody} PriceListGetActionBody
 */

/**
 * @typedef {import('./price-list-get-action-response').PriceListGetActionResponse} PriceListGetActionResponse
 */

/**
 * The path of the PriceListGetAction action.
 */
export const priceListGetActionPath = new SweetPath('/api/finance/price_list/{price_list_id}', { path: '{param}' });

/**
 * Creates a contextualized PriceListGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPriceListGetAction(axios) {
  /**
   * The priceListGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<PriceListGetActionResponse>}
   */
  function priceListGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = priceListGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return priceListGetAction;
}
