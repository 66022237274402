import {
  endOfDay,
  endOfMonth,
  isMonday,
  isSunday,
  nextSunday,
  previousMonday,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';

/**
 * The options for the date range input.
 */
export const DATE_RANGE_TYPES = [
  { value: 'anytime', label: 'Anytime' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'this_week', label: 'This Week' },
  { value: 'last_week', label: 'Last Week' },
  { value: 'this_month', label: 'This Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_3_months', label: 'Last 3 Months' },
  { value: 'last_6_months', label: 'Last 6 Months' },
  { value: 'custom', label: 'Custom date range' },
];

/**
 * Creates a date range from a given date range type.
 *
 * @param {string} dateRangeType
 */
export function createDateRangeFromType(dateRangeType) {
  const today = new Date();
  const yesterday = subDays(today, 1);
  const thisMonday = isMonday(today) ? today : previousMonday(today);
  const thisSunday = isSunday(today) ? today : nextSunday(today);
  const firstDayOfThisMonth = startOfMonth(today);
  const lastDayOfThisMonth = endOfMonth(today);

  let start, end;

  switch (dateRangeType) {
    case 'anytime':
      start = new Date('1970-01-01');
      end = new Date('2099-12-31');
      break;

    case 'today':
      start = today;
      end = today;
      break;

    case 'yesterday':
      start = yesterday;
      end = yesterday;
      break;

    case 'this_week':
      start = thisMonday;
      end = thisSunday;
      break;

    case 'last_week':
      start = subWeeks(thisMonday, 1);
      end = subWeeks(thisSunday, 1);
      break;

    case 'this_month':
      start = firstDayOfThisMonth;
      end = lastDayOfThisMonth;
      break;

    case 'last_month':
      start = startOfMonth(subMonths(today, 1));
      end = endOfMonth(subMonths(today, 1));
      break;

    case 'last_3_months':
      start = startOfMonth(subMonths(today, 2)); // 2 = 3 - 1: This month is already included
      end = endOfMonth(today);
      break;

    case 'last_6_months':
      start = startOfMonth(subMonths(today, 5)); // 5 = 6 - 1: This month is already included
      end = endOfMonth(today);
      break;
  }

  if (start && end) {
    return {
      start: startOfDay(start),
      end: endOfDay(end),
      type: dateRangeType,
    };
  }

  return undefined;
}
