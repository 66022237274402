/** @typedef {import('components/files/upload/types').IFile} IFile */
/** @typedef {import('components/files/upload/types').IFileError} IFileError */
/** @typedef {import('components/files/upload/types').IFileRejectionReason} IFileRejectionReason */

import { Button, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import UploadFileIcon from 'components/icons/UploadFileIcon';
import { _t } from 'lang';
import { Dropzone } from '@mantine/dropzone';
import { ErrorStyles } from 'theme/components/Inputs/shared/Error';
import matchFileType from 'components/files/upload/validation/match-file-type';
import { nanoid } from 'nanoid';
import { FILE_UPLOAD_MAX_SIZE } from 'environment';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';
import Alert from 'components/Alert';
import getErrorMessage from 'components/files/upload/validation/get-error-message';

/**
 * Used to collect dropped files.
 *
 * @param {{
 *   onAccept?: (files: IFile[]) => void;
 *   onReject?: (errors: IFileError[]) => void;
 *   accept?: string[];
 *   maxFiles?: number;
 *   maxSize?: number;
 *   disabled?: boolean;
 *   error?: string;
 *   withErrorList?: boolean;
 *   errorAutoClose?: number;
 *   initialErrors?: IFileError[];
 *   errorMessages?: Record<IFileRejectionReason, (error: IFileError) => string>;
 * }}
 */
export default function DropFiles({
  onAccept = noop,
  onReject = noop,
  accept = ['*'],
  maxFiles = Infinity,
  maxSize = FILE_UPLOAD_MAX_SIZE,
  disabled = false,
  error = undefined,
  withErrorList = false,
  errorAutoClose = 10000,
  initialErrors = [],
  errorMessages = {},
} = {}) {
  const theme = useMantineTheme();
  const [errors, setErrors] = useState(initialErrors);

  /**
   * Handles file selection.
   *
   * @param {File[] | null} droppedFiles
   */
  const onDropImpl = (droppedFiles) => {
    if (!droppedFiles) {
      return; // User clicked on the "Cancel" button provided by the browser.
    }

    /** @type {IFile[]}      */ const valid = [];
    /** @type {IFileError[]} */ const errors = [];

    for (const file of droppedFiles) {
      let errorReason;

      if (!matchFileType(file.type, accept)) {
        errorReason = 'accept';
      } else if (file.size > maxSize) {
        errorReason = 'size';
      } else if (valid.length >= maxFiles) {
        errorReason = 'maxFiles';
      } else {
        errorReason = null;
      }

      if (errorReason) {
        errors.push({ errorId: nanoid(), fileName: file.name, reason: errorReason });
      } else {
        valid.push({ uuid: nanoid(), file });
      }
    }

    onAccept(valid);
    onReject(errors);

    if (withErrorList) {
      setErrors((curr) => [...curr, ...errors]);
    }
  };

  /**
   * Closes the specified error.
   *
   * @type {(error: IFileError) => void}
   */
  const closeError = useCallback(
    ({ errorId }) => setErrors((errors) => errors.filter((error) => error.errorId !== errorId)),
    [setErrors]
  );

  const border = error ? 'border-complementary-danger' : 'border-nautral-300';

  return (
    <Stack spacing={16}>
      <Dropzone
        onDrop={onDropImpl}
        disabled={disabled}
        aria-disabled={disabled}
        aria-errormessage={error || undefined}
        className={`flex justify-start border border-dashed ${border}`}
      >
        <Dropzone.Accept>
          <Group position="center" spacing={8} h={36}>
            <UploadFileIcon width={24} height={24} stroke={theme.fn.primaryColor()} />
            <Text fz={12} lh={16 / 12} color="primary">
              {_t('Drop it like it´s hot')}
            </Text>
          </Group>
        </Dropzone.Accept>
        <Dropzone.Idle>
          <Group position="center" spacing={16}>
            <Button variant="secondary" disabled={disabled} leftIcon={<UploadFileIcon size="1.2rem" />} radius={10}>
              {_t('Upload files')}
            </Button>
            <Text fz={12} lh={16 / 12} color={disabled ? 'neutral300' : 'neutral700'}>
              {_t('Drag here or click to Upload')}
            </Text>
          </Group>
        </Dropzone.Idle>
      </Dropzone>

      {error && <Text style={ErrorStyles}>{error}</Text>}

      {(withErrorList ? errors : []).map((error) => (
        <Alert
          key={error.errorId}
          severity="error"
          withCloseButton
          autoClose={errorAutoClose}
          onClose={() => closeError(error)}
          primaryText={errorMessages?.[error.reason]?.(error) ?? getErrorMessage(error)}
        />
      ))}
    </Stack>
  );
}
