/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { useApi } from 'api/ApiContext';
import ExternalCostsForm from 'components/forms/external-costs/ExternalCostsForm';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import panic from 'errors/Panic';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE, SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { _t } from 'lang';
import { showNotification } from '@mantine/notifications';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The contents of the edit external cost page.
 *
 * @param {{
 *  costEstimate: CostEstimate;
 * }}
 */
export default function EditExternalCostsPageContent({ costEstimate }) {
  const navigate = useNavigate();
  const { externalCostId } = useParams();

  const { getAction } = useApi();
  const { getFileMetadata } = useFileManager();

  const [initialFiles, setInitialFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  const detailPath = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({
    costEstimateId: costEstimate.cost_estimate_id,
  });
  const detailTab = `${detailPath}?tab=external-cost`;

  useEffect(() => {
    /**
     * Retrieves the data from the API.
     */
    async function fetchData() {
      const externalCostGetAction = getAction('ExternalCostGetAction');

      setLoading(true);

      try {
        const data = await externalCostGetAction({ parameters: { external_cost_id: externalCostId } });

        const totalPaidAmountWithoutVat = data.amount;
        const currencyRate = data.currency_rate;
        const fileId = data.file_id;

        const initialValues = {
          supplierId: String(data.supplier_id),
          fileId,
          invoiceNumber: data.invoice_number,
          invoiceDate: data.invoice_issued ? new Date(data.invoice_issued) : undefined,
          supplyDate: data.invoice_supplied ? new Date(data.invoice_supplied) : undefined,
          paymentDueDate: data.invoice_due_date ? new Date(data.invoice_due_date) : undefined,
          currency: data.currency,
          totalPaidAmountWithoutVat,
          calculatedExpense: totalPaidAmountWithoutVat / currencyRate,
          baseAmount: data.base_amount,
          currencyRate,
          currencyRateDate: data.currency_rate_date,
          invoiceSubject: data.invoice_subject,
          internalNote: data.note,
        };

        setInitialValues(initialValues);

        if (fileId) {
          const file = await getFileMetadata({ fileId });
          setInitialFiles([
            {
              uuid: file.fileId,
              fileName: file.basename,
              fileType: file.mimeType,
              remoteId: fileId,
              progress: 1,
              finalized: true,
            },
          ]);
        }
      } catch (e) {
        if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
          setInitialValues(null);
        } else {
          panic(e);
        }
      }

      setLoading(false);
    }

    fetchData();
  }, [externalCostId, getAction, getFileMetadata]);

  if (costEstimate.closed_at || !externalCostId) {
    return <Navigate to={detailTab} replace />;
  }

  if (!loading && !initialValues) {
    return <NotFoundPageContent />;
  }

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  function submit({
    supplierId,
    fileId,
    invoiceNumber,
    invoiceDate,
    supplyDate,
    paymentDueDate,
    currency,
    totalPaidAmountWithoutVat,
    baseAmount,
    currencyRate,
    currencyRateDate,
    invoiceSubject,
    internalNote,
  }) {
    const externalCostUpdateAction = getAction('ExternalCostUpdateAction');

    return externalCostUpdateAction({
      parameters: {
        external_cost_id: externalCostId,
      },
      body: {
        cost_estimate_id: Number(costEstimate.cost_estimate_id),
        supplier_id: supplierId,
        file_id: fileId[0].fileId,
        invoice_number: invoiceNumber,
        invoice_issued: invoiceDate ? new Date(invoiceDate).toLocaleDateString() : null,
        invoice_supplied: supplyDate ? new Date(supplyDate).toLocaleDateString() : null,
        invoice_due_date: paymentDueDate ? new Date(paymentDueDate).toLocaleDateString() : null,
        currency: currency,
        amount: totalPaidAmountWithoutVat,
        base_amount: baseAmount,
        currency_rate: currencyRate,
        currency_rate_date: currencyRateDate,
        invoice_subject: invoiceSubject,
        note: internalNote,
      },
    })
      .then(({ external_cost_id }) => {
        showNotification({
          title: _t('External cost updated'),
          message: _t('External cost has been updated successfully.'),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        const detailTabWithExternalCost = `${detailTab}&externalCostId=${external_cost_id}`;

        navigate(detailTabWithExternalCost, { replace: true });
      })
      .catch((e) => {
        panic(e.message);
      });
  }

  return (
    <Box mt={16}>
      {!loading && (
        <ExternalCostsForm
          onSubmit={submit}
          initialValues={initialValues}
          costEstimate={costEstimate}
          initialFiles={initialFiles}
        />
      )}
    </Box>
  );
}
