/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse['groups'][number]['items'][number]} SearchResult */

import { Box, Group, Text } from '@mantine/core';
import SearchHighlighter from 'components/search/SearchHighlighter';
import ObjectDetailLink from 'components/ObjectDetailLink';
import Truncate from 'components/Truncate';
import { useSearch } from './SearchProvider';

/**
 * Displays a single item in the search result.
 *
 * @param {{ item: SearchResult }}
 */
export default function SectionResult({ item: { id, name, type, parent } }) {
  const { search } = useSearch();

  return (
    <ObjectDetailLink id={id} type={type} parent={parent}>
      <Group spacing={16} w="100%" c="#908EFB" className="group/section-result hover:text-white">
        <Box w={328} bg="complementaryIndigo">
          <SearchHighlighter
            needle={search}
            haystack={name}
            highlightClassName="font-medium bg-[transparent] text-[#cecdff] group-hover/section-result:text-white"
          />
        </Box>
        <Text align="right" w={140} fz={12} lh={2}>
          {type === 'file' ? (
            <Truncate text={parent.parent ? parent.parent.name : parent.name} />
          ) : (
            <Truncate text={parent?.name ?? ''} />
          )}
        </Text>
      </Group>
    </ObjectDetailLink>
  );
}
