import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for roles.
 *
 * @param { import('react').RefAttributes<HTMLInputElement> &
 *   import('@mantine/core').SelectProps
 * }
 */
export default function RoleSelect({ ...props }) {
  const { getAction } = useApi();
  const roleGetListAction = getAction('RoleGetListAction');

  return <DataSelect {...props} action={roleGetListAction} labelProperty="role_name" valueProperty="role_id" />;
}
