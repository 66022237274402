/** @typedef {import("components/comments/data/types").IComment} IComment */
/** @typedef {import("components/comments/data/types").CommentReactionType} CommentReactionType */
/** @typedef {import("components/comments/data/types").ICommentFormDataOut} ICommentFormDataOut */

import { useApi } from 'api/ApiContext';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import parseComment from 'components/comments/data/parse-comment';
import panic from 'errors/Panic';
import { isEqual, isFunction } from 'lodash';
import { useInterval } from '@mantine/hooks';
import objectHash from 'object-hash';
import { COMMENT_AUTO_REFRESH_INTERVAL } from 'environment';
import useLocalStorage from 'hooks/use-local-storage';

/**
 * Data context for a comment thread.
 *
 * @typedef {() => Promise<void>} IFetchComments
 * @typedef {(commentId: string) => IComment | undefined} IGetComment
 * @typedef {(comment: IComment) => void} IAddComment
 * @typedef {(commentId: string, update: Partial<IComment> | ((comment: IComment) => IComment)) => void} IUpdateComment
 * @typedef {(commentId: string) => void} IRemoveComment
 * @typedef {(commentId: string, fileId: string) => void} IRemoveAttachment
 * @typedef {(commentId: string, reactionType: CommentReactionType | null) => void} IReactToComment
 * @typedef {(newNotifyMe: boolean) => void} IUpdateNotifyMe
 * @typedef {{user_id: number; avatar?: string; full_name: string}} User
 *
 * @type {React.Context<{
 *   threadId: string;
 *   clientId: number;
 *   comments: IComment[];
 *   notifyMe: boolean;
 *   threadNotified: User[];
 *   loading: boolean;
 *   cache: ICommentFormDataOut;
 *   fetchComments: IFetchComments;
 *   getComment: IGetComment;
 *   addComment: IAddComment;
 *   updateComment: IUpdateComment;
 *   removeComment: IRemoveComment;
 *   removeAttachment: IRemoveAttachment;
 *   reactToComment: IReactToComment;
 *   updateNotifyMe: IUpdateNotifyMe;
 *   setCache: (cache: ICommentFormDataOut) => void;
 *   resetCache: () => void;
 * }>}
 */
const CommentDataContext = createContext();

/**
 * Provides the comment data context.
 *
 * @param {{
 *   children: React.ReactNode;
 *   threadId: string;
 *   autoRefreshEnabled?: boolean;
 *   initialComments?: IComment[];
 *   initialNotifyMe?: boolean;
 *   initialThreadNotified?: User[];
 * }}
 */
export function CommentDataProvider({
  children,
  threadId,
  autoRefreshEnabled = false,
  initialComments = [],
  initialNotifyMe = false,
  initialThreadNotified = [],
}) {
  const { getAction } = useApi();

  const [clientId, setClientId] = useState(-1);

  const [comments, setComments] = useState(initialComments);
  const [notifyMe, setNotifyMe] = useState(initialNotifyMe);
  const [threadNotified, setThreadNotified] = useState(initialThreadNotified);
  const [loading, setLoading] = useState(true);

  const [autoRefreshToken, setAutoRefreshToken] = useState(0);
  const forceAutoRefresh = useCallback(() => setAutoRefreshToken((curr) => curr + 1), []);
  const autoRefreshInterval = useInterval(forceAutoRefresh, COMMENT_AUTO_REFRESH_INTERVAL);

  const cacheKey = useMemo(() => `toolio.comment.${threadId}.create.cache`, [threadId]);
  const [{ cache }, setCacheImpl] = useLocalStorage(cacheKey, { cache: undefined });

  const setCache = useCallback((cache) => setCacheImpl({ cache }), [setCacheImpl]);
  const resetCache = useCallback(() => setCache(undefined), [setCache]);

  /**
   * Returns the comment with the specified ID.
   *
   * @type {IGetComment}
   */
  const getComment = useCallback(
    (commentId) => comments.find((comment) => comment.commentId === commentId),
    [comments]
  );

  /**
   * Adds a new comment.
   *
   * @type {IAddComment}
   */
  const addComment = useCallback(
    (comment) =>
      setComments((curr) => {
        const exists = curr.some((c) => c.commentId === comment.commentId);
        return exists ? curr : [comment, ...curr];
      }),
    [setComments]
  );

  /**
   * Edits the specified comment.
   *
   * @type {IUpdateComment}
   */
  const updateComment = useCallback(
    (commentId, update) => {
      const updateFn = isFunction(update) ? update : (comment) => ({ ...comment, ...update });

      setComments((curr) =>
        curr.map((comment) => (comment.commentId === commentId ? { ...comment, ...updateFn(comment) } : comment))
      );
    },
    [setComments]
  );

  /**
   * Removes the specified comment.
   *
   * @type {IRemoveComment}
   */
  const removeComment = useCallback(
    (commentId) => setComments((curr) => curr.filter((comment) => comment.commentId !== commentId)),
    [setComments]
  );

  /**
   * Removes the specified attachment from specified comment.
   *
   * @type {IRemoveAttachment}
   */
  const removeAttachment = useCallback(
    (commentId, fileId) =>
      updateComment(commentId, ({ attachments }) => ({
        attachments: attachments.filter((attachment) => attachment.fileId !== fileId),
      })),
    [updateComment]
  );

  /**
   * Reacts to a comment.
   *
   * @type {IReactToComment}
   */
  const reactToComment = useCallback(
    (commentId, reactionType) =>
      updateComment(commentId, ({ reactions }) => ({
        reactions: reactions.map((reaction) => {
          const newCount =
            reaction.count +
            (reaction.type === reactionType ? 1 : 0) - // This reaction becomes active
            (reaction.active ? 1 : 0); // This reaction becomes inactive

          return {
            ...reaction,
            active: reaction.type === reactionType,
            count: newCount,
          };
        }),
      })),
    [updateComment]
  );

  /**
   * Updates the notifyMe setting.
   */
  const updateNotifyMe = useCallback(
    (newNotifyMe) => {
      setNotifyMe(newNotifyMe);
    },
    [setNotifyMe]
  );

  /**
   * Fetches comments for the thread.
   */
  const fetchComments = async () => {
    const commentThreadGetListAction = getAction('CommentThreadGetListAction');

    const clientBefore = clientId;
    const commentsBefore = comments;
    const notifyMeBefore = notifyMe;
    const threadNotifiedBefore = threadNotified;
    const hashBefore = objectHash(commentsBefore);

    try {
      const { comments, notify_me, thread_notified, client_id } = await commentThreadGetListAction({
        parameters: { comment_thread_id: threadId },
      });

      setClientId((clientAfter) => {
        if (clientBefore === clientAfter) {
          return client_id;
        } else {
          return clientAfter;
        }
      });

      setComments((commentsAfter) => {
        const hashAfter = objectHash(commentsAfter);

        if (hashBefore === hashAfter) {
          return comments.map(parseComment);
        } else {
          return commentsAfter;
        }
      });

      setNotifyMe((notifyMeAfter) => {
        if (notifyMeBefore === notifyMeAfter) {
          return notify_me;
        } else {
          return notifyMeAfter;
        }
      });

      setThreadNotified((threadNotifiedAfter) => {
        if (isEqual(threadNotifiedBefore, threadNotifiedAfter)) {
          return thread_notified;
        } else {
          return threadNotifiedAfter;
        }
      });
    } catch (error) {
      return panic(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch comments
  useEffect(() => {
    fetchComments();
  }, [threadId, autoRefreshToken]);

  // Enable / disable auto-refresh
  useEffect(() => {
    if (autoRefreshEnabled) {
      autoRefreshInterval.start();
    } else {
      autoRefreshInterval.stop();
    }

    return autoRefreshInterval.stop;
  }, [autoRefreshEnabled]);

  const value = useMemo(
    () => ({
      threadId,
      clientId,
      comments,
      notifyMe,
      threadNotified,
      loading,
      cache,
      fetchComments,
      getComment,
      addComment,
      updateComment,
      removeComment,
      removeAttachment,
      reactToComment,
      updateNotifyMe,
      setCache,
      resetCache,
    }),
    [
      threadId,
      clientId,
      comments,
      notifyMe,
      threadNotified,
      loading,
      cache,
      fetchComments,
      getComment,
      addComment,
      updateComment,
      removeComment,
      removeAttachment,
      reactToComment,
      updateNotifyMe,
      setCache,
      resetCache,
    ]
  );

  return <CommentDataContext.Provider value={value}>{children}</CommentDataContext.Provider>;
}

/**
 * Uses the comment data context.
 */
export function useCommentData() {
  return useContext(CommentDataContext);
}
