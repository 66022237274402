import { Menu } from '@mantine/core';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import Truncate from 'components/Truncate';
import { useMemo } from 'react';

/**
 * The heading of the Finance pages.
 *
 * @param {{
 *   heading: string,
 *   actionButton: React.ReactElement,
 *   menu?: React.ReactElement,
 *   menuDropdown?: React.ReactElement,
 *   breadcrumbs?: React.ReactElement,
 *   className?: string
 * }}
 */
export default function PageHeading({
  heading,
  actionButton,
  menu,
  menuDropdown,
  breadcrumbs = <></>,
  className = '',
}) {
  const menuComponent = useMemo(() => {
    if (menu) {
      return menu;
    }

    if (menuDropdown) {
      return (
        <Menu position="bottom-end" offset={15}>
          <Menu.Target>
            <div className="cursor-pointer pb-[7px] pt-[7px]">
              <OptionsDotsIcon />
            </div>
          </Menu.Target>
          {menuDropdown}
        </Menu>
      );
    }

    return <></>;
  }, [menu, menuDropdown]);

  return (
    <div className={className}>
      <div className="flex flex-col justify-center gap-2 py-2">
        {breadcrumbs && <div className="flex h-4 items-center">{breadcrumbs}</div>}
        <div className="flex flex-row items-center justify-between gap-4 md:gap-16">
          <Truncate
            className="flex-grow text-[20px] leading-[48px] text-nautral-700 md:text-[40px] md:font-light"
            text={heading}
          />
          <div className="flex flex-nowrap gap-4">
            {actionButton}
            {menuComponent}
          </div>
        </div>
      </div>
    </div>
  );
}
