/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./price-list-create-action-body').PriceListCreateActionBody} PriceListCreateActionBody
 */

/**
 * @typedef {import('./price-list-create-action-response').PriceListCreateActionResponse} PriceListCreateActionResponse
 */

/**
 * The path of the PriceListCreateAction action.
 */
export const priceListCreateActionPath = new SweetPath('/api/finance/price_list', { path: '{param}' });

/**
 * Creates a contextualized PriceListCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPriceListCreateAction(axios) {
  /**
   * The priceListCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PriceListCreateActionBody } }
   *
   * @returns {Promise<PriceListCreateActionResponse>}
   */
  function priceListCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = priceListCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return priceListCreateAction;
}
