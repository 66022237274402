import { Box, Flex, Group, Menu, Popover, Stack, Text } from '@mantine/core';
import StopIcon from '../icons/StopIcon';
import PlayIcon from '../icons/PlayIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import OptionsDotsIcon from '../icons/OptionsDotsIcon';
import PauseIcon from '../icons/PauseIcon';
import { useTimer } from './TimerProvider';
import { _t } from 'lang';
import CommentIcon from 'components/icons/CommentIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import Truncate from 'components/Truncate';
import AddIcon from 'components/icons/AddIcon';
import { useDisclosure } from '@mantine/hooks';
import { usePickTaskSubtask } from 'providers/pick-task-subtask/PickTaskSubtaskProvider';
import { useCallback } from 'react';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { EditDescriptionProvider, useEditDescription } from './EditDescriptionProvider';

/**
 * Timer controls
 *
 * @param {{
 *   isRunning: boolean,
 *   isPaused: boolean,
 *   setIsRunning: (value: boolean) => void,
 *   togglePaused: () => void,
 *   stopPause: () => void
 * }}
 */
function TimerControls() {
  const { isPaused, isRunning, setIsRunning, togglePaused, stopPause, saveTimer } = useTimer();

  return (
    <Flex gap={8}>
      <Box
        className={`${isRunning ? 'cursor-pointer' : 'cursor-not-allowed'}`}
        onClick={() => {
          if (isRunning) {
            saveTimer();
          }
        }}
      >
        {isRunning ? <StopIcon fill="#BF0D38" /> : <StopIcon />}
      </Box>

      {(isPaused || !isRunning) && (
        <Box
          className="cursor-pointer"
          onClick={() => {
            stopPause();
            setIsRunning(true);
          }}
        >
          <PlayIcon />
        </Box>
      )}

      {isRunning && !isPaused && (
        <Box
          className="cursor-pointer"
          onClick={() => {
            togglePaused();
          }}
        >
          <PauseIcon />
        </Box>
      )}
    </Flex>
  );
}

/**
 * Represents a component for displaying time with a suffix.
 *
 * @param {{
 *   time: string,
 *   suffix: string
 * }} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
const TimeDisplayText = ({ time, suffix }) => {
  return (
    <Flex align="center">
      <Text fz={15} weight={500} lh={20 / 15} c="#FFF">
        {time}
      </Text>
      <Text className="overflow-ellipsis" fz={12} lh={16 / 12} c="#FFF">
        {suffix}
      </Text>
    </Flex>
  );
};

/**
 * Displays the elapsed time.
 */
function TimerDisplay() {
  const { formattedTime } = useTimer();

  return (
    <Flex gap={4} w={90}>
      <TimeDisplayText time={formattedTime[0]} suffix="h" />
      <TimeDisplayText time={formattedTime[1]} suffix="m" />
      <TimeDisplayText time={formattedTime[2]} />
    </Flex>
  );
}

/**
 * Displays task and project name.
 *
 * @param {{
 *   taskName: string,
 *   projectName: string,
 *   isActive?: boolean
 * }}
 */
function TaskAndProjectDisplay({ taskName, projectName, isActive = false }) {
  return (
    <Stack spacing={0} className="hover:bg-complementary-indigo" w="100%" px={16} py={isActive ? 0 : 4}>
      <Truncate text={taskName} className="text-[12px] leading-[16px] text-white" />
      <Truncate text={projectName} className="text-[12px] leading-[16px] text-complementary-ametyst" />
    </Stack>
  );
}

/**
 * Allows the user select a task or add a new one.
 */
function TimerTaskSelect() {
  const { pickTaskSubtask } = usePickTaskSubtask();

  const { activeTimer, pausedTimers, excludeTaskIds, addTimer, restartTimer } = useTimer();
  const [menuOpened, { toggle: toggleMenu }] = useDisclosure(false);

  const addTask = useCallback(() => {
    pickTaskSubtask({
      onPick: addTimer,
      excludeTaskIds,
    });
  }, [pickTaskSubtask]);

  return (
    <Box style={{ borderLeft: '1px solid #908EFB' }}>
      <Popover
        opened={menuOpened}
        onChange={toggleMenu}
        closeOnClickOutside
        closeOnEscape
        width={194}
        offset={0}
        position="bottom"
        styles={{
          dropdown: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            padding: '0',
            paddingTop: '8px',
            paddingBottom: '8px',
            backgroundColor: '#38298B',
            border: '0px',
            color: '#FFF',
          },
        }}
      >
        <Popover.Target>
          <Group spacing={8} pr={8}>
            {/* Tasks display */}
            <Group w={154}>
              {activeTimer ? (
                <TaskAndProjectDisplay
                  taskName={activeTimer.task_full_name}
                  projectName={activeTimer.project_full_name}
                  isActive={true}
                />
              ) : (
                <Group
                  className="cursor-pointer hover:bg-complementary-indigo"
                  onClick={addTask}
                  px={16}
                  py={4}
                  w="100%"
                >
                  <Text c="#FFF" fz={12} lh={16 / 12}>
                    {_t('Select task')}
                  </Text>
                </Group>
              )}
            </Group>

            {/* The actual target */}
            <Box className="cursor-pointer pb-[7px] pt-[7px]" onClick={toggleMenu}>
              <ArrowDownIcon stroke="#FFFFFF" />
            </Box>
          </Group>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack spacing={0}>
            {/* TODO allow reorder of paused timers */}
            {pausedTimers.map((timer) => (
              <Group key={timer.timer_id} onClick={() => restartTimer(timer.timer_id)}>
                <TaskAndProjectDisplay
                  taskName={timer.task_full_name}
                  projectName={timer.project_full_name}
                  isActive={false}
                />
              </Group>
            ))}

            <Group px={16} py={4} className="hover:bg-complementary-indigo" onClick={addTask}>
              <AddIcon stroke="#FFF" />
              <Text c="#FFF" fz={12} lh={16 / 12}>
                {_t('Add task')}
              </Text>
            </Group>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
}

/**
 * Three dots menu for timer.
 */
function TimerOptions() {
  const { pickTaskSubtask } = usePickTaskSubtask();
  const { editDescription } = useEditDescription();
  const { confirm } = useConfirm();
  const { activeTimer, excludeTaskIds, removeTimer, addTimer, refresh } = useTimer();

  const addTask = useCallback(() => {
    pickTaskSubtask({
      onPick: addTimer,
      excludeTaskIds,
    });
  }, [pickTaskSubtask]);

  const handleEditDescription = useCallback(() => {
    if (activeTimer) {
      editDescription({
        description: activeTimer.description,
        taskName: activeTimer.task_full_name,
        // eslint-disable-next-line
        onEdit: (description) => {
          // TODO call API to update the description

          // then refresh the data
          refresh();
        },
      });
    }
  }, [editDescription, refresh, activeTimer]);

  const handleDelete = useCallback(() => {
    confirm({
      title: _t('Remove timer'),
      message: `${_t('Are you sure you want to remove this timer? Task name:')} ${activeTimer.task_full_name}`,
      onConfirm: removeTimer,
    });
  }, [confirm, activeTimer, removeTimer]);

  return (
    <Menu withinPortal>
      <Menu.Target>
        <Box className="cursor-pointer pb-[7px] pt-[7px]">
          <OptionsDotsIcon width={24} height={24} fill="#CECDFF" />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={addTask} icon={<AddIcon stroke="#4D4D4D" />}>
          {_t('Add task')}
        </Menu.Item>
        {activeTimer && (
          <Menu.Item onClick={handleEditDescription} icon={<CommentIcon stroke="#4D4D4D" />}>
            {_t('Edit description')}
          </Menu.Item>
        )}
        {activeTimer && (
          <Menu.Item onClick={handleDelete} icon={<DeleteIcon stroke="#BF0D38" />} c="#BF0D38">
            {_t('Remove from list')}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

/**
 * @function Timer
 * @description Creates a timer component.
 *
 * @return {JSX.Element} The timer component.
 */
export default function Timer() {
  return (
    <EditDescriptionProvider>
      <Flex className="rounded-lg bg-main-primary" py={4} px={8} h={40} align="center">
        <Group spacing={8}>
          <TimerControls />
          <TimerDisplay />
        </Group>

        <TimerTaskSelect />

        <TimerOptions />
      </Flex>
    </EditDescriptionProvider>
  );
}
