import { Menu } from '@mantine/core';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { _t } from 'lang';
import { useState } from 'react';
import { Link } from 'react-router-dom';

/**
 * Displays a single row in DataTable.
 *
 * @template TRow
 *
 * @param {{
 *   data: TRow,
 *   gridLayoutCss: React.CSSProperties,
 *   RowComponent?: (props: { data: TRow, hover: boolean }) => React.ReactNode,
 *   editLink: (data: TRow) => string,
 *   // TODO maybe use Menu.Items props
 *   menuItems?: {
 *    key: string,
 *    icon: React.ReactElement,
 *    onClick: () => void,
 *    color?: string,
 *    label: string,
 *    showIf: boolean
 *    }[],
 * }}
 */
export default function DataTableRow({ data, gridLayoutCss, RowComponent, editLink, menuItems = [] }) {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={gridLayoutCss}
      className="grid min-h-[84px] items-center border-b border-b-grey-200 pb-[12px] pl-[10px] pr-[10px] pt-[12px] text-[15px] leading-[18px] text-grey-800"
    >
      {/* Custom rendered content */}
      {<RowComponent data={data} hover={hover} />}

      {/* Actions button */}
      <div className="flex justify-end">
        <Menu position="bottom-end" offset={15}>
          <Menu.Target>
            <div className="cursor-pointer pb-[7px] pl-[10px] pr-[10px] pt-[7px]">
              <OptionsDotsIcon />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item component={Link} to={editLink(data)} icon={<EditIcon fill="#4D4D4D" />}>
              {_t('Edit')}
            </Menu.Item>
            {menuItems
              .filter((item) => item.showIf(data))
              .map((item) => (
                <Menu.Item key={item.key} icon={item.icon} onClick={() => item.onClick(data)} c={item.color}>
                  {item.label}
                </Menu.Item>
              ))}
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
}
