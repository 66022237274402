/** @typedef {import('api/actions/team-get-list-action/team-get-list-action-response').TeamGetListActionResponse[number]} Team */

import StatusSwitch from 'components/inputs/status-switch/StatusSwitch';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';

/**
 * Status switch for the team model.
 *
 * @param {{
 *   team: Team;
 * }}
 */
export default function TeamStatusSwitch({ team }) {
  const { getAction } = useApi();
  const teamSetActiveAction = getAction('TeamSetActiveAction');

  let disabled = false;
  let reason = null;

  if (team.members.length > 0) {
    disabled = true;
    reason = _t('Cannot deactivate a team that has members.');
  }

  return (
    <StatusSwitch
      entity={_t('Team')}
      name={team.name}
      action={teamSetActiveAction}
      primaryKey={team.team_id}
      pathParameterName="team_id"
      initialValue={team.is_active}
      disabled={disabled}
      disabledReason={reason}
    />
  );
}
