import { _t } from 'lang';

/**
 * Displays due date.
 *
 * @param {{
 *   dueDate?: string;
 * }}
 */
export default function DueDateDisplay({ dueDate }) {
  return (
    <div className="text-right text-[12px] leading-[16px] text-neutral-700">
      {dueDate ? new Date(dueDate).toLocaleDateString() : _t('No due date')}
    </div>
  );
}
