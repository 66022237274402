import { useCallback, useState } from 'react';

/**
 * Hook to manage an immutable list.
 *
 * @template {string} TKeyName
 * @template {Record<TKeyName,any>} TItem
 *
 * @param {TKeyName} keyProp
 * @param {TItem[] | (() => TItem[])} [initialList=[]]
 *
 * @returns {[TItem[], { add: (item: TItem) => void, remove: (key: TItem[TKeyName]) => void, update: (key: TItem[TKeyName], updates: Partial<Omit<TItem,TKeyName>>) => void }]}
 */
export default function useImmutableList(keyProp, initialList = []) {
  const [list, setList] = useState(initialList);

  const add = useCallback(
    /** @param {TItem} item */
    (item) => setList((prev) => [...prev, item]),
    []
  );

  const remove = useCallback(
    /** @param {TItem[TKeyName]} key */
    (key) => setList((prev) => prev.filter((item) => item[keyProp] !== key)),
    []
  );

  const update = useCallback(
    /**
     * @param {TItem[TKeyName]} key
     * @param {Partial<Omit<TItem,TKeyName>>} updates
     */
    (key, updates) => setList((prev) => prev.map((item) => (item[keyProp] === key ? { ...item, ...updates } : item))),
    []
  );

  return [list, { add, remove, update }];
}
