/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['NumberInput']} NumberInputTheme */

import { ErrorStyles } from 'theme/components/Inputs/shared/Error';

/** @type {NumberInputTheme} */
export const NumberInput = {
  styles: (theme, { withRightSection }) => {
    return {
      rightSection: {
        backgroundColor: '#FFFFFF',
      },
      input: {
        backgroundColor: '#FFFFFF !important',
        borderRadius: withRightSection ? '8px 0 0 8px !important' : '8px !important',
      },
      error: ErrorStyles,
    };
  },
};
