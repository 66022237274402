/**
 * @typedef {{ message: string} | null} ValidationError
 */

/**
 * @typedef {(value: string) => ValidationError} ValidationRule
 */

/**
 * Creates a validator from the given validation rules.
 *
 * @param {Array<ValidationRule>} rules
 */
export const createValidator = (rules) => {
  /**
   * Validates the input.
   * @param {string} value
   */
  const validate = (value) => {
    for (const rule of rules) {
      const error = rule(value);

      if (error) {
        return error.message;
      }
    }

    return null;
  };

  return validate;
};
