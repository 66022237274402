/** @typedef {import('api/file-manager/FileManagerProvider').ThumbnailSize} ThumbnailSize */

import { Box, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import Preloader from 'components/Preloader';
import panic from 'errors/Panic';
import { useEffect, useState } from 'react';

/**
 * Displays a single image.
 *
 * @param {{
 *   fileId: string;
 *   alt?: string;
 *   title?: string;
 *   imageSize?: ThumbnailSize;
 * }}
 */
export default function ImageDisplay({ fileId, alt, title, imageSize = '' }) {
  const { createAccessToken } = useFileManager();
  const [url, setUrl] = useState('');
  const [loading, { open: startLoading, close: stopLoading }] = useDisclosure(true);

  useEffect(() => {
    startLoading();

    createAccessToken({ fileId })
      .then((token) => {
        let url = `/api/files/one-time/file/${token}`;

        if (imageSize) {
          url += `?thumbnailSize=${encodeURIComponent(imageSize)}`;
        }

        setUrl(url);
      })
      .catch(panic);
  }, [fileId]);

  return (
    <Box w="100%" h="100%" pos="relative">
      <LoadingOverlay visible={loading} loader={<Preloader size={32} />} />
      <img
        className="m-auto max-h-[calc(100vh-300px)] max-w-[650px]"
        src={url}
        alt={loading ? '' : alt}
        title={loading ? '' : title}
        onLoad={stopLoading}
      />
    </Box>
  );
}
