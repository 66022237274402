/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-get-action-body').TaskGetActionBody} TaskGetActionBody
 */

/**
 * @typedef {import('./task-get-action-response').TaskGetActionResponse} TaskGetActionResponse
 */

/**
 * The path of the TaskGetAction action.
 */
export const taskGetActionPath = new SweetPath('/api/project/project/task/{task_id}', { path: '{param}' });

/**
 * Creates a contextualized TaskGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskGetAction(axios) {
  /**
   * The taskGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<TaskGetActionResponse>}
   */
  function taskGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = taskGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return taskGetAction;
}
