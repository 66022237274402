import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { PRICE_LISTS_PAGE_PATH } from 'routes/paths';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE, SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import { useEffect, useState } from 'react';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import PriceListForm from 'components/forms/price-list-form/PriceListForm';
import panic from 'errors/Panic';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the EditPriceList page.
 *
 * @param {{ priceListId: string }}
 */
function EditPriceListImpl({ priceListId }) {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshPriceLists } = useSettingsSidebar();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const priceListGetAction = getAction('PriceListGetAction');

    setLoading(true);
    priceListGetAction({ parameters: { price_list_id: priceListId } })
      .then((data) => {
        const rawClients = data.clients.length === 0 ? [] : data.clients.map((client) => String(client.client_id));

        const rawPriceListItems = data.price_list_items.reduce(
          (agg, priceListItem) => ({
            ...agg,
            [priceListItem.position_in_company_id]: {
              positionInCompanyId: priceListItem.position_in_company_id,
              positionName: priceListItem.position_in_company_name,
              projectBasedRate: priceListItem.project_based_rate,
              flatFeeRate: priceListItem.flat_fee_rate,
              currency: priceListItem.currency,
              isCustom: priceListItem.is_custom,
            },
          }),
          {}
        );

        const initialValues = {
          priceListName: data.price_list_name,
          note: data.note ?? '',
          isDefault: data.is_default,
          clients: rawClients,
          items: rawPriceListItems,
          currency: data.currency,
          validFrom: data.valid_from ? new Date(data.valid_from) : '',
          validTo: data.valid_to ? new Date(data.valid_to) : '',
        };

        setInitialValues(initialValues);
      })
      .catch((e) => {
        if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
          setInitialValues(null);
        } else {
          panic(e);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, priceListId]);

  if (!priceListId || (!loading && !initialValues)) {
    return <NotFoundPageContent />;
  }

  /**
   * Makes the call to the API
   *
   * @param {typeof initialValues}
   */
  const submit = ({ priceListName, note, clients, items, isDefault, currency, validFrom, validTo }) => {
    if (Object.keys(items).length === 0) {
      alert('No price list items available!');
      return;
    }

    const priceListUpdateAction = getAction('PriceListUpdateAction');
    const mappedPriceListItems = [];

    for (let key of Object.keys(items)) {
      const priceListItem = items[key];

      mappedPriceListItems.push({
        position_in_company_id: priceListItem.positionInCompanyId,
        project_based_rate: priceListItem.projectBasedRate,
        flat_fee_rate: priceListItem.flatFeeRate,
        is_custom: isDefault ? true : priceListItem.isCustom,
      });
    }

    // because the Mantine Select component returns the values as strings
    const mappedClients = clients.map((client) => Number(client));

    return priceListUpdateAction({
      parameters: {
        price_list_id: priceListId,
      },
      body: {
        price_list_name: priceListName,
        note,
        clients: mappedClients,
        price_list_items: mappedPriceListItems,
        is_default: isDefault,
        currency,
        valid_from: validFrom ? validFrom.toLocaleDateString() : null,
        valid_to: validTo ? validTo.toLocaleDateString() : null,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Price list updated'),
          message: _t("Price list '%s' has been updated successfully.", priceListName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshPriceLists();
      })
      .catch((e) => {
        panic(e);

        navigate(PRICE_LISTS_PAGE_PATH.original);
      });
  };

  return (
    <>
      <PageHeading heading={_t('Edit price list')} />
      {!loading && (
        <PriceListForm initialValues={initialValues} onSubmit={submit} isDefaultPriceList={initialValues.isDefault} />
      )}
    </>
  );
}

/**
 * The Edit price list page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=515%3A28690&t=KmGU0c7SRwOArYf9-0
 */
export default function EditPriceListPage() {
  const { priceListId } = useParams();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <EditPriceListImpl key={priceListId} priceListId={priceListId} />
      </div>
    </DashboardLayout>
  );
}
