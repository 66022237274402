import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { Link, useSearchParams } from 'react-router-dom';
import { ADD_PROJECT_PAGE_PATH } from 'routes/paths';
import { APP_HEADER_HEIGHT } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { Breadcrumbs, Button, Flex, Group, Input, Menu, Skeleton, Stack } from '@mantine/core';
import AddIcon from 'components/icons/AddIcon';
import EditIcon from 'components/icons/EditIcon';
import { useEffect, useMemo, useState } from 'react';
import XIcon from 'components/icons/XIcon';
import ShowDeletedSwitch from '../../../components/inputs/ShowDeletedSwitch';
import ClientProjects from 'pages/projects/project-overview/ClientProjects';
import ShowMyClientsSwitch from 'components/inputs/ShowMyClientsSwitch';
import ShowMyProjectsSwitch from 'components/inputs/ShowMyProjectsSwitch';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import SearchIcon from '../../../components/icons/SearchIcon';
import removeAccents from 'remove-accents';

/**
 * The main page in the projects module.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=240%3A5845
 */
export default function ProjectOverviewPage() {
  const { getAction, hasPermission, accessibleClients } = useApi();
  const [searchParams] = useSearchParams();
  const focusedClientId = useMemo(
    () => (searchParams.get('clientId') ? Number(searchParams.get('clientId')) : null),
    [searchParams]
  );
  const hasProjectCreatePermission = useMemo(() => hasPermission('PROJECTS_MANAGE_PROJECT', '*'), [hasPermission]);

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientFilter, setClientFilter] = useState(focusedClientId);
  const [clientSearch, setClientSearch] = useState('');
  const [showDeleted, { toggle: setShowDeleted }] = useLocalStorageDisclosure(
    'toolio.projects.overview.showDeleted',
    false
  );
  const [showOnlyMyClients, { toggle: setShowOnlyMyClients }] = useLocalStorageDisclosure(
    'toolio.projects.overview.myClientsOnly',
    false
  );
  const [showOnlyMyProjects, { toggle: setShowOnlyMyProjects }] = useLocalStorageDisclosure(
    'toolio.projects.overview.myProjectsOnly',
    false
  );

  // Fetch the clients.
  useEffect(() => {
    const clientGetListAction = getAction('ClientGetListAction');

    clientGetListAction({
      query: {
        filter: { 'is_active.eq': 1 },
        sort: [{ by: 'client_name', order: 'asc' }],
      },
    })
      .then(setClients)
      .catch(panic)
      .finally(() => setLoading(false));
  }, []);

  return (
    <DashboardLayout>
      <div className="bg-neutral-50 px-8 pb-8" style={{ minHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)` }}>
        <PageHeading
          heading={_t('Projects')}
          breadcrumbs={
            <Breadcrumbs>
              <span>{_t('Projects')}</span>
            </Breadcrumbs>
          }
          actionButton={
            hasProjectCreatePermission && (
              <Button leftIcon={<AddIcon />} variant="primary" component={Link} to={ADD_PROJECT_PAGE_PATH.original}>
                {_t('Project')}
              </Button>
            )
          }
          menuDropdown={
            hasProjectCreatePermission ? (
              <Menu.Dropdown>
                <Menu.Item component={Link} to={ADD_PROJECT_PAGE_PATH.original} icon={<EditIcon fill="#4D4D4D" />}>
                  {_t('Create project')}
                </Menu.Item>
              </Menu.Dropdown>
            ) : undefined
          }
        />
        {clientFilter && (
          <Group>
            <div className="text-[15px] leading-[18px]">
              {_t('Active filter')}: {clients?.find((client) => client.client_id === clientFilter)?.client_name}
            </div>
            <Button
              leftIcon={<XIcon />}
              onClick={() => {
                setClientFilter(null);
              }}
            >
              {_t('Remove filter')}
            </Button>
          </Group>
        )}
        <Group w="100%" spacing={32}>
          {hasPermission('PROJECTS_VIEW_PROJECT') && (
            <Flex justify="space-between" className="flex-1">
              <Input
                icon={<SearchIcon stroke="#B3B3B3" />}
                placeholder="Search client"
                onChange={(value) => setClientSearch(value.target.value)}
              />
              <Group spacing={32}>
                <ShowMyClientsSwitch value={showOnlyMyClients} onChange={setShowOnlyMyClients} />
                <ShowMyProjectsSwitch value={showOnlyMyProjects} onChange={setShowOnlyMyProjects} />
              </Group>
            </Flex>
          )}

          <ShowDeletedSwitch value={showDeleted} onChange={setShowDeleted} />
        </Group>
        <Stack spacing={16} py={16}>
          {loading ? (
            <>
              <Skeleton width="full" height={68} animate />
              <Skeleton width="full" height={68} animate />
              <Skeleton width="full" height={68} animate />
            </>
          ) : (
            (clients || [])
              .filter((client) => (clientFilter ? client.client_id === clientFilter : true))
              .filter((client) => !showOnlyMyClients || accessibleClients.includes(client.client_id))
              .filter(
                (client) =>
                  !clientSearch ||
                  removeAccents(client.client_name.toLowerCase().trim()).includes(
                    removeAccents(clientSearch.toLowerCase().trim())
                  )
              )
              .map((client) => (
                <ClientProjects
                  key={client.client_id}
                  clientId={client.client_id}
                  clientName={client.client_name}
                  showDeleted={showDeleted}
                  showOnlyMyProjects={showOnlyMyProjects}
                />
              ))
          )}
          {!loading && clients.length === 0 && <div>{_t('No clients found.')}</div>}
        </Stack>
      </div>
    </DashboardLayout>
  );
}
