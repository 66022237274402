/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-restore-action-body').TaskRestoreActionBody} TaskRestoreActionBody
 */

/**
 * @typedef {import('./task-restore-action-response').TaskRestoreActionResponse} TaskRestoreActionResponse
 */

/**
 * The path of the TaskRestoreAction action.
 */
export const taskRestoreActionPath = new SweetPath('/api/project/project/task/{task_id}/restore', { path: '{param}' });

/**
 * Creates a contextualized TaskRestoreAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskRestoreAction(axios) {
  /**
   * The taskRestoreAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskRestoreActionBody } }
   *
   * @returns {Promise<TaskRestoreActionResponse>}
   */
  function taskRestoreAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskRestoreActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return taskRestoreAction;
}
