/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./workspace-config-get-action-body').WorkspaceConfigGetActionBody} WorkspaceConfigGetActionBody
 */

/**
 * @typedef {import('./workspace-config-get-action-response').WorkspaceConfigGetActionResponse} WorkspaceConfigGetActionResponse
 */

/**
 * The path of the WorkspaceConfigGetAction action.
 */
export const workspaceConfigGetActionPath = new SweetPath('/api/status/workspace-config', { path: '{param}' });

/**
 * Creates a contextualized WorkspaceConfigGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createWorkspaceConfigGetAction(axios) {
  /**
   * The workspaceConfigGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<WorkspaceConfigGetActionResponse>}
   */
  function workspaceConfigGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = workspaceConfigGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return workspaceConfigGetAction;
}
