/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */

import { Button, Collapse, Group } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import CollapseArrow from 'components/CollapseArrow';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import ProjectsTable from 'pages/projects/project-overview/ProjectsTable';
import AddIcon from 'components/icons/AddIcon';
import { Link } from 'react-router-dom';
import { ADD_PROJECT_PAGE_PATH } from 'routes/paths';
import { useDisclosure } from '@mantine/hooks';
import DashboardLayoutOverlay from 'layouts/dashboard-layout/DashboardLayoutOverlay';
import Preloader from 'components/Preloader';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE } from 'utils/constants';

/**
 * @typedef {'column-1'} ColumnName
 *
 * @typedef {{
 *   id: string;
 *   content: {project: Project};
 * }} DraggableProject
 *
 * @typedef {{
 *   id: ColumnName;
 *   title: string;
 *   projectIds: string[];
 * }} DraggableColumn
 *
 * @typedef {{
 *   columnOrder: ColumnName[];
 *   projects: Record<string, DraggableProject>;
 *   columns: Record<ColumnName, DraggableColumn>;
 * }} DraggableProjects
 */

/** @type {DraggableProjects} */
const emptyDraggableProjects = {
  columnOrder: ['column-1'],
  projects: {},
  columns: {
    'column-1': {
      id: 'column-1',
      title: '',
      projectIds: [],
    },
  },
};

/**
 * The client table for the Project overview page.
 *
 * @param {{
 *   clientId: number;
 *   clientName: string;
 *   showDeleted?: boolean;
 *   showOnlyMyProjects?: boolean;
 * }}
 */
export default function ClientProjects({ clientId, clientName, showDeleted = false, showOnlyMyProjects = false }) {
  const ref = useRef();
  const { getAction, hasPermission, userId } = useApi();

  const key = useMemo(() => `toolio.project.overview.client.${clientId}.opened`, [clientId]);
  const [opened, { toggle: toggleOpened }] = useLocalStorageDisclosure(key);
  const [loading, setLoading] = useState(true);
  const [dragging, { open: startDragging, close: stopDragging }] = useDisclosure(false);
  const [canDrop, setCanDrop] = useState(false);

  const ready = useMemo(() => opened && !loading, [opened, loading]);
  const waiting = useMemo(() => opened && loading, [opened, loading]);

  const hasProjectCreatePermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );

  const [draggableProjects, setDraggableProjects] = useState(emptyDraggableProjects);

  const hasVisibleProject = useMemo(
    () =>
      Object.values(draggableProjects.projects).some(({ content: { project } }) => {
        if (!showDeleted && project.deleted_at) {
          return false;
        }

        if (showOnlyMyProjects && project.owner.user_id !== userId) {
          return false;
        }

        return true;
      }),
    [showDeleted, showOnlyMyProjects, draggableProjects]
  );

  const addProjectPath = useMemo(() => `${ADD_PROJECT_PAGE_PATH.original}?clientId=${clientId}`, [clientId]);

  const addProjectButton = useMemo(
    () =>
      hasProjectCreatePermission && (
        <Button variant="link" leftIcon={<AddIcon fill="#38298B" />} component={Link} to={addProjectPath}>
          {_t('Add Project')}
        </Button>
      ),
    [addProjectPath, hasProjectCreatePermission]
  );

  /** Updates the specified project. */
  const updateProject = useCallback(
    /**
     * @param {number} projectId
     * @param {Project} project
     */
    (projectId, project) =>
      setDraggableProjects((curr) => ({
        ...curr,
        projects: {
          ...curr.projects,
          [`project-${projectId}`]: { ...curr.projects[`project-${projectId}`], content: { project } },
        },
      })),
    []
  );

  /** Refreshes the data about a project. */
  const refreshProject = useCallback(
    /** @param {number} projectId */
    (projectId) => {
      const projectGetAction = getAction('ProjectGetAction');

      projectGetAction({ parameters: { project_id: projectId } })
        .then((project) => {
          updateProject(projectId, project);
        })
        .catch((e) => {
          if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
            throw new Error(`Project ${projectId} not found`);
          } else {
            panic(e);
          }
        });
    },
    [getAction, updateProject]
  );

  // Fetch projects when the client is opened.
  useEffect(() => {
    if (waiting) {
      setLoading(true);

      const projectGetListAction = getAction('ProjectGetListAction');

      projectGetListAction({
        query: {
          filter: { 'client_id.eq': clientId },
          sort: [{ by: 'ord', order: 'ASC' }],
        },
      })
        .then((projects) => {
          const groupedProjects = projects.reduce((acc, project) => {
            const id = `project-${project.project_id}`;
            return { ...acc, [id]: { id, content: { project } } };
          }, {});

          const projectIds = projects.map((project) => `project-${project.project_id}`);

          setDraggableProjects({
            projects: groupedProjects,
            columns: {
              'column-1': {
                id: 'column-1',
                title: '',
                projectIds: projectIds,
              },
            },
            columnOrder: ['column-1'],
          });
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [opened]);

  const borderRadius = ready ? 'rounded-t-[8px]' : 'rounded-[8px]';
  const color = waiting ? 'text-neutral-500' : 'text-neutral-700';

  return (
    <div ref={ref} className="rounded-[8px] bg-white">
      {dragging && <DashboardLayoutOverlay cursor={canDrop ? 'grabbing' : 'not-allowed'} />}
      <div className={`group flex h-[68px] items-center px-2 py-4 ${borderRadius} bg-[inherit] hover:bg-neutral-20`}>
        <div
          className={`flex w-full cursor-pointer items-center gap-x-2 pb-4 pt-4 text-[20px] leading-[36px] ${color}`}
          onClick={toggleOpened}
        >
          <CollapseArrow opened={opened} />
          <Group spacing={8}>
            {clientName} {waiting && <Preloader size={16} />}
          </Group>
        </div>
        <Group className="hidden group-hover:flex">{addProjectButton}</Group>
      </div>

      <Collapse
        in={ready}
        className={`flex flex-col ${ready ? 'rounded-b-[8px]' : ''}`}
        style={{ position: dragging ? 'relative' : 'static', zIndex: dragging ? 51 : 1 }}
      >
        {hasVisibleProject ? (
          <ProjectsTable
            state={draggableProjects}
            showDeleted={showDeleted}
            showOnlyMyProjects={showOnlyMyProjects}
            setState={setDraggableProjects}
            onProjectUpdate={refreshProject}
            onDragStart={startDragging}
            onDragEnd={stopDragging}
            onCanDropChange={setCanDrop}
          />
        ) : (
          <div className="py-3 pl-9">{addProjectButton}</div>
        )}
      </Collapse>
    </div>
  );
}
