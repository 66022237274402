import { _t } from 'lang';
import FinanceFormLayout from 'layouts/finance-form-layout/FinanceFormLayout';
import EditInvoicePageContent from './EditInvoicePageContent';

/**
 * Displays a form to edit an invoice.
 *
 * @see {@link https://www.figma.com/file/CQQ1mMQRmo9H7dsJSejbRd/Toolio---Design?node-id=82%3A3089&t=70yfhn5wGgjFtKdu-0 Figma Design}
 */
export default function EditInvoicePage() {
  return (
    <FinanceFormLayout heading={_t('Edit invoice')}>
      {(costEstimate) => <EditInvoicePageContent costEstimate={costEstimate} />}
    </FinanceFormLayout>
  );
}
