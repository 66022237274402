/** @typedef {import('components/files/upload/types').IFileState} IFileState */

import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import { useForm } from '@mantine/form';
import { _t } from 'lang';
import { Box, Stack, TextInput, Textarea } from '@mantine/core';
import InputWrapper from 'components/inputs/InputWrapper';
import MoneyInput from 'components/inputs/MoneyInput';
import FormRow from 'components/forms/FormRow';
import UploadFiles from 'components/files/upload/UploadFiles';
import FormFooter from 'components/forms/FormFooter';
import useLoadingAction from 'hooks/use-loading-action';
import DatePicker from 'components/inputs/DatePicker';

/**
 *  The Add / Edit Invoice Form.
 *
 * @param {{
 *   initialValues: object,
 *   onSubmit: (values: object) => void | Promise<void>,
 *   currency?: string,
 *   initialFiles: IFileState[],
 * }}
 */
export default function InvoiceForm({ initialValues, onSubmit, currency = 'EUR', initialFiles = [] }) {
  const [loading, submit] = useLoadingAction(onSubmit);

  const form = useForm({
    initialValues,

    validate: {
      invoiceNumber: createValidator([required]),
      issued: createValidator([required]),
      supplied: createValidator([required]),
      dueDate: createValidator([required]),
      fileId: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={16}>
        <Stack spacing={16}>
          <FormRow
            label={_t('Invoice')}
            input={<UploadFiles maxFiles={1} {...form.getInputProps('fileId')} initialFiles={initialFiles} />}
          />
          <FormRow
            label={_t('Invoice number')}
            input={<TextInput type="text" {...form.getInputProps('invoiceNumber')} />}
          />
          <FormRow
            label={_t('Invoiced amount')}
            input={<MoneyInput disabled currency={currency} {...form.getInputProps('amount')} />}
          />
          <FormRow label={_t('Invoice date')} input={<DatePicker {...form.getInputProps('issued')} />} />
          <FormRow label={_t('Date of supply')} input={<DatePicker {...form.getInputProps('supplied')} />} />
          <FormRow label={_t('Payment due date')} input={<DatePicker {...form.getInputProps('dueDate')} />} />
        </Stack>

        <Box pt={16}>
          <InputWrapper
            label={_t('Internal note')}
            align="column"
            inputSize="full"
            input={<Textarea placeholder={_t('Enter note')} minRows={5} {...form.getInputProps('note')} />}
          />
        </Box>

        <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
      </Stack>
    </form>
  );
}
