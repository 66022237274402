/**
 * Creates monogram from given text.
 * Takes the first letter of the first word and the first letter of the last word.
 * @param {string} text
 */
export const createMonogram = (text) => {
  if (!text) {
    return '';
  }

  const trimmedText = text.trim(); // remove leading and trailing spaces

  const parts = trimmedText.split(/\s+/);

  if (parts.length === 1) {
    return text.charAt(0).toUpperCase();
  }

  const first = parts[0].charAt(0);
  const last = parts[parts.length - 1].charAt(0);

  return `${first}${last}`.toUpperCase();
};
