import { Tooltip } from '@mantine/core';
import { useCallback, useRef, useState } from 'react';

/**
 * Truncates the text to the specified length in pixels.
 *
 * TODO: Consider using resize observer to update the tooltip when the layout changes.
 *
 * @param {{
 *   text: string;
 *   className?: string;
 *   tooltipOpenDelay?: number;
 *   multilineTextLength?: number;
 *   multilineWidth?: number;
 * }}
 */
export default function Truncate({
  text,
  className,
  tooltipOpenDelay = 500,
  multilineTextLength = 100,
  multilineWidth = 400,
}) {
  const [hasOverflow, setHasOverflow] = useState(false);
  const outerRef = useRef();
  const innerRef = useRef();

  const checkOverflow = useCallback(() => {
    const hasOverflow =
      outerRef.current && innerRef.current && innerRef.current.offsetWidth > outerRef.current.offsetWidth;

    setHasOverflow(hasOverflow);
  }, []);

  const multiline = text.length > multilineTextLength;
  const width = multiline ? multilineWidth : 'auto';

  return (
    <Tooltip
      label={text}
      openDelay={tooltipOpenDelay}
      disabled={!hasOverflow}
      withinPortal
      onMouseEnter={checkOverflow}
      multiline={multiline}
      width={width}
    >
      <div ref={outerRef} className={`truncate ${className}`}>
        <span ref={innerRef}>{text}</span>
      </div>
    </Tooltip>
  );
}
