import { useState } from 'react';
import { useApi } from '../../../api/ApiContext';
import { Flex, Grid, Text, Textarea } from '@mantine/core';
import parseDuration from 'utils/parse-duration';
import { _t } from '../../../lang';
import { showNotification } from '@mantine/notifications';
import { MAX_LOGGED_MINUTES, SUCCESS_NOTIFICATION_COLOR } from '../../../utils/constants';
import DurationInput from '../../inputs/DurationInput';

/**
 * Handles the time log column component.
 *
 * @param {Object} options - The options object.
 * @param {number} options.time - The duration of the time log.
 * @param {boolean} options.active - Indicates whether the time log is active or not.
 * @param {string} options.description - The description of the time log.
 * @param {string} options.time_log_id - The ID of the time log.
 * @param {Function} options.refresh - The refresh function.
 * @param {Date} options.date - The date of the time log.
 * @param {Object} options.task - The task object.
 * @returns {JSX.Element} - The time log column element.
 */
export default function TimeLogColumn({ time, active = true, description = '', time_log_id, refresh, date, task }) {
  const [inputDescriptionValue, setInputDescriptionValue] = useState(description);
  const { getAction } = useApi();
  const timeLogUpdateDurationAction = getAction('TimeLogUpdateDurationAction');
  const timeLogUpdateDescriptionAction = getAction('TimeLogUpdateDescriptionAction');
  const actualDay = new Date();
  const isActualDay = actualDay.getDate() === date?.getDate() && actualDay.getMonth() === date?.getMonth();
  const isWeekend = date?.getDay() === 0 || date?.getDay() === 6;
  const [toggleDescriptionInput, setToggleDescriptionInput] = useState(false);

  if (!active) {
    return (
      <Grid.Col
        span={1}
        py={18}
        className={`border-b border-b-neutral-50
          ${isActualDay ? 'bg-main-secondary' : ''}
          ${isWeekend ? 'border-b-white bg-neutral-50' : ''}
    `}
      ></Grid.Col>
    );
  }

  /**
   * Handles the change of duration for a time log.
   *
   * @param {number} duration - The new duration for the time log.
   * @returns {void}
   */
  const handleDurationChange = (duration) => {
    const parsed = parseDuration(duration);

    timeLogUpdateDurationAction({
      parameters: {
        time_log_id,
      },
      body: {
        duration: parsed,
      },
    }).then(() => {
      showNotification({
        title: _t('Time logged successfully'),
        color: SUCCESS_NOTIFICATION_COLOR,
      });

      refresh();
    });
  };

  /**
   * Handles the change event of the description input field.
   *
   * @param {ChangeEvent} event - The change event object.
   * @returns {void}
   */
  const handleDescriptionChange = (event) => {
    setInputDescriptionValue(event.target.value);
  };

  /**
   * Event handler for description input blur.
   *
   * @param {Object} event - The event object.
   */
  const handleDescriptionBlur = (event) => {
    const description = event.target.value;

    timeLogUpdateDescriptionAction({
      parameters: {
        time_log_id,
      },
      body: {
        description,
      },
    }).then(() => {
      setToggleDescriptionInput(false);
      refresh();
    });
  };

  return (
    <Grid.Col
      span={1}
      className={`border-b border-b-neutral-50 px-2 py-4
          ${isActualDay ? 'bg-main-secondary' : ''}
          ${isWeekend ? 'border-b-white bg-neutral-50' : ''}
    `}
      w="100%"
    >
      <Flex align="center" w="100%" gap={8} h="100%" direction="column" className="relative">
        <DurationInput
          value={time}
          max={MAX_LOGGED_MINUTES}
          onChange={handleDurationChange}
          className="[&_input]:text-right"
          disabled={task.closed_at || task.deleted_at}
        />
        <Text
          className={`relative w-full text-right text-xs hover:text-main-primary
        ${inputDescriptionValue ? 'text-neutral-700' : 'text-neutral-500'}
        `}
          onClick={() => setToggleDescriptionInput(true)}
        >
          {inputDescriptionValue || _t('Add description')}

          {toggleDescriptionInput && (
            <Textarea
              variant="unstyled"
              autoFocus
              placeholder={_t('Add description')}
              className="absolute right-0 top-0 z-50 w-full min-w-[278px] [&_textarea]:border-main-primary focus:[&_textarea]:border-main-primary"
              autosize
              minRows={4}
              maxRows={4}
              value={inputDescriptionValue || ''}
              onChange={handleDescriptionChange}
              onBlur={handleDescriptionBlur}
            />
          )}
        </Text>
      </Flex>
    </Grid.Col>
  );
}
