import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for job positions.
 *
 * @param {import('react').RefAttributes<HTMLInputElement> & import('@mantine/core').SelectProps}
 */
export default function JobPositionSelect({ ...props }) {
  const { getAction } = useApi();
  const positionInCompanyGetListAction = getAction('PositionInCompanyGetListAction');

  return (
    <DataSelect
      {...props}
      action={positionInCompanyGetListAction}
      labelProperty="position_name"
      valueProperty="position_in_company_id"
    />
  );
}
