/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Edit" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function EditIcon({ className = '', height = 24, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4305 5.56839L18.7837 5.21449V5.21449L18.4305 5.56839ZM7.72223 19V19.5C7.85467 19.5 7.9817 19.4475 8.07544 19.3539L7.72223 19ZM5 19H4.5C4.5 19.2761 4.72386 19.5 5 19.5V19ZM5 16.228L4.6468 15.8741C4.55281 15.9679 4.5 16.0952 4.5 16.228H5ZM16.0338 5.9223C16.598 5.35923 17.5131 5.35923 18.0773 5.9223L18.7837 5.21449C17.8292 4.26184 16.2819 4.26184 15.3274 5.21449L16.0338 5.9223ZM18.0773 5.9223C18.6409 6.48481 18.6409 7.39643 18.0773 7.95894L18.7837 8.66675C19.7388 7.71355 19.7388 6.16769 18.7837 5.21449L18.0773 5.9223ZM18.0773 7.95894L7.36903 18.6461L8.07544 19.3539L18.7837 8.66675L18.0773 7.95894ZM7.72223 18.5H5V19.5H7.72223V18.5ZM15.3274 5.21449L4.6468 15.8741L5.3532 16.5819L16.0338 5.9223L15.3274 5.21449ZM4.5 16.228V19H5.5V16.228H4.5ZM14.1607 7.08667L16.9106 9.83112L17.617 9.12331L14.8672 6.37886L14.1607 7.08667Z"
        fill={fill}
      />
    </svg>
  );
}
