/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-update-action-body').ProjectUpdateActionBody} ProjectUpdateActionBody
 */

/**
 * @typedef {import('./project-update-action-response').ProjectUpdateActionResponse} ProjectUpdateActionResponse
 */

/**
 * The path of the ProjectUpdateAction action.
 */
export const projectUpdateActionPath = new SweetPath('/api/project/project/{project_id}', { path: '{param}' });

/**
 * Creates a contextualized ProjectUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectUpdateAction(axios) {
  /**
   * The projectUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ProjectUpdateActionBody } }
   *
   * @returns {Promise<ProjectUpdateActionResponse>}
   */
  function projectUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = projectUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return projectUpdateAction;
}
