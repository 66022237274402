/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./department-create-action-body').DepartmentCreateActionBody} DepartmentCreateActionBody
 */

/**
 * @typedef {import('./department-create-action-response').DepartmentCreateActionResponse} DepartmentCreateActionResponse
 */

/**
 * The path of the DepartmentCreateAction action.
 */
export const departmentCreateActionPath = new SweetPath('/api/user/department/', { path: '{param}' });

/**
 * Creates a contextualized DepartmentCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createDepartmentCreateAction(axios) {
  /**
   * The departmentCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: DepartmentCreateActionBody } }
   *
   * @returns {Promise<DepartmentCreateActionResponse>}
   */
  function departmentCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = departmentCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return departmentCreateAction;
}
