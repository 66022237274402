import ToolioLogo from 'components/ToolioLogo';
import { _t } from 'lang';
import ResetPasswordForm from 'components/forms/reset-password-form/ResetPasswordForm';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE_PATH } from 'routes/paths';
import { useState } from 'react';
import { useApi } from 'api/ApiContext';
import { Box, Group, Stack } from '@mantine/core';
import panic from 'errors/Panic';

/**
 * Reset Password page content.
 */
export default function ResetPasswordContent() {
  const [isSuccess, setIsSuccess] = useState(false);
  const { getAction } = useApi();

  const initialValues = {
    email: '',
  };

  /**
   * Handles the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ email }) => {
    const userPasswordResetAction = getAction('UserPasswordResetAction');

    return userPasswordResetAction({ body: { email } })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(panic);
  };

  return (
    <div className="toolio-login-background flex h-full items-center">
      <div className="ml-auto mr-auto w-[278px] text-center">
        <Group
          pr={24}
          pb={2}
          pl={1}
          position="center"
          style={{ position: 'absolute', top: '135px', left: 0, right: 0 }}
        >
          <ToolioLogo className="ml-auto mr-auto" width="109px" height="80px" variant="dark" />
        </Group>
        <Stack spacing={16} h={224}>
          <Group position="center" style={{ fontSize: '22px', lineHeight: '32px', color: '#38298B' }}>
            {_t('Lost your password?')}
          </Group>
          <Group position="center" style={{ fontSize: '15px', lineHeight: '18px', color: ' #38298B' }}>
            {_t('Enter your email ')}
            <br />
            {_t('to start recovery process.')}
          </Group>
          <div className="w-[278px]">
            {isSuccess ? (
              <Group position="center" style={{ fontSize: '15px', lineHeight: '18px', color: ' #38298B' }}>
                {_t(
                  "If your email address exists in the system, you'll receive an email with a link to reset the password."
                )}
              </Group>
            ) : (
              <Box className="text-left">
                <ResetPasswordForm onSubmit={submit} initialValues={initialValues} />
              </Box>
            )}
          </div>
          <Group
            position="center"
            style={{ color: '#38298B', fontSize: '15px', lineHeight: '20px', textDecorationLine: 'underline' }}
          >
            <Link to={LOGIN_PAGE_PATH.original}>{_t('Back to login')}</Link>
          </Group>
        </Stack>
      </div>
    </div>
  );
}
