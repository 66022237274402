import Alert from 'components/Alert';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';

/**
 * Displays an alert containing the note from the customer.
 */
export default function CustomerNoteAlert() {
  const { costEstimate } = useCostEstimateDetail();

  if (!costEstimate.client.note_cost_estimate) {
    return <></>;
  }

  return <Alert severity="notification" primaryText={costEstimate.client.note_cost_estimate} />;
}
