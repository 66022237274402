/** @typedef {import('api/actions/user-task-activity-get-action/user-task-activity-get-action-response').UserTaskActivityGetActionResponse['tasks'][number]} Task */

import { Grid, Text } from '@mantine/core';
import { formattedTime } from './TimeEntriesTable';

/**
 * Renders the total row of the time entries table.
 *
 * @param {{
 *   filters: {
 *     showWeekends: boolean,
 *     orderBy: string,
 *   },
 *   actualWeek: Date[],
 *   tasks: Task[]
 * }} props - The component props.
 *
 * @return {JSX.Element} - The total row of the time entries tables.
 */
export default function TimeEntriesTableTotalRow({ filters, actualWeek, tasks }) {
  const actualDay = new Date();

  const groupedTimeLogsByDay =
    tasks?.reduce((acc, task) => {
      task.time_logs.forEach((timeLog) => {
        const date = new Date(timeLog.start).getDate();
        acc[date] = acc[date] || 0;
        acc[date] += timeLog.duration;
      });

      return acc;
    }, {}) || {};

  const totalOfActualWeek = actualWeek.reduce((acc, date) => {
    return acc + (groupedTimeLogsByDay[date.getDate()] || 0);
  }, 0);

  return (
    <Grid w="100%" columns={filters.showWeekends ? 11 : 9} align="center" m={0}>
      <Grid.Col span={3} align="center" pl={82}></Grid.Col>
      <Grid.Col span={filters.showWeekends ? 8 : 6}>
        <Grid columns={filters.showWeekends ? 8 : 6} align="stretch">
          {actualWeek.map((date, index) => {
            if (filters.showWeekends === false && (index === 5 || index === 6)) {
              return null;
            }
            const isActualDay = actualDay.getDate() === date.getDate() && actualDay.getMonth() === date.getMonth();

            return (
              <Grid.Col
                span={1}
                key={date.getDate()}
                p={16}
                className={`
                ${isActualDay ? 'rounded-bl-lg rounded-br-lg bg-main-secondary' : ''}
                ${date.getDay() === 0 ? 'rounded-br-lg bg-neutral-50' : ''}
                ${date.getDay() === 6 ? 'rounded-bl-lg bg-neutral-50' : ''}
                `}
              >
                <Text fz={14} lh="24px" align="right" w="100%" weight="500">
                  {formattedTime(groupedTimeLogsByDay[date.getDate()] || 0)}
                </Text>
              </Grid.Col>
            );
          })}

          <Grid.Col span={1} py={16} pr={16}>
            <Text fz={14} lh="24px" align="right" w="100%" weight="500">
              {formattedTime(totalOfActualWeek || 0)}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}
