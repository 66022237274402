/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "JobPosition" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function JobPositionIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Settings/job_position">
        <path
          id="Vector"
          d="M16.5 14.5C16.5 13.8942 16.3862 13.3157 16.1794 12.7857C15.5158 11.0856 13.8947 9.88462 12 9.88462C10.1053 9.88462 8.48415 11.0856 7.82062 12.7857C7.61378 13.3157 7.5 13.8942 7.5 14.5M4 21V18.5H12M20 21V18.5H12M12 18.5V21M12 18.5V15.5M14.7 5.26923C14.7 6.79863 13.4912 8.03846 12 8.03846C10.5088 8.03846 9.3 6.79863 9.3 5.26923C9.3 3.73983 10.5088 2.5 12 2.5C13.4912 2.5 14.7 3.73983 14.7 5.26923Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
