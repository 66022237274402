/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-get-reacted-people-action-body').CommentGetReactedPeopleActionBody} CommentGetReactedPeopleActionBody
 */

/**
 * @typedef {import('./comment-get-reacted-people-action-response').CommentGetReactedPeopleActionResponse} CommentGetReactedPeopleActionResponse
 */

/**
 * The path of the CommentGetReactedPeopleAction action.
 */
export const commentGetReactedPeopleActionPath = new SweetPath(
  '/api/comment/comment_reacted/{comment_id}/{reaction_type}',
  { path: '{param}' }
);

/**
 * Creates a contextualized CommentGetReactedPeopleAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentGetReactedPeopleAction(axios) {
  /**
   * The commentGetReactedPeopleAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<CommentGetReactedPeopleActionResponse>}
   */
  function commentGetReactedPeopleAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = commentGetReactedPeopleActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return commentGetReactedPeopleAction;
}
