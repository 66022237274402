/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-get-structure-action-body').ProjectGetStructureActionBody} ProjectGetStructureActionBody
 */

/**
 * @typedef {import('./project-get-structure-action-response').ProjectGetStructureActionResponse} ProjectGetStructureActionResponse
 */

/**
 * The path of the ProjectGetStructureAction action.
 */
export const projectGetStructureActionPath = new SweetPath('/api/project/project/{project_id}/structure', {
  path: '{param}',
});

/**
 * Creates a contextualized ProjectGetStructureAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectGetStructureAction(axios) {
  /**
   * The projectGetStructureAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<ProjectGetStructureActionResponse>}
   */
  function projectGetStructureAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = projectGetStructureActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return projectGetStructureAction;
}
