import { Box, Flex, Text } from '@mantine/core';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import { _t } from 'lang';

/**
 * Renders the week controls component.
 *
 * @param {{
 *  onClick: (value: number) => () => void,
 *  onCalendarClick: (value: number) => () => void,
 *  stroke: string,
 *  }} props - The component props.
 *
 * @return {JSX.Element} The rendered week controls component.
 */
export default function WeekControls({ onClick, onCalendarClick, stroke }) {
  return (
    <Flex gap={8} px={16}>
      <Box onClick={onClick(-1)}>
        <ArrowLeftIcon className="cursor-pointer" stroke={stroke} />
      </Box>
      <Box onClick={onCalendarClick(0)}>
        <Text className="cursor-pointer text-main-primary">{_t('Current week')}</Text>
      </Box>
      <Box onClick={onClick(1)}>
        <ArrowRightIcon className="cursor-pointer" stroke={stroke} />
      </Box>
    </Flex>
  );
}
