import StateDraft from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/draft/StateDraft';
import StateWaitingForPO from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/waiting-for-po/StateWaitingForPO';
import StateWaitingForInvoice from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/waiting-for-invoice/StateWaitingForInvoice';
import StateInvoiced from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/invoiced/StateInvoiced';
import StateClosed from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/closed/StateClosed';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import StateDeclined from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/declined/StateDeclined';
import panic from 'errors/Panic';
import { _t } from 'lang';

/**
 * Contains information about the state of the Cost Estimate with buttons to
 * change it.
 */
export default function CostEstimateState() {
  const { costEstimate, isApproved, isClosed, isDeclined } = useCostEstimateDetail();

  // Closed
  if (isClosed) {
    return <StateClosed />;
  }

  // Declined
  if (isDeclined) {
    return <StateDeclined />;
  }

  // Draft
  if (!isApproved) {
    return <StateDraft />;
  }

  // Waiting for PO
  else if (!costEstimate.purchase_order) {
    return <StateWaitingForPO />;
  }

  // Waiting for invoice
  else if (!costEstimate.invoice) {
    return <StateWaitingForInvoice />;
  }

  // Invoiced
  else if (!isClosed) {
    return <StateInvoiced />;
  } else {
    panic(_t('This state is not supported.'));
    return <></>;
  }
}
