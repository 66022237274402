/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "FiletypeXls" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function FiletypeXlsIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 4.61929 6.11929 3.5 7.5 3.5H14.0925C14.8134 3.5 15.4992 3.8112 15.9739 4.35374L18.3814 7.10521C18.7802 7.56094 19 8.14591 19 8.75147V11C19 11.2761 18.7761 11.5 18.5 11.5C18.2239 11.5 18 11.2761 18 11V8.75147C18 8.6667 17.9928 8.5826 17.9788 8.5H15.5C14.6716 8.5 14 7.82843 14 7V4.5H7.5C6.67157 4.5 6 5.17157 6 6V19C6 19.8284 6.67157 20.5 7.5 20.5H13.5C13.7761 20.5 14 20.7239 14 21C14 21.2761 13.7761 21.5 13.5 21.5H7.5C6.11929 21.5 5 20.3807 5 19V6ZM15.2213 5.01224L17.3981 7.5H15.5C15.2239 7.5 15 7.27614 15 7V4.80568C15.0799 4.86639 15.1541 4.93545 15.2213 5.01224Z"
        fill={fill}
      />
      <path
        d="M11 14.75V13.5H10V14.75C10 14.9075 9.926 15.0555 9.8 15.15L9.5 15.375L9.2 15.15C9.074 15.0555 9 14.9075 9 14.75V13.5H8V14.75C8 15.2222 8.22225 15.6667 8.6 15.95L8.66675 16L8.6 16.05C8.222 16.3333 8 16.7778 8 17.25V18.5H9V17.25C9 17.0925 9.074 16.9445 9.2 16.85L9.5 16.625L9.8 16.85C9.926 16.9445 10 17.0925 10 17.25V18.5H11V17.25C11 16.7778 10.7778 16.3333 10.4 16.05L10.3333 16L10.4 15.95C10.7778 15.6667 11 15.2222 11 14.75Z"
        fill={fill}
      />
      <path
        d="M17.75 14.5L18 13.5H16C15.4478 13.5 15 13.9478 15 14.5V15.5C15 16.0522 15.4478 16.5 16 16.5H17V17.5H15V18.5H17C17.5522 18.5 18 18.0522 18 17.5V16.5C18 15.9478 17.5522 15.5 17 15.5H16V14.5H17.75Z"
        fill={fill}
      />
      <path d="M13 17.5V13.5H12V18.5H15V17.5H13Z" fill={fill} />
    </svg>
  );
}
