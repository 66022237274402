/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-can-be-closed-action-body').TaskCanBeClosedActionBody} TaskCanBeClosedActionBody
 */

/**
 * @typedef {import('./task-can-be-closed-action-response').TaskCanBeClosedActionResponse} TaskCanBeClosedActionResponse
 */

/**
 * The path of the TaskCanBeClosedAction action.
 */
export const taskCanBeClosedActionPath = new SweetPath('/api/project/project/task/{task_id}/can-be-closed', {
  path: '{param}',
});

/**
 * Creates a contextualized TaskCanBeClosedAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskCanBeClosedAction(axios) {
  /**
   * The taskCanBeClosedAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<TaskCanBeClosedActionResponse>}
   */
  function taskCanBeClosedAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = taskCanBeClosedActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return taskCanBeClosedAction;
}
