/** @typedef {import("components/comments/data/types").CommentReactionType} CommentReactionType */
/** @typedef {import("components/comments/data/types").ICommentReaction} ICommentReaction */

import { Box, Button, useMantineTheme } from '@mantine/core';
import { noop } from 'lodash';
import CommentReaction from 'components/comments/reaction/CommentReaction';
import CommentReactionSelect from 'components/comments/reaction/CommentReactionSelect';
import AddReactionIcon from 'components/icons/AddReactionIcon';

/**
 * Maps reaction types to their order in the reaction list.
 */
const reactionOrderMap = {
  like: 0,
  dislike: 1,
  heart: 2,
  laugh: 3,
  sad: 4,
};

/**
 * Displays a footer with the comment controls. This includes:
 * - reaction buttons
 * - reply button
 *
 * @param {{
 *   commentId: string;
 *   reactions: ICommentReaction[];
 *   onReact?: (type: CommentReactionType | null) => void;
 *   onReply?: () => void;
 * }}
 */
export default function CommentControls({ commentId, reactions, onReact = noop, onReply = noop }) {
  const theme = useMantineTheme();

  return (
    <Box className="text-15 flex flex-row items-center gap-4 text-sm text-main-primary">
      {reactions
        .filter(({ count, active }) => count > 0 || active)
        .sort((a, b) => reactionOrderMap[a.type] - reactionOrderMap[b.type])
        .map((reaction) => (
          <CommentReaction
            key={reaction.type}
            commentId={commentId}
            reaction={reaction}
            onClick={() => onReact(reaction.active ? null : reaction.type)}
          />
        ))}

      <CommentReactionSelect onReact={({ type }) => onReact(type)}>
        <Button variant="link">
          <AddReactionIcon width={24} height={24} stroke={theme.fn.primaryColor()} />
        </Button>
      </CommentReactionSelect>

      <Button variant="link" onClick={onReply}>
        Reply
      </Button>
    </Box>
  );
}
