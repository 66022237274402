import { Box, Tooltip } from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import striptags from 'striptags';
import removeAccents from 'remove-accents';

/**
 * Highlight the search text in the given text.
 *
 * @param {{
 *   needle: string;
 *   haystack: string;
 *   highlightClassName?: string;
 *   tooltipOpenDelay?: number;
 *   multilineTextLength?: number;
 *   multilineWidth?: number;
 *   tooltipMaxLength?: number;
 * }}
 */
export default function SearchHighlighter({
  needle,
  haystack,
  highlightClassName = '',
  tooltipOpenDelay = 500,
  multilineTextLength = 100,
  multilineWidth = 400,
  tooltipMaxLength = 600,
}) {
  const ref = useRef();
  const [hasOverflowLeft, setHasOverflowLeft] = useState(false);
  const [hasOverflowRight, setHasOverflowRight] = useState(false);
  const strippedHaystack = useMemo(() => striptags(haystack, [], ' ').replace(/(?:\s+|&nbsp;)/g, ' '), [haystack]);

  const shortenedHaystack = useMemo(() => {
    const shortened = strippedHaystack.slice(0, tooltipMaxLength);
    return strippedHaystack.length > tooltipMaxLength ? `${shortened} ...` : shortened;
  }, [strippedHaystack, tooltipMaxLength]);

  const searchWords = useMemo(() => needle.trim().split(/\s+/g), [needle]);

  useEffect(() => {
    if (ref.current) {
      ref.current.querySelector('mark')?.scrollIntoView({ block: 'center', inline: 'center' });

      setHasOverflowLeft(ref.current.scrollLeft > 0);
      setHasOverflowRight(ref.current.scrollWidth > ref.current.clientWidth + ref.current.scrollLeft);
    }
  }, [needle, strippedHaystack]);

  const hasOverflow = hasOverflowLeft || hasOverflowRight;
  const multiline = strippedHaystack.length > multilineTextLength;
  const width = multiline ? multilineWidth : 'auto';

  return (
    <Tooltip
      disabled={!hasOverflow}
      label={
        <Highlighter
          autoEscape
          searchWords={searchWords}
          textToHighlight={shortenedHaystack}
          sanitize={removeAccents}
          highlightClassName="font-semibold bg-[transparent] text-neutral-700"
        />
      }
      withinPortal
      width={width}
      multiline={multiline}
      openDelay={tooltipOpenDelay}
    >
      <Box h={26} pos="relative" className="overflow-y-hidden bg-[inherit]">
        <Box ref={ref} pb={20} className="overflow-x-auto whitespace-nowrap bg-[inherit]">
          {hasOverflowLeft && (
            <Box pos="absolute" left={0} top={0} h="100%" w="auto" pr={2} className="bg-[inherit]">
              &hellip;
            </Box>
          )}
          <Highlighter
            autoEscape
            searchWords={searchWords}
            textToHighlight={strippedHaystack}
            sanitize={removeAccents}
            highlightClassName={highlightClassName}
          />
          {hasOverflowRight && (
            <Box pos="absolute" right={0} top={0} h="100%" w="auto" pl={2} className="bg-[inherit]">
              &hellip;
            </Box>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
}
