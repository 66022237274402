import { Collapse, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { _t } from 'lang';
import { useProject } from 'providers/project/ProjectProvider';
import { useMemo, useState } from 'react';
import GroupedTimeEntriesTable from '../../../components/tables/time-log/GroupedTimeEntriesTable';

/**
 * Displays time entries for a project.
 */
export default function ProjectTimeEntries() {
  const { projectId } = useProject();
  const key = useMemo(() => `toolio.project.detail.${projectId}.time-entries.opened`, [projectId]);
  const [opened, { setValue: setOpened }] = useLocalStorageDisclosure(key, false);
  const [number, setNumber] = useState(-1);

  return (
    <Stack spacing={16}>
      <GroupHeadline
        heading={_t('TIME ENTRIES')}
        opened={opened}
        setOpened={setOpened}
        loadingNumber={number === -1}
        number={number}
      />
      <Collapse in={opened}>
        <GroupedTimeEntriesTable projectId={projectId} onLoaded={setNumber} />
      </Collapse>
    </Stack>
  );
}
