import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for suppliers.
 *
 * @param { import('react').RefAttributes<HTMLInputElement> &
 *   import('@mantine/core').SelectProps
 * }
 */
export default function SupplierSelect({ ...props }) {
  const { getAction } = useApi();
  const supplierGetListAction = getAction('SupplierGetListAction');

  return (
    <DataSelect {...props} action={supplierGetListAction} labelProperty="supplier_name" valueProperty="supplier_id" />
  );
}
