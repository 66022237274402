import AddCostEstimatePageContent from 'pages/finance/cost-estimate/add-cost-estimate/AddCostEstimatePageContent';
import { ClientProvider } from 'providers/client/ClientProvider';
import { PriceListProvider } from 'providers/price-list/PriceListProvider';
import { ProjectProvider } from 'providers/project/ProjectProvider';
import { JobSelectProvider } from 'pages/finance/cost-estimate/add-cost-estimate/providers/JobSelectProvider';
import { DataProvider } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import { SubmitProvider } from 'pages/finance/cost-estimate/add-cost-estimate/providers/SubmitProvider';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { useSearchParams } from 'react-router-dom';

/**
 * Form for adding a cost estimate.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=3329%3A51194&t=j4NS3BSXk6aY0J4C-4
 */
export default function AddCostEstimatePage() {
  const [searchParams] = useSearchParams();

  const clientId = searchParams.get('clientId') || null;
  const projectId = searchParams.get('projectId') || null;

  return (
    <DashboardLayout contentClass="bg-legacy-elements-body px-8 pb-8">
      <ClientProvider initialClientId={clientId}>
        <PriceListProvider>
          <ProjectProvider initialProjectId={projectId}>
            <JobSelectProvider>
              <DataProvider>
                <SubmitProvider>
                  <AddCostEstimatePageContent />
                </SubmitProvider>
              </DataProvider>
            </JobSelectProvider>
          </ProjectProvider>
        </PriceListProvider>
      </ClientProvider>
    </DashboardLayout>
  );
}
