import { Group, Text, useMantineTheme } from '@mantine/core';
import AttachmentIcon from 'components/icons/AttachmentIcon';

/**
 * Displays an icon with the number of attachments in the comment.
 *
 * @param {{
 *   commentId: string;
 *   count: number;
 * }}
 */
export default function CommentAttachmentsCount({ count }) {
  const theme = useMantineTheme();

  return (
    <Group spacing={4} align="center" h={20}>
      <AttachmentIcon stroke={theme.colors.neutral500[0]} width={16} height={16} />
      <Text color="neutral300" fz={12} lh={16 / 12}>
        {count}
      </Text>
    </Group>
  );
}
