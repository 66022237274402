import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { _t } from 'lang';

/**
 * The modal that appears when the user wants to close the cost estimate.
 *
 * @param {{
 *  opened: boolean;
 *  onClose: () => void;
 *  onConfirm: () => void
 * }}
 */
export default function CostEstimateCloseModal({ opened, onClose, onConfirm }) {
  return (
    <Modal centered opened={opened} onClose={onClose} padding={12} title={_t('Close cost estimate')}>
      <Stack spacing={8}>
        <Text fz={15} lh={20 / 15}>
          {
            _t('Make sure all time reports are done and all external invoices are imported. Once you close cost estimate, you can´t add any expenses unless you reopen the cost estimate.') // prettier-ignore
          }
        </Text>
        <Text fz={15} lh={20 / 15} pt={32} pb={16}>
          {_t('Are you sure to close this cost estimate?')}
        </Text>
        <Group spacing={16} position="right" pt={8} pb={16}>
          <Button variant="link" w={76} h={36} onClick={onClose}>
            {_t('Cancel')}
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            {_t('Yes, close')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
