/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-password-reset-action-body').UserPasswordResetActionBody} UserPasswordResetActionBody
 */

/**
 * @typedef {import('./user-password-reset-action-response').UserPasswordResetActionResponse} UserPasswordResetActionResponse
 */

/**
 * The path of the UserPasswordResetAction action.
 */
export const userPasswordResetActionPath = new SweetPath('/api/user/password/reset', { path: '{param}' });

/**
 * Creates a contextualized UserPasswordResetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserPasswordResetAction(axios) {
  /**
   * The userPasswordResetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserPasswordResetActionBody } }
   *
   * @returns {Promise<UserPasswordResetActionResponse>}
   */
  function userPasswordResetAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userPasswordResetActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return userPasswordResetAction;
}
