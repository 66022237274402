import { Button, Group, LoadingOverlay, Modal, Stack, Text, Textarea } from '@mantine/core';
import { _t } from 'lang';
import { useEffect, useMemo, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import {
  SUCCESS_NOTIFICATION_COLOR,
  TIME_LOG_CANNOT_BE_CREATED_ERROR_CODE,
  TIME_LOG_CANNOT_BE_UPDATED_ERROR_CODE,
} from 'utils/constants';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import TaskSelect from 'components/selects/TaskSelect';
import { useForm } from '@mantine/form';
import { useApi } from 'api/ApiContext';
import { noop } from 'lodash';
import panic from 'errors/Panic';
import ProjectSelect from 'components/selects/ProjectSelect';
import StartEndDurationInput from 'components/inputs/StartEndDurationInput';
import { addMinutes, subMinutes } from 'date-fns';
import Truncate from 'components/Truncate';
import EditIcon from 'components/icons/EditIcon';
import Preloader from 'components/Preloader';

/**
 * Modal allowing to log time.
 *
 * @typedef {import('pages/time-entries/time-log/TimeLogProvider').TimeLogProps} TimeLogProps
 *
 * @param {TimeLogProps & {
 *   opened: boolean,
 *   onClose: () => void,
 * }}
 */
export default function TimeLogModal({
  opened,
  onClose,
  projectId,
  taskId,
  start = subMinutes(new Date(), 60),
  end = new Date(),
  description,
  timeLogId,
  hideAddNext = false,
  onTimeLogged = noop,
  onTimeCancelled = noop,
}) {
  const { getAction, hasPermission, accessibleClients } = useApi();
  const [loading, setLoading] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);
  const type = useMemo(() => (timeLogId ? 'edit' : 'create'), [timeLogId]);
  const [taskSelectOpened, setTaskSelectOpened] = useState(!taskId);
  const [task, setTask] = useState(null);

  // If the user has global permission, the filter is not needed. Otherwise show only
  // accessible clients. If the user does not have contextualized permission, this modal
  // will never be opened.
  const clientFilter = useMemo(
    () => (hasPermission('TIME_LOGS_CREATE_TIME_LOG') ? {} : { 'client_id.in': accessibleClients }),
    [accessibleClients, hasPermission]
  );

  const form = useForm({
    initialValues: {
      projectId: projectId ? String(projectId) : '',
      taskId: taskId ? String(taskId) : '',
      start,
      end,
      description: description || '',
    },
    validate: {
      projectId: createValidator([required]),
      taskId: createValidator([required]),
      end: (end, { start }) => (!end || !start || end <= start ? _t('Invalid duration') : null),
    },
  });

  /**
   * Makes the call to the API.
   *
   * @param {typeof form['values']}
   */
  const submit = ({ projectId, taskId, start, end, description }) => {
    setLoading(true);

    start.setSeconds(0, 0);
    end.setSeconds(0, 0);

    const body = {
      task_id: taskId,
      start: start.toISOString(),
      end: end.toISOString(),
      description: description === '' ? undefined : description,
    };

    const timeLogCreateAction = getAction('TimeLogCreateAction');
    const timeLogUpdateAction = getAction('TimeLogUpdateAction');

    const actionCall =
      type === 'create'
        ? timeLogCreateAction({ body, parameters: { project_id: projectId, task_id: taskId } })
        : timeLogUpdateAction({ body, parameters: { time_log_id: timeLogId } });

    actionCall
      .then(() => {
        showNotification({
          title: _t('Time logged successfully'),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        onTimeLogged();

        if (keepOpen) {
          form.setFieldValue('start', form.values.end);
          form.setFieldValue('end', addMinutes(form.values.end, 60));
          form.setFieldValue('description', '');

          setTaskSelectOpened(true);
          setKeepOpen(false);
        } else {
          onClose();
        }
      })
      .catch((error) => {
        if (
          error.code === TIME_LOG_CANNOT_BE_CREATED_ERROR_CODE ||
          error.code === TIME_LOG_CANNOT_BE_UPDATED_ERROR_CODE
        ) {
          form.setFieldError('taskId', _t('Time cannot be logged for this task because it is closed.'));
        } else {
          panic(error);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (taskId) {
      const taskGetAction = getAction('TaskGetAction');

      taskGetAction({ parameters: { task_id: taskId } })
        .then(setTask)
        .catch(panic);
    }
  }, [taskId]);

  const allowQuickClose = !form.isDirty();

  return (
    <Modal
      closeOnClickOutside={allowQuickClose}
      closeOnEscape={allowQuickClose}
      opened={opened}
      onClose={onClose}
      centered
      size={594}
      title={
        <Text c="#4D4D4D" fz={24} lh={36 / 24}>
          {_t('Log time')}
        </Text>
      }
      styles={{
        modal: {
          borderRadius: '8px',
          boxShadow: '8px 8px 16px 0px rgba(0, 0, 0, 0.10)',
          backgroundColor: '#FFF',
          padding: '16px 16px 32px 16px !important',
        },
        close: {
          height: '40px',
          width: '40px',
          paddingLeft: '8px',
          paddingRight: '8px',
          color: '#4D4D4D',
          svg: {
            width: '24px',
            height: '24px',
          },
        },
      }}
    >
      {!taskSelectOpened && !task && <LoadingOverlay visible loader={<Preloader size={32} />} />}
      <form onSubmit={form.onSubmit(submit)}>
        <Stack spacing={16}>
          {taskSelectOpened ? (
            <Group align="start" spacing={16}>
              <ProjectSelect
                styles={{ dropdown: { minWidth: '300px' } }}
                label={
                  <Text c="#808080" fz={12} lh={16 / 12}>
                    {_t('Project')}
                  </Text>
                }
                actionFilter={clientFilter}
                placeholder={_t('Project')}
                {...form.getInputProps('projectId')}
                className="w-[144px]"
              />
              <TaskSelect
                clearable
                clearButtonTabIndex={-1}
                label={
                  <Text c="#808080" fz={12} lh={16 / 12}>
                    {_t('Task')}
                  </Text>
                }
                placeholder={form.values.projectId ? _t('Task') : _t('Select project first')}
                projectId={form.values.projectId}
                {...form.getInputProps('taskId')}
                className="w-[402px]"
              />
            </Group>
          ) : (
            <Group spacing={8} align="center">
              <Truncate
                className="text-neutral700 max-w-[calc(100%-90px)] text-[15px] leading-[18px]"
                text={task?.task_full_name ?? ''}
              />
              <Button w={82} variant="link" leftIcon={<EditIcon />} onClick={() => setTaskSelectOpened(true)}>
                {_t('Change')}
              </Button>
            </Group>
          )}
          <StartEndDurationInput
            withDateInput
            value={form.values}
            onChange={form.setValues}
            initialLocked={type === 'edit' ? 'start' : 'end'}
          />

          <Textarea minRows={3} placeholder={_t('Description')} {...form.getInputProps('description')} />

          <Group spacing={16} position="right">
            <Button
              variant="link"
              onClick={() => {
                onClose();
                onTimeCancelled();
              }}
              disabled={loading}
              style={{ order: 1 }}
            >
              {_t('Cancel')}
            </Button>
            <Button type="submit" disabled={loading} variant="primary" style={{ order: 3 }}>
              {type === 'edit' ? _t('Update') : _t('Log time')}
            </Button>
            {type !== 'edit' && !hideAddNext && (
              <Button
                type="submit"
                variant="secondary"
                disabled={loading}
                onClick={() => setKeepOpen(true)}
                style={{ order: 2 }}
              >
                {_t('Log & add next')}
              </Button>
            )}
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
