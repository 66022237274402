/** @typedef {import('api/actions/role-get-list-action/role-get-list-action-response').RoleGetListActionResponse[number]} Role */

import StatusSwitch from 'components/inputs/status-switch/StatusSwitch';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';

/**
 * Status switch for the role model.
 *
 * @param {{
 *   role: Role;
 * }}
 */
export default function RoleStatusSwitch({ role }) {
  const { getAction } = useApi();
  const roleSetActiveAction = getAction('RoleSetActiveAction');

  let disabled = false;
  let reason = null;

  if (role.members.length > 0) {
    disabled = true;
    reason = _t('This role is assigned to one or more users.');
  }

  return (
    <StatusSwitch
      entity={_t('Role')}
      name={role.role_name}
      action={roleSetActiveAction}
      primaryKey={role.role_id}
      pathParameterName="role_id"
      initialValue={role.is_active}
      disabled={disabled}
      disabledReason={reason}
    />
  );
}
