/** @typedef {import('@mantine/core').CSSObject} CSSObject */

/**
 * Styles for error message on all inputs.
 *
 * @type {CSSObject}
 */
export const ErrorStyles = {
  paddingTop: '4px',
  paddingBottom: '4px',
  fontSize: '15px',
  lineHeight: '18px',
  fontWeight: 400,
  color: '#BF0D38',
};
