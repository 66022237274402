/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Subtask" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function SubtaskIcon({
  className = '',
  height = 25,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6H7.5C6.39543 6 5.5 6.89543 5.5 8V19C5.5 20.1046 6.39543 21 7.5 21H16.5C17.6046 21 18.5 20.1046 18.5 19V8C18.5 6.89543 17.6046 6 16.5 6H15M9 6C9 7.10457 9.89543 8 11 8H13C14.1046 8 15 7.10457 15 6M9 6C9 4.89543 9.89543 4 11 4H13C14.1046 4 15 4.89543 15 6"
        stroke={stroke}
        strokeLinecap="round"
      />
    </svg>
  );
}
