import { Button, Flex } from '@mantine/core';
import { _t } from '../../../lang';
import PlusSmallIcon from '../../icons/PlusSmallIcon';

/**
 * Renders the footer of the time entries table.
 *
 * @returns {ReactNode} The rendered footer element.
 */
export default function TimeEntriesTableFooter({ onAddTask }) {
  return (
    <Flex px={16} justify="space-between" pt={6} pb={22}>
      <Button variant="link" leftIcon={<PlusSmallIcon height={24} width={24} />} onClick={onAddTask}>
        {_t('Add Task / Subtask')}
      </Button>
    </Flex>
  );
}
