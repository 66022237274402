import { _t } from 'lang';
import FinanceFormLayout from 'layouts/finance-form-layout/FinanceFormLayout';
import AddInvoicePageContent from 'pages/finance/invoice/add-invoice/AddInvoicePageContent';

/**
 * Displays a form to add an invoice.
 *
 * @see {@link https://www.figma.com/file/CQQ1mMQRmo9H7dsJSejbRd/Toolio---Design?node-id=82%3A3089&t=70yfhn5wGgjFtKdu-0 Figma Design}
 */
export default function AddInvoicePage() {
  return (
    <FinanceFormLayout heading={_t('Add invoice')}>
      {(costEstimate) => <AddInvoicePageContent costEstimate={costEstimate} />}
    </FinanceFormLayout>
  );
}
