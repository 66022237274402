/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./position-in-company-create-action-body').PositionInCompanyCreateActionBody} PositionInCompanyCreateActionBody
 */

/**
 * @typedef {import('./position-in-company-create-action-response').PositionInCompanyCreateActionResponse} PositionInCompanyCreateActionResponse
 */

/**
 * The path of the PositionInCompanyCreateAction action.
 */
export const positionInCompanyCreateActionPath = new SweetPath('/api/user/position_in_company/', { path: '{param}' });

/**
 * Creates a contextualized PositionInCompanyCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPositionInCompanyCreateAction(axios) {
  /**
   * The positionInCompanyCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PositionInCompanyCreateActionBody } }
   *
   * @returns {Promise<PositionInCompanyCreateActionResponse>}
   */
  function positionInCompanyCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = positionInCompanyCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return positionInCompanyCreateAction;
}
