import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Uses state stored in the URL query string.
 *
 * @param {string} paramName
 * @param {string} [defaultValue='']
 *
 * @returns {[string, (value: string) => void]}
 */
export default function useQueryState(paramName, defaultValue = '') {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Sets the active tab.
   */
  const setValue = useCallback(
    /** @param {string} value */
    (value) =>
      setSearchParams(
        (prev) => {
          prev.set(paramName, value);
          return prev;
        },
        { replace: true }
      ),
    [paramName, setSearchParams]
  );

  const value = searchParams.get(paramName) || defaultValue;

  return [value, setValue];
}
