/** @typedef {import('./CostEstimateData').CostEstimateData} CostEstimateData */

import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { _t } from 'lang';
import { useEffect, useMemo, useState } from 'react';
import CostEstimatePart from 'pages/finance/cost-estimate/add-cost-estimate/part/CostEstimatePart';
import { IconArrowRight } from '@tabler/icons';
import { useClient } from 'providers/client/ClientProvider';
import { usePriceList } from 'providers/price-list/PriceListProvider';
import CostEstimateName from 'pages/finance/cost-estimate/add-cost-estimate/header/CostEstimateName';
import CostEstimateDescription from 'pages/finance/cost-estimate/add-cost-estimate/header/CostEstimateDescription';
import CostEstimateNote from 'pages/finance/cost-estimate/add-cost-estimate/header/CostEstimateNote';
import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import { useSubmit } from 'pages/finance/cost-estimate/add-cost-estimate/providers/SubmitProvider';
import { Box, Breadcrumbs, Button, Center, Collapse, Group, Stack } from '@mantine/core';
import Alert from 'components/Alert';
import GroupHeadline from 'components/GroupHeadline';
import CostEstimateProject from 'pages/finance/cost-estimate/add-cost-estimate/header/CostEstimateProject';
import CostEstimateProjectHolder from 'pages/finance/cost-estimate/add-cost-estimate/header/CostEstimateProjectHolder';
import { useProject } from 'providers/project/ProjectProvider';
import { useDisclosure } from '@mantine/hooks';
import PlusSmallIcon from 'components/icons/PlusSmallIcon';
import PriceDisplay from 'components/PriceDisplay';
import DiscountForm from 'pages/finance/cost-estimate/add-cost-estimate/discount/DiscountForm';
import { FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from 'routes/paths';
import LinkWithConfirm from 'components/navigate/LinkWithConfirm';
import formatPrice from 'utils/format-price';
import CostEstimateClient from 'pages/finance/cost-estimate/add-cost-estimate/header/CostEstimateClient';
import { Link, useParams } from 'react-router-dom';
import CostEstimateCurrency from './header/CostEstimateCurrency';
import { useApi } from 'api/ApiContext';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * Displays finance project cost estimate.
 */
export default function AddCostEstimatePageContent() {
  const [projectInformationOpened, setProjectInformationOpened] = useState(true);

  const { costEstimateId } = useParams();
  const { client, clientId } = useClient();
  const { project, projectId } = useProject();
  const { setPriceListId } = usePriceList();
  const { workspaceConfig } = useApi();

  const { data, updateData, hasChanges } = useData();
  const [hasDiscount, { open: addDiscount, close: removeDiscount }] = useDisclosure(!!data.discount);

  const { save, isSaving } = useSubmit();

  // Set the price list on client change.
  useEffect(() => {
    if (client) {
      setPriceListId(client.price_list_id);
    }
  }, [client]);

  // Remove discount from data if it is disabled.
  useEffect(() => {
    if (!hasDiscount) {
      updateData({ discount: undefined });
    }
  }, [hasDiscount]);

  const suffix = data.isDraft ? _t('(draft)') : '';
  const heading = data.costEstimateId ? `${data.name} ${suffix}`.trim() : _t('New Cost Estimate');

  const hasZeroPrice = useMemo(
    () =>
      data.inHouse.sections.some((section) => section.rows.some((row) => row.price === 0)) ||
      data.outSource.sections.some((section) => section.rows.some((row) => row.price === 0)),
    [data.inHouse.sections, data.outSource.sections]
  );

  // this is for edit only
  // data and costEstimateId are present
  // but the data does not have costEstimateId
  if (data && costEstimateId && !data.costEstimateId) {
    return <NotFoundPageContent />;
  }

  return (
    <div>
      {/* Header / Edit */}
      <PageHeading
        heading={heading}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}>{_t('Finance')}</Link>
            {client && (
              <Link to={`${FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}?clientId=${client.client_id}`}>
                {client.client_name}
              </Link>
            )}
            {project && client && (
              <Link
                to={`${FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}?projectId=${project.project_id}&clientId=${client.client_id}`}
              >
                {project.project_full_name}
              </Link>
            )}
          </Breadcrumbs>
        }
      />

      {/* Messages */}
      {client?.note_cost_estimate && (
        <Stack pt={24} pb={16}>
          <Alert severity="notification" primaryText={client.note_cost_estimate} />
        </Stack>
      )}

      {/* Content */}
      <Stack spacing={16}>
        <GroupHeadline
          heading={_t('PROJECT INFORMATION')}
          opened={projectInformationOpened}
          setOpened={setProjectInformationOpened}
        />

        <Collapse in={projectInformationOpened}>
          <Stack spacing={16} maw={790}>
            <CostEstimateClient />
            {clientId && <CostEstimateProject />}
            {projectId && <CostEstimateCurrency />}
            {projectId && <CostEstimateName />}
            {projectId && <CostEstimateDescription />}
            {projectId && <CostEstimateProjectHolder />}
          </Stack>
        </Collapse>

        {projectId && (
          <>
            <CostEstimatePart
              isInHouse={true}
              title={_t('IN HOUSE ITEMS')}
              onChange={(inHouse) => updateData({ inHouse })}
              initialData={data.inHouse}
            />

            <CostEstimatePart
              isInHouse={false}
              title={_t('OUT OF HOUSE ITEMS')}
              onChange={(outSource) => updateData({ outSource })}
              initialData={data.outSource}
            />

            <Box py={24} className={hasDiscount ? 'group' : 'group border-t border-neutral-300'}>
              {hasDiscount && (
                <Stack pb={24} px={8} mb={16} spacing={16} className="border-b border-b-neutral-300">
                  <div className="grid h-[24px] grid-cols-[auto_168px_128px_64px] items-center justify-end gap-x-[8px] text-right">
                    <span className="text-[15px] leading-[18px]">{_t('Subtotal:')}</span>
                    <PriceDisplay value={data.subtotal} currency={data.currency} />
                  </div>
                  <DiscountForm
                    onDelete={removeDiscount}
                    onChange={(discount) => updateData({ discount })}
                    discountAmount={data.discountAmount}
                    initialData={data.discount || undefined}
                    currency={data.currency || 'Lorem'}
                  />
                </Stack>
              )}
              <Group spacing={8} position="apart">
                <Center px={8}>
                  {!hasDiscount && (
                    <Button
                      variant="link"
                      leftIcon={<PlusSmallIcon width={24} height={24} stroke="#38298B" />}
                      onClick={addDiscount}
                    >
                      {_t('Add discount')}
                    </Button>
                  )}
                </Center>
                <Group px={8} spacing={8} align="baseline" className="text-right">
                  <Box w={91}>{_t('Total:')}</Box>
                  <PriceDisplay value={data.total} size="xl" currency={data.currency} />
                  <PriceDisplay
                    value={data.externalCosts}
                    className="w-[128px] text-complementary-african-violet"
                    currency={data.currency}
                  />
                  <Box w={64} /> {/* Empty */}
                </Group>
              </Group>
            </Box>

            <Box pt={16}>
              <CostEstimateNote />
            </Box>

            <Stack spacing={0}>
              {data.externalCosts > data.total && (
                <Stack spacing={0} py={8}>
                  <Alert
                    severity="error"
                    primaryText={_t(
                      'This cost estimate is not profitable. Planned external cost of %s exceeds budgeted costs of %s by %s.',
                      formatPrice(data.externalCosts, workspaceConfig.currency),
                      formatPrice(data.total, workspaceConfig.currency),
                      formatPrice(data.externalCosts - data.total, workspaceConfig.currency)
                    )}
                  />
                </Stack>
              )}

              {hasZeroPrice && (
                <Stack spacing={0} py={8}>
                  <Alert
                    severity="actionRequired"
                    primaryText={_t('Reminder: Null items in cost estimate, please double-check if intended.')}
                  />
                </Stack>
              )}
            </Stack>

            <Group position="right" spacing={16} py={16}>
              <Button
                w={80}
                h={36}
                disabled={isSaving}
                variant="link"
                component={LinkWithConfirm}
                to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}
                confirmTitle={_t('Cancel')}
                confirmMessage={_t('Are you sure you want to cancel? The changes will not be saved.')}
                skipConfirm={() => !hasChanges()}
              >
                {_t('Cancel')}
              </Button>
              <Button disabled={isSaving} variant="secondary" onClick={() => save({ isDraft: true })}>
                {_t('Save draft')}
              </Button>
              <Button
                disabled={isSaving}
                rightIcon={<IconArrowRight stroke="#fff" />}
                variant="primary"
                onClick={() => save({ isDraft: false })}
              >
                {_t('Send to confirm')}
              </Button>
            </Group>
          </>
        )}
      </Stack>
    </div>
  );
}
