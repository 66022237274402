/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-price-create-action-body').CostEstimatePriceCreateActionBody} CostEstimatePriceCreateActionBody
 */

/**
 * @typedef {import('./cost-estimate-price-create-action-response').CostEstimatePriceCreateActionResponse} CostEstimatePriceCreateActionResponse
 */

/**
 * The path of the CostEstimatePriceCreateAction action.
 */
export const costEstimatePriceCreateActionPath = new SweetPath('/api/finance/cost_estimate_price', { path: '{param}' });

/**
 * Creates a contextualized CostEstimatePriceCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimatePriceCreateAction(axios) {
  /**
   * The costEstimatePriceCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CostEstimatePriceCreateActionBody } }
   *
   * @returns {Promise<CostEstimatePriceCreateActionResponse>}
   */
  function costEstimatePriceCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = costEstimatePriceCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return costEstimatePriceCreateAction;
}
