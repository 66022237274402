import { Box, Button, Checkbox, Group, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import InputWrapper from 'components/inputs/InputWrapper';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { emailFormat } from 'components/forms/validators/rules/rule-email-format';
import { required } from 'components/forms/validators/rules/rule-required';
import Alert from 'components/Alert';
import useLoadingAction from 'hooks/use-loading-action';

/**
 * @typedef {{
 *   email: string,
 *   password: string,
 * }} LoginFormData
 */

/**
 * Login Form.
 *
 * @param {{
 *   initialValues: LoginFormData,
 *   onSubmit: (data: LoginFormData) => void | Promise<void>,
 *   isResumeSession: boolean,
 *   errorMessage?:string,
 * }}
 */
export default function LoginForm({ initialValues, onSubmit, isResumeSession, errorMessage }) {
  const [loading, submit] = useLoadingAction(onSubmit);

  const form = useForm({
    initialValues,

    validate: {
      email: createValidator([required, emailFormat]),
      password: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={16}>
        <InputWrapper
          align="column"
          inputSize="small"
          input={<TextInput placeholder={_t('Email')} autoFocus {...form.getInputProps('email')} />}
        />
        <InputWrapper
          align="column"
          inputSize="small"
          input={<PasswordInput placeholder={_t('Your password')} {...form.getInputProps('password')} />}
        />
        {errorMessage && (
          <Alert className="mb-2 mt-2" severity="error" primaryText={errorMessage} displayIcon={false} />
        )}
        {!isResumeSession && (
          <Group position="center">
            <Checkbox
              label={
                <Box style={{ color: '#38298B', fontSize: '15px', lineHeight: '18px' }}>{_t('Keep me logged in')}</Box>
              }
              {...form.getInputProps('keepMeLogged')}
            />
          </Group>
        )}
        <Group position="center" className="text-[14px] text-grey-50">
          <Button loading={loading} type="submit" variant="primary">
            {_t('Login')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
