import { useForm } from '@mantine/form';
import DateRangeCalendarInput from 'components/inputs/date-range-calendar/DateRangeCalendarInput';
import InputWrapper from 'components/inputs/InputWrapper';
import ClientSelect from 'components/selects/ClientSelect';
import ProjectSelect from 'components/selects/ProjectSelect';
import TaskSelect from 'components/selects/TaskSelect';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { _t } from 'lang';
import useLoadingAction from 'hooks/use-loading-action';
import { useMemo } from 'react';
import UserSelect from '../../../components/selects/UserSelect';

/** @typedef {{start: Date, end: Date, type: string}} DateRange */

/**
 * @typedef {{
 *   dateRange: DateRange;
 *   clientId?: string;
 *   projectId?: string;
 *   taskId?: string;
 *   userId?: string;
 * }} TimeEntriesExportFormData
 */

/**
 * The form that collects data for exporting time entries.
 *
 * @param {{
 *  initialValues: TimeEntriesExportFormData;
 *  onSubmit: (values: TimeEntriesExportFormData) => void;
 * }}
 */
export default function TimeEntriesExportForm({ initialValues, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);

  const form = useForm({
    initialValues,
  });

  const clientFilter = useMemo(
    () => (form.values.clientId ? { 'client_id.eq': Number(form.values.clientId) } : {}),
    [form.values.clientId]
  );

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={16} py={16}>
        <Text fz={20} lh={36 / 20} c="#4D4D4D">
          {_t('Select Export Criteria')}
        </Text>

        <Group spacing={16} align="start">
          <InputWrapper
            align="column"
            inputSize="small"
            input={
              <DateRangeCalendarInput
                {...form.getInputProps('dateRange')}
                initialDateRangeType={initialValues.dateRange}
              />
            }
            label={_t('Date Range')}
          />
          <InputWrapper
            align="column"
            inputSize="small"
            input={<ClientSelect placeholder={_t('All Clients')} clearable {...form.getInputProps('clientId')} />}
            label={_t('Client')}
          />
          <InputWrapper
            align="column"
            inputSize="small"
            input={
              <ProjectSelect
                placeholder={_t('All Projects')}
                actionFilter={clientFilter}
                clearable
                {...form.getInputProps('projectId')}
              />
            }
            label={_t('Project')}
          />
          <InputWrapper
            align="column"
            inputSize="small"
            input={
              <TaskSelect
                projectId={Number(form.values.projectId) || -1}
                placeholder={form.values.projectId ? _t('Task') : _t('All Tasks')}
                clearable
                {...form.getInputProps('taskId')}
              />
            }
            label={_t('Task')}
          />
          <InputWrapper
            align="column"
            inputSize="small"
            input={<UserSelect multiple placeholder={_t('All Users')} clearable {...form.getInputProps('userId')} />}
            label={_t('Users')}
          />
        </Group>

        <Box>
          <Button variant="primary" type="submit" loading={loading} disabled={loading}>
            {_t('Export')}
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
