/**
 * Icon used by the Underline control component.
 */
export default function IconUnderline() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.8332 16.667V18.3337H4.1665V16.667H15.8332ZM13.3332 11.0128C13.3057 11.5628 13.1425 12.0974 12.858 12.5689C12.5734 13.0404 12.1766 13.434 11.7028 13.7147C11.229 13.9953 10.693 14.1542 10.1428 14.1772C9.59265 14.2001 9.04535 14.0864 8.54984 13.8462C7.9787 13.5991 7.49434 13.1871 7.15881 12.663C6.82328 12.1389 6.65186 11.5266 6.6665 10.9045V4.17116H4.99984V11.0128C5.02803 11.7973 5.24055 12.5642 5.62018 13.2513C5.99982 13.9384 6.53591 14.5265 7.18506 14.9679C7.83421 15.4093 8.57817 15.6917 9.35673 15.7921C10.1353 15.8926 10.9266 15.8083 11.6665 15.5462C12.6508 15.2181 13.5047 14.5846 14.1042 13.7379C14.7037 12.8911 15.0174 11.8752 14.9998 10.8378V4.17116H13.3332V11.0128ZM13.3332 4.16699H14.9998H13.3332ZM6.6665 4.16699H4.99984H6.6665Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
