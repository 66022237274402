import LoginContent from 'pages/login/login/LoginContent';
import SimpleLayout from 'layouts/simple-layout/SimpleLayout';

/**
 * Displays the login page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=1527%3A34562
 */
export default function LoginPage() {
  return <SimpleLayout content={<LoginContent />} />;
}
