import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconAlignRight from 'components/inputs/wysiwyg/controls/align-right/IconAlignRight';

/**
 * Used to align text to the right.
 */
export default function ControlAlignRight() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Align right" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().setTextAlign('right').run()}
        active={editor?.isActive({ textAlign: 'right' })}
      >
        <IconAlignRight />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
