/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */

import { Box, Button, Collapse, Group, Menu } from '@mantine/core';
import UserView from 'components/avatars/UserView';
import FinanceStatusBubble from 'components/FinanceStatusBubble';
import FinanceSummary from 'components/FinanceSummary';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { useEffect, useMemo, useState } from 'react';
import CostEstimateTable from 'pages/finance/finance-project-overview/projects-table/cost-estimate-table/CostEstimateTable';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';
import { Link } from 'react-router-dom';
import { FINANCE_ADD_COST_ESTIMATE_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH } from 'routes/paths';
import CollapseArrow from 'components/CollapseArrow';
import EditIcon from 'components/icons/EditIcon';
import AddIcon from 'components/icons/AddIcon';
import panic from 'errors/Panic';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import Truncate from 'components/Truncate';
import Preloader from 'components/Preloader';

/**
 * Displays the table with projects and their financial status.
 *
 * @param {{
 *   project: Project;
 *   currency: string
 * }}
 */
export default function ProjectFinanceTable({ project, currency }) {
  const { getAction } = useApi();
  const key = useMemo(() => `toolio.finance.overview.project.${project.project_id}.opened`, [project]);
  const [opened, { toggle: toggleOpened }] = useLocalStorageDisclosure(key);
  const [costEstimates, setCostEstimates] = useState(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const addCostEstimatePagePath = useMemo(() => {
    const projectId = project.project_id;
    const clientId = project.client.client_id;

    return `${FINANCE_ADD_COST_ESTIMATE_PAGE_PATH.original}?projectId=${projectId}&clientId=${clientId}`;
  }, [project]);

  // Fetch the cost estimates when the table is opened.
  useEffect(() => {
    if (opened && costEstimates === null) {
      const costEstimateGetListAction = getAction('CostEstimateGetListAction');

      costEstimateGetListAction({ query: { filter: { 'project_id.eq': project.project_id, 'is_template.eq': 0 } } })
        .then(setCostEstimates)
        .catch(panic);
    }
  }, [opened, costEstimates]);

  const loading = useMemo(() => opened && costEstimates === null, [opened, costEstimates]);

  const contents = useMemo(() => {
    if (loading) {
      return <Box h={48} />;
    }

    if (!costEstimates || costEstimates.length === 0) {
      return (
        <div className="flex h-12 items-center border-t border-t-neutral-100 pb-3 pl-[68px] pt-3">
          <Button component={Link} to={addCostEstimatePagePath} variant="compact">
            {_t('Add cost estimate')}
          </Button>
        </div>
      );
    }

    return costEstimates.map((ce) => <CostEstimateTable key={ce.cost_estimate_id} costEstimate={ce} />);
  }, [costEstimates, loading]);

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';
  const projectNameColor = loading ? 'text-neutral-500' : 'text-neutral-700';
  const clientNameColor = loading ? 'text-neutral-300' : 'text-neutral-500';

  return (
    <div className="border-b border-b-neutral-100 [&:last-of-type]:mb-4">
      <div className="group grid grid-cols-[24px_1fr_60px_128px_128px_128px_122px_24px] items-center gap-2 px-2 text-[20px] text-neutral-700 hover:bg-neutral-20">
        <div></div> {/* drag and drop icon */}
        {/* PROJECT NAME */}
        <div className="flex cursor-pointer flex-row items-center gap-2 py-2" onClick={toggleOpened}>
          <div className="h-6 w-6">
            {/* toggle icon */}
            <CollapseArrow opened={opened} />
          </div>
          <Group spacing={8}>
            <div className="flex max-w-[590px] flex-col">
              <Truncate className={`text-[18px] leading-[24px] ${projectNameColor}`} text={project.project_full_name} />
              <Truncate
                className={`text-[12px] uppercase leading-[18px] ${clientNameColor}`}
                text={project.client.client_name}
              />
            </div>
            {loading && <Preloader />}
          </Group>
        </div>
        <div className="py-2">
          {/* ASSIGNEE */}
          <UserView users={[{ full_name: project.owner.full_name, avatar: project.owner.avatar }]} avatarSize={32} />
        </div>
        <div className="py-2">
          {/* INVOICED */}
          <FinanceSummary
            total={project.budget}
            part={project.invoiced_amount}
            currency={currency}
            type="invoiced"
            progressVariant="negative"
          />
        </div>
        <div className="py-2">
          {/* BUDGET / MARGIN */}
          <FinanceSummary
            total={project.budget}
            part={project.external_cost_sum_actual}
            currency={currency}
            type="budgeted"
            progressVariant="positive"
          />
        </div>
        <div className="py-2">
          {/* EXTERNAL COSTS */}
          <FinanceSummary
            total={project.budget_external}
            part={project.external_cost_sum_actual}
            currency={currency}
            progressVariant="positive"
            type="external-costs"
          />
        </div>
        <div className="py-2">
          {/* TODO the status should not be hard-coded */}
          <FinanceStatusBubble status={_t('New')} variant="small" />
        </div>
        <div className={`flex justify-end py-2 ${menuClasses}`}>
          <Menu position="bottom-end" offset={15} onChange={setIsMenuOpened}>
            <Menu.Target>
              <div className="cursor-pointer pb-[7px] pt-[7px]">
                <OptionsDotsIcon />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                component={Link}
                to={PROJECT_DETAIL_PAGE_PATH.insert({ projectId: project.project_id })}
                icon={<EditIcon fill="#4D4D4D" />}
              >
                {_t('View project')}
              </Menu.Item>
              <Menu.Item component={Link} to={addCostEstimatePagePath} icon={<AddIcon stroke="#4D4D4D" />}>
                {_t('Add cost estimate')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
      <Collapse in={opened}>{contents}</Collapse>
    </div>
  );
}
