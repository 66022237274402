/** @typedef {import('components/files/upload/types').IFile} IFile */

import { useFileManager } from 'api/file-manager/FileManagerContext';
import panic from 'errors/Panic';
import { useEffect, useState } from 'react';
import getImageDimensions from 'utils/get-image-dimensions';
import toBase64 from 'utils/to-base64';
import DropFiles from 'components/files/upload/DropFiles';
import { Stack, Box, LoadingOverlay, Center } from '@mantine/core';
import { noop } from 'lodash';
import { IMAGE_UPLOAD_MAX_SIZE } from 'environment';
import { _t } from 'lang';
import formatFileSize from 'utils/format-file-size';
import Preloader from 'components/Preloader';

/**
 * Used to upload a single image.
 *
 * @typedef {{
 *   remoteId: string;
 *   dimensions: { width: number; height: number; };
 * }} IOnUploadParams
 *
 * @param {{
 *   onUpload?: (params: IOnUploadParams) => void,
 *   onFileSelected?: (selected: boolean) => void,
 *   isPublic?: boolean,
 * }}
 */
export default function UploadImage({ onUpload = noop, onFileSelected = noop, isPublic = false } = {}) {
  const { uploadFile } = useFileManager();
  const [hasFile, setHasFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState('');

  /**
   * Handles file acceptance by file drop.
   *
   * @param {IFile[]} files
   */
  const onAccept = async ([file]) => {
    if (!file) {
      return; // Empty drop
    }

    setLoading(true);
    setHasFile(true);

    try {
      const contents = await toBase64(file.file);
      setContents(contents);

      const dimensions = await getImageDimensions(contents);
      const remoteId = await uploadFile({ contents, fileName: file.file.name, isPublic });

      onUpload({ dimensions, remoteId });
    } catch (error) {
      setHasFile(false);
      setContents('');
      panic(error);
    } finally {
      setLoading(false);
    }
  };

  // Propagate file selection state.
  useEffect(() => {
    onFileSelected(hasFile);
  }, [hasFile]);

  return (
    <Stack spacing={8}>
      <Box hidden={!hasFile} pos="relative" w={480} h={320} bg="neutral50">
        <LoadingOverlay visible={loading} loader={<Preloader size={32} />} />
        <Center w="100%" h="100%">
          <img className="max-h-full max-w-full" src={contents} />
        </Center>
      </Box>

      <Box hidden={hasFile}>
        <DropFiles
          accept={['image/*']}
          maxSize={IMAGE_UPLOAD_MAX_SIZE}
          onAccept={onAccept}
          withErrorList
          errorMessages={{
            size: () =>
              _t('Images larger than %s must be uploaded as attachments.', formatFileSize(IMAGE_UPLOAD_MAX_SIZE)),
          }}
        />
      </Box>
    </Stack>
  );
}
