/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-close-action-body').ProjectCloseActionBody} ProjectCloseActionBody
 */

/**
 * @typedef {import('./project-close-action-response').ProjectCloseActionResponse} ProjectCloseActionResponse
 */

/**
 * The path of the ProjectCloseAction action.
 */
export const projectCloseActionPath = new SweetPath('/api/project/project/{project_id}/close', { path: '{param}' });

/**
 * Creates a contextualized ProjectCloseAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectCloseAction(axios) {
  /**
   * The projectCloseAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ProjectCloseActionBody } }
   *
   * @returns {Promise<ProjectCloseActionResponse>}
   */
  function projectCloseAction({ headers = {}, parameters = {}, body } = {}) {
    const path = projectCloseActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return projectCloseAction;
}
