import { _t } from 'lang';
import { useEffect, useMemo, useState } from 'react';
import { Box, Skeleton, Stack } from '@mantine/core';
import AttachmentsListRow from 'components/files/AttachmentsListRow';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import { noop } from 'lodash';
import BulkFileDownloadLink from './BulkFileDownloadLink';
import Slider from 'components/modals/Slider';
import { useDisclosure } from '@mantine/hooks';

const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '4fr 2fr 1fr 1fr 24px',
  gap: '8px',
  alignItems: 'center',
};

/**
 * Displays a list of attachments.
 *
 * @param {{
 *   attachments: { fileId: string; isComment: boolean; }[]
 *   canDelete?: boolean | ((fileId: string) => boolean);
 *   hideCommentAttachments?: boolean;
 *   onDelete?: (fileId: string) => void
 * }}
 */
export default function AttachmentsList({
  attachments: attachmentIds,
  canDelete = false,
  hideCommentAttachments = false,
  onDelete = noop,
}) {
  const { getFileMetadata } = useFileManager();
  const [attachmentData, setAttachmentData] = useState(() => ({}));
  const [isSliderOpened, { open: openSlider, close: closeSlider }] = useDisclosure(false);
  const [initialImageId, setInitialImageId] = useState('');

  const attachments = useMemo(
    () => attachmentIds.map(({ fileId, isComment }) => ({ fileId, isComment, metadata: attachmentData[fileId] })),
    [attachmentIds, attachmentData]
  );

  const filteredAttachments = useMemo(() => {
    if (!hideCommentAttachments) {
      return attachments;
    }

    return attachments.filter(({ isComment }) => !isComment);
  }, [attachments, hideCommentAttachments]);

  const filteredFileIds = useMemo(() => filteredAttachments.map(({ fileId }) => fileId), [filteredAttachments]);
  const hideDownloadAll = useMemo(() => filteredAttachments.length === 0, [filteredAttachments]);

  // Fetch metadata for attachments
  useEffect(() => {
    for (const { fileId } of attachmentIds) {
      if (!attachmentData[fileId]) {
        getFileMetadata({ fileId }).then((metadata) => setAttachmentData((curr) => ({ ...curr, [fileId]: metadata })));
      }
    }
  }, [attachmentIds]);

  return (
    <>
      <div style={gridStyles}>
        <Box className="text-left text-neutral-500" pl={8}>
          {_t('Name')}
        </Box>
        <Box className="text-right text-neutral-500" pr={8}>
          {_t('Size')}
        </Box>
        <Box className="text-right text-neutral-500" pr={8}>
          {_t('Author')}
        </Box>
        <Box className="text-right text-neutral-500" pr={8}>
          {_t('Created')}
        </Box>
        <Box />
      </div>
      <Stack spacing={0}>
        {filteredAttachments.map(({ fileId, metadata }) =>
          metadata ? (
            <AttachmentsListRow
              gridStyles={gridStyles}
              key={fileId}
              fileId={fileId}
              name={metadata.name}
              extension={metadata.extension}
              type={metadata.mimeType}
              size={metadata.size}
              metadata={metadata}
              authorId={metadata.created.author.userId}
              created={new Date(metadata.created.timestamp)}
              onDelete={() => onDelete(fileId)}
              canDelete={canDelete}
              onOpenSlider={() => {
                openSlider();
                setInitialImageId(fileId);
              }}
            />
          ) : (
            <Skeleton key={fileId} width="100%" h={56} />
          )
        )}
      </Stack>
      <Slider
        key={initialImageId}
        opened={isSliderOpened}
        fileIds={filteredFileIds}
        onClose={closeSlider}
        initialImageId={initialImageId}
      />
      {!hideDownloadAll && (
        <Box>
          <BulkFileDownloadLink fileIds={filteredFileIds}>{_t('Download all')}</BulkFileDownloadLink>
        </Box>
      )}
    </>
  );
}
