/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-create-action-body').UserCreateActionBody} UserCreateActionBody
 */

/**
 * @typedef {import('./user-create-action-response').UserCreateActionResponse} UserCreateActionResponse
 */

/**
 * The path of the UserCreateAction action.
 */
export const userCreateActionPath = new SweetPath('/api/user/', { path: '{param}' });

/**
 * Creates a contextualized UserCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserCreateAction(axios) {
  /**
   * The userCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserCreateActionBody } }
   *
   * @returns {Promise<UserCreateActionResponse>}
   */
  function userCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return userCreateAction;
}
