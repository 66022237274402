/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-restore-action-body').ProjectRestoreActionBody} ProjectRestoreActionBody
 */

/**
 * @typedef {import('./project-restore-action-response').ProjectRestoreActionResponse} ProjectRestoreActionResponse
 */

/**
 * The path of the ProjectRestoreAction action.
 */
export const projectRestoreActionPath = new SweetPath('/api/project/project/{project_id}/restore', { path: '{param}' });

/**
 * Creates a contextualized ProjectRestoreAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectRestoreAction(axios) {
  /**
   * The projectRestoreAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ProjectRestoreActionBody } }
   *
   * @returns {Promise<ProjectRestoreActionResponse>}
   */
  function projectRestoreAction({ headers = {}, parameters = {}, body } = {}) {
    const path = projectRestoreActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return projectRestoreAction;
}
