/** @typedef {import("components/comments/data/types").CommentReactionType} CommentReactionType */

import { Menu, useMantineTheme } from '@mantine/core';
import { noop } from 'lodash';
import LikeReactionIcon from 'components/comments/reaction/icons/LIkeReactionIcon';
import DislikeReactionIcon from 'components/comments/reaction/icons/DislikeReactionIcon';
import HeartReactionIcon from 'components/comments/reaction/icons/HeartReactionIcon';
import LaughReactionIcon from 'components/comments/reaction/icons/LaughReactionIcon';
import SadReactionIcon from 'components/comments/reaction/icons/SadReactionIcon';
import { useMemo } from 'react';

/**
 * Payload of the onReact event.
 *
 * @typedef {{
 *   type: CommentReactionType;
 *   emoji: string;
 * }} OnReactPayload
 */

/**
 * Used to select a reaction for a comment.
 *
 * @param {{
 *   onReact?: (payload: OnReactPayload) => void;
 *   useEmoji?: boolean;
 *   children: React.ReactNode;
 * }}
 */
export default function CommentReactionSelect({ onReact = noop, useEmoji = false, children } = {}) {
  const theme = useMantineTheme();

  const menuStyles = useMemo(
    () => ({
      dropdown: {
        border: `1px solid ${theme.colors.neutral50[0]} !important`,
        boxShadow: '8px 8px 16px 0px rgba(0, 0, 0, 0.10) !important',
        borderRadius: '8px !important',
      },
      item: {
        background: 'transparent !important',
        padding: '0 !important',
      },
    }),
    [theme]
  );

  return (
    <Menu withinPortal styles={menuStyles}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown className="p-0">
        <div className="flex flex-row gap-4 p-2 px-4">
          <Menu.Item
            className="transition-transform hover:scale-125"
            onClick={() => onReact({ type: 'dislike', emoji: '👎' })}
          >
            <DislikeReactionIcon useEmoji={useEmoji} size={24} />
          </Menu.Item>
          <Menu.Item
            className="transition-transform hover:scale-125"
            onClick={() => onReact({ type: 'like', emoji: '👍' })}
          >
            <LikeReactionIcon useEmoji={useEmoji} size={24} />
          </Menu.Item>
          <Menu.Item
            className="transition-transform hover:scale-125"
            onClick={() => onReact({ type: 'heart', emoji: '❤️' })}
          >
            <HeartReactionIcon useEmoji={useEmoji} size={24} />
          </Menu.Item>
          <Menu.Item
            className="transition-transform hover:scale-125"
            onClick={() => onReact({ type: 'laugh', emoji: '😂' })}
          >
            <LaughReactionIcon useEmoji={useEmoji} size={24} />
          </Menu.Item>
          <Menu.Item
            className="transition-transform hover:scale-125"
            onClick={() => onReact({ type: 'sad', emoji: '😢' })}
          >
            <SadReactionIcon useEmoji={useEmoji} size={24} />
          </Menu.Item>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
}
