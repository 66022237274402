/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-create-action-body').TimeLogCreateActionBody} TimeLogCreateActionBody
 */

/**
 * @typedef {import('./time-log-create-action-response').TimeLogCreateActionResponse} TimeLogCreateActionResponse
 */

/**
 * The path of the TimeLogCreateAction action.
 */
export const timeLogCreateActionPath = new SweetPath('/api/time_log/{project_id}/{task_id}', { path: '{param}' });

/**
 * Creates a contextualized TimeLogCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogCreateAction(axios) {
  /**
   * The timeLogCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TimeLogCreateActionBody } }
   *
   * @returns {Promise<TimeLogCreateActionResponse>}
   */
  function timeLogCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = timeLogCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return timeLogCreateAction;
}
