import { Button, Group, Modal, Textarea } from '@mantine/core';
import { _t } from 'lang';
import { useEffect, useState } from 'react';

/**
 * A modal used to set a comment of a cost estimate row.
 *
 * @param {{
 *   opened: boolean,
 *   onClose: (comment?: string) => void,
 *   initialComment?: string,
 * }}
 */
export default function CommentModal({ opened, onClose, initialComment = '' }) {
  initialComment = initialComment || '';

  const [comment, setComment] = useState(initialComment);

  /**
   * Prompts the user to close the modal.
   */
  function promptClose() {
    let shouldPrompt = comment !== initialComment;
    let shouldClose = true;

    if (shouldPrompt) {
      shouldClose = window.confirm(_t('Are you sure you want to remove changes?'));
    }

    if (shouldClose) {
      onClose(initialComment);
    }
  }

  // Reset the comment when the modal is opened.
  useEffect(() => {
    if (opened) {
      setComment(initialComment);
    }
  }, [opened]);

  return (
    <Modal
      padding={16}
      opened={opened}
      onClose={onClose}
      title={_t('Add Comment')}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      centered
    >
      <Textarea
        autosize
        minRows={4}
        value={comment}
        onChange={({ target: { value: comment } }) => setComment(comment)}
      />
      <Group position="right" py={16} spacing={16}>
        <Button w={76} h={36} variant="link" onClick={promptClose}>
          {_t('Cancel')}
        </Button>
        <Button variant="primary" onClick={() => onClose(comment)}>
          {_t('Save')}
        </Button>
      </Group>
    </Modal>
  );
}
