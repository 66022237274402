/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Locked" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function LockedIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z"
        stroke={stroke}
        strokeLinecap="round"
      />
    </svg>
  );
}
