import { Box, Collapse, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import { useMemo, useState } from 'react';
import ProjectTable from 'pages/projects/project-overview/project-table/ProjectTable';
import { useProject } from 'providers/project/ProjectProvider';
import { DragDropContext } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../project-overview/StrictModeDroppable';
import { noop } from 'lodash';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import ShowDeletedSwitch from '../../../components/inputs/ShowDeletedSwitch';

/**
 * Displays the project tasks section.
 */
export default function ProjectTasks() {
  const { project } = useProject();
  const key = useMemo(() => `toolio.project.detail.${project.project_id}.tasks.opened`, [project]);
  const [opened, { toggle }] = useLocalStorageDisclosure(key, true);
  const [showDeleted, setShowDeleted] = useState(false);

  return (
    <Stack spacing={16}>
      <GroupHeadline heading={_t('TASK LIST')} opened={opened} setOpened={toggle} />
      <Collapse in={opened}>
        <Box pb={16} mt={-16}>
          <ShowDeletedSwitch value={showDeleted} onChange={setShowDeleted} />
        </Box>
        <Stack spacing={0} pt={16} style={{ backgroundColor: '#fff', borderRadius: '8px', overflow: 'hidden' }}>
          <div className="grid translate-y-[1px] grid-cols-[1fr_60px_128px_128px_154px] gap-x-2 bg-white pl-[72px] pr-2">
            <span className="text-[12px] leading-[18px] text-neutral-500">{_t('Name')}</span>
            <span className="text-[12px] leading-[18px] text-neutral-500">{_t('Assignee')}</span>
            <span className="pr-2 text-right text-[12px] leading-[18px] text-neutral-500">{_t('Logged hours')}</span>
            <span className="pr-2 text-right text-[12px] leading-[18px] text-neutral-500">{_t('Due date')}</span>
            <span className="text-[12px] leading-[18px] text-neutral-500">{_t('Status')}</span>
          </div>
          <DragDropContext onDragEnd={noop}>
            <div key="column-1">
              <StrictModeDroppable droppableId="column-1">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={snapshot.isDraggingOver ? { pointerEvents: 'none' } : {}}
                  >
                    <ProjectTable
                      key={`project-${project.project_id}`}
                      index={0}
                      project={project}
                      forceOpened
                      hideProjectRow
                      draggableId={`project-${project.project_id}`}
                      showDeleted={showDeleted}
                      loadingRows={0}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </div>
          </DragDropContext>
        </Stack>
      </Collapse>
    </Stack>
  );
}
