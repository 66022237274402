/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse} SearchResults */

import { Box, Button, Stack } from '@mantine/core';
import { _t } from 'lang';
import SectionResults from './SectionResults';
import { ADVANCED_SEARCH_PAGE_PATH } from 'routes/paths';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import NoResultsImage from './NoResultsImage';
import { useSearch } from './SearchProvider';

/**
 * Displays the search results.
 */
export default function SearchResults() {
  const {
    searchResults: { hits, groups },
    search,
  } = useSearch();

  const advancedSearchPath = useMemo(() => `${ADVANCED_SEARCH_PAGE_PATH.original}?query=${search}`, [search]);

  const advancedSearchButton = useMemo(
    () => (
      <Button variant="secondary-dark" component={Link} to={advancedSearchPath} state={{ showResults: false }}>
        {_t('Advanced search')}
      </Button>
    ),
    [advancedSearchPath]
  );

  return (
    <Stack spacing={0} px={32} pb={16}>
      {hits > 0 ? (
        <Stack spacing={32} pt={32}>
          {groups.map((group) => (
            <SectionResults key={group.name} group={group} />
          ))}
          <Box>{advancedSearchButton}</Box>
        </Stack>
      ) : (
        <Stack spacing={32} align="center" pt={48}>
          <NoResultsImage />
          <Stack spacing={8} align="center">
            <Box fz={15} lh={1.6} fw={500} c="#FFFFFF">
              {_t('No results found.')}
            </Box>
            <Box fz={15} lh={1.6} c="#FFFFFF">
              {_t('Narrow down your criteria in')}{' '}
              <span className="text-complementary-ametyst">{_t('advanced search.')}</span>
            </Box>
          </Stack>
          <Box pt={16} pb={16}>
            {advancedSearchButton}
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
