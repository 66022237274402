/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-update-ord-action-body').TaskUpdateOrdActionBody} TaskUpdateOrdActionBody
 */

/**
 * @typedef {import('./task-update-ord-action-response').TaskUpdateOrdActionResponse} TaskUpdateOrdActionResponse
 */

/**
 * The path of the TaskUpdateOrdAction action.
 */
export const taskUpdateOrdActionPath = new SweetPath('/api/project/project/task/{task_id}/update_ord', {
  path: '{param}',
});

/**
 * Creates a contextualized TaskUpdateOrdAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskUpdateOrdAction(axios) {
  /**
   * The taskUpdateOrdAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskUpdateOrdActionBody } }
   *
   * @returns {Promise<TaskUpdateOrdActionResponse>}
   */
  function taskUpdateOrdAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskUpdateOrdActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return taskUpdateOrdAction;
}
