import CommentThread from 'components/comments/CommentThread';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';
import { useMemo } from 'react';

/**
 * Displays comments for a task.
 *
 * @param {{
 *   collapsible?: boolean;
 * }}
 */
export default function TaskComments({ collapsible = false } = {}) {
  const { taskId, task, refresh } = useTaskDetail();
  const headlineKey = useMemo(() => `toolio.task.detail.${taskId}.comments.opened`, [taskId]);

  return (
    <CommentThread
      groupHeadlineKey={headlineKey}
      threadId={task.comment_thread_id}
      headlineCollapsible={collapsible}
      onCommentAdded={refresh}
      onCommentEdited={refresh}
      onCommentDeleted={refresh}
      isSticky
      autoRefreshEnabled
    />
  );
}
