import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { _t } from 'lang';
import { USERS_PAGE_PATH } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import UserForm from 'components/forms/user-form/UserForm';
import { showNotification } from '@mantine/notifications';
import {
  DUPLICATE_USER_EMAIL_ERROR_CODE,
  SUCCESS_NOTIFICATION_COLOR,
  USER_DEACTIVATED_ERROR_CODE,
} from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { useState } from 'react';
import panic from 'errors/Panic';
import { isEmpty } from 'lodash';

/**
 * Displays a form to add user.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A24851
 */
export default function AddUserPage() {
  const navigate = useNavigate();
  const { getAction, workspaceConfig } = useApi();
  const { refreshUsers } = useSettingsSidebar();
  const [emailError, setEmailError] = useState('');

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    jobPositionId: '',
    roleId: '1',
    teamId: workspaceConfig.features.teams ? '' : undefined,
    hourRate: 0,
    memberOf: [],
    initStatus: 'not-initialized',
  };

  /**
   * Sends the request to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = async ({ email, firstName, lastName, jobPositionId, roleId, teamId, hourRate, memberOf }) => {
    try {
      const userCreateAction = getAction('UserCreateAction');

      await userCreateAction({
        body: {
          email: email,
          first_name: firstName,
          last_name: lastName,
          position_in_company_id: jobPositionId,
          role_id: roleId,
          team_id: teamId === '' ? undefined : teamId,
          internal_hour_rate: hourRate,
          member_of: !isEmpty(memberOf) ? memberOf.map(Number) : [],
        },
      });
      showNotification({
        title: _t('User created'),
        message: _t("User '%s' has been created successfully.", `${firstName} ${lastName}`),
        color: SUCCESS_NOTIFICATION_COLOR,
      });

      refreshUsers();

      navigate(USERS_PAGE_PATH.original);
    } catch (error) {
      if (error.code === DUPLICATE_USER_EMAIL_ERROR_CODE) {
        setEmailError(error.message);
      } else if (error.code === USER_DEACTIVATED_ERROR_CODE) {
        setEmailError(_t('User with this email already exists and the account can be activated.'));
      } else {
        panic(error);
      }
    }
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Add user')} />
        <UserForm
          initialValues={initialValues}
          onSubmit={submit}
          saveText={_t('Send invite')}
          emailError={emailError}
        />
      </div>
    </DashboardLayout>
  );
}
