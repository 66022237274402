/**
 * Icon used by the Strikethrough control component.
 */
export default function IconStrikethrough() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.5 10.1695H17.5V11.4195H13.8618C14.0592 11.8338 14.1589 12.288 14.153 12.7469C14.1647 13.1823 14.0713 13.6141 13.8806 14.0058C13.69 14.3974 13.4077 14.7373 13.0578 14.9967C12.2149 15.5897 11.1981 15.8843 10.1688 15.8337C9.41663 15.8388 8.67183 15.6858 7.98263 15.3845C7.35323 15.1234 6.80764 14.6945 6.40543 14.1444C6.0351 13.6193 5.83969 12.9908 5.84697 12.3482V12.2537H6.08632V12.2528H6.3709V12.2537H7.51667V12.3482C7.50361 12.641 7.56151 12.9326 7.68544 13.1982C7.80937 13.4639 7.99566 13.6956 8.22844 13.8737C8.79295 14.2757 9.47667 14.4751 10.1687 14.4398C10.7797 14.4797 11.3871 14.32 11.8993 13.9846C12.0892 13.8442 12.2418 13.6594 12.3436 13.4463C12.4455 13.2332 12.4936 12.9985 12.4837 12.7625C12.497 12.5345 12.4549 12.3067 12.361 12.0986C12.2671 11.8904 12.1242 11.7081 11.9445 11.5672C11.8709 11.5138 11.7944 11.4645 11.7155 11.4195H2.5V10.1695ZM13.6206 5.88355C13.2693 5.33773 12.7726 4.90087 12.1863 4.62221C11.5328 4.31088 10.8161 4.15509 10.0923 4.16704C9.083 4.12373 8.09069 4.43708 7.28933 5.05215C6.94529 5.31786 6.66778 5.65995 6.47873 6.05138C6.28968 6.44281 6.19427 6.87285 6.20005 7.3075C6.19714 7.71941 6.28882 8.1265 6.46805 8.49739H8.63195C8.56247 8.45268 8.47747 8.40924 8.42137 8.36352C8.25182 8.23845 8.11418 8.07512 8.01965 7.88682C7.92512 7.69853 7.87636 7.49058 7.87735 7.27989C7.86478 7.03938 7.91035 6.79938 8.01022 6.58022C8.11008 6.36107 8.26129 6.1692 8.45103 6.02089C8.93111 5.68794 9.50907 5.52583 10.0923 5.56053C10.7264 5.52021 11.3526 5.71904 11.8473 6.11778C12.0542 6.3126 12.2162 6.55011 12.3221 6.81382C12.428 7.07753 12.4753 7.36112 12.4606 7.64493V7.73944H14.1299V7.64493C14.1327 7.02109 13.9559 6.40963 13.6206 5.88355Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
