import { HoverCard } from '@mantine/core';
import FinanceIndicator from 'components/FinanceIndicator';
import ProgressGroup from 'components/progress/ProgressGroup';

/**
 * Styles for the HoverCard component.
 */
const hoverCardStyles = {
  dropdown: {
    backgroundColor: '#D4D4D4',
    borderColor: '#D4D4D4',
    color: '#4D4D4D',
    borderRadius: '8px',
    padding: 0,
    transform: 'translateX(-16px)',
  },
  arrow: {
    borderColor: '#D4D4D4',
  },
};

/**
 * The finance summary. Shows how much of the total value has been consumed.
 *
 * @param {{
 *   part: number,
 *   total: number,
 *   currency: string,
 *   type: 'budgeted' | 'invoiced' | 'external-costs,
 *   status?: string,
 *   progressVariant?: 'constant' | 'positive' | 'negative'
 * }}
 */
export default function FinanceSummary({ part, total, currency, type, progressVariant = 'constant' }) {
  return (
    <div className="flex flex-row items-center justify-between pr-2 text-[12px] leading-[16px] text-neutral-700">
      <HoverCard
        width={192}
        withArrow
        position="bottom"
        styles={hoverCardStyles}
        arrowSize={10}
        openDelay={0}
        closeDelay={50}
        offset={-7}
      >
        <HoverCard.Target>
          <div className="w-full pb-2 pt-2">
            <ProgressGroup total={total} part={part} currency={currency} variant={progressVariant} />
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <FinanceIndicator type={type} currency={currency} total={total} part={part} />
        </HoverCard.Dropdown>
      </HoverCard>
    </div>
  );
}
