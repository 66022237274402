import { Box, Button, Center, Stack, Text } from '@mantine/core';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { Link } from 'react-router-dom';

/**
 * No Access image.
 */
function NoAccessImage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="135" viewBox="0 0 220 135" fill="none">
      <path
        d="M157.699 53.8256L158.142 50.6906C158.368 49.0846 157.841 47.4639 156.713 46.299L133.75 22.5955C132.937 21.756 131.867 21.2116 130.71 21.0482L127.221 20.5557L93.069 15.7347C90.1948 15.3289 87.5358 17.33 87.1301 20.2042L84.4569 39.1412M157.699 53.8256L148.812 116.785C148.406 119.659 145.747 121.66 142.873 121.254L79.5233 112.312C76.6158 111.901 74.6085 109.188 75.0665 106.288L76.5 97.2088M157.699 53.8256C153.755 53.2687 143.499 51.8209 138.765 51.1527C134.031 50.4845 129.966 45.0827 130.634 40.3491L132.858 24.0363"
        stroke="#908EFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158 49L161.19 53.2537C162.029 54.3716 162.387 55.7774 162.187 57.1603L153.219 119.1C152.588 123.461 148.548 126.491 144.184 125.875L82.7611 117.204C80.2027 116.843 78.3493 114.579 78.5001 112V112"
        stroke="#908EFB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88 72.3333L88 75.2222M79.75 81L96.25 81C97.7688 81 99 79.7066 99 78.1111L99 69.4444C99 67.849 97.7688 66.5556 96.25 66.5556L79.75 66.5556C78.2312 66.5556 77 67.849 77 69.4444L77 78.1111C77 79.7066 78.2312 81 79.75 81ZM93.5 66.5556L93.5 60.7778C93.5 57.5868 91.0376 55 88 55C84.9624 55 82.5 57.5868 82.5 60.7778L82.5 66.5556L93.5 66.5556Z"
        stroke="#BF0D38"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="88.1123" cy="68.8211" r="26.5" stroke="#BF0D38" strokeWidth="2" />
    </svg>
  );
}

/**
 * Content of the no access page.
 */
export function NoAccessPageContent() {
  return (
    <Center className="bg-neutral-50 px-8 pb-12 pt-4" style={{ minHeight: 'calc(100vh - 60px)' }}>
      <Stack spacing={16} align="center">
        <NoAccessImage />
        <Text fz={24} lh={32 / 24} c="#4D4D4D" align="center">
          {_t('It seems you need')} <span className="font-semibold">{_t('additional permission')}</span>
          <br /> {_t('to access this content.')}
        </Text>
        <Box>
          {/* go back to the last page the user had access */}
          {/* -1 would not work since the user is redirected */}
          <Button component={Link} to={-2} variant="primary">
            {_t('Back')}
          </Button>
        </Box>
      </Stack>
    </Center>
  );
}

/**
 * No Access page.
 *
 * @see Figma {@link https://www.figma.com/design/CQQ1mMQRmo9H7dsJSejbRd/Toolio---Design?node-id=3371-14616&m=dev}
 */
export default function NoAccessPage() {
  return (
    <DashboardLayout>
      <NoAccessPageContent />
    </DashboardLayout>
  );
}
