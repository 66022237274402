import { useApi } from 'api/ApiContext';
import ToolioLogo from 'components/ToolioLogo';
import LoginForm from 'components/forms/login-form/LoginForm';
import { _t } from 'lang';
import { Group, Stack } from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { RESET_PASSWORD_PAGE_PATH } from 'routes/paths';

// TODO code review

/**
 * Resume session modal window.
 */
export default function ResumeSessionModal({ context, id }) {
  const [error, setError] = useState(null);
  const { login, email, firstName } = useApi();

  const initialValues = {
    email,
    password: '',
  };

  /**
   * Handles the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ email, password }) => {
    return login({ email, password })
      .then(() => {
        context.closeModal(id);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <Group position="center" className="bg-login-background w-[326px] bg-cover bg-center bg-no-repeat text-center">
      <div className="m-6 ml-auto mr-auto h-[550px] w-[278px]">
        <Group pr={24} pb={2} pl={1} position="center">
          <ToolioLogo className="ml-auto mr-auto" variant="dark" height="80px" width="109px" />
        </Group>
        <Stack spacing={16} h={320} mt={48}>
          <Group position="center" style={{ fontSize: '22px', lineHeight: '32px', color: '#38298B' }}>
            {_t('Welcome back')} {firstName}.
            <br />
            {_t('Login to continue.')}
          </Group>
          <Group position="center" maw={262} style={{ fontSize: '12px', lineHeight: '16px', color: '#38298B' }}>
            {_t('Seems like you have been inactive for a while or we need to authenticate you in order to continue.')}
          </Group>
          <div className="w-[278px] text-left">
            <LoginForm onSubmit={submit} initialValues={initialValues} isResumeSession={true} errorMessage={error} />
          </div>
          <Group
            position="center"
            style={{ color: '#38298B', fontSize: '15px', lineHeight: '20px', textDecorationLine: 'underline' }}
          >
            <Link to={RESET_PASSWORD_PAGE_PATH.original}>{_t('Forgot password?')}</Link>
          </Group>
        </Stack>
      </div>
    </Group>
  );
}
