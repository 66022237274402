/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-task-activity-create-action-body').UserTaskActivityCreateActionBody} UserTaskActivityCreateActionBody
 */

/**
 * @typedef {import('./user-task-activity-create-action-response').UserTaskActivityCreateActionResponse} UserTaskActivityCreateActionResponse
 */

/**
 * The path of the UserTaskActivityCreateAction action.
 */
export const userTaskActivityCreateActionPath = new SweetPath('/api/project/user_task_activity_create', {
  path: '{param}',
});

/**
 * Creates a contextualized UserTaskActivityCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserTaskActivityCreateAction(axios) {
  /**
   * The userTaskActivityCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserTaskActivityCreateActionBody } }
   *
   * @returns {Promise<UserTaskActivityCreateActionResponse>}
   */
  function userTaskActivityCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userTaskActivityCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return userTaskActivityCreateAction;
}
