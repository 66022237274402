import { Box } from '@mantine/core';
import { _t } from 'lang';

/**
 * Icon for like reaction.
 *
 * @param {{
 *   size?: number;
 *   useEmoji?: boolean;
 * }}
 */
export default function LikeReactionIcon({ size = 16, useEmoji = false } = {}) {
  if (useEmoji) {
    const emojiSize = size * 0.8;

    return (
      <Box w={emojiSize} h={emojiSize} fz={emojiSize} lh={1} aria-label={_t('like')}>
        👍
      </Box>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 10H5.23607C3.74931 10 2.78231 11.5646 3.44722 12.8944L6.94722 19.8944C7.286 20.572 7.97853 21 8.73607 21H12.7538C12.9173 21 13.0802 20.9799 13.2389 20.9403L17 20V11L13.6077 5.91149C13.2114 5.31715 13 4.61883 13 3.90453C13 3.40497 12.595 3 12.0955 3H12C10.8954 3 10 3.89543 10 5V10Z"
        fill="#FFC700"
      />
      <path
        d="M10 10H5.23607C3.74931 10 2.78231 11.5646 3.44722 12.8944L6.94722 19.8944C7.286 20.572 7.97853 21 8.73607 21H12.7538C12.9173 21 13.0802 20.9799 13.2389 20.9403L17 20M10 10V5C10 3.89543 10.8954 3 12 3H12.0955C12.595 3 13 3.40497 13 3.90453C13 4.61883 13.2114 5.31715 13.6077 5.91149L17 11V20M10 10H12M17 20H19C20.1046 20 21 19.1046 21 18V12C21 10.8954 20.1046 10 19 10H16.5"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
