/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "FiletypeTxt" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function FiletypeTxtIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 4.61929 6.11929 3.5 7.5 3.5H14.0925C14.8134 3.5 15.4992 3.8112 15.9739 4.35374L18.3814 7.10521C18.7802 7.56094 19 8.14591 19 8.75147V11C19 11.2761 18.7761 11.5 18.5 11.5C18.2239 11.5 18 11.2761 18 11V8.75147C18 8.6667 17.9928 8.5826 17.9788 8.5H15.5C14.6716 8.5 14 7.82843 14 7V4.5H7.5C6.67157 4.5 6 5.17157 6 6V19C6 19.8284 6.67157 20.5 7.5 20.5H13.5C13.7761 20.5 14 20.7239 14 21C14 21.2761 13.7761 21.5 13.5 21.5H7.5C6.11929 21.5 5 20.3807 5 19V6ZM15.2213 5.01224L17.3981 7.5H15.5C15.2239 7.5 15 7.27614 15 7V4.80568C15.0799 4.86639 15.1541 4.93545 15.2213 5.01224Z"
        fill={fill}
      />
      <path
        d="M15 14.75V13.5H14V14.75C14 14.9075 13.926 15.0555 13.8 15.15L13.5 15.375L13.2 15.15C13.074 15.0555 13 14.9075 13 14.75V13.5H12V14.75C12 15.2222 12.2222 15.6667 12.6 15.95L12.6667 16L12.6 16.05C12.222 16.3333 12 16.7778 12 17.25V18.5H13V17.25C13 17.0925 13.074 16.9445 13.2 16.85L13.5 16.625L13.8 16.85C13.926 16.9445 14 17.0925 14 17.25V18.5H15V17.25C15 16.7778 14.7778 16.3333 14.4 16.05L14.3333 16L14.4 15.95C14.7778 15.6667 15 15.2222 15 14.75Z"
        fill={fill}
      />
      <path d="M11 13.5H8V14.5H9V18.5H10V14.5H11V13.5Z" fill={fill} />
      <path d="M19 13.5H16V14.5H17V18.5H18V14.5H19V13.5Z" fill={fill} />
    </svg>
  );
}
