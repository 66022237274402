/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse['groups'][number]['items'][number]} SearchResult */

import { Box, Group } from '@mantine/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import striptags from 'striptags';
import SearchHighlighter from 'components/search/SearchHighlighter';
import { getObjectDetailPath } from 'components/ObjectDetailLink';
import Truncate from 'components/Truncate';

/**
 * Displays a single item in the advanced search result.
 *
 * @param {{ item: SearchResult; search: string }}
 */
export default function AdvancedSearchSectionResult({ search, item: { id, name, type, parent } }) {
  const strippedLabel = useMemo(() => striptags(name, [], ' ').replace(/(?:\s+|&nbsp;)/g, ' '), [name]);

  const path = useMemo(() => getObjectDetailPath({ type, id, parent }), [type, id, parent]);

  return (
    <Group position="apart" spacing={16} w="100%" style={{ borderBottom: '1px solid #E6E6E6' }} p={4}>
      <Box bg="neutral50" component={Link} to={path} w={841} className="hover:text-main-primary">
        <SearchHighlighter
          needle={search}
          haystack={strippedLabel}
          highlightClassName="font-medium bg-[transparent] text-main-primary"
        />
      </Box>
      <Box fz={12} lh={2}>
        {type === 'file' ? (
          <Truncate text={parent.parent ? parent.parent.name : parent.name} />
        ) : (
          <Truncate text={parent?.name ?? ''} />
        )}
      </Box>
    </Group>
  );
}
