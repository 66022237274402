/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "ExternalInvoice" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function ExternalInvoiceIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 4.61929 6.11929 3.5 7.5 3.5H14.0925C14.8134 3.5 15.4992 3.8112 15.9739 4.35374L18.3814 7.10521C18.7802 7.56094 19 8.14591 19 8.75147V16C19 16.2761 18.7761 16.5 18.5 16.5C18.2239 16.5 18 16.2761 18 16V8.75147C18 8.6667 17.9928 8.5826 17.9788 8.5H15.5C14.6716 8.5 14 7.82843 14 7V4.5H7.5C6.67157 4.5 6 5.17157 6 6V19C6 19.8284 6.67157 20.5 7.5 20.5H13.5C13.7761 20.5 14 20.7239 14 21C14 21.2761 13.7761 21.5 13.5 21.5H7.5C6.11929 21.5 5 20.3807 5 19V6ZM15.2213 5.01224L17.3981 7.5H15.5C15.2239 7.5 15 7.27614 15 7V4.80568C15.0799 4.86639 15.1541 4.93545 15.2213 5.01224Z"
        fill={fill}
      />
      <path
        d="M15 19C15 18.1716 15.6716 17.5 16.5 17.5H18.5C18.7761 17.5 19 17.7239 19 18C19 18.2761 18.7761 18.5 18.5 18.5H16.7071L19.8536 21.6464C20.0488 21.8417 20.0488 22.1583 19.8536 22.3536C19.6583 22.5488 19.3417 22.5488 19.1464 22.3536L16 19.2071V21C16 21.2761 15.7761 21.5 15.5 21.5C15.2239 21.5 15 21.2761 15 21V19Z"
        fill={fill}
      />
      <path
        d="M12.5 10.5C11.2905 10.5 10.2816 11.3589 10.05 12.5H12.5C12.7761 12.5 13 12.7239 13 13C13 13.2761 12.7761 13.5 12.5 13.5H10.05C10.2816 14.6411 11.2905 15.5 12.5 15.5C13.1814 15.5 13.7982 15.2281 14.2496 14.7857C14.4468 14.5925 14.7634 14.5957 14.9567 14.7929C15.15 14.9901 15.1467 15.3067 14.9495 15.5C14.3188 16.1181 13.4535 16.5 12.5 16.5C10.567 16.5 9 14.933 9 13C9 11.067 10.567 9.5 12.5 9.5C13.4792 9.5 14.3653 9.90281 15 10.5505C15.1932 10.7477 15.19 11.0643 14.9928 11.2576C14.7956 11.4508 14.479 11.4476 14.2857 11.2504C13.8314 10.7867 13.1996 10.5 12.5 10.5Z"
        fill={fill}
      />
    </svg>
  );
}
