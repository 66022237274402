import Alert from 'components/Alert';
import { _t } from 'lang';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import formatPrice from 'utils/format-price';

/**
 * Displays an alert if a discount has been applied to the cost estimate.
 */
export default function DiscountAlert() {
  const { costEstimate, currency } = useCostEstimateDetail();

  const discount = costEstimate.discount_total;

  if (!discount) {
    return <></>;
  }

  return (
    <Alert
      severity="information"
      primaryText={_t('There is a %s discount applied to this cost estimate.', formatPrice(discount, currency))}
    />
  );
}
