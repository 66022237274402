/** @typedef {import('api/actions/time-log-get-list-action/time-log-get-list-action-response').TimeLogGetListActionResponse['groups'][number]} TimeEntryGroup */
/** @typedef {import('hooks/use-grid-styles').GridStyles} GridStyles */

import { useMemo, useState } from 'react';
import { Box, Collapse } from '@mantine/core';
import GroupedTimeEntryRow from './GroupedTimeEntryRow';
import GroupHeadline from 'components/GroupHeadline';
import DurationDisplay from 'components/DurationDisplay';

/**
 * Grouped time entry in the the table.
 *
 * @param {{
 *   group: TimeEntryGroup,
 *   groupBy: string,
 *   gridStyles: GridStyles,
 *   refresh: () => void,
 * }}
 */
export default function GroupedTimeEntry({ group, groupBy, gridStyles, refresh }) {
  const [opened, setOpened] = useState(true);

  const groupName = useMemo(
    () => (groupBy === 'date' ? new Date(group.group_name).toLocaleDateString('sk') : group.group_name),
    [groupBy, group.group_name]
  );

  return (
    <Box bg="#FFFFFF" style={{ borderBottom: '1px solid #E6E6E6' }}>
      <GroupHeadline
        className="bg-white px-4"
        heading={groupName}
        opened={opened}
        setOpened={setOpened}
        collapsible
        number={<DurationDisplay display="inline" showMinutes duration={group.logged_total} />}
      />

      <Collapse in={opened}>
        {group.items.map((item) => (
          <GroupedTimeEntryRow key={item.time_log_id} item={item} gridStyles={gridStyles} refresh={refresh} />
        ))}
      </Collapse>
    </Box>
  );
}
