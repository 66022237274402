/** @typedef {import("../CostEstimateData").CostEstimateData} CostEstimateData */

import useImmutable from 'hooks/use-immutable';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import createCostEstimateData from 'pages/finance/cost-estimate/add-cost-estimate/CostEstimateData';
import { useParams } from 'react-router-dom';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useProject } from 'providers/project/ProjectProvider';
import { useClient } from 'providers/client/ClientProvider';
import createDiscountData from 'pages/finance/cost-estimate/add-cost-estimate/discount/DiscountData';
import { createCostEstimateSectionData } from 'pages/finance/cost-estimate/add-cost-estimate/section/CostEstimateSectionData';
import { createCostEstimatePartData } from 'pages/finance/cost-estimate/add-cost-estimate/part/CostEstimatePartData';
import {
  CUSTOM_JOB_POSITION_ITEM_ID,
  createCostEstimateRowData,
} from 'pages/finance/cost-estimate/add-cost-estimate/row/CostEstimateRowData';
import createAgencyFeeData from 'pages/finance/cost-estimate/add-cost-estimate/agency-fee/AgencyFeeData';
import deepEqual from 'deep-equal';
import { _t } from 'lang';
import deepDiff from 'deep-diff';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE } from 'utils/constants';

/**
 * The data context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   data: CostEstimateData,
 *   initialData: CostEstimateData,
 *   updateData: (update: Partial<CostEstimateData>) => void,
 *   hasChanges: () => boolean,
 * }>}
 */
export const DataContext = createContext();

/**
 * Provides the data context to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export function DataProvider({ children }) {
  const { costEstimateId } = useParams();

  const { getAction } = useApi();
  const costEstimateGetAction = getAction('CostEstimateGetAction');

  const { setProjectId } = useProject();
  const { setClientId } = useClient();

  const [loading, setLoading] = useState(!!costEstimateId);

  const [initialData, updateInitialData] = useImmutable(
    () => ({
      inHouse: {
        sections: [{ isInHouse: true, rows: [{ isInHouse: true }, { isInHouse: true }, { isInHouse: true }] }],
      },
      outSource: {
        sections: [],
      },
    }),
    { createFn: createCostEstimateData }
  );

  const [data, updateData] = useImmutable(initialData, { createFn: createCostEstimateData });

  useEffect(() => {
    if (costEstimateId) {
      costEstimateGetAction({ parameters: { cost_estimate_id: costEstimateId } })
        .then((data) => {
          setProjectId(data.project.project_id);
          setClientId(data.client.client_id);

          const sections = data.sections.map((section) =>
            createCostEstimateSectionData({
              isInHouse: section.is_in_house,
              name: section.section_name,
              discount: section.discount
                ? createDiscountData({
                    amount: section.discount,
                    type: section.discount_unit.trim() === '%' ? 'relative' : 'absolute',
                    comment: section.discount_comment || '',
                  })
                : undefined,
              agencyFee: section.agency_fee
                ? createAgencyFeeData({
                    amount: section.agency_fee,
                    comment: section.agency_fee_comment || '',
                  })
                : undefined,
              rows: section.items.map((item) =>
                createCostEstimateRowData({
                  isInHouse: section.is_in_house,
                  jobPositionId: item.position_in_company_id || CUSTOM_JOB_POSITION_ITEM_ID,
                  jobPositionName: item.position_in_company_id ? item.title : _t('Custom'),
                  note: item.title,
                  number: item.number,
                  unit: item.unit,
                  unitPrice: item.unit_price,
                  comment: item.comment || '',
                  externalCosts: item.external_cost,
                })
              ),
            })
          );

          const update = {
            costEstimateId: data.cost_estimate_id,
            name: data.cost_estimate_name,
            currency: data.currency,
            description: data.description,
            note: data.note,
            isDraft: data.is_draft,
            discount: data.discount
              ? createDiscountData({
                  amount: data.discount,
                  type: data.discount_unit.trim() === '%' ? 'relative' : 'absolute',
                  comment: data.discount_comment || '',
                })
              : undefined,
            inHouse: createCostEstimatePartData({
              sections: sections.filter((section) => section.isInHouse),
            }),
            outSource: createCostEstimatePartData({
              sections: sections.filter((section) => !section.isInHouse),
            }),
          };

          updateData(update);
          updateInitialData(update);
        })
        .catch((e) => {
          if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
            updateData(undefined);
          } else {
            panic(e);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [costEstimateId]);

  /**
   * Determines whether the data has changed.
   */
  const hasChanges = () => {
    const hasChanges = !deepEqual(data, initialData);

    if (hasChanges) {
      const diff = deepDiff(initialData, data);
      console.log(diff); // eslint-disable-line no-console
    }

    return hasChanges;
  };

  const value = useMemo(
    () => ({ data, initialData, updateData, hasChanges }),
    [data, initialData, updateData, hasChanges]
  );

  return <DataContext.Provider value={value}>{loading ? <></> : children}</DataContext.Provider>;
}

/**
 * Uses the data context.
 */
export function useData() {
  return useContext(DataContext);
}
