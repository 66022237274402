/**
 * Icon used by the Bold control component.
 */
export default function IconBold() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7083 9.83366C13.1163 9.5852 13.4607 9.24484 13.7138 8.83973C13.967 8.43463 14.1221 7.97597 14.1666 7.50033C14.1744 7.07023 14.0973 6.64282 13.9398 6.24252C13.7823 5.84222 13.5475 5.47686 13.2488 5.16732C12.9501 4.85777 12.5933 4.61011 12.1989 4.43847C11.8044 4.26683 11.3801 4.17458 10.95 4.16699H5.54163V15.8337H11.375C11.7843 15.8293 12.1887 15.7444 12.5651 15.5837C12.9416 15.423 13.2827 15.1898 13.5691 14.8973C13.8554 14.6048 14.0813 14.2587 14.2339 13.8789C14.3864 13.4991 14.4627 13.093 14.4583 12.6837V12.5837C14.4586 12.0063 14.2941 11.4408 13.9841 10.9536C13.6741 10.4665 13.2315 10.0779 12.7083 9.83366ZM7.20829 5.83366H10.7083C11.0634 5.82267 11.4134 5.92056 11.7114 6.1142C12.0093 6.30784 12.2408 6.58797 12.375 6.91699C12.5107 7.35681 12.4668 7.83248 12.2529 8.24004C12.0389 8.64759 11.6724 8.95389 11.2333 9.09199C11.0628 9.14196 10.886 9.16722 10.7083 9.16699H7.20829V5.83366ZM11.0416 14.167H7.20829V10.8337H11.0416C11.3968 10.8227 11.7468 10.9206 12.0447 11.1142C12.3426 11.3078 12.5742 11.588 12.7083 11.917C12.844 12.3568 12.8001 12.8325 12.5862 13.24C12.3723 13.6476 12.0057 13.9539 11.5666 14.092C11.3961 14.142 11.2193 14.1672 11.0416 14.167Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
