/** @typedef {import('api/file-manager/FileManagerContext').FileMetadata} FileMetadata */

import { Modal, ActionIcon, Flex, Button, Text, Center, CopyButton } from '@mantine/core';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import LinkIcon from 'components/icons/LinkIcon';
import { noop } from 'lodash';
import { useState, useEffect, useMemo, useCallback } from 'react';
import useFileDownload from 'api/file-manager/use-file-download';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import { useApi } from 'api/ApiContext';
import formatFileSize from 'utils/format-file-size';
import panic from 'errors/Panic';
import ImageDisplay from 'components/files/ImageDisplay';
import { FILE_DOWNLOAD_PAGE_PATH } from 'routes/paths';
import { _t } from 'lang';
import useImmutableList from 'hooks/use-immutable-list';
import Truncate from 'components/Truncate';

/**
 * Initializes the images.
 *
 * @typedef {{
 *   fileId: string;
 *   ready: boolean;
 *   metadata?: FileMetadata;
 *   author?: { full_name: string; };
 * }} Image
 *
 * @param {string[]} fileIds
 *
 * @returns {Image[]}
 */
const initImages = (fileIds) => fileIds.map((fileId) => ({ fileId, ready: false }));

/**
 * The slider for images.
 *
 * @param {{
 *   initialImageId: string;
 *   opened: boolean;
 *   onClose?: () => void;
 *   fileIds?: string[];
 * }}
 */
export default function Slider({ initialImageId, opened, onClose = noop, fileIds = [] }) {
  const { getAction } = useApi();
  const { getFileMetadata } = useFileManager();
  const { download } = useFileDownload();

  const [images, { update: updateImage, remove: removeImage }] = useImmutableList('fileId', () => initImages(fileIds));
  const [currImageId, setCurrImageId] = useState(initialImageId);

  const allImagesReady = useMemo(() => images.every(({ ready }) => ready), [images]);
  const currImage = useMemo(() => images.find(({ fileId }) => fileId === currImageId), [images, currImageId]);
  const currImageIndex = useMemo(() => images.findIndex(({ fileId }) => fileId === currImageId), [images, currImageId]);
  const currImageName = useMemo(() => currImage?.metadata?.name, [currImage]);
  const currImageExtension = useMemo(() => currImage?.metadata?.extension, [currImage]);
  const currImageFullName = useMemo(() => `${currImageName}${currImageExtension}`, [currImageName, currImageExtension]);

  const trimmedName = useMemo(() => currImageName?.slice(0, 40), [currImageName]);

  const currImageDownloadPath = useMemo(
    () => window.location.host + FILE_DOWNLOAD_PAGE_PATH.insert({ fileId: currImageId }),
    [currImageId]
  );

  const nextImageIndex = useMemo(() => Math.min(currImageIndex + 1, images.length - 1), [currImageIndex, images]);
  const prevImageIndex = useMemo(() => Math.max(currImageIndex - 1, 0), [currImageIndex]);
  const nextImageId = useMemo(() => images[nextImageIndex]?.fileId, [images, nextImageIndex]);
  const prevImageId = useMemo(() => images[prevImageIndex]?.fileId, [images, prevImageIndex]);
  const isLeftButtonDisabled = useMemo(() => currImageIndex === 0, [currImageIndex]);
  const isRightButtonDisabled = useMemo(() => currImageIndex === images.length - 1, [currImageIndex, images]);

  const goToPrevImage = useCallback(() => setCurrImageId(prevImageId), [prevImageId]);
  const goToNextImage = useCallback(() => setCurrImageId(nextImageId), [nextImageId]);
  const downloadCurrImage = useCallback(() => download([{ fileId: currImage.fileId }]), [download, currImage]);

  const fetchMetadataAndAuthor = useCallback(
    /** @param {string} fileId */
    async (fileId) => {
      const userGetMinimalInfoAction = getAction('UserGetMinimalInfoAction');
      const metadata = await getFileMetadata({ fileId });

      if (metadata.mimeType.startsWith('image/')) {
        const author = await userGetMinimalInfoAction({
          parameters: { user_id: metadata.created.author.userId },
        });

        updateImage(fileId, { metadata, author, ready: true });
      } else {
        removeImage(fileId);
      }
    },
    [getAction, getFileMetadata]
  );

  const initData = useCallback(async () => {
    const nonInitialAttachmentIds = fileIds.filter((id) => id !== initialImageId);

    await fetchMetadataAndAuthor(initialImageId);
    await Promise.all(nonInitialAttachmentIds.map(fetchMetadataAndAuthor));
  }, [initialImageId, fileIds, fetchMetadataAndAuthor]);

  useEffect(() => {
    if (opened) {
      initData().catch(panic);
    }
  }, [opened]);

  useEffect(() => {
    /** Handling keyboard navigation */
    const handleKeyboardNavigation = (event) => {
      if (opened && event.key === 'ArrowLeft') {
        goToPrevImage();
      } else if (opened && event.key === 'ArrowRight') {
        goToNextImage();
      }
    };

    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => window.removeEventListener('keydown', handleKeyboardNavigation);
  }, [opened, goToPrevImage, goToNextImage]);

  if (!opened || !currImage || !currImage.ready) {
    return null;
  }

  return (
    <Modal
      title={
        <Truncate
          text={`${trimmedName}${trimmedName.length < currImageName.length ? '..' : ''}${currImageExtension}`}
        />
      }
      opened={opened}
      onClose={onClose}
      centered
      size={682}
      styles={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(16px)' },
        inner: { paddingBottom: '110px' },
        modal: { padding: '8px 16px 16px 16px !important' },
        title: { fontSize: '20px', lineHeight: '36px' },
        header: { marginBottom: '8px' },
      }}
    >
      <ActionIcon
        radius="xl"
        pos="absolute"
        top="50%"
        left="-60px"
        style={{ border: 'none', background: isLeftButtonDisabled ? '#FFFFFF1A' : 'white' }}
        onClick={goToPrevImage}
        disabled={isLeftButtonDisabled}
      >
        <ArrowLeftIcon stroke={isLeftButtonDisabled ? '#FFFFFF33' : '#38298B'} />
      </ActionIcon>
      <Center w={650} mih={300}>
        <ImageDisplay fileId={currImageId} alt={currImageFullName} title={currImageFullName} imageSize="800-max" />
      </Center>
      <ActionIcon
        radius="xl"
        pos="absolute"
        top="50%"
        right="-60px"
        style={{ border: 'none', background: isRightButtonDisabled ? '#FFFFFF1A' : 'white' }}
        onClick={goToNextImage}
        disabled={isRightButtonDisabled}
      >
        <ArrowRightIcon stroke={isRightButtonDisabled ? '#FFFFFF33' : '#38298B'} />
      </ActionIcon>
      <Flex justify="space-between" gap={32} mt={8}>
        <Text className="text-xs font-normal text-neutral-500">
          By {currImage.author.full_name} on {new Date(currImage.metadata.created.timestamp).toLocaleString('sk-SK')},
          <br />
          Size: {formatFileSize(currImage.metadata.size)}
        </Text>
        <Flex gap={16}>
          <CopyButton value={currImageDownloadPath}>
            {({ copy }) => (
              <Button variant="secondary" w={130} leftIcon={<LinkIcon />} onClick={copy}>
                {_t('Copy link')}
              </Button>
            )}
          </CopyButton>
          <Button variant="primary" leftIcon={<DownloadIcon />} onClick={downloadCurrImage}>
            Download
          </Button>
        </Flex>
      </Flex>
      {allImagesReady && (
        <Text w="calc(100% - 32px)" pos="absolute" bottom={-42} left={16} c="#fff" fz={15} lh={18 / 15} align="center">
          {currImageIndex + 1} / {images.length}
        </Text>
      )}
    </Modal>
  );
}
