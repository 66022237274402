import { Stack } from '@mantine/core';
import TaskDescription from 'pages/tasks/task-detail/parts/TaskDescription';
import TaskFiles from 'pages/tasks/task-detail/parts/TaskFiles';
import TaskSubtasks from 'pages/tasks/task-detail/parts/TaskSubtasks';
import TaskOverview from 'pages/tasks/task-detail/parts/TaskOverview';
import TaskComments from 'pages/tasks/task-detail/parts/TaskComments';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';

/**
 * Overview tab in the task detail page.
 */
export default function TaskOverviewTab() {
  const { isSubtask } = useTaskDetail();

  return (
    <Stack spacing={16} pt={16}>
      <TaskOverview />
      <TaskDescription />
      <TaskFiles />
      {!isSubtask && <TaskSubtasks collapsible />}
      <TaskComments collapsible />
    </Stack>
  );
}
