/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { Box, Group, Skeleton, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import Alert from 'components/Alert';
import InvoiceForm from 'components/forms/invoice-form/InvoiceForm';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * The contents of the add invoice page.
 *
 * @param {{
 *   costEstimate: CostEstimate;
 * }}
 */
export default function AddInvoicePageContent({ costEstimate }) {
  const navigate = useNavigate();
  const { costEstimateId } = useParams();

  const { getAction, userId } = useApi();
  const invoiceCreateAction = getAction('InvoiceCreateAction');

  const detailPath = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId });
  const detailTab = `${detailPath}?tab=invoice`;

  const initialValues = {
    invoiceNumber: '',
    issued: '',
    supplied: '',
    dueDate: '',
    note: '',
    fileId: undefined,
    amount: costEstimate.amount_after_discount,
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ invoiceNumber, issued, supplied, dueDate, note, fileId }) => {
    return invoiceCreateAction({
      body: {
        invoice_number: invoiceNumber,
        issued: new Date(issued).toLocaleDateString(),
        supplied: new Date(supplied).toLocaleDateString(),
        due_date: new Date(dueDate).toLocaleDateString(),
        note,
        cost_estimate_id: costEstimateId,
        author_id: String(userId),
        file_id: fileId[0].fileId,
        amount: costEstimate.amount_after_discount,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Invoice uploaded'),
          message: _t('Invoice has been uploaded successfully.'),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(detailTab, { replace: true });
      })
      .catch((e) => {
        panic(e.message);
      });
  };

  if (costEstimate.invoice || costEstimate.closed_at) {
    return <Navigate to={detailTab} replace />;
  }

  return (
    <div>
      <Box pt={24} pb={32}>
        <Alert severity="notification" primaryText={costEstimate.client.note_invoice ?? ''} />
      </Box>
      <Group mb={32} h={34} spacing={16} align="center">
        <Text fz={15} lh={18 / 15}>
          {_t('Status:')}
        </Text>
        <Group spacing={4} align="center">
          <Skeleton bg="#DCDCDC" w={12} h={12} radius={6} animate={false} />
          <Text fz={15} lh={18 / 15}>
            {_t('Invoice to be uploaded')}
          </Text>
        </Group>
      </Group>
      <Box mt={16}>
        <InvoiceForm initialValues={initialValues} onSubmit={submit} currency={costEstimate.currency} />
      </Box>
    </div>
  );
}
