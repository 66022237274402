import { Button } from '@mantine/core';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import useFileDownload from 'api/file-manager/use-file-download';
import panic from 'errors/Panic';
import { useState } from 'react';

/**
 * Link used to download a multiple files.
 *
 * @param {{ fileIds: string[] } & import('@mantine/core').ButtonProps}
 */
export default function BulkFileDownloadLink({ fileIds, ...props }) {
  const { loading: loadingDownload, download } = useFileDownload();
  const [loadingZip, setLoadingZip] = useState(false);
  const { zipFiles } = useFileManager();

  /**
   * Zips the files and downloads the zip.
   */
  const zipAndDownload = () => {
    setLoadingZip(true);

    zipFiles({ files: fileIds })
      .then((zipFileId) => download([{ fileId: zipFileId, fileName: 'files.zip' }]))
      .catch(panic)
      .finally(() => setLoadingZip(false));
  };

  return (
    <Button
      {...props}
      style={{ userSelect: 'text' }}
      variant="link"
      loaderPosition="right"
      loading={loadingDownload || loadingZip}
      onClick={zipAndDownload}
    />
  );
}
