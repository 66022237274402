// Buttons
import { Button } from 'theme/components/Buttons/Button';

// Feedback
import { Loader } from 'theme/components/Feedback/Loader';

// Inputs
import { Checkbox } from 'theme/components/Inputs/Checkbox';
import { DatePicker } from 'theme/components/Inputs/DatePicker';
import { DateRangePicker } from './components/Inputs/DateRangePicker';
import { TimeInput } from 'theme/components/Inputs/TimeInput';
import { Input } from 'theme/components/Inputs/Input';
import { InputWrapper } from 'theme/components/Inputs/InputWrapper';
import { MultiSelect } from 'theme/components/Inputs/MultiSelect';
import { NumberInput } from 'theme/components/Inputs/NumberInput';
import { RangeCalendar } from 'theme/components/Inputs/RangeCalendar';
import { Select } from 'theme/components/Inputs/Select';
import { Textarea } from 'theme/components/Inputs/Textarea';
import { TextInput } from 'theme/components/Inputs/TextInput';

// Navigation
import { Breadcrumbs } from 'theme/components/Navigation/Breadcrumbs';
import { Tabs } from 'theme/components/Navigation/Tabs';

// Others
import { Dropzone } from 'theme/components/Others/Dropzone';
import { RichTextEditor } from 'theme/components/Others/RichTextEditor';

// Overlays
import { Menu } from 'theme/components/Overlays/Menu';
import { Tooltip } from 'theme/components/Overlays/Tooltip';

// Typography
import { TypographyStylesProvider } from 'theme/components/Typography/TypographyStylesProvider';

/** @type {import('@mantine/core').MantineThemeOverride} */
const theme = {
  fontFamily: 'Rubik, sans-serif',
  black: '#4D4D4D',
  white: '#FFFFFF',
  primaryColor: 'primary',
  fontSizes: {
    md: '15px',
  },
  colors: {
    black: ['#4D4D4D'],
    white: ['#FFFFFF'],
    primary: Array.from({ length: 10 }, () => '#38298B'),
    secondary: Array.from({ length: 10 }, () => '#CECDFF'),
    accent: Array.from({ length: 10 }, () => '#FFC700'),
    neutral700: Array.from({ length: 10 }, () => '#4D4D4D'),
    neutral500: Array.from({ length: 10 }, () => '#808080'),
    neutral300: Array.from({ length: 10 }, () => '#B3B3B3'),
    neutral200: Array.from({ length: 10 }, () => '#D4D4D4'),
    neutral100: Array.from({ length: 10 }, () => '#E6E6E6'),
    neutral50: Array.from({ length: 10 }, () => '#F2F2F2'),
    neutral20: Array.from({ length: 10 }, () => '#FAFAFA'),
    red: Array.from({ length: 10 }, () => '#BF0D38'),
    redErrorBackground: Array.from({ length: 10 }, () => '#FFB6BA'),
    complementaryAfricanViolet: Array.from({ length: 10 }, () => '#A475C0'),
    complementaryLavender: Array.from({ length: 10 }, () => '#CFB0E2'),
    complementaryIndigo: Array.from({ length: 10 }, () => '#211C57'),
  },
  components: {
    // Buttons
    Button,
    // Feedback
    Loader,
    // Inputs
    Checkbox,
    DatePicker,
    DateRangePicker,
    TimeInput,
    Input,
    InputWrapper,
    MultiSelect,
    NumberInput,
    RangeCalendar,
    Select,
    Textarea,
    TextInput,
    // Navigation
    Breadcrumbs,
    Tabs,
    // Others
    Dropzone,
    RichTextEditor,
    // Overlays
    Menu,
    Tooltip,
    // Typography
    TypographyStylesProvider,
  },
};

export default theme;
