import AppSidebar from 'layouts/dashboard-layout/AppSidebar';
import { APP_HEADER_HEIGHT, APP_SIDEBAR_WIDTH, BOTTOM_BAR_HEIGHT, MODULE_SIDEBAR_WIDTH } from 'utils/constants';
import DashboardHeader from 'layouts/dashboard-layout/headers/DashboardHeader';
import { Box } from '@mantine/core';

/**
 * DashboardLayout component.
 *
 * @param {{
 *   header: React.ReactNode,
 *   sidebar: React.ReactNode,
 *   children: React.ReactNode,
 *   footer: React.ReactNode,
 *   appSidebarWidth: number,
 *   moduleSidebarWidth: number,
 *   headerHeight: number,
 * }}
 */

/**
 * DashboardLayout
 */
export default function DashboardLayout({
  sidebar,
  children,
  footer,
  appSidebarWidth = APP_SIDEBAR_WIDTH,
  moduleSidebarWidth = MODULE_SIDEBAR_WIDTH,
  headerHeight = APP_HEADER_HEIGHT,
  contentClass = '',
}) {
  if (!sidebar) {
    moduleSidebarWidth = 0;
  }

  const contentPaddingTop = headerHeight;
  const contentPaddingLeft = appSidebarWidth + moduleSidebarWidth;
  const contentHeight = footer ? `calc(100% - ${BOTTOM_BAR_HEIGHT}px)` : '100%';
  const contentBottomPadding = footer ? `${BOTTOM_BAR_HEIGHT}px` : '0';

  return (
    <div>
      {/* App sidebar */}
      <div className="fixed left-0 top-0 z-[199] h-full" style={{ width: `${appSidebarWidth}px` }}>
        <AppSidebar />
      </div>

      {/* Header */}
      <div
        className="fixed z-[199]"
        style={{
          height: `${headerHeight}px`,
          width: `calc(100% - ${appSidebarWidth}px)`,
          left: `${appSidebarWidth}px`,
        }}
      >
        <DashboardHeader />
      </div>

      {/* Module sidebar */}
      <Box
        className="fixed z-10 h-full"
        sx={{
          left: `${appSidebarWidth}px`,
          top: `${headerHeight}px`,
          width: `${moduleSidebarWidth}px`,
          height: `calc(100% - ${headerHeight}px)`,
          '@media (max-width: 767px)': {
            display: 'none',
          },
        }}
      >
        {sidebar}
      </Box>

      {/* Content */}
      <Box
        className="h-screen"
        sx={{
          paddingTop: `${contentPaddingTop}px`,
          paddingLeft: `${contentPaddingLeft}px`,
          paddingBottom: `${contentBottomPadding}`,
          height: `${contentHeight}`,
          '@media (max-width: 767px)': {
            paddingLeft: `${appSidebarWidth}px`,
          },
        }}
      >
        <div style={{ minHeight: `calc(100vh - ${headerHeight}px)` }} className={contentClass}>
          {children}
        </div>
      </Box>

      <div>{footer}</div>
    </div>
  );
}
