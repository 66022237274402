import Alert from 'components/Alert';
import { _t } from 'lang';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import formatPrice from 'utils/format-price';

/**
 * Displays an alert when the cost estimate is not profitable.
 */
export default function BudgetOverflowAlert() {
  const { costEstimate, currency } = useCostEstimateDetail();

  const budget = costEstimate.budget;
  const expenses = costEstimate.external_cost_sum_actual;
  const profit = budget - expenses;
  const isProfitable = profit >= 0;

  if (isProfitable) {
    return <></>;
  }

  return (
    <Alert
      severity="error"
      primaryText={_t('This cost estimate is currently %s in loss.', formatPrice(-profit, currency))}
    />
  );
}
