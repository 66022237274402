/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./permission-get-list-action-body').PermissionGetListActionBody} PermissionGetListActionBody
 */

/**
 * @typedef {import('./permission-get-list-action-response').PermissionGetListActionResponse} PermissionGetListActionResponse
 */

/**
 * The path of the PermissionGetListAction action.
 */
export const permissionGetListActionPath = new SweetPath('/api/user/permission/', { path: '{param}' });

/**
 * Creates a contextualized PermissionGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPermissionGetListAction(axios) {
  /**
   * The permissionGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<PermissionGetListActionResponse>}
   */
  function permissionGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = permissionGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return permissionGetListAction;
}
