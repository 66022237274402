import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { _t } from 'lang';
import { useNavigate } from 'react-router-dom';
import { ADD_ROLE_PAGE_PATH } from 'routes/paths';
import DashboardPageHeading from 'layouts/dashboard-layout/headers/DashboardPageHeading';
import RoleTable from 'components/tables/RoleTable';

/**
 * Roles page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=233%3A6291
 */
export default function Roles() {
  const navigate = useNavigate();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="relative h-full p-[20px] pt-0">
        <DashboardPageHeading heading={_t('Roles')} buttonAction={() => navigate(ADD_ROLE_PAGE_PATH.original)} />
        <RoleTable />
      </div>
    </DashboardLayout>
  );
}
