import { Stack } from '@mantine/core';
import CommentThread from 'components/comments/CommentThread';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';

/**
 * Comments section in Cost Estimate Detail page.
 */
export default function CostEstimateComments() {
  const { costEstimate } = useCostEstimateDetail();

  return (
    <Stack spacing={16}>
      <CommentThread
        threadId={costEstimate.comment_thread_id ?? ''}
        groupHeadlineKey={`toolio.cost-estimate.${costEstimate.cost_estimate_id}.comments.opened`}
        headlineCollapsible
        withHeadline
        withNotifyMeSwitch={false}
        autoRefreshEnabled
      />
    </Stack>
  );
}
