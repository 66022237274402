import { useDisclosure } from '@mantine/hooks';
import ConfirmModal from 'components/modals/ConfirmModal';
import { noop } from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

/**
 * The props of the confirm callback.
 *
 * @typedef {{
 *   title: string;
 *   message: string;
 *   primaryButtonText?: string;
 *   secondaryButtonText?: string;
 *   hideSecondaryButton?: boolean;
 *   onConfirm?: () => void;
 * }} ConfirmProps
 */

/**
 * The confirm callback.
 *
 * @typedef {(props: ConfirmProps) => void} ConfirmCallback
 */

/**
 * The confirm context.
 *
 * @type {React.Context<{
 *   confirm: ConfirmCallback;
 * }>}
 */
const ConfirmContext = createContext();

/**
 * Provides the confirm context.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export function ConfirmProvider({ children }) {
  const [opened, { close, open }] = useDisclosure(false);

  const [props, setProps] = useState({
    title: '',
    message: '',
    hideSecondaryButton: false,
    onConfirm: noop,
  });

  /**
   * Opens the confirm modal.
   *
   * @type {ConfirmCallback}
   */
  const confirm = useCallback(
    (props) => {
      setProps(props);
      open();
    },
    [setProps, open]
  );

  const value = useMemo(() => ({ confirm }), [confirm]);

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      <ConfirmModal opened={opened} onClose={close} {...props} />
    </ConfirmContext.Provider>
  );
}

/**
 * Uses the confirm context.
 */
export function useConfirm() {
  return useContext(ConfirmContext);
}
