/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Attachment" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function AttachmentIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 11.4334L11.2698 19.1637C9.70689 20.7266 7.17382 20.7266 5.61145 19.1642C4.04855 17.6013 4.04855 15.0682 5.61145 13.5053L14.8681 4.24871C15.9209 3.19586 17.6288 3.19533 18.6822 4.24871C19.735 5.30156 19.7355 7.00943 18.6822 8.06281L9.44918 17.2958C8.90638 17.8386 8.02694 17.8386 7.48414 17.2958C6.94134 16.753 6.94134 15.8736 7.48414 15.3308L15.3325 7.48243"
        stroke={stroke}
        strokeMiterlimit="10"
      />
    </svg>
  );
}
