/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-add-attachment-action-body').TaskAddAttachmentActionBody} TaskAddAttachmentActionBody
 */

/**
 * @typedef {import('./task-add-attachment-action-response').TaskAddAttachmentActionResponse} TaskAddAttachmentActionResponse
 */

/**
 * The path of the TaskAddAttachmentAction action.
 */
export const taskAddAttachmentActionPath = new SweetPath('/api/project/project/task/{task_id}/add-attachment', {
  path: '{param}',
});

/**
 * Creates a contextualized TaskAddAttachmentAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskAddAttachmentAction(axios) {
  /**
   * The taskAddAttachmentAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskAddAttachmentActionBody } }
   *
   * @returns {Promise<TaskAddAttachmentActionResponse>}
   */
  function taskAddAttachmentAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskAddAttachmentActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return taskAddAttachmentAction;
}
