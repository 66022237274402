import { ColorInput, Stack, Tabs, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import UserSelect from 'components/selects/UserSelect';
import TeamSelect from 'components/selects/TeamSelect';
import FormRow from 'components/forms/FormRow';
import { useSearchParams } from 'react-router-dom';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';

/**
 * @typedef {{
 *   departmentName: string;
 *   leaderId: string;
 *   color: string;
 *   avatar: string;
 *   teams: string[];
 * }} DepartmentFormData
 */

/**
 * The Add / Edit Department form.
 *
 * TODO inputs for avatar
 *
 * @param {{
 *   initialValues: DepartmentFormData,
 *   onSubmit: (values: DepartmentFormData) => void | Promise<void>,
 * }}
 */
export default function DepartmentForm({ initialValues, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'department';

  const form = useForm({
    initialValues,

    validate: {
      departmentName: createValidator([required]),
      leaderId: createValidator([required]),
      color: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Tabs defaultValue={activeTab}>
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="department">{_t('Department')}</Tabs.Tab>
          <Tabs.Tab value="teams">{_t('Teams')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="department">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Department name')}
              input={
                <TextInput
                  type="text"
                  placeholder={_t('Department name')}
                  autoFocus
                  {...form.getInputProps('departmentName')}
                />
              }
            />
            <FormRow
              label={_t('Department director')}
              input={<UserSelect placeholder={_t('Director')} {...form.getInputProps('leaderId')} />}
            />
            <FormRow
              label={_t('Color')}
              input={<ColorInput placeholder={_t('Pick a color')} {...form.getInputProps('color')} />}
            />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="teams">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Teams (readonly)')}
              tooltipText={_t('The department of a team can be changed in the team detail.')}
              input={
                <TeamSelect
                  readOnly
                  disabled
                  multiple={true}
                  placeholder={_t('No teams')}
                  {...form.getInputProps('teams')}
                />
              }
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
