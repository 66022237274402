/** @typedef {import('./actions').GetActionMap} GetActionMap */

import Qs from 'qs';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiContext } from 'api/ApiContext';
import useLocalStorage from 'hooks/use-local-storage';
import { _t } from 'lang';
import actions from 'api/actions';
import ApiError, { API_ERROR_LEVEL_ERROR, API_ERROR_LEVEL_WARNING } from 'api/api-error';
import createWorkspaceConfig from 'api/workspace-config';
import { REST_API_TIMEOUT, REST_API_URL } from 'environment';

/**
 * The user is not logged exception code.
 */
export const USER_IS_NOT_LOGGED_EXCEPTION_CODE = 4010107;

/**
 * Creates an empty session.
 */
const createEmptySession = () => ({
  jwt: '',
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  userId: -1,
  permissions: [],
  roles: [],
  accessibleClients: [],
});

/**
 * API Provider.
 */
export default function ApiProvider({ children }) {
  const [ready, setReady] = useState(false);
  const [shouldResumeSession, setShouldResumeSession] = useState(false);
  const [workspaceConfig] = useState(createWorkspaceConfig);

  const [{ jwt, firstName, lastName, fullName, email, userId, permissions, roles, accessibleClients }, setSession] =
    useLocalStorage('toolio.session', createEmptySession());

  /**
   * Destroys the session.
   */
  const destroySession = useCallback(() => {
    setSession(createEmptySession());
    setShouldResumeSession(true);
  }, [setSession, setShouldResumeSession]);

  /**
   * Creates a contextualized action.
   *
   * @type {GetActionMap}
   */
  const getAction = useMemo(() => {
    const connector = axios.create({
      timeout: REST_API_TIMEOUT,
      baseURL: REST_API_URL,
    });

    // Intercept the request
    connector.interceptors.request.use((cfg) => {
      // Add JWT to the request.
      if (jwt) {
        if (!cfg.headers) {
          cfg.headers = {};
        }

        if (jwt && !cfg.headers.Authorization) {
          cfg.headers.Authorization = `Bearer ${jwt}`;
        }
      }

      // Serialize deep objects.
      cfg.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false });

      return cfg;
    });

    // Processes the success and fail response.
    connector.interceptors.response.use(
      ({ data }) => data.response,
      (err) => {
        let code = -1;
        let message = _t('Server error, please try again later');
        let level = API_ERROR_LEVEL_ERROR;

        if (err.response && err.response.data && err.response.data.error) {
          code = err.response.data.error.code;
          message = err.response.data.error.message;
        }

        if (code === USER_IS_NOT_LOGGED_EXCEPTION_CODE) {
          setShouldResumeSession(true);
          message = _t('Your session has expired, please log in again');
          level = API_ERROR_LEVEL_WARNING;
        }

        throw new ApiError(message, code, level);
      }
    );

    // Contextualize (create) all actions only once.
    const contextualizedActions = new Map(
      Array.from(actions.entries()).map(([name, createAction]) => [name, createAction(connector)])
    );

    return (action) => contextualizedActions.get(action);
  }, [jwt, setShouldResumeSession]);

  /**
   * Logs the user in and stores the session.
   *
   * @type {(params: { email: string; password: string }) => Promise<void>}
   */
  const login = useCallback(
    async ({ email, password }) => {
      const loginAction = getAction('UserLoginAction');
      const userGetMeAction = getAction('UserGetMeAction');

      const { token } = await loginAction({ body: { email, password } });

      const { first_name, last_name, full_name, user_id, permissions, roles, clients } = await userGetMeAction({
        headers: { Authorization: `Bearer ${token}` },
      });

      setSession({
        jwt: token,
        firstName: first_name,
        lastName: last_name,
        fullName: full_name,
        email,
        userId: user_id,
        permissions,
        roles,
        accessibleClients: clients,
      });

      setShouldResumeSession(false);
    },
    [getAction, setSession, setShouldResumeSession]
  );

  /**
   * Initialize the session.
   */
  const initSession = useCallback(async () => {
    const userGetMeAction = getAction('UserGetMeAction');

    const { email, first_name, last_name, full_name, user_id, permissions, roles, clients } = await userGetMeAction();

    setSession(({ jwt }) => ({
      jwt,
      firstName: first_name,
      lastName: last_name,
      fullName: full_name,
      email,
      userId: user_id,
      permissions,
      roles,
      accessibleClients: clients,
    }));
  }, [getAction, setSession, destroySession]);

  useEffect(() => {
    initSession()
      .catch(destroySession)
      .finally(() => setReady(true));
  }, []); // Run only once.

  const hasPermission = useCallback(
    /**
     * @param {string} permission
     * @param {'*' | number | null} clientId
     */
    (permission, clientId = null) => {
      const globalPermission = `${permission}_GLOBAL`;

      if (permissions.includes(globalPermission)) {
        return true;
      }

      if (permissions.includes(permission) && (clientId === '*' || accessibleClients.includes(clientId))) {
        return true;
      }

      return false;
    },
    [permissions, accessibleClients]
  );

  const hasRole = useCallback(
    /** @param {string} roleName */
    (roleName) => roles.some((role) => role.role_name === roleName),
    [roles]
  );

  const value = useMemo(
    () => ({
      ready,
      shouldResumeSession,
      jwt,
      firstName,
      lastName,
      fullName,
      email,
      userId,
      permissions,
      roles,
      accessibleClients,
      getAction,
      login,
      logout: destroySession,
      hasPermission,
      hasRole,
      workspaceConfig,
    }),
    [
      ready,
      shouldResumeSession,
      jwt,
      firstName,
      lastName,
      fullName,
      email,
      userId,
      permissions,
      roles,
      accessibleClients,
      getAction,
      login,
      destroySession,
      hasPermission,
      hasRole,
      workspaceConfig,
    ]
  );

  return <ApiContext.Provider value={value}>{ready ? children : <></>}</ApiContext.Provider>;
}
