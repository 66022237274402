import { Box, Button, Group, Modal, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import InputWrapper from 'components/inputs/InputWrapper';
import UserSelect from 'components/selects/UserSelect';
import Truncate from 'components/Truncate';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Two part input label.
 *
 * @param {{
 *   left: string;
 *   right: string;
 * }}
 */
function KeyValueLabel({ left, right }) {
  return (
    <Box align="baseline" className="grid w-full grid-cols-[auto_1fr] gap-1">
      <Box className="whitespace-nowrap">{left}</Box>
      <Truncate text={right} />
    </Box>
  );
}

/**
 * Modal used to deactivate user.
 *
 * @param {{
 *   opened: boolean;
 *   holdingProjects: {project_id: number, project_name: string, project_full_name: string}[],
 *   leadingTeams: {team_id: number, name: string}[],
 *   directingDepartments: {department_id: number, name: string}[],
 *   userId: number;
 *   onDeactivate?: (() => void) | (() => Promise<void>);
 *   onClose: () => void;
 * }}
 */
export default function DeactivateUserModal({
  opened,
  holdingProjects = [],
  leadingTeams = [],
  directingDepartments = [],
  userId,
  onDeactivate = noop,
  onClose,
}) {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      projectHolders: (holdingProjects || []).reduce((acc, project) => {
        acc[project.project_id] = '';
        return acc;
      }, {}),
      teamLeaders: (leadingTeams || []).reduce((acc, team) => {
        acc[team.team_id] = '';
        return acc;
      }, {}),
      departmentDirectors: (directingDepartments || []).reduce((acc, department) => {
        acc[department.department_id] = '';
        return acc;
      }, {}),
    },
    validate: {
      projectHolders: (holdingProjects || []).reduce((acc, project) => {
        acc[project.project_id] = createValidator([required]);
        return acc;
      }, {}),
      teamLeaders: (leadingTeams || []).reduce((acc, team) => {
        acc[team.team_id] = createValidator([required]);
        return acc;
      }, {}),
      departmentDirectors: (directingDepartments || []).reduce((acc, department) => {
        acc[department.department_id] = createValidator([required]);
        return acc;
      }, {}),
    },
  });

  const nFunctions = useMemo(
    () => holdingProjects.length + leadingTeams.length + directingDepartments.length,
    [holdingProjects, leadingTeams, directingDepartments]
  );

  const actionFilter = useMemo(() => ({ 'user_id.neq': userId }), [userId]);
  const teamLeaderAdditionalValues = useMemo(() => [{ label: _t('No team leader'), value: '-1' }], []);

  /**
   * Handles the click on the deactivate button.
   */
  const onClick = useCallback(
    async ({ projectHolders, teamLeaders, departmentDirectors }) => {
      try {
        const deactivateAction = onDeactivate({ projectHolders, teamLeaders, departmentDirectors });

        if (deactivateAction instanceof Promise) {
          setLoading(true);
          await deactivateAction;
        }

        form.reset();
        onClose();
      } catch (err) {
        panic(err);
      }
    },
    [setLoading, onDeactivate, onClose, form]
  );

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [onClose, form]);

  useEffect(() => {
    if (!opened) {
      setLoading(false);
    }
  }, [opened]);

  return (
    <Modal
      centered
      onClose={handleClose}
      opened={opened}
      title={<span className="text-[20px] leading-[36px]">{_t('Review and confirm user deletion')}</span>}
      closeOnClickOutside={false}
      closeOnEscape={!loading}
      withCloseButton={!loading}
      size={540}
    >
      <div className="mb-4 text-[15px] leading-[20px] text-neutral-700">
        <Stack spacing={16}>
          <Box>
            {
              _t('Once deleted, user will be removed from all assigned tasks and projects and will no longer receive notifications or access any files.') // prettier-ignore
            }
          </Box>
          <Box>{_t('This action is irreversible.')}</Box>
          {nFunctions > 0 && <Box>{_t('Assign a replacement to take over their duties in order to proceed.')}</Box>}
        </Stack>
      </div>
      <form onSubmit={form.onSubmit(onClick)}>
        {nFunctions > 0 && (
          <Stack spacing={8}>
            {holdingProjects.map(({ project_id: id, project_full_name: name }) => (
              <InputWrapper
                key={id}
                align="column"
                inputSize="medium"
                label={<KeyValueLabel left={_t('Set new project holder for:')} right={name} />}
                input={<UserSelect actionFilter={actionFilter} {...form.getInputProps(`projectHolders.${id}`)} />}
              />
            ))}
            {leadingTeams.map(({ team_id: id, name }) => (
              <InputWrapper
                key={id}
                align="column"
                inputSize="medium"
                label={<KeyValueLabel left={_t('Set new team leader for:')} right={name} />}
                input={
                  <UserSelect
                    actionFilter={actionFilter}
                    additionalValues={teamLeaderAdditionalValues}
                    {...form.getInputProps(`teamLeaders.${id}`)}
                  />
                }
              />
            ))}
            {directingDepartments.map(({ department_id: id, name }) => (
              <InputWrapper
                key={id}
                align="column"
                inputSize="medium"
                label={<KeyValueLabel left={_t('Set new department director for:')} right={name} />}
                input={<UserSelect actionFilter={actionFilter} {...form.getInputProps(`departmentDirectors.${id}`)} />}
              />
            ))}
          </Stack>
        )}
        <Group position="right" spacing={16} my={16}>
          <Button disabled={loading} variant="link" onClick={handleClose} px={8}>
            {_t('Cancel')}
          </Button>
          <Button loading={loading} variant="primary" type="submit">
            {_t('Delete user')}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
