import SupplierTable from 'components/tables/SupplierTable';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import DashboardPageHeading from 'layouts/dashboard-layout/headers/DashboardPageHeading';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import { ADD_SUPPLIER_PAGE_PATH } from 'routes/paths';

/**
 * Suppliers page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=303%3A13422
 */
export default function SuppliersPage() {
  const navigate = useNavigate();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="relative h-full p-[20px] pt-0">
        <DashboardPageHeading
          heading={_t('Suppliers')}
          buttonAction={() => navigate(ADD_SUPPLIER_PAGE_PATH.original)}
        />
        <SupplierTable />
      </div>
    </DashboardLayout>
  );
}
