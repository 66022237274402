/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./exchange-rate-get-latest-action-body').ExchangeRateGetLatestActionBody} ExchangeRateGetLatestActionBody
 */

/**
 * @typedef {import('./exchange-rate-get-latest-action-response').ExchangeRateGetLatestActionResponse} ExchangeRateGetLatestActionResponse
 */

/**
 * The path of the ExchangeRateGetLatestAction action.
 */
export const exchangeRateGetLatestActionPath = new SweetPath('/api/cron/latest-exchange-rate', { path: '{param}' });

/**
 * Creates a contextualized ExchangeRateGetLatestAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createExchangeRateGetLatestAction(axios) {
  /**
   * The exchangeRateGetLatestAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<ExchangeRateGetLatestActionResponse>}
   */
  function exchangeRateGetLatestAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = exchangeRateGetLatestActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return exchangeRateGetLatestAction;
}
