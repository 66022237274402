import { useForm } from '@mantine/form';
import InputWrapper from 'components/inputs/InputWrapper';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { passwordFormat } from 'components/forms/validators/rules/rule-password-format';
import { required } from 'components/forms/validators/rules/rule-required';
import PasswordStrengthInput from 'components/inputs/password/PasswordStrengthInput';
import { Button, Group, PasswordInput, Stack } from '@mantine/core';
import { useMemo } from 'react';
import Alert from 'components/Alert';
import useLoadingAction from 'hooks/use-loading-action';

/**
 * @typedef {{
 *   password: string;
 *   passwordAgain: string;
 * }} SetPasswordFormData
 */

/**
 * Set Password Form.
 *
 * @param {{
 *   initialValues: SetPasswordFormData,
 *   onSubmit: (data: SetPasswordFormData) => void | Promise<void>,
 *   errorMessage?: string,
 * }}
 */
export default function SetPasswordForm({ initialValues, onSubmit, errorMessage }) {
  const [loading, submit] = useLoadingAction(onSubmit);

  const form = useForm({
    initialValues,

    validate: {
      password: createValidator([required, passwordFormat]),
      passwordAgain: (passwordAgain, { password }) =>
        passwordAgain === password ? null : _t('Passwords do not match'),
    },
  });

  const passwordRequirements = useMemo(
    () => (
      <Group position="center" style={{ textAlign: 'center', fontSize: '12px', lineHeight: '16px', color: '#38298B' }}>
        {_t('Password requirements:')}
        <br />
        {_t('At least 8 characters, 1 number, 1 uppercase, 1 special character')}
      </Group>
    ),
    []
  );

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={16}>
        {errorMessage && passwordRequirements}
        <InputWrapper
          align="column"
          inputSize="small"
          input={
            <PasswordStrengthInput placeholder={_t('Your password')} autoFocus {...form.getInputProps('password')} />
          }
        />
        <InputWrapper
          align="column"
          inputSize="small"
          input={<PasswordInput placeholder={_t('Password again')} {...form.getInputProps('passwordAgain')} />}
        />
        {errorMessage ? (
          <Alert className="mb-2 mt-2" severity="error" primaryText={errorMessage} displayIcon={false} />
        ) : (
          passwordRequirements
        )}
        <Group position="center">
          <Button type="submit" loading={loading} variant="primary">
            {_t('Set password')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
