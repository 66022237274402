import { Button, Group, Modal, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import InputWrapper from 'components/inputs/InputWrapper';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useEffect } from 'react';

/**
 * Modal allowing the user to edit the description of the active timer.
 *
 * @param {{
 *  opened: boolean;
 *  onClose: () => void;
 *  description: string;
 *  taskName: string;
 *  onEdit: (description: string) => void;
 * }}
 */
export default function EditDescriptionModal({ opened, onClose, description, taskName, onEdit = noop }) {
  const form = useForm({
    initialValues: {
      description,
    },
  });

  /**
   * Handles the form submission.
   */
  function handleEdit({ description }) {
    onEdit(description);
    form.reset();
    onClose();
  }

  /**
   * Clears the form and closes the modal.
   */
  function handleClose() {
    form.reset();
    onClose();
  }

  useEffect(() => {
    form.setFieldValue('description', description);
  }, [description]);

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      centered
      title={`${_t('Edit description for timer for:')} ${taskName}`}
    >
      <form onSubmit={form.onSubmit(handleEdit)}>
        <InputWrapper
          align="column"
          label={_t('New description')}
          input={<Textarea placeholder={_t('New description')} {...form.getInputProps('description')} />}
        />

        <Group spacing={20} position="right" pt={16}>
          <Button variant="link" onClick={handleClose}>
            {_t('Cancel')}
          </Button>
          <Button type="submit" variant="primary">
            {_t('Update description')}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
