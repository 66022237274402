import { Collapse } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import CollapseArrow from 'components/CollapseArrow';
import TaskClosedIcon from 'components/icons/TaskClosedIcon';
import TaskIcon from 'components/icons/TaskIcon';
import panic from 'errors/Panic';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TASK_DETAIL_PAGE_PATH } from 'routes/paths';
import SubtaskMenuItem from 'pages/tasks/task-detail/sidebar/SubtaskMenuItem';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';
import Truncate from 'components/Truncate';

/**
 * Single task in the ProjectSidebar menu.
 *
 * @param {{
 *   projectId: number;
 *   taskId: number;
 * }} props
 */
export default function TaskMenuItem({ projectId, taskId }) {
  const { getAction } = useApi();
  const { taskId: pathTaskId } = useParams();
  const { task: openedTask, sidebarRefreshToken } = useTaskDetail();
  const [task, setTask] = useState();
  const localStorageKey = useMemo(() => `project.task.detail.sidebar.opened.${taskId}`, [pathTaskId]);
  const [opened, { open, toggle }] = useLocalStorageDisclosure(localStorageKey, false);

  useEffect(() => {
    const taskGetAction = getAction('TaskGetAction');

    taskGetAction({ parameters: { task_id: taskId } })
      .then(setTask)
      .catch(panic);
  }, [taskId, sidebarRefreshToken]);

  const hasSubtasks = useMemo(() => task?.subtasks && task?.subtasks.length > 0, [task]);
  const isClosed = useMemo(() => !!task?.closed_at, [task]);
  const isDeleted = useMemo(() => !!task?.deleted_at, [task]);
  const isDone = useMemo(() => isClosed || isDeleted, [isClosed, isDeleted]);
  const openSubtasks = useMemo(() => task?.open_subtask_count ?? 0, [task]);

  useEffect(() => {
    if (openedTask?.parent_task_id == taskId) {
      open();
    }
  }, [taskId, openedTask]);

  if (!task) {
    return null;
  }

  const bgColor = Number(pathTaskId) === taskId ? 'bg-neutral-200' : '';

  return (
    <>
      <div className={`flex flex-row items-center rounded-[8px] px-3 py-2 ${bgColor} hover:bg-neutral-200`}>
        {hasSubtasks ? (
          <div onClick={toggle} className="cursor-pointer">
            <CollapseArrow opened={opened} />
          </div>
        ) : (
          <div className="h-6 w-6"></div>
        )}
        <div className="flex w-full flex-row items-center justify-between">
          <div className="w-[270px] whitespace-nowrap">
            <Link
              to={TASK_DETAIL_PAGE_PATH.insert({ taskId })}
              className="flex w-full flex-row items-center gap-1 text-neutral-700 hover:underline"
            >
              <div className="h-6 w-6">{isDone ? <TaskClosedIcon /> : <TaskIcon />}</div>
              <Truncate
                className={`text-[15px] leading-[18px] ${isDone ? 'line-through' : ''}`}
                text={task.task_full_name}
              />
            </Link>
          </div>
          {hasSubtasks && (
            <div className="pl-2">
              <span className="rounded-full bg-neutral-50 px-1 text-[12px] leading-[16px] text-neutral-500">
                {openSubtasks}
              </span>
            </div>
          )}
        </div>
      </div>
      {hasSubtasks && (
        <Collapse in={opened}>
          <div className="flex flex-col gap-2">
            {task.subtasks
              .filter(({ deleted_at }) => !deleted_at)
              .map((subtask) => (
                <SubtaskMenuItem projectId={projectId} taskId={taskId} key={subtask.task_id} subtask={subtask} />
              ))}
          </div>
        </Collapse>
      )}
    </>
  );
}
