import { useDisclosure } from '@mantine/hooks';
import AddTaskSubtaskModal from 'components/modals/AddTaskSubtaskModal';
import { noop } from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

/**
 * The props of the pickTaskSubtask callback.
 *
 * @typedef {{
 *  onPick: (values: {projectId: number; taskId: number}) => void;
 *  excludeTaskIds: number[];
 * }} PickTaskSubtaskProps
 */

/**
 * The pickTaskSubtask callback.
 *
 * @typedef {(props: PickTaskSubtaskProps) => void} PickTaskSubtaskCallback
 */

/**
 * The pickTaskSubtask context.
 *
 * @type {React.Context<{
 *  pickTaskSubtask: PickTaskSubtaskCallback;
 * }>}
 */
const PickTaskSubtaskContext = createContext();

/**
 * Provides the pickTaskSubtask context.
 *
 * @param {{
 *  children: React.ReactNode,
 * }}
 */
export function PickTaskSubtaskProvider({ children }) {
  const [opened, { close, open }] = useDisclosure(false);

  const [props, setProps] = useState({
    onPick: noop,
    excludeTaskIds: [],
  });

  /**
   * Opens the pickTaskSubtask modal.
   *
   * @type {PickTaskSubtaskCallback}
   */
  const pickTaskSubtask = useCallback(
    (props) => {
      setProps(props);
      open();
    },
    [open]
  );

  const value = useMemo(() => ({ pickTaskSubtask }), [pickTaskSubtask]);

  return (
    <PickTaskSubtaskContext.Provider value={value}>
      {children}
      <AddTaskSubtaskModal opened={opened} onClose={close} {...props} />
    </PickTaskSubtaskContext.Provider>
  );
}

/**
 * Uses the pickTaskSubtask context.
 */
export function usePickTaskSubtask() {
  return useContext(PickTaskSubtaskContext);
}
