/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Input']} InputTheme */

/** @type {InputTheme} */
export const Input = {
  styles: {
    root: {
      borderRadius: '8px',
    },

    wrapper: {
      marginBottom: '0',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
    },

    input: {
      padding: '8px',
      color: '#4D4D4D',
      fontSize: '15px',
      lineHeight: '20px',
      height: '100%',
      '::placeholder': {
        color: '#B3B3B3',
        fontSize: '15px',
        lineHeight: '20px',
      },
      border: '1px solid #B3B3B3',
      borderRadius: '8px',
      '&:disabled': {
        color: '#B8B8B8',
        backgroundColor: '#E6E6E6',
        opacity: '1',
      },
    },
  },
};
