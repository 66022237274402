import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconClearFormatting } from '@tabler/icons';

/**
 * Used to clear formatting of the selected text.
 */
export default function ClearFormatting() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Clear formatting" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control onClick={() => editor?.chain().focus().unsetAllMarks().run()}>
        {/* TODO use icon from Figma when it's done */}
        <IconClearFormatting size={18} stroke={2} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
