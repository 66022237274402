/**
 * Icon used by the BulletList control component.
 */
export default function IconBulletList() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3.3335 8.74967C2.66683 8.74967 2.0835 9.33301 2.0835 9.99967C2.0835 10.6663 2.66683 11.2497 3.3335 11.2497C4.00016 11.2497 4.5835 10.6663 4.5835 9.99967C4.5835 9.33301 4.00016 8.74967 3.3335 8.74967ZM3.3335 4.58301C2.66683 4.58301 2.0835 5.16634 2.0835 5.83301C2.0835 6.49967 2.66683 7.08301 3.3335 7.08301C4.00016 7.08301 4.5835 6.49967 4.5835 5.83301C4.5835 5.16634 4.00016 4.58301 3.3335 4.58301ZM3.3335 12.9163C2.66683 12.9163 2.0835 13.4997 2.0835 14.1663C2.0835 14.833 2.66683 15.4163 3.3335 15.4163C4.00016 15.4163 4.5835 14.833 4.5835 14.1663C4.5835 13.4997 4.00016 12.9163 3.3335 12.9163ZM6.25016 4.99967V6.66634H17.9168V4.99967H6.25016ZM6.25016 14.9997H17.9168V13.333H6.25016V14.9997ZM6.25016 10.833H17.9168V9.16634H6.25016V10.833Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
