import { Box, Group, Text } from '@mantine/core';
import { _t } from 'lang';

/**
 * Contains information about the state of the Cost Estimate with buttons to
 * change it when the state is `closed`.
 */
export default function StateClosed() {
  return (
    <Box pt={24}>
      <Group h={34} spacing={16} pl={32}>
        <Text fz={15} lh={18 / 15} color="neutral-700">
          {_t('Status:')}
        </Text>
        <Group spacing={4}>
          <Box w={12} h={12} bg="#74E479" className="rounded-full" />
          <Text fz={15} lh={18 / 15} color="neutral-700">
            {_t('Closed')}
          </Text>
        </Group>
      </Group>
    </Box>
  );
}
