import { useInterval } from '@mantine/hooks';
import { VERSION, VERSION_CHECK_INTERVAL } from 'environment';
import NewVersionNotification from './NewVersionNotification';
import { noop } from 'lodash';

const { createContext, useState, useMemo, useCallback, useEffect, useContext } = require('react');

/**
 * Version context.
 *
 * @type {React.Context<{
 *   currentVersion: string;
 *   latestVersion: string;
 *   updateAvailable: boolean;
 * }>}
 */
const VersionContext = createContext();

/**
 * Provides the version context.
 *
 * @param {{
 *   children: React.ReactNode;
 * }}
 */
export function VersionProvider({ children }) {
  const [currentVersion] = useState(VERSION);
  const [latestVersion, setLatestVersion] = useState(VERSION);

  const updateAvailable = useMemo(() => currentVersion !== latestVersion, [currentVersion, latestVersion]);

  const fetchLatestVersion = useCallback(() => {
    fetch(`/version?t=${Date.now()}`)
      .then((response) => response.text())
      .then((version) => setLatestVersion(version.trim()))
      .catch(noop);
  }, []);

  const refreshInterval = useInterval(fetchLatestVersion, VERSION_CHECK_INTERVAL);

  useEffect(() => {
    fetchLatestVersion();
    refreshInterval.start();
    return refreshInterval.stop;
  }, []);

  useEffect(() => {
    window.document.documentElement.dataset.version = currentVersion;
  }, [currentVersion]);

  const value = useMemo(
    () => ({ currentVersion, latestVersion, updateAvailable }),
    [currentVersion, latestVersion, updateAvailable]
  );

  return (
    <VersionContext.Provider value={value}>
      {children}
      {updateAvailable && <NewVersionNotification />}
    </VersionContext.Provider>
  );
}

/**
 * Uses the version context.
 */
export function useVersion() {
  return useContext(VersionContext);
}
