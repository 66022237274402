import { Group, Switch } from '@mantine/core';
import { _t } from 'lang';

/**
 * Show objects of clients assigned to me switch.
 *
 * @param {{value: boolean; onChange: (val: boolean) => void}} props
 */
export default function ShowMyClientsSwitch({ value, onChange }) {
  return (
    <Group position="right">
      <Switch
        checked={value}
        onChange={(e) => {
          onChange(e.currentTarget.checked);
        }}
        label={_t('My clients')}
        labelPosition="left"
      />
    </Group>
  );
}
