/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-reopen-action-body').TaskReopenActionBody} TaskReopenActionBody
 */

/**
 * @typedef {import('./task-reopen-action-response').TaskReopenActionResponse} TaskReopenActionResponse
 */

/**
 * The path of the TaskReopenAction action.
 */
export const taskReopenActionPath = new SweetPath('/api/project/project/task/{task_id}/reopen', { path: '{param}' });

/**
 * Creates a contextualized TaskReopenAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskReopenAction(axios) {
  /**
   * The taskReopenAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskReopenActionBody } }
   *
   * @returns {Promise<TaskReopenActionResponse>}
   */
  function taskReopenAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskReopenActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return taskReopenAction;
}
