/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./price-list-set-active-action-body').PriceListSetActiveActionBody} PriceListSetActiveActionBody
 */

/**
 * @typedef {import('./price-list-set-active-action-response').PriceListSetActiveActionResponse} PriceListSetActiveActionResponse
 */

/**
 * The path of the PriceListSetActiveAction action.
 */
export const priceListSetActiveActionPath = new SweetPath('/api/finance/price_list/{price_list_id}/set_active', {
  path: '{param}',
});

/**
 * Creates a contextualized PriceListSetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPriceListSetActiveAction(axios) {
  /**
   * The priceListSetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PriceListSetActiveActionBody } }
   *
   * @returns {Promise<PriceListSetActiveActionResponse>}
   */
  function priceListSetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = priceListSetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return priceListSetActiveAction;
}
