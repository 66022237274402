import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for clients.
 *
 * @param {import('react').RefAttributes<HTMLInputElement> & import('@mantine/core').SelectProps & { clientFilter?: number[]}}
 */
export default function ClientSelect({ ...props }) {
  const { getAction } = useApi();
  const clientGetListAction = getAction('ClientGetListAction');

  return <DataSelect {...props} action={clientGetListAction} labelProperty="client_name" valueProperty="client_id" />;
}
