/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { Breadcrumbs } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import NotFoundPage from 'pages/system/not-found/NotFoundPage';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH, FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from 'routes/paths';

/** @type {CostEstimate | null} */
const initialCostEstimate = null;

/**
 * Extends the dashboard layout to provide a form layout for the finance pages.
 *
 * @param {{
 *   children: (costEstimate: CostEstimate) => React.ReactNode;
 *   heading: string;
 * }}
 */
export default function FinanceFormLayout({ children, heading }) {
  const { costEstimateId } = useParams();
  const { getAction } = useApi();
  const [costEstimate, setCostEstimate] = useState(initialCostEstimate);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const costEstimateGetListAction = getAction('CostEstimateGetListAction');

    costEstimateGetListAction({ query: { filter: { 'cost_estimate_id.eq': costEstimateId } } })
      .then(([costEstimate]) => (costEstimate ? setCostEstimate(costEstimate) : setCostEstimate(null)))
      .catch(panic)
      .finally(() => setLoading(false));
  }, [costEstimateId, getAction]);

  const baseUrl = FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original;

  if (!loading && !costEstimate) {
    return <NotFoundPage />;
  }

  return (
    <DashboardLayout>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        {costEstimate && (
          <>
            <PageHeading
              heading={heading}
              breadcrumbs={
                <Breadcrumbs>
                  <Link to={baseUrl}>{_t('Finance')}</Link>
                  <Link to={`${baseUrl}?clientId=${costEstimate.client.client_id}`}>
                    {costEstimate.client.client_name}
                  </Link>
                  <Link to={`${baseUrl}?projectId=${costEstimate.project.project_id}`}>
                    {costEstimate.project.project_full_name}
                  </Link>
                  <Link to={FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId })}>
                    {costEstimate.cost_estimate_name}
                  </Link>
                </Breadcrumbs>
              }
            />
            {children(costEstimate)}
          </>
        )}
      </div>
    </DashboardLayout>
  );
}
