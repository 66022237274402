/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-approve-action-body').CostEstimateApproveActionBody} CostEstimateApproveActionBody
 */

/**
 * @typedef {import('./cost-estimate-approve-action-response').CostEstimateApproveActionResponse} CostEstimateApproveActionResponse
 */

/**
 * The path of the CostEstimateApproveAction action.
 */
export const costEstimateApproveActionPath = new SweetPath('/api/finance/cost_estimate/{cost_estimate_id}/approve', {
  path: '{param}',
});

/**
 * Creates a contextualized CostEstimateApproveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateApproveAction(axios) {
  /**
   * The costEstimateApproveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CostEstimateApproveActionBody } }
   *
   * @returns {Promise<CostEstimateApproveActionResponse>}
   */
  function costEstimateApproveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = costEstimateApproveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return costEstimateApproveAction;
}
