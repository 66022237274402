import { Group, Text, useMantineTheme } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import UsersListTooltip from 'components/UsersListTooltip';
import NotifiedIcon from 'components/icons/NotifiedIcon';
import panic from 'errors/Panic';

/**
 * Displays an icon with the number of people notified by the comment.
 *
 * @param {{
 *   commentId: string;
 *   count: number;
 * }}
 */
export default function CommentNotifiedPeopleCount({ commentId, count }) {
  const theme = useMantineTheme();
  const { getAction } = useApi();

  /**
   * Fetches the list of users notified by the comment.
   */
  async function fetchUsers() {
    const commentGetNotifiedPeopleAction = getAction('CommentGetNotifiedPeopleAction');

    try {
      const users = await commentGetNotifiedPeopleAction({
        parameters: { comment_id: commentId },
      });

      return users.map((user) => ({ userId: user.user_id, fullName: user.full_name }));
    } catch (error) {
      panic(error);
      return null;
    }
  }

  return (
    <UsersListTooltip action={fetchUsers}>
      <Group spacing={4} align="center" h={20}>
        <NotifiedIcon stroke={theme.colors.neutral500[0]} width={16} height={16} />
        <Text color="neutral300" fz={12} lh={16 / 12}>
          {count}
        </Text>
      </Group>
    </UsersListTooltip>
  );
}
