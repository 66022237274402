/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-update-ord-action-body').ProjectUpdateOrdActionBody} ProjectUpdateOrdActionBody
 */

/**
 * @typedef {import('./project-update-ord-action-response').ProjectUpdateOrdActionResponse} ProjectUpdateOrdActionResponse
 */

/**
 * The path of the ProjectUpdateOrdAction action.
 */
export const projectUpdateOrdActionPath = new SweetPath('/api/project/project/{project_id}/update_ord', {
  path: '{param}',
});

/**
 * Creates a contextualized ProjectUpdateOrdAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectUpdateOrdAction(axios) {
  /**
   * The projectUpdateOrdAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ProjectUpdateOrdActionBody } }
   *
   * @returns {Promise<ProjectUpdateOrdActionResponse>}
   */
  function projectUpdateOrdAction({ headers = {}, parameters = {}, body } = {}) {
    const path = projectUpdateOrdActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return projectUpdateOrdAction;
}
