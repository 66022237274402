/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-get-minimal-info-action-body').UserGetMinimalInfoActionBody} UserGetMinimalInfoActionBody
 */

/**
 * @typedef {import('./user-get-minimal-info-action-response').UserGetMinimalInfoActionResponse} UserGetMinimalInfoActionResponse
 */

/**
 * The path of the UserGetMinimalInfoAction action.
 */
export const userGetMinimalInfoActionPath = new SweetPath('/api/user/{user_id}/minimal_info', { path: '{param}' });

/**
 * Creates a contextualized UserGetMinimalInfoAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserGetMinimalInfoAction(axios) {
  /**
   * The userGetMinimalInfoAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<UserGetMinimalInfoActionResponse>}
   */
  function userGetMinimalInfoAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = userGetMinimalInfoActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return userGetMinimalInfoAction;
}
