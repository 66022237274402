import AddExternalCostsPageContent from 'pages/finance/external-costs/add-external-costs/AddExternalCostsPageContent';
import FinanceFormLayout from 'layouts/finance-form-layout/FinanceFormLayout';
import { _t } from 'lang';

/**
 * Displays add external cost page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=3128%3A41395&t=ErSfhDBFtNlFfoqq-0
 */
export default function AddExternalCostsPage() {
  return (
    <FinanceFormLayout heading={_t('Add external costs')}>
      {(costEstimate) => <AddExternalCostsPageContent costEstimate={costEstimate} />}
    </FinanceFormLayout>
  );
}
