import removeAccents from 'remove-accents';

/**
 * Performs a full-text search on the given data. Returns true if the needle is
 * found in the haystack.
 *
 * @param {string} needle
 * @param {string} haystack
 */
export default function fullTextSearch(needle, haystack) {
  const normalizedNeedle = removeAccents(needle.toLowerCase().trim());
  const normalizedHaystack = removeAccents(haystack.toLowerCase());

  return normalizedHaystack.includes(normalizedNeedle);
}
