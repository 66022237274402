import SimpleLayout from 'layouts/simple-layout/SimpleLayout';
import ResetLinkExpiredContent from 'pages/login/reset-link-expired/ResetLinkExpiredContent';

/**
 * Displays the reset password form.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=2340%3A36349
 */
export default function ResetLinkExpiredPage() {
  return <SimpleLayout content={<ResetLinkExpiredContent />} />;
}
