/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-close-action-body').TaskCloseActionBody} TaskCloseActionBody
 */

/**
 * @typedef {import('./task-close-action-response').TaskCloseActionResponse} TaskCloseActionResponse
 */

/**
 * The path of the TaskCloseAction action.
 */
export const taskCloseActionPath = new SweetPath('/api/project/project/task/{task_id}/close', { path: '{param}' });

/**
 * Creates a contextualized TaskCloseAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskCloseAction(axios) {
  /**
   * The taskCloseAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskCloseActionBody } }
   *
   * @returns {Promise<TaskCloseActionResponse>}
   */
  function taskCloseAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskCloseActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return taskCloseAction;
}
