/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "ApproveV2" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function ApproveV2Icon({
  className = '',
  height = 24,
  width = 24,
  stroke = 'white',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Message type/approve_v2">
        <path id="Vector" d="M5 13L9 17L19 7" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
}
