import { useCounter, useDisclosure } from '@mantine/hooks';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import TimeLogModal from 'pages/time-entries/time-log/TimeLogModal';

/**
 * The props of the log time callback.
 *
 * @typedef {{
 *   projectId: number;
 *   taskId?: number;
 *   start?: Date;
 *   end?: Date;
 *   description?: string;
 *   timeLogId?: number;
 *   hideAddNext?: boolean;
 *   onTimeLogged: () => void;
 *   onTimeCancelled: () => void;
 * }} TimeLogProps
 */

/**
 * @typedef {(props: TimeLogProps) => void} IOpenTimeLogModal
 */

/**
 * The log time context.
 *
 * @type {React.Context<{
 *   openTimeLogModal: IOpenTimeLogModal;
 * }>}
 */
const TimeLogContext = createContext();

/**
 * Provides the log time context.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export function TimeLogProvider({ children }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [key, { increment: incrementKey }] = useCounter(0);
  const [props, setProps] = useState({});

  /**
   * Opens the log time modal.
   *
   * @type {IOpenTimeLogModal}
   */
  const openTimeLogModal = useCallback(
    (props) => {
      setProps(props);
      incrementKey();
      open();
    },
    [setProps, incrementKey, open]
  );

  const value = useMemo(() => ({ openTimeLogModal }), [openTimeLogModal]);

  return (
    <TimeLogContext.Provider value={value}>
      {children}
      <TimeLogModal key={key} opened={opened} onClose={close} {...props} />
    </TimeLogContext.Provider>
  );
}

/**
 * Uses the log time context.
 */
export function useTimeLog() {
  return useContext(TimeLogContext);
}
