/** @typedef {{date: Date, loggedHours: number}} TimeLog */

import { Box, Flex, Group, Stack, Text } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import { addMonths, endOfMonth, isToday, isWeekend, startOfMonth } from 'date-fns';
import panic from 'errors/Panic';
import { max } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import TimeEntriesSummary from '../TimeEntriesSummary';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import { IconCalendar, IconRefresh } from '@tabler/icons';
import { _t } from '../../lang';
import { formattedTime } from '../tables/time-entries/TimeEntriesTable';
import ArrowRightIcon from '../icons/ArrowRightIcon';

/**
 * Creates array with this month's days and 0 logged hours.
 *
 * @param {{
 *   start: Date,
 *   end: Date,
 * }}
 *
 * @returns {TimeLog[]}
 */
function createDataForRange({ start, end }) {
  return Array.from({ length: end.getDate() }, (_, i) => ({
    date: new Date(start.getFullYear(), start.getMonth(), i + 1),
    loggedHours: 0,
  }));
}

/**
 * ChartTimelog component displays a chart representing the logged hours for each day of the current month.
 *
 * TODO: Move month shifting logic to a separate component.
 *
 * @param {{
 *   start: Date,
 *   end: Date,
 *   setStart: (start: Date) => void,
 *   setEnd: (end: Date) => void,
 *   displaySummary?: boolean,
 *   ref: React.RefObject<{ refresh: () => void }>,
 * }}
 *
 * @returns {JSX.Element} - The rendered ChartTimelog component.
 */
const ChartTimelog = forwardRef(({ displaySummary, start, setStart, end, setEnd }, ref) => {
  const { userId, getAction } = useApi();

  const shiftRange = useCallback((shift) => {
    setStart((start) => startOfMonth(addMonths(start, shift)));
    setEnd((end) => endOfMonth(addMonths(end, shift)));
  }, []);

  const nextRange = useCallback(() => shiftRange(1), [shiftRange]);
  const prevRange = useCallback(() => shiftRange(-1), [shiftRange]);

  const monthFormatted = useMemo(() => start.toLocaleDateString('en-EN', { month: 'long' }), [start]);
  const yearFormatted = useMemo(() => start.getFullYear(), [start]);

  const [data, setData] = useState(() => createDataForRange({ start, end }));

  const [forceUpdate, setForceUpdate] = useState();
  const doForceUpdate = useCallback(() => setForceUpdate({}), []);

  const totalLoggedMinutes = data.reduce((acc, { loggedHours }) => acc + loggedHours, 0).toFixed(2) * 60;

  useImperativeHandle(ref, () => ({
    refresh: doForceUpdate,
  }));

  const maxLoggedHours = useMemo(() => {
    const maxLoggedHours = max(data.map(({ loggedHours }) => loggedHours));
    return Math.max(Math.ceil(maxLoggedHours), 8);
  }, [data]);

  const isActualMonth = useMemo(() => {
    const actualStartOfMonth = startOfMonth(new Date());
    return start.getTime() === actualStartOfMonth.getTime();
  }, [start]);

  const goToActualMonth = useCallback(() => {
    setStart(startOfMonth(new Date()));
    setEnd(endOfMonth(new Date()));
  }, []);

  useEffect(() => {
    const timeLogGetMyListAction = getAction('TimeLogGetMyListAction');

    timeLogGetMyListAction({
      query: {
        filter: {
          'user_id.eq': userId,
          'start.gte': start.toISOString(),
          'end.lte': end.toISOString(),
        },
      },
    })
      .then((response) => {
        const newData = createDataForRange({ start, end });

        response.forEach(({ start, duration }) => {
          const date = new Date(start).getDate();
          const index = date - 1;

          if (index < 0 || index >= newData.length) {
            throw new Error(`Invalid date index: ${index}`);
          }

          newData[index].loggedHours += duration / 60;
        });

        setData(newData);
      })
      .catch(panic);
  }, [start, end, userId, forceUpdate]);

  return (
    <Stack spacing={16}>
      <Flex justify="space-between">
        <Group spacing={16}>
          <Box w={24} onClick={prevRange} className="cursor-pointer">
            <ArrowLeftIcon />
          </Box>
          <Text fz={22} weight={600}>
            {monthFormatted} {yearFormatted}
          </Text>
          {isActualMonth ? (
            <Box w={24} onClick={doForceUpdate} className="cursor-pointer">
              <IconRefresh size={24} />
            </Box>
          ) : (
            <>
              <Box w={24} onClick={nextRange} className="cursor-pointer">
                <ArrowRightIcon />
              </Box>
              <Box w={24} onClick={goToActualMonth} className="cursor-pointer">
                <IconCalendar size={24} />
              </Box>
            </>
          )}
        </Group>

        <Flex align="center" gap={16}>
          <Text className="text-neutral-500">{_t('Logged time')}</Text>
          <Text fz={22} className="text-neutral-700" weight={600}>
            {formattedTime(totalLoggedMinutes)}
          </Text>
        </Flex>
      </Flex>

      {displaySummary && <TimeEntriesSummary data={data} />}

      <Box className="rounded-2xl bg-white px-4 py-8">
        <Flex h={245} w="100%">
          <Flex h="100%" direction="column">
            <Box pos="relative" h="100%" mt={8}>
              {maxLoggedHours > 16 && (
                <Text pos="absolute" bottom="100%">
                  24h
                </Text>
              )}
              {maxLoggedHours > 8 && (
                <Text pos="absolute" bottom={`${maxLoggedHours <= 16 ? 100 : 68}%`}>
                  16h
                </Text>
              )}
              <Text
                pos="absolute"
                bottom={`${maxLoggedHours <= 8 ? (8 / maxLoggedHours) * 100 - 4 : maxLoggedHours <= 16 ? 50 : 32}%`}
              >
                8h
              </Text>
              <Text pos="absolute" bottom={0}>
                0h
              </Text>
            </Box>
            <Box h={30}></Box>
          </Flex>

          <Flex ml={58} h="100%" justify="space-between" w="100%">
            {data.map(({ date, loggedHours }) => {
              // due to changing y-axis labels, we need to calculate the percentage of logged hours
              const upperLimit = Math.max(maxLoggedHours, 8 * Math.ceil(maxLoggedHours / 8));
              const loggedHoursPercentage = (loggedHours / upperLimit) * 100;

              const today = isToday(date);
              const weekend = isWeekend(date);
              const holiday = false; // TODO: Add holiday check here

              const wrapperBg = today ? 'rounded-full bg-main-secondary' : weekend ? 'rounded-full bg-neutral-50' : '';

              const barBg = weekend ? 'bg-neutral-200' : holiday ? 'bg-red bg-opacity-50' : 'bg-neutral-100';

              return (
                <Flex
                  key={date.toISOString()}
                  h="100%"
                  direction="column"
                  align="center"
                  gap={8}
                  pb={16}
                  className={wrapperBg}
                >
                  <Box h="100%" className={`${barBg} flex-1 shrink-0 grow rounded-full`} w={8} mt={16} pos="relative">
                    <Box
                      h={`${loggedHoursPercentage}%`}
                      w="100%"
                      className="rounded-full bg-complementary-status-positive"
                      pos="absolute"
                      bottom={0}
                      left={0}
                    />
                  </Box>
                  <Flex w={32} h={18} justify="center" align="center">
                    <Text className={`${holiday || weekend ? 'text-neutral-300' : ''} shrink-0`}>{date.getDate()}</Text>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Stack>
  );
});

ChartTimelog.displayName = 'ChartTimelog';

export default ChartTimelog;
