import Alert from 'components/Alert';
import { _t } from 'lang';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import formatPrice from 'utils/format-price';

/**
 * Displays an alert if the cost estimate is not profitable, ie. the planned
 * external costs exceed the revenue.
 */
export default function NotProfitableAlert() {
  const { costEstimate, currency } = useCostEstimateDetail();

  const revenue = costEstimate.amount_after_discount;
  const expenses = costEstimate.external_cost_sum_estimated;
  const profit = revenue - expenses;
  const isProfitable = profit > 0;

  if (isProfitable) {
    return <></>;
  }

  return (
    <Alert
      severity="error"
      primaryText={_t('This cost estimate is not profitable. Planned external cost of %s exceeds budgeted costs of %s by %s.', formatPrice(expenses, currency), formatPrice(revenue, currency), formatPrice(-profit, currency))} // prettier-ignore
      secondaryText={
        costEstimate.approver
          ? _t('Approved by %s', costEstimate.approver.full_name)
          : _t('This cost estimate has not been approved yet.')
      }
    />
  );
}
