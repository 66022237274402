/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-get-list-action-body').UserGetListActionBody} UserGetListActionBody
 */

/**
 * @typedef {import('./user-get-list-action-response').UserGetListActionResponse} UserGetListActionResponse
 */

/**
 * The path of the UserGetListAction action.
 */
export const userGetListActionPath = new SweetPath('/api/user/', { path: '{param}' });

/**
 * Creates a contextualized UserGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserGetListAction(axios) {
  /**
   * The userGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<UserGetListActionResponse>}
   */
  function userGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = userGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return userGetListAction;
}
