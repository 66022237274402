import { Box } from '@mantine/core';
import Truncate from 'components/Truncate';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

/**
 * Child item of the SidebarItem the settings sidebar.
 *
 * @param {{
 *   title: string,
 *   path: string,
 * }}
 */
export default function SidebarSubItem({ title, path }) {
  const location = useLocation();

  const styles = useMemo(
    () => ({
      cursor: 'pointer',
      borderRadius: '8px',
      backgroundColor: location.pathname === path ? '#D4D4D4' : '#FAFAFA',
      '&:hover': {
        backgroundColor: '#D4D4D4',
      },
    }),
    [location.pathname, path]
  );

  return (
    <Box component={Link} to={path} pl={60} h={32} pr={8} pt={4} pb={4} sx={styles}>
      <div className="h-full w-[250px]">
        <div className="flex h-full w-full flex-row items-center gap-1 text-neutral-700">
          <Truncate className="text-[15px] leading-[18px]" text={title} />
        </div>
      </div>
    </Box>
  );
}
