import { Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import InputWrapper from 'components/inputs/InputWrapper';
import { required } from 'components/forms/validators/rules/rule-required';
import { emailFormat } from 'components/forms/validators/rules/rule-email-format';
import { createValidator } from 'components/forms/validators/create-validator';
import { _t } from 'lang';
import useLoadingAction from 'hooks/use-loading-action';

/**
 * @typedef {{
 *  email: string,
 * }} ResetPasswordFormData
 */

/**
 * Reset Password Form.
 *
 * @param {{
 *   initialValues: ResetPasswordFormData,
 *   onSubmit: (data: ResetPasswordFormData) => void | Promise<void>,
 *}}
 */
export default function ResetPasswordForm({ initialValues, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);

  const form = useForm({
    initialValues,

    validate: {
      email: createValidator([required, emailFormat]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={16}>
        <InputWrapper
          align="column"
          inputSize="small"
          input={<TextInput type="email" placeholder={_t('Email')} autoFocus {...form.getInputProps('email')} />}
        />
        <Group position="center" className="text-[14px] text-grey-50">
          <Button type="submit" loading={loading} variant="primary">
            {_t('Reset password')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
