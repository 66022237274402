import { Box, Button, Flex, Group } from '@mantine/core';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import PlusIcon from 'components/icons/PlusIcon';
import { _t } from 'lang';

/**
 * Heading of the Dashboard page. Contains a heading and an action button (usually `Add new` button) and three dots for more options.
 *
 * @param {{
 *   heading: string,
 *   buttonAction: (() => void) | null,
 *   threeDotsAction: (() => void) | null,
 *   buttonText?: string,
 *   leftIcon?: React.ReactNode,
 *   switchComponent?: JSX.Element,
 * }}
 */
export default function DashboardPageHeading({
  heading,
  buttonAction,
  threeDotsAction,
  buttonText = _t('Add new'),
  leftIcon = <PlusIcon width={20} height={20} stroke="#F2F2F2" />,
  switchComponent,
}) {
  return (
    <Box bg="#FFF" pos="sticky" top={60} left={0} right={0} p={20} mx={-20} w="calc(100% + 40px)" className="z-10">
      <Flex justify="space-between" align="center">
        <Group spacing={switchComponent ? 16 : 0}>
          <div className="text-[24px] leading-[32px]">{heading}</div>
          {switchComponent}
        </Group>
        <div className="flex items-center justify-between">
          {buttonAction && (
            <Button leftIcon={leftIcon} onClick={buttonAction} variant="primary">
              {buttonText}
            </Button>
          )}
          {threeDotsAction && (
            <div onClick={threeDotsAction} className="cursor-not-allowed">
              <OptionsDotsIcon />
            </div>
          )}
        </div>
      </Flex>
    </Box>
  );
}
