import { ActionIcon, Box, Stack } from '@mantine/core';
import FileTypeIcon from 'components/files/FileTypeIcon';
import CloseIcon from 'components/icons/CloseIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import ProgressBar from 'components/progress/ProgressBar';
import Truncate from 'components/Truncate';
import { useMemo } from 'react';

/**
 * Indicator for a file upload.
 *
 * @param {{
 *   fileName: string,
 *   fileType: string,
 *   progress: number,
 *   finalized: boolean,
 *   onDelete: () => void,
 * }}
 */
export default function UploadProgressIndicator({ fileName, fileType, progress, finalized, onDelete }) {
  const extension = useMemo(() => fileName.slice(fileName.lastIndexOf('.')), [fileName]);
  const name = useMemo(() => fileName.slice(0, fileName.lastIndexOf('.')), [fileName]);

  const trimmedName = useMemo(() => name.slice(0, 45), [name]);

  return (
    <Stack spacing={4}>
      <div className="flex h-6 items-center justify-start gap-2">
        <Box w={24} h={24}>
          <FileTypeIcon width={24} height={24} type={fileType} />
        </Box>
        <Truncate
          text={`${trimmedName}${trimmedName.length < name.length ? '..' : ''}${extension}`}
          className="flex-1 text-[15px] leading-[18px] text-neutral-700"
        />
        <div className="cursor-pointer" onClick={onDelete}>
          <ActionIcon variant="transparent">
            {finalized ? (
              <DeleteIcon stroke="#BF0D38" height={24} width={24} />
            ) : (
              <CloseIcon stroke="#BF0D38" height={24} width={24} />
            )}
          </ActionIcon>
        </div>
      </div>
      {!finalized && <ProgressBar variant="constant" value={progress * 100} />}
    </Stack>
  );
}
