/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { _t } from 'lang';
import InvoiceRequestForm from 'components/forms/invoice-request-form/InvoiceRequestForm';
import { useApi } from 'api/ApiContext';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import panic from 'errors/Panic';
import { Box } from '@mantine/core';
import Alert from 'components/Alert';

/**
 * The content of the add invoice request page.
 *
 * @param {{
 *   costEstimate: CostEstimate;
 * }}
 */
export default function AddInvoiceRequestPageContent({ costEstimate }) {
  const navigate = useNavigate();
  const { costEstimateId } = useParams();

  const { getAction, userId } = useApi();
  const purchaseOrderInvoiceRequestCreateAction = getAction('PurchaseOrderInvoiceRequestCreateAction');

  const detailPath = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId });
  const detailTab = `${detailPath}?tab=purchase-order`;

  const initialValues = {
    poNumber: '',
    fileId: undefined,
    textInvoice: '',
    supplyDate: null,
    note: '',
    invoicedAmount: costEstimate.amount_after_discount,
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ poNumber, fileId, textInvoice, supplyDate, note }) => {
    return purchaseOrderInvoiceRequestCreateAction({
      body: {
        po_number: poNumber,
        file_id: fileId[0].fileId,
        invoice_subject: textInvoice,
        supplied: supplyDate,
        note,
        cost_estimate_id: costEstimateId,
        author_id: String(userId),
      },
    })
      .then(() => {
        showNotification({
          title: _t('Purchase order and Invoice request added'),
          message: _t("Purchase order '%s' and Invoice request '%s' have been created successfully.",poNumber,textInvoice), // prettier-ignore
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(detailTab, { replace: true });
      })
      .catch((e) => {
        panic(e.message);
      });
  };

  if (costEstimate.invoice_request || costEstimate.closed_at) {
    return <Navigate to={detailTab} replace />;
  }

  return (
    <Box>
      <Box pt={24} pb={16}>
        <Alert
          severity="notification"
          primaryText={_t('Open attached invoice and fill in all information from the invoice.')}
        />
      </Box>
      <InvoiceRequestForm
        initialValues={initialValues}
        onSubmit={submit}
        costEstimate={costEstimate}
        mainActionText={_t('Create')}
      />
    </Box>
  );
}
