import { isFunction } from 'lodash';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

/**
 * Link component which shows a confirmation dialog before navigating to the
 * given URL.
 *
 * @param {import('react-router-dom').LinkProps & {
 *   confirmTitle: string,
 *   confirmMessage: string,
 *   skipConfirm?: boolean | (() => boolean),
 * }}
 */
export default function LinkWithConfirm({ confirmTitle, confirmMessage, to, skipConfirm = false, ...props }) {
  const { confirm } = useConfirm();
  const navigate = useNavigate();

  /**
   * Handle the user's click on the link.
   */
  const click = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const shouldSkip = isFunction(skipConfirm) ? skipConfirm() : skipConfirm;

      if (shouldSkip) {
        navigate(to);
      } else {
        confirm({
          title: confirmTitle,
          message: confirmMessage,
          onConfirm: () => navigate(to),
        });
      }

      return false;
    },
    [navigate, skipConfirm, confirmMessage, confirmTitle, to]
  );

  return <Link {...props} to={to} onClick={click} />;
}
