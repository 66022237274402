import { Box } from '@mantine/core';

/**
 * The title of a section in the form.
 *
 * @param {{title: string}}
 */
export default function SectionTitle({ title }) {
  return <Box style={{ color: '#4D4D4D', fontSize: '20px', lineHeight: '36px' }}>{title}</Box>;
}
