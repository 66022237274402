/**
 * Icon used by the Italic control component.
 */
export default function IconItalic() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.80013 7.50033H11.4668L9.63346 15.8337H7.9668L9.80013 7.50033ZM11.2001 4.16699C11.0353 4.16699 10.8742 4.21587 10.7372 4.30743C10.6001 4.399 10.4933 4.52915 10.4302 4.68142C10.3672 4.83369 10.3507 5.00125 10.3828 5.1629C10.415 5.32455 10.4943 5.47304 10.6109 5.58958C10.7274 5.70612 10.8759 5.78549 11.0376 5.81765C11.1992 5.8498 11.3668 5.8333 11.519 5.77023C11.6713 5.70715 11.8015 5.60034 11.893 5.4633C11.9846 5.32626 12.0335 5.16514 12.0335 5.00033C12.0335 4.77931 11.9457 4.56735 11.7894 4.41107C11.6331 4.25479 11.4211 4.16699 11.2001 4.16699Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
