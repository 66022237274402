import { Box, Group, Skeleton, Text } from '@mantine/core';
import CollapseArrow from 'components/CollapseArrow';

/**
 * @param {{
 *   number: React.ReactNode | null,
 *   loading: boolean,
 * }}
 */
function GroupHeadlineNumber({ number, loading }) {
  if (loading) {
    return <Skeleton w={30} h={18} />;
  }

  if (number === null) {
    return null;
  }

  return (
    <Box px={4} pt={2} color="#4D4D4D" className="text-[12px] leading-[16px]">
      ( {number} )
    </Box>
  );
}

/**
 * Headline of a collapsible group.
 *
 * @see {@link https://www.figma.com/file/jZPNVAdhBmEl8qIOrILWmH/Toolio---component-library?node-id=177%3A1562&mode=dev Figma Design}
 *
 * @param {{
 *   heading: string,
 *   opened: boolean,
 *   setOpened: (opened: boolean) => void,
 *   number?: React.ReactNode | null,
 *   loadingNumber?: boolean,
 *   actions?: React.ReactNode,
 *   style?: React.CSSProperties,
 *   className?: string,
 *   collapsible?: boolean,
 * }}
 */
export default function GroupHeadline({
  heading,
  opened,
  setOpened,
  actions,
  style,
  className,
  number = null,
  loadingNumber = false,
  collapsible = true,
}) {
  /**
   * Toggles the collapse state if the group is collapsible.
   */
  const maybeToggleCollapse = () => {
    if (collapsible) {
      setOpened(!opened);
    }
  };

  const cursor = collapsible ? 'cursor-pointer' : 'cursor-text';

  return (
    <Group style={style} className={className} position="apart" w="100%">
      <Group className={cursor} spacing={4} py={16} onClick={maybeToggleCollapse}>
        {collapsible && (
          <Box w={24} h={24}>
            <CollapseArrow opened={opened} />
          </Box>
        )}
        <Group spacing={4}>
          <Text fz={22} lh={36 / 22} weight={400} className="uppercase">
            {heading}
          </Text>
          <GroupHeadlineNumber number={number} loading={loadingNumber} />
        </Group>
      </Group>
      {actions}
    </Group>
  );
}
