import { Link } from 'react-router-dom';
import Avatar from 'components/avatars/Avatar';
import EditIcon from 'components/icons/EditIcon';
import { Stack, Tooltip } from '@mantine/core';
import UserNameWithAvatar from 'components/avatars/UserNameWithAvatar';
import { useMemo } from 'react';
import { nanoid } from 'nanoid';

/**
 * User View component.
 *
 * @param {{
 *   users: Array,
 *   editUsersPath: string,
 *   max: number,
 *   overlap: number,
 *   avatarSize: number,
 * }}
 */
export default function UserView({ users, editUsersPath, max = 10, overlap = 3, avatarSize = 30 }) {
  const usersWithKey = useMemo(() => users.map((user) => ({ user, key: nanoid() })), [users]);
  const visibleUsers = useMemo(() => usersWithKey.slice(0, max), [usersWithKey, max]);
  const hiddenUsers = useMemo(() => usersWithKey.slice(max), [usersWithKey, max]);

  const gridStyle = useMemo(
    () => ({
      gridTemplateColumns: `repeat(${Math.min(max + 1, users.length) + (users.length > 1 ? 1 : 0)}, ${
        avatarSize - overlap
      }px)`,
      gridTemplateRows: `${avatarSize}px`,
    }),
    [users.length, avatarSize, overlap, max]
  );

  return (
    <div className="grid items-center" style={gridStyle}>
      {/* USER ICONS */}
      {visibleUsers.map(({ user: { full_name, avatar, color }, key }) => (
        <Avatar withTooltip key={key} image={avatar} label={full_name} color={color} size={avatarSize} />
      ))}

      {/* TOO MANY ICONS TO DISPLAY */}
      {hiddenUsers.length > 0 && (
        <Tooltip
          label={
            <Stack spacing={8} py={4}>
              {hiddenUsers.map(({ user: { full_name, avatar }, key }) => (
                <UserNameWithAvatar key={key} avatar={avatar} name={full_name} size={avatarSize} />
              ))}
            </Stack>
          }
          offset={4}
          openDelay={150}
        >
          <div
            className="z-10 flex items-center justify-center rounded-full bg-grey-500 text-[10px] text-white"
            style={{ width: avatarSize, height: avatarSize }}
          >
            +{hiddenUsers.length}
          </div>
        </Tooltip>
      )}

      {/* EDIT USERS ICON */}
      {editUsersPath && (
        <Link
          to={editUsersPath}
          className="rounded-full border border-primary-dark-blue p-[5px]"
          style={{
            // 10px according to Figma design.
            transform: users.length > 0 ? `translateX(${overlap + 10}px)` : undefined,
          }}
        >
          <EditIcon height={15} width={15} fill="#38298B" />
        </Link>
      )}
    </div>
  );
}
