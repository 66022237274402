import { _t } from 'lang';
import StatusSwitch from 'components/inputs/status-switch/StatusSwitch';
import { useApi } from 'api/ApiContext';

/**
 * Status switch for the supplier model.
 */
export default function SupplierStatusSwitch({ supplierId, name, isActive }) {
  const { getAction } = useApi();
  const supplierSetActiveAction = getAction('SupplierSetActiveAction');

  return (
    <StatusSwitch
      entity={_t('Supplier')}
      name={name}
      action={supplierSetActiveAction}
      primaryKey={supplierId}
      pathParameterName="supplier_id"
      initialValue={isActive}
    />
  );
}
