/** @typedef {import('api/actions/price-list-get-list-action/price-list-get-list-action-response').PriceListGetListActionResponse[number]} PriceList */

import { _t } from 'lang';
import StatusSwitch from 'components/inputs/status-switch/StatusSwitch';
import { useApi } from 'api/ApiContext';

/**
 * Status switch for the Price list model.
 *
 * @param {{
 *   priceList: PriceList;
 * }}
 */
export default function PriceListStatusSwitch({ priceList }) {
  const { getAction } = useApi();
  const priceListSetActiveAction = getAction('PriceListSetActiveAction');

  let disabled = false;
  let reason = null;

  if (priceList.clients.length > 0) {
    disabled = true;
    reason = _t('This price list is assigned to one or more clients.');
  }

  return (
    <StatusSwitch
      entity={_t('Price list')}
      name={priceList.price_list_name}
      action={priceListSetActiveAction}
      primaryKey={priceList.price_list_id}
      pathParameterName="price_list_id"
      initialValue={priceList.is_active}
      disabled={disabled}
      disabledReason={reason}
    />
  );
}
