/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-delete-attachment-action-body').TaskDeleteAttachmentActionBody} TaskDeleteAttachmentActionBody
 */

/**
 * @typedef {import('./task-delete-attachment-action-response').TaskDeleteAttachmentActionResponse} TaskDeleteAttachmentActionResponse
 */

/**
 * The path of the TaskDeleteAttachmentAction action.
 */
export const taskDeleteAttachmentActionPath = new SweetPath('/api/project/project/task/{task_id}/delete-attachment', {
  path: '{param}',
});

/**
 * Creates a contextualized TaskDeleteAttachmentAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskDeleteAttachmentAction(axios) {
  /**
   * The taskDeleteAttachmentAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskDeleteAttachmentActionBody } }
   *
   * @returns {Promise<TaskDeleteAttachmentActionResponse>}
   */
  function taskDeleteAttachmentAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskDeleteAttachmentActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return taskDeleteAttachmentAction;
}
