/** @typedef {import("../CostEstimateRowData").CostEstimateRowData} CostEstimateRowData */

import { useEffect, useState } from 'react';
import MoneyInput from 'components/inputs/MoneyInput';
import { useMantineTheme } from '@mantine/core';
import { useData } from '../../providers/DataProvider';

/**
 * Input inside a CostEstimateRow to specify a number.
 *
 * @param {{
 *   data: CostEstimateRowData,
 *   updateData: (value: Partial<CostEstimateRowData>) => void,
 * }}
 */
export default function ExternalCosts({ data, updateData }) {
  const theme = useMantineTheme();

  const [externalCosts, setExternalCosts] = useState(data.externalCosts);
  const { data: costEstimateData } = useData();

  // Propagate changes with debounce.
  useEffect(() => {
    updateData({ externalCosts });
  }, [externalCosts]);

  return (
    <MoneyInput
      borderColor={theme.colors.complementaryLavender[0]}
      textColor={theme.colors.complementaryAfricanViolet[0]}
      min={0}
      precision={2}
      step={1}
      value={externalCosts}
      onChange={setExternalCosts}
      currency={costEstimateData.currency}
    />
  );
}
