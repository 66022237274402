import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { _t } from 'lang';
import { useNavigate } from 'react-router-dom';
import { ADD_TEAM_PAGE_PATH } from 'routes/paths';
import DashboardPageHeading from 'layouts/dashboard-layout/headers/DashboardPageHeading';
import TeamTable from 'components/tables/TeamTable';

/**
 * Teams page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=233%3A6291
 */
export default function TeamsPage() {
  const navigate = useNavigate();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="relative h-full p-[20px] pt-0">
        <DashboardPageHeading heading={_t('Teams')} buttonAction={() => navigate(ADD_TEAM_PAGE_PATH.original)} />
        <TeamTable />
      </div>
    </DashboardLayout>
  );
}
