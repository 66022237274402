/** @typedef {import('@tiptap/react').Editor['view']} EditorView */
/** @typedef {import('@tiptap/react').Node} Node */

import { createContext, useContext } from 'react';

/**
 * The name of the image extension.
 */
export const TOOLIO_IMAGE_NAME = 'toolio-image';

/**
 * Context for the Toolio image extension.
 *
 * @typedef {(view: EditorView, image: Blob) => Promise<Node>} ICreateToolioImage
 *
 * @type {React.Context<{
 *   createToolioImage: ICreateToolioImage;
 *   ToolioImage: Node;
 *   loading: boolean;
 *   resizing: boolean;
 * }>}
 */
export const ToolioImageContext = createContext();

/**
 * Uses the Toolio image extension.
 */
export function useToolioImage() {
  return useContext(ToolioImageContext);
}
