import Alert from 'components/Alert';
import { _t } from 'lang';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import formatPrice from 'utils/format-price';

/**
 * Displays an alert when the actual external costs exceed the budgeted
 * external costs.
 *
 * @param {{
 *   className?: string;
 * }}
 */
export default function ExternalCostsOverflowAlert({ className = '' } = {}) {
  const { costEstimate, currency } = useCostEstimateDetail();

  const budget = costEstimate.budget_external;
  const actual = costEstimate.external_cost_sum_actual;
  const overflow = actual - budget;
  const hasOverflow = overflow > 0;

  if (!hasOverflow) {
    return <></>;
  }

  return (
    <div className={className}>
      <Alert
        severity="error"
        primaryText={_t('The entered external cost of %s exceeds the budgeted external costs of %s by %s.', formatPrice(actual, currency), formatPrice(budget, currency), formatPrice(overflow, currency))} // prettier-ignore
      />
    </div>
  );
}
