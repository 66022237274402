/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./workspace-config-update-action-body').WorkspaceConfigUpdateActionBody} WorkspaceConfigUpdateActionBody
 */

/**
 * @typedef {import('./workspace-config-update-action-response').WorkspaceConfigUpdateActionResponse} WorkspaceConfigUpdateActionResponse
 */

/**
 * The path of the WorkspaceConfigUpdateAction action.
 */
export const workspaceConfigUpdateActionPath = new SweetPath('/api/status/workspace-config', { path: '{param}' });

/**
 * Creates a contextualized WorkspaceConfigUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createWorkspaceConfigUpdateAction(axios) {
  /**
   * The workspaceConfigUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: WorkspaceConfigUpdateActionBody } }
   *
   * @returns {Promise<WorkspaceConfigUpdateActionResponse>}
   */
  function workspaceConfigUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = workspaceConfigUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return workspaceConfigUpdateAction;
}
