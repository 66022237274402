import { _t } from 'lang';
import { useMemo } from 'react';
import formatPrice from 'utils/format-price';

/**
 * One row in the Finance Indicator.
 */
function Row({ label, value }) {
  return (
    <div className="flex flex-row items-center justify-between gap-4 text-[12px] leading-[16px] text-neutral-700">
      <div>{label}</div>
      <div>{value}</div>
    </div>
  );
}

/**
 * The finance indicator component.
 *
 * @see {@link https://www.figma.com/file/jZPNVAdhBmEl8qIOrILWmH/Toolio---component-library?node-id=640%3A5245&mode=dev Figma}
 *
 * @param {{
 *   type: 'budgeted'|'invoiced'|'external-costs',
 *   currency: string,
 *   total: number,
 *   part: number
 * }}
 */
export default function FinanceIndicator({ type, currency, total, part }) {
  const remains = total - part;

  // TODO should the status be hard-coded?
  const invoicedStatus = remains > 0 ? _t('Warning') : _t('Good');
  const invoicedStatusColor = remains > 0 ? 'bg-complementary-danger' : 'bg-complementary-status-positive';

  const status = remains > 0 ? _t('Good') : _t('Warning');
  const statusColor = remains > 0 ? 'bg-complementary-status-positive' : 'bg-complementary-danger';

  const indicator = useMemo(() => {
    if (type === 'budgeted') {
      return (
        <>
          <Row label={_t('Budgeted')} value={formatPrice(total, currency)} />
          <Row label={_t('Spent')} value={formatPrice(part, currency)} />
          <Row label={_t('Remaining')} value={formatPrice(remains, currency)} />
          <Row
            label={_t('Health')}
            value={
              <div className="flex flex-row items-center gap-1">
                <div className={`h-2 w-2 rounded-full ${statusColor}`}></div>
                <div>{status}</div>
              </div>
            }
          />
        </>
      );
    }

    if (type === 'invoiced') {
      return (
        <>
          <Row label={_t('Invoiced')} value={formatPrice(part, currency)} />
          <Row label={_t('Budgeted')} value={formatPrice(total, currency)} />
          <Row label={_t('Remaining')} value={formatPrice(remains, currency)} />
          <Row
            label={_t('Health')}
            value={
              <div className="flex flex-row items-center gap-[7px]">
                <div className={`h-2 w-2 rounded-full ${invoicedStatusColor}`}></div>
                <div>{invoicedStatus}</div>
              </div>
            }
          />
        </>
      );
    }

    if (type === 'external-costs') {
      return (
        <>
          <Row label={_t('Estimated')} value={formatPrice(total, currency)} />
          <Row label={_t('Spent')} value={formatPrice(part, currency)} />
          <Row label={_t('Remaining')} value={formatPrice(remains, currency)} />
          <Row
            label={_t('Health')}
            value={
              <div className="flex flex-row items-center gap-[7px]">
                <div className={`h-2 w-2 rounded-full ${statusColor}`}></div>
                <div>{status}</div>
              </div>
            }
          />
        </>
      );
    }

    return <></>;
  }, [type]);

  return <div className="flex flex-col gap-1 p-2">{indicator}</div>;
}
