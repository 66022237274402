import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { _t } from 'lang';
import { Breadcrumbs, Menu, Tabs } from '@mantine/core';
import { APP_HEADER_HEIGHT } from 'utils/constants';
import CostEstimateTab from 'pages/finance/cost-estimate/detail/cost-estimate-tab/CostEstimateTab';
import PurchaseOrderTab from 'pages/finance/cost-estimate/detail/purchase-order-tab/PurchaseOrderTab';
import InvoiceTab from 'pages/finance/cost-estimate/detail/invoice-tab/InvoiceTab';
import ExternalCostTab from 'pages/finance/cost-estimate/detail/external-cost-tab/ExternalCostTab';
import { Link, useSearchParams } from 'react-router-dom';
import EditIcon from 'components/icons/EditIcon';
import ExportPdfIcon from 'components/icons/ExportPdfIcon';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import CostEstimateActions from 'pages/finance/cost-estimate/detail/CostEstimateActions';
import { useCallback } from 'react';
import { FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from 'routes/paths';

/**
 * Contents of the cost estimate detail page.
 */
export default function CostEstimateDetailContent() {
  const { costEstimate, links, isApproved } = useCostEstimateDetail();
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Sets the active tab.
   *
   * @type {(tab: string) => void}
   */
  const setTab = useCallback((tab) => setSearchParams({ tab }, { replace: true }), [setSearchParams]);

  const activeTab = searchParams.get('tab') || 'cost-estimate';
  const baseUrl = FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original;

  return (
    <div className="bg-neutral-50 pb-8" style={{ minHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)` }}>
      <PageHeading
        className="px-8"
        heading={costEstimate.cost_estimate_name}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={baseUrl}>{_t('Finance')}</Link>
            <Link to={`${baseUrl}?clientId=${costEstimate.client.client_id}`}>{costEstimate.client.client_name}</Link>
            <Link
              to={`${baseUrl}?projectId=${costEstimate.project.project_id}&clientId=${costEstimate.client.client_id}`}
            >
              {costEstimate.project.project_full_name}
            </Link>
          </Breadcrumbs>
        }
        actionButton={<CostEstimateActions tab={activeTab} />}
        menuDropdown={
          <Menu.Dropdown>
            {!isApproved && (
              <Menu.Item icon={<EditIcon fill="#4D4D4D" />} component={Link} to={links.edit}>
                {_t('Edit')}
              </Menu.Item>
            )}
            <Menu.Item icon={<ExportPdfIcon stroke="#4D4D4D" />} component={Link} to={links.pdf} target="_blank">
              {_t('Download preview')}
            </Menu.Item>
          </Menu.Dropdown>
        }
      />

      <Tabs value={activeTab} onTabChange={setTab}>
        <Tabs.List className="px-8">
          <Tabs.Tab value="cost-estimate">{_t('Cost estimate')}</Tabs.Tab>
          <Tabs.Tab value="purchase-order">{_t('Purchase order')}</Tabs.Tab>
          <Tabs.Tab value="invoice">{_t('Invoice')}</Tabs.Tab>
          <Tabs.Tab value="external-cost">{_t('External cost')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="cost-estimate">
          <CostEstimateTab key={costEstimate.cost_estimate_id} />
        </Tabs.Panel>
        <Tabs.Panel value="purchase-order">
          <PurchaseOrderTab key={costEstimate.cost_estimate_id} />
        </Tabs.Panel>
        <Tabs.Panel value="invoice">
          <InvoiceTab key={costEstimate.cost_estimate_id} />
        </Tabs.Panel>
        <Tabs.Panel value="external-cost">
          <ExternalCostTab key={costEstimate.cost_estimate_id} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
