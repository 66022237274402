/** @typedef {import('api/actions/team-get-list-action/team-get-list-action-response').TeamGetListActionResponse[number]} Team */

import { _t } from 'lang';
import DataTable from 'components/tables/DataTable';
import UserView from 'components/avatars/UserView';
import Avatar from 'components/avatars/Avatar';
import TeamStatusSwitch from 'components/inputs/status-switch/TeamStatusSwitch';
import { useApi } from 'api/ApiContext';
import { EDIT_TEAM_PAGE_PATH } from 'routes/paths';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Group } from '@mantine/core';

/**
 * A row in the Teams table.
 *
 * @param {{
 *   data: Team;
 *   hover: boolean;
 * }}
 */
function TeamTableRow({ data: team, hover }) {
  return (
    <>
      <Group noWrap>
        <Box w={60}>
          <Avatar image={team.avatar} color={`#${team.color}`} size={60} />
        </Box>
        <Link to={EDIT_TEAM_PAGE_PATH.insert({ teamId: team.team_id })}>
          <div className="flex items-center transition-all hover:text-main-primary">
            <span className="text-[20px] leading-[20px]">{team.name}</span>
            {hover && <small className="ml-[10px] text-[12px] text-grey-500">(ID: {team.team_id})</small>}
          </div>
        </Link>
      </Group>
      <div className="flex">
        <UserView
          max={9}
          users={team.members}
          editUsersPath={`${EDIT_TEAM_PAGE_PATH.insert({ teamId: team.team_id })}?tab=members`}
        />
      </div>
      <TeamStatusSwitch team={team} />
    </>
  );
}

/**
 * The table in the Teams page.
 */
export default function TeamTable() {
  const { getAction } = useApi();
  const teamGetListAction = getAction('TeamGetListAction');

  const columns = useMemo(
    () => [
      { title: _t('Team name'), width: 6 },
      { title: _t('Members'), width: 3 },
      { title: _t('Status'), width: 1 },
    ],
    []
  );

  return (
    <DataTable
      action={teamGetListAction}
      columns={columns}
      rowKey={(team) => team.team_id}
      editLink={(team) => EDIT_TEAM_PAGE_PATH.insert({ teamId: team.team_id })}
      RowComponent={TeamTableRow}
    />
  );
}
