import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { _t } from 'lang';
import { USERS_PAGE_PATH } from 'routes/paths';
import { useNavigate, useParams } from 'react-router-dom';
import { SUCCESS_NOTIFICATION_COLOR, DUPLICATE_USER_EMAIL_ERROR_CODE } from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { isEmpty } from 'lodash';
import UserForm from 'components/forms/user-form/UserForm';
import { useEffect, useState } from 'react';
import panic from 'errors/Panic';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the EditUserPage.
 *
 * @param {{ userId: string }}
 */
function EditUserImpl({ userId }) {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshUsers } = useSettingsSidebar();
  const [emailError, setEmailError] = useState('');

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const userGetListAction = getAction('UserGetListAction');

    setLoading(true);

    userGetListAction({
      query: {
        filter: { 'user_id.eq': userId },
        limit: 1,
      },
    })
      .then((data) => {
        if (data.length === 0) {
          setInitialValues({});
          return;
        }
        const user = data[0];

        const initialValues = {
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          jobPositionId: user.position_in_company ? String(user.position_in_company.position_in_company_id) : '',
          roleId: user.role ? String(user.role.role_id) : '',
          teamId: user.team ? String(user.team.team_id) : undefined,
          hourRate: user.internal_hour_rate ? user.internal_hour_rate.value : 0,
          memberOf: user.member_of ? user.member_of.map(String) : [],
          initStatus: user.init_status,
          lastPasswordChange: user.last_password_change,
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, userId]);

  if (!userId || (!loading && isEmpty(initialValues))) {
    return <NotFoundPageContent />;
  }

  /**
   * Sends the request to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ email, firstName, lastName, jobPositionId, roleId, teamId, hourRate, memberOf }) => {
    const userUpdateAction = getAction('UserUpdateAction');
    setEmailError('');

    return userUpdateAction({
      parameters: { user_id: userId },
      body: {
        email: email,
        first_name: firstName,
        last_name: lastName,
        position_in_company_id: jobPositionId,
        role_id: roleId,
        team_id: teamId === '' ? undefined : teamId,
        internal_hour_rate: hourRate,
        member_of: !isEmpty(memberOf) ? memberOf.map(Number) : [],
      },
    })
      .then(() => {
        showNotification({
          title: _t('User updated'),
          message: _t("User '%s' has been updated successfully.", `${firstName} ${lastName}`),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshUsers();
      })
      .catch((e) => {
        if (e.code === DUPLICATE_USER_EMAIL_ERROR_CODE) {
          setEmailError(e.message);
        } else {
          panic(e);

          navigate(USERS_PAGE_PATH.original);
        }
      });
  };

  return (
    <>
      <PageHeading heading={_t('Edit user')} />
      {!loading && <UserForm initialValues={initialValues} onSubmit={submit} emailError={emailError} />}
    </>
  );
}
/**
 * Displays form to edit user.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A24851
 */
export default function EditUserPage() {
  const { userId } = useParams();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <EditUserImpl key={userId} userId={userId} />
      </div>
    </DashboardLayout>
  );
}
