/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./role-get-list-action-body').RoleGetListActionBody} RoleGetListActionBody
 */

/**
 * @typedef {import('./role-get-list-action-response').RoleGetListActionResponse} RoleGetListActionResponse
 */

/**
 * The path of the RoleGetListAction action.
 */
export const roleGetListActionPath = new SweetPath('/api/user/role/', { path: '{param}' });

/**
 * Creates a contextualized RoleGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createRoleGetListAction(axios) {
  /**
   * The roleGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<RoleGetListActionResponse>}
   */
  function roleGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = roleGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return roleGetListAction;
}
