import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconOrderedList from 'components/inputs/wysiwyg/controls/ordered-list/IconOrderedList';

/**
 * Used to insert ordered list.
 */
export default function ControlOrderedList() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Ordered list" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        active={editor?.isActive('orderedList')}
      >
        <IconOrderedList />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
