/**
 * The error level of the error.
 */
export const API_ERROR_LEVEL_ERROR = 0;

/**
 * The warning level of the error.
 */
export const API_ERROR_LEVEL_WARNING = 1;

/**
 * Error returned by the API.
 */
export default class ApiError extends Error {
  /**
   * Creates a new instance of ApiError.
   *
   * @param {string} message
   * @param {number} code
   * @param {0|1} level
   */
  constructor(message, code, level = API_ERROR_LEVEL_ERROR) {
    super(message);

    /**
     * The error code.
     */
    this.code = code;

    /**
     * The error level.
     */
    this.level = level;
  }
}
