import FormRow from 'components/forms/FormRow';
import ClientSelect from 'components/selects/ClientSelect';
import { _t } from 'lang';
import { useClient } from 'providers/client/ClientProvider';
import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import { Skeleton } from '@mantine/core';
import { useProject } from 'providers/project/ProjectProvider';

/**
 * The client of a cost estimate.
 */
export default function CostEstimateClient() {
  const { clientId, client, setClientId } = useClient();
  const { setProjectId } = useProject();
  const { data } = useData();

  /**
   * Updates the client ID.
   *
   * @param {string} clientId The client ID.
   */
  function onChange(clientId) {
    setClientId((oldClientId) => {
      const newClientId = Number(clientId);

      if (oldClientId !== newClientId) {
        setProjectId(null);
      }

      return newClientId;
    });
  }

  let select = <Skeleton w="full" h={36} animate radius={8} />;

  // Either this is a new cost estimate or the project is already fetched.
  if (!data.costEstimateId || client) {
    select = <ClientSelect value={String(clientId)} onChange={onChange} disabled={!!data.costEstimateId} />;
  }

  return <FormRow label={_t('Client')} input={select} />;
}
