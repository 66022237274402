/** @typedef {import('api/actions/price-list-create-action/price-list-create-action-body').PriceListCreateActionBody} PriceListCreateActionBody */

import { useForm } from '@mantine/form';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import FormRow from 'components/forms/FormRow';
import { _t } from 'lang';
import { Box, Stack, Textarea, TextInput } from '@mantine/core';
import ClientSelect from 'components/selects/ClientSelect';
import PriceListItemsDefinition from 'components/forms/price-list-form/price-list-items/PriceListItemsDefinition';
import CurrencySelect from 'components/selects/CurrencySelect';
import { useApi } from 'api/ApiContext';
import useLoadingAction from 'hooks/use-loading-action';
import SectionTitle from '../FormSectionTitle';
import FormFooter from '../FormFooter';
import DatePicker from 'components/inputs/DatePicker';

/**
 * @typedef {{
 *   positionInCompanyId: string;
 *   positionName: string;
 *   projectBasedRate: number;
 *   flatFeeRate: number;
 *   currency: string;
 *   isCustom: boolean;
 * }} PriceListItem
 */

/**
 * @typedef {{
 *   priceListName: string;
 *   clients: string[];
 *   isDefault?: boolean;
 *   currency: string;
 *   validFrom: string|Date;
 *   validTo: string|Date;
 *   note: string;
 *   items: any;
 * }} PriceListFormData
 */

/**
 * The Add/Edit price list form.
 *
 * @param {{
 *   initialValues: PriceListFormData;
 *   onSubmit: (values: PriceListFormData) => void | Promise<void>;
 *   isDefaultPriceList: boolean;
 * }}
 */
export default function PriceListForm({ initialValues, onSubmit, isDefaultPriceList }) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const { workspaceConfig } = useApi();
  const form = useForm({
    initialValues,

    validate: {
      priceListName: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={16} pt={16}>
        <FormRow
          label={_t('Price list name')}
          input={
            <TextInput
              placeholder={_t('Price list name')}
              disabled={isDefaultPriceList}
              autoFocus
              {...form.getInputProps('priceListName')}
            />
          }
        />
        <FormRow
          label={_t('Client')}
          input={
            <ClientSelect
              multiple
              placeholder={_t('Clients')}
              {...form.getInputProps('clients')}
              disabled={isDefaultPriceList}
              readOnly={isDefaultPriceList}
            />
          }
        />
        <FormRow
          label={_t('Currency')}
          input={<CurrencySelect disabled placeholder={_t('Currency')} {...form.getInputProps('currency')} />}
        />
        <FormRow label={_t('Valid from')} input={<DatePicker {...form.getInputProps('validFrom')} />} />
        <FormRow label={_t('Valid to')} input={<DatePicker {...form.getInputProps('validTo')} />} />
        <FormRow label={_t('Note')} input={<Textarea placeholder={_t('Note')} {...form.getInputProps('note')} />} />

        <Box mt={52} bg="#FFFFFF" style={{ borderRadius: '8px' }}>
          <Box pt={16} pb={16} pl={40} pr={8}>
            <SectionTitle title={_t('Hour rates')} />
          </Box>
          <PriceListItemsDefinition
            {...form.getInputProps('items')}
            currency={form.values.currency || workspaceConfig.currency}
            isDefaultPriceList={isDefaultPriceList}
          />
        </Box>
      </Stack>

      <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
