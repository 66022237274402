/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-get-notified-people-action-body').CommentGetNotifiedPeopleActionBody} CommentGetNotifiedPeopleActionBody
 */

/**
 * @typedef {import('./comment-get-notified-people-action-response').CommentGetNotifiedPeopleActionResponse} CommentGetNotifiedPeopleActionResponse
 */

/**
 * The path of the CommentGetNotifiedPeopleAction action.
 */
export const commentGetNotifiedPeopleActionPath = new SweetPath('/api/comment/comment_notified/{comment_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized CommentGetNotifiedPeopleAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentGetNotifiedPeopleAction(axios) {
  /**
   * The commentGetNotifiedPeopleAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<CommentGetNotifiedPeopleActionResponse>}
   */
  function commentGetNotifiedPeopleAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = commentGetNotifiedPeopleActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return commentGetNotifiedPeopleAction;
}
