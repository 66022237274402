import { SweetPath } from 'sweet-path';

// HOME PAGE
export const HOME_PAGE_PATH = new SweetPath('/');

// SYSTEM PAGES
export const NOT_ALLOWED_PAGE_PATH = new SweetPath('/not-allowed');
export const SYSTEM_MAINTENANCE_PAGE_PATH = new SweetPath('/system-maintenance');
export const SYSTEM_OUTAGE_PAGE_PATH = new SweetPath('/system-outage');

// LOGIN
export const LOGIN_PAGE_PATH = new SweetPath('/login');
export const RESET_PASSWORD_PAGE_PATH = new SweetPath('/reset-password');
export const SET_PASSWORD_PAGE_PATH = new SweetPath('/set-password');
export const RESET_LINK_EXPIRED_PAGE_PATH = new SweetPath('/reset-link-expired');

// SETTINGS - CLIENT
export const ADD_CLIENT_PAGE_PATH = new SweetPath('/settings/clients/add');
export const EDIT_CLIENT_PAGE_PATH = new SweetPath('/settings/clients/edit/:clientId');
export const CLIENTS_PAGE_PATH = new SweetPath('/settings/clients');

// SETTINGS - ROLE
export const ADD_ROLE_PAGE_PATH = new SweetPath('/settings/roles/add');
export const EDIT_ROLE_PAGE_PATH = new SweetPath('/settings/roles/edit/:roleId');
export const ROLES_PAGE_PATH = new SweetPath('/settings/roles');

// SETTINGS - USER
export const ADD_USER_PAGE_PATH = new SweetPath('/settings/users/add');
export const EDIT_USER_PAGE_PATH = new SweetPath('/settings/users/edit/:userId');
export const USERS_PAGE_PATH = new SweetPath('/settings/users');

// SETTINGS - TEAM
export const ADD_TEAM_PAGE_PATH = new SweetPath('/settings/teams/add');
export const EDIT_TEAM_PAGE_PATH = new SweetPath('/settings/teams/edit/:teamId');
export const TEAMS_PAGE_PATH = new SweetPath('/settings/teams');

// SETTINGS - JOB POSITIONS
export const ADD_JOB_POSITION_PAGE_PATH = new SweetPath('/settings/job-positions/add');
export const EDIT_JOB_POSITION_PAGE_PATH = new SweetPath('/settings/job-positions/edit/:jobPositionId');
export const JOB_POSITIONS_PAGE_PATH = new SweetPath('/settings/job-positions');

// SETTINGS - DEPARTMENT
export const ADD_DEPARTMENT_PAGE_PATH = new SweetPath('/settings/departments/add');
export const EDIT_DEPARTMENT_PAGE_PATH = new SweetPath('/settings/departments/edit/:departmentId');
export const DEPARTMENTS_PAGE_PATH = new SweetPath('/settings/departments');

// SETTINGS - GLOBAL
export const FILE_PREFIX_SETTINGS_PAGE_PATH = new SweetPath('/settings/file-prefix');
export const GLOBAL_SETTINGS_PAGE_PATH = new SweetPath('/settings/global-settings');

// SETTINGS - SUPPLIERS
export const ADD_SUPPLIER_PAGE_PATH = new SweetPath('/settings/suppliers/add');
export const EDIT_SUPPLIER_PAGE_PATH = new SweetPath('/settings/suppliers/edit/:supplierId');
export const SUPPLIERS_PAGE_PATH = new SweetPath('/settings/suppliers');

// SETTINGS - PRICE LISTS
export const ADD_PRICE_LIST_PAGE_PATH = new SweetPath('/settings/price-lists/add');
export const EDIT_PRICE_LIST_PAGE_PATH = new SweetPath('/settings/price-lists/edit/:priceListId');
export const PRICE_LISTS_PAGE_PATH = new SweetPath('/settings/price-lists');

// PROJECTS
export const PROJECT_DASHBOARD_PAGE_PATH = new SweetPath('/projects');
export const PROJECT_DETAIL_PAGE_PATH = new SweetPath('/projects/project/:projectId');
export const ADD_PROJECT_PAGE_PATH = new SweetPath('/projects/add');
export const EDIT_PROJECT_PAGE_PATH = new SweetPath('/projects/project/edit/:projectId');

// TASKS
export const ADD_TASK_PAGE_PATH = new SweetPath('/projects/:projectId/task/add');
export const ADD_SUBTASK_PAGE_PATH = new SweetPath('/projects/task/:taskId/subtask/add');
export const EDIT_TASK_PAGE_PATH = new SweetPath('/projects/task/edit/:taskId');
export const TASK_DETAIL_PAGE_PATH = new SweetPath('/projects/task/detail/:taskId');

// TIME ENTRIES
export const TIME_ENTRIES_OVERVIEW_PAGE_PATH = new SweetPath('/time-entries');

// REPORTS
export const TIME_ENTRIES_EXPORT_PAGE_PATH = new SweetPath('/reports/time-entries');

// FINANCE - PROJECT OVERVIEW
export const FINANCE_PROJECT_OVERVIEW_PAGE_PATH = new SweetPath('/finance/project-overview');
export const FINANCE_ADD_COST_ESTIMATE_PAGE_PATH = new SweetPath('/finance/cost-estimate/add');
export const FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH = new SweetPath('/finance/cost-estimate/:costEstimateId/edit');
export const FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH = new SweetPath('/finance/cost-estimate/:costEstimateId/detail');
export const FINANCE_DETAIL_COST_ESTIMATE_PDF_PREVIEW_PAGE_PATH = new SweetPath(
  '/finance/cost-estimate/:costEstimateId/pdf-preview'
);
export const FINANCE_ADD_EXTERNAL_COST_PAGE_PATH = new SweetPath(
  '/finance/cost-estimate/:costEstimateId/external-cost/add'
);
export const FINANCE_EDIT_EXTERNAL_COST_PAGE_PATH = new SweetPath(
  '/finance/cost-estimate/:costEstimateId/external-cost/edit/:externalCostId'
);

// FINANCE - INVOICES
export const FINANCE_ADD_INVOICE_REQUEST_PAGE_PATH = new SweetPath(
  '/finance/cost-estimate/:costEstimateId/invoice-request/add'
);
export const FINANCE_EDIT_INVOICE_REQUEST_PAGE_PATH = new SweetPath(
  '/finance/cost-estimate/:costEstimateId/invoice-request/edit/:purchaseOrderId'
);
export const FINANCE_ADD_INVOICE_PAGE_PATH = new SweetPath('/finance/cost-estimate/:costEstimateId/invoice/add');
export const FINANCE_EDIT_INVOICE_PAGE_PATH = new SweetPath(
  '/finance/cost-estimate/:costEstimateId/invoice/edit/:invoiceId'
);

// FILE - FILE DOWNLOAD
export const FILE_DOWNLOAD_PAGE_PATH = new SweetPath('/files/file/:fileId/download');

// SEARCH - ADVANCED SEARCH
export const ADVANCED_SEARCH_PAGE_PATH = new SweetPath('/search/advanced');
