/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-task-activity-get-action-body').UserTaskActivityGetActionBody} UserTaskActivityGetActionBody
 */

/**
 * @typedef {import('./user-task-activity-get-action-response').UserTaskActivityGetActionResponse} UserTaskActivityGetActionResponse
 */

/**
 * The path of the UserTaskActivityGetAction action.
 */
export const userTaskActivityGetActionPath = new SweetPath('/api/project/user_task_grouped_activities', {
  path: '{param}',
});

/**
 * Creates a contextualized UserTaskActivityGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserTaskActivityGetAction(axios) {
  /**
   * The userTaskActivityGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<UserTaskActivityGetActionResponse>}
   */
  function userTaskActivityGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = userTaskActivityGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return userTaskActivityGetAction;
}
