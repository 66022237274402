/** @typedef {import('components/files/upload/types').IFileError} IFileError */

import { FILE_UPLOAD_MAX_SIZE } from 'environment';
import { _t } from 'lang';
import striptags from 'striptags';
import formatFileSize from 'utils/format-file-size';

/**
 * Returns an error message text.
 *
 * @param {IFileError} error
 */
export default function getErrorMessage(error) {
  switch (error.reason) {
    case 'accept': {
      const text = _t('File %s is <b>not supported</b>.', error.fileName);
      const safeText = striptags(text, ['b']);

      return <span dangerouslySetInnerHTML={{ __html: safeText }} />;
    }

    case 'size': {
      const maxSize = formatFileSize(FILE_UPLOAD_MAX_SIZE, { maxDecimals: 0 });
      const weTransferLink = 'https://ohmydot.wetransfer.com/';

      const text = _t(
        'File %s <b>exceeds %s limit</b>. Try <a rel="external" target="_blank" href="%s">%s</a> instead.',
        error.fileName,
        maxSize,
        weTransferLink,
        weTransferLink
      );

      const safeText = striptags(text, ['b', 'a']);

      return <span dangerouslySetInnerHTML={{ __html: safeText }} />;
    }

    case 'maxFiles':
      return _t('File "%s" was not uploaded because the maximum number of files was reached.', error.fileName);

    default:
      return _t('Unknown error.');
  }
}
