/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['TextInput']} TextInputTheme */

import { ErrorStyles } from 'theme/components/Inputs/shared/Error';

/** @type {TextInputTheme} */
export const TextInput = {
  styles: {
    error: ErrorStyles,
  },
};
