/** @typedef {import('api/actions/user-task-activity-get-action/user-task-activity-get-action-response').UserTaskActivityGetActionResponse['tasks'][number]} Task */

import { useEffect, useMemo, useState } from 'react';
import { useApi } from '../../../api/ApiContext';
import { Grid, Text } from '@mantine/core';
import parseDuration from 'utils/parse-duration';
import { showNotification } from '@mantine/notifications';
import { _t } from '../../../lang';
import { SUCCESS_NOTIFICATION_COLOR } from '../../../utils/constants';
import WarningIcon from '../../icons/WarningIcon';
import DurationInput from '../../inputs/DurationInput';

/**
 * Handles the rendering of a single task entry column.
 * If the column is not active, it returns a red background grid column.
 * If the column is active, it renders a grid column with a duration input field.
 *
 * @param {Object} params - The parameters for the task entry column.
 * @param {Object} params.filters - The filters for the task entry column.
 * @param {number} [params.time] - The time value for the duration input field. Defaults to undefined.
 * @param {boolean} [params.active=true] - Determines if the column is active. Defaults to true.
 * @param {Task} params.task - The task object for the column.
 * @param {Date} params.date - The date for the column.
 * @param {Function} params.refresh - The refresh function for the column.
 * @returns {JSX.Element} - The rendered task entry column.
 */
export default function TaskEntryColumn({ filters, time = undefined, active = true, task, date, refresh }) {
  const [duration, setDuration] = useState(time);
  const { getAction, hasPermission } = useApi();
  const timeLogCreateFromDurationAction = getAction('TimeLogCreateFromDurationAction');
  const [error, setError] = useState(null);
  const actualDay = new Date();
  const isActualDay = actualDay.getDate() === date.getDate() && actualDay.getMonth() === date.getMonth();
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;

  const hasCreateOwnTimeLogPermission = useMemo(
    () => hasPermission('TIME_LOGS_CREATE_TIME_LOG', task.client.client_id),
    [task.client.client_id]
  );

  /**
   * Handles the change in duration.
   *
   * @param {number} duration - The new duration.
   * @param {number} [oldDuration=0] - The previous duration (default: 0).
   *
   * @returns {void}
   */
  const handleDurationChange = (duration, oldDuration = 0) => {
    if (!duration) {
      setDuration(0);
      return;
    }

    setError(false);

    const parsed = parseDuration(duration);

    if (parsed === oldDuration) {
      return;
    }

    if (parsed < oldDuration) {
      showNotification({
        title: _t('Time log cannot be less than the previous value'),
        color: 'red',
      });

      setError(
        <Text display="flex" fz={12} className="justify-end gap-x-1">
          <WarningIcon stroke={'#BF0D38'} height={16} width={16} /> Check time
        </Text>
      );

      return;
    }

    const newDuration = parsed - oldDuration;

    timeLogCreateFromDurationAction({
      parameters: {
        project_id: task?.project?.project_id,
        task_id: task?.task_id,
      },
      body: {
        duration: newDuration,
        date: date.toISOString().split('T')[0],
      },
    }).then(() => {
      showNotification({
        title: _t('Time logged successfully'),
        color: SUCCESS_NOTIFICATION_COLOR,
      });

      refresh();
    });

    setDuration(parsed);
  };

  /**
   * Handles the blur event of an input field.
   *
   * @param {number} value - The value of the input field.
   * @returns {void}
   */
  const handleInputBlur = (value) => {
    handleDurationChange(value, duration);
  };

  useEffect(() => {
    setDuration(time);
  }, [time]);

  if (!active) {
    return (
      <Grid.Col span={1} py={18} className={`bg-red bg-opacity-5 ${filters.showWeekends ? 'pl-2' : 'pl-8'}`}></Grid.Col>
    );
  }

  return (
    <Grid.Col
      span={1}
      className={`relative px-2 py-4
    ${isActualDay ? 'bg-main-secondary group-hover:bg-[#C0C3FF]' : ''}
    ${isWeekend ? 'border-b border-b-white bg-neutral-50 group-hover:bg-neutral-100' : ''}
    `}
      w="100%"
    >
      <DurationInput
        onChange={handleInputBlur}
        value={time}
        error={error}
        className="[&_input]:text-right"
        disabled={task.closed_at || task.deleted_at || !hasCreateOwnTimeLogPermission}
        readOnly={!hasCreateOwnTimeLogPermission}
      />
    </Grid.Col>
  );
}
