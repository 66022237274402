import { Tooltip } from '@mantine/core';
import { useRichTextEditorContext, RichTextEditor } from '@mantine/tiptap';
import IconItalic from 'components/inputs/wysiwyg/controls/italic/IconItalic';

/**
 * Used to make text italic.
 */
export default function ControlItalic() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Italic" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleItalic().run()}
        active={editor?.isActive('italic')}
      >
        <IconItalic />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
