/**
 * Scales a width and height to fit within a maximum width and height keeping
 * the aspect ratio.
 *
 * @param {{
 *   width: number;
 *   height: number;
 *   maxWidth: number;
 *   maxHeight: number;
 * }}
 */
export default function fitToBox({ width, height, maxWidth, maxHeight }) {
  if (width > maxWidth) {
    height = Math.round(height * (maxWidth / width));
    width = maxWidth;
  }

  if (height > maxHeight) {
    width = Math.round(width * (maxHeight / height));
    height = maxHeight;
  }

  return { width, height };
}
