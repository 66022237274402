import { Box, Stack, Tabs, Text, TextInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import FormRow from 'components/forms/FormRow';
import { _t } from 'lang';
import { required } from 'components/forms/validators/rules/rule-required';
import { emailFormat } from 'components/forms/validators/rules/rule-email-format';
import { createValidator } from 'components/forms/validators/create-validator';
import RoleSelect from 'components/selects/RoleSelect';
import TeamSelect from 'components/selects/TeamSelect';
import JobPositionSelect from 'components/selects/JobPositionSelect';
import MoneyInput from 'components/inputs/MoneyInput';
import { useApi } from 'api/ApiContext';
import LinkWithConfirm from 'components/navigate/LinkWithConfirm';
import { ADD_JOB_POSITION_PAGE_PATH } from 'routes/paths';
import PlusIcon from 'components/icons/PlusIcon';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';
import SectionTitle from '../FormSectionTitle';
import ClientCheckboxSelect from 'components/inputs/ClientCheckboxSelect';
import { useParams, useSearchParams } from 'react-router-dom';

/**
 * @param {'active'|'deactivated'|'not-initialized'} status
 */
function prettyInitStatus(status) {
  switch (status) {
    case 'active':
      return _t('Active');
    case 'deactivated':
      return _t('Deactivated');
    case 'not-initialized':
      return _t('Not initialized');
    default:
      return _t('Unknown status');
  }
}

/**
 * @typedef {{
 *   email: string;
 *   firstName: string;
 *   lastName: string;
 *   jobPositionId: string;
 *   roleId: string;
 *   teamId?: string;
 *   hourRate: number;
 *   memberOf: string[];
 *   initStatus: string;
 *   lastPasswordChange?: string;
 * }} UserFormData
 */

/**
 * The Add/Edit user form.
 *
 * @param {{
 *   initialValues: UserFormData,
 *   onSubmit: (values: UserFormData) => void | Promise<void>,
 *   emailError?: string,
 *   saveText?: string,
 * }}
 */
export default function UserForm({ initialValues, onSubmit, emailError, saveText = _t('Save') }) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const { workspaceConfig } = useApi();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'information';
  const { userId } = useParams();

  const form = useForm({
    initialValues,

    validate: {
      email: createValidator([required, emailFormat]),
      firstName: createValidator([required]),
      lastName: createValidator([required]),
      jobPositionId: createValidator([required]),
      roleId: createValidator([required]),
      hourRate: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Tabs defaultValue={activeTab}>
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="information">{_t('Information')}</Tabs.Tab>
          <Tabs.Tab value="client-membership">{_t('Client membership')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="information">
          <Stack spacing={16} pt={16}>
            {/* display only when editing the user */}
            {userId && (
              <>
                <SectionTitle title={_t('Status')} />

                <FormRow
                  label={_t('User status')}
                  input={
                    <Text fz={15} lh={18 / 15} c="#4D4D4D">
                      {prettyInitStatus(form.values.initStatus)}
                    </Text>
                  }
                />
                <FormRow
                  label={_t('Last password change')}
                  input={
                    <Text fz={15} lh={18 / 15} c="#4D4D4D">
                      {form.values.lastPasswordChange
                        ? new Date(form.values.lastPasswordChange).toLocaleString('sk-SK')
                        : _t('Not set')}
                    </Text>
                  }
                />
              </>
            )}

            <SectionTitle title={_t('Personal Information')} />
            <FormRow
              label={_t('Email')}
              input={
                <TextInput
                  type="email"
                  placeholder={_t('username@company.com')}
                  autoFocus
                  {...form.getInputProps('email')}
                  error={emailError}
                />
              }
            />
            <FormRow
              label={_t('First name')}
              input={<TextInput type="text" placeholder={_t('First name')} {...form.getInputProps('firstName')} />}
            />
            <FormRow
              label={_t('Last name')}
              input={<TextInput type="text" placeholder={_t('Last name')} {...form.getInputProps('lastName')} />}
            />

            <SectionTitle title={_t('Settings')} />
            <FormRow
              label={_t('Role')}
              input={<RoleSelect placeholder={_t('Role')} {...form.getInputProps('roleId')} />}
            />
            {workspaceConfig.features.teams && (
              <FormRow
                label={_t('Team')}
                input={<TeamSelect placeholder={_t('Team')} {...form.getInputProps('teamId')} />}
              />
            )}
            <FormRow
              label={_t('Job position')}
              input={<JobPositionSelect placeholder={_t('Job position')} {...form.getInputProps('jobPositionId')} />}
              leftAction={
                <Tooltip withArrow label={_t('Add job position')}>
                  <Box>
                    <LinkWithConfirm
                      confirmMessage={
                        _t('You will be redirected to the job position creation page. The data you have inputted will be lost. Are you sure you want to continue?') // prettier-ignore
                      }
                      confirmTitle={_t('Add job position')}
                      to={ADD_JOB_POSITION_PAGE_PATH.original}
                    >
                      <PlusIcon stroke="#38298B" />
                    </LinkWithConfirm>
                  </Box>
                </Tooltip>
              }
            />

            {workspaceConfig.features.internalCosts && (
              <>
                <SectionTitle title={_t('Finance')} />
                <FormRow
                  label={_t('Internal hour rate')}
                  input={<MoneyInput currency="EUR" min={0} {...form.getInputProps('hourRate')} />}
                />
              </>
            )}
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="client-membership">
          <Stack pt={16}>
            <ClientCheckboxSelect {...form.getInputProps('memberOf')} />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      <FormFooter loading={loading} saveText={saveText} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
