import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import GlobalSettingsForm from 'components/forms/global-settings-form/GlobalSettingsForm';
import { useApi } from 'api/ApiContext';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import panic from 'errors/Panic';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';

/**
 * Displays a form to edit the global company settings.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=316%3A11389
 */
export default function GlobalSettingsPage() {
  const { getAction } = useApi();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const workspaceConfigGetAction = getAction('WorkspaceConfigGetAction');

    setLoading(true);

    workspaceConfigGetAction()
      .then((data) => {
        const initialValues = {
          invoiceRequestEmail: data.invoice_request_email ? data.invoice_request_email : '',
          paymentRequestEmail: data.payment_request_email ? data.payment_request_email : '',
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction]);

  /**
   * Sends the request to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ invoiceRequestEmail, paymentRequestEmail }) => {
    const workspaceConfigUpdateAction = getAction('WorkspaceConfigUpdateAction');

    return workspaceConfigUpdateAction({
      body: {
        invoice_request_email: invoiceRequestEmail,
        payment_request_email: paymentRequestEmail,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Global settings updated'),
          message: _t('Global settings have been created successfully.'),
          color: SUCCESS_NOTIFICATION_COLOR,
        });
      })
      .catch((e) => panic(e));
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Global Settings')} />
        {!loading && <GlobalSettingsForm initialValues={initialValues} onSubmit={submit} />}
      </div>
    </DashboardLayout>
  );
}
