/** @typedef {import('../CostEstimateData').CostEstimateData} CostEstimateData */

import { Textarea } from '@mantine/core';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useData } from 'pages/finance/cost-estimate/add-cost-estimate/providers/DataProvider';
import FormRow from 'components/forms/FormRow';

/**
 * The description of a cost estimate.
 */
export default function CostEstimateDescription() {
  const { data, updateData } = useData();
  const [description, setDescription] = useState(data.description);

  // Debounce can take longer here because change in cost estimate description
  // does not cause changes in the price calculation.
  const [propagateChanges] = useState(() => debounce((description) => updateData({ description }), 300));

  // Propagate changes when the description changes.
  useEffect(() => {
    propagateChanges(description);
  }, [description]);

  return (
    <FormRow
      label={_t('Scope description')}
      input={
        <Textarea
          autosize
          minRows={5}
          placeholder={_t('Scope description')}
          value={description}
          onChange={({ target: { value: description } }) => setDescription(description)}
        />
      }
    />
  );
}
