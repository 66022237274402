import { Button, Group } from '@mantine/core';
import LinkWithConfirm from 'components/navigate/LinkWithConfirm';
import { _t } from 'lang';

/**
 * Contains action buttons for the form.
 *
 * @param {{
 *   saveText?: string,
 *   cancelText?: string,
 *   skipCancelConfirmation?: boolean | (() => boolean),
 *   cancelConfirmation?: string,
 *   loading?: boolean,
 * }}
 */
export default function FormFooter({
  saveText = _t('Save'),
  cancelText = _t('Cancel'),
  skipCancelConfirmation = false,
  cancelConfirmation = _t('Are you sure you want to cancel? The data you have inputted will be lost.'),
  loading = false,
} = {}) {
  return (
    <Group position="right" py={16} spacing={16}>
      <Button
        component={LinkWithConfirm}
        skipConfirm={skipCancelConfirmation}
        to={-1}
        confirmMessage={cancelConfirmation}
        confirmTitle={_t('Cancel form submission')}
        type="button"
        variant="link"
        w={76}
        h={36}
        disabled={loading}
      >
        {cancelText}
      </Button>
      <Button type="submit" variant="primary" loading={loading}>
        {saveText}
      </Button>
    </Group>
  );
}
