import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconAlignCenter from 'components/inputs/wysiwyg/controls/align-center/IconAlignCenter';

/**
 * Used to align text to the middle.
 */
export default function ControlAlignCenter() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Align center" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().setTextAlign('center').run()}
        active={editor?.isActive({ textAlign: 'center' })}
      >
        <IconAlignCenter />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
