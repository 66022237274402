/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./project-delete-action-body').ProjectDeleteActionBody} ProjectDeleteActionBody
 */

/**
 * @typedef {import('./project-delete-action-response').ProjectDeleteActionResponse} ProjectDeleteActionResponse
 */

/**
 * The path of the ProjectDeleteAction action.
 */
export const projectDeleteActionPath = new SweetPath('/api/project/project/{project_id}/delete', { path: '{param}' });

/**
 * Creates a contextualized ProjectDeleteAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createProjectDeleteAction(axios) {
  /**
   * The projectDeleteAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ProjectDeleteActionBody } }
   *
   * @returns {Promise<ProjectDeleteActionResponse>}
   */
  function projectDeleteAction({ headers = {}, parameters = {}, body } = {}) {
    const path = projectDeleteActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return projectDeleteAction;
}
