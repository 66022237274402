import { useApi } from 'api/ApiContext';
import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { NOT_ALLOWED_PAGE_PATH } from 'routes/paths';

/**
 * Requires the user to have the given permissions.
 *
 * @param {{
 *  permission?: string;
 *  oneOfPermissions?: string[];
 *  allOfPermissions?: string[];
 *  redirect?: string;
 *  clientId?: '*' | number;
 * }} props
 */
export default function RequirePermission({
  permission,
  oneOfPermissions,
  allOfPermissions,
  redirect = NOT_ALLOWED_PAGE_PATH.original,
  clientId = null,
}) {
  const { permissions, hasPermission } = useApi();

  const hasAccess = useMemo(() => {
    if (permission && !hasPermission(permission, clientId)) {
      return false;
    }

    if (oneOfPermissions && !oneOfPermissions.some((p) => hasPermission(p, clientId))) {
      return false;
    }

    if (allOfPermissions && !allOfPermissions.every((p) => hasPermission(p, clientId))) {
      return false;
    }

    return true;
  }, [permission, oneOfPermissions, allOfPermissions, permissions]);

  if (!hasAccess) {
    return <Navigate to={redirect} />;
  }

  return <Outlet />;
}
