import ResetPasswordContent from 'pages/login/reset-password/ResetPasswordContent';
import SimpleLayout from 'layouts/simple-layout/SimpleLayout';

/**
 * Displays the reset password form.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=1687%3A42621
 */
export default function ResetPasswordPage() {
  return <SimpleLayout content={<ResetPasswordContent />} />;
}
