/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./client-create-action-body').ClientCreateActionBody} ClientCreateActionBody
 */

/**
 * @typedef {import('./client-create-action-response').ClientCreateActionResponse} ClientCreateActionResponse
 */

/**
 * The path of the ClientCreateAction action.
 */
export const clientCreateActionPath = new SweetPath('/api/project/client', { path: '{param}' });

/**
 * Creates a contextualized ClientCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createClientCreateAction(axios) {
  /**
   * The clientCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ClientCreateActionBody } }
   *
   * @returns {Promise<ClientCreateActionResponse>}
   */
  function clientCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = clientCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return clientCreateAction;
}
