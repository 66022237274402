/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-get-subtasks-action-body').TaskGetSubtasksActionBody} TaskGetSubtasksActionBody
 */

/**
 * @typedef {import('./task-get-subtasks-action-response').TaskGetSubtasksActionResponse} TaskGetSubtasksActionResponse
 */

/**
 * The path of the TaskGetSubtasksAction action.
 */
export const taskGetSubtasksActionPath = new SweetPath('/api/project/project/task/{task_id}/subtasks', {
  path: '{param}',
});

/**
 * Creates a contextualized TaskGetSubtasksAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskGetSubtasksAction(axios) {
  /**
   * The taskGetSubtasksAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<TaskGetSubtasksActionResponse>}
   */
  function taskGetSubtasksAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = taskGetSubtasksActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return taskGetSubtasksAction;
}
