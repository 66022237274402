/** @typedef {import('api/actions/task-get-action/task-get-action-response').TaskGetActionResponse} Task */
/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */

import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { useTask } from 'providers/task/TaskProvider';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import sleep from 'utils/sleep';

/**
 * @type {React.Context<{
 *   loading: boolean,
 *   project: Project | null,
 *   task: Task | null,
 *   taskId: number | null,
 *   isSubtask: boolean,
 *   taskAttachments: { fileId: number, isComment: boolean }[],
 *   attachmentBeingDeleted: number | null,
 *   sidebarRefreshToken: number,
 *   refreshSidebar: () => void,
 *   refresh: () => Promise<void>,
 *   canDeleteAttachment: (fileId: number) => boolean,
 *   addAttachment: (fileId: number) => void,
 *   deleteAttachment: (fileId: number) => void,
 *   deleteTask: () => void,
 *   closeTask: () => void,
 *   reopenTask: () => void,
 *   restoreTask: () => void,
 * }>}
 */
const TaskDetailContext = createContext(undefined);

/**
 * Provides the context for the task detail page.
 */
export function TaskDetailProvider({ children }) {
  const { getAction, hasPermission } = useApi();

  const {
    loading,
    project,
    task,
    taskId,
    refresh: refreshImpl,
    deleteTask: deleteTaskImpl,
    closeTask: closeTaskImpl,
    reopenTask: reopenTaskImpl,
    restoreTask: restoreTaskImpl,
  } = useTask();

  const [attachmentBeingDeleted, setAttachmentBeingDeleted] = useState(null);
  const [sidebarRefreshToken, setSidebarRefreshToken] = useState(0);

  const refreshSidebar = useCallback(() => setSidebarRefreshToken((token) => token + 1), []);
  const refresh = useCallback(() => Promise.all([refreshImpl(), refreshSidebar()]), [refreshImpl, refreshSidebar]);

  const taskAttachments = useMemo(
    () => (task?.attachments ?? []).map(({ file_id, is_comment }) => ({ fileId: file_id, isComment: is_comment })),
    [task]
  );

  /**
   * Whether an attachment can be deleted.
   */
  const canDeleteAttachment = useCallback(
    (fileId) =>
      project &&
      !taskAttachments.find(({ fileId: id }) => id === fileId)?.isComment &&
      hasPermission('PROJECTS_MANAGE_PROJECT', project.client.client_id),
    [taskAttachments, project]
  );

  /**
   * Adds an attachment to the task.
   */
  const addAttachment = useCallback(
    (fileId) => {
      const taskAddAttachmentAction = getAction('TaskAddAttachmentAction');

      taskAddAttachmentAction({
        parameters: { task_id: task.task_id },
        body: { file_id: fileId },
      })
        .then(refresh)
        .catch(panic);
    },
    [task, getAction, refresh]
  );

  /**
   * Deletes an attachment from the task.
   */
  const deleteAttachment = useCallback(
    (fileId) => {
      const taskDeleteAttachmentAction = getAction('TaskDeleteAttachmentAction');

      setAttachmentBeingDeleted(fileId);

      const deleteAttachment = taskDeleteAttachmentAction({
        parameters: { task_id: task.task_id },
        body: { file_id: fileId },
      });

      // Wait for at least 600ms to make the UI feel more responsive.
      Promise.all([deleteAttachment, sleep(600)])
        .then(refresh)
        .catch(panic);
    },
    [task, getAction]
  );

  /**
   * Deletes the task.
   */
  const deleteTask = useCallback(() => {
    deleteTaskImpl();
    refreshSidebar();
  }, [taskId, deleteTaskImpl]);

  /**
   * Closes the task.
   */
  const closeTask = useCallback(() => {
    closeTaskImpl();
    refreshSidebar();
  }, [taskId, closeTaskImpl]);

  /**
   * Reopens the task.
   */
  const reopenTask = useCallback(() => {
    reopenTaskImpl();
    refreshSidebar();
  }, [taskId, reopenTaskImpl]);

  /**
   * Restores the task.
   */
  const restoreTask = useCallback(() => {
    restoreTaskImpl();
    refreshSidebar();
  }, [taskId, restoreTaskImpl]);

  const isSubtask = task?.parent_task_id !== null;

  const value = useMemo(
    () => ({
      loading,
      project,
      task,
      taskId,
      isSubtask,
      taskAttachments,
      attachmentBeingDeleted,
      sidebarRefreshToken,
      refreshSidebar,
      refresh,
      canDeleteAttachment,
      addAttachment,
      deleteAttachment,
      deleteTask,
      closeTask,
      reopenTask,
      restoreTask,
    }),
    [
      loading,
      project,
      task,
      taskId,
      isSubtask,
      taskAttachments,
      attachmentBeingDeleted,
      sidebarRefreshToken,
      refreshSidebar,
      refresh,
      canDeleteAttachment,
      addAttachment,
      deleteAttachment,
      deleteTask,
      closeTask,
      reopenTask,
      restoreTask,
    ]
  );

  return <TaskDetailContext.Provider value={value}>{children}</TaskDetailContext.Provider>;
}

/**
 * Uses the task detail context.
 */
export function useTaskDetail() {
  return useContext(TaskDetailContext);
}
