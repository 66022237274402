/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-create-action-body').CommentCreateActionBody} CommentCreateActionBody
 */

/**
 * @typedef {import('./comment-create-action-response').CommentCreateActionResponse} CommentCreateActionResponse
 */

/**
 * The path of the CommentCreateAction action.
 */
export const commentCreateActionPath = new SweetPath('/api/comment/comment/{comment_thread_id}', { path: '{param}' });

/**
 * Creates a contextualized CommentCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentCreateAction(axios) {
  /**
   * The commentCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CommentCreateActionBody } }
   *
   * @returns {Promise<CommentCreateActionResponse>}
   */
  function commentCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = commentCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return commentCreateAction;
}
