/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "LogTime" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function LogTimeIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Top_navigation/log_time">
        <path
          id="Vector"
          d="M12 3.5C16.5 3.5 20.5 7 20.5 12C20.5 16.5 16.7727 20.5 12 20.5C7.22726 20.5 3.5 16.6487 3.5 12C3.5 10.9391 3.6473 9.9392 4 9.00005M16.5 9.00005L12 13L8.99999 10.5M3.22726 4.5L6.5 4.5M6.5 4.5L9.77271 4.5M6.5 4.5L6.5 7.74325M6.5 4.5V1.25676"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
