/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { _t } from 'lang';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import panic from 'errors/Panic';
import { showNotification } from '@mantine/notifications';
import ExternalCostsForm from 'components/forms/external-costs/ExternalCostsForm';
import { Box } from '@mantine/core';

/**
 * The contents of the add external cost page.
 *
 * @param {{
 *   costEstimate: CostEstimate;
 * }}
 */
export default function AddExternalCostsPageContent({ costEstimate }) {
  const navigate = useNavigate();

  const { getAction, userId } = useApi();
  const externalCostCreateAction = getAction('ExternalCostCreateAction');

  const detailPath = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId: costEstimate.cost_estimate_id });
  const detailTab = `${detailPath}?tab=external-cost`;

  const initialValues = {
    supplierId: '',
    fileId: undefined,
    invoiceNumber: '',
    invoiceDate: '',
    supplyDate: null,
    paymentDueDate: null,
    currency: costEstimate.currency,
    totalPaidAmountWithoutVat: null,
    calculatedExpense: null,
    baseAmount: 0,
    currencyRate: 1,
    currencyRateDate: '',
    invoiceSubject: '',
    internalNote: '',
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({
    supplierId,
    fileId,
    invoiceNumber,
    invoiceDate,
    supplyDate,
    paymentDueDate,
    currency,
    totalPaidAmountWithoutVat,
    baseAmount,
    currencyRate,
    currencyRateDate,
    invoiceSubject,
    internalNote,
  }) => {
    return externalCostCreateAction({
      body: {
        author_id: String(userId),
        cost_estimate_id: Number(costEstimate.cost_estimate_id),
        supplier_id: supplierId,
        file_id: fileId[0].fileId,
        invoice_number: invoiceNumber,
        invoice_issued: invoiceDate ? new Date(invoiceDate).toLocaleDateString() : null,
        invoice_supplied: supplyDate ? new Date(supplyDate).toLocaleDateString() : null,
        invoice_due_date: paymentDueDate ? new Date(paymentDueDate).toLocaleDateString() : null,
        currency: currency,
        amount: totalPaidAmountWithoutVat,
        base_amount: baseAmount,
        currency_rate: currencyRate,
        currency_rate_date: currencyRateDate,
        invoice_subject: invoiceSubject,
        note: internalNote,
      },
    })
      .then(({ external_cost_id }) => {
        showNotification({
          title: _t('External Cost added'),
          message: _t('External cost has been created successfully.'),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        const detailTabWithExternalCost = `${detailTab}&externalCostId=${external_cost_id}`;

        navigate(detailTabWithExternalCost, { replace: true });
      })
      .catch((e) => {
        panic(e.message);
      });
  };

  if (costEstimate.closed_at) {
    return <Navigate to={detailTab} replace />;
  }

  return (
    <Box mt={16}>
      <ExternalCostsForm onSubmit={submit} initialValues={initialValues} costEstimate={costEstimate} />
    </Box>
  );
}
