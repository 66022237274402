import SetPasswordContent from 'pages/login/set-password/SetPasswordContent';
import SimpleLayout from 'layouts/simple-layout/SimpleLayout';

/**
 * The page where the user can set the password.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=1687%3A42535
 */
export default function SetPasswordPage() {
  return <SimpleLayout content={<SetPasswordContent />} />;
}
