import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';

// TODO: code review

/**
 * Select input for tasks.
 *
 * @param {{
 *     projectId: number;
 *     showDeleted?: boolean;
 *     showClosed?: boolean
 *     filterOutTasks?: number[];
 *   } &
 *   import('react').RefAttributes<HTMLInputElement> &
 *   import('@mantine/core').SelectProps
 * }
 */
export default function TaskSelect({
  projectId,
  showDeleted = false,
  showClosed = false,
  filterOutTasks = [],
  ...props
}) {
  const { getAction } = useApi();
  const key = useMemo(nanoid, [projectId, showDeleted, showClosed]);

  const fetchTasks = useCallback(async () => {
    if (!projectId) {
      return [];
    }

    // workaround so that the select is not disabled when projectId is -1
    // we want to show the single option without calling the API
    if (projectId === -1) {
      return [];
    }

    const taskGetListAction = getAction('TaskGetListAction');
    const taskGetSubtasksAction = getAction('TaskGetSubtasksAction');

    const tasks = await taskGetListAction({ parameters: { project_id: projectId } });

    const res = [];

    for (const task of tasks) {
      const isDeleted = task.deleted_at !== null;
      const isClosed = task.closed_at !== null;

      const subtasks = await taskGetSubtasksAction({ parameters: { task_id: task.task_id } });
      const hasNoSubtasks = task.parent_task_id === null && subtasks.length === 0;

      const shouldBeFiltered = filterOutTasks.includes(task.task_id);

      const isFilteredOut = hasNoSubtasks && shouldBeFiltered;

      if ((isDeleted && !showDeleted) || (isClosed && !showClosed) || isFilteredOut) {
        continue;
      }

      res.push({
        label: task.task_full_name,
        value: String(task.task_id),
        disabled: shouldBeFiltered,
      });

      for (const subtask of subtasks) {
        const isDeleted = subtask.deleted_at !== null;
        const isClosed = subtask.closed_at !== null;
        const isExcluded = filterOutTasks.includes(subtask.task_id);

        if ((isDeleted && !showDeleted) || (isClosed && !showClosed) || isExcluded) {
          continue;
        }

        res.push({ label: ` -- ${subtask.task_full_name}`, value: String(subtask.task_id) });
      }
    }

    return res;
  }, [getAction, projectId, showClosed, showDeleted]);

  return (
    <DataSelect
      {...props}
      key={key}
      disabled={props.disabled ?? !projectId}
      action={fetchTasks}
      labelProperty="label"
      valueProperty="value"
    />
  );
}
