/** @typedef {import('api/actions/supplier-get-list-action/supplier-get-list-action-response').SupplierGetListActionResponse[number]} Supplier */

import { _t } from 'lang';
import DataTable from 'components/tables/DataTable';
import SupplierStatusSwitch from 'components/inputs/status-switch/SupplierStatusSwitch';
import { useApi } from 'api/ApiContext';
import { EDIT_SUPPLIER_PAGE_PATH } from 'routes/paths';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

/**
 * A row in the Suppliers table.
 *
 * @param {{
 *   data: Supplier;
 *   hover: boolean;
 * }}
 */
function SupplierTableRow({ data: supplier, hover }) {
  return (
    <>
      <Link to={EDIT_SUPPLIER_PAGE_PATH.insert({ supplierId: supplier.supplier_id })} className="block w-fit">
        <span className="flex items-center transition-all hover:text-main-primary">
          <span className="text-[20px] leading-[20px]">{supplier.supplier_name}</span>
          {hover && <small className="ml-[10px] text-[12px] text-grey-500">(ID: {supplier.supplier_id})</small>}
        </span>
      </Link>
      <div className="text-[14px] leading-[20px]">
        <span>
          {supplier.language}
          <br />
          {supplier.currency}
        </span>
      </div>
      <SupplierStatusSwitch
        supplierId={supplier.supplier_id}
        name={supplier.supplier_name}
        isActive={supplier.is_active}
      />
    </>
  );
}

/**
 * The table in the Suppliers page.
 */
export default function SupplierTable() {
  const { getAction } = useApi();
  const supplierGetListAction = getAction('SupplierGetListAction');

  const columns = useMemo(
    () => [
      { title: _t('Supplier name'), width: 3 },
      { title: _t('Language / Currency'), width: 2 },
      { title: _t('Status'), width: 1 },
    ],
    []
  );

  return (
    <DataTable
      action={supplierGetListAction}
      columns={columns}
      rowKey={(supplier) => supplier.supplier_id}
      editLink={(supplier) => EDIT_SUPPLIER_PAGE_PATH.insert({ supplierId: supplier.supplier_id })}
      RowComponent={SupplierTableRow}
    />
  );
}
