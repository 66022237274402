/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */

import { Box, Menu } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import AddIcon from 'components/icons/AddIcon';
import ConfirmIcon from 'components/icons/ConfirmIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import ReplyIcon from 'components/icons/ReplyIcon';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ADD_TASK_PAGE_PATH, EDIT_PROJECT_PAGE_PATH } from 'routes/paths';

/**
 * Actions for a project.
 *
 * @param {{
 *   project: Project;
 *   onMenuChange?: (opened: boolean) => void;
 *   onProjectDelete?: () => void;
 *   onProjectClose?: () => void;
 *   onProjectReopen?: () => void;
 *   onProjectRestore?: () => void;
 * }}
 */
export default function ProjectActions({
  project: {
    project_id: projectId,
    closed_at: closedAt,
    deleted_at: deletedAt,
    client: { client_id: clientId },
  },
  onMenuChange = noop,
  onProjectDelete = noop,
  onProjectClose = noop,
  onProjectReopen = noop,
  onProjectRestore = noop,
}) {
  const { getAction, hasPermission } = useApi();
  const { confirm } = useConfirm();
  const editLink = useMemo(() => EDIT_PROJECT_PAGE_PATH.insert({ projectId }), [projectId]);
  const hasProjectManagePermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );
  const hasTaskCreatePermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );

  /**
   * Deletes the project.
   */
  const deleteProject = useCallback(async () => {
    const canCloseProjectAction = getAction('ProjectCanBeClosedAction');
    const { result: canClose } = await canCloseProjectAction({ parameters: { project_id: projectId } }).catch(panic);

    if (!canClose) {
      confirm({
        title: _t('Cannot delete project with open tasks'),
        message: _t('This project has open tasks. Close them to delete this project.'),
        primaryButtonText: _t('Ok'),
        hideSecondaryButton: true,
      });
    } else {
      confirm({
        title: _t('Delete project'),
        message: _t('The project will be deleted. Do you want to proceed?'),
        onConfirm: () => {
          const deleteProjectAction = getAction('ProjectDeleteAction');

          return deleteProjectAction({ parameters: { project_id: projectId } })
            .then(onProjectDelete)
            .catch(panic);
        },
      });
    }
  }, [projectId, confirm, getAction]);

  /**
   * Closes the project.
   */
  const closeProject = useCallback(async () => {
    const canCloseProjectAction = getAction('ProjectCanBeClosedAction');
    const { result: canClose } = await canCloseProjectAction({ parameters: { project_id: projectId } }).catch(panic);

    if (!canClose) {
      confirm({
        title: _t('Cannot close project with open tasks'),
        message: _t('This project has open tasks. Close them to close this project.'),
        primaryButtonText: _t('Ok'),
        hideSecondaryButton: true,
      });
    } else {
      confirm({
        title: _t('Close project'),
        message: _t('The project will be closed. Do you want to proceed?'), // prettier-ignore
        onConfirm: () => {
          const closeProjectAction = getAction('ProjectCloseAction');

          return closeProjectAction({ parameters: { project_id: projectId } })
            .then(onProjectClose)
            .catch(panic);
        },
      });
    }
  }, [projectId, confirm, getAction]);

  /**
   * Reopens the project.
   */
  const reopenProject = useCallback(() => {
    confirm({
      title: _t('Reopen project'),
      message: _t('The project will be reopened. Do you want to proceed?'),
      onConfirm: () => {
        const reopenProjectAction = getAction('ProjectReopenAction');

        return reopenProjectAction({ parameters: { project_id: projectId } })
          .then(onProjectReopen)
          .catch(panic);
      },
    });
  }, [projectId, confirm, getAction]);

  /**
   * Restores the project.
   */
  const restoreProject = useCallback(() => {
    confirm({
      title: _t('Restore project'),
      message: _t('The project will be restored. Do you want to proceed?'),
      onConfirm: () => {
        const restoreProjectAction = getAction('ProjectRestoreAction');

        return restoreProjectAction({ parameters: { project_id: projectId } })
          .then(onProjectRestore)
          .catch(panic);
      },
    });
  }, [projectId, confirm, getAction]);

  const isClosed = !!closedAt;
  const isDeleted = !!deletedAt;

  const canEdit = !isDeleted && hasProjectManagePermission;
  const canAddTask = !isClosed && !isDeleted && hasTaskCreatePermission;

  const canClose = !isClosed && !isDeleted && hasProjectManagePermission;
  const canReopen = isClosed && !isDeleted && hasProjectManagePermission;

  const canDelete = !isDeleted && hasProjectManagePermission;
  const canRestore = isDeleted && hasProjectManagePermission;

  const hasActions = canEdit || canAddTask || canClose || canReopen || canDelete || canRestore;

  return hasActions ? (
    <Menu withinPortal position="bottom-end" offset={15} onChange={onMenuChange}>
      <Menu.Target>
        <div className="cursor-pointer pb-[7px] pt-[7px]">
          <OptionsDotsIcon />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {canEdit && (
          <Menu.Item component={Link} to={editLink} icon={<EditIcon fill="#4D4D4D" />}>
            {_t('Edit')}
          </Menu.Item>
        )}
        {canAddTask && (
          <Menu.Item component={Link} to={ADD_TASK_PAGE_PATH.insert({ projectId })} icon={<AddIcon stroke="#4D4D4D" />}>
            {_t('Add task')}
          </Menu.Item>
        )}
        {canClose && (
          <Menu.Item onClick={closeProject} icon={<ConfirmIcon stroke="#4D4D4D" />}>
            {_t('Close')}
          </Menu.Item>
        )}
        {canReopen && (
          <Menu.Item onClick={reopenProject} icon={<ReplyIcon stroke="#4D4D4D" />}>
            {_t('Reopen')}
          </Menu.Item>
        )}
        {canRestore && (
          <Menu.Item onClick={restoreProject} icon={<ReplyIcon stroke="#4D4D4D" />}>
            {_t('Restore project')}
          </Menu.Item>
        )}
        {canDelete && (
          <>
            <Menu.Divider />
            <Menu.Item onClick={deleteProject} icon={<DeleteIcon stroke="#BF0D38" />}>
              <Box className="text-red">{_t('Delete project')}</Box>
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  ) : (
    <></>
  );
}
