/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-update-duration-action-body').TimeLogUpdateDurationActionBody} TimeLogUpdateDurationActionBody
 */

/**
 * @typedef {import('./time-log-update-duration-action-response').TimeLogUpdateDurationActionResponse} TimeLogUpdateDurationActionResponse
 */

/**
 * The path of the TimeLogUpdateDurationAction action.
 */
export const timeLogUpdateDurationActionPath = new SweetPath('/api/time_log/update_duration/{time_log_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized TimeLogUpdateDurationAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogUpdateDurationAction(axios) {
  /**
   * The timeLogUpdateDurationAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TimeLogUpdateDurationActionBody } }
   *
   * @returns {Promise<TimeLogUpdateDurationActionResponse>}
   */
  function timeLogUpdateDurationAction({ headers = {}, parameters = {}, body } = {}) {
    const path = timeLogUpdateDurationActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return timeLogUpdateDurationAction;
}
