/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */

import { ErrorStyles } from 'theme/components/Inputs/shared/Error';

export const TimeInput = {
  styles: {
    input: {
      height: '36px',
      paddingTop: '1px',
      paddingBottom: 0,
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    label: {
      fontWeight: 400,
    },
    error: ErrorStyles,
  },
};
