import ChartTimelog from 'components/charts/ChartTimelog';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { useCallback, useRef, useState } from 'react';
import { Breadcrumbs, Button, Menu, Stack } from '@mantine/core';
import TimeEntriesTable from 'components/tables/time-entries/TimeEntriesTable';
import TimeEntriesFilters from 'components/tables/time-entries/TimeEntriesFilters';
import PageHeading from '../../../layouts/dashboard-layout/headers/PageHeading';
import { useTimeLog } from '../time-log/TimeLogProvider';
import LogTimeIcon from '../../../components/icons/LogTimeIcon';
import FiletypeXlsIcon from '../../../components/icons/FiletypeXlsIcon';
import { exportFile } from '../export/export-file';
import { useApi } from 'api/ApiContext';
import { endOfMonth, startOfMonth } from 'date-fns';

/**
 * Displays a page with an overview of logged time.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=341%3A21507
 */
export default function TimeEntriesOverviewPage() {
  const { getAction, userId } = useApi();
  const chartRef = useRef(null);
  const timeEntriesTableRef = useRef(null);
  const [timeEntriesFilters, setTimeEntriesFilters] = useState(() => ({ showWeekends: false, orderBy: 'date' }));
  const { openTimeLogModal } = useTimeLog();
  const [start, setStart] = useState(() => startOfMonth(new Date()));
  const [end, setEnd] = useState(() => endOfMonth(new Date()));

  const refreshAll = useCallback(() => {
    chartRef.current?.refresh();
    timeEntriesTableRef.current?.refresh();
  }, []);

  /**
   * Retrieves the export from the API.
   * The export is in the CSV format.
   */
  async function handleExport() {
    const timeLogExportAction = getAction('TimeLogExportAction');

    // TODO what range should be here?
    // the displayed month or the displayed week?
    const filter = {
      'start.gte': start.toISOString(),
      'end.lte': end.toISOString(),
      'user_id.eq': userId,
      // TODO rest of the filters
    };

    const { file_contents: contents } = await timeLogExportAction({ query: { filter } });

    exportFile({ contents, fileName: 'time-entries.xlsx' });
  }

  return (
    <DashboardLayout contentClass="bg-neutral-50 px-8 pb-8">
      <PageHeading
        heading={_t('My Timesheets')}
        breadcrumbs={
          <Breadcrumbs>
            <span>{_t('Timesheets')}</span>
          </Breadcrumbs>
        }
        actionButton={
          <Button
            leftIcon={<LogTimeIcon />}
            variant="primary"
            onClick={() => openTimeLogModal({ onTimeLogged: refreshAll })}
          >
            {_t('Log Time')}
          </Button>
        }
        menuDropdown={
          <Menu.Dropdown>
            <Menu.Item onClick={handleExport} icon={<FiletypeXlsIcon fill="#4D4D4D" />}>
              {_t('Export to XLSX')}
            </Menu.Item>
          </Menu.Dropdown>
        }
      />
      <Stack spacing={24}>
        <ChartTimelog ref={chartRef} start={start} setStart={setStart} end={end} setEnd={setEnd} />
        <TimeEntriesFilters
          timeEntriesFilters={timeEntriesFilters}
          onChange={setTimeEntriesFilters}
          onWeekChange={(value) => {
            if (value === 'prev') {
              timeEntriesTableRef.current?.prevWeek();
            } else if (value === 'next') {
              timeEntriesTableRef.current?.nextWeek();
            } else {
              timeEntriesTableRef.current?.resetWeek();
            }
          }}
        />
        <TimeEntriesTable
          filters={timeEntriesFilters}
          onChange={() => chartRef.current?.refresh()}
          ref={timeEntriesTableRef}
        />
      </Stack>
    </DashboardLayout>
  );
}
