/** @typedef {import('components/files/upload/types').IFile} IFile */
/** @typedef {import('components/files/upload/types').IFileState} IFileState */
/** @typedef {import('components/files/upload/types').IFileError} IFileError */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import panic from 'errors/Panic';
import toBase64 from 'utils/to-base64';
import { noop } from 'lodash';
import UploadFilesList from 'components/files/upload/UploadFilesList';
import DropFiles from 'components/files/upload/DropFiles';
import { Stack } from '@mantine/core';
import { FILE_UPLOAD_CHUNK_SIZE } from 'environment';

/**
 * Upload files component.
 *
 * @param {{
 *   accept?: string[],
 *   maxFiles?: number,
 *   onChange?: (state: IFileState[] | undefined) => void,
 *   onFileUploaded?: (fileId: string) => void,
 *   error?: string,
 *   initialFiles?: IFileState[],
 *   initialErrors?: IFileError[],
 *   isPublic?: boolean,
 *   loading?: boolean,
 *   placeholderCount?: number,
 *   errorAutoClose?: number,
 *   hideUploadedFiles?: boolean,
 * }}
 */
export default function UploadFiles({
  accept = ['*'],
  maxFiles = Infinity,
  onChange = noop,
  onFileUploaded = noop,
  error,
  initialFiles = [],
  initialErrors = [],
  isPublic = false,
  loading = false,
  placeholderCount = 0,
  errorAutoClose = 10000,
  hideUploadedFiles = false,
} = {}) {
  const [files, setFiles] = useState(initialFiles);
  const finalizedFiles = useMemo(() => files.filter(({ finalized }) => finalized), [files]);
  const { initLargeFile, uploadLargeFile, finalizeLargeFile } = useFileManager();

  /**
   * Performs a partial update on one of the files.
   *
   * @param {string} uuid
   * @param {Partial<IFileState>} partial
   */
  const updateFile = useCallback(
    (uuid, partial) => setFiles((files) => files.map((file) => (file.uuid === uuid ? { ...file, ...partial } : file))),
    [setFiles]
  );

  /**
   * Deletes the file from the list.
   *
   * @param {string} uuid
   */
  const deleteFile = useCallback((uuid) => setFiles((files) => files.filter((file) => file.uuid !== uuid)), [setFiles]);

  /**
   * Appends files to the list.
   *
   * @param {IFileState[]} files
   */
  const addFiles = useCallback((files) => setFiles((curr) => [...curr, ...files]), [setFiles]);

  /**
   * Handles file acceptance by file drop.
   *
   * @param {IFile[]} files
   */
  const onAccept = async (files) => {
    addFiles(
      files.map(({ uuid, file }) => ({
        uuid,
        progress: 0,
        finalized: false,
        fileName: file.name,
        fileType: file.type,
      }))
    );

    // Upload valid files.
    for (const { uuid, file } of files) {
      try {
        const fileId = await initLargeFile({ fileName: file.name, isPublic });

        updateFile(uuid, { fileId });

        let offset = 0;
        while (offset < file.size) {
          const chunk = file.slice(offset, offset + FILE_UPLOAD_CHUNK_SIZE);
          const contents = await toBase64(chunk);

          await uploadLargeFile({ fileId, contents });

          offset += chunk.size;

          updateFile(uuid, { progress: offset / file.size });
        }

        await finalizeLargeFile({ fileId });

        updateFile(uuid, { progress: 1, finalized: true });
        onFileUploaded(fileId);
      } catch (error) {
        panic(error);
      }
    }
  };

  // Propagate changes to parent component.
  useEffect(() => {
    onChange(finalizedFiles.length > 0 ? finalizedFiles : undefined);
  }, [finalizedFiles]);

  return (
    <Stack spacing={16} maw={500}>
      <DropFiles
        accept={accept}
        onAccept={onAccept}
        maxFiles={maxFiles - files.length}
        disabled={files.length >= maxFiles}
        error={error}
        withErrorList
        errorAutoClose={errorAutoClose}
        initialErrors={initialErrors}
      />
      <UploadFilesList
        files={files}
        onDelete={deleteFile}
        loading={loading}
        placeholderCount={placeholderCount}
        hideUploadedFiles={hideUploadedFiles}
      />
    </Stack>
  );
}
