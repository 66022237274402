/**
 * Map from color name to Tailwind CSS class.
 */
const bgColors = {
  Draft: 'bg-status-grey',
  Yellow: 'bg-status-yellow',
  Orange: 'bg-status-orange',
  Red: 'bg-status-red',
  New: 'bg-status-green',
  Closed: 'bg-status-green', // NOTE this is a duplicate but that's the way it is in the design
  Blue: 'bg-status-blue',
};

/**
 * Finance status bubble.
 *
 * @param {{
 *   status: string,
 *   color?: keyof typeof bgColors,
 *   variant?: 'small' | 'default',
 * }}
 */
export default function FinanceStatusBubble({ status, color, variant = 'default' }) {
  const bgColor = bgColors[color] || 'bg-status-green';
  const styles = variant === 'small' ? 'py-1 text-[12px] leading-[16px]' : 'py-[3px] text-[15px] leading-[18px]';
  const bubbleHeight = variant === 'small' ? 'h-2' : 'h-3';
  const bubbleWidth = variant === 'small' ? 'w-2' : 'w-3';

  return (
    <div className={`flex h-6 flex-row items-center gap-1 text-neutral-700 ${styles}`}>
      <div className={`rounded-full ${bubbleHeight} ${bubbleWidth} ${bgColor}`}></div>
      <span>{status}</span>
    </div>
  );
}
