import PriceListTable from 'components/tables/PriceListTable';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import DashboardPageHeading from 'layouts/dashboard-layout/headers/DashboardPageHeading';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import { ADD_PRICE_LIST_PAGE_PATH } from 'routes/paths';

// TODO code review

/**
 * Price lists page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=302%3A12387&t=KmGU0c7SRwOArYf9-0
 */
export default function PriceListsPage() {
  const navigate = useNavigate();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="relative h-full p-[20px] pt-0">
        <DashboardPageHeading
          heading={_t('Price lists')}
          buttonAction={() => navigate(ADD_PRICE_LIST_PAGE_PATH.original)}
        />
        <PriceListTable />
      </div>
    </DashboardLayout>
  );
}
