/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "FiletypeXml" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function FiletypeXmlIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4 4.61929 5.11929 3.5 6.5 3.5H13.0925C13.8134 3.5 14.4992 3.8112 14.9739 4.35374L17.3814 7.10521C17.7802 7.56094 18 8.14591 18 8.75147V11C18 11.2761 17.7761 11.5 17.5 11.5C17.2239 11.5 17 11.2761 17 11V8.75147C17 8.6667 16.9928 8.5826 16.9788 8.5H14.5C13.6716 8.5 13 7.82843 13 7V4.5H6.5C5.67157 4.5 5 5.17157 5 6V19C5 19.8284 5.67157 20.5 6.5 20.5H12.5C12.7761 20.5 13 20.7239 13 21C13 21.2761 12.7761 21.5 12.5 21.5H6.5C5.11929 21.5 4 20.3807 4 19V6ZM14.2213 5.01224L16.3981 7.5H14.5C14.2239 7.5 14 7.27614 14 7V4.80568C14.0799 4.86639 14.1541 4.93545 14.2213 5.01224Z"
        fill={fill}
      />
      <path
        d="M10 14.75V13.5H9V14.75C9 14.9075 8.926 15.0555 8.8 15.15L8.5 15.375L8.2 15.15C8.074 15.0555 8 14.9075 8 14.75V13.5H7V14.75C7 15.2222 7.22225 15.6667 7.6 15.95L7.66675 16L7.6 16.05C7.222 16.3333 7 16.7778 7 17.25V18.5H8V17.25C8 17.0925 8.074 16.9445 8.2 16.85L8.5 16.625L8.8 16.85C8.926 16.9445 9 17.0925 9 17.25V18.5H10V17.25C10 16.7778 9.77775 16.3333 9.4 16.05L9.33325 16L9.4 15.95C9.77775 15.6667 10 15.2222 10 14.75Z"
        fill={fill}
      />
      <path
        d="M14.5 13.5C14.1152 13.5 13.7655 13.646 13.5 13.8842C13.2343 13.646 12.8848 13.5 12.5 13.5C11.6715 13.5 11 14.1715 11 15V18.5H12V15C12 14.7238 12.2238 14.5 12.5 14.5C12.7762 14.5 13 14.7238 13 15V18.5H14V15C14 14.7238 14.2238 14.5 14.5 14.5C14.7762 14.5 15 14.7238 15 15V18.5H16V15C16 14.1715 15.3285 13.5 14.5 13.5Z"
        fill={fill}
      />
      <path d="M18 17.5V13.5H17V18.5H20V17.5H18Z" fill={fill} />
    </svg>
  );
}
