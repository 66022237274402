import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ProjectProvider, useProject } from 'providers/project/ProjectProvider';

/**
 * Watches the projectId path parameter and updates the project context.
 */
function ProjectPathParamWatcher({ children }) {
  const { projectId } = useParams();
  const { setProjectId } = useProject();

  useEffect(() => {
    setProjectId(Number(projectId));
  }, [projectId]);

  return children;
}

/**
 * Provides the project id from the path to the project context.
 */
export default function ProjectPathParamProvider({ children }) {
  const { projectId } = useParams();

  return (
    <ProjectProvider initialProjectId={Number(projectId)}>
      <ProjectPathParamWatcher>{children}</ProjectPathParamWatcher>
    </ProjectProvider>
  );
}
