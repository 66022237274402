/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-get-action-body').CostEstimateGetActionBody} CostEstimateGetActionBody
 */

/**
 * @typedef {import('./cost-estimate-get-action-response').CostEstimateGetActionResponse} CostEstimateGetActionResponse
 */

/**
 * The path of the CostEstimateGetAction action.
 */
export const costEstimateGetActionPath = new SweetPath('/api/finance/cost_estimate/{cost_estimate_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized CostEstimateGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateGetAction(axios) {
  /**
   * The costEstimateGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<CostEstimateGetActionResponse>}
   */
  function costEstimateGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = costEstimateGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return costEstimateGetAction;
}
