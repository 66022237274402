import { useState } from 'react';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import ProcessMessageWithAction from 'components/ProcessMessageWithAction';
import { _t } from 'lang';
import { Button } from '@mantine/core';
import LockIcon from 'components/icons/LockIcon';
import { useDisclosure } from '@mantine/hooks';
import CostEstimateCloseModal from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/CostEstimateCloseModal';

/**
 * Contains information about the state of the Cost Estimate with buttons to
 * change it when the state is `declined`.
 */
export default function StateDeclined() {
  const { costEstimate, setIsClosed } = useCostEstimateDetail();

  const [loading, setLoading] = useState(false);
  const [confirm, { close: closeConfirm, open: openConfirm }] = useDisclosure(false);

  const { getAction } = useApi();
  const costEstimateCloseAction = getAction('CostEstimateCloseAction');

  /**
   * Closes the cost estimate.
   */
  function close() {
    if (!loading) {
      setLoading(true);

      costEstimateCloseAction({ parameters: { cost_estimate_id: costEstimate.cost_estimate_id } })
        .then(() => setIsClosed(true))
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <ProcessMessageWithAction
        heading={_t('Declined')}
        message={_t(
          'This cost estimate was declined and there is no profit. All internal and external costs will cause a negative balance in the project...'
        )}
        buttons={
          <Button variant="primary" leftIcon={<LockIcon stroke="#FFFFFF" />} onClick={openConfirm} loading={loading}>
            {_t('Mark as closed')}
          </Button>
        }
      />
      <CostEstimateCloseModal opened={confirm} onClose={closeConfirm} onConfirm={close} />
    </>
  );
}
