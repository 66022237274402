import { _t } from 'lang';
import EmptyTab from 'pages/finance/cost-estimate/detail/EmptyTab';
import ProgressGroup from 'components/progress/ProgressGroup';
import ExternalCostTabRow from 'pages/finance/cost-estimate/detail/external-cost-tab/ExternalCostTabRow';
import { Button } from '@mantine/core';
import AddIcon from 'components/icons/AddIcon';
import { Link } from 'react-router-dom';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import PriceDisplay from 'components/PriceDisplay';
import ExternalCostsOverflowAlert from 'pages/finance/cost-estimate/detail/alerts/warning/ExternalCostsOverflowAlert';
import { useApi } from 'api/ApiContext';

/**
 * The tab for the external cost in the cost estimate detail page.
 */
export default function ExternalCostTab() {
  const { costEstimate, links, isClosed } = useCostEstimateDetail();
  const externalCosts = costEstimate.external_costs;
  const { workspaceConfig } = useApi();

  return (
    <div>
      <div className="px-8 py-4">
        {/* external costs or button to add external costs */}
        {externalCosts && externalCosts.length > 0 ? (
          <>
            <div className="rounded-[8px] bg-white py-4">
              <div>
                <div className="grid grid-cols-[1fr_1fr_120px_32px] gap-2 px-2 text-[12px] leading-[18px] text-neutral-500">
                  <div className="pl-9">{_t('Document name')}</div>
                  <div>{_t('Supplier')}</div>
                  <div className="text-right">{_t('Amount')}</div>
                  <div></div>
                </div>

                {externalCosts.map((externalCost) => (
                  <ExternalCostTabRow key={externalCost.external_cost_id} externalCost={externalCost} />
                ))}

                {!isClosed && (
                  <div className="h-12 py-3 pl-10">
                    <Button
                      variant="link"
                      leftIcon={<AddIcon fill="#38298B" />}
                      component={Link}
                      to={links.externalCost.add}
                    >
                      {_t('Add external costs')}
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <ExternalCostsOverflowAlert className="mt-4" />
          </>
        ) : !isClosed ? (
          <EmptyTab title={_t('Add external costs')} path={links.externalCost.add} />
        ) : (
          <></>
        )}
      </div>

      {/* The ratio of actual to estimated external costs */}
      <div className="flex justify-end pl-[42px] pr-12">
        <div className="flex w-[270px] flex-col items-end justify-center gap-2 text-right">
          <div className="flex h-6 flex-row items-end gap-2 text-[15px] leading-[18px] text-neutral-700">
            <span>{_t('Total external costs')} </span>
            <PriceDisplay
              value={costEstimate.external_cost_sum_actual}
              currency={workspaceConfig.currency}
              valueClassName="font-semibold"
            />
          </div>
          <ProgressGroup
            variant="positive"
            part={costEstimate.external_cost_sum_actual}
            total={costEstimate.budget_external}
          />
          <div className="flex h-12 flex-row items-end justify-center gap-2 pt-6 text-[15px] leading-[18px] text-neutral-700">
            <span>{_t('from planned')} </span>
            <PriceDisplay
              value={costEstimate.budget_external}
              currency={workspaceConfig.currency}
              valueClassName="font-semibold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
