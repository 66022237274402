/**
 * Displays the project information row in the cost estimate detail page.
 *
 * @param {{
 *   label: string;
 *   value: string | React.ElementType
 * }}
 */
export default function ProjectInformationRow({ label, value }) {
  return (
    <div className="flex flex-row gap-4 text-[15px] leading-[18px]">
      <div className="min-h-[36px] w-[240px] py-2">
        <div className="h-full">
          <span>{label}</span>
        </div>
      </div>
      <div className="flex w-[532px] items-center justify-start">{value}</div>
    </div>
  );
}
