import { Menu, Group, Stack, Text, Box } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import Avatar from 'components/avatars/Avatar';
import EditIcon from 'components/icons/EditIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import LogTimeIcon from 'components/icons/LogTimeIcon';
import UserIcon from 'components/icons/UserIcon';
import { _t } from 'lang';
import { useNavigate, Link } from 'react-router-dom';
import { LOGIN_PAGE_PATH, TIME_ENTRIES_OVERVIEW_PAGE_PATH, RESET_PASSWORD_PAGE_PATH } from 'routes/paths';

/**
 * Styles for the user menu.
 */
const menuStyles = {
  dropdown: {
    borderRadius: '8px',
    border: '1px solid #F2F2F2',
    backgroundColor: '#fff',
    boxShadow: '8px 8px 16px 0px rgba(0, 0, 0, 0.10)',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  item: {
    padding: '8px 16px !important',
    color: '#4D4D4D',
    fontSize: '15px !important',
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: '#908EFB',
      color: '#FFF',
      svg: {
        path: {
          stroke: '#FFF',
        },
      },
    },
    '&:active': {
      backgroundColor: '#38298B',
      color: '#FFF',
      svg: {
        path: {
          stroke: '#FFF',
        },
      },
    },
  },
  itemIcon: {
    marginRight: '8px !important',
  },
  divider: {
    borderTop: '1px solid #F2F2F2',
  },
};

/**
 * The user menu in the dashboard header.
 */
export function DashboardUserMenu() {
  const navigate = useNavigate();
  const { logout, fullName, email, hasPermission } = useApi();

  /**
   * Signs out and redirects the user.
   */
  const signOutAndRedirect = () => {
    navigate(LOGIN_PAGE_PATH.original);
    logout();
  };

  return (
    <Menu position="bottom-end" offset={15} styles={menuStyles}>
      <Menu.Target>
        <Box>
          <Avatar label={fullName} size={40} cursor="pointer" />
        </Box>
      </Menu.Target>
      <Menu.Dropdown miw={200}>
        <Menu.Item sx={{ cursor: 'default', ':hover': { background: '#fff' } }}>
          <Group spacing={8} pb={8} align="center">
            <Avatar label={fullName} size={32} />
            <Stack spacing={0}>
              <Text fz={15} lh={18 / 15} weight={500} c="#4D4D4D">
                {fullName}
              </Text>
              <Text fz={12} lh={16 / 12} c="#808080">
                {email}
              </Text>
            </Stack>
          </Group>
        </Menu.Item>
        <Menu.Divider />
        {/* TODO which path */}
        <Menu.Item component={Link} to="/" icon={<UserIcon stroke="#4D4D4D" />}>
          {_t('My account')}
        </Menu.Item>
        {hasPermission('TIME_LOGS_CREATE_TIME_LOG', '*') && (
          <Menu.Item
            component={Link}
            to={TIME_ENTRIES_OVERVIEW_PAGE_PATH.original}
            icon={<LogTimeIcon stroke="#4D4D4D" />}
          >
            {_t('Timesheet')}
          </Menu.Item>
        )}
        <Menu.Item component={Link} to={RESET_PASSWORD_PAGE_PATH.original} icon={<EditIcon stroke="#4D4D4D" />}>
          {_t('Change password')}
        </Menu.Item>
        <Menu.Item onClick={signOutAndRedirect} icon={<LogoutIcon stroke="#4D4D4D" />}>
          <span>{_t('Log out')}</span>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
