/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse['groups'][number]} ResultGroup */

import { Box, Group, Stack } from '@mantine/core';
import { ADVANCED_SEARCH_PAGE_PATH } from 'routes/paths';
import SectionResult from './SectionResult';
import { Link } from 'react-router-dom';
import { _t } from 'lang';
import { useMemo } from 'react';
import { useSearch } from './SearchProvider';

const sectionNameMap = {
  project: _t('Projects'),
  task: _t('Tasks'),
  client: _t('Clients'),
  time_log: _t('Time logs'),
  cost_estimate: _t('Cost estimates'),
  external_cost: _t('External costs'),
  file: _t('Files'),
  comment: _t('Comments'),
  other: _t('Other items'),
};

/**
 * Returns the section name for a given group name.
 *
 * @param {string} groupName
 */
export function getSectionName(groupName) {
  return sectionNameMap[groupName] || '';
}

/**
 * Displays the search results for a given section.
 *
 * @param {{
 *   group: ResultGroup;
 *   onResultsHide?: () => void;
 * }}
 */
export default function SectionResults({ group: { name, total, items } }) {
  const { search } = useSearch();

  const advancedSearchLink = useMemo(
    () => `${ADVANCED_SEARCH_PAGE_PATH.original}?query=${search}&searchType=${name}`,
    [search, name]
  );

  const sectionName = useMemo(() => getSectionName(name), [name]);

  return (
    <Stack spacing={0}>
      <Group position="apart">
        <Box pr={4} c="#fff" fz={12} lh={1.33}>
          {sectionName}
        </Box>
        <Box fz={12} lh={2} c="#908EFB" className="hover:text-white">
          <Link to={advancedSearchLink} state={{ showResults: false }} style={{ textDecoration: 'none' }}>
            {total} {total === 1 ? _t('result') : _t('results')}
          </Link>
        </Box>
      </Group>
      <Stack spacing={4}>
        {items.map((item) => (
          <SectionResult key={item.id} item={item} />
        ))}
      </Stack>
    </Stack>
  );
}
