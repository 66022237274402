/** @typedef {import('../section/CostEstimateSectionData').CostEstimateSectionData} CostEstimateSectionData */

import { round, sumBy } from 'lodash';

/**
 * Creates a new instance of CostEstimatePartData.
 *
 * @param {{
 *   sections: CostEstimateSectionData[],
 * }}
 */
export function createCostEstimatePartData({ sections = [] } = {}) {
  // Price after discount. Cost Estimate Part cannot have a discount therefore
  // there is no need to compute the subtotal.
  const total = round(sumBy(sections, 'total'), 2);

  // Sum of external costs.
  const externalCosts = round(sumBy(sections, 'externalCosts'), 2);

  return Object.freeze({
    sections,
    total,
    externalCosts,
  });
}

/** @typedef {ReturnType<typeof createCostEstimatePartData>} CostEstimatePartData */
