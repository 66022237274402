/** @typedef {import('api/actions/cost-estimate-get-action/cost-estimate-get-action-response').CostEstimateGetActionResponse} CostEstimate */

import { createContext, useContext, useMemo, useState } from 'react';
import {
  FINANCE_ADD_EXTERNAL_COST_PAGE_PATH,
  FINANCE_ADD_INVOICE_PAGE_PATH,
  FINANCE_ADD_INVOICE_REQUEST_PAGE_PATH,
  FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH,
  FINANCE_DETAIL_COST_ESTIMATE_PDF_PREVIEW_PAGE_PATH,
  FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH,
} from 'routes/paths';

/**
 * Creates links for a cost estimate detail page.
 *
 * @param {number} costEstimateId
 */
function createLinks(costEstimateId) {
  const linkDetail = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId });

  return {
    detail: linkDetail,
    edit: FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId }),
    pdf: FINANCE_DETAIL_COST_ESTIMATE_PDF_PREVIEW_PAGE_PATH.insert({ costEstimateId }),
    purchaseOrder: {
      detail: `${linkDetail}?tab=purchase-order`,
      add: FINANCE_ADD_INVOICE_REQUEST_PAGE_PATH.insert({ costEstimateId }),
    },
    invoice: {
      detail: `${linkDetail}?tab=invoice`,
      add: FINANCE_ADD_INVOICE_PAGE_PATH.insert({ costEstimateId }),
    },
    externalCost: {
      detail: `${linkDetail}?tab=external-cost`,
      add: FINANCE_ADD_EXTERNAL_COST_PAGE_PATH.insert({ costEstimateId }),
    },
  };
}

/**
 * The context for the cost estimate detail page.
 *
 * @type {React.Context<{
 *   costEstimate: CostEstimate;
 *   costEstimateId: number;
 *   currency: string;
 *   links: ReturnType<typeof createLinks>;
 *   isApproved: boolean;
 *   setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
 *   isClosed: boolean;
 *   setIsClosed: React.Dispatch<React.SetStateAction<boolean>>;
 *   isDeclined: boolean;
 *   setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>;
 *   inHouseSections: CostEstimate['sections'];
 *   outOfHouseSections: CostEstimate['sections'];
 * }>}
 */
const CostEstimateDetailContext = createContext();

/**
 * Provides the context for the cost estimate detail page.
 *
 * @param {{
 *   costEstimate: CostEstimate;
 *   children: React.ReactNode;
 * }}
 */
export function CostEstimateDetailProvider({ costEstimate, children }) {
  const [isApproved, setIsApproved] = useState(!!costEstimate.approved_at);
  const [isClosed, setIsClosed] = useState(!!costEstimate.closed_at);
  const [isDeclined, setIsDeclined] = useState(!!costEstimate.declined_at);

  const inHouseSections = useMemo(() => {
    return costEstimate.sections.filter((section) => section.is_in_house);
  }, [costEstimate]);

  const outOfHouseSections = useMemo(() => {
    return costEstimate.sections.filter((section) => !section.is_in_house);
  }, [costEstimate]);

  const costEstimateId = costEstimate.cost_estimate_id;
  const currency = costEstimate.currency;

  const links = useMemo(() => createLinks(costEstimateId), [costEstimateId]);

  const value = useMemo(
    () => ({
      costEstimate,
      costEstimateId,
      currency,
      links,
      isApproved,
      setIsApproved,
      isClosed,
      setIsClosed,
      isDeclined,
      setIsDeclined,
      inHouseSections,
      outOfHouseSections,
    }),
    [
      costEstimate,
      costEstimateId,
      currency,
      links,
      isApproved,
      setIsApproved,
      isClosed,
      setIsClosed,
      isDeclined,
      setIsDeclined,
      inHouseSections,
      outOfHouseSections,
    ]
  );

  return <CostEstimateDetailContext.Provider value={value}>{children}</CostEstimateDetailContext.Provider>;
}

/**
 * Uses the context for the cost estimate detail page.
 */
export function useCostEstimateDetail() {
  return useContext(CostEstimateDetailContext);
}
