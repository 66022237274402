import { Button, Group, Stack } from '@mantine/core';
import ToolioLogo from 'components/ToolioLogo';
import { _t } from 'lang';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE_PATH, RESET_PASSWORD_PAGE_PATH } from 'routes/paths';

/**
 * Reset Link Expired page content.
 */
export default function ResetLinkExpiredContent() {
  return (
    <div className="toolio-login-background flex h-full items-center">
      <div className="ml-auto mr-auto w-[278px] text-center">
        <Group
          pr={24}
          pb={2}
          pl={1}
          position="center"
          style={{ position: 'absolute', top: '135px', left: 0, right: 0 }}
        >
          <ToolioLogo className="ml-auto mr-auto" width="109px" height="80px" variant="dark" />
        </Group>
        <Stack spacing={16} h={186}>
          <Group position="center" style={{ fontSize: '22px', lineHeight: '32px', color: '#BF0D38' }}>
            {_t('Ooops, your password reset link has expired.')}
          </Group>
          <Group position="center" style={{ fontSize: '15px', lineHeight: '18px', color: '#38298B' }}>
            {_t('Generate a new one.')}
          </Group>
          <Group position="center">
            <Link to={RESET_PASSWORD_PAGE_PATH.original}>
              <Button type="button" variant="primary">
                {_t('Reset password')}
              </Button>
            </Link>
          </Group>
          <Group
            position="center"
            style={{ color: '#38298B', fontSize: '15px', lineHeight: '20px', textDecorationLine: 'underline' }}
          >
            <Link to={LOGIN_PAGE_PATH.original}>{_t('Back to login')}</Link>
          </Group>
        </Stack>
      </div>
    </div>
  );
}
