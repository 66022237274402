/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-delete-action-body').TimeLogDeleteActionBody} TimeLogDeleteActionBody
 */

/**
 * @typedef {import('./time-log-delete-action-response').TimeLogDeleteActionResponse} TimeLogDeleteActionResponse
 */

/**
 * The path of the TimeLogDeleteAction action.
 */
export const timeLogDeleteActionPath = new SweetPath('/api/time_log/delete/{time_log_id}', { path: '{param}' });

/**
 * Creates a contextualized TimeLogDeleteAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogDeleteAction(axios) {
  /**
   * The timeLogDeleteAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TimeLogDeleteActionBody } }
   *
   * @returns {Promise<TimeLogDeleteActionResponse>}
   */
  function timeLogDeleteAction({ headers = {}, parameters = {}, body } = {}) {
    const path = timeLogDeleteActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return timeLogDeleteAction;
}
