/** @typedef {import('api/actions/task-get-subtasks-action/task-get-subtasks-action-response').TaskGetSubtasksActionResponse[number]} Subtask */

import { Box, Group } from '@mantine/core';
import UserView from 'components/avatars/UserView';
import DragIcon from 'components/icons/DragIcon';
import { useState } from 'react';
import DueDateDisplay from 'pages/projects/project-overview/project-table/DueDateDisplay';
import TaskStatusBubble from 'pages/projects/project-overview/project-table/task-table/TaskStatusBubble';
import { Link } from 'react-router-dom';
import { TASK_DETAIL_PAGE_PATH } from 'routes/paths';
import SubtaskIcon from 'components/icons/SubtaskIcon';
import TaskActions from 'components/task/TaskActions';
import { noop } from 'lodash';
import { Draggable } from 'react-beautiful-dnd';
import DurationDisplay from 'components/DurationDisplay';
import Truncate from 'components/Truncate';

/**
 * The subtask row of the Project overview page.
 *
 * @param {{
 *   draggableId: string;
 *   clientId: number;
 *   index: number;
 *   onTaskDelete?: () => void;
 *   onTaskClose?: () => void;
 *   onTaskReopen?: () => void;
 *   onTaskRestore?: () => void;
 *   subtaskId: number;
 *   subtask: Subtask;
 *   hideTaskRow?: boolean;
 * }}
 */
export default function SubtaskRow({
  draggableId,
  clientId,
  index,
  onTaskClose = noop,
  onTaskDelete = noop,
  onTaskReopen = noop,
  onTaskRestore = noop,
  projectId,
  subtaskId,
  subtask,
  hideTaskRow = false,
}) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';

  // TODO figure out how to re-render the page when the subtask is deleted

  const gridColumns = hideTaskRow
    ? 'grid-cols-[24px_1fr_60px_128px_128px_122px_24px]'
    : 'grid-cols-[84px_1fr_60px_128px_128px_122px_24px]';

  const subtaskNameClass = subtask.closed_at || subtask.deleted_at ? 'line-through' : '';

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={!!subtask.deleted_at}>
      {(provided, snapshot) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${
            snapshot.isDragging && !snapshot.isDropAnimating ? 'pl-6 pr-2' : 'border-t border-t-neutral-100'
          }`}
        >
          <Box
            className={`bg-white ${
              snapshot.isDragging && !snapshot.isDropAnimating
                ? 'rounded-[8px] border border-primary-dark-blue bg-neutral-20 shadow-modal-shadow'
                : ''
            }`}
          >
            <div
              className={`group grid h-[56px] items-center gap-2 px-2 text-[20px] text-neutral-700 hover:bg-neutral-20 ${gridColumns}`}
            >
              {/* DRAG & DROP ICON */}
              <div
                {...provided.dragHandleProps}
                className="flex cursor-grab items-center opacity-0 group-hover:opacity-100"
              >
                {subtask.deleted_at ? <></> : <DragIcon />}
              </div>

              {/* SUBTASK NAME */}
              <Group maw={590}>
                <Link
                  maw={590}
                  to={TASK_DETAIL_PAGE_PATH.insert({ taskId: subtaskId })}
                  className="group/subtask-name stroke-neutral-700 text-[15px] leading-[18px] transition-all hover:text-hyperlink"
                >
                  <Group noWrap spacing={4} maw={590}>
                    <SubtaskIcon
                      width={24}
                      height={24}
                      className="[&_path]:group-hover/subtask-name:!stroke-hyperlink"
                    />
                    <Truncate
                      className={`text-[15px] leading-[18px] ${subtaskNameClass}`}
                      text={subtask.task_full_name}
                    />
                  </Group>
                </Link>
              </Group>

              {/* ASSIGNEE */}
              <div className="py-2">
                <UserView users={subtask.assignee} avatarSize={32} max={2} overlap={4} />
              </div>

              {/* LOGGED HOURS */}
              <DurationDisplay
                duration={subtask.minutes_total}
                className="py-2 pr-2 text-right text-[12px] leading-[16px] text-neutral-700"
              />

              {/* DUE DATE */}
              <div className="py-2 pr-2">
                <DueDateDisplay dueDate={subtask.end} />
              </div>

              {/* STATUS */}
              <div className="py-3">
                <TaskStatusBubble task={subtask} variant="small" />
              </div>

              {/* MENU */}
              <div className={`flex justify-end py-3 ${menuClasses}`}>
                <TaskActions
                  projectId={projectId}
                  task={subtask}
                  onMenuChange={setIsMenuOpened}
                  onTaskClose={onTaskClose}
                  onTaskDelete={onTaskDelete}
                  onTaskReopen={onTaskReopen}
                  onTaskRestore={onTaskRestore}
                  hideAddSubtask
                  // TODO re-render the row after the time has been logged
                  onTaskLogTime={noop}
                  clientId={clientId}
                />
              </div>
            </div>
          </Box>
        </Box>
      )}
    </Draggable>
  );
}
