import { Box, Collapse, Group, Skeleton, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import { _t } from 'lang';
import TaskTable from 'pages/projects/project-overview/project-table/task-table/TaskTable';
import { useEffect, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { noop, times } from 'lodash';
import { StrictModeDroppable } from 'pages/projects/project-overview/StrictModeDroppable';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import ShowDeletedSwitch from 'components/inputs/ShowDeletedSwitch';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';

/**
 * A loading row with layout for a single subtask.
 *
 * @param {{hideTaskRow: boolean}}
 */
function SkeletonSubtask({ hideTaskRow }) {
  const gridColumns = hideTaskRow
    ? 'grid-cols-[24px_1fr_60px_128px_128px_122px_24px]'
    : 'grid-cols-[84px_1fr_60px_128px_128px_122px_24px]';

  return (
    <Group h={57} align="center" className="border-t border-t-neutral-100" grow>
      <Box className={`grid ${gridColumns} gap-2 px-2`}>
        <Box h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
      </Box>
    </Group>
  );
}

/**
 * Displays the task subtasks section.
 *
 * @param {{
 *   collapsible: boolean
 * }}
 */
export default function TaskSubtasks({ collapsible = false } = {}) {
  const { project, task, refresh } = useTaskDetail();
  const key = useMemo(() => `toolio.task.detail.${task.task_id}.subtasks.opened`, [task]);
  const [opened, { toggle }] = useLocalStorageDisclosure(key, true);
  const [showDeleted, setShowDeleted] = useState(false);
  const [ready, setReady] = useState(false);
  const [showSubtasks, setShowSubtasks] = useState(false);

  const number = useMemo(() => {
    if (!task.subtasks) {
      return 0;
    }

    if (showDeleted) {
      return task.subtasks.length;
    }

    return task.subtasks.filter(({ deleted_at }) => !deleted_at).length;
  }, [task, showDeleted]);

  useEffect(() => {
    if (ready) {
      setTimeout(() => setShowSubtasks(true), 100);
    }
  }, [ready]);

  return (
    <Stack spacing={16}>
      <GroupHeadline
        className="top-[120px] z-[90] bg-neutral-50 md:sticky"
        heading={_t('SUBTASKS')}
        opened={opened}
        setOpened={toggle}
        collapsible={collapsible}
        number={number}
        loadingNumber={!ready}
        actions={<ShowDeletedSwitch value={showDeleted} onChange={setShowDeleted} />}
      />
      <Collapse in={opened}>
        <Stack spacing={0} pt={16} style={{ backgroundColor: '#fff', borderRadius: '8px', overflow: 'hidden' }}>
          <div className="grid translate-y-[1px] grid-cols-[1fr_60px_128px_128px_154px] gap-x-2 bg-white pl-[72px] pr-2">
            <span className="text-[12px] leading-[18px] text-neutral-500">{_t('Name')}</span>
            <span className="text-[12px] leading-[18px] text-neutral-500">{_t('Assignee')}</span>
            <span className="pr-2 text-right text-[12px] leading-[18px] text-neutral-500">{_t('Logged hours')}</span>
            <span className="pr-2 text-right text-[12px] leading-[18px] text-neutral-500">{_t('Due date')}</span>
            <span className="text-[12px] leading-[18px] text-neutral-500">{_t('Status')}</span>
          </div>
          {!showSubtasks && (
            <Stack spacing={0}>
              {times(number, (i) => (
                <SkeletonSubtask key={i} hideTaskRow />
              ))}
              <Group h={49} px={40} align="center" className="border-t border-t-neutral-100">
                <Skeleton h={24} w={120} />
              </Group>
            </Stack>
          )}
          <Box h={showSubtasks ? 'auto' : 0} style={{ overflow: 'hidden' }}>
            <DragDropContext onDragEnd={noop}>
              <div key="column-2">
                <StrictModeDroppable droppableId="column-2">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={snapshot.isDraggingOver ? { pointerEvents: 'none' } : {}}
                    >
                      <TaskTable
                        key={`task-${task.task_id}`}
                        index={0}
                        draggableTask={{ id: `task-${task.task_id}` }}
                        forceOpened
                        hideTaskRow
                        projectId={project.project_id}
                        task={task}
                        showDeleted={showDeleted}
                        onSubtaskClose={refresh}
                        onSubtaskDelete={refresh}
                        onSubtaskReopen={refresh}
                        onSubtaskRestore={refresh}
                        onReorder={refresh}
                        onReady={() => setReady(true)}
                        clientId={project.client.client_id}
                      />
                    </div>
                  )}
                </StrictModeDroppable>
              </div>
            </DragDropContext>
          </Box>
        </Stack>
      </Collapse>
    </Stack>
  );
}
