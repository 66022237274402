import { Button, Group, Modal, Stack } from '@mantine/core';
import { _t } from '../../lang';
import { useForm } from '@mantine/form';
import { createValidator } from '../forms/validators/create-validator';
import { required } from '../forms/validators/rules/rule-required';
import InputWrapper from '../inputs/InputWrapper';
import ProjectSelect from '../selects/ProjectSelect';
import TaskSelect from '../selects/TaskSelect';
import { noop } from 'lodash';

/**
 * Modal allowing to select task or subtask from available projects and tasks.
 *
 * @param {{
 *   loading: boolean,
 *   opened: boolean,
 *   onClose: () => void,
 *   onPick: (values: { projectId: number, taskId: number }) => void,
 *   excludeTaskIds: number[],
 * }}
 */
export default function AddTaskSubtaskModal({ loading, opened, onClose, onPick = noop, excludeTaskIds = [] }) {
  const form = useForm({
    initialValues: {
      projectId: '',
      taskId: '',
    },
    validate: {
      projectId: createValidator([required]),
      taskId: createValidator([required]),
    },
  });

  /**
   * Handles the form submission.
   */
  function handleSubmit({ projectId, taskId }) {
    onPick({ projectId: Number(projectId), taskId: Number(taskId) });
    form.reset();
    onClose();
  }

  /**
   * Clears the form and closes the modal.
   */
  function handleClose() {
    form.reset();
    onClose();
  }

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      centered
      size="auto"
      title={<span className="text-[24px] leading-[32px]">{_t('Add task/subtask')}</span>}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing={20}>
          <InputWrapper
            align="column"
            label={_t('Project')}
            input={<ProjectSelect placeholder={_t('Project')} {...form.getInputProps('projectId')} />}
          />

          <InputWrapper
            align="column"
            label={_t('Task')}
            input={
              <TaskSelect
                filterOutTasks={excludeTaskIds}
                projectId={form.values.projectId}
                placeholder={_t('Task')}
                {...form.getInputProps('taskId')}
              />
            }
          />

          <Group spacing={20} position="right">
            <Button variant="link" onClick={handleClose} disabled={loading}>
              {_t('Cancel')}
            </Button>
            <Button type="submit" disabled={loading} variant="primary">
              {_t('Add')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
