import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';

/**
 * Displays a form to list the file prefix settings.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=433%3A22261
 */
export default function FilePrefixSettingsPage() {
  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div>
        This is <pre>FilePrefixSettingsContent</pre>.
      </div>
    </DashboardLayout>
  );
}
