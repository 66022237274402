import ToolioLogo from 'components/ToolioLogo';
import { _t } from 'lang';
import { useEffect, useState } from 'react';
import { LOGIN_PAGE_PATH, RESET_LINK_EXPIRED_PAGE_PATH } from 'routes/paths';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import SetPasswordForm from 'components/forms/set-password-form/SetPasswordForm';
import { PASSWORD_SAME_AS_OLD_ONE_ERROR_CODE, USER_DEACTIVATED_ERROR_CODE } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { Group, Stack } from '@mantine/core';
import panic from 'errors/Panic';

/**
 * Set Password page content.
 */
export default function SetPasswordContent() {
  const { getAction } = useApi();
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const name = searchParams.get('name');

  useEffect(() => {
    const userPasswordCheckTokenAction = getAction('UserPasswordCheckTokenAction');

    setLoading(true);

    userPasswordCheckTokenAction({ body: { email, token } })
      .then(setData)
      .catch((e) => {
        panic(e);
        navigate(RESET_LINK_EXPIRED_PAGE_PATH.original, { replace: true });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, email, token]);

  const initialValues = {
    password: '',
    passwordAgain: '',
  };

  /**
   * Checks for password equality, password strength requirements and sends valid password to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ password }) => {
    const userPasswordSetAction = getAction('UserPasswordSetAction');

    return userPasswordSetAction({ body: { email, password, token } })
      .then(() => {
        setIsSuccess(true);
      })
      .catch((e) => {
        if (e.code === PASSWORD_SAME_AS_OLD_ONE_ERROR_CODE) {
          setFormError(_t('Your new password must be different than the old one.'));
        } else if (e.code === USER_DEACTIVATED_ERROR_CODE) {
          setFormError(_t('Your account has been deactivated.'));
        } else {
          panic(e);
        }
      });
  };

  if (loading) {
    return <></>;
  }

  if (!token || !email || !data.valid) {
    return <Navigate to={RESET_LINK_EXPIRED_PAGE_PATH.original} replace />;
  }

  if (isSuccess) {
    return <Navigate to={LOGIN_PAGE_PATH.original} />;
  }

  return (
    <div className="toolio-login-background flex h-full items-center">
      <div className="ml-auto mr-auto w-[278px] text-center">
        <Group
          pr={24}
          pb={2}
          pl={1}
          position="center"
          style={{ position: 'absolute', top: '105px', left: 0, right: 0 }}
        >
          <ToolioLogo className="ml-auto mr-auto" width="109px" height="80px" variant="dark" />
        </Group>
        <Stack spacing={16} h={284}>
          <Group position="center" style={{ color: '#38298B', fontSize: '22px', lineHeight: '32px' }}>
            {_t('Hi')}, {name}.
            <br />
            {_t('Set your password.')}
          </Group>
          <div className="w-[278px] text-left">
            <SetPasswordForm onSubmit={submit} initialValues={initialValues} errorMessage={formError} />
          </div>
        </Stack>
      </div>
    </div>
  );
}
