/** @typedef {import('api/actions/task-get-list-action/task-get-list-action-response').TaskGetListActionResponse[number]} Task */

import { Box, Menu } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import AddIcon from 'components/icons/AddIcon';
import CheckIcon from 'components/icons/CheckIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import LogTimeIcon from 'components/icons/LogTimeIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import ReplyIcon from 'components/icons/ReplyIcon';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useTimeLog } from 'pages/time-entries/time-log/TimeLogProvider';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ADD_SUBTASK_PAGE_PATH, EDIT_TASK_PAGE_PATH } from 'routes/paths';

/**
 * Actions for a task.
 *
 * @param {{
 *   projectId: number;
 *   clientId: number;
 *   task: Task;
 *   onMenuChange?: (opened: boolean) => void;
 *   onTaskDelete?: () => void;
 *   onTaskClose?: () => void;
 *   onTaskReopen?: () => void;
 *   onTaskRestore?: () => void;
 *   onTaskLogTime?: () => void;
 *   hideEdit?: boolean;
 *   hideAddSubtask?: boolean;
 *   hideClose?: boolean;
 *   hideDelete?: boolean;
 *   hideLogTime?: boolean;
 * }}
 */
export default function TaskActions({
  projectId,
  clientId,
  task: { task_id: taskId, closed_at: closedAt, deleted_at: deletedAt, parent_task_id: parentTaskId },
  onMenuChange = noop,
  onTaskDelete = noop,
  onTaskClose = noop,
  onTaskReopen = noop,
  onTaskRestore = noop,
  onTaskLogTime = noop,
  hideEdit = false,
  hideAddSubtask = false,
  hideClose = false,
  hideDelete = false,
  hideLogTime = false,
}) {
  const { getAction, hasPermission } = useApi();
  const { confirm } = useConfirm();
  const { openTimeLogModal } = useTimeLog();

  const editLink = useMemo(() => EDIT_TASK_PAGE_PATH.insert({ taskId }), [taskId]);
  const addSubtaskLink = useMemo(() => ADD_SUBTASK_PAGE_PATH.insert({ taskId }), [taskId]);
  const hasTaskCreatePermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );
  const hasTaskUpdatePermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );
  const hasTimeLogCreatePermission = useMemo(
    () => hasPermission('TIME_LOGS_CREATE_TIME_LOG', clientId),
    [hasPermission, clientId]
  );

  /**
   * Deletes the task.
   */
  const deleteTask = useCallback(async () => {
    const canCloseTaskAction = getAction('TaskCanBeClosedAction');
    const { result: canClose } = await canCloseTaskAction({ parameters: { task_id: taskId } }).catch(panic);

    if (!canClose) {
      confirm({
        title: _t('Cannot delete task with open subtasks'),
        message: _t('This task has open subtasks. Close them to delete this task.'),
        primaryButtonText: _t('Ok'),
        hideSecondaryButton: true,
      });
    } else {
      confirm({
        title: _t('Delete task'),
        message: _t('The task will be deleted. Do you want to proceed?'),
        onConfirm: () => {
          const deleteTaskAction = getAction('TaskDeleteAction');

          return deleteTaskAction({ parameters: { task_id: taskId } })
            .then(onTaskDelete)
            .catch(panic);
        },
      });
    }
  }, [taskId, confirm, getAction]);

  /**
   * Closes the task.
   */
  const closeTask = useCallback(async () => {
    const canCloseTaskAction = getAction('TaskCanBeClosedAction');
    const { result: canClose } = await canCloseTaskAction({ parameters: { task_id: taskId } }).catch(panic);

    if (!canClose) {
      confirm({
        title: _t('Cannot close task with open subtasks'),
        message: _t('This task has open subtasks. Close them to close this task.'),
        primaryButtonText: _t('Ok'),
        hideSecondaryButton: true,
      });
    } else {
      confirm({
        title: _t('Close task'),
        message: _t('The task will be closed. Do you want to proceed?'),
        onConfirm: async () => {
          try {
            const closeTaskAction = getAction('TaskCloseAction');
            await closeTaskAction({ parameters: { task_id: taskId } });
            onTaskClose();
          } catch (error) {
            panic(error);
          }
        },
      });
    }
  }, [taskId, confirm, getAction, onTaskClose]);

  /**
   * Reopens the task.
   */
  const reopenTask = useCallback(() => {
    confirm({
      title: _t('Reopen task'),
      message: _t('The task will be reopened. Do you want to proceed?'),
      onConfirm: () => {
        const reopenTaskAction = getAction('TaskReopenAction');

        return reopenTaskAction({ parameters: { task_id: taskId } })
          .then(onTaskReopen)
          .catch(panic);
      },
    });
  }, [taskId, confirm, getAction]);

  /**
   * Restores the task.
   */
  const restoreTask = useCallback(() => {
    confirm({
      title: _t('Restore task'),
      message: _t('The task will be restored. Do you want to proceed?'),
      onConfirm: () => {
        const restoreTaskAction = getAction('TaskRestoreAction');

        return restoreTaskAction({ parameters: { task_id: taskId } })
          .then(onTaskRestore)
          .catch(panic);
      },
    });
  }, [taskId, confirm, getAction]);

  /**
   * Logs time on the task.
   */
  const logTimeOnTask = useCallback(() => {
    openTimeLogModal({
      projectId,
      taskId,
      onTimeLogged: onTaskLogTime,
    });
  }, [openTimeLogModal, projectId, taskId, onTaskLogTime]);

  const isClosed = !!closedAt;
  const isDeleted = !!deletedAt;

  const canEdit = !isDeleted && hasTaskUpdatePermission;
  const canAddSubtask = !isClosed && !isDeleted && hasTaskCreatePermission && !parentTaskId;

  const canClose = !isClosed && !isDeleted && hasTaskUpdatePermission;
  const canReopen = isClosed && !isDeleted && hasTaskUpdatePermission;

  const canDelete = !isDeleted && hasTaskUpdatePermission;
  const canRestore = isDeleted && hasTaskUpdatePermission;

  const canLogTime = !hideLogTime && !isDeleted && !isClosed && hasTimeLogCreatePermission;

  const hasAction = canEdit || canAddSubtask || canClose || canReopen || canDelete || canRestore || canLogTime;

  return (
    hasAction && (
      <Menu withinPortal position="bottom-end" offset={15} onChange={onMenuChange}>
        <Menu.Target>
          <div className="-my-2 flex cursor-pointer items-center py-2">
            <OptionsDotsIcon />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {canLogTime && (
            <Menu.Item icon={<LogTimeIcon stroke="#4D4D4D" />} onClick={logTimeOnTask}>
              {_t('Log time')}
            </Menu.Item>
          )}
          {canEdit && !hideEdit && (
            <Menu.Item component={Link} to={editLink} icon={<EditIcon fill="#4D4D4D" />}>
              {_t('Edit')}
            </Menu.Item>
          )}
          {canAddSubtask && !hideAddSubtask && (
            <Menu.Item component={Link} to={addSubtaskLink} icon={<AddIcon />}>
              {_t('Add subtask')}
            </Menu.Item>
          )}
          {canClose && !hideClose && (
            <Menu.Item onClick={closeTask} icon={<CheckIcon stroke="#4D4D4D" />}>
              {_t('Close')}
            </Menu.Item>
          )}
          {canReopen && (
            <Menu.Item onClick={reopenTask} icon={<ReplyIcon stroke="#4D4D4D" />}>
              {_t('Reopen')}
            </Menu.Item>
          )}
          {canRestore && (
            <Menu.Item onClick={restoreTask} icon={<ReplyIcon stroke="#4D4D4D" />}>
              {_t('Restore task')}
            </Menu.Item>
          )}
          {canDelete && !hideDelete && (
            <>
              <Menu.Divider />
              <Menu.Item onClick={deleteTask} icon={<DeleteIcon stroke="#BF0D38" />}>
                <Box className="text-red">{_t('Delete task')}</Box>
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    )
  );
}
