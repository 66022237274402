import { _t } from 'lang';

/**
 * Validates that the input is not empty.
 *
 * @param {string} value
 */
export const required = (value) => {
  if (value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
    return {
      message: _t('This field is required.'),
    };
  }

  return null;
};
