import Avatar from 'components/avatars/Avatar';

/**
 * Displays an avatar with the user's name.
 *
 * @param {{
 *   avatar: string,
 *   name: string,
 *   size?: number,
 * }}
 */
export default function UserNameWithAvatar({ avatar, name, size = 24 }) {
  return (
    <div className="flex flex-row items-center gap-2">
      <Avatar image={avatar} label={name} size={size} />
      <span className="text-[15px] leading-[18px]">{name}</span>
    </div>
  );
}
