import { Group, Skeleton, Stack } from '@mantine/core';
import { times } from 'lodash';

/**
 * Displays a skeleton loader for the form.
 *
 * @param {{
 *   rows?: number,
 * }}
 */
export default function FormLoader({ rows = 5 } = {}) {
  return (
    <Stack spacing={16} pt={24}>
      {times(rows, (i) => (
        <Group key={i} spacing={16}>
          <Skeleton w={240} h={36} radius={8} animate />
          <Skeleton w={500} h={36} radius={8} animate />
        </Group>
      ))}
      <Group position="right" pt={16}>
        <Skeleton w={80} h={36} radius={8} animate />
        <Skeleton w={80} h={36} radius={8} animate />
      </Group>
    </Stack>
  );
}
