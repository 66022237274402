/**
 * SimpleLayout component.
 *
 * @param {{
 *   content: React.ReactNode,
 * }}
 */

/**
 * Simple layout.
 */
export default function SimpleLayout({ content }) {
  return (
    <div className="simple-layout">
      <div className="simple-layout__content h-screen">{content}</div>
    </div>
  );
}
