import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconUnderline from 'components/inputs/wysiwyg/controls/underline/IconUnderline';

/**
 * Used to underline text.
 */
export default function ControlUnderline() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Underline" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleUnderline().run()}
        active={editor?.isActive('underline')}
      >
        <IconUnderline />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
