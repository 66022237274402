import { ActionIcon, Button, Group, Menu, Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconImage from 'components/inputs/wysiwyg/controls/image/IconImage';
import { useEffect, useState } from 'react';
import CloseIcon from 'components/icons/CloseIcon';
import { _t } from 'lang';
import panic from 'errors/Panic';
import fitToBox from 'utils/fit-to-box';
import { WYSIWYG_INLINE_IMAGE_MAX_HEIGHT, WYSIWYG_INLINE_IMAGE_MAX_WIDTH } from 'utils/constants';
import UploadImage from 'components/files/upload/UploadImage';

/**
 * Used to insert image.
 */
export default function ControlImage() {
  const { editor } = useRichTextEditorContext();
  const [opened, { toggle, close }] = useDisclosure(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [image, setImage] = useState(null);

  /**
   * Insert image into editor.
   */
  const insertImage = () => {
    try {
      if (!image) {
        throw new Error('Image is not ready to be inserted.');
      }

      const { width, height } = fitToBox({
        ...image.dimensions,
        maxWidth: WYSIWYG_INLINE_IMAGE_MAX_WIDTH,
        maxHeight: WYSIWYG_INLINE_IMAGE_MAX_HEIGHT,
      });

      const src = `/api/files/public/file/${image.remoteId}`;
      const content = `<img src="${src}" width="${width}" height="${height}" /><p></p>`;

      editor?.chain().focus().insertContent(content).run();

      close();
    } catch (error) {
      panic(error);
    }
  };

  // Reset state when menu is closed.
  useEffect(() => {
    if (!opened) {
      setFileSelected(false);
      setImage(null);
    }
  }, [opened]);

  return (
    <Menu opened={opened} onClose={close} withinPortal exitTransitionDuration={0}>
      <Menu.Target>
        <Tooltip label="Add image" openDelay={100} closeDelay={50}>
          <RichTextEditor.Control onClick={toggle} active={opened}>
            <IconImage />
          </RichTextEditor.Control>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown className="p-2">
        <Stack w={512} spacing={8} p={16}>
          <Group position="apart" align="center">
            <Text fz={20} lh={36 / 20}>
              {_t('Insert image')}
            </Text>
            <ActionIcon variant="transparent" w={40} h={40} onClick={close}>
              <CloseIcon width={24} height={24} stroke="#4D4D4D" />
            </ActionIcon>
          </Group>

          <UploadImage isPublic onUpload={setImage} onFileSelected={setFileSelected} />

          <Group hidden={!fileSelected} spacing={16} pt={8} pb={16} position="right">
            <Button variant="link" w={76} h={36} onClick={close}>
              {_t('Cancel')}
            </Button>
            <Button variant="primary" onClick={insertImage} disabled={!image}>
              {_t('Insert')}
            </Button>
          </Group>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}
