/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./department-update-action-body').DepartmentUpdateActionBody} DepartmentUpdateActionBody
 */

/**
 * @typedef {import('./department-update-action-response').DepartmentUpdateActionResponse} DepartmentUpdateActionResponse
 */

/**
 * The path of the DepartmentUpdateAction action.
 */
export const departmentUpdateActionPath = new SweetPath('/api/user/department/{department_id}', { path: '{param}' });

/**
 * Creates a contextualized DepartmentUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createDepartmentUpdateAction(axios) {
  /**
   * The departmentUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: DepartmentUpdateActionBody } }
   *
   * @returns {Promise<DepartmentUpdateActionResponse>}
   */
  function departmentUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = departmentUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return departmentUpdateAction;
}
