/** @typedef {import('@mantine/core').CSSObject} CSSObject */
/** @typedef {import('@mantine/core').MantineTheme} MantineTheme */
/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Button']} ButtonTheme */

/** @typedef {'primary' | 'secondary' | 'compact' | 'link' | 'primary-dark' | 'secondary-dark' | 'compact-dark' | 'link-dark'} ButtonVariant */

export const BUTTON_VARIANTS = [
  'primary',
  'secondary',
  'compact',
  'link',
  'primary-dark',
  'secondary-dark',
  'compact-dark',
  'link-dark',
];

/**
 * Determines the border style of the button.
 *
 * @param {ButtonVariant} variant
 * @returns {string} - The border style value.
 */
const getButtonBorder = (variant) => {
  if (variant === 'primary' || variant === 'secondary' || variant === 'compact') {
    return '1px solid #38298B';
  }

  if (variant === 'primary-dark' || variant === 'secondary-dark' || variant === 'compact-dark') {
    return '1px solid #FFFFFF';
  }

  return 'none';
};

/**
 * Determines the button radius.
 * @param {string} variant
 * @returns {string} - The radius value.
 */
const getButtonRadius = (variant) => {
  if (variant === 'compact' || variant === 'compact-dark') {
    return '12px';
  }

  return '8px';
};

/**
 * Determines the button height.
 * @param {string} variant - The variant of the button.
 * @returns {string} - The height value.
 */
const getButtonHeight = (variant) => {
  if (variant === 'compact-dark' || variant === 'compact' || variant === 'link' || variant === 'link-dark') {
    return '24px';
  }

  return '36px';
};

/**
 * Determines the button paddingLeft.
 * @param {string} variant - The variant of the button.
 * @returns {string} - The paddingLeft value.
 */
const getButtonPaddingLeft = (variant) => {
  if (variant === 'link' || variant === 'link-dark') {
    return '0px';
  }

  return '16px';
};

/**
 * Determines the button paddingRight.
 * @param {string} variant - The variant of the button.
 * @returns {string} - The paddingRight value.
 */
const getButtonPaddingRight = (variant) => {
  if (variant === 'link' || variant === 'link-dark') {
    return '0px';
  }

  return '16px';
};

/**
 * Determines the button paddingTop.
 * @param {string} variant - The variant of the button.
 * @returns {string} - The paddingTop value.
 */
const getButtonPaddingTop = (variant) => {
  if (variant === 'link' || variant === 'link-dark') {
    return '0px';
  } else if (variant === 'compact-dark' || variant === 'compact') {
    return '4px';
  }

  return '8px';
};

/**
 * Determines the button paddingBottom.
 * @param {string} variant - The variant of the button.
 * @returns {string} - The paddingBottom value.
 */
const getButtonPaddingBottom = (variant) => {
  if (variant === 'link' || variant === 'link-dark') {
    return '0px';
  } else if (variant === 'compact-dark' || variant === 'compact') {
    return '4px';
  }

  return '8px';
};

/**
 * Determines the button backgroundColor.
 * @param {string} variant - The variant of the button.
 * @returns {string} - The backgroundColor value.
 */
const getButtonBackgroundColor = (variant) => {
  if (variant === 'primary' || variant === 'compact') {
    return '#38298B !important';
  } else if (variant === 'secondary' || variant === 'primary-dark' || variant === 'compact-dark') {
    return '#FFFFFF !important';
  }

  return 'transparent';
};

/**
 * Determines the button color.
 * @param {string} variant - The variant of the button.
 * @returns {string} - The color value.
 */
const getButtonColor = (variant) => {
  if (variant === 'primary' || variant === 'compact' || variant === 'secondary-dark' || variant === 'link-dark') {
    return '#FFFFFF';
  } else {
    return '#38298B';
  }
};

/**
 * Determines the button styles for :hover state.
 * @param {string} variant - The variant of the button.
 * @returns {Object} - The styles for :hover state.
 */
const getButtonHoverStyles = (variant) => {
  let backgroundColor, color, border, stroke;

  if (variant === 'primary' || variant === 'compact') {
    backgroundColor = '#908EFB !important';
    color = '#FFFFFF';
    border = '1px solid #908EFB';
    stroke = '#FFFFFF';
  } else if (variant === 'primary-dark' || variant === 'compact-dark') {
    backgroundColor = '#CECDFF !important';
    color = '#38298B';
    border = '1px solid #CECDFF';
    stroke = '#38298B';
  } else if (variant === 'secondary') {
    backgroundColor = 'inherit';
    color = '#5351CE';
    border = '1px solid #5351CE';
    stroke = '#5351CE';
  } else if (variant === 'secondary-dark') {
    backgroundColor = 'inherit';
    color = '#CECDFF';
    border = '1px solid #CECDFF';
    stroke = '#CECDFF';
  } else if (variant === 'link' || variant === 'link-dark') {
    backgroundColor = 'inherit';
    color = variant === 'link' ? '#908EFB' : '#CECDFF';
    border = 'none';
    stroke = variant === 'link' ? '#908EFB' : '#CECDFF';
  } else {
    backgroundColor = 'inherit';
    color = '#38298B';
    border = 'none';
    stroke = '#38298B';
  }

  return {
    '&:hover': {
      backgroundColor,
      color,
      border,
      '& path': {
        stroke,
      },
    },
  };
};

/**
 * Determines the button styles for :focus state.
 * @param {string} variant - The variant of the button.
 * @returns {Object} - The styles for :focus state.
 */
const getButtonFocusStyles = (variant) => {
  let backgroundColor, color, border, stroke;

  if (variant === 'primary' || variant === 'compact') {
    backgroundColor = '#211C57 !important';
    color = '#FFFFFF';
    border = '1px solid #211C57';
    stroke = '#FFFFFF';
  } else if (variant === 'primary-dark' || variant === 'compact-dark') {
    backgroundColor = '#FFFFFF !important';
    color = '#38298B';
    border = '1px solid #CECDFF';
    stroke = '#38298B';
  } else if (variant === 'secondary') {
    backgroundColor = 'transparent';
    color = '#211C57';
    border = '1px solid #211C57';
    stroke = '#211C57';
  } else if (variant === 'secondary-dark') {
    backgroundColor = 'transparent';
    color = '#CECDFF';
    border = '1px solid #CECDFF';
    stroke = '#CECDFF';
  } else if (variant === 'link') {
    backgroundColor = 'transparent';
    color = '#211C57';
    border = 'none';
    stroke = '#211C57';
  } else if (variant === 'link-dark') {
    backgroundColor = 'transparent';
    color = '#FFFFFF';
    border = 'none';
    stroke = '#FFFFFF';
  } else {
    backgroundColor = 'transparent';
    color = '#38298B';
    border = 'none';
    stroke = '#38298B';
  }

  return {
    '&:focus': {
      backgroundColor,
      color,
      border,
      '& path': {
        stroke,
      },
    },
  };
};

/**
 * Determines the button styles for &[data-disabled="true"] state.
 * @param {string} variant - The variant of the button.
 * @returns {Object} - The styles for &[data-disabled="true"] state.
 */
const getButtonDisabledStyles = (variant) => {
  let backgroundColor, color, border, stroke;

  if (variant === 'primary' || variant === 'compact') {
    backgroundColor = '#E6E6E6 !important';
    color = '#B3B3B3';
    border = '1px solid #E6E6E6';
    stroke = '#B3B3B3';
  } else if (variant === 'secondary') {
    backgroundColor = '#FFFFFF';
    color = '#B3B3B3';
    border = '1px solid #B3B3B3';
    stroke = '#B3B3B3';
  } else if (variant === 'primary-dark' || variant === 'compact-dark') {
    backgroundColor = '#5351CE !important';
    color = '#38298B';
    border = '1px solid #5351CE';
    stroke = '#38298B';
  } else if (variant === 'link-dark') {
    backgroundColor = 'transparent';
    color = '#5351CE';
    border = 'none';
    stroke = '#5351CE';
  } else if (variant === 'secondary-dark') {
    backgroundColor = 'transparent';
    color = '#5351CE';
    border = '1px solid #5351CE';
    stroke = '#5351CE';
  } else {
    backgroundColor = 'transparent';
    color = '#B3B3B3';
    border = 'none';
    stroke = '#B3B3B3';
  }

  return {
    '&[data-disabled="true"]': {
      backgroundColor,
      color,
      border,
      '& path': {
        stroke,
      },
    },
  };
};

/**
 * Determines the button styles for &:selected state.
 * @param {string} variant - The variant of the button.
 * @returns {Object} - The styles for &:selected state.
 */
const getButtonSelectedStyles = (variant) => {
  let backgroundColor, color, border, stroke;

  if (variant === 'primary' || variant === 'compact') {
    backgroundColor = '#211C57 !important';
    color = '#FFFFFF';
    border = '1px solid #211C57';
    stroke = '#FFFFFF';
  } else if (variant === 'primary-dark' || variant === 'compact-dark') {
    backgroundColor = '#FFFFFF !important';
    color = '#38298B';
    border = '1px solid #CECDFF';
    stroke = '#38298B';
  } else if (variant === 'secondary') {
    backgroundColor = 'transparent';
    color = '#211C57';
    border = '1px solid #211C57';
    stroke = '#211C57';
  } else if (variant === 'secondary-dark') {
    backgroundColor = 'transparent';
    color = '#CECDFF';
    border = '1px solid #CECDFF';
    stroke = '#CECDFF';
  } else if (variant === 'link') {
    backgroundColor = 'transparent';
    color = '#211C57';
    border = 'none';
    stroke = '#211C57';
  } else {
    backgroundColor = 'transparent';
    color = '#FFFFFF';
    border = 'none';
    stroke = '#FFFFFF';
  }

  return {
    '&:selected': {
      backgroundColor,
      color,
      border,
      '& path': {
        stroke,
      },
    },
  };
};

/**
 * Determines the styles for leftIcon.
 * @param {string} variant - The variant of the button.
 * @returns {Object} - The styles for leftIcon.
 */
const getLeftIconStyles = (variant) => {
  let strokeColor;

  if (variant === 'primary' || variant === 'compact' || variant === 'secondary-dark' || variant === 'link-dark') {
    strokeColor = '#FFFFFF';
  } else {
    strokeColor = '#38298B';
  }

  return {
    marginRight: '4px',
    '& path': {
      stroke: strokeColor,
    },
  };
};

/** @type {ButtonTheme} */
export const Button = {
  /** @type {(theme: MantineTheme, params: {variant: ButtonVariant}) => Record<string,CSSObject>} */
  styles: (theme, { variant }) => {
    return {
      root: {
        transition: 'color 100ms ease, background-color 100ms ease, border-color 100ms ease',
        border: getButtonBorder(variant),
        borderRadius: getButtonRadius(variant),
        fontSize: '15px',
        lineHeight: '20px',
        fontWeight: 400,
        height: getButtonHeight(variant),
        paddingLeft: getButtonPaddingLeft(variant),
        paddingRight: getButtonPaddingRight(variant),
        paddingTop: getButtonPaddingTop(variant),
        paddingBottom: getButtonPaddingBottom(variant),
        backgroundColor: getButtonBackgroundColor(variant),
        color: getButtonColor(variant),
        ...getButtonHoverStyles(variant),
        ...getButtonFocusStyles(variant),
        ...getButtonDisabledStyles(variant),
        ...getButtonSelectedStyles(variant),
      },
      leftIcon: getLeftIconStyles(variant),
    };
  },
};
