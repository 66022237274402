/** @typedef {import('./FileManagerContext').ThumbnailSize} ThumbnailSize */

import { useApi } from 'api/ApiContext';
import { FileManagerContext } from 'api/file-manager/FileManagerContext';
import axios from 'axios';
import Qs from 'qs';
import { _t } from 'lang';
import { useMemo } from 'react';
import { FILE_MANAGER_API_TIMEOUT, FILE_MANAGER_API_URL } from 'environment';

/**
 * File Manager Provider.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export default function FileManagerProvider({ children }) {
  const { jwt } = useApi();

  /**
   * The context value.
   */
  const contextValue = useMemo(() => {
    const connector = axios.create({
      timeout: FILE_MANAGER_API_TIMEOUT,
      baseURL: FILE_MANAGER_API_URL,
    });

    connector.interceptors.request.use((cfg) => {
      // Add JWT to the request.
      if (jwt) {
        if (!cfg.headers) {
          cfg.headers = {};
        }

        if (jwt && !cfg.headers.Authorization) {
          cfg.headers.Authorization = `Bearer ${jwt}`;
        }
      }

      // Serialize deep objects.
      cfg.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false });

      return cfg;
    });

    /**
     * Uploads the file and returns its ID.
     *
     * @param {{
     *   fileName: string;
     *   contents: string;
     *   isPublic?: boolean;
     * }}
     */
    async function uploadFile({ fileName, contents, isPublic = false }) {
      try {
        const { data } = await connector.post('/file/upload', { fileName, contents, isPublic });
        return data.fileId;
      } catch (e) {
        if (e.response.status === 413) {
          throw new Error(_t('The file is too large.'));
        }

        throw e;
      }
    }

    /**
     * Retrieves the contents of the file.
     *
     * @param {{
     *   fileId: string;
     * }}
     *
     * @returns {Promise<Blob>}
     */
    async function readFile({ fileId }) {
      const { data } = await connector.get(`/file/${fileId}`, { responseType: 'blob' });
      return data;
    }

    /**
     * Retrieves the metadata of the file.
     *
     * @param {{
     *   fileId: string;
     * }}
     */
    async function getFileMetadata({ fileId }) {
      const { data } = await connector.get(`/file/${fileId}/metadata`);
      return data;
    }

    /**
     * Zips the provided files and returns the ID of the zip file.
     *
     * @param {{
     *  files: string[];
     * }}
     */
    async function zipFiles({ files }) {
      const { data } = await connector.post('/file/bulk/zip', { files });

      return data.fileId;
    }

    /**
     * Fetches the thumbnail of the file.
     *
     * @param {{
     *   fileId: string;
     *   size?: ThumbnailSize;
     * }}
     */
    async function getFileThumbnail({ fileId, size = '96x96' }) {
      const { data } = await connector.get(`/file/${fileId}/thumbnail/${size}`, { responseType: 'blob' });
      return data;
    }

    /**
     * Initializes a large file upload. Returns the ID of the file.
     *
     * @param {{
     *   fileName: string;
     *   isPublic?: boolean;
     * }}
     *
     * @returns {Promise<string>}
     */
    async function initLargeFile({ fileName, isPublic = false }) {
      const { data } = await connector.post('/file/large/init', { fileName, isPublic });
      return data.fileId;
    }

    /**
     * Uploads a chunk of a large file.
     *
     * @param {{
     *   fileId: string;
     *   contents: string;
     * }}
     */
    async function uploadLargeFile({ fileId, contents }) {
      await connector.post(`/file/large/${fileId}/upload`, { contents });
    }

    /**
     * Finalizes a large file upload.
     *
     * @param {{
     *   fileId: string;
     * }}
     */
    async function finalizeLargeFile({ fileId }) {
      await connector.post(`/file/large/${fileId}/finalize`);
    }

    /**
     * Creates an access token for the file.
     *
     * @param {{
     *   fileId: string;
     * }}
     */
    async function createAccessToken({ fileId }) {
      const { data } = await connector.post(`/file/${fileId}/create-access-token`);
      return data.token;
    }

    return {
      uploadFile,
      readFile,
      getFileMetadata,
      zipFiles,
      getFileThumbnail,
      initLargeFile,
      uploadLargeFile,
      finalizeLargeFile,
      createAccessToken,
    };
  }, [jwt]);

  return <FileManagerContext.Provider value={contextValue}>{children}</FileManagerContext.Provider>;
}
