import { Stack, Switch } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import { useTaskDetail } from '../context/TaskDetailProvider';
import { _t } from 'lang';
import { useMemo } from 'react';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import UploadFiles from 'components/files/upload/UploadFiles';
import AttachmentsList from 'components/files/AttachmentsList';
import { useApi } from 'api/ApiContext';

/**
 * Files tab in the task detail page.
 */
export default function TaskFilesTab() {
  const { hasPermission } = useApi();
  const { taskId, taskAttachments, canDeleteAttachment, deleteAttachment, addAttachment, project } = useTaskDetail();

  const key = useMemo(() => `toolio.task.show.comment.files.${taskId}`, [taskId]);
  const [showCommentAttachments, { setValue: setShowCommentAttachments }] = useLocalStorageDisclosure(key, false);

  const nDirectAttachments = useMemo(
    () => taskAttachments.filter(({ isComment }) => !isComment).length,
    [taskAttachments]
  );

  const nAttachments = showCommentAttachments ? taskAttachments.length : nDirectAttachments;

  return (
    <Stack spacing={16}>
      <GroupHeadline
        className="sticky top-[120px] z-[90] bg-neutral-50"
        collapsible={false}
        heading={_t('FILES')}
        number={nAttachments}
        actions={
          <Switch
            checked={showCommentAttachments}
            label={_t('Include comment files')}
            labelPosition="left"
            onChange={(e) => setShowCommentAttachments(e.currentTarget.checked)}
          />
        }
      />
      {hasPermission('PROJECTS_UPLOAD_FILES', project.client.client_id) && (
        <Stack spacing={0} pb={24}>
          <UploadFiles onFileUploaded={addAttachment} hideUploadedFiles />
        </Stack>
      )}
      <AttachmentsList
        attachments={taskAttachments}
        canDelete={canDeleteAttachment}
        onDelete={deleteAttachment}
        hideCommentAttachments={!showCommentAttachments}
      />
    </Stack>
  );
}
