/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Delete" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function DeleteIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 7.5V17.5C17.5 18.6046 16.6792 19.5 15.6667 19.5H8.33333C7.32081 19.5 6.5 18.6046 6.5 17.5V7.5M15.5 7.0975V6.03867C15.5 4.86913 15.0465 4.5 14 4.5H10C8.95357 4.5 8.5 4.9481 8.5 6.11765V7.17647M5 7.5H19M10.5 10V16.5M13.5 10V13.5V16.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
