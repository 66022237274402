import { useDisclosure } from '@mantine/hooks';
import DeactivateUserModal from 'providers/deactivate-user/DeactivateUserModal';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

/**
 * The props of the deactivate callback.
 *
 * @typedef {{
 *   holdingProjects: {project_id: number, project_name: string, project_full_name: string}[],
 *   leadingTeams: {team_id: number, name: string}[],
 *   directingDepartments: {department_id: number, name: string}[],
 *   userId: number;
 *   onDeactivate?: (() => void) | (() => Promise<void>);;
 * }} DeactivateProps
 */

/**
 * The deactivate callback.
 *
 * @typedef {(props: DeactivateProps) => void} DeactivateCallback
 */

/**
 * The deactivate context.
 *
 * @type {React.Context<{
 *   deactivate: DeactivateCallback;
 * }>}
 */
const DeactivateContext = createContext();

/**
 * Provides the deactivate context.
 *
 * @param {{
 *   children: React.ReactNode,
 * }}
 */
export function DeactivateProvider({ children }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [props, setProps] = useState({});

  /**
   * Opens the deactivate modal.
   *
   * @type {DeactivateCallback}
   */
  const deactivate = useCallback(
    (props) => {
      setProps(props);
      open();
    },
    [setProps, open]
  );

  const value = useMemo(() => ({ deactivate }), [deactivate]);

  return (
    <DeactivateContext.Provider value={value}>
      {children}
      <DeactivateUserModal opened={opened} onClose={close} {...props} />
    </DeactivateContext.Provider>
  );
}

/**
 * Uses the deactivate context.
 */
export function useDeactivateUser() {
  return useContext(DeactivateContext);
}
