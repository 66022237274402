import { ReactRenderer } from '@tiptap/react';
import { MentionList } from 'components/inputs/wysiwyg/extensions/mention-list/MentionList';

export default {
  render: () => {
    let component, popup;
    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        // Create custom popup using vanilla JS instead of Mantine's Popover
        popup = document.createElement('div');
        popup.className = 'suggestion-popup';
        popup.style.position = 'absolute';
        const rect = props.clientRect();
        popup.style.top = `${rect.top + window.scrollY + rect.height * 1.5}px`;
        popup.style.left = `${rect.left}px`;
        popup.appendChild(component.element);
        document.body.appendChild(popup);
      },
      /**
       * Updates the component's props and position of a custom popup.
       *
       * @param {Object} props - The props to be updated.
       * @returns {void}
       */
      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        const rect = props.clientRect();

        // Update custom popup's position with new clientRect
        popup.style.top = `${rect.top + window.scrollY + rect.height * 1.5}px`;
        popup.style.left = `${rect.left}px`;
      },
      /**
       * Handles the onKeyDown event.
       *
       * @param {Object} props - The props to be passed to the component.
       * @param {Event} props.event - The event object that triggered the onKeyDown event.
       * @param {HTMLElement} [popup] - The popup element to be removed from the document body when the Escape key is pressed.
       * @param {HTMLElement} [component.ref] - The ref of the component that contains the onKeyDown method.
       *
       * @return {boolean} - Returns true if the Escape key is pressed and the popup element is successfully removed.
       *                    Otherwise, it returns the result of calling the onKeyDown method of the component.
       */
      onKeyDown(props) {
        if (props.event.key === 'Escape' && document.body.contains(popup)) {
          document.body.removeChild(popup);
          return true;
        }
        return component.ref?.onKeyDown(props);
      },
      /**
       * Destroys the popup and component when exiting.
       *
       * @returns {undefined}
       */
      onExit() {
        if (!document.body.contains(popup)) {
          return;
        }
        document.body.removeChild(popup);
        component.destroy();
      },
    };
  },
};
