/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./health-check-action-body').HealthCheckActionBody} HealthCheckActionBody
 */

/**
 * @typedef {import('./health-check-action-response').HealthCheckActionResponse} HealthCheckActionResponse
 */

/**
 * The path of the HealthCheckAction action.
 */
export const healthCheckActionPath = new SweetPath('/api/status/health-check', { path: '{param}' });

/**
 * Creates a contextualized HealthCheckAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createHealthCheckAction(axios) {
  /**
   * The healthCheckAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<HealthCheckActionResponse>}
   */
  function healthCheckAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = healthCheckActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return healthCheckAction;
}
