import EmptyTab from 'pages/finance/cost-estimate/detail/EmptyTab';
import { _t } from 'lang';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import CollapseArrow from 'components/CollapseArrow';
import InvoiceIcon from 'components/icons/InvoiceIcon';
import { Link } from 'react-router-dom';
import { Collapse, Menu } from '@mantine/core';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import EditIcon from 'components/icons/EditIcon';
import TabDetailRow from 'pages/finance/cost-estimate/detail/TabDetailRow';
import FinanceStatusBubble from 'components/FinanceStatusBubble';
import Alert from 'components/Alert';
import ProgressGroup from 'components/progress/ProgressGroup';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import FileDownloadLink from 'components/files/FileDownloadLink';
import PriceDisplay from 'components/PriceDisplay';
import { useApi } from 'api/ApiContext';

/**
 * The tab for the invoice in the cost estimate detail page.
 */
export default function InvoiceTab() {
  const { costEstimate, links, currency, isClosed } = useCostEstimateDetail();
  const { workspaceConfig } = useApi();

  const [opened, { toggle: toggleOpened }] = useDisclosure(true);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const invoice = costEstimate.invoice;
  const invoiceRequest = costEstimate.invoice_request;
  const purchaseOrder = costEstimate.purchase_order;

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';

  return (
    <div>
      <div className="flex flex-col gap-4 px-8 py-4">
        {invoice ? (
          <div className="rounded-[8px] bg-white py-4">
            <div>
              <div className="grid grid-cols-[1fr_1fr_120px_32px] gap-2 px-2 text-[12px] leading-[18px] text-neutral-500">
                <div className="pl-9">{_t('Document name')}</div>
                <div>{_t('PO number')}</div>
                <div className="text-right">{_t('Amount')}</div>
              </div>
              <div className="group grid h-12 grid-cols-[1fr_1fr_120px_32px] items-center justify-between gap-2 border-b border-b-neutral-100 bg-white px-2  hover:bg-neutral-20">
                <div
                  className="flex w-full cursor-pointer items-center gap-x-2 py-3 text-[20px] leading-[36px] text-neutral-700"
                  onClick={toggleOpened}
                >
                  <div className="h-6 w-6">
                    <CollapseArrow opened={opened} />
                  </div>
                  <div className="flex flex-row items-center gap-1 text-[15px] leading-[18px] text-neutral-700">
                    <InvoiceIcon fill="#4D4D4D" />
                    <span>{invoice.invoice_name}</span>
                  </div>
                </div>
                {purchaseOrder ? (
                  <Link
                    replace
                    className="-ml-2 w-fit py-3 pl-2 pr-2 text-[15px] leading-[18px] text-main-primary"
                    to={links.purchaseOrder.detail}
                  >
                    {purchaseOrder.purchase_order_name}
                  </Link>
                ) : (
                  <div className="py-3 text-[15px] leading-[18px]">-</div>
                )}
                <PriceDisplay
                  size="sm"
                  value={costEstimate.invoiced_sum}
                  currency={currency}
                  className="text-right text-neutral-700"
                />
                <div className={`flex justify-end py-3 ${menuClasses}`}>
                  <Menu className="hidden" width={220} position="bottom-end" offset={15} onChange={setIsMenuOpened}>
                    <Menu.Target>
                      <div className="-my-2 cursor-pointer py-2">
                        <OptionsDotsIcon />
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item icon={<EditIcon fill="#4D4D4D" />}>
                        {_t('Edit')} / {_t('View')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </div>
              <Collapse in={opened} className="border-b border-b-neutral-100 bg-white py-4 pl-[68px]">
                <div className="flex flex-col gap-y-2">
                  {/* TODO status */}
                  <TabDetailRow
                    title={_t('Status')}
                    value={<FinanceStatusBubble status={_t('Closed')} variant="default" />}
                  />
                  <TabDetailRow
                    title={_t('Invoice number')}
                    value={
                      <FileDownloadLink h={18} fileId={invoice.file_id}>
                        {invoice.invoice_name}
                      </FileDownloadLink>
                    }
                  />
                  <TabDetailRow
                    title={_t('Invoiced amount')}
                    value={<PriceDisplay size="md" value={costEstimate.invoiced_sum} currency={currency} />}
                  />
                  <TabDetailRow title={_t('Invoice date')} value={new Date(invoice.issued).toLocaleDateString()} />
                  <TabDetailRow title={_t('Date of supply')} value={new Date(invoice.supplied).toLocaleDateString()} />
                  <TabDetailRow
                    title={_t('Payment due date')}
                    value={new Date(invoice.due_date).toLocaleDateString()}
                  />
                  <TabDetailRow title={_t('Invoice subject')} value={invoiceRequest ? invoiceRequest.note : '-'} />
                  <TabDetailRow title={_t('Invoice description or note')} value={invoice.note} />
                  <TabDetailRow title={_t('Processed by')} value={invoice.author.full_name} />
                  <TabDetailRow
                    title={_t('Date of processing')}
                    value={new Date(invoice.created_at).toLocaleString()}
                  />
                </div>
              </Collapse>
            </div>
          </div>
        ) : !isClosed ? (
          <EmptyTab title={_t('Add invoice')} path={links.invoice.add} />
        ) : (
          <></>
        )}
      </div>
      {invoiceRequest && (
        <div className="px-8 py-6">
          <Alert
            primaryText={_t('Invoice request created by %s, %s', invoiceRequest.author.full_name, new Date(invoiceRequest.created_at).toLocaleString())} // prettier-ignore
          />
        </div>
      )}
      <div className="flex justify-end pl-[42px] pr-12">
        <div className="flex w-[270px] flex-col items-end justify-center gap-2 text-right">
          <div className="flex h-6 flex-row items-end gap-2 text-[15px] leading-[18px] text-neutral-700">
            <span>{_t('Total invoiced amount')} </span>
            <PriceDisplay
              value={costEstimate.invoiced_sum}
              currency={workspaceConfig.currency}
              valueClassName="font-semibold"
            />
          </div>
          <ProgressGroup variant="constant" total={costEstimate.budget} part={costEstimate.invoiced_sum} />
          <div className="flex h-12 flex-row items-end justify-center gap-2 pt-6 text-[15px] leading-[18px] text-neutral-700">
            <span>{_t('from budgeted')} </span>
            <PriceDisplay
              value={costEstimate.budget}
              currency={workspaceConfig.currency}
              valueClassName="font-semibold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
