import darkLogo from 'assets/images/logo-dark.svg';
import lightLogo from 'assets/images/logo-light.svg';

/**
 * Toolio logo.
 *
 * @param {{
 *   width?: string,
 *   height?: string,
 *   variant?: 'light' | 'dark',
 * }}
 */
export default function ToolioLogo({ width = '40px', height = '40px', variant = 'light' }) {
  return <img src={variant === 'light' ? lightLogo : darkLogo} width={width} height={height} />;
}
