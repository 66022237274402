import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/ApiContext';
import SupplierForm from 'components/forms/supplier-form/SupplierForm';
import panic from 'errors/Panic';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import { useNavigate } from 'react-router-dom';
import { SUPPLIERS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * The Add Supplier page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=522%3A26734
 */
export default function AddSupplierPage() {
  const navigate = useNavigate();
  const { getAction, workspaceConfig } = useApi();
  const { refreshSuppliers } = useSettingsSidebar();

  const initialValues = {
    name: '',
    ico: '',
    dic: '',
    icDph: '',
    streetAndNumber: '',
    city: '',
    zipCode: '',
    country: '',
    note: '',
    contactPerson: [],
    language: 'sk',
    currency: workspaceConfig.currency,
    supplierType: 'test', // TODO create input
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({
    name,
    ico,
    dic,
    icDph,
    streetAndNumber,
    city,
    zipCode,
    country,
    note,
    contactPerson,
    language,
    currency,
    supplierType,
  }) => {
    const supplierCreateAction = getAction('SupplierCreateAction');

    return supplierCreateAction({
      body: {
        supplier_name: name,
        ico,
        dic,
        ic_dph: icDph,
        street_and_number: streetAndNumber,
        city: city,
        zip_code: zipCode,
        country: country,
        note,
        contact_persons: contactPerson.map(({ name, email, phone }) => ({ full_name: name, email, phone })),
        language,
        currency,
        supplier_type: supplierType,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Supplier created'),
          message: _t("Supplier '%s' has been created successfully.", name),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshSuppliers();

        navigate(SUPPLIERS_PAGE_PATH.original);
      })
      .catch((e) => {
        panic(e);

        navigate(SUPPLIERS_PAGE_PATH.original);
      });
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Add new supplier')} />
        <SupplierForm initialValues={initialValues} onSubmit={submit} />
      </div>
    </DashboardLayout>
  );
}
