import { Navbar } from '@mantine/core';
import ToolioLogo from 'components/ToolioLogo';
import { Link } from 'react-router-dom';
import {
  FINANCE_PROJECT_OVERVIEW_PAGE_PATH,
  PROJECT_DASHBOARD_PAGE_PATH,
  TIME_ENTRIES_EXPORT_PAGE_PATH,
  TIME_ENTRIES_OVERVIEW_PAGE_PATH,
  USERS_PAGE_PATH,
} from 'routes/paths';
import AppSidebarItem from 'layouts/dashboard-layout/AppSidebarItem';
import DuplicateIcon from 'components/icons/DuplicateIcon';
import SettingsIcon from 'components/icons/SettingsIcon';
import InvoicedIcon from 'components/icons/InvoicedIcon';
import LogTimeIcon from 'components/icons/LogTimeIcon';
import { useApi } from 'api/ApiContext';
import ReportsIcon from 'components/icons/ReportsIcon';

/**
 * App Sidebar.
 */
export default function AppSidebar() {
  const { hasPermission, workspaceConfig } = useApi();

  return (
    <Navbar className="flex h-screen flex-col items-center justify-between border-none bg-primary-dark-blue">
      <div className="toolio-sidebar">
        <div className="toolio-logo pb-4 pt-8">
          <Navbar.Section>
            <Link to={PROJECT_DASHBOARD_PAGE_PATH.original}>
              <ToolioLogo variant="light" />
            </Link>
          </Navbar.Section>
        </div>
        <div className="toolio-main-menu flex h-[60vh] flex-col items-center gap-[21px] pt-[24px]">
          {hasPermission('PROJECTS_VIEW_PROJECT', '*') && (
            <Navbar.Section>
              <AppSidebarItem
                // TODO this icon is temporary
                linkIcon={<DuplicateIcon stroke="#FFFFFF" />}
                pagePath={PROJECT_DASHBOARD_PAGE_PATH.original}
                base="projects"
                activeStrokeColor="#4D4D4D"
              />
            </Navbar.Section>
          )}
          {workspaceConfig.features.finance && (
            <Navbar.Section>
              <AppSidebarItem
                linkIcon={<InvoicedIcon stroke="#FFFFFF" />}
                pagePath={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}
                base="finance"
                activeStrokeColor="#4D4D4D"
              />
            </Navbar.Section>
          )}
          <Navbar.Section>
            <AppSidebarItem
              linkIcon={<LogTimeIcon stroke="#FFF" />}
              pagePath={TIME_ENTRIES_OVERVIEW_PAGE_PATH.original}
              base="time-entries"
              activeStrokeColor="#4D4D4D"
            />
          </Navbar.Section>
          {hasPermission('PROJECTS_VIEW_PROJECT', '*') && (
            <Navbar.Section>
              <AppSidebarItem
                linkIcon={<ReportsIcon stroke="#FFF" />}
                pagePath={TIME_ENTRIES_EXPORT_PAGE_PATH.original}
                base="reports"
                activeStrokeColor="#4D4D4D"
              />
            </Navbar.Section>
          )}
          {hasPermission('SETTINGS_MANAGE_SETTINGS') && (
            <Navbar.Section>
              <AppSidebarItem
                linkIcon={<SettingsIcon stroke="#FFFFFF" />}
                pagePath={USERS_PAGE_PATH.original}
                base="settings"
                activeStrokeColor="#4D4D4D"
              />
            </Navbar.Section>
          )}
        </div>
      </div>
    </Navbar>
  );
}
