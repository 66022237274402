import { Box, Checkbox, Group, Stack } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import SectionTitle from 'components/forms/FormSectionTitle';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';

/**
 * Allows to select multiple clients.
 *
 * @param {{
 *  value: string[],
 *  onChange: (value: string[]) => void,
 * }}
 */
export default function ClientCheckboxSelect({ value, onChange = noop }) {
  const { getAction } = useApi();
  const [options, setOptions] = useState([]);

  /**
   * Handles the checkbox change.
   * @param {boolean} newState
   * @param {number} clientId
   */
  function handleCheck(newState, clientId) {
    if (newState) {
      onChange([...value, String(clientId)]);
    } else {
      onChange(value.filter((v) => v !== String(clientId)));
    }
  }

  useEffect(() => {
    const clientGetListAction = getAction('ClientGetListAction');

    clientGetListAction()
      .then((clients) => {
        setOptions(clients);
      })
      .catch(panic);
  }, []);

  return (
    <Stack spacing={16}>
      <SectionTitle title={_t('Access data of clients')} />

      {options.map((client) => (
        <Group key={client.client_id}>
          <Box w={240} py={8}>
            {client.client_name}
          </Box>
          <Checkbox
            value={client.client_id}
            size="md"
            onChange={(e) => handleCheck(e.currentTarget.checked, client.client_id)}
            checked={value.includes(String(client.client_id))}
          />
        </Group>
      ))}
    </Stack>
  );
}
