/**
 * Icon used by the AlignRight control component.
 */
export default function IconAlignRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="m 12.484073,14.9821 h 5.000001 V 13.3154 H 12.484073 Z M 7.4858657,9.1977979 V 10.864429 H 17.485866 V 9.1977979 Z M 2.5,5 v 1.66667 h 15 V 5 Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
