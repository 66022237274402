import { _t } from 'lang';
import { Tabs, TextInput, Stack } from '@mantine/core';
import FormRow from 'components/forms/FormRow';
import { useForm } from '@mantine/form';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';

/**
 * @typedef {{
 *   invoiceRequestEmail: string,
 *   paymentRequestEmail: string,
 * }} GlobalSettingsFormData
 */

/**
 * Global settings form.
 *
 * @param {{
 *   initialValues: GlobalSettingsFormData,
 *   onSubmit: (data: GlobalSettingsFormData) => void | Promise<void>,
 * }}
 */
export default function GlobalSettingsForm({ initialValues, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);

  const form = useForm({
    initialValues,
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Tabs defaultValue="Instance setup">
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="Instance setup">{_t('Instance setup')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="Instance setup">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Invoice request email')}
              input={
                <TextInput
                  type="email"
                  placeholder={_t('username@company.com')}
                  autoFocus
                  {...form.getInputProps('invoiceRequestEmail')}
                />
              }
            />
            <FormRow
              label={_t('Payment request email')}
              input={
                <TextInput
                  type="email"
                  placeholder={_t('username@company.com')}
                  {...form.getInputProps('paymentRequestEmail')}
                />
              }
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
