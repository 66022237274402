import { Loader } from '@mantine/core';
import { useMemo } from 'react';

/**
 * Loader component.
 *
 * @param {{
 *   size: 16|32|64,
 *   variant: 'default'|'inverse'
 * } & Omit<import('@mantine/core').LoaderProps, 'size'|'variant'>}
 */
export default function Preloader({ size = 16, variant = 'default', ...props }) {
  const className = useMemo(() => (variant === 'inverse' ? 'toolio-preloader-inverse' : 'toolio-preloader'), [variant]);

  return <Loader {...props} size={size} className={className} />;
}
