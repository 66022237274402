/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./purchase-order-get-list-action-body').PurchaseOrderGetListActionBody} PurchaseOrderGetListActionBody
 */

/**
 * @typedef {import('./purchase-order-get-list-action-response').PurchaseOrderGetListActionResponse} PurchaseOrderGetListActionResponse
 */

/**
 * The path of the PurchaseOrderGetListAction action.
 */
export const purchaseOrderGetListActionPath = new SweetPath('/api/finance/purchase_order', { path: '{param}' });

/**
 * Creates a contextualized PurchaseOrderGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPurchaseOrderGetListAction(axios) {
  /**
   * The purchaseOrderGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<PurchaseOrderGetListActionResponse>}
   */
  function purchaseOrderGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = purchaseOrderGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return purchaseOrderGetListAction;
}
