import { MIN_PASSWORD_LENGTH } from 'utils/constants';

/**
 * Checks whether the password is valid - if it has at least MIN_PASSWORD_LENGTH, at least one number, capital letter and symbol.
 *
 * @param {String} password
 */
export const isPasswordValid = (password) => {
  const hasNeededLength = password.length >= MIN_PASSWORD_LENGTH;
  const hasNumber = /\d/.test(password);
  const hasCapital = /[A-Z]/.test(password);
  const hasSymbol = /[^0-9a-zA-Z]/.test(password);

  return hasNeededLength && hasNumber && hasCapital && hasSymbol;
};
