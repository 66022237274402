/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-create-from-duration-action-body').TimeLogCreateFromDurationActionBody} TimeLogCreateFromDurationActionBody
 */

/**
 * @typedef {import('./time-log-create-from-duration-action-response').TimeLogCreateFromDurationActionResponse} TimeLogCreateFromDurationActionResponse
 */

/**
 * The path of the TimeLogCreateFromDurationAction action.
 */
export const timeLogCreateFromDurationActionPath = new SweetPath(
  '/api/time_log/create_from_duration/{project_id}/{task_id}',
  { path: '{param}' }
);

/**
 * Creates a contextualized TimeLogCreateFromDurationAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogCreateFromDurationAction(axios) {
  /**
   * The timeLogCreateFromDurationAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TimeLogCreateFromDurationActionBody } }
   *
   * @returns {Promise<TimeLogCreateFromDurationActionResponse>}
   */
  function timeLogCreateFromDurationAction({ headers = {}, parameters = {}, body } = {}) {
    const path = timeLogCreateFromDurationActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return timeLogCreateFromDurationAction;
}
