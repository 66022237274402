import { Box, Avatar as MantineAvatar, Tooltip } from '@mantine/core';
import { createMonogram } from 'utils/create-monogram';

/**
 * Parameters of the Avatar component.
 *
 * @typedef {{
 *   image: string;
 *   label: string;
 *   size: number;
 *   color?: string;
 *   style: object;
 * }} AvatarParams
 */

/**
 * Avatar with monogram or image.
 *
 * @param {AvatarParams & {
 *   cursor: 'default' | 'pointer';
 * }}
 */
function AvatarInner({ image, label, size, style, cursor, color = '#38298B' }) {
  return image ? (
    <MantineAvatar radius="50%" src={image} size={size} style={style} />
  ) : (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        backgroundColor: color,
        lineHeight: `${Math.min(16, size / 2)}px`,
        fontSize: `${Math.min(16, size / 2)}px`,
        cursor,
        ...style,
      }}
      className="flex select-none items-center justify-center rounded-full text-white hover:text-complementary-ametyst focus:text-main-secondary disabled:text-neutral-300"
    >
      <span>{createMonogram(label)}</span>
    </div>
  );
}

/**
 * Avatar with monogram or image.
 *
 * @param {AvatarParams & {
 *   withTooltip: boolean;
 *   cursor: 'default' | 'pointer';
 * }}
 */
export default function Avatar({ withTooltip, cursor = 'default', label, ...rest }) {
  const inner = <AvatarInner label={label} cursor={cursor} {...rest} />;

  if (withTooltip) {
    return (
      <Tooltip label={label} offset={4} openDelay={150}>
        <Box>{inner}</Box>
      </Tooltip>
    );
  }

  return inner;
}
