import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconAlignLeft from 'components/inputs/wysiwyg/controls/align-left/IconAlignLeft';

/**
 * Used to align text to the left.
 */
export default function ControlAlignLeft() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Align left" openDelay={100} closeDelay={50}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().setTextAlign('left').run()}
        active={editor?.isActive({ textAlign: 'left' })}
      >
        <IconAlignLeft />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
