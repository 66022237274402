/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['RichTextEditor']} RichTextEditorTheme */

/** @type {RichTextEditorTheme} */
export const RichTextEditor = {
  styles: (theme) => ({
    toolbar: {
      gap: '16px',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    control: {
      '&:hover': {
        backgroundColor: `${theme.colors.neutral20[0]} !important`,
      },
      '&[data-active="true"]': {
        backgroundColor: `${theme.colors.neutral50[0]} !important`,
      },
      '&[data-active="true"]:hover': {
        backgroundColor: `${theme.colors.neutral100[0]} !important`,
      },
    },
    root: {
      position: 'relative',
      overflow: 'hidden',
      resize: 'both',
      maxHeight: 'calc(50vh + 47px)',
      minHeight: '170px',
      zIndex: 1,
      '& .toolio-img-wrapper': {
        width: 'fit-content',
        maxWidth: '100%',
        height: 'fit-content',
        position: 'relative',
      },
      '& .toolio-img-resizer': {
        display: 'none',
        position: 'absolute',
        right: '-5px',
        bottom: '-5px',
        width: '10px',
        height: '10px',
        backgroundColor: theme.fn.primaryColor(),
        cursor: 'nwse-resize',
      },
      '& .toolio-img-wrapper.ProseMirror-selectednode': {
        outline: `2px solid ${theme.fn.primaryColor()}`,
      },
      '& .toolio-img-wrapper.ProseMirror-selectednode .toolio-img-resizer': {
        display: 'block',
      },
    },
    typographyStylesProvider: {
      height: '100%',
      paddingTop: '47px',
    },
    content: {
      height: '100%',
    },
    linkEditor: {
      // todo
    },
    linkEditorSave: {
      // todo
    },
    linkEditorInput: {
      // todo
    },
    linkEditorExternalControl: {
      '&[data-active]': {
        backgroundColor: '#F2F2F2 !important',
        borderColor: '#ced4da !important',
      },
    },
    linkEditorDropdown: {
      // todo
    },
  }),
  classNames: {
    linkEditor: 'gap-2',
    linkEditorInput: 'border min-w-[278px] border-neutral-300 border-r border-solid rounded-lg',
    linkEditorSave:
      'border border-solid border-main-primary text-main-primary rounded-lg hover:border hover:border-solid hover:border-main-primary hover:text-complementary-ametyst',
  },
};
