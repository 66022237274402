/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "FiletypeDoc" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function FiletypeDocIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 4.61929 6.11929 3.5 7.5 3.5H14.0925C14.8134 3.5 15.4992 3.8112 15.9739 4.35374L18.3814 7.10521C18.7802 7.56094 19 8.14591 19 8.75147V11C19 11.2761 18.7761 11.5 18.5 11.5C18.2239 11.5 18 11.2761 18 11V8.75147C18 8.6667 17.9928 8.5826 17.9788 8.5H15.5C14.6716 8.5 14 7.82843 14 7V4.5H7.5C6.67157 4.5 6 5.17157 6 6V19C6 19.8284 6.67157 20.5 7.5 20.5H13.5C13.7761 20.5 14 20.7239 14 21C14 21.2761 13.7761 21.5 13.5 21.5H7.5C6.11929 21.5 5 20.3807 5 19V6ZM15.2213 5.01224L17.3981 7.5H15.5C15.2239 7.5 15 7.27614 15 7V4.80568C15.0799 4.86639 15.1541 4.93545 15.2213 5.01224Z"
        fill={fill}
      />
      <path
        d="M12 14.5V17.5C12 18.0522 12.4478 18.5 13 18.5H14C14.5522 18.5 15 18.0522 15 17.5V14.5C15 13.9478 14.5522 13.5 14 13.5H13C12.4478 13.5 12 13.9478 12 14.5ZM14 17.5H13V14.5H14V17.5Z"
        fill={fill}
      />
      <path
        d="M8 13.5V18.5H9.5C10.3285 18.5 11 17.8285 11 17V15C11 14.1715 10.3285 13.5 9.5 13.5H8ZM9.5 17.5H9V14.5H9.5C9.77625 14.5 10 14.7238 10 15V17C10 17.2762 9.77625 17.5 9.5 17.5Z"
        fill={fill}
      />
      <path
        d="M16 14.5V17.5C16 18.0522 16.4478 18.5 17 18.5H18C18.5522 18.5 19 18.0522 19 17.5V16.75H18V17.5H17V14.5H18V15.25H19V14.5C19 13.9478 18.5522 13.5 18 13.5H17C16.4478 13.5 16 13.9478 16 14.5Z"
        fill={fill}
      />
    </svg>
  );
}
