/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./position-in-company-update-action-body').PositionInCompanyUpdateActionBody} PositionInCompanyUpdateActionBody
 */

/**
 * @typedef {import('./position-in-company-update-action-response').PositionInCompanyUpdateActionResponse} PositionInCompanyUpdateActionResponse
 */

/**
 * The path of the PositionInCompanyUpdateAction action.
 */
export const positionInCompanyUpdateActionPath = new SweetPath(
  '/api/user/position_in_company/{position_in_company_id}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PositionInCompanyUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPositionInCompanyUpdateAction(axios) {
  /**
   * The positionInCompanyUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PositionInCompanyUpdateActionBody } }
   *
   * @returns {Promise<PositionInCompanyUpdateActionResponse>}
   */
  function positionInCompanyUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = positionInCompanyUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return positionInCompanyUpdateAction;
}
