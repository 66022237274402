/** @typedef {import('api/actions/user-task-activity-get-action/user-task-activity-get-action-response').UserTaskActivityGetActionResponse['tasks'][number]} Task */

import { Box, Collapse, Flex, Grid, Text } from '@mantine/core';
import CollapseArrow from '../../CollapseArrow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formattedTime } from './TimeEntriesTable';
import TaskEntryColumn from './TaskEntryColumn';
import TimeLogColumn from './TimeLogColumn';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import PinItemIcon from 'components/icons/PinItemIcon';
import PinItemActiveIcon from 'components/icons/PinItemActiveIcon';
import { PROJECT_DASHBOARD_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH, TASK_DETAIL_PAGE_PATH } from '../../../routes/paths';
import { Link } from 'react-router-dom';

/**
 * Represents a time entries table row.
 *
 * @param {{
 *  filters: {
 *    showWeekends: boolean,
 *    orderBy: string,
 *  },
 *  actualWeek?: Date[],
 *  task: Task,
 *  refresh: () => void,
 * }} props - The component props.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function TimeEntriesTableRow({ filters, actualWeek, task, refresh }) {
  const { getAction } = useApi();

  const localStorageKey = useMemo(() => `toolio.time-entries-row.${task.task_id}.opened`, [task.task_id]);
  const [localOpened, { toggle: toggleOpened }] = useLocalStorageDisclosure(localStorageKey);

  const [weekendLogExists, setWeekendLogExists] = useState(false);
  const [iconHover, setIconHover] = useState(false);

  const pinItemIconFill = useMemo(() => (iconHover ? '#BF0D38' : '#4D4D4D'), [iconHover]);

  /**
   * Group time logs by day.
   *
   * @param {Array} timeLogs - The array of time logs.
   * @returns {Object} - An object containing time logs grouped by day.
   */
  const groupedTimeLogsByDay = task.time_logs?.reduce((acc, log) => {
    const date = new Date(log.start).getDate();
    acc[date] = acc[date] || [];
    acc[date].push(log);
    return acc;
  }, {});

  /**
   * Calculates the total time logged for each day in a given task.
   *
   * @param {Array} task.time_logs - Array of time log objects.
   *
   * @returns {Object} - An object containing the total time logged for each day.
   */
  const groupedTotalTimeLogsByDay = task.time_logs?.reduce((acc, log) => {
    const date = new Date(log.start).getDate();
    acc[date] = acc[date] || 0;
    acc[date] += log.duration;
    return acc;
  }, {});

  /**
   * Calculates the total time by the actual week.
   *
   * @param {Array<Date>} actualWeek - The array of dates representing the actual week.
   * @param {Object} groupedTotalTimeLogsByDay - The object containing the grouped total time logs by day.
   * @returns {number} - The total time for the actual week.
   */
  const totalTimeByActualWeek = actualWeek?.reduce((acc, date) => {
    return acc + (groupedTotalTimeLogsByDay[date.getDate()] || 0);
  }, 0);

  /**
   * @type {number} max_number_of_rows - The maximum number of rows.
   */
  const max_number_of_rows = Object.values(groupedTimeLogsByDay).reduce((max, logs) => Math.max(max, logs.length), 0);

  useEffect(() => {
    const hasWeekendLogs = task.time_logs.some((log) => {
      const date = new Date(log.start).getDay();

      return date === 0 || date === 6;
    });

    setWeekendLogExists(hasWeekendLogs);
  }, []);

  /**
   * Creates a pin for a task.
   */
  const pinRow = useCallback(() => {
    const action = getAction('UserTimesheetPinUpdateAction');
    action({ parameters: { task_id: task.task_id } })
      .then(() => refresh())
      .catch(panic);
  }, [task.task_id, refresh, getAction]);

  return (
    <>
      <Grid
        m={0}
        w="100%"
        columns={filters.showWeekends ? 11 : 9}
        align="center"
        className="group border-b border-b-neutral-50 hover:bg-neutral-50"
      >
        <Grid.Col span={3} py={0} pl={16}>
          <Flex gap={8} align="center">
            <Box
              onClick={pinRow}
              className="cursor-pointer"
              onMouseEnter={() => {
                setIconHover(true);
              }}
              onMouseLeave={() => {
                setIconHover(false);
              }}
            >
              {task.is_pinned ? <PinItemActiveIcon /> : <PinItemIcon fill={pinItemIconFill} />}
            </Box>
            <Box
              onClick={() => toggleOpened()}
              className={`${task.time_logs.length > 0 ? 'visible cursor-pointer' : 'invisible'} `}
            >
              <CollapseArrow opened={localOpened} stroke="#4D4D4D" />
            </Box>
            <Flex direction="column">
              <Text fz={18} lh="24px" align="left" className="flex items-center">
                <Link
                  className="transition-colors hover:text-hyperlink"
                  to={TASK_DETAIL_PAGE_PATH.insert({ taskId: task?.task_id })}
                >
                  {task.task_full_name}
                </Link>
                {task.closed_at && (
                  <span className="flex items-center gap-x-1 pl-2 text-xs">
                    <span className="h-2 w-2 rounded-full bg-status-red"></span> {_t('Closed')}
                  </span>
                )}
                {task.deleted_at && (
                  <span className="flex items-center gap-x-1 pl-2 text-xs">
                    <span className="h-2 w-2 rounded-full bg-status-red"></span> {_t('Deleted')}
                  </span>
                )}
              </Text>
              <Text fz={12} lh="16px" className="text-grey-500" align="left">
                <Link
                  className="transition-colors hover:text-hyperlink"
                  to={PROJECT_DETAIL_PAGE_PATH.insert({ projectId: task?.project?.project_id })}
                >
                  {task.project.project_full_name}
                </Link>
              </Text>
              <Text fz={12} lh={16 / 12} c="#808080" align="left">
                <Link
                  className="transition-colors hover:text-hyperlink"
                  to={PROJECT_DASHBOARD_PAGE_PATH.insert() + '?clientId=' + task?.client?.client_id}
                >
                  {task.client.client_name}
                </Link>{' '}
                ({task.points} {_t('p')})
              </Text>
            </Flex>
          </Flex>
        </Grid.Col>
        <Grid.Col span={filters.showWeekends ? 8 : 6}>
          <Grid columns={filters.showWeekends ? 8 : 6} align="stretch">
            {actualWeek.map((day, index) => {
              if (filters.showWeekends === false && (index === 5 || index === 6)) {
                return null;
              }

              /**
               * @type {number|undefined} time_total - The total time.
               */
              const time_total = groupedTotalTimeLogsByDay[day.getDate()];

              return (
                <TaskEntryColumn
                  key={day}
                  filters={filters}
                  date={day}
                  time={time_total}
                  task={task}
                  refresh={refresh}
                />
              );
            })}
            <Grid.Col span={1} pr={16}>
              <Flex align="center" h="100%">
                <Text fz={15} lh="24px" align="right" w="100%" weight="500" display="flex" className="justify-end">
                  {formattedTime(totalTimeByActualWeek || 0)}
                  {weekendLogExists && (
                    <Text lh="16px" className="text-notification-status-warning" align="right">
                      *
                    </Text>
                  )}
                </Text>
              </Flex>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Collapse direction="column" in={localOpened}>
        {Array(max_number_of_rows)
          .fill(0)
          .map((_, index) => {
            const totalTimeByActualRow = actualWeek.reduce((acc, day) => {
              return acc + (groupedTimeLogsByDay?.[day.getDate()]?.[index]?.duration || 0);
            }, 0);

            return (
              <Grid
                key={task.time_logs[index].time_log_id}
                m={0}
                w="100%"
                columns={filters.showWeekends ? 11 : 9}
                align="stretch"
              >
                <Grid.Col span={3} py={0} className="h-auto border-b border-b-neutral-50"></Grid.Col>
                <Grid.Col span={filters.showWeekends ? 8 : 6}>
                  <Grid columns={filters.showWeekends ? 8 : 6} align="stretch">
                    {actualWeek.map((day, index_week) => {
                      if (filters.showWeekends === false && (index_week === 5 || index_week === 6)) {
                        return null;
                      }

                      const time_logs = groupedTimeLogsByDay?.[day.getDate()] || [];

                      const time = time_logs[index]?.duration;

                      if (!time && time !== 0) {
                        return <TimeLogColumn key={day} date={day} filters={filters} active={false} />;
                      }

                      return (
                        <TimeLogColumn
                          key={day}
                          filters={filters}
                          date={day}
                          time={time}
                          task={task}
                          description={time_logs[index]?.description}
                          time_log_id={time_logs[index]?.time_log_id}
                          refresh={refresh}
                        />
                      );
                    })}

                    <Grid.Col span={1} pr={16} className="border-b border-b-neutral-50">
                      <Flex align="center" h="100%">
                        <Text
                          fz={15}
                          lh="24px"
                          align="right"
                          w="100%"
                          weight="400"
                          display="flex"
                          className="justify-end"
                        >
                          {formattedTime(totalTimeByActualRow || 0)}
                        </Text>
                      </Flex>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            );
          })}
      </Collapse>
    </>
  );
}
