/** @typedef {import('@mantine/core').PasswordInputProps} PasswordInputProps */

import { PasswordInput, Popover, Progress } from '@mantine/core';
import { _t } from 'lang';
import PasswordRequirement from 'components/inputs/password/PasswordRequirement';
import { useMemo, useState } from 'react';
import { MIN_PASSWORD_LENGTH } from 'utils/constants';

/**
 * Password requirements.
 */
const REQUIREMENTS = [
  { name: 'number', re: /\d/, label: _t('Includes number') },
  { name: 'uppercase', re: /[A-Z]/, label: _t('Includes uppercase letter') },
  { name: 'special', re: /[^0-9a-zA-Z]/, label: _t('Includes special symbol') },
  { name: 'length', re: new RegExp(`^.{${MIN_PASSWORD_LENGTH},}$`), label: _t('Includes at least 8 characters') },
];

/**
 * Password strength input, accepts the form data and any PasswordInput props.
 *
 * @param {PasswordInputProps & { initialOpened?: boolean }}
 */
export default function PasswordStrengthInput({ value, onChange, initialOpened = false, ...props }) {
  const [popoverOpened, setPopoverOpened] = useState(initialOpened);

  const strength = useMemo(() => {
    const { length: total } = REQUIREMENTS;
    const { length: passed } = REQUIREMENTS.filter((requirement) => requirement.re.test(value));

    return Math.max(passed / total, 0) * 100;
  }, [value]);

  const color = useMemo(() => {
    if (strength === 100) {
      return 'teal';
    }

    if (strength > 50) {
      return 'orange';
    }

    return 'red';
  }, [strength]);

  const checks = useMemo(
    () =>
      REQUIREMENTS.map(({ label, name, re }) => (
        <PasswordRequirement key={name} label={label} meets={re.test(value)} />
      )),
    [value]
  );

  return (
    <Popover opened={popoverOpened} position="bottom" width="target" transition="pop">
      <Popover.Target>
        <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
          <PasswordInput value={value} onChange={onChange} {...props} />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Progress color={color} value={strength} size={5} mb={10} />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
}
