/**
 * Converts the file to its base64 representation.
 *
 * @param {File} file
 *
 * @returns {Promise<string>}
 */
export default function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}
