import { Flex, Grid, Input } from '@mantine/core';
import SearchIcon from '../../icons/SearchIcon';
import { _t } from '../../../lang';

/**
 * Render the table header component for time entries.
 *
 * @param {{
 *  filters: {
 *    showWeekends: boolean,
 *    orderBy: string,
 *  },
 *  setShift: (value: (prev: number) => number) => void,
 *  actualWeek: Date[],
 *  headerData?: {
 *    date: string,
 *    state: string,
 *    note: string,
 *  }[],
 *  setSearch: (value: string) => void,
 *  }} props - The component props.
 *
 * @returns {JSX.Element} - The rendered component.
 */
export default function TimeEntriesTableHeader({ filters, actualWeek, headerData, setSearch }) {
  return (
    <Grid w="100%" columns={filters.showWeekends ? 11 : 9} pt={16} align="stretch" m={0}>
      <Grid.Col className="h-auto border-b border-b-neutral-50" span={3} align="center" pl={82}>
        <Flex align="center" className="h-full">
          <Input
            icon={<SearchIcon stroke="#B3B3B3" />}
            placeholder="Filter items"
            onChange={(value) => setSearch(value.target.value)}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={filters.showWeekends ? 8 : 6}>
        <Grid columns={filters.showWeekends ? 8 : 6} align="stretch">
          {actualWeek.map((date, index) => {
            let state = false;
            const actualDay = new Date();
            const isActualDay = actualDay.getDate() === date.getDate() && actualDay.getMonth() === date.getMonth();

            if (headerData) {
              state = headerData.find((x) => x.date === `${date.getDate()}.${date.getMonth() + 1}.`);
            }

            if (state) {
              date.state = state.state;
              date.note = state.note;
            }

            if (filters.showWeekends === false && (index === 5 || index === 6)) {
              return null;
            }

            return (
              <Grid.Col
                span={1}
                key={date.getDate()}
                className={`${date.state === 'inactive' ? 'bg-red bg-opacity-5' : ''} p-4
                ${isActualDay ? 'rounded-tl-lg rounded-tr-lg bg-main-secondary' : ''}
                ${filters.showWeekends ? 'pl-2' : ''}
                ${index === 5 ? 'rounded-tl-lg border-b-white bg-neutral-50' : ''}
                ${index === 6 ? 'rounded-tr-lg border-b-white bg-neutral-50' : ''}
                border-b border-b-neutral-50
                `}
              >
                <Flex direction="column" justify="center" className="h-full">
                  <Flex
                    direction="column"
                    align="flex-end"
                    className={`text-xs text-neutral-700 ${
                      date.state === 'inactive' || date.state === 'holiday' ? 'text-red' : ''
                    }`}
                  >
                    <span>{`${date.getDate()}. ${date.getMonth() + 1}.`}</span>
                    <span>{new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date)}</span>
                    <b>{date.note}</b>
                  </Flex>
                </Flex>
              </Grid.Col>
            );
          })}
          <Grid.Col span={1} className="border-b border-b-neutral-50 p-0 pr-4">
            <Flex direction="column" align="flex-end" justify="center" className="h-full text-xs text-neutral-700">
              <b>{_t('Week total')}</b>
            </Flex>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}
