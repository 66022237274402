import { _t } from 'lang';
import { noop } from 'lodash';
import { useCommentData } from '../providers/CommentDataProvider';
import { useMemo } from 'react';
import striptags from 'striptags';

/**
 * Renders a placeholder for the comment form.
 *
 * @param {{
 *   onClick?: () => void;
 *   visible?: boolean;
 * }}
 */
export default function CommentFormPlaceholder({ onClick = noop, visible = false } = {}) {
  const { cache } = useCommentData();

  const text = useMemo(() => {
    if (!visible) {
      return '';
    }

    if (!cache?.text) {
      return _t('Write a comment ...');
    }

    const eText = document.createElement('div');
    eText.innerHTML = cache.text;
    const images = eText.querySelectorAll('img');

    images.forEach((img) => {
      const ePlaceholder = document.createElement('p');
      ePlaceholder.textContent = _t('[Image]');
      img.replaceWith(ePlaceholder);
    });

    return striptags(eText.innerHTML, '<p><br><ul><ol><li><strong><em><u><s><a><code><pre>');
  }, [cache, visible]);

  return (
    <div
      className="max-h-[125px] cursor-pointer overflow-hidden rounded-[8px] border border-neutral-100 bg-neutral-100 p-2 text-[15px] leading-[18px] text-neutral-300 transition-all hover:bg-white hover:text-neutral-700 [&_ol]:pl-6 [&_ol_li]:list-decimal [&_p]:my-1 [&_ul]:pl-6 [&_ul_li]:list-disc"
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  );
}
