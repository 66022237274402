/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-export-action-body').TimeLogExportActionBody} TimeLogExportActionBody
 */

/**
 * @typedef {import('./time-log-export-action-response').TimeLogExportActionResponse} TimeLogExportActionResponse
 */

/**
 * The path of the TimeLogExportAction action.
 */
export const timeLogExportActionPath = new SweetPath('/api/time_log/export', { path: '{param}' });

/**
 * Creates a contextualized TimeLogExportAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogExportAction(axios) {
  /**
   * The timeLogExportAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<TimeLogExportActionResponse>}
   */
  function timeLogExportAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = timeLogExportActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return timeLogExportAction;
}
