import { ActionIcon, CopyButton, Menu } from '@mantine/core';
import AttachmentIcon from 'components/icons/AttachmentIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { _t } from 'lang';
import { isFunction, noop } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * The comment options menu.
 *
 * @param {{
 *   commentId: string;
 *   canEdit?: boolean | () => boolean;
 *   canDelete?: boolean | () => boolean;
 *   onEdit?: () => void;
 *   onDelete?: () => void;
 * }}
 */
export default function CommentOptions({
  commentId,
  canEdit = false,
  onEdit = noop,
  canDelete = false,
  onDelete = noop,
}) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const location = useLocation();

  const commentLink = useMemo(() => {
    const url = new URL(location.pathname + location.search, window.location.origin);
    url.searchParams.set('c', commentId);

    return url.toString();
  }, [location, commentId]);

  const menuOpacity = isMenuOpened ? '' : 'opacity-0';

  canEdit = isFunction(canEdit) ? canEdit() : canEdit;
  canDelete = isFunction(canDelete) ? canDelete() : canDelete;

  return (
    <Menu opened={isMenuOpened} onChange={setIsMenuOpened}>
      <Menu.Target>
        <ActionIcon
          className={`transition-opacity focus:opacity-100 group-hover/comment:opacity-100 ${menuOpacity}`}
          variant="transparent"
          w={24}
          h={24}
        >
          <OptionsDotsIcon width={24} height={24} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {canEdit && (
          <>
            <Menu.Item onClick={onEdit} icon={<EditIcon />}>
              {_t('Edit')}
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
        <CopyButton value={commentLink}>
          {({ copy }) => (
            <Menu.Item onClick={copy} icon={<AttachmentIcon />}>
              {_t('Copy link')}
            </Menu.Item>
          )}
        </CopyButton>
        {canDelete && (
          <Menu.Item onClick={onDelete} icon={<DeleteIcon stroke="#BF0D38" />}>
            <div className="translate-y-[1px] text-complementary-danger">{_t('Delete comment')}</div>
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
