import StatusSwitch from 'components/inputs/status-switch/StatusSwitch';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';

/**
 * Status switch for the client model.
 */
export default function ClientStatusSwitch({ clientId, fullName, isActive }) {
  const { getAction } = useApi();
  const clientSetActiveAction = getAction('ClientSetActiveAction');

  return (
    <StatusSwitch
      entity={_t('Client')}
      name={fullName}
      action={clientSetActiveAction}
      primaryKey={clientId}
      pathParameterName="client_id"
      initialValue={isActive}
    />
  );
}
