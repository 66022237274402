import { _t } from 'lang';
import FormRow from 'components/forms/FormRow';
import { useProject } from 'providers/project/ProjectProvider';
import UserNameWithAvatar from 'components/avatars/UserNameWithAvatar';
import { Button, Group, Text, useMantineTheme } from '@mantine/core';
import LinkWithConfirm from 'components/navigate/LinkWithConfirm';
import { EDIT_PROJECT_PAGE_PATH } from 'routes/paths';
import EditIcon from 'components/icons/EditIcon';

/**
 * The project holder of a cost estimate.
 */
export default function CostEstimateProjectHolder() {
  const theme = useMantineTheme();
  const { project } = useProject();

  return (
    <FormRow
      label={_t('Project holder')}
      input={
        project ? (
          <Group position="apart">
            <UserNameWithAvatar avatar={project.owner.avatar} name={project.owner.full_name} />
            <Button
              variant="link"
              component={LinkWithConfirm}
              to={EDIT_PROJECT_PAGE_PATH.insert({ projectId: project.project_id })}
              confirmTitle={_t('Change project holder')}
              confirmMessage={
                _t('You will be redirected to the project edit page. The data you have inputted will be lost. Are you sure you want to continue?') // prettier-ignore
              }
            >
              <Group spacing={4}>
                <EditIcon width={24} height={24} fill={theme.fn.primaryColor()} />
                <Text fz={15} lh={20 / 15}>
                  {_t('Change project holder')}
                </Text>
              </Group>
            </Button>
          </Group>
        ) : (
          <></>
        )
      }
    />
  );
}
