import { Collapse, Group } from '@mantine/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import CollapseArrow from 'components/CollapseArrow';
import { useApi } from 'api/ApiContext';
import { _t } from 'lang';
import ProjectFinanceTable from 'pages/finance/finance-project-overview/projects-table/ProjectFinanceTable';
import panic from 'errors/Panic';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import Preloader from 'components/Preloader';

/**
 * The client table for the Finance / Project overview page.
 *
 * @param {{
 *   clientId: string;
 *   clientName: string;
 *   hasFocus?: boolean;
 *   onReady?: (clientId: string) => void;
 * }}
 */
export default function ClientTable({ clientId, clientName }) {
  const { getAction } = useApi();
  const anchorRef = useRef();

  const key = useMemo(() => `toolio.finance.overview.client.${clientId}.opened`, [clientId]);
  const [opened, { toggle: toggleOpened }] = useLocalStorageDisclosure(key);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    if (opened && projects === null) {
      const projectGetListAction = getAction('ProjectGetListAction');

      projectGetListAction({
        query: {
          filter: { 'client_id.eq': clientId },
          sort: [{ by: 'ord', order: 'asc' }],
        },
      })
        .then((projects) => {
          const activeProjects = projects.filter(({ deleted_at }) => !deleted_at);
          setProjects(activeProjects);
        })
        .catch((error) => panic(error.message));
    }
  }, [opened, clientId, projects]);

  const loading = useMemo(() => opened && projects === null, [opened, projects]);

  const contents = useMemo(() => {
    if (loading) {
      return <></>;
    }

    if ((projects || []).length === 0) {
      return <div className="h-12 pb-3 pl-[68px] pt-3">{_t('No projects found!')}</div>;
    }

    return projects.map((project) => <ProjectFinanceTable key={project.project_id} project={project} currency="EUR" />);
  }, [projects, loading]);

  const borderRadius = opened ? 'rounded-t-[8px]' : 'rounded-[8px]';
  const color = loading ? 'text-neutral-500' : 'text-neutral-700';

  return (
    <div className="rounded-[8px] bg-white">
      <div ref={anchorRef} className="translate-y-[-100px]"></div>
      <div className="rounded-[8px]">
        <div className={`flex h-[68px] items-center px-2 py-4 ${borderRadius} hover:bg-neutral-20`}>
          <div
            className={`flex w-full cursor-pointer items-center gap-x-2 pb-4 pt-4 text-[20px] leading-[36px] ${color}`}
            onClick={toggleOpened}
          >
            <CollapseArrow opened={opened} />
            <Group spacing={8}>
              {clientName} {loading && <Preloader size={32} />}
            </Group>
          </div>
        </div>

        <Collapse in={opened} className={`flex flex-col ${opened ? 'rounded-b-[8px]' : ''}`}>
          <div className="h-[18px] pl-2 pr-2 text-[12px] leading-[18px] text-neutral-500">
            <div className="grid grid-cols-[24px_24px_1fr_60px_128px_128px_128px_122px_24px] gap-2">
              <div></div> {/* drag and drop icon */}
              <div></div> {/* toggle icon */}
              <div>{_t('Name')}</div>
              <div>{_t('Assignee')}</div>
              <div className="pr-2 text-right">{_t('Invoiced')}</div>
              <div className="pr-2 text-right">{_t('Budget/Margin')}</div>
              <div className="pr-2 text-right">{_t('External costs')}</div>
              <div>{_t('Status')}</div>
              <div className="flex justify-end" />
            </div>
          </div>
          {contents}
        </Collapse>
      </div>
    </div>
  );
}
