import { useDisclosure } from '@mantine/hooks';
import { noop } from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import EditDescriptionModal from './EditDescriptionModal';

/**
 * The props of the editDescription callback.
 *
 * @typedef {{
 *  description: string;
 *  taskName: string;
 *  onEdit: (description: string) => void;
 * }} EditDescriptionProps
 */

/**
 * The editDescription callback.
 *
 * @typedef {(props: EditDescriptionProps) => void} EditDescriptionCallback
 */

/**
 * The editDescription context.
 *
 * @type {React.Context<{
 *   editDescription: EditDescriptionCallback;
 * }>}
 */
const EditDescriptionContext = createContext();

/**
 * Provides the editDescription context.
 *
 * @param {{
 *  children: React.ReactNode,
 * }}
 */
export function EditDescriptionProvider({ children }) {
  const [opened, { close, open }] = useDisclosure(false);

  const [props, setProps] = useState({
    description: '',
    taskName: '',
    onEdit: noop,
  });

  /**
   * Opens the editDescription modal.
   *
   * @type {EditDescriptionCallback}
   */
  const editDescription = useCallback(
    (props) => {
      setProps(props);
      open();
    },
    [open]
  );

  const value = useMemo(() => ({ editDescription }), [editDescription]);

  return (
    <EditDescriptionContext.Provider value={value}>
      {children}
      <EditDescriptionModal opened={opened} onClose={close} {...props} />
    </EditDescriptionContext.Provider>
  );
}

/**
 * Uses the editDescription context.
 */
export function useEditDescription() {
  return useContext(EditDescriptionContext);
}
