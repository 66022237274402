import { Checkbox } from '@mantine/core';
import { noop } from 'lodash';
import { useMemo } from 'react';

/**
 * A row in the permission table.
 *
 * @param {{
 *   title: string;
 *   slug: string;
 *   value?: string | null;
 *   hideGlobal?: boolean;
 *   hideClient?: boolean;
 *   onChange?: (value: IPermission) => void;
 * }}
 */
export default function PermissionRow({
  title,
  slug: clientSlug,
  value = null,
  hideGlobal = false,
  hideClient = false,
  onChange = noop,
}) {
  const globalSlug = useMemo(() => `${clientSlug}_GLOBAL`, [clientSlug]);

  /** Toggles the clicked value. */
  const toggle = (newValue) => onChange(value === newValue ? null : newValue);

  return (
    <div className="grid grid-cols-[500px_160px_160px] items-center gap-2 p-2">
      <span className="py-2 leading-[18px]">{title}</span>
      {!hideGlobal && <Checkbox size="md" checked={value === globalSlug} onChange={() => toggle(globalSlug)} />}
      {!hideClient && <Checkbox size="md" checked={value === clientSlug} onChange={() => toggle(clientSlug)} />}
    </div>
  );
}
