import { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

/**
 * Strict mode droppable.
 *
 * Since plain ordinary Droppable did not work properly.
 * @see Issue {@link https://github.com/atlassian/react-beautiful-dnd/issues/2350#issuecomment-1318179729}
 * @see Solution {@link https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194}
 *
 * @param {import('react-beautiful-dnd').DroppableProps} props
 */
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};
