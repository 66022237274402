import { Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { useEffect, useState } from 'react';

/**
 * @typedef {{
 *   userId: number;
 *   fullName: string
 * }} IUser
 */

/**
 * Displays a list of users
 *
 * @param {{
 *   users: IUser[] | null;
 * }}
 */
export function UserList({ users }) {
  if (users === null) {
    return (
      <Text fz={12} lh={16 / 12} color="neutral700">
        {_t('Loading...')}
      </Text>
    );
  }

  return (
    <Stack spacing={4}>
      {users.map((person) => (
        <Text key={person.userId} fz={12} lh={16 / 12} color="neutral700">
          {person.fullName}
        </Text>
      ))}
    </Stack>
  );
}

/**
 * A tooltip which displays a list of users fetched using the specified action.
 * The action is called when the user hovers over the tooltip. The data is cached
 * and the action is not called again.
 *
 * @param {{
 *   action: () => Promise<IUser[]>;
 *   children: React.ReactNode;
 *   withCache?: boolean;
 * }}
 */
export default function UsersListTooltip({ action, children, withCache = false }) {
  const [hover, { open: startHover, close: stopHover }] = useDisclosure(false);
  const [users, setUsers] = useState(null);

  // Fetch the list of users when the user hovers over the tooltip.
  useEffect(() => {
    if (hover && (!users || !withCache)) {
      setUsers(null);
      action().then(setUsers).catch(panic);
    }
  }, [hover]);

  return (
    <Tooltip
      openDelay={50}
      onMouseEnter={startHover}
      onMouseLeave={stopHover}
      withArrow
      arrowSize={10}
      multiline
      color="neutral200"
      label={<UserList users={users} />}
    >
      {children}
    </Tooltip>
  );
}
