import { useCallback } from 'react';
import useLocalStorage from 'hooks/use-local-storage';

/**
 * @typedef {{
 *   open: () => void;
 *   close: () => void;
 *   toggle: () => void;
 *   setValue: (value: boolean) => void;
 * }} DisclosureActions
 */

/**
 * Uses local storage to store a toggable value.
 *
 * @param {string} key
 * @param {boolean} [defaultValue]
 *
 * @returns {[boolean, DisclosureActions]}
 */
export default function useLocalStorageDisclosure(key, defaultValue = false) {
  const [{ value }, setValueImpl] = useLocalStorage(key, { value: defaultValue });

  /**
   * Sets the state to true.
   */
  const open = useCallback(() => setValueImpl({ value: true }), [setValueImpl]);

  /**
   * Sets the state to false.
   */
  const close = useCallback(() => setValueImpl({ value: false }), [setValueImpl]);

  /**
   * Toggles the state.
   */
  const toggle = useCallback(() => setValueImpl(({ value }) => ({ value: !value })), [setValueImpl]);

  /**
   * Sets the state to the provided value.
   */
  const setValue = useCallback((value) => setValueImpl({ value }), [setValueImpl]);

  return [value, { open, close, toggle, setValue }];
}
