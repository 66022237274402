import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PRICE_LISTS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import PriceListForm from 'components/forms/price-list-form/PriceListForm';
import panic from 'errors/Panic';

/**
 * The Add Price list page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=515%3A28690&t=KmGU0c7SRwOArYf9-0
 */
export default function AddPriceListPage() {
  const navigate = useNavigate();
  const { getAction, workspaceConfig } = useApi();
  const { refreshPriceLists } = useSettingsSidebar();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const priceListDefaultGetAction = getAction('PriceListDefaultGetAction');

    setLoading(true);

    priceListDefaultGetAction()
      .then((data) => {
        const positions = data.price_list_items.reduce(
          (agg, priceListItem) => ({
            ...agg,
            [priceListItem.position_in_company_id]: {
              positionInCompanyId: priceListItem.position_in_company_id,
              positionName: priceListItem.position_in_company_name,
              projectBasedRate: priceListItem.project_based_rate,
              flatFeeRate: priceListItem.flat_fee_rate,
              currency: priceListItem.currency,
              isCustom: false, // by default false, since the default price list is used
            },
          }),
          {}
        );

        const initialValues = {
          items: positions,
          priceListName: '',
          note: '',
          clients: [],
          currency: workspaceConfig.currency,
          validFrom: '',
          validTo: '',
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction]);

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ priceListName, items, note, clients, currency, validFrom, validTo }) => {
    const priceListCreateAction = getAction('PriceListCreateAction');

    if (Object.keys(items).length === 0) {
      alert('No price list items available!');
      return;
    }

    const mappedPriceListItems = [];

    for (let key of Object.keys(items)) {
      const priceListItem = items[key];

      mappedPriceListItems.push({
        position_in_company_id: priceListItem.positionInCompanyId,
        project_based_rate: priceListItem.projectBasedRate,
        flat_fee_rate: priceListItem.flatFeeRate,
        is_custom: priceListItem.isCustom,
      });
    }

    return priceListCreateAction({
      body: {
        price_list_name: priceListName,
        note,
        price_list_items: mappedPriceListItems,
        clients,
        currency,
        valid_from: validFrom ? validFrom.toLocaleDateString() : null,
        valid_to: validTo ? validTo.toLocaleDateString() : null,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Price list created'),
          message: _t("Price list '%s' has been created successfully.", priceListName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshPriceLists();

        navigate(PRICE_LISTS_PAGE_PATH.original);
      })
      .catch(panic);
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Add price list')} />
        {!loading && <PriceListForm initialValues={initialValues} onSubmit={submit} isDefaultPriceList={false} />}
      </div>
    </DashboardLayout>
  );
}
