/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */

import { createContext, useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE, USER_IS_NOT_ALLOWED_ERROR_CODE } from 'utils/constants';

/** @type {Project} */ const emptyProject = null;

/**
 * The project context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   projectId: number | null,
 *   setProjectId: (projectId: number) => void,
 *   project: Project | null,
 *   loading: boolean,
 *   exists: boolean | undefined,
 *   refresh: () => void,
 * }>}
 */
const ProjectContext = createContext();

/**
 * Provides the project to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 *   initialProjectId: number | null,
 * }}
 */
export function ProjectProvider({ children, initialProjectId }) {
  const { getAction } = useApi();
  const [projectId, setProjectId] = useState(initialProjectId);
  const [project, setProject] = useState(emptyProject);
  const [loading, setLoading] = useState(!!initialProjectId);
  const [exists, setExists] = useState(undefined);

  const refresh = useCallback(() => {
    if (projectId) {
      setLoading(true);

      const projectGetAction = getAction('ProjectGetAction');

      projectGetAction({ parameters: { project_id: projectId } })
        .then((project) => {
          setProject(project);
          setExists(true);
        })
        .catch((e) => {
          if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
            setProject(null);
            setExists(false);
          } else if (e.code === USER_IS_NOT_ALLOWED_ERROR_CODE) {
            setProject(null);
            setExists(true);
          } else {
            panic(e);
            setExists(undefined);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [projectId, getAction]);

  // Fetch project when the projectId changes.
  useEffect(refresh, [projectId]);

  const value = useMemo(
    () => ({ projectId, setProjectId, project, loading, exists, refresh }),
    [projectId, setProjectId, project, loading, exists, refresh]
  );

  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
}

/**
 * Uses the project context.
 */
export function useProject() {
  return useContext(ProjectContext);
}
