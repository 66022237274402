/** @typedef {import('api/actions/cost-estimate-get-action/cost-estimate-get-action-response').CostEstimateGetActionResponse['sections'][number]} CostEstimateSection */

import CommentTooltip from 'components/comments/CommentTooltip';
import PriceDisplay from 'components/PriceDisplay';
import { _t } from 'lang';

/**
 * This component is used to display the in-house section items of the cost estimate.
 *
 * @param {{
 *   currency: string,
 *   section: CostEstimateSection
 * }}
 */
export default function InHouseSectionItems({ currency, section }) {
  return (
    <div className="flex flex-col rounded-[8px] bg-white px-4">
      {/* The name of the section */}
      <div className="py-4 text-[20px] leading-[36px] text-neutral-700">{section.section_name}</div>

      {/* The items of the section */}
      <div className="grid grid-cols-[1fr_80px_110px_140px_120px_32px] gap-2 text-[12px] leading-[18px] text-neutral-500">
        <div>{_t('Description')}</div>
        <div className="text-right">{_t('Amount')}</div>
        <div className="text-right">{_t('Unit price')}</div>
        <div className="text-right">{_t('Price')}</div>
        <div className="text-right">{_t('External costs')}</div>
        <div></div>
      </div>
      <div>
        {section.items.map((item) => (
          <div
            className="grid h-10 grid-cols-[1fr_80px_110px_140px_120px_32px] items-center gap-2 border-b border-b-neutral-100 py-2 text-[15px] leading-[18px] text-neutral-700"
            key={item.cost_estimate_item_id}
          >
            <div>{item.title}</div>
            <PriceDisplay
              precision={item.unit === 'pcs' ? 0 : 2}
              value={item.number}
              currency={`${item.unit}.`}
              className="text-right"
            />
            <PriceDisplay
              value={item.unit_price}
              currency={item.unit === 'hrs' ? `${currency}/h` : currency}
              className="text-right"
            />
            <PriceDisplay value={item.price} currency={currency} className="text-right" />
            {item.external_cost ? (
              <PriceDisplay
                value={item.external_cost}
                currency={currency}
                className="text-right text-complementary-african-violet"
              />
            ) : (
              <div></div>
            )}
            {item.comment && <CommentTooltip className="pl-2" comment={item.comment} />}
          </div>
        ))}
      </div>
      {/* Section subtotal and discount */}
      <div className="group flex flex-col items-end justify-center gap-y-4 py-6">
        {section.discount && section.discount > 0 && (
          <>
            <div className="grid h-6 grid-cols-[auto_168px_120px_32px] items-center justify-end gap-x-2 text-right">
              <span className="text-[15px] leading-[18px]">{_t('Section subtotal:')}</span>
              <PriceDisplay value={section.amount} currency={currency} />
            </div>

            <div className="grid grid-cols-[auto_168px_120px_32px] items-center justify-end gap-x-2 text-right">
              <span className="text-[15px] leading-[18px]">
                <span>{_t('Discount')}:</span>
                {section.discount_unit?.trim() === '%' && <span> {section.discount}%</span>}
              </span>
              <PriceDisplay value={section.amount_after_modifiers - section.amount} currency={currency} />
              <div></div>
              {section.discount_comment && <CommentTooltip className="pl-2" comment={section.discount_comment} />}
            </div>
          </>
        )}
        {/* Section total */}
        <div className="grid h-6 grid-cols-[auto_168px_120px_32px] items-center justify-end gap-x-2 text-right">
          <span className="text-[15px] leading-[18px]">{_t('Section total:')}</span>
          <PriceDisplay size="lg" value={section.amount_after_modifiers} currency={currency} />
          <PriceDisplay
            className="text-complementary-african-violet"
            value={section.external_costs}
            currency={currency}
          />
        </div>
      </div>
    </div>
  );
}
