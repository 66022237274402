import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import TeamForm from 'components/forms/team-form/TeamForm';
import { TEAMS_PAGE_PATH } from 'routes/paths';
import { _t } from 'lang';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import panic from 'errors/Panic';

/**
 * Displays a form to add a role.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A25807
 */
export default function AddTeamPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshTeams } = useSettingsSidebar();

  const initialValues = {
    avatar: '',
    teamName: '',
    color: '',
    departmentId: '',
    members: [],
  };

  /**
   * Sends the request to the API and create new team.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ avatar, teamName, color, departmentId, teamLeaderId, members }) => {
    const teamCreateAction = getAction('TeamCreateAction');
    const trimmedColor = color.replace(/^#/, '');

    return teamCreateAction({
      body: {
        avatar,
        name: teamName,
        color: trimmedColor,
        department_id: departmentId,
        leader_id: teamLeaderId ? teamLeaderId : undefined,
        members: members,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Team created'),
          message: _t("Team '%s' has been created successfully.", teamName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshTeams();

        navigate(TEAMS_PAGE_PATH.original);
      })
      .catch(panic);
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Add new team')} />
        <TeamForm initialValues={initialValues} onSubmit={submit} />
      </div>
    </DashboardLayout>
  );
}
