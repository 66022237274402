/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */

import { Box, Button, Collapse, Group, Skeleton } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import CollapseArrow from 'components/CollapseArrow';
import UserView from 'components/avatars/UserView';
import DragIcon from 'components/icons/DragIcon';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ADD_TASK_PAGE_PATH, PROJECT_DETAIL_PAGE_PATH } from 'routes/paths';
import TaskTable from 'pages/projects/project-overview/project-table/task-table/TaskTable';
import DueDateDisplay from 'pages/projects/project-overview/project-table/DueDateDisplay';
import ProjectStatusBubble from 'components/project/ProjectStatusBubble';
import { isEmpty, noop, times } from 'lodash';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import ProjectActions from 'components/project/ProjectActions';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import ProgressGroup from 'components/progress/ProgressGroup';
import { StrictModeDroppable } from '../StrictModeDroppable';
import Truncate from 'components/Truncate';
import { nanoid } from 'nanoid';
import DashboardLayoutOverlay from 'layouts/dashboard-layout/DashboardLayoutOverlay';
import { useDisclosure } from '@mantine/hooks';
import Preloader from 'components/Preloader';

/**
 * A loading row with layout for a single task.
 */
function SkeletonTask() {
  return (
    <Group h={56} align="center" className="border-t border-t-neutral-100" grow>
      <Box className="grid grid-cols-[44px_1fr_60px_128px_128px_122px_24px] gap-2 px-2">
        <Box h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
      </Box>
    </Group>
  );
}

/**
 * The table of projects in the Project overview page.
 *
 * @param {{
 *   draggableId: string;
 *   project: Project;
 *   index: number;
 *   showDeleted: boolean;
 *   forceOpened?: boolean;
 *   hideProjectRow?: boolean;
 *   onProjectDelete?: () => void;
 *   onProjectClose?: () => void;
 *   onProjectReopen?: () => void;
 *   onProjectRestore?: () => void;
 *   loadingRows?: number;
 * }}
 */
export default function ProjectTable({
  draggableId,
  project,
  index,
  showDeleted,
  forceOpened = false,
  hideProjectRow = false,
  onProjectDelete = noop,
  onProjectClose = noop,
  onProjectReopen = noop,
  onProjectRestore = noop,
  loadingRows = 0,
}) {
  const projectId = project.project_id;
  const clientId = project.client.client_id;

  const { getAction, hasPermission } = useApi();
  const key = useMemo(() => `toolio.project.overview.project.${projectId}.opened`, [projectId]);
  const hasCreateTaskPermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );
  const [localOpened, { toggle: toggleOpened, close: closeOpened }] = useLocalStorageDisclosure(key);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [state, setState] = useState({});
  const [dragging, { open: startDragging, close: stopDragging }] = useDisclosure(false);
  const [canDrop, setCanDrop] = useState(false);

  const opened = localOpened || forceOpened;

  /**
   * Performs a partial update on the specified task.
   */
  const updateTask = useCallback(
    (taskId, update) =>
      setTasks((tasks) => tasks.map((task) => (task.task_id === taskId ? { ...task, ...update } : task))),
    [setTasks]
  );

  /**
   * Deletes the task.
   */
  const deleteTask = useCallback(
    (taskId) => updateTask(taskId, { deleted_at: new Date().toISOString() }),
    [updateTask]
  );

  /**
   * Closes the task.
   */
  const closeTask = useCallback((taskId) => updateTask(taskId, { closed_at: new Date().toISOString() }), [updateTask]);

  /**
   * Reopens the task.
   */
  const reopenTask = useCallback((taskId) => updateTask(taskId, { closed_at: null }), [updateTask]);

  /**
   * Restores the task.
   */
  const restoreTask = useCallback((taskId) => updateTask(taskId, { deleted_at: null }), [updateTask]);

  /**
   * Handles the drag end event.
   */
  const onDragEnd = (result) => {
    stopDragging();

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const taskId = draggableId.split('-')[1];
    const newIndex = destination.index;

    const column = state.columns[source.droppableId];
    const newTaskIds = Array.from(column.taskIds);
    newTaskIds.splice(source.index, 1);
    newTaskIds.splice(newIndex, 0, draggableId);

    const newColumn = {
      ...column,
      taskIds: newTaskIds,
    };

    const tasksAbove = newTaskIds.slice(0, newIndex);
    // count the deleted tasks
    // -1 since we included the dropped task
    const deletedTasksCount =
      Object.keys(state.tasks)
        .filter((taskKey) => tasksAbove.includes(taskKey))
        .filter((taskKey) => !state.tasks[taskKey]['content']['task']['ord']).length - 1 || 0;

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newColumn.id]: newColumn,
      },
    };

    const taskUpdateOrdAction = getAction('TaskUpdateOrdAction');
    taskUpdateOrdAction({
      parameters: { task_id: taskId },
      body: { new_ord: newIndex - deletedTasksCount },
    }).catch(panic);

    setState(newState);
  };

  useEffect(() => {
    if (tasks !== null) {
      const groupedTasks = tasks.reduce((acc, task) => {
        const id = `task-${task.task_id}`;
        return { ...acc, [id]: { id, content: { task } } };
      }, {});

      const taskIds = tasks.map((task) => `task-${task.task_id}`);

      const draggable = {
        tasks: groupedTasks,
        columns: {
          'column-2': {
            id: 'column-2',
            taskIds,
          },
        },
        columnOrder: ['column-2'],
      };

      setState(draggable);
    }
  }, [tasks, showDeleted]);

  // Fetch the tasks when the table is opened.
  useEffect(() => {
    if (opened && tasks === null) {
      const taskGetListAction = getAction('TaskGetListAction');

      taskGetListAction({
        parameters: { project_id: projectId },
        query: { sort: [{ by: 'ord', order: 'ASC' }] },
      })
        .then(setTasks)
        .catch(panic);
    }
  }, [opened, tasks]);

  useEffect(() => {
    if ((project.closed_at || project.deleted_at) && project.all_task_count === 0) {
      closeOpened();
    }
  }, [project.closed_at, project.deleted_at, project.all_task_count]);

  const loading = opened && tasks === null;

  const menuClasses = isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100';
  const projectNameColor = loading ? 'text-neutral-500' : 'text-neutral-700';
  const clientNameColor = loading ? 'text-neutral-300' : 'text-neutral-500';

  const hasTasks = showDeleted ? project.all_task_count > 0 : project.all_task_count - project.deleted_task_count > 0;

  const canCollapse = project.closed_at || project.deleted_at ? hasTasks : hasTasks || hasCreateTaskPermission;

  const canDrag = useMemo(() => hasPermission('PROJECTS_MANAGE_PROJECT', clientId), [hasPermission, clientId]);

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={project.deleted_at !== null}>
      {(provided, snapshot) => (
        <Box
          {...(canDrag ? provided.draggableProps : {})}
          ref={provided.innerRef}
          className={`${snapshot.isDragging && !snapshot.isDropAnimating ? 'pl-6 pr-2' : 'mt-[-1px] overflow-hidden'} ${
            hideProjectRow || (snapshot.isDragging && !snapshot.isDropAnimating) ? '' : 'border-y border-y-neutral-100'
          }`}
          style={
            snapshot.isDragging
              ? { cursor: 'not-allowed', ...provided.draggableProps.style }
              : { ...provided.draggableProps.style }
          }
        >
          {canDrag && dragging && <DashboardLayoutOverlay cursor={canDrop ? 'grabbing' : 'not-allowed'} />}
          <Box
            className={`bg-white ${
              snapshot.isDragging && !snapshot.isDropAnimating
                ? 'rounded-[8px] border border-primary-dark-blue bg-neutral-20 shadow-modal-shadow'
                : ''
            }`}
          >
            <div
              className={`group grid h-[58px] grid-cols-[24px_1fr_60px_128px_128px_122px_24px] items-center gap-2 px-2 text-[20px] text-neutral-700 hover:bg-neutral-20 ${
                hideProjectRow ? 'hidden' : ''
              }`}
            >
              {/* DRAG & DROP ICON */}
              <div
                className="flex cursor-grab items-center justify-center opacity-0 group-hover:opacity-100"
                {...(canDrag ? provided.dragHandleProps : {})}
              >
                {!canDrag || project.deleted_at ? <></> : <DragIcon />}
              </div>

              {/* PROJECT NAME */}
              <div className="flex flex-row items-center gap-2 py-2">
                <div
                  className={`h-6 w-6 ${canCollapse ? 'cursor-pointer' : ''}`}
                  onClick={() => {
                    if (canCollapse) {
                      toggleOpened();
                    }
                  }}
                >
                  {/* toggle icon */}
                  {canCollapse && (
                    <CollapseArrow opened={snapshot.isDragging && !snapshot.isDropAnimating ? false : opened} />
                  )}
                </div>
                <Group spacing={8}>
                  <div className="flex max-w-[590px] flex-col">
                    <div
                      className={`text-[18px] leading-[24px] transition-all ${
                        snapshot.isDragging ? '' : 'hover:text-hyperlink'
                      } ${projectNameColor}`}
                    >
                      <Link to={PROJECT_DETAIL_PAGE_PATH.insert({ projectId })}>
                        <Truncate text={project.project_full_name} />
                      </Link>
                    </div>
                    {/* CLIENT NAME */}
                    <Truncate
                      className={`text-[12px] uppercase leading-[18px] ${clientNameColor}`}
                      text={project.client.client_name ?? ''}
                    />
                  </div>
                  {loading && <Preloader />}
                </Group>
              </div>

              {/* ASSIGNEE */}
              <div className="py-2">
                <UserView
                  users={[{ full_name: project.owner.full_name, avatar: project.owner.avatar }]}
                  avatarSize={32}
                />
              </div>

              {/* LOGGED HOURS */}
              <div className="py-2 pr-2">
                <ProgressGroup
                  variant="positive"
                  total={project.total_minutes_estimated}
                  part={project.minutes_total ?? 0}
                />
              </div>

              {/* DUE DATE */}
              <div className="py-2 pr-2">
                <DueDateDisplay dueDate={project.end} />
              </div>

              {/* STATUS */}
              <div className="py-2">
                <ProjectStatusBubble project={project} variant="small" />
              </div>

              <div className={`flex justify-end py-2 ${menuClasses}`}>
                <ProjectActions
                  project={project}
                  onMenuChange={setIsMenuOpened}
                  onProjectDelete={onProjectDelete}
                  onProjectClose={onProjectClose}
                  onProjectReopen={onProjectReopen}
                  onProjectRestore={onProjectRestore}
                />
              </div>
            </div>

            <Collapse
              in={snapshot.isDragging && !snapshot.isDropAnimating ? false : (opened && !loading) || hideProjectRow}
            >
              <Box style={{ position: dragging ? 'relative' : 'static', zIndex: dragging ? 51 : 2 }}>
                <DragDropContext
                  onDragStart={startDragging}
                  onDragEnd={onDragEnd}
                  onDragUpdate={({ destination }) => setCanDrop(!!destination)}
                >
                  {!loading &&
                    !isEmpty(state) &&
                    state.columnOrder.map((columnId) => {
                      const column = state.columns[columnId];
                      const draggableTasks = column.taskIds.map((taskId) => state.tasks[taskId]);

                      if (draggableTasks.length === 0) {
                        return (
                          <Group pl={72} h={56} p={8} key={nanoid()} style={{ borderTop: '1px solid #E6E6E6' }}>
                            {_t('No tasks found!')}
                          </Group>
                        );
                      }

                      return (
                        <div key={column.id} style={{ backgroundColor: '#F2F2F2' }}>
                          <StrictModeDroppable droppableId={column.id}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={snapshot.isDraggingOver ? { pointerEvents: 'none' } : {}}
                              >
                                {draggableTasks
                                  .filter(({ content: { task } }) => showDeleted || !task.deleted_at)
                                  .map((draggableTask, index) => (
                                    <TaskTable
                                      key={draggableTask.id}
                                      draggableTask={draggableTask}
                                      index={index}
                                      task={draggableTask.content.task}
                                      projectId={projectId}
                                      clientId={clientId}
                                      onTaskClose={() => closeTask(draggableTask.content.task.task_id)}
                                      onTaskDelete={() => deleteTask(draggableTask.content.task.task_id)}
                                      onTaskReopen={() => reopenTask(draggableTask.content.task.task_id)}
                                      onTaskRestore={() => restoreTask(draggableTask.content.task.task_id)}
                                      showDeleted={showDeleted}
                                      onSubtaskClose={() =>
                                        updateTask(draggableTask.content.task.task_id, {
                                          closed_subtask_count: draggableTask.content.task.closed_subtask_count + 1,
                                        })
                                      }
                                      onSubtaskReopen={() =>
                                        updateTask(draggableTask.content.task.task_id, {
                                          closed_subtask_count: draggableTask.content.task.closed_subtask_count - 1,
                                        })
                                      }
                                      onSubtaskDelete={() =>
                                        updateTask(draggableTask.content.task.task_id, {
                                          deleted_subtask_count: draggableTask.content.task.deleted_subtask_count + 1,
                                        })
                                      }
                                      onSubtaskRestore={() =>
                                        updateTask(draggableTask.content.task.task_id, {
                                          deleted_subtask_count: draggableTask.content.task.deleted_subtask_count - 1,
                                        })
                                      }
                                    />
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </StrictModeDroppable>
                        </div>
                      );
                    })}
                </DragDropContext>
              </Box>
              {(loading || isEmpty(state)) && times(loadingRows, (i) => <SkeletonTask key={i} />)}
              {!project.closed_at && !project.deleted_at && hasCreateTaskPermission && (
                <div className="flex h-12 items-center border-t border-t-neutral-100 bg-white pb-3 pl-[68px] pt-3">
                  <Button
                    component={Link}
                    to={ADD_TASK_PAGE_PATH.insert({ projectId: project.project_id })}
                    variant="compact"
                  >
                    {_t('Add task')}
                  </Button>
                </div>
              )}
            </Collapse>
          </Box>
        </Box>
      )}
    </Draggable>
  );
}
