import { _t } from 'lang';
import FinanceFormLayout from 'layouts/finance-form-layout/FinanceFormLayout';
import EditInvoiceRequestPageContent from './EditInvoiceRequestPageContent';

/**
 * Displays add invoice request page.
 *
 * @see {@link https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=3128%3A41336&t=u0ATDfjKTGvXjW3S-0 Figma Design}
 */
export default function EditInvoiceRequestPage() {
  return (
    <FinanceFormLayout heading={_t('Edit the invoice request')}>
      {(costEstimate) => <EditInvoiceRequestPageContent costEstimate={costEstimate} />}
    </FinanceFormLayout>
  );
}
