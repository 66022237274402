/** @typedef {import('api/actions').GetActionMap} GetActionMap */
/** @typedef {import('api/workspace-config').WorkspaceConfig} WorkspaceConfig */

import { createContext, useContext } from 'react';

/**
 * Creates empty context.
 *
 * @type {import('react').Context<{
 *   ready: boolean,
 *   shouldResumeSession: boolean,
 *   jwt: string,
 *   firstName: string,
 *   lastName: string,
 *   fullName: string,
 *   email: string,
 *   userId: number,
 *   permissions: string[],
 *   roles: {role_id: number, role_name: string}[],
 *   accessibleClients: number[],
 *   getAction: GetActionMap,
 *   login: (credentials: { email: string, password: string }) => Promise<void>,
 *   logout: () => Promise<void>,
 *   workspaceConfig: WorkspaceConfig,
 *   hasPermission: (permission: string, clientId?: '*'|number) => boolean,
 *   hasRole: (roleName: string) => boolean,
 * }>}
 */
export const ApiContext = createContext(undefined);

/**
 * Requires the API context.
 */
export const useApi = () => useContext(ApiContext);
