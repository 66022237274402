/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./purchase-order-invoice-request-update-action-body').PurchaseOrderInvoiceRequestUpdateActionBody} PurchaseOrderInvoiceRequestUpdateActionBody
 */

/**
 * @typedef {import('./purchase-order-invoice-request-update-action-response').PurchaseOrderInvoiceRequestUpdateActionResponse} PurchaseOrderInvoiceRequestUpdateActionResponse
 */

/**
 * The path of the PurchaseOrderInvoiceRequestUpdateAction action.
 */
export const purchaseOrderInvoiceRequestUpdateActionPath = new SweetPath(
  '/api/finance/purchase_order/{purchase_order_id}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PurchaseOrderInvoiceRequestUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPurchaseOrderInvoiceRequestUpdateAction(axios) {
  /**
   * The purchaseOrderInvoiceRequestUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PurchaseOrderInvoiceRequestUpdateActionBody } }
   *
   * @returns {Promise<PurchaseOrderInvoiceRequestUpdateActionResponse>}
   */
  function purchaseOrderInvoiceRequestUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = purchaseOrderInvoiceRequestUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return purchaseOrderInvoiceRequestUpdateAction;
}
