import { Button } from '@mantine/core';
import AddIcon from 'components/icons/AddIcon';
import { Link } from 'react-router-dom';

/**
 * Renders button prompting the user to create a new entity to the cost estimate.
 *
 * @param {{ title: string, path: string }}
 */
export default function EmptyTab({ title, path }) {
  return (
    <div className="h-20 rounded-[8px] bg-white py-4">
      <div className="flex flex-row items-center py-3 pl-10">
        <Button leftIcon={<AddIcon stroke="#38298B" />} variant="link" component={Link} to={path}>
          {title}
        </Button>
      </div>
    </div>
  );
}
