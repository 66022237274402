/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-copy-action-body').CostEstimateCopyActionBody} CostEstimateCopyActionBody
 */

/**
 * @typedef {import('./cost-estimate-copy-action-response').CostEstimateCopyActionResponse} CostEstimateCopyActionResponse
 */

/**
 * The path of the CostEstimateCopyAction action.
 */
export const costEstimateCopyActionPath = new SweetPath('/api/finance/cost_estimate/{cost_estimate_id}/copy', {
  path: '{param}',
});

/**
 * Creates a contextualized CostEstimateCopyAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateCopyAction(axios) {
  /**
   * The costEstimateCopyAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CostEstimateCopyActionBody } }
   *
   * @returns {Promise<CostEstimateCopyActionResponse>}
   */
  function costEstimateCopyAction({ headers = {}, parameters = {}, body } = {}) {
    const path = costEstimateCopyActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return costEstimateCopyAction;
}
