import { Box, Menu, ActionIcon, LoadingOverlay, CopyButton, Button, Collapse } from '@mantine/core';
import React, { useCallback, useState, useMemo } from 'react';
import useFileDownload from 'api/file-manager/use-file-download';
import DownloadIcon from 'components/icons/DownloadIcon';
import LinkIcon from 'components/icons/LinkIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { _t } from 'lang';
import { FILE_DOWNLOAD_PAGE_PATH } from 'routes/paths';
import formatFileSize from 'utils/format-file-size';
import FileThumbnail from 'components/files/FileThumbnail';
import { isFunction, noop } from 'lodash';
import DeleteIcon from 'components/icons/DeleteIcon';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import Truncate from 'components/Truncate';
import Preloader from 'components/Preloader';

/**
 * Displays the preview of an uploaded file. The file size is in bytes.
 *
 * @param {{
 *   fileId: string;
 *   type: string;
 *   name: string;
 *   extension: string;
 *   size: number;
 *   beingDeleted?: boolean;
 *   canDelete?: boolean | ((fileId: string) => boolean);
 *   onDelete?: () => void;
 * }}
 */
export default function FilePreview({
  fileId,
  type,
  name,
  extension,
  size,
  beingDeleted = false,
  canDelete = false,
  onDelete = noop,
  onOpenSlider = noop,
}) {
  const { confirm } = useConfirm();
  const { loading, download } = useFileDownload();
  const [menuOpened, setMenuOpened] = useState(false);

  const fileDownloadPath = useMemo(() => window.location.host + FILE_DOWNLOAD_PAGE_PATH.insert({ fileId }), [fileId]);
  const formattedSize = useMemo(() => formatFileSize(size), [size]);
  const trimmedName = useMemo(() => name.slice(0, 28), [name]);

  /**
   * Confirms the deletion of the comment.
   */
  const confirmDelete = useCallback(() => {
    confirm({
      title: _t('Delete file'),
      message: _t('Are you sure you want to delete this file? This action cannot be undone.'),
      onConfirm: onDelete,
    });
  }, [confirm, onDelete]);

  /**
   * Downloads the file.
   */
  const downloadFile = useCallback(() => download([{ fileId }]), [download, fileId]);

  const menuOpacity = menuOpened && !loading ? '' : 'opacity-0';

  canDelete = isFunction(canDelete) ? canDelete(fileId) : canDelete;

  return (
    <Collapse in={!beingDeleted} transitionDuration={600}>
      <Box className="group/file-preview relative grid h-[48px] grid-cols-[48px_1fr_40px] items-stretch gap-x-2 rounded-[8px] bg-neutral-100 md:w-[374px]">
        <LoadingOverlay visible={loading} zIndex={10} loader={<Preloader />} />

        <FileThumbnail
          fileId={fileId}
          fileType={type}
          width={48}
          height={48}
          menuOpacity={menuOpacity}
          onOpenSlider={onOpenSlider}
        />

        <Box className="flex w-full flex-col items-start justify-center">
          <Button variant="link" className="max-w-full text-[14px] leading-[18px]" onClick={downloadFile}>
            <Truncate text={`${trimmedName}${trimmedName.length < name.length ? '..' : ''}${extension}`} />
          </Button>
          <span className="text-xs text-neutral-500">{formattedSize}</span>
        </Box>

        <Box className="flex self-center">
          <Menu onChange={setMenuOpened}>
            <Menu.Target>
              <ActionIcon
                w={24}
                h={24}
                variant="transparent"
                className={`transition-opacity focus:opacity-100 group-hover/file-preview:opacity-100 ${menuOpacity}`}
              >
                <OptionsDotsIcon width={24} height={24} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item icon={<DownloadIcon />} onClick={downloadFile}>
                {_t('Download')}
              </Menu.Item>
              <CopyButton value={fileDownloadPath}>
                {({ copy }) => (
                  <Menu.Item onClick={copy} icon={<LinkIcon />}>
                    {_t('Copy link')}
                  </Menu.Item>
                )}
              </CopyButton>
              {canDelete && (
                <>
                  <Menu.Divider />
                  <Menu.Item onClick={confirmDelete} icon={<DeleteIcon stroke="#BF0D38" />}>
                    <div className="translate-y-[1px] text-complementary-danger">{_t('Delete file')}</div>
                  </Menu.Item>
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Box>
    </Collapse>
  );
}
