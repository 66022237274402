/** @typedef {import('components/forms/role-form/RoleForm').RoleFormData} RoleFormData */

import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import { ROLES_PAGE_PATH } from 'routes/paths';
import { _t } from 'lang';
import RoleForm from 'components/forms/role-form/RoleForm';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import panic from 'errors/Panic';

/**
 * Displays a form to add a role.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A25345
 */
export default function AddRolePage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshRoles } = useSettingsSidebar();

  /**
   * Sends the request to the API and create new role
   *
   * @param {RoleFormData}
   */
  const submit = ({ roleName, description, permissions }) => {
    const roleCreateAction = getAction('RoleCreateAction');

    const permissionSlugs = Object.values(permissions).filter((slug) => slug !== null);

    return roleCreateAction({
      body: {
        role_name: roleName,
        description: description,
        permissions: permissionSlugs,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Role created'),
          message: _t("Role '%s' has been created successfully.", roleName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshRoles();

        navigate(ROLES_PAGE_PATH.original);
      })
      .catch(panic);
  };

  return (
    <DashboardLayout
      contentClass="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0"
      sidebar={<SettingsSidebar />}
    >
      <PageHeading heading={_t('Add new role')} />
      <RoleForm onSubmit={submit} />
    </DashboardLayout>
  );
}
