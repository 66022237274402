/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./role-set-active-action-body').RoleSetActiveActionBody} RoleSetActiveActionBody
 */

/**
 * @typedef {import('./role-set-active-action-response').RoleSetActiveActionResponse} RoleSetActiveActionResponse
 */

/**
 * The path of the RoleSetActiveAction action.
 */
export const roleSetActiveActionPath = new SweetPath('/api/user/role/{role_id}/set_active', { path: '{param}' });

/**
 * Creates a contextualized RoleSetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createRoleSetActiveAction(axios) {
  /**
   * The roleSetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: RoleSetActiveActionBody } }
   *
   * @returns {Promise<RoleSetActiveActionResponse>}
   */
  function roleSetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = roleSetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return roleSetActiveAction;
}
