import { Navigate } from 'react-router-dom';
import { PROJECT_DASHBOARD_PAGE_PATH } from 'routes/paths';

/**
 * Dummy home page.
 *
 * @see This is only dummy page, no design needed.
 */
export default function HomePage() {
  return <Navigate to={PROJECT_DASHBOARD_PAGE_PATH.original} />;
}
