import ArrowRightIcon from 'components/icons/ArrowRightIcon';

import ArrowDownIcon from 'components/icons/ArrowDownIcon';

/**
 * Collapse arrow animated component.
 *
 * @param {{
 *   opened: boolean,
 *   onClick: () => void,
 *   className?: string,
 *   stroke?: string,
 * }}
 */
export default function CollapseArrow({ opened, onClick, className = '', stroke }) {
  const props = {
    className,
    onClick,
    stroke: stroke ?? '#38298B',
  };

  return opened ? <ArrowDownIcon {...props} /> : <ArrowRightIcon {...props} />;
}
