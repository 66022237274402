import FiletypePdfIcon from 'components/icons/FiletypePdfIcon';
import FiletypeJpegIcon from 'components/icons/FiletypeJpegIcon';
import FiletypeXlsIcon from 'components/icons/FiletypeXlsIcon';
import FiletypeDocIcon from 'components/icons/FiletypeDocIcon';
import FiletypeTxtIcon from 'components/icons/FiletypeTxtIcon';
import FiletypeXmlIcon from 'components/icons/FiletypeXmlIcon';
import FiletypePptIcon from 'components/icons/FiletypePptIcon';
import FiletypeGifIcon from 'components/icons/FiletypeGifIcon';
import FiletypePngIcon from 'components/icons/FiletypePngIcon';
import FiletypeZipIcon from 'components/icons/FiletypeZipIcon';
import FiletypeRarIcon from 'components/icons/FiletypeRarIcon';
import FiletypeDefaultIcon from 'components/icons/FiletypeDefaultIcon';

/**
 * Maps MIME types to icons.
 *
 * TODO: Fix mime types.
 */
export const fileIconMap = {
  'application/doc': (w, h) => <FiletypeDocIcon width={w} height={h} />,
  'application/docx': (w, h) => <FiletypeDocIcon width={w} height={h} />,
  'application/pdf': (w, h) => <FiletypePdfIcon width={w} height={h} />,
  'application/ppt': (w, h) => <FiletypePptIcon width={w} height={h} />,
  'application/pptx': (w, h) => <FiletypePptIcon width={w} height={h} />,
  'application/rar': (w, h) => <FiletypeRarIcon width={w} height={h} />,
  'application/xls': (w, h) => <FiletypeXlsIcon width={w} height={h} />,
  'application/xlsx': (w, h) => <FiletypeXlsIcon width={w} height={h} />,
  'application/xml': (w, h) => <FiletypeXmlIcon width={w} height={h} />,
  'application/zip': (w, h) => <FiletypeZipIcon width={w} height={h} />,
  'image/gif': (w, h) => <FiletypeGifIcon width={w} height={h} />,
  'image/jpeg': (w, h) => <FiletypeJpegIcon width={w} height={h} />,
  'image/jpg': (w, h) => <FiletypeJpegIcon width={w} height={h} />,
  'image/png': (w, h) => <FiletypePngIcon width={w} height={h} />,
  'text/plain': (w, h) => <FiletypeTxtIcon width={w} height={h} />,
  default: (w, h) => <FiletypeDefaultIcon width={w} height={h} />,
};

/**
 * Displays an icon for a file.
 *
 * @param {{
 *   type: string;
 *   width?: number;
 *   height?: number;
 * }}
 */
export default function FileTypeIcon({ type, width = 24, height = 24 }) {
  return (fileIconMap[type] || fileIconMap.default)(width, height);
}
