/** @typedef {import('api/actions/department-get-list-action/department-get-list-action-response').DepartmentGetListActionResponse[number]} Department */

import Avatar from 'components/avatars/Avatar';
import { _t } from 'lang';
import DataTable from 'components/tables/DataTable';
import DepartmentStatusSwitch from 'components/inputs/status-switch/DepartmentStatusSwitch';
import UserView from 'components/avatars/UserView';
import { useApi } from 'api/ApiContext';
import { EDIT_DEPARTMENT_PAGE_PATH } from 'routes/paths';
import { useMemo } from 'react';
import { Box, Group } from '@mantine/core';
import { Link } from 'react-router-dom';

/**
 * A row in the Departments table.
 *
 * @param {{
 *   data: Department;
 *   hover: boolean;
 * }}
 */
function DepartmentTableRow({ data: department, hover }) {
  return (
    <>
      <Group noWrap>
        <Box w={60}>
          <Avatar image={department.avatar} color={`#${department.color}`} size={60} />
        </Box>
        <Link to={EDIT_DEPARTMENT_PAGE_PATH.insert({ departmentId: department.department_id })}>
          <span className=" flex items-center transition-all hover:text-main-primary">
            <span className="text-[20px] leading-[20px]">{department.name}</span>
            {hover && <small className="ml-[10px] text-[12px] text-grey-500">(ID: {department.department_id})</small>}
          </span>
        </Link>
      </Group>
      <div>
        <Avatar label={department.leader.full_name} image={department.leader.avatar} size={30} />
      </div>
      <div className="flex">
        <UserView
          max={9}
          users={department.members}
          editUsersPath={`${EDIT_DEPARTMENT_PAGE_PATH.insert({
            departmentId: department.department_id,
          })}?tab=teams`}
        />
      </div>
      <DepartmentStatusSwitch
        departmentId={department.department_id}
        name={department.name}
        isActive={department.is_active}
      />
    </>
  );
}

/**
 * The table in the Departments page.
 */
export default function DepartmentTable() {
  const { getAction } = useApi();
  const departmentGetListAction = getAction('DepartmentGetListAction');

  const columns = useMemo(
    () => [
      { title: _t('Department name'), width: 4 },
      { title: _t('Director'), width: 2 },
      { title: _t('Members'), width: 3 },
      { title: _t('Status'), width: 1 },
    ],
    []
  );

  return (
    <DataTable
      action={departmentGetListAction}
      columns={columns}
      rowKey={(department) => department.department_id}
      editLink={(department) => EDIT_DEPARTMENT_PAGE_PATH.insert({ departmentId: department.department_id })}
      RowComponent={DepartmentTableRow}
    />
  );
}
