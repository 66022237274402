import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, ActionIcon, CopyButton, Menu, Skeleton } from '@mantine/core';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import { _t } from 'lang';
import DownloadIcon from 'components/icons/DownloadIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import LinkIcon from 'components/icons/LinkIcon';
import FileThumbnail from 'components/files/FileThumbnail';
import formatFileSize from 'utils/format-file-size';
import Avatar from 'components/avatars/Avatar';
import { isFunction, noop } from 'lodash';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import useFileDownload from 'api/file-manager/use-file-download';
import { FILE_DOWNLOAD_PAGE_PATH } from 'routes/paths';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import Truncate from 'components/Truncate';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';

/**
 * @param {{
 *   gridStyles: React.CSSProperties;
 *   fileId: string;
 *   type: string;
 *   name: string;
 *   extension: string;
 *   size: number;
 *   authorId: number;
 *   created: Date;
 *   canDelete?: boolean|((fileId: string) => boolean);
 *   onDelete?: () => void;
 * }}
 */
export default function AttachmentsListRow({
  gridStyles,
  fileId,
  type,
  name,
  extension,
  size,
  authorId,
  created,
  canDelete = false,
  onDelete = noop,
  onOpenSlider = noop,
}) {
  const { confirm } = useConfirm();
  const { getAction } = useApi();
  const { loading, download } = useFileDownload();
  const [menuOpened, setMenuOpened] = useState(false);
  const [author, setAuthor] = useState(null);
  const { canDeleteAttachment } = useTaskDetail();

  const fileDownloadPath = useMemo(() => window.location.host + FILE_DOWNLOAD_PAGE_PATH.insert({ fileId }), [fileId]);

  const trimmedName = useMemo(() => name?.slice(0, 60), [name]);

  /**
   * Confirms the deletion of the comment.
   */
  const confirmDelete = useCallback(() => {
    confirm({
      title: _t('Delete file'),
      message: _t('Are you sure you want to delete this file? This action cannot be undone.'),
      onConfirm: onDelete,
    });
  }, [confirm, onDelete]);

  /**
   * Downloads the file.
   */
  const downloadFile = useCallback(() => download([{ fileId }]), [download, fileId]);

  const menuOpacity = menuOpened && !loading ? '' : 'opacity-0';

  const canDeleteEvaluated = isFunction(canDelete) ? canDelete(fileId) : canDelete;

  useEffect(() => {
    const userGetMinimalInfoAction = getAction('UserGetMinimalInfoAction');

    userGetMinimalInfoAction({ parameters: { user_id: authorId } })
      .then(({ full_name }) => setAuthor(full_name))
      .catch(panic);
  }, [authorId]);

  return (
    <div style={gridStyles} className="group/attachment-list border-b border-b-neutral-100 py-3 hover:bg-neutral-20">
      <Box className="flex items-center gap-2" pl={8}>
        <FileThumbnail
          fileId={fileId}
          fileType={type}
          width={48}
          height={48}
          menuOpacity={menuOpacity}
          onOpenSlider={onOpenSlider}
          inList={true}
        />
        <Truncate text={`${trimmedName}${trimmedName.length < name.length ? '..' : ''}${extension}`} />
      </Box>

      <Box className="text-right text-sm" pr={8}>
        <Box>{formatFileSize(size)}</Box>
      </Box>
      <Box className="text-right" pr={8}>
        <div className="flex justify-end">
          {author ? <Avatar withTooltip size={32} label={author} /> : <Skeleton width={32} h={32} radius={16} />}
        </div>
      </Box>
      <Box className="text-right text-sm" pr={8}>
        {created.toLocaleDateString()}
      </Box>

      <Box className="flex justify-center">
        <Menu opened={menuOpened} onChange={setMenuOpened}>
          <Menu.Target>
            <ActionIcon
              w={24}
              h={24}
              variant="transparent"
              className={`transition-opacity focus:opacity-100 group-hover/attachment-list:opacity-100 ${menuOpacity}`}
            >
              <OptionsDotsIcon width={24} height={24} />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item icon={<DownloadIcon />} onClick={downloadFile}>
              {_t('Download')}
            </Menu.Item>
            <CopyButton value={fileDownloadPath}>
              {({ copy }) => (
                <Menu.Item onClick={copy} icon={<LinkIcon />}>
                  {_t('Copy link')}
                </Menu.Item>
              )}
            </CopyButton>
            {canDeleteEvaluated && canDeleteAttachment(fileId) && (
              <>
                <Menu.Divider />
                <Menu.Item onClick={confirmDelete} icon={<DeleteIcon stroke="#BF0D38" />}>
                  <div className="translate-y-[1px] text-complementary-danger">{_t('Delete file')}</div>
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </Box>
    </div>
  );
}
