import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for users.
 *
 * @param { import('react').RefAttributes<HTMLInputElement> &
 *   import('@mantine/core').SelectProps
 * }
 */
export default function UserSelect({ ...props }) {
  const { getAction } = useApi();
  const userGetListAction = getAction('UserGetListAction');

  return <DataSelect {...props} action={userGetListAction} labelProperty="full_name" valueProperty="user_id" />;
}
