/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./department-get-list-action-body').DepartmentGetListActionBody} DepartmentGetListActionBody
 */

/**
 * @typedef {import('./department-get-list-action-response').DepartmentGetListActionResponse} DepartmentGetListActionResponse
 */

/**
 * The path of the DepartmentGetListAction action.
 */
export const departmentGetListActionPath = new SweetPath('/api/user/department/', { path: '{param}' });

/**
 * Creates a contextualized DepartmentGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createDepartmentGetListAction(axios) {
  /**
   * The departmentGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<DepartmentGetListActionResponse>}
   */
  function departmentGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = departmentGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return departmentGetListAction;
}
