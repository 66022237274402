/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "PurchaseOrder" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function PurchaseOrderIcon({ className = '', height = 25, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3.5C6.11929 3.5 5 4.61929 5 6V19C5 20.3807 6.11929 21.5 7.5 21.5H11C11.2761 21.5 11.5 21.2761 11.5 21C11.5 20.7239 11.2761 20.5 11 20.5H7.5C6.67157 20.5 6 19.8284 6 19V6C6 5.17157 6.67157 4.5 7.5 4.5H14V7C14 7.82843 14.6716 8.5 15.5 8.5H17.9788C17.9928 8.5826 18 8.6667 18 8.75147V12.5C18 12.7761 18.2239 13 18.5 13C18.7761 13 19 12.7761 19 12.5V8.75147C19 8.14591 18.7802 7.56094 18.3814 7.10521L15.9739 4.35374C15.4992 3.8112 14.8134 3.5 14.0925 3.5H7.5ZM17.3981 7.5L15.2213 5.01224C15.1541 4.93545 15.0799 4.86639 15 4.80568V7C15 7.27614 15.2239 7.5 15.5 7.5H17.3981Z"
        fill={fill}
      />
      <path
        d="M9 13.5C8.72386 13.5 8.5 13.7239 8.5 14C8.5 14.2761 8.72386 14.5 9 14.5H10.691L11.0282 15.1745L12.1096 19.5H18.5C18.7761 19.5 19 19.2761 19 19C19 18.7239 18.7761 18.5 18.5 18.5H12.8904L12.1404 15.5H18.3596L18.1096 16.5H14C13.7239 16.5 13.5 16.7239 13.5 17C13.5 17.2761 13.7239 17.5 14 17.5H18.8904L19.6404 14.5H11.809L11.309 13.5H9Z"
        fill={fill}
      />
      <path
        d="M14 21C14 21.2761 13.7761 21.5 13.5 21.5C13.2239 21.5 13 21.2761 13 21C13 20.7239 13.2239 20.5 13.5 20.5C13.7761 20.5 14 20.7239 14 21Z"
        fill={fill}
      />
      <path
        d="M17.5 21.5C17.7761 21.5 18 21.2761 18 21C18 20.7239 17.7761 20.5 17.5 20.5C17.2239 20.5 17 20.7239 17 21C17 21.2761 17.2239 21.5 17.5 21.5Z"
        fill={fill}
      />
    </svg>
  );
}
