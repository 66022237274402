import { Button } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import ProcessMessageWithAction from 'components/ProcessMessageWithAction';
import LockedIcon from 'components/icons/LockedIcon';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { useState } from 'react';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import { useDisclosure } from '@mantine/hooks';
import CostEstimateCloseModal from 'pages/finance/cost-estimate/detail/cost-estimate-tab/state/CostEstimateCloseModal';

/**
 * Contains information about the state of the Cost Estimate with buttons to
 * change it when the state is `invoiced`.
 */
export default function StateInvoiced() {
  const { costEstimate, setIsClosed } = useCostEstimateDetail();

  const [loading, setLoading] = useState(false);
  const [confirm, { close: closeConfirm, open: openConfirm }] = useDisclosure(false);

  const { getAction } = useApi();
  const costEstimateCloseAction = getAction('CostEstimateCloseAction');

  /**
   * Closes the cost estimate.
   */
  function closeCostEstimate() {
    if (!loading) {
      setLoading(true);

      costEstimateCloseAction({ parameters: { cost_estimate_id: costEstimate.cost_estimate_id } })
        .then(() => setIsClosed(true))
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <ProcessMessageWithAction
        heading={_t('Invoiced')}
        message={_t('Wait until all external costs are imported and once work on this project ends, mark as complete. Note, that no internal hours can be reported afterwards.')} // prettier-ignore
        buttons={
          <Button variant="primary" leftIcon={<LockedIcon stroke="#FFFFFF" />} onClick={openConfirm} loading={loading}>
            {_t('Mark as closed')}
          </Button>
        }
      />
      <CostEstimateCloseModal opened={confirm} onClose={closeConfirm} onConfirm={closeCostEstimate} />
    </>
  );
}
