import { Button, Group, Modal } from '@mantine/core';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

/**
 * Modal used to confirm actions.
 *
 * @param {{
 *   title: string;
 *   message: string;
 *   opened: boolean;
 *   primaryButtonText?: string;
 *   secondaryButtonText?: string;
 *   hideSecondaryButton?: boolean;
 *   onConfirm?: (() => void) | (() => Promise<void>);
 *   onClose: () => void;
 * }}
 */
export default function ConfirmModal({
  title,
  message,
  opened,
  primaryButtonText = _t('Yes'),
  secondaryButtonText = _t('No'),
  hideSecondaryButton = false,
  onConfirm = noop,
  onClose,
}) {
  const [loading, setLoading] = useState(false);

  /**
   * Handles the click on the confirm button.
   */
  const onClick = useCallback(async () => {
    try {
      const confirmAction = onConfirm();

      if (confirmAction instanceof Promise) {
        setLoading(true);
        await confirmAction;
      }

      onClose();
    } catch (err) {
      panic(err);
    }
  }, [setLoading, onConfirm, onClose]);

  useEffect(() => {
    if (!opened) {
      setLoading(false);
    }
  }, [opened]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={opened}
      title={title}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      withCloseButton={!loading}
    >
      <div className="mb-8 text-[15px] leading-[20px] text-neutral-700">{message}</div>
      <Group position="right" spacing={16}>
        {!hideSecondaryButton && (
          <Button disabled={loading} variant="link" onClick={onClose} px={8}>
            {secondaryButtonText}
          </Button>
        )}
        <Button loading={loading} variant="primary" onClick={onClick}>
          {primaryButtonText}
        </Button>
      </Group>
    </Modal>
  );
}
