import { _t } from 'lang';
import { isPasswordValid } from 'utils/is-password-valid';

/**
 * Validates the email format.
 *
 * @param {string} value
 */
export const passwordFormat = (value) => {
  if (!isPasswordValid(value)) {
    return {
      message: _t('The password is invalid.'),
    };
  }

  return null;
};
