import { Box, Button, Collapse, Stack } from '@mantine/core';
import AttachmentIcon from 'components/icons/AttachmentIcon';
import { useDisclosure } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { noop } from 'lodash';
import { _n, _t, sprintf } from 'lang';
import FilePreviewLoader from 'components/files/FilePreviewLoader';
import BulkFileDownloadLink from 'components/files/BulkFileDownloadLink';
import Slider from 'components/modals/Slider';

/**
 * Displays the attachments in a simple grid.
 *
 * @param {{
 *   attachments: { fileId: string; }[];
 *   attachmentBeingDeleted?: string | null;
 *   canDelete?: boolean | ((fileId: string) => boolean);
 *   infoPosition?: 'top' | 'bottom';
 *   hideCount?: boolean;
 *   hideToggle?: boolean;
 *   onDelete?: (fileId: string) => void;
 * }}
 */
export default function AttachmentsSimpleGrid({
  attachments,
  attachmentBeingDeleted,
  canDelete = false,
  infoPosition = 'top',
  hideCount = false,
  hideToggle = false,
  onDelete = noop,
}) {
  const [attachmentsVisible, { toggle: toggleAttachmentsVisibility }] = useDisclosure(true);
  const [openedSlider, setOpenSlider] = useState(false);
  const [initialFileId, setInitialFileId] = useState('');
  const fileIds = useMemo(() => attachments.map(({ fileId }) => fileId), [attachments]);

  const hideDownloadAll = attachments.length === 0;
  const hideInfoBox = hideCount && hideToggle && hideDownloadAll;

  if (hideInfoBox) {
    return <></>;
  }

  const infoBox = (
    <Box className="flex flex-row items-center gap-4">
      {!hideCount && (
        <Box className="flex flex-row items-center gap-1 text-sm">
          <AttachmentIcon width={24} height={24} stroke="#4D4D4D" />
          <span>{sprintf(_n('%d attachment', '%d attachments', attachments.length), attachments.length)}</span>
        </Box>
      )}
      {!hideToggle && (
        <Button variant="link" onClick={toggleAttachmentsVisibility}>
          {attachmentsVisible ? 'Hide' : 'Show'}
        </Button>
      )}
      {!hideDownloadAll && <BulkFileDownloadLink fileIds={fileIds}>{_t('Download all')}</BulkFileDownloadLink>}
    </Box>
  );

  return (
    <Stack spacing={16}>
      {infoPosition === 'top' && infoBox}
      <Collapse in={attachmentsVisible}>
        <div className="flex flex-col gap-4 md:grid md:grid-cols-[374px_374px]">
          {attachments.map(({ fileId }) => (
            <Box key={fileId}>
              <FilePreviewLoader
                fileId={fileId}
                beingDeleted={attachmentBeingDeleted === fileId}
                canDelete={canDelete}
                onDelete={() => onDelete(fileId)}
                onOpenSlider={() => {
                  setInitialFileId(fileId);
                  setOpenSlider(true);
                }}
              />
            </Box>
          ))}
          <Slider
            key={initialFileId}
            opened={openedSlider}
            fileIds={fileIds}
            onClose={() => setOpenSlider(false)}
            initialImageId={initialFileId}
          />
        </div>
      </Collapse>
      {infoPosition === 'bottom' && infoBox}
    </Stack>
  );
}
