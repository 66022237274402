/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project */

import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { Link, useParams } from 'react-router-dom';
import { ADD_TASK_PAGE_PATH, PROJECT_DASHBOARD_PAGE_PATH } from 'routes/paths';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { Box, Breadcrumbs, Button, Group, Stack, Text } from '@mantine/core';
import { _t } from 'lang';
import AddIcon from 'components/icons/AddIcon';
import ProjectInformationRow from 'components/project/ProjectInformationRow';
import PriceDisplay from 'components/PriceDisplay';
import { ProjectProvider, useProject } from 'providers/project/ProjectProvider';
import ProjectDetails from 'pages/projects/project-detail/ProjectDetails';
import ProjectTasks from 'pages/projects/project-detail/ProjectTasks';
import ProjectActions from 'components/project/ProjectActions';
import ProjectStatusBubble from 'components/project/ProjectStatusBubble';
import ProjectTimeEntries from './ProjectTimeEntries';
import { useMemo } from 'react';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';
import { useApi } from 'api/ApiContext';
import { NoAccessPageContent } from 'pages/system/no-access/NoAccessPage';
import FormattedTextDisplay from '../../../components/FormattedTextDisplay';

/**
 * Displays an action button for this page.
 *
 * @param {{
 *   project: Project,
 * }}
 */
function ProjectDetailActionButton({ project }) {
  const { hasPermission } = useApi();
  const {
    projectId,
    project: {
      client: { client_id: clientId },
    },
  } = useProject();
  const addTaskPath = useMemo(() => ADD_TASK_PAGE_PATH.insert({ projectId }), [projectId]);

  const hasCreateTaskPermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );

  if (project.deleted_at || project.closed_at) {
    return <></>;
  }

  if (!hasCreateTaskPermission) {
    return <></>;
  }

  return (
    <Button component={Link} to={addTaskPath} variant="primary" leftIcon={<AddIcon />}>
      {_t('Task')}
    </Button>
  );
}

/**
 * Displays a project detail.
 *
 * @param {{
 *   hideTimeEntriesSection?: boolean,
 * }}
 */
function ProjectDetailPageImpl({ hideTimeEntriesSection = false } = {}) {
  const { project, loading, refresh, exists } = useProject();

  if (loading) {
    return <></>;
  }

  if (exists === false) {
    return <NotFoundPageContent />;
  }

  if (!project && exists) {
    return <NoAccessPageContent />;
  }

  return (
    <>
      <PageHeading
        heading={project.project_full_name}
        breadcrumbs={
          <Breadcrumbs>
            <Link to={PROJECT_DASHBOARD_PAGE_PATH.original}>{_t('Projects')}</Link>
            <Link to={`${PROJECT_DASHBOARD_PAGE_PATH.original}?clientId=${project.client.client_id}`}>
              {project.client.client_name}
            </Link>
          </Breadcrumbs>
        }
        actionButton={<ProjectDetailActionButton project={project} />}
        menu={
          <ProjectActions
            project={project}
            onProjectClose={refresh}
            onProjectDelete={refresh}
            onProjectReopen={refresh}
            onProjectRestore={refresh}
          />
        }
      />
      <Box pt={24}>
        <Group spacing={16} align="center">
          <Text fz={15} lh={18 / 15}>
            {_t('Status:')}
          </Text>
          <ProjectStatusBubble project={project} variant="default" />
        </Group>
      </Box>
      <Stack spacing={16} pt={16}>
        <ProjectInformationRow label={_t('Description')} value={<FormattedTextDisplay text={project.description} />} />
        <ProjectInformationRow label={_t('Total logged hours')} value={<PriceDisplay value={0} currency="hrs." />} />
        <ProjectDetails />
        <ProjectTasks />
        {!hideTimeEntriesSection && <ProjectTimeEntries />}
      </Stack>
    </>
  );
}

/**
 * Displays a project detail.
 */
export default function ProjectDetailPage() {
  const { projectId } = useParams();

  return (
    <ProjectProvider initialProjectId={projectId} key={projectId}>
      <DashboardLayout contentClass="bg-neutral-50 px-8 pb-8">
        <ProjectDetailPageImpl key={projectId} />
      </DashboardLayout>
    </ProjectProvider>
  );
}
