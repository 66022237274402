import { Box, Group, Stack, Text } from '@mantine/core';
import { DatePicker as MantineDatePicker } from '@mantine/dates';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
import { addDays, isToday, isTomorrow } from 'date-fns';
import { useMemo } from 'react';

/**
 * Parse date from input.
 *
 * @param {string} input
 */
function dateParser(input) {
  const [day, month, year] = input.split('.');

  if (!day || !month || !year) {
    return null;
  }

  return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
}

/**
 * The CSS styles for the today's date in calendar.
 */
export const todayStyle = {
  color: '#38298B',
  border: '1px solid #38298B',
  borderRadius: '50%',
  backgroundColor: 'transparent',
};

/**
 * Custom DatePicker component.
 *
 * @param { {showDayControls?: boolean } & import("@mantine/dates").DatePickerProps} props
 */
export default function DatePicker({ value, onChange, showDayControls = false, ...props }) {
  const prevDay = useMemo(() => (showDayControls && value ? addDays(value, -1) : undefined), [value]);
  const nextDay = useMemo(() => (showDayControls && value ? addDays(value, 1) : undefined), [value]);

  const isNextDayTomorrow = useMemo(() => (nextDay ? isTomorrow(nextDay) : false), [nextDay]);

  return (
    <Stack spacing={0}>
      <Box pos="relative">
        <MantineDatePicker
          value={value}
          onChange={onChange}
          {...props}
          clearable={false}
          allowFreeInput
          inputFormat="DD.MM.YYYY"
          dayStyle={(date, { selected }) => (isToday(date) && !selected ? todayStyle : null)}
          dateParser={dateParser}
        />
        <Box pos="absolute" right={8} bottom={10} style={{ pointerEvents: 'none' }}>
          <CalendarIcon width={16} height={16} stroke="#000" />
        </Box>
      </Box>
      {showDayControls && (
        <Group position="apart">
          <Group
            spacing={0}
            className="cursor-pointer"
            onClick={() => {
              onChange(prevDay);
            }}
          >
            <ArrowLeftIcon stroke="#4D4D4D" />
            <Text c="#4D4D4D" fz={12} lh={16 / 12}>
              {prevDay.toLocaleDateString('sk-SK', { day: 'numeric' })}{' '}
              {prevDay.toLocaleDateString('sk-SK', { month: 'numeric' })}
            </Text>
          </Group>
          <Group
            spacing={0}
            className={isNextDayTomorrow ? 'cursor-not-allowed' : 'cursor-pointer'}
            onClick={() => {
              if (!isNextDayTomorrow) {
                onChange(nextDay);
              }
            }}
          >
            <Text c={isNextDayTomorrow ? '#B3B3B3' : '#4D4D4D'} fz={12} lh={16 / 12}>
              {nextDay.toLocaleDateString('sk-SK', { day: 'numeric' })}{' '}
              {nextDay.toLocaleDateString('sk-SK', { month: 'numeric' })}
            </Text>
            <ArrowRightIcon stroke={isNextDayTomorrow ? '#B3B3B3' : '#4D4D4D'} />
          </Group>
        </Group>
      )}
    </Stack>
  );
}
