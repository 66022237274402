/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-thread-set-notify-me-action-body').CommentThreadSetNotifyMeActionBody} CommentThreadSetNotifyMeActionBody
 */

/**
 * @typedef {import('./comment-thread-set-notify-me-action-response').CommentThreadSetNotifyMeActionResponse} CommentThreadSetNotifyMeActionResponse
 */

/**
 * The path of the CommentThreadSetNotifyMeAction action.
 */
export const commentThreadSetNotifyMeActionPath = new SweetPath(
  '/api/comment/comment_thread_set_notify_me/{comment_thread_id}',
  { path: '{param}' }
);

/**
 * Creates a contextualized CommentThreadSetNotifyMeAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentThreadSetNotifyMeAction(axios) {
  /**
   * The commentThreadSetNotifyMeAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CommentThreadSetNotifyMeActionBody } }
   *
   * @returns {Promise<CommentThreadSetNotifyMeActionResponse>}
   */
  function commentThreadSetNotifyMeAction({ headers = {}, parameters = {}, body } = {}) {
    const path = commentThreadSetNotifyMeActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return commentThreadSetNotifyMeAction;
}
