import { Box, NumberInput, Select, Stack, Switch, Tabs, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { _t } from 'lang';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import { useEffect, useMemo } from 'react';
import LanguageSelect from 'components/selects/LanguageSelect';
import CurrencySelect from 'components/selects/CurrencySelect';
import PriceListSelect from 'components/selects/PriceListSelect';
import FormRow from 'components/forms/FormRow';
import MoneyInput from 'components/inputs/MoneyInput';
import Alert from 'components/Alert';
import { useSearchParams } from 'react-router-dom';
import ContactPersonsInput from 'components/inputs/contact-person/ContactPersonsInput';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';
import SectionTitle from '../FormSectionTitle';
import { useApi } from 'api/ApiContext';
import UserTagSelect from 'components/selects/UserTagSelect';
import slugify from 'slugify';
import iso from 'iso-3166-1';
import { MAX_NEXT_PROJECT_NUM, NEXT_PROJECT_NUM_MODAL_THRESHOLD } from 'utils/constants';
import { useConfirm } from 'providers/confirm/ConfirmProvider';

/** @typedef {import('components/inputs/contact-person/types').IContactPerson} IContactPerson */

/**
 * @typedef {{
 *   name: string;
 *   officialName: string;
 *   ico: string;
 *   dic: string;
 *   icDph: string;
 *   streetAndNumber: string;
 *   city: string;
 *   zipCode: string;
 *   country: string;
 *   avatar: string;
 *   contactPerson: IContactPerson[];
 *   language: string;
 *   priceListId: string;
 *   currency: string;
 *   increasingPrice: number;
 *   addPoToInvoice: boolean;
 *   note: string;
 *   noteCostEstimate: string;
 *   noteInvoice: string;
 *   members: string[];
 *   shortcutSlug: string;
 *   shortcutInternal: string;
 *   nextProjectNum: number;
 *   maxProjectNum: number;
 * }} ClientFormData
 */

const countries = iso.all().map((country) => ({ label: country.country, value: country.alpha3 }));

const nextProjectNumErrorMessage = `${_t('The maximum allowed value is')} ${MAX_NEXT_PROJECT_NUM}.`;

/**
 * The Add / Edit Client form.
 *
 * @param {{
 *   initialValues: ClientFormData;
 *   onSubmit: (values: ClientFormData) => void | Promise<void>;
 *   hasShortcutSlugError?: boolean;
 *   hasNextProjectNumError?: boolean;
 * }}
 */
export default function ClientForm({
  initialValues,
  onSubmit,
  hasShortcutSlugError = false,
  hasNextProjectNumError = false,
}) {
  const { workspaceConfig } = useApi();
  const [loading, submit] = useLoadingAction(onSubmit);
  const [searchParams] = useSearchParams();
  const { confirm } = useConfirm();

  const activeTab = searchParams.get('tab') || 'information';

  const form = useForm({
    initialValues,

    validate: {
      name: createValidator([required]),
      ico: createValidator([required]),
      dic: createValidator([required]),
      streetAndNumber: createValidator([required]),
      city: createValidator([required]),
      zipCode: createValidator([required]),
      country: createValidator([required]),
      language: createValidator([required]),
      priceListId: createValidator([required]),
      currency: createValidator([required]),
      increasingPrice: createValidator([required]),
      shortcutSlug: createValidator([
        required,
        (val) => (val.length === 3 ? null : { message: _t('The abbreviation must have 3 characters.') }),
      ]),
      nextProjectNum: createValidator([required], (val) =>
        val >= initialValues.nextProjectNum + 1 && val <= MAX_NEXT_PROJECT_NUM
          ? null
          : { message: nextProjectNumErrorMessage }
      ),
    },
  });

  const infoError = useMemo(
    () =>
      form.errors.name ||
      form.errors.ico ||
      form.errors.dic ||
      form.errors.streetAndNumber ||
      form.errors.city ||
      form.errors.zipCode ||
      form.errors.country ||
      form.errors.shortcutSlug ||
      form.errors.nextProjectNum,
    [form.errors]
  );

  /**
   * Decides whether to show the warning modal or just submit the values.
   *
   * @param {ClientFormData} values
   */
  function handleSubmit(values) {
    if (
      values.nextProjectNum !== initialValues.nextProjectNum &&
      values.nextProjectNum >= NEXT_PROJECT_NUM_MODAL_THRESHOLD
    ) {
      confirm({
        title: _t('Attention'),
        message: (
          <Stack spacing={16}>
            <Box fz={15} lh={18 / 15} c="#4D4D4D">
              {_t('The maximum number of projects is 9 999.')}
            </Box>
            <Box fz={15} lh={18 / 15} c="#4D4D4D">
              {
                _t('You will be unable to create more projects once this number is reached this year. Make sure your input leaves enough room for future projects.') // prettier-ignore
              }
            </Box>
            <Box fz={15} lh={18 / 15} c="#4D4D4D">
              {_t('Do you want to continue and save this number?')}
            </Box>
          </Stack>
        ),
        primaryButtonText: _t('Save'),
        secondaryButtonText: _t('Cancel'),
        onConfirm: () => submit(values),
      });
    } else {
      submit(values);
    }
  }

  useEffect(() => {
    if (form.values.nextProjectNum > MAX_NEXT_PROJECT_NUM) {
      form.setFieldError('nextProjectNum', nextProjectNumErrorMessage);
    }
  }, [form.values.nextProjectNum]);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {infoError && <Alert severity="error" primaryText={_t('Some errors are present in the "Information" tab.')} />}

      <Tabs defaultValue={activeTab}>
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="information">{_t('Information')}</Tabs.Tab>
          <Tabs.Tab value="contact">{_t('Contact')}</Tabs.Tab>
          {workspaceConfig.features.finance && <Tabs.Tab value="invoicing">{_t('Invoicing')}</Tabs.Tab>}
          <Tabs.Tab value="members">{_t('Members')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="information">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Internal name')}
              input={<TextInput placeholder={_t('Internal name')} autoFocus {...form.getInputProps('name')} />}
            />
            <FormRow
              label={_t('Client code')}
              input={
                <TextInput
                  placeholder={_t('Client code')}
                  {...form.getInputProps('shortcutSlug')}
                  error={
                    form.getInputProps('shortcutSlug').error
                      ? form.getInputProps('shortcutSlug').error
                      : hasShortcutSlugError
                      ? _t('This abbreviation has already been assigned. Please pick another.')
                      : ''
                  }
                  disabled={form.values.nextProjectNum > 1}
                  maxLength={3}
                  onChange={(e) => {
                    // use build-in function to remove the special characters
                    // remove prop in slugify replaced '&' by 'and' - we don't want that
                    const val = e.currentTarget.value.replace(/[^a-zA-Z0-9]/g, '');

                    const normalized = slugify(val, {
                      replacement: '',
                      remove: /[^a-zA-Z0-9]/g,
                    });

                    form.getInputProps('shortcutSlug').onChange(normalized.toUpperCase());
                  }}
                />
              }
            />
            <FormRow
              label={_t('Internal code')}
              input={
                <TextInput
                  maxLength={5}
                  placeholder={_t('Internal code')}
                  value={form.values.shortcutInternal}
                  onChange={(e) => {
                    // use build-in function to remove the special characters
                    // remove prop in slugify replaced '&' by 'and' - we don't want that
                    const val = e.currentTarget.value.replace(/[^a-zA-Z0-9]/g, '');

                    const normalized = slugify(val, {
                      replacement: '',
                      remove: /[^a-zA-Z0-9]/g,
                    });

                    form.getInputProps('shortcutInternal').onChange(normalized.toUpperCase());
                  }}
                />
              }
            />
            <FormRow
              label={_t('Official name')}
              input={<TextInput placeholder={_t('Official name')} {...form.getInputProps('officialName')} />}
            />
            <FormRow label={_t('IČO')} input={<TextInput placeholder={_t('IČO')} {...form.getInputProps('ico')} />} />
            <FormRow label={_t('DIČ')} input={<TextInput placeholder={_t('DIČ')} {...form.getInputProps('dic')} />} />
            <FormRow
              label={_t('IČ DPH')}
              input={<TextInput placeholder={_t('IČ DPH')} {...form.getInputProps('icDph')} />}
            />
            <FormRow
              label={_t('Street and number')}
              input={<TextInput placeholder={_t('Street and number')} {...form.getInputProps('streetAndNumber')} />}
            />
            <FormRow
              label={_t('City')}
              input={<TextInput placeholder={_t('City')} {...form.getInputProps('city')} />}
            />
            <FormRow
              label={_t('ZIP code')}
              input={<TextInput placeholder={_t('Zip code')} {...form.getInputProps('zipCode')} />}
            />
            <FormRow
              label={_t('Country')}
              input={
                <Select
                  data={countries}
                  dropdownPosition="top"
                  withinPortal
                  searchable
                  placeholder={_t('Country')}
                  {...form.getInputProps('country')}
                />
              }
            />
            <FormRow
              label={_t('Next project number')}
              input={
                <NumberInput
                  placeholder={_t('Next project number')}
                  hideControls
                  min={initialValues.maxProjectNum + 1}
                  max={MAX_NEXT_PROJECT_NUM}
                  {...form.getInputProps('nextProjectNum')}
                  error={
                    form.getInputProps('nextProjectNum').error
                      ? form.getInputProps('nextProjectNum').error
                      : hasNextProjectNumError
                      ? nextProjectNumErrorMessage
                      : ''
                  }
                />
              }
            />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="contact">
          <ContactPersonsInput {...form.getInputProps('contactPerson')} />
        </Tabs.Panel>

        <Tabs.Panel value="invoicing">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Language')}
              input={<LanguageSelect placeholder={_t('Language')} {...form.getInputProps('language')} />}
            />
            <FormRow
              label={_t('Price list')}
              input={<PriceListSelect placeholder={_t('Price list')} {...form.getInputProps('priceListId')} />}
            />
            <FormRow
              label={_t('Currency')}
              input={<CurrencySelect disabled placeholder={_t('Currency')} {...form.getInputProps('currency')} />}
            />
            <FormRow
              label={_t('Increasing price %')}
              input={
                <MoneyInput
                  placeholder={_t('Increasing price')}
                  currency="%"
                  min={0}
                  {...form.getInputProps('increasingPrice')}
                />
              }
            />
            <FormRow
              label={_t('Require PO for invoicing')}
              input={<Switch {...form.getInputProps('addPoToInvoice', { type: 'checkbox' })} />}
            />

            <SectionTitle title={_t('Notes')} />
            <FormRow
              label={_t('Note')}
              input={<Textarea minRows={4} placeholder={_t('Note')} {...form.getInputProps('note')} />}
            />
            <FormRow
              label={_t('Internal notes - cost estimate')}
              input={
                <Textarea minRows={4} placeholder={_t('Input text')} {...form.getInputProps('noteCostEstimate')} />
              }
            />
            <FormRow
              label={_t('Internal notes - invoice')}
              input={<Textarea minRows={4} placeholder={_t('Input text')} {...form.getInputProps('noteInvoice')} />}
            />

            {/* Temporarily hide the flat fees */}
            {/* {form.values.clientId && <FlatFeeTable clientId={form.values.clientId} />} */}
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="members">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Members')}
              input={<UserTagSelect placeholder={_t('Search ...')} {...form.getInputProps('members')} />}
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
