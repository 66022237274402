/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse} SearchResults */

import { useApi } from 'api/ApiContext';
import { SEARCH_MINIMAL_LENGTH } from 'environment';
import panic from 'errors/Panic';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import { useLocation } from 'react-router-dom';
import sleep from 'utils/sleep';
import { createContext, useState, useMemo, useContext, useCallback, useEffect, useRef } from 'react';

/**
 * The search context for the SearchBar component.
 *
 * @type {React.Context<{
 *   search: string,
 *   searchResults: SearchResults,
 *   displayedResults: boolean,
 *   tooFewCharacters: boolean,
 *   loading: boolean,
 *   setSearch: (value: string) => void,
 *   setDisplayedResults: (value: boolean) => void,
 *   fetchSearchResults: (search: string) => void
 * }>}
 */
const SearchContext = createContext();

/**
 * Empty search results.
 */
const emptyResults = {
  results: [],
  hits: 0,
};

/**
 * Provides the search context to the SearchBar component.
 *
 * @param {{children: React.ReactNode}}
 */
export function SearchProvider({ children }) {
  const { getAction } = useApi();
  const location = useLocation();

  const searchId = useRef('');

  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState(emptyResults);
  const [loading, setLoading] = useState(false);
  const [displayedResults, setDisplayedResults] = useState(location?.state?.showResults || false);

  const tooFewCharacters = useMemo(() => search.length < SEARCH_MINIMAL_LENGTH, [search]);

  const fetchSearchResultsImpl = useCallback(
    /** @param {string} search */
    (search) => {
      const action = getAction('SearchDocumentsAction');

      action({ body: { needle: search } })
        .then(setSearchResults)
        .catch(panic)
        .finally(() => setLoading(false));
    },
    [getAction]
  );

  const fetchSearchResults = useMemo(
    () => debounce(fetchSearchResultsImpl, 250, { maxWait: 5000 }),
    [fetchSearchResultsImpl]
  );

  /**
   * Pretends to load the results.
   */
  const pretendLoading = (originalId) => {
    sleep(2000).then(() => {
      if (searchId.current === originalId) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    setDisplayedResults(true);
    searchId.current = nanoid();

    if (tooFewCharacters) {
      pretendLoading(searchId.current);
    } else if (search) {
      fetchSearchResults(search);
    }
  }, [search]);

  useEffect(() => {
    if (location?.state) {
      setDisplayedResults(location.state.showResults);
    }
  }, [location]);

  useEffect(() => {
    if (search === '') {
      setDisplayedResults(false);
      setSearchResults(emptyResults);
    }
  }, [search]);

  const value = useMemo(
    () => ({
      search,
      searchResults,
      displayedResults,
      tooFewCharacters,
      loading,
      setSearch,
      setDisplayedResults,
      fetchSearchResults,
    }),
    [
      search,
      searchResults,
      displayedResults,
      tooFewCharacters,
      loading,
      setSearch,
      setDisplayedResults,
      fetchSearchResults,
    ]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

/**
 * Uses the search context.
 */
export function useSearch() {
  return useContext(SearchContext);
}
