/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "TaskClosed" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function TaskClosedIcon({ className = '', height = 24, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Document type/Task closed">
        <path
          id="Vector"
          d="M11 17.5L10.6464 17.8536C10.8417 18.0488 11.1583 18.0488 11.3536 17.8536L11 17.5ZM15.3536 13.8536C15.5488 13.6583 15.5488 13.3417 15.3536 13.1464C15.1583 12.9512 14.8417 12.9512 14.6464 13.1464L15.3536 13.8536ZM9.35355 15.1464C9.15829 14.9512 8.84171 14.9512 8.64645 15.1464C8.45118 15.3417 8.45118 15.6583 8.64645 15.8536L9.35355 15.1464ZM11 13.5L10.6464 13.8536C10.8417 14.0488 11.1583 14.0488 11.3536 13.8536L11 13.5ZM15.3536 9.85355C15.5488 9.65829 15.5488 9.34171 15.3536 9.14645C15.1583 8.95118 14.8417 8.95118 14.6464 9.14645L15.3536 9.85355ZM9.35355 11.1464C9.15829 10.9512 8.84171 10.9512 8.64645 11.1464C8.45118 11.3417 8.45118 11.6583 8.64645 11.8536L9.35355 11.1464ZM18 7.5V18.5H19V7.5H18ZM16.5 20H7.5V21H16.5V20ZM6 18.5V7.5H5V18.5H6ZM7.5 6H9V5H7.5V6ZM15 6H16.5V5H15V6ZM7.5 20C6.67157 20 6 19.3284 6 18.5H5C5 19.8807 6.11929 21 7.5 21V20ZM18 18.5C18 19.3284 17.3284 20 16.5 20V21C17.8807 21 19 19.8807 19 18.5H18ZM19 7.5C19 6.11929 17.8807 5 16.5 5V6C17.3284 6 18 6.67157 18 7.5H19ZM6 7.5C6 6.67157 6.67157 6 7.5 6V5C6.11929 5 5 6.11929 5 7.5H6ZM11.3536 17.8536L15.3536 13.8536L14.6464 13.1464L10.6464 17.1464L11.3536 17.8536ZM11 4H13V3H11V4ZM13 7H11V8H13V7ZM11 7C10.1716 7 9.5 6.32843 9.5 5.5H8.5C8.5 6.88071 9.61929 8 11 8V7ZM14.5 5.5C14.5 6.32843 13.8284 7 13 7V8C14.3807 8 15.5 6.88071 15.5 5.5H14.5ZM13 4C13.8284 4 14.5 4.67157 14.5 5.5H15.5C15.5 4.11929 14.3807 3 13 3V4ZM11 3C9.61929 3 8.5 4.11929 8.5 5.5H9.5C9.5 4.67157 10.1716 4 11 4V3ZM11.3536 17.1464L9.35355 15.1464L8.64645 15.8536L10.6464 17.8536L11.3536 17.1464ZM11.3536 13.8536L15.3536 9.85355L14.6464 9.14645L10.6464 13.1464L11.3536 13.8536ZM11.3536 13.1464L9.35355 11.1464L8.64645 11.8536L10.6464 13.8536L11.3536 13.1464Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
