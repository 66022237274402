/** @typedef {import('api/actions/position-in-company-get-list-action/position-in-company-get-list-action-response').PositionInCompanyGetListActionResponse[number]} JobPosition */

import StatusSwitch from 'components/inputs/status-switch/StatusSwitch';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';

/**
 * Status switch for the Position in company model.
 *
 * @param {{
 *   jobPosition: JobPosition;
 * }}
 */
export default function JobPositionStatusSwitch({ jobPosition }) {
  const { getAction } = useApi();
  const positionInCompanySetActiveAction = getAction('PositionInCompanySetActiveAction');

  let disabled = false;
  let reason = null;

  if (jobPosition.members.length > 0) {
    disabled = true;
    reason = _t('This position is assigned to one or more users.');
  }

  return (
    <StatusSwitch
      entity={_t('Job position')}
      name={jobPosition.position_name}
      action={positionInCompanySetActiveAction}
      primaryKey={jobPosition.position_in_company_id}
      pathParameterName="position_in_company_id"
      initialValue={jobPosition.is_active}
      disabled={disabled}
      disabledReason={reason}
    />
  );
}
