/** @typedef {import('api/actions/time-log-get-list-action/time-log-get-list-action-response').TimeLogGetListActionResponse['groups'][number]['items'][number]} TimeEntry */
/** @typedef {import('hooks/use-grid-styles').GridStyles} GridStyles */

import { Box, Button } from '@mantine/core';
import DateDisplay from 'components/DateDisplay';
import DurationDisplay from 'components/DurationDisplay';
import TimeEntryActions from 'components/time-entry/TimeEntryActions';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TASK_DETAIL_PAGE_PATH } from 'routes/paths';

/**
 * Single row in the grouped time entry table.
 *
 * @param {{
 *   item: TimeEntry,
 *   gridStyles: GridStyles,
 *   refresh: () => void,
 * }}
 */
export default function GroupedTimeEntryRow({ item, gridStyles, refresh }) {
  const taskDetailPath = useMemo(
    () => TASK_DETAIL_PAGE_PATH.insert({ taskId: item.task.task_id }),
    [item.task.task_id]
  );

  return (
    <Box
      py={8}
      pr={16}
      pl={44}
      fz={15}
      c="#4D4D4D"
      style={{ borderTop: '1px solid #E6E6E6' }}
      className="group hover:bg-neutral-20"
      sx={gridStyles.row}
    >
      <DateDisplay sx={gridStyles.children[0]} date={item.start} />
      <Box sx={gridStyles.children[1]}>{item.user.full_name}</Box>
      <Box sx={gridStyles.children[2]}>{item.position_in_company.position_name}</Box>
      <Box sx={gridStyles.children[3]}>
        <Button component={Link} variant="link" to={taskDetailPath}>
          {item.task.task_full_name}
        </Button>
      </Box>
      <Box sx={gridStyles.children[4]}>{item.description}</Box>
      <DurationDisplay sx={gridStyles.children[5]} showMinutes duration={item.duration} align="right" />
      <TimeEntryActions sx={gridStyles.children[6]} timeEntry={item} onDelete={refresh} onEdit={refresh} />
    </Box>
  );
}
