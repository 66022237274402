/** @typedef {import('api/actions/user-get-list-action/user-get-list-action-response').UserGetListActionResponse[number]} User */
/** @typedef {import('api/actions/role-get-list-action/role-get-list-action-response').RoleGetListActionResponse[number]} Role */
/** @typedef {import('api/actions/team-get-list-action/team-get-list-action-response').TeamGetListActionResponse[number]} Team */
/** @typedef {import('api/actions/position-in-company-get-list-action/position-in-company-get-list-action-response').PositionInCompanyGetListActionResponse[number]} PositionInCompany */
/** @typedef {import('api/actions/department-get-list-action/department-get-list-action-response').DepartmentGetListActionResponse[number]} Department */
/** @typedef {import('api/actions/client-get-list-action/client-get-list-action-response').ClientGetListActionResponse[number]} Client */
/** @typedef {import('api/actions/price-list-get-list-action/price-list-get-list-action-response').PriceListGetListActionResponse[number]} PriceList */
/** @typedef {import('api/actions/supplier-get-list-action/supplier-get-list-action-response').SupplierGetListActionResponse[number]} Supplier */

import { createContext, useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { Outlet } from 'react-router-dom';

/** @type {User[]} */ const emptyUsers = [];
/** @type {Role[]} */ const emptyRoles = [];
/** @type {Team[]} */ const emptyTeams = [];
/** @type {PositionInCompany[]} */ const emptyPositions = [];
/** @type {Department[]} */ const emptyDepartments = [];
/** @type {Client[]} */ const emptyClients = [];
/** @type {PriceList[]} */ const emptyPriceLists = [];
/** @type {Supplier[]} */ const emptySuppliers = [];

/**
 * The data context for the Settings Sidebar.
 *
 * @type {React.Context<{
 *   users: User[],
 *   roles: Role[],
 *   teams: Team[],
 *   positions: PositionInCompany[],
 *   departments: Department[],
 *   clients: Client[],
 *   priceLists: PriceList[],
 *   suppliers: Supplier[],
 *   loading: boolean,
 *   refreshUsers: () => void,
 *   refreshRoles: () => void,
 *   refreshTeams: () => void,
 *   refreshJobPositions: () => void,
 *   refreshDepartments: () => void,
 *   refreshClients: () => void,
 *   refreshPriceLists: () => void,
 *   refreshSuppliers: () => void
 * }>}
 */
const SettingsSidebarContext = createContext();

/**
 * Provides the data to the Settings Sidebar.
 */
export function SettingsSidebarProvider() {
  const { getAction, ready, jwt, hasRole } = useApi();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(emptyUsers);
  const [roles, setRoles] = useState(emptyRoles);
  const [teams, setTeams] = useState(emptyTeams);
  const [positions, setPositions] = useState(emptyPositions);
  const [departments, setDepartments] = useState(emptyDepartments);
  const [clients, setClients] = useState(emptyClients);
  const [priceLists, setPriceLists] = useState(emptyPriceLists);
  const [suppliers, setSuppliers] = useState(emptySuppliers);

  const actionMap = useMemo(
    () => ({
      users: { action: getAction('UserGetListAction'), setter: setUsers },
      roles: { action: getAction('RoleGetListAction'), setter: setRoles },
      teams: { action: getAction('TeamGetListAction'), setter: setTeams },
      positions: { action: getAction('PositionInCompanyGetListAction'), setter: setPositions },
      departments: { action: getAction('DepartmentGetListAction'), setter: setDepartments },
      clients: { action: getAction('ClientGetListAction'), setter: setClients },
      priceLists: { action: getAction('PriceListGetListAction'), setter: setPriceLists },
      suppliers: { action: getAction('SupplierGetListAction'), setter: setSuppliers },
    }),
    [getAction]
  );

  const actionNames = useMemo(() => Object.keys(actionMap), [actionMap]);

  const refresh = useCallback(
    /** @param {keyof typeof actionMap} actionName */
    (actionName) => {
      const { action, setter } = actionMap[actionName];
      action({ query: { filter: { 'is_active.eq': 1 } } })
        .then(setter)
        .catch(panic);
    },
    [actionMap]
  );

  const refreshAll = useCallback(() => {
    setLoading(true);

    // refresh based on role
    if (hasRole('Super admin')) {
      Promise.all(actionNames.map(refresh)).finally(() => setLoading(false));
    } else {
      const actions = actionNames.filter((actionName) => actionName !== 'roles');
      Promise.all(actions.map(refresh)).finally(() => setLoading(false));
    }
  }, [actionNames, refresh]);

  const refreshUsers = useCallback(() => refresh('users'), [refresh]);
  const refreshRoles = useCallback(() => refresh('roles'), [refresh]);
  const refreshTeams = useCallback(() => refresh('teams'), [refresh]);
  const refreshJobPositions = useCallback(() => refresh('positions'), [refresh]);
  const refreshDepartments = useCallback(() => refresh('departments'), [refresh]);
  const refreshClients = useCallback(() => refresh('clients'), [refresh]);
  const refreshPriceLists = useCallback(() => refresh('priceLists'), [refresh]);
  const refreshSuppliers = useCallback(() => refresh('suppliers'), [refresh]);

  useEffect(() => {
    if (ready && jwt && !loading) {
      refreshAll();
    }
  }, [ready, jwt]);

  const value = useMemo(
    () => ({
      users,
      roles,
      teams,
      positions,
      departments,
      clients,
      priceLists,
      suppliers,
      loading,
      refreshUsers,
      refreshRoles,
      refreshTeams,
      refreshJobPositions,
      refreshDepartments,
      refreshClients,
      refreshPriceLists,
      refreshSuppliers,
    }),
    [
      users,
      roles,
      teams,
      positions,
      departments,
      clients,
      priceLists,
      suppliers,
      loading,
      refreshUsers,
      refreshRoles,
      refreshTeams,
      refreshJobPositions,
      refreshDepartments,
      refreshClients,
      refreshPriceLists,
      refreshSuppliers,
    ]
  );

  return (
    <SettingsSidebarContext.Provider value={value}>
      <Outlet />
    </SettingsSidebarContext.Provider>
  );
}

/**
 * Uses the settings sidebar context.
 */
export function useSettingsSidebar() {
  return useContext(SettingsSidebarContext);
}
