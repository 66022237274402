import { Group, NumberInput } from '@mantine/core';
import { noop } from 'lodash';
import { useMemo } from 'react';

const inputStyles = {
  input: {
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#4D4D4D',
  },
};

/**
 * Used to input a hours and minutes in human readable format.
 *
 * @param {{
 *     hoursLabel?: string|JSX.Element;
 *     minutesLabel?: string|JSX.Element;
 *     value?: number;
 *     onChange?: (value: number) => void;
 *     min?: number;
 *   }
 *   & import('@mantine/core').NumberInputProps
 * }
 */
export default function HoursAndMinutesInput({ w, hoursLabel, minutesLabel, value = 0, onChange = noop, ...props }) {
  const hours = useMemo(() => Math.floor(value / 60), [value]);
  const minutes = useMemo(() => value % 60, [value]);

  return (
    <Group spacing={8} w={w} noWrap>
      <NumberInput
        label={hoursLabel}
        value={hours}
        onChange={(hours = 0) => onChange(60 * hours + minutes)}
        min={0}
        max={23}
        hideControls
        placeholder="0"
        w="50%"
        {...props}
        styles={inputStyles}
      />

      <NumberInput
        label={minutesLabel}
        value={minutes}
        onChange={(minutes = 0) => onChange(60 * hours + minutes)}
        min={0}
        max={59}
        hideControls
        placeholder="0"
        w="50%"
        {...props}
        styles={inputStyles}
      />
    </Group>
  );
}
