import { validate } from 'email-validator';
import { _t } from 'lang';

/**
 * Validates the email format.
 *
 * @param {string} value
 */
export const emailFormat = (value) => {
  if (!validate(value)) {
    return {
      message: _t('The email address is invalid.'),
    };
  }

  return null;
};
