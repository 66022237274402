/**
 * The options for the group time entries table.
 *
 * @typedef {{
 *   groupBy: string;
 *   showDescription: boolean;
 *   dateRange: DateRange;
 * }} IOptions
 */

import { Group, Menu, Select, Switch, Text } from '@mantine/core';
import FiletypeXlsIcon from 'components/icons/FiletypeXlsIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import DateRangeCalendarInput from 'components/inputs/date-range-calendar/DateRangeCalendarInput';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useMemo } from 'react';

/**
 * Input label.
 *
 * @param {{
 *   children: React.ReactNode
 * }}
 */
function Label({ children }) {
  return (
    <Text fz={15} lh={1.2} c="#808080">
      {children}
    </Text>
  );
}

/**
 * Options bar for group time entries table.
 *
 * @param {{
 *   value: IOptions;
 *   onChange: (value: IOptions) => void;
 *   disableGroupByDate?: boolean;
 *   disableGroupByPerson?: boolean;
 *   disableGroupByPosition?: boolean;
 *   disableGroupByTask?: boolean;
 *   disableGroupByProject?: boolean;
 *   onExportStart: () => void;
 * }}
 */
export default function GroupedTimeEntriesOptions({
  value,
  onChange,
  disableGroupByDate = false,
  disableGroupByPerson = false,
  disableGroupByPosition = false,
  disableGroupByProject = false,
  disableGroupByTask = false,
  onExportStart = noop,
}) {
  const groupByOptions = useMemo(
    () =>
      [
        { label: _t('Date'), value: 'date', disabled: disableGroupByDate },
        { label: _t('Person'), value: 'user_name', disabled: disableGroupByPerson },
        { label: _t('Job Position'), value: 'position_name', disabled: disableGroupByPosition },
        { label: _t('Task'), value: 'task_full_name', disabled: disableGroupByTask },
        { label: _t('Project'), value: 'project_full_name', disabled: disableGroupByProject },
      ].filter(({ disabled }) => !disabled),
    [disableGroupByProject]
  );

  /**
   * Updates the options.
   *
   * @param {Partial<IOptions>} options
   */
  const updateOptions = (options) => onChange({ ...value, ...options });

  return (
    <Group h={64} align="center" position="apart" bg="#E6E6E6" style={{ borderRadius: '16px' }} px={16} py={4}>
      <Group spacing={16}>
        <Label>{_t('Group by')}</Label>
        <Select data={groupByOptions} value={value.groupBy} onChange={(groupBy) => updateOptions({ groupBy })} />
        <Switch
          label={<Label>{_t('Show description')}</Label>}
          labelPosition="left"
          checked={value.showDescription}
          onChange={(e) => updateOptions({ showDescription: e.currentTarget.checked })}
        />
      </Group>
      <Group spacing={16}>
        <Label>{_t('Filter')}</Label>
        <DateRangeCalendarInput
          initialDateRangeType="anytime"
          value={value.dateRange}
          onChange={(dateRange) => updateOptions({ dateRange })}
        />
      </Group>
      <Menu withinPortal position="bottom-end" offset={15}>
        <Menu.Target>
          <div className="cursor-pointer pb-[7px] pt-[7px]">
            <OptionsDotsIcon />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<FiletypeXlsIcon fill="#4D4D4D" />} onClick={onExportStart}>
            {_t('Export to XLSX')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
