import { useState } from 'react';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import panic from 'errors/Panic';

/**
 * @typedef {{
 *   fileId: string;
 *   fileName?: string;
 * }} IFile
 */

/**
 * Triggers file download in the browser.
 *
 * @param {string} url
 * @param {string} filename
 */
function downloadFile(url, filename) {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * Hook for downloading a file.
 */
export default function useFileDownload() {
  const { getFileMetadata, createAccessToken } = useFileManager();
  const [loading, setLoading] = useState(false);

  /**
   * Downloads a single file.
   *
   * @param {IFile}
   */
  async function downloadSingle({ fileId, fileName }) {
    const promises = [createAccessToken({ fileId })];

    if (!fileName) {
      promises.push(getFileMetadata({ fileId }));
    }

    const [token, metadata] = await Promise.all(promises);
    const url = `/api/files/one-time/file/${token}`;

    downloadFile(url, fileName || metadata.basename);
  }

  /**
   * Downloads all files.
   *
   * @param {IFile[]} files
   */
  function download(files) {
    if (!loading) {
      setLoading(true);

      Promise.all(files.map(downloadSingle))
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }

  return { download, loading };
}
