import { Box, Button, Group, Select, Stack, TextInput } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import CloseIcon from 'components/icons/CloseIcon';
import SearchIcon from 'components/icons/SearchIcon';
import { ADVANCED_SEARCH_LIMIT, SEARCH_MINIMAL_LENGTH } from 'environment';
import panic from 'errors/Panic';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import { APP_HEADER_HEIGHT } from 'utils/constants';
import ClientSelect from 'components/selects/ClientSelect';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import AdvancedSearchResults from './AdvancedSearchResults';
import { nanoid } from 'nanoid';
import Preloader from 'components/Preloader';

/**
 * Empty search results.
 */
const emptyResults = {
  groups: [],
  hits: 0,
};

/**
 * @typedef {{
 *  search: string;
 *  searchType: string;
 *  clientId: string;
 *  startFrom: number;
 * }} AdvancedSearchFormData
 */

/**
 * The advanced search form.
 *
 * @param {{
 *  initialValues: AdvancedSearchFormData;
 *  onSubmit: (values: AdvancedSearchFormData) => void | Promise<void>;
 *  loading?: boolean;
 * }}
 */
function AdvancedSearchForm({ initialValues, onSubmit, loading = false }) {
  const form = useForm({
    initialValues,
    validate: {
      search: (value) => {
        if (!value) {
          return _t('This field is required.');
        }

        if (value.length < SEARCH_MINIMAL_LENGTH) {
          return _t('Type at least %d characters.', SEARCH_MINIMAL_LENGTH);
        }

        return null;
      },
      searchType: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Group spacing={16}>
        <TextInput
          placeholder={_t('Search ...')}
          icon={<SearchIcon stroke={form.getInputProps('search').error ? '#BF0D38' : '#4D4D4D'} />}
          rightSection={
            form.values.search !== '' && (
              <Box
                onClick={() => {
                  form.setFieldValue('search', '');
                }}
                className="cursor-pointer"
              >
                <CloseIcon stroke={form.getInputProps('search').error ? '#BF0D38' : '#4D4D4D'} />
              </Box>
            )
          }
          className="w-[535px]"
          {...form.getInputProps('search')}
        />
        <Select
          data={[
            { label: _t('In all types'), value: 'all' },
            { label: _t('Project'), value: 'project' },
            { label: _t('Task'), value: 'task' },
            { label: _t('Client'), value: 'client' },
            { label: _t('Time log'), value: 'time_log' },
            { label: _t('Cost estimate'), value: 'cost_estimate' },
            { label: _t('External cost'), value: 'external_cost' },
            { label: _t('File'), value: 'file' },
            { label: _t('Comment'), value: 'comment' },
          ]}
          className="w-[278px]"
          {...form.getInputProps('searchType')}
        />
        <ClientSelect className="w-[278px]" {...form.getInputProps('clientId')} />
        <Button variant="primary" type="submit" loading={loading}>
          {_t('Search')}
        </Button>
      </Group>
    </form>
  );
}

/**
 * Advanced search page.
 */
export default function AdvancedSearchPage() {
  const { getAction } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState(emptyResults);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('all');

  const fetchSearchResults = useCallback(
    /** @param {{search: string, searchType: string, clientId: string, startFrom: number}} */
    ({ search, searchType, clientId, startFrom }) => {
      setLoading(true);
      const action = getAction('SearchDocumentsAction');
      setSearch(search);
      setSearchType(searchType);

      return action({
        body: {
          needle: search,
          limit: ADVANCED_SEARCH_LIMIT,
          search_type: searchType,
          client_id: clientId,
          start_from: startFrom,
        },
      })
        .then((response) => {
          setSearchResults(response);
          setSearchParams({ query: search, searchType: searchType }, { replace: true });
        })
        .catch(panic)
        .finally(() => setLoading(false));
    },
    [getAction]
  );

  const handleLoadMoreResults = useCallback(
    /** @param {{search: string, searchType: string, clientId: string, startFrom: number}} */
    ({ search, searchType, clientId, startFrom }) => {
      setLoading(true);
      const action = getAction('SearchDocumentsAction');

      return action({
        body: {
          needle: search,
          limit: ADVANCED_SEARCH_LIMIT,
          search_type: searchType,
          client_id: clientId,
          start_from: startFrom,
        },
      })
        .then((response) => {
          setSearchResults(({ groups, hits }) => {
            const newGroups = [...groups];
            const oldDataIndex = newGroups.findIndex((g) => g.name === searchType);

            if (oldDataIndex !== -1) {
              newGroups[oldDataIndex].items.push(...response.groups[0].items);
              newGroups[oldDataIndex].more = response.groups[0].more;
            }

            return { hits, groups: newGroups };
          });
        })
        .catch(panic)
        .finally(() => setLoading(false));
    },
    [getAction]
  );

  const initialValues = useMemo(
    () => ({
      search,
      searchType,
      clientId: '',
      startFrom: 0,
    }),
    [search, searchType]
  );

  useEffect(() => {
    // query the results instantly if the query string is in the URL
    if (searchParams.get('query')) {
      const search = searchParams.get('query');
      setSearch(search);

      let searchType = 'all';
      if (searchParams.get('searchType')) {
        searchType = searchParams.get('searchType');
        setSearchType(searchType);
      }

      fetchSearchResults({ search, searchType, clientId: '', startFrom: 0 });
    }
  }, []);

  return (
    <DashboardLayout>
      <div className="bg-neutral-50 pb-8" style={{ minHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)` }}>
        <PageHeading heading={_t('Advanced search')} breadcrumbs={null} className="px-8" />
        <Box style={{ borderTop: '1px solid #B3B3B3' }} mt={8} />
        <Stack spacing={16} pt={16} pl={32} pr={32}>
          <Box fz={20} lh={36 / 20} c="#4D4D4D">
            {_t('Search results for')}
          </Box>
          <AdvancedSearchForm
            key={nanoid()} // so that the search is updated
            initialValues={{ ...initialValues, search, searchType }}
            onSubmit={fetchSearchResults}
            loading={loading}
          />
          {loading ? (
            <Group position="center" pt={64} pr={64}>
              <Preloader size={64} />
            </Group>
          ) : (
            <AdvancedSearchResults
              results={searchResults}
              search={search}
              loadMore={({ searchType, startFrom }) =>
                handleLoadMoreResults({ search, searchType, clientId: '', startFrom })
              }
            />
          )}
        </Stack>
      </div>
    </DashboardLayout>
  );
}
