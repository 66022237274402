import { Extension } from '@tiptap/core';

/**
 * An extension that allows submitting the form by pressing Ctrl+Enter.
 */
export const CtrlEnterSubmit = Extension.create({
  name: 'ctrlEnterSubmit',

  /**
   * Adds keyboard shortcuts to the editor.
   */
  addKeyboardShortcuts() {
    /**
     * Creates a submit button and clicks it to submit the form.
     */
    const submit = () => {
      const { element: eEditor } = this.editor.options;
      const eBtn = document.createElement('button');
      eBtn.type = 'submit';
      eBtn.style.display = 'none';
      eEditor.appendChild(eBtn);
      eBtn.click();
      eEditor.removeChild(eBtn);
      return true;
    };

    return {
      'Cmd-Enter': submit,
      'Ctrl-Enter': submit,
    };
  },
});
