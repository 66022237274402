/**
 * The regex to match placeholders.
 */
const placeholderRegex = /%[sd]/;

/**
 * Formats the string replacing placeholders with the given parameters.
 *
 * @example
 *
 * sprintf('Hello, %s!', 'World');
 * sprintf('2 + 4 = %d', 6);
 *
 * @param {string} text The text to format.
 * @param {string[]} parameters Parameters to replace %s or %d placeholders
 *
 * @returns {string} The formatted text.
 */
export function sprintf(text, ...parameters) {
  while (text.match(placeholderRegex)) {
    text = text.replace(placeholderRegex, parameters.shift());
  }

  return text;
}

/**
 * Translates the text.
 *
 * @example
 *
 * _t('Hello, %s!', 'World');
 * _t('2 + 4 = %d', 6);
 *
 * @param {string} text The text to translate.
 * @param {string[]} parameters Parameters to replace %s or %d placeholders
 *
 * @returns {string} The translated text.
 */
export function _t(text, ...parameters) {
  // TODO: Apply sprintf to the text before passing it to the translation function.
  return sprintf(text, ...parameters);
}

/**
 * Translates the text using singular or plural form.
 *
 * @example
 *
 * _n('There is %d apple', 'There are %d apples', 1); // There is 1 apple
 * _n('There is %d apple', 'There are %d apples', 2); // There are 2 apples
 *
 * @param {string} singular The singular form of the text.
 * @param {string} plural The plural form of the text.
 * @param {number} count The number to determine the form of the text.
 *
 * @returns {string} The translated text.
 */
export function _n(singular, plural, count) {
  return count === 1 ? singular : plural;
}
