import React, { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import UserNameWithAvatar from 'components/avatars/UserNameWithAvatar';
import { noop } from 'lodash';

/**
 * Context for the MentionList component.
 *
 * @typedef {(view: EditorView, image: Blob) => Promise<Node>} ICreateToolioImage
 *
 * @type {React.Context<{
 *   onUserMentioned: (userId: number) => void;
 * }>}
 */
export const MentionListContext = createContext();

/**
 * A custom image extension for the wysiwyg editor.
 *
 * @param {{
 *   children: React.ReactNode;
 *   onUserMentioned?: (userId: number) => void;
 * }}
 */
export function MentionListProvider({ children, onUserMentioned = noop }) {
  const value = useMemo(() => ({ onUserMentioned }), [onUserMentioned]);
  return <MentionListContext.Provider value={value}>{children}</MentionListContext.Provider>;
}

/**
 * Uses the mention list context.
 */
export function useMentionListContext() {
  return useContext(MentionListContext);
}

export const MentionList = forwardRef((props, ref) => {
  const { onUserMentioned } = useMentionListContext();
  const [selectedIndex, setSelectedIndex] = useState(0);

  /**
   * Selects an item by its index.
   *
   * @param {number} index - The index of the item to be selected.
   */
  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.user_id, label: item.full_name });
      onUserMentioned(item.user_id);
    }
  };

  /**
   * Handles the 'up' action by updating the selected index to the previous item in the list.
   * If the current selected index is the first item, it wraps around to the last item.
   *
   * @function upHandler
   * @returns {void}
   */
  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  /**
   * Decreases the selected index by 1, wrapping around to the beginning if necessary.
   *
   * @function
   * @memberof module:MyApp
   * @alias downHandler
   * @inner
   *
   * @param {number} selectedIndex - The current selected index.
   * @param {Array} items - The list of items to select from.
   */
  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  /**
   * Handle the enter key press event.
   *
   * @function enterHandler
   *
   * @returns {void}
   */
  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items relative z-10 flex flex-col border border-solid border-border-color bg-white">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={`item px-2.5 py-1.5 ${index === selectedIndex ? 'is-selected bg-main-secondary' : ''}`}
            key={item.user_id}
            onClick={() => selectItem(index)}
          >
            <UserNameWithAvatar avatar={item.avatar} name={item.full_name} />
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});

MentionList.displayName = 'MentionList';
