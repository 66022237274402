/** @typedef {{start: Date, end: Date}} DateRange */

import { Group, Stack, Text } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { useCallback, useEffect, useMemo, useState } from 'react';
import GroupedTimeEntry from './GroupedTimeEntry';
import { createDateRangeFromType } from 'components/inputs/date-range/date-range';
import GroupedTimeEntriesOptions from './GroupedTimeEntriesOptions';
import useGridStyles from 'hooks/use-grid-styles';
import { noop } from 'lodash';
import { exportFile } from 'pages/time-entries/export/export-file';

/**
 * Displays project time entries grouped by a value.
 *
 * @param {{
 *   taskId?: number;
 *   projectId?: number;
 *   onLoaded?: (nTimeLogs: number) => void;
 *   refreshToken?: string;
 * }}
 */
export default function GroupedTimeEntriesTable({ taskId, projectId, refreshToken, onLoaded = noop } = {}) {
  const { getAction } = useApi();
  const [data, setData] = useState(() => ({ groups: [] }));

  const [initialGroupBy] = useState(() => {
    if (taskId) {
      return 'task_full_name';
    }

    if (projectId) {
      return 'project_full_name';
    }

    return 'date';
  });

  const [options, setOptions] = useState(() => ({
    groupBy: initialGroupBy,
    showDescription: true,
    dateRange: createDateRangeFromType('anytime'),
  }));

  const [effectiveOptions, setEffectiveOptions] = useState(options);

  const columns = useMemo(
    () => [
      {
        label: _t('Date'),
        key: 'start',
        visible: effectiveOptions.groupBy !== 'date',
        width: '140px',
      },
      {
        label: _t('Person'),
        key: 'user_name',
        visible: effectiveOptions.groupBy !== 'user_name',
        width: '200px',
      },
      {
        label: _t('Job position'),
        key: 'position_name',
        visible: effectiveOptions.groupBy !== 'position_name',
        width: '180px',
      },
      {
        label: _t('Task'),
        key: 'task_full_name',
        visible: !taskId && effectiveOptions.groupBy !== 'task_full_name',
        width: '160px',
      },
      {
        label: _t('Description'),
        key: 'description',
        visible: effectiveOptions.showDescription,
        width: '1fr',
      },
      {
        label: _t('Time'),
        key: 'duration',
        visible: true,
        width: effectiveOptions.showDescription ? '96px' : '1fr',
        align: 'right',
      },
      {
        label: '',
        key: 'actions',
        visible: true,
        width: '24px',
      },
    ],
    [effectiveOptions, taskId]
  );

  const gridStyles = useGridStyles(columns);

  const additionalFilters = useMemo(() => {
    if (taskId) {
      return { 'task_id.eq': taskId };
    }

    if (projectId) {
      return { 'project_id.eq': projectId };
    }

    return {};
  }, [taskId, projectId]);

  const fetchData = useCallback(() => {
    setOptions((options) => {
      const timeLogGetListAction = getAction('TimeLogGetListAction');

      timeLogGetListAction({
        query: {
          filter: {
            'start.gte': options.dateRange.start.toISOString(),
            'end.lte': options.dateRange.end.toISOString(),
            ...additionalFilters,
          },
          sort: [{ by: 'start', order: 'desc' }],
          group_by: options.groupBy,
        },
      })
        .then((data) => {
          setData(data);
          setEffectiveOptions(options);

          const nTimeLogs = data.groups.reduce((acc, group) => acc + group.items.length, 0);
          onLoaded(nTimeLogs);
        })
        .catch(panic);

      return options;
    });
  }, [getAction, additionalFilters]);

  /**
   * Retrieves the export from the API.
   * The export is in the CSV format.
   */
  async function exportData() {
    const timeLogExportAction = getAction('TimeLogExportAction');

    const filter = {
      'start.gte': effectiveOptions.dateRange.start.toISOString(),
      'end.lte': effectiveOptions.dateRange.end.toISOString(),
      ...additionalFilters,
    };

    const { file_contents: contents } = await timeLogExportAction({ query: { filter } });

    exportFile({ contents, fileName: 'time-entries.xlsx' });
  }

  // Refresh data when options change.
  useEffect(fetchData, [options, additionalFilters, refreshToken]);

  return (
    <Stack spacing={16}>
      <GroupedTimeEntriesOptions
        value={options}
        onChange={setOptions}
        disableGroupByProject={!!taskId}
        onExportStart={exportData}
      />
      <Stack spacing={0} py={16} bg="#FFF" style={{ borderRadius: '8px' }}>
        <Group sx={gridStyles.row} pr={16} pl={44} c="#808080" bg="#FFF" fz={12} lh={1.5}>
          {columns.map(({ key, label }, i) => (
            <Text key={key} sx={gridStyles.children[i]}>
              {label}
            </Text>
          ))}
        </Group>
        {data.groups.map((group) => (
          <GroupedTimeEntry
            key={group.group_name}
            group={group}
            groupBy={effectiveOptions.groupBy}
            refresh={fetchData}
            gridStyles={gridStyles}
          />
        ))}
      </Stack>
    </Stack>
  );
}
