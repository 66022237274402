/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-delete-attachment-action-body').CommentDeleteAttachmentActionBody} CommentDeleteAttachmentActionBody
 */

/**
 * @typedef {import('./comment-delete-attachment-action-response').CommentDeleteAttachmentActionResponse} CommentDeleteAttachmentActionResponse
 */

/**
 * The path of the CommentDeleteAttachmentAction action.
 */
export const commentDeleteAttachmentActionPath = new SweetPath('/api/comment/comment_attachment_delete/{comment_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized CommentDeleteAttachmentAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentDeleteAttachmentAction(axios) {
  /**
   * The commentDeleteAttachmentAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CommentDeleteAttachmentActionBody } }
   *
   * @returns {Promise<CommentDeleteAttachmentActionResponse>}
   */
  function commentDeleteAttachmentAction({ headers = {}, parameters = {}, body } = {}) {
    const path = commentDeleteAttachmentActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return commentDeleteAttachmentAction;
}
