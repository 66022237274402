/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./position-in-company-get-list-action-body').PositionInCompanyGetListActionBody} PositionInCompanyGetListActionBody
 */

/**
 * @typedef {import('./position-in-company-get-list-action-response').PositionInCompanyGetListActionResponse} PositionInCompanyGetListActionResponse
 */

/**
 * The path of the PositionInCompanyGetListAction action.
 */
export const positionInCompanyGetListActionPath = new SweetPath('/api/user/position_in_company/', { path: '{param}' });

/**
 * Creates a contextualized PositionInCompanyGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPositionInCompanyGetListAction(axios) {
  /**
   * The positionInCompanyGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<PositionInCompanyGetListActionResponse>}
   */
  function positionInCompanyGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = positionInCompanyGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return positionInCompanyGetListAction;
}
