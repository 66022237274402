/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-get-list-action-body').CostEstimateGetListActionBody} CostEstimateGetListActionBody
 */

/**
 * @typedef {import('./cost-estimate-get-list-action-response').CostEstimateGetListActionResponse} CostEstimateGetListActionResponse
 */

/**
 * The path of the CostEstimateGetListAction action.
 */
export const costEstimateGetListActionPath = new SweetPath('/api/finance/cost_estimate', { path: '{param}' });

/**
 * Creates a contextualized CostEstimateGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateGetListAction(axios) {
  /**
   * The costEstimateGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<CostEstimateGetListActionResponse>}
   */
  function costEstimateGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = costEstimateGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return costEstimateGetListAction;
}
