import { Collapse, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { _t } from 'lang';
import { useTask } from 'providers/task/TaskProvider';
import { useMemo } from 'react';
import FormattedTextDisplay from '../../../../components/FormattedTextDisplay';

/**
 * Displays the task description section.
 */
export default function TaskDescription() {
  const { task } = useTask();
  const key = useMemo(() => `toolio.task.detail.${task.task_id}.description.opened`, [task]);
  const [opened, { toggle }] = useLocalStorageDisclosure(key, true);

  return (
    <Stack spacing={16}>
      <GroupHeadline
        className="top-[120px] z-[90] bg-neutral-50 md:sticky"
        heading={_t('DESCRIPTION')}
        opened={opened}
        setOpened={toggle}
      />
      <Collapse in={opened}>
        <FormattedTextDisplay text={task.description} />
      </Collapse>
    </Stack>
  );
}
