import { openContextModal } from '@mantine/modals';
import { useApi } from 'api/ApiContext';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

/**
 * Displays the children only if the user is logged in.
 *
 * @param {{
 *   redirect: string
 * }}
 */
export default function RequireAuth({ redirect }) {
  const navigate = useNavigate();
  const { shouldResumeSession, jwt } = useApi();
  const location = useLocation();

  useEffect(() => {
    // Redirect to the login page if the user is not logged in.
    if (!jwt) {
      navigate(redirect, { replace: true, state: { from: location.pathname + location.search } });
    }

    // Open the resume session modal.
    else if (shouldResumeSession) {
      openContextModal({
        withCloseButton: false,
        centered: true,
        closeOnClickOutside: false,
        closeOnEscape: false,
        modal: 'resumeSession',
        size: 'auto',
        padding: 0,
      });
    }
  }, [jwt, shouldResumeSession]);

  if (!jwt) {
    return <></>; // Always hide page content if the user is not logged in.
  }

  return <Outlet />;
}
