/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "Search" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function SearchIcon({ className = '', height = 24, width = 24, stroke = 'white', fill = 'none' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Top_navigation/search">
        <path
          id="Vector"
          d="M16 18L19 21.5M18.5 12.0826C18.5 16.2703 15.1421 19.5 11 19.5C6.85786 19.5 3.5 16.2703 3.5 12.0826C3.5 7.89483 6.85786 4.5 11 4.5C15.1421 4.5 18.5 7.89483 18.5 12.0826Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
