import { TypographyStylesProvider } from '@mantine/core';
import { useMemo } from 'react';

/**
 * Renders string with HTML markup as HTML element(s).
 *
 * @param {{
 *   text: string,
 *   [key: string]: any
 * }}
 */
export default function FormattedTextDisplay({ text, ...props }) {
  const processedHtml = useMemo(() => {
    const parser = new DOMParser();

    const doc = parser.parseFromString(text, 'text/html');

    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });

    return doc.body.innerHTML;
  }, [text]);

  return (
    <TypographyStylesProvider {...props}>
      <div dangerouslySetInnerHTML={{ __html: processedHtml }} />
    </TypographyStylesProvider>
  );
}
