/** @typedef {import('api/actions/cost-estimate-get-list-action/cost-estimate-get-list-action-response').CostEstimateGetListActionResponse[number]} CostEstimate */

import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import Alert from 'components/Alert';
import { Box } from '@mantine/core';
import InvoiceRequestForm from 'components/forms/invoice-request-form/InvoiceRequestForm';
import { useEffect, useState } from 'react';
import { useFileManager } from 'api/file-manager/FileManagerContext';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the edit invoice request page.
 *
 * @param {{
 *   costEstimate: CostEstimate;
 * }}
 */
export default function EditInvoiceRequestPageContent({ costEstimate }) {
  const navigate = useNavigate();
  const { costEstimateId, purchaseOrderId } = useParams();

  const { getAction } = useApi();
  const { getFileMetadata } = useFileManager();

  const [initialFiles, setInitialFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  const detailPath = FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.insert({ costEstimateId });
  const detailTab = `${detailPath}?tab=purchase-order`;

  useEffect(() => {
    /**
     * Retrieves the data from the API.
     */
    async function fetchData() {
      const purchaseOrderInvoiceRequestGetListAction = getAction('PurchaseOrderGetListAction');

      setLoading(true);

      try {
        const data = await purchaseOrderInvoiceRequestGetListAction({
          query: {
            filter: { 'purchase_order_id.eq': purchaseOrderId },
            limit: 1,
          },
        });

        if (data.length === 0) {
          setInitialValues(null);
          setLoading(false);
          return;
        }

        const poInvoiceRequest = data[0];
        const fileId = poInvoiceRequest.file_id;

        const initialValues = {
          poNumber: poInvoiceRequest.po_number,
          fileId,
          textInvoice: poInvoiceRequest.invoice_request.invoice_subject,
          invoiceRequestId: poInvoiceRequest.invoice_request.invoice_request_id,
          supplyDate: poInvoiceRequest.invoice_request.supplied
            ? new Date(poInvoiceRequest.invoice_request.supplied)
            : undefined,
          note: poInvoiceRequest.invoice_request.note,
          invoicedAmount: costEstimate.amount_after_discount,
        };
        setInitialValues(initialValues);

        if (fileId) {
          const file = await getFileMetadata({ fileId });
          setInitialFiles([
            {
              uuid: file.fileId,
              fileName: file.basename,
              fileType: file.mimeType,
              remoteId: fileId,
              progress: 1,
              finalized: true,
            },
          ]);
        }
      } catch (e) {
        panic(e);
      }

      setLoading(false);
    }

    fetchData();
  }, [purchaseOrderId, getAction, getFileMetadata, costEstimate.amount_after_discount]);

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ poNumber, fileId, textInvoice, supplyDate, note, invoiceRequestId }) => {
    const purchaseOrderInvoiceRequestUpdateAction = getAction('PurchaseOrderInvoiceRequestUpdateAction');

    return purchaseOrderInvoiceRequestUpdateAction({
      parameters: {
        purchase_order_id: purchaseOrderId,
      },
      body: {
        po_number: poNumber,
        file_id: fileId[0].fileId,
        invoice_subject: textInvoice,
        supplied: new Date(supplyDate).toLocaleDateString(),
        note,
        invoice_request_id: invoiceRequestId,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Purchase order and Invoice request updated'),
          message: _t("Purchase order '%s' and Invoice request '%s' have been updated successfully.",poNumber,textInvoice), // prettier-ignore
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        navigate(detailTab, { replace: true });
      })
      .catch(panic);
  };

  if (costEstimate.closed_at || costEstimate.invoice) {
    return <Navigate to={detailTab} replace />;
  }

  if (!loading && !initialValues) {
    return <NotFoundPageContent />;
  }

  return (
    <Box>
      <Box pt={24} pb={16}>
        <Alert
          severity="notification"
          primaryText={_t('Open attached invoice and fill in all information from the invoice.')}
        />
      </Box>
      {!loading && (
        <InvoiceRequestForm
          initialValues={initialValues}
          onSubmit={submit}
          costEstimate={costEstimate}
          initialFiles={initialFiles}
          mainActionText={_t('Update')}
        />
      )}
    </Box>
  );
}
