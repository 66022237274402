import { Box, Tooltip } from '@mantine/core';
import HintIcon from 'components/icons/HintIcon';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

/**
 * One row in a Finance Module form.
 *
 * @param {{
 *   label: JSX.Element | string,
 *   input: React.ReactNode,
 *   tooltipText: string,
 *   leftAction?: React.ReactNode,
 *   className?: string,
 *   labelWidth?: number,
 * }}
 */
export default function FormRow({ label, input, tooltipText, leftAction = null, className = '', labelWidth = 240 }) {
  const gridTemplateColumns = useMemo(() => `${labelWidth}px 532px`, [labelWidth]);

  return (
    <Box className={className}>
      <div style={{ gridTemplateColumns }} className="grid items-stretch gap-[16px]">
        <div className="py-2 text-[15px] leading-[18px] text-nautral-700">{label}</div>
        <div className="flex items-start gap-2">
          <div className="w-[500px] self-center">{input}</div>

          {!isEmpty(tooltipText) && (
            <Tooltip label={tooltipText} withArrow>
              <div className="h-9 w-6 py-1.5">
                <HintIcon width={24} height={24} />
              </div>
            </Tooltip>
          )}

          {leftAction && <div className="h-9 w-6 py-1.5">{leftAction}</div>}
        </div>
      </div>
    </Box>
  );
}
