/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "FiletypePdf" icon.
 *
 * @param { { className?: string, height?: number, width?: number,   fill?: string,  } }
 */
export default function FiletypePdfIcon({ className = '', height = 24, width = 24, fill = '#4D4D4D' } = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.5C5 4.11929 6.11929 3 7.5 3H14.0925C14.8134 3 15.4992 3.3112 15.9739 3.85374L18.3814 6.60521C18.7802 7.06094 19 7.64591 19 8.25147V11C19 11.2761 18.7761 11.5 18.5 11.5C18.2239 11.5 18 11.2761 18 11V8.25147C18 8.1667 17.9928 8.0826 17.9788 8H15.5C14.6716 8 14 7.32843 14 6.5V4H7.5C6.67157 4 6 4.67157 6 5.5V18.5C6 19.3284 6.67157 20 7.5 20H17C17.2761 20 17.5 20.2239 17.5 20.5C17.5 20.7761 17.2761 21 17 21H7.5C6.11929 21 5 19.8807 5 18.5V5.5ZM15.2213 4.51224L17.3981 7H15.5C15.2239 7 15 6.77614 15 6.5V4.30568C15.0799 4.36639 15.1541 4.43545 15.2213 4.51224Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99998 18V13H9.02914C9.39724 13 9.72955 13.0486 10.0261 13.1457C10.3226 13.2428 10.5578 13.409 10.7316 13.6442C10.9105 13.8742 11 14.1887 11 14.5874C11 14.9709 10.9131 15.2853 10.7393 15.5307C10.5654 15.7761 10.3303 15.9601 10.0337 16.0828C9.74233 16.2004 9.41769 16.2592 9.05981 16.2592H8.0506V18H6.99998ZM8.0506 15.4156H8.9908C9.32311 15.4156 9.56851 15.3466 9.72699 15.2086C9.88548 15.0654 9.96472 14.8584 9.96472 14.5874C9.96472 14.3062 9.87781 14.112 9.70399 14.0046C9.53528 13.8921 9.28732 13.8359 8.96012 13.8359H8.0506V15.4156Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18V13H13.388C13.8993 13 14.4611 13.0895 14.8343 13.2684C15.2076 13.4422 15.4939 13.7132 15.6932 14.0813C15.8977 14.4443 16 14.9095 16 15.477C16 16.0445 15.8977 16.5148 15.6932 16.888C15.4939 17.2613 15.2127 17.5399 14.8497 17.7239C14.4867 17.908 13.9402 18 13.4494 18H12ZM13.0506 17.1488H13.3267C13.6232 17.1488 14.001 17.0925 14.2208 16.9801C14.4407 16.8676 14.612 16.6912 14.7347 16.4509C14.8574 16.2055 14.9187 15.8809 14.9187 15.477C14.9187 15.0731 14.8574 14.7536 14.7347 14.5184C14.612 14.2781 14.4407 14.1069 14.2208 14.0046C14.001 13.8972 13.6232 13.8436 13.3267 13.8436H13.0506V17.1488Z"
        fill={fill}
      />
      <path d="M17 13V18H18.0506V16.0061H20.2009V15.1242H18.0506V13.8819H20.5V13H17Z" fill={fill} />
    </svg>
  );
}
