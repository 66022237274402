import { showNotification } from '@mantine/notifications';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { SUPPLIERS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import SupplierForm from 'components/forms/supplier-form/SupplierForm';
import panic from 'errors/Panic';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';

/**
 * The content of the EditSupplierPage.
 *
 * @param {{ supplierId: string }}
 */
function EditSupplierImpl({ supplierId }) {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshSuppliers } = useSettingsSidebar();

  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const supplierGetListAction = getAction('SupplierGetListAction');

    setLoading(true);

    supplierGetListAction({
      query: {
        filter: { 'supplier_id.eq': supplierId },
        limit: 1,
      },
    })
      .then((data) => {
        if (data.length === 0) {
          setInitialValues({});
          return;
        }

        const supplier = data[0];

        const initialValues = {
          name: supplier.supplier_name,
          ico: supplier.ico,
          dic: supplier.dic,
          icDph: supplier.ic_dph || '',
          streetAndNumber: supplier.street_and_number,
          city: supplier.city,
          zipCode: supplier.zip_code,
          country: supplier.country,
          note: supplier.note,
          contactPerson: supplier.contact_persons.map(({ full_name, email, phone }) => ({
            uuid: nanoid(),
            name: full_name,
            email,
            phone,
          })),
          language: supplier.language,
          currency: supplier.currency,
          supplierType: supplier.supplier_type,
        };

        setInitialValues(initialValues);
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [getAction, supplierId]);

  if (!supplierId || (!loading && isEmpty(initialValues))) {
    return <NotFoundPageContent />;
  }

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({
    name,
    ico,
    dic,
    icDph,
    streetAndNumber,
    city,
    zipCode,
    country,
    contactPerson,
    note,
    language,
    currency,
    supplierType,
  }) => {
    const supplierUpdateAction = getAction('SupplierUpdateAction');

    return supplierUpdateAction({
      parameters: {
        supplier_id: supplierId,
      },
      body: {
        supplier_name: name,
        ico,
        dic,
        ic_dph: icDph,
        street_and_number: streetAndNumber,
        city: city,
        zip_code: zipCode,
        country: country,
        contact_persons: contactPerson.map(({ name, email, phone }) => ({ full_name: name, email, phone })),
        language,
        currency,
        note,
        supplier_type: supplierType,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Supplier updated'),
          message: _t("Supplier '%s' has been updated successfully.", name),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshSuppliers();
      })
      .catch((e) => {
        panic(e);

        navigate(SUPPLIERS_PAGE_PATH.original);
      });
  };

  return (
    <>
      <PageHeading heading={_t('Edit supplier')} />
      {!loading && <SupplierForm initialValues={initialValues} onSubmit={submit} />}
    </>
  );
}

/**
 * The Edit Supplier page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=522%3A26734
 */
export default function EditSupplierPage() {
  const { supplierId } = useParams();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <EditSupplierImpl key={supplierId} supplierId={supplierId} />
      </div>
    </DashboardLayout>
  );
}
