/** @typedef {import("components/comments/data/types").CommentReactionType} CommentReactionType */

import { Box, Menu, Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import IconEmoji from 'components/inputs/wysiwyg/controls/emoji/IconEmoji';
import Picker from '@emoji-mart/react';
import { useDisclosure } from '@mantine/hooks';

/**
 * Used to add emojis.
 */
export default function ControlEmoji() {
  const { editor } = useRichTextEditorContext();
  const [opened, { toggle, close }] = useDisclosure(false);

  /**
   * Handles the selection of an emoji.
   *
   * @param {object} emoji - The selected emoji.
   *
   * @returns {void}
   */
  const handleEmojiSelect = (emoji) => {
    editor?.chain().focus().insertContent(emoji.native).run();
    close();
  };

  return (
    <Menu opened={opened} onClose={close} withinPortal exitTransitionDuration={0}>
      <Menu.Target>
        <Tooltip label="Emoji" openDelay={100} closeDelay={50}>
          <RichTextEditor.Control onClick={toggle} active={opened}>
            <Box w={20} h={20}>
              <IconEmoji />
            </Box>
          </RichTextEditor.Control>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <Picker onEmojiSelect={handleEmojiSelect} onClickOutside={close} />
      </Menu.Dropdown>
    </Menu>
  );
}
