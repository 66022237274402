import { Collapse } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import UserNameWithAvatar from 'components/avatars/UserNameWithAvatar';
import { _t } from 'lang';
import { useState } from 'react';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import ProjectInformationRow from 'components/project/ProjectInformationRow';

/**
 * Displays the project information section in the cost estimate detail page.
 */
export default function ProjectInformation() {
  const { costEstimate } = useCostEstimateDetail();

  const [opened, setOpened] = useState(true);

  const {
    client: { client_name: clientName },
    project: {
      project_full_name: projectName,
      project_holder: { avatar: projectHolderAvatar, full_name: projectHolderName },
    },
    cost_estimate_name: costEstimateName,
    description: costEstimateDescription,
  } = costEstimate;

  return (
    <div className="pt-4">
      <GroupHeadline heading={_t('Project information')} opened={opened} setOpened={setOpened} />
      <Collapse in={opened}>
        <div className="flex flex-col gap-4 pt-4">
          <ProjectInformationRow label={_t('Client')} value={clientName} />
          <ProjectInformationRow label={_t('Project')} value={projectName} />
          <ProjectInformationRow label={_t('Cost estimate name')} value={costEstimateName} />
          <ProjectInformationRow label={_t('Scope description')} value={costEstimateDescription} />
          <ProjectInformationRow
            label={_t('Project holder')}
            value={<UserNameWithAvatar avatar={projectHolderAvatar} name={projectHolderName} />}
          />
        </div>
      </Collapse>
    </div>
  );
}
