/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./client-update-action-body').ClientUpdateActionBody} ClientUpdateActionBody
 */

/**
 * @typedef {import('./client-update-action-response').ClientUpdateActionResponse} ClientUpdateActionResponse
 */

/**
 * The path of the ClientUpdateAction action.
 */
export const clientUpdateActionPath = new SweetPath('/api/project/client/{client_id}', { path: '{param}' });

/**
 * Creates a contextualized ClientUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createClientUpdateAction(axios) {
  /**
   * The clientUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ClientUpdateActionBody } }
   *
   * @returns {Promise<ClientUpdateActionResponse>}
   */
  function clientUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = clientUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return clientUpdateAction;
}
