/** @typedef {import('api/actions/task-get-subtasks-action/task-get-subtasks-action-response').TaskGetSubtasksActionResponse[number]} Subtask */

import SubaskClosedIcon from 'components/icons/SubaskClosedIcon';
import SubtaskIcon from 'components/icons/SubtaskIcon';
import Truncate from 'components/Truncate';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TASK_DETAIL_PAGE_PATH } from 'routes/paths';

/**
 * Single subtask in the ProjectSidebar menu.
 *
 * @param {{
 *   projectId: number;
 *   taskId: number;
 *   subtask: Subtask;
 * }}
 */
export default function SubtaskMenuItem({ projectId, taskId, subtask }) {
  const { taskId: pathTaskId } = useParams();

  const detailLink = useMemo(() => TASK_DETAIL_PAGE_PATH.insert({ taskId: subtask.task_id }), [subtask, projectId]);
  const isClosed = useMemo(() => !!subtask?.closed_at, [subtask]);
  const isDeleted = useMemo(() => !!subtask?.deleted_at, [subtask]);
  const isDone = useMemo(() => isClosed || isDeleted, [isClosed, isDeleted]);

  const bgColor = subtask.parent_task_id === taskId && String(subtask.task_id) === pathTaskId ? 'bg-neutral-200' : '';

  return (
    <div className={`flex w-full items-center rounded-[8px] py-1 pl-[56px] pr-2 ${bgColor} hover:bg-neutral-200`}>
      <div className="w-[250px] whitespace-nowrap">
        <Link to={detailLink} className="flex w-full flex-row items-center gap-1 text-neutral-700 hover:underline">
          <div className="h-6 w-6">{isDone ? <SubaskClosedIcon /> : <SubtaskIcon />}</div>
          <Truncate
            className={`text-[15px] leading-[18px] ${isDone ? 'line-through' : ''}`}
            text={subtask.task_full_name}
          />
        </Link>
      </div>
    </div>
  );
}
