/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['DatePicker']} DatePickerTheme */

import { ErrorStyles } from 'theme/components/Inputs/shared/Error';

/** @type {DatePickerTheme} */
export const DatePicker = {
  styles: {
    label: {
      fontWeight: 400,
    },
    cell: {
      paddingBottom: '2px',
      height: '32px',
      width: '32px',
    },
    day: {
      height: '32px',
      width: '100%',
      color: '#808080',
      lineHeight: '16px',
      fontSize: '14px',
      '&:hover': {
        borderRadius: '50%',
        color: '#38298B',
        backgroundColor: '#908EFB',
      },
      '&[data-weekend]': {
        color: '#808080',
      },
      '&[data-outside]': {
        color: '#C6C6C6',
      },
      '&[data-selected]': {
        backgroundColor: '#38298B',
        color: '#FFFFFF',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: '#908EFB',
        },
      },
    },
    weekdayCell: {
      paddingBottom: '8px',
      height: '32px',
      width: '32px',
    },
    weekday: {
      color: '#808080',
      fontSize: '12px',
      lineHeight: '14px',
    },
    calendarHeader: {
      marginBottom: '8px',
    },
    calendarHeaderLevel: {
      color: '#38298B',
      fontSize: '15px',
      lineHeight: '16px',
    },
    calendarHeaderControl: {
      color: '#38298B',
    },
    monthPickerControlActive: {
      backgroundColor: '#38298B !important',
    },
    yearPickerControlActive: {
      backgroundColor: '#38298B !important',
    },
    rightSection: {
      paddingRight: '10px',
      width: '22px',
    },
    dropdown: {
      padding: '16px',
    },
    error: ErrorStyles,
  },
};
