/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./user-timesheet-pin-update-action-body').UserTimesheetPinUpdateActionBody} UserTimesheetPinUpdateActionBody
 */

/**
 * @typedef {import('./user-timesheet-pin-update-action-response').UserTimesheetPinUpdateActionResponse} UserTimesheetPinUpdateActionResponse
 */

/**
 * The path of the UserTimesheetPinUpdateAction action.
 */
export const userTimesheetPinUpdateActionPath = new SweetPath('/api/project/user_timesheet_pin_update/{task_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized UserTimesheetPinUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createUserTimesheetPinUpdateAction(axios) {
  /**
   * The userTimesheetPinUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: UserTimesheetPinUpdateActionBody } }
   *
   * @returns {Promise<UserTimesheetPinUpdateActionResponse>}
   */
  function userTimesheetPinUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = userTimesheetPinUpdateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return userTimesheetPinUpdateAction;
}
