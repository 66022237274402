/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-close-action-body').CostEstimateCloseActionBody} CostEstimateCloseActionBody
 */

/**
 * @typedef {import('./cost-estimate-close-action-response').CostEstimateCloseActionResponse} CostEstimateCloseActionResponse
 */

/**
 * The path of the CostEstimateCloseAction action.
 */
export const costEstimateCloseActionPath = new SweetPath('/api/finance/cost_estimate/{cost_estimate_id}/close', {
  path: '{param}',
});

/**
 * Creates a contextualized CostEstimateCloseAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateCloseAction(axios) {
  /**
   * The costEstimateCloseAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CostEstimateCloseActionBody } }
   *
   * @returns {Promise<CostEstimateCloseActionResponse>}
   */
  function costEstimateCloseAction({ headers = {}, parameters = {}, body } = {}) {
    const path = costEstimateCloseActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return costEstimateCloseAction;
}
