/** @typedef {import('components/files/upload/types').IFileState} IFileState */

import { Skeleton } from '@mantine/core';
import { times } from 'lodash';
import UploadProgressIndicator from 'components/files/upload/UploadProgressIndicator';

/**
 * Displays a list of files being uploaded and files that have been uploaded.
 *
 * @param {{
 *   files: IFileState[],
 *   onDelete: (uuid: string) => void,
 *   loading: boolean,
 *   placeholderCount: number,
 *   hideUploadedFiles?: boolean,
 * }}
 */
export default function UploadFilesList({ files, onDelete, loading, placeholderCount, hideUploadedFiles = false }) {
  if (loading) {
    return times(placeholderCount, (i) => <Skeleton key={i} w="100%" h={24} radius={8} />);
  }

  return files
    .filter(({ progress }) => !hideUploadedFiles || progress < 1)
    .map(({ uuid, fileName, fileType, progress, finalized }) => (
      <UploadProgressIndicator
        key={uuid}
        fileName={fileName}
        fileType={fileType}
        progress={progress}
        finalized={finalized}
        onDelete={() => onDelete(uuid)}
      />
    ));
}
