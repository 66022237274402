/** @typedef {import('api/actions/time-log-get-list-action/time-log-get-list-action-response').TimeLogGetListActionResponse['groups'][number]['items'][number]} TimeEntry */

import { Box, Menu } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import panic from 'errors/Panic';
import { _t } from 'lang';
import { noop } from 'lodash';
import { useTimeLog } from 'pages/time-entries/time-log/TimeLogProvider';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import { useCallback, useMemo, useState } from 'react';

/**
 * Actions for a single time entry.
 *
 * @param {{
 *   timeEntry: TimeEntry;
 *   onEdit?: () => void;
 *   onDelete?: () => void;
 * }}
 */
export default function TimeEntryActions({ timeEntry, onEdit = noop, onDelete = noop }) {
  const { getAction, userId } = useApi();
  const { openTimeLogModal } = useTimeLog();
  const { confirm } = useConfirm();

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const menuClasses = useMemo(
    () => (isMenuOpened ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'),
    [isMenuOpened]
  );

  const taskIsClosed = useMemo(() => timeEntry.task.closed_at !== null, [timeEntry.task.closed_at]);
  const taskIsDeleted = useMemo(() => timeEntry.task.deleted_at !== null, [timeEntry.task.deleted_at]);
  const isOwnTimeEntry = useMemo(() => timeEntry.user.user_id === userId, [timeEntry.user.user_id, userId]);

  /**
   * Deletes the time log.
   */
  const handleDeleteTimeLog = useCallback(() => {
    confirm({
      title: _t('Delete Time Log'),
      message: _t('Are you sure you want to delete this time log? This action is irreversible.'),
      onConfirm: () => {
        const action = getAction('TimeLogDeleteAction');

        action({ parameters: { time_log_id: timeEntry.time_log_id } })
          .then(onDelete)
          .catch(panic);
      },
    });
  }, [confirm, getAction, timeEntry.time_log_id]);

  /**
   * Opens the edit time log modal.
   */
  const handleEditTimeLog = useCallback(() => {
    openTimeLogModal({
      projectId: timeEntry.project.project_id,
      taskId: timeEntry.task.task_id,
      start: new Date(timeEntry.start),
      end: new Date(timeEntry.end),
      description: timeEntry.description,
      timeLogId: timeEntry.time_log_id,
      onTimeLogged: onEdit,
    });
  }, [openTimeLogModal, onEdit, timeEntry]);

  if (taskIsClosed || taskIsDeleted || !isOwnTimeEntry) {
    return null;
  }

  return (
    <div className={`flex justify-end ${menuClasses}`}>
      <Menu withinPortal position="bottom-end" offset={-5} onChange={setIsMenuOpened}>
        <Menu.Target>
          <div className="-my-2 flex cursor-pointer items-center py-2">
            <OptionsDotsIcon />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<EditIcon fill="#4D4D4D" />} onClick={handleEditTimeLog}>
            {_t('Edit')}
          </Menu.Item>
          <Menu.Item icon={<DeleteIcon stroke="#BF0D38" />} onClick={handleDeleteTimeLog}>
            <Box style={{ color: '#BF0D38' }}>{_t('Delete')}</Box>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
