import { Route, Routes, useLocation } from 'react-router-dom';
import RequireAuth from 'routes/decorators/RequireAuth';
import HomePage from 'pages/home/HomePage';
import LoginPage from 'pages/login/login/LoginPage';
import ResetPasswordPage from 'pages/login/reset-password/ResetPasswordPage';
import SetPasswordPage from 'pages/login/set-password/SetPasswordPage';
import NotFoundPage from 'pages/system/not-found/NotFoundPage';
import AddProjectPage from 'pages/projects/add-project/AddProjectPage';
import ProjectOverviewPage from 'pages/projects/project-overview/ProjectOverviewPage';
import AddClientPage from 'pages/settings/client/add-client/AddClientPage';
import ClientsPage from 'pages/settings/client/client/ClientsPage';
import FilePrefixSettingsPage from 'pages/settings/global-settings/file-prefix/FilePrefixSettingsPage';
import AddGlobalSettingsPage from 'pages/settings/global-settings/add-global-settings/AddGlobalSettingsPage';
import AddRolePage from 'pages/settings/role/add-role/AddRolePage';
import RolesPage from 'pages/settings/role/roles/RolesPage';
import AddTeamPage from 'pages/settings/team/add-team/AddTeamPage';
import TeamsPage from 'pages/settings/team/teams/TeamsPage';
import AddUserPage from 'pages/settings/user/add-user/AddUserPage';
import UsersPage from 'pages/settings/user/users/UsersPage';
import AddTaskPage from 'pages/tasks/task/add-task/AddTaskPage';
import TaskDetailPage from 'pages/tasks/task-detail/TaskDetailPage';
import TimeEntriesOverviewPage from 'pages/time-entries/overview/TimeEntriesOverviewPage';
import {
  ADD_CLIENT_PAGE_PATH,
  ADD_PROJECT_PAGE_PATH,
  ADD_ROLE_PAGE_PATH,
  ADD_SUBTASK_PAGE_PATH,
  ADD_TASK_PAGE_PATH,
  ADD_USER_PAGE_PATH,
  CLIENTS_PAGE_PATH,
  FILE_PREFIX_SETTINGS_PAGE_PATH,
  GLOBAL_SETTINGS_PAGE_PATH,
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  PROJECT_DASHBOARD_PAGE_PATH,
  RESET_LINK_EXPIRED_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  ROLES_PAGE_PATH,
  SET_PASSWORD_PAGE_PATH,
  TASK_DETAIL_PAGE_PATH,
  TIME_ENTRIES_OVERVIEW_PAGE_PATH,
  USERS_PAGE_PATH,
  ADD_TEAM_PAGE_PATH,
  TEAMS_PAGE_PATH,
  JOB_POSITIONS_PAGE_PATH,
  ADD_JOB_POSITION_PAGE_PATH,
  ADD_DEPARTMENT_PAGE_PATH,
  DEPARTMENTS_PAGE_PATH,
  EDIT_USER_PAGE_PATH,
  EDIT_ROLE_PAGE_PATH,
  EDIT_TEAM_PAGE_PATH,
  EDIT_JOB_POSITION_PAGE_PATH,
  EDIT_DEPARTMENT_PAGE_PATH,
  ADD_SUPPLIER_PAGE_PATH,
  EDIT_SUPPLIER_PAGE_PATH,
  SUPPLIERS_PAGE_PATH,
  EDIT_CLIENT_PAGE_PATH,
  FINANCE_PROJECT_OVERVIEW_PAGE_PATH,
  FINANCE_ADD_COST_ESTIMATE_PAGE_PATH,
  FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH,
  FINANCE_ADD_INVOICE_REQUEST_PAGE_PATH,
  EDIT_TASK_PAGE_PATH,
  ADD_PRICE_LIST_PAGE_PATH,
  EDIT_PRICE_LIST_PAGE_PATH,
  PRICE_LISTS_PAGE_PATH,
  EDIT_PROJECT_PAGE_PATH,
  FINANCE_ADD_EXTERNAL_COST_PAGE_PATH,
  FINANCE_ADD_INVOICE_PAGE_PATH,
  FINANCE_DETAIL_COST_ESTIMATE_PDF_PREVIEW_PAGE_PATH,
  FILE_DOWNLOAD_PAGE_PATH,
  FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH,
  PROJECT_DETAIL_PAGE_PATH,
  FINANCE_EDIT_EXTERNAL_COST_PAGE_PATH,
  FINANCE_EDIT_INVOICE_REQUEST_PAGE_PATH,
  FINANCE_EDIT_INVOICE_PAGE_PATH,
  ADVANCED_SEARCH_PAGE_PATH,
  NOT_ALLOWED_PAGE_PATH,
  SYSTEM_MAINTENANCE_PAGE_PATH,
  SYSTEM_OUTAGE_PAGE_PATH,
  TIME_ENTRIES_EXPORT_PAGE_PATH,
} from 'routes/paths';
import ResetLinkExpiredPage from 'pages/login/reset-link-expired/ResetLinkExpiredPage';
import JobPositionsPage from 'pages/settings/job-position/job-positions/JobPositionsPage';
import AddJobPositionPage from 'pages/settings/job-position/add-job-position/AddJobPositionPage';
import AddDepartmentPage from 'pages/settings/department/add-department/AddDepartmentPage';
import DepartmentsPage from 'pages/settings/department/departments/DepartmentsPage';
import EditUserPage from 'pages/settings/user/edit-user/EditUserPage';
import EditRolePage from 'pages/settings/role/edit-role/EditRolePage';
import EditTeamPage from 'pages/settings/team/edit-team/EditTeamPage';
import EditJobPositionPage from 'pages/settings/job-position/edit-job-position/EditJobPositionPage';
import EditDepartmentPage from 'pages/settings/department/edit-department/EditDepartmentPage';
import { useEffect } from 'react';
import { showNotification } from '@mantine/notifications';
import AddSupplierPage from 'pages/settings/supplier/add-supplier/AddSupplierPage';
import EditSupplierPage from 'pages/settings/supplier/edit-supplier/EditSupplierPage';
import SuppliersPage from 'pages/settings/supplier/suppliers/SuppliersPage';
import EditClientPage from 'pages/settings/client/edit-client/EditClientPage';
import FinanceProjectOverviewPage from 'pages/finance/finance-project-overview/FinanceProjectOverviewPage';
import AddCostEstimatePage from 'pages/finance/cost-estimate/add-cost-estimate/AddCostEstimatePage';
import AddExternalCostsPage from 'pages/finance/external-costs/add-external-costs/AddExternalCostsPage';
import EditTaskPage from 'pages/tasks/task/edit-task/EditTaskPage';
import PriceListsPage from 'pages/settings/price-list/price-lists/PriceListsPage';
import AddPriceListPage from 'pages/settings/price-list/add-price-list/AddPriceListPage';
import EditPriceListPage from 'pages/settings/price-list/edit-price-list/EditPriceListPage';
import EditProjectPage from 'pages/projects/edit-project/EditProjectPage';
import AddInvoiceRequestPage from 'pages/finance/invoice-request/add-invoice-request/AddInvoiceRequestPage';
import AddInvoicePage from 'pages/finance/invoice/add-invoice/AddInvoicePage';
import CostEstimateDetail from 'pages/finance/cost-estimate/detail/CostEstimateDetail';
import CostEstimatePDFPreview from 'pages/finance/cost-estimate/pdf-preview/CostEstimatePDFPreview';
import FileDownloadPage from 'pages/files/file-download/FileDownloadPage';
import ProjectDetailPage from 'pages/projects/project-detail/ProjectDetailPage';
import { useApi } from 'api/ApiContext';
import EditExternalCostsPage from 'pages/finance/external-costs/edit-external-costs/EditExternalCostsPage';
import EditInvoiceRequestPage from 'pages/finance/invoice-request/edit-invoice-request/EditInvoiceRequestPage';
import EditInvoicePage from 'pages/finance/invoice/edit-invoice/EditInvoicePage';
import AdvancedSearchPage from 'pages/search/advanced-search/AdvancedSearchPage';
import { SettingsSidebarProvider } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import RequirePermission from './decorators/RequirePermission';
import NoAccessPage from 'pages/system/no-access/NoAccessPage';
import SystemMaintenancePage from 'pages/system/system-maintenance/SystemMaintenancePage';
import SystemOutagePage from 'pages/system/system-outage/SystemOutagePage';
import TimeEntriesExportPage from 'pages/time-entries/export/TimeEntriesExportPage';

/**
 * App Routes.
 */
export default function AppRoutes() {
  const { workspaceConfig } = useApi();

  // handle redirects upon error or invalid parameters
  const location = useLocation();
  const notifications = location.state && location.state.notifications ? location.state.notifications : [];

  useEffect(() => notifications.forEach(showNotification));

  return (
    <Routes>
      {/* LOGIN */}
      <Route exact path={LOGIN_PAGE_PATH.original} element={<LoginPage />} />
      <Route exact path={RESET_PASSWORD_PAGE_PATH.original} element={<ResetPasswordPage />} />
      <Route exact path={SET_PASSWORD_PAGE_PATH.original} element={<SetPasswordPage />} />
      <Route exact path={RESET_LINK_EXPIRED_PAGE_PATH.original} element={<ResetLinkExpiredPage />} />

      <Route element={<RequireAuth redirect={LOGIN_PAGE_PATH.original} />}>
        <Route path={NOT_ALLOWED_PAGE_PATH.original} element={<NoAccessPage />} />
        <Route path={SYSTEM_MAINTENANCE_PAGE_PATH.original} element={<SystemMaintenancePage />} />
        <Route path={SYSTEM_OUTAGE_PAGE_PATH.original} element={<SystemOutagePage />} />

        {/* HOME PAGE */}
        <Route exact path={HOME_PAGE_PATH.original} element={<HomePage />} />

        <Route element={<RequirePermission permission="SETTINGS_MANAGE_SETTINGS" />}>
          <Route element={<SettingsSidebarProvider />}>
            {/* SETTINGS - USER */}
            <Route exact path={ADD_USER_PAGE_PATH.original} element={<AddUserPage />} />
            <Route exact path={EDIT_USER_PAGE_PATH.original} element={<EditUserPage />} />
            <Route exact path={USERS_PAGE_PATH.original} element={<UsersPage />} />

            {/* SETTINGS - ROLE */}
            {workspaceConfig.features.roles && (
              <Route element={<RequirePermission permission="SETTINGS_MANAGE_ROLE" />}>
                <Route exact path={ADD_ROLE_PAGE_PATH.original} element={<AddRolePage />} />
                <Route exact path={EDIT_ROLE_PAGE_PATH.original} element={<EditRolePage />} />
                <Route exact path={ROLES_PAGE_PATH.original} element={<RolesPage />} />
              </Route>
            )}

            {/* SETTINGS - TEAM */}
            {workspaceConfig.features.teams && (
              <>
                <Route exact path={ADD_TEAM_PAGE_PATH.original} element={<AddTeamPage />} />
                <Route exact path={EDIT_TEAM_PAGE_PATH.original} element={<EditTeamPage />} />
                <Route exact path={TEAMS_PAGE_PATH.original} element={<TeamsPage />} />
              </>
            )}

            {/* SETTINGS - JOB POSITIONS */}
            <Route exact path={ADD_JOB_POSITION_PAGE_PATH.original} element={<AddJobPositionPage />} />
            <Route exact path={EDIT_JOB_POSITION_PAGE_PATH.original} element={<EditJobPositionPage />} />
            <Route exact path={JOB_POSITIONS_PAGE_PATH.original} element={<JobPositionsPage />} />

            {/* SETTINGS - DEPARTMENTS */}
            {workspaceConfig.features.departments && (
              <>
                <Route exact path={ADD_DEPARTMENT_PAGE_PATH.original} element={<AddDepartmentPage />} />
                <Route exact path={EDIT_DEPARTMENT_PAGE_PATH.original} element={<EditDepartmentPage />} />
                <Route exact path={DEPARTMENTS_PAGE_PATH.original} element={<DepartmentsPage />} />
              </>
            )}

            {/* SETTINGS - CLIENT */}
            <Route exact path={ADD_CLIENT_PAGE_PATH.original} element={<AddClientPage />} />
            <Route exact path={EDIT_CLIENT_PAGE_PATH.original} element={<EditClientPage />} />
            <Route exact path={CLIENTS_PAGE_PATH.original} element={<ClientsPage />} />

            {/* SETTINGS - PRICE LISTS */}
            {workspaceConfig.features.priceLists && (
              <>
                <Route exact path={ADD_PRICE_LIST_PAGE_PATH.original} element={<AddPriceListPage />} />
                <Route exact path={EDIT_PRICE_LIST_PAGE_PATH.original} element={<EditPriceListPage />} />
                <Route exact path={PRICE_LISTS_PAGE_PATH.original} element={<PriceListsPage />} />
              </>
            )}

            {/* SETTINGS - SUPPLIERS */}
            {workspaceConfig.features.suppliers && (
              <>
                <Route exact path={ADD_SUPPLIER_PAGE_PATH.original} element={<AddSupplierPage />} />
                <Route exact path={EDIT_SUPPLIER_PAGE_PATH.original} element={<EditSupplierPage />} />
                <Route exact path={SUPPLIERS_PAGE_PATH.original} element={<SuppliersPage />} />
              </>
            )}

            {/* SETTINGS - GLOBAL */}
            {workspaceConfig.features.globalSettings && (
              <>
                <Route exact path={FILE_PREFIX_SETTINGS_PAGE_PATH.original} element={<FilePrefixSettingsPage />} />
                <Route exact path={GLOBAL_SETTINGS_PAGE_PATH.original} element={<AddGlobalSettingsPage />} />
              </>
            )}
          </Route>
        </Route>

        {/* PROJECTS */}
        <Route element={<RequirePermission permission="PROJECTS_VIEW_PROJECT" clientId="*" />}>
          <Route exact path={PROJECT_DASHBOARD_PAGE_PATH.original} element={<ProjectOverviewPage />} />
          <Route exact path={PROJECT_DETAIL_PAGE_PATH.original} element={<ProjectDetailPage />} />

          <Route element={<RequirePermission permission="PROJECTS_MANAGE_PROJECT" clientId="*" />}>
            <Route exact path={ADD_PROJECT_PAGE_PATH.original} element={<AddProjectPage />} />
            <Route exact path={EDIT_PROJECT_PAGE_PATH.original} element={<EditProjectPage />} />
          </Route>
        </Route>

        {/* TASKS */}
        <Route element={<RequirePermission permission="PROJECTS_VIEW_PROJECT" clientId="*" />}>
          <Route exact path={TASK_DETAIL_PAGE_PATH.original} element={<TaskDetailPage />} />

          <Route element={<RequirePermission permission="PROJECTS_MANAGE_PROJECT" clientId="*" />}>
            <Route exact path={ADD_TASK_PAGE_PATH.original} element={<AddTaskPage />} />
            <Route exact path={ADD_SUBTASK_PAGE_PATH.original} element={<AddTaskPage />} />
            <Route exact path={EDIT_TASK_PAGE_PATH.original} element={<EditTaskPage />} />
          </Route>
        </Route>

        {/* TIME ENTRIES */}
        <Route element={<RequirePermission permission="TIME_LOGS_CREATE_TIME_LOG" clientId="*" />}>
          <Route exact path={TIME_ENTRIES_OVERVIEW_PAGE_PATH.original} element={<TimeEntriesOverviewPage />} />
        </Route>

        <Route element={<RequirePermission permission="PROJECTS_VIEW_PROJECT" clientId="*" />}>
          <Route exact path={TIME_ENTRIES_EXPORT_PAGE_PATH.original} element={<TimeEntriesExportPage />} />
        </Route>

        {/* FINANCE - PROJECT OVERVIEW */}
        <Route exact path={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original} element={<FinanceProjectOverviewPage />} />

        {/* FINANCE - PROJECT COST ESTIMATE */}
        {[FINANCE_ADD_COST_ESTIMATE_PAGE_PATH.original, FINANCE_EDIT_COST_ESTIMATE_PAGE_PATH.original].map((path) => (
          <Route exact key={path} path={path} element={<AddCostEstimatePage />} />
        ))}

        <Route exact path={FINANCE_ADD_EXTERNAL_COST_PAGE_PATH.original} element={<AddExternalCostsPage />} />

        <Route exact path={FINANCE_EDIT_EXTERNAL_COST_PAGE_PATH.original} element={<EditExternalCostsPage />} />

        <Route exact path={FINANCE_DETAIL_COST_ESTIMATE_PAGE_PATH.original} element={<CostEstimateDetail />} />

        <Route
          exact
          path={FINANCE_DETAIL_COST_ESTIMATE_PDF_PREVIEW_PAGE_PATH.original}
          element={<CostEstimatePDFPreview />}
        />

        {/* FINANCE - INVOICE REQUEST */}
        <Route exact path={FINANCE_ADD_INVOICE_REQUEST_PAGE_PATH.original} element={<AddInvoiceRequestPage />} />
        <Route exact path={FINANCE_EDIT_INVOICE_REQUEST_PAGE_PATH.original} element={<EditInvoiceRequestPage />} />

        {/* FINANCE - INVOICE */}
        <Route exact path={FINANCE_ADD_INVOICE_PAGE_PATH.original} element={<AddInvoicePage />} />
        <Route exact path={FINANCE_EDIT_INVOICE_PAGE_PATH.original} element={<EditInvoicePage />} />

        <Route exact path={FILE_DOWNLOAD_PAGE_PATH.original} element={<FileDownloadPage />} />

        <Route exact path={ADVANCED_SEARCH_PAGE_PATH.original} element={<AdvancedSearchPage />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
