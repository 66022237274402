/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./time-log-set-is-billable-action-body').TimeLogSetIsBillableActionBody} TimeLogSetIsBillableActionBody
 */

/**
 * @typedef {import('./time-log-set-is-billable-action-response').TimeLogSetIsBillableActionResponse} TimeLogSetIsBillableActionResponse
 */

/**
 * The path of the TimeLogSetIsBillableAction action.
 */
export const timeLogSetIsBillableActionPath = new SweetPath('/api/time_log/set_is_billable/{time_log_id}', {
  path: '{param}',
});

/**
 * Creates a contextualized TimeLogSetIsBillableAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTimeLogSetIsBillableAction(axios) {
  /**
   * The timeLogSetIsBillableAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TimeLogSetIsBillableActionBody } }
   *
   * @returns {Promise<TimeLogSetIsBillableActionResponse>}
   */
  function timeLogSetIsBillableAction({ headers = {}, parameters = {}, body } = {}) {
    const path = timeLogSetIsBillableActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return timeLogSetIsBillableAction;
}
