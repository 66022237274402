import { Collapse, Stack } from '@mantine/core';
import GroupHeadline from 'components/GroupHeadline';
import AttachmentsSimpleGrid from 'components/files/AttachmentsSimpleGrid';
import { _t } from 'lang';
import { useMemo } from 'react';
import UploadFiles from 'components/files/upload/UploadFiles';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';
import useLocalStorageDisclosure from 'hooks/use-local-storage-disclosure';
import { useApi } from 'api/ApiContext';

/**
 * Displays the task files section.
 */
export default function TaskFiles() {
  const { hasPermission } = useApi();
  const {
    taskId,
    taskAttachments,
    attachmentBeingDeleted,
    canDeleteAttachment,
    addAttachment,
    deleteAttachment,
    project,
  } = useTaskDetail();
  const key = useMemo(() => `toolio.task.detail.${taskId}.files.opened`, [taskId]);
  const [opened, { toggle }] = useLocalStorageDisclosure(key, true);
  const filteredAttachments = useMemo(() => taskAttachments.filter(({ isComment }) => !isComment), [taskAttachments]);

  return (
    <Stack spacing={16}>
      <GroupHeadline
        className="top-[120px] z-[90] bg-neutral-50 md:sticky"
        heading={_t('FILES')}
        opened={opened}
        setOpened={toggle}
        number={filteredAttachments.length}
      />
      <Collapse in={opened}>
        <Stack>
          <AttachmentsSimpleGrid
            attachments={filteredAttachments}
            infoPosition="bottom"
            hideCount
            hideToggle
            canDelete={canDeleteAttachment}
            onDelete={deleteAttachment}
            attachmentBeingDeleted={attachmentBeingDeleted}
          />
          {hasPermission('PROJECTS_UPLOAD_FILES', project.client.client_id) && (
            <UploadFiles onFileUploaded={addAttachment} hideUploadedFiles />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}
