/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./comment-delete-action-body').CommentDeleteActionBody} CommentDeleteActionBody
 */

/**
 * @typedef {import('./comment-delete-action-response').CommentDeleteActionResponse} CommentDeleteActionResponse
 */

/**
 * The path of the CommentDeleteAction action.
 */
export const commentDeleteActionPath = new SweetPath('/api/comment/comment_delete/{comment_id}', { path: '{param}' });

/**
 * Creates a contextualized CommentDeleteAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCommentDeleteAction(axios) {
  /**
   * The commentDeleteAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CommentDeleteActionBody } }
   *
   * @returns {Promise<CommentDeleteActionResponse>}
   */
  function commentDeleteAction({ headers = {}, parameters = {}, body } = {}) {
    const path = commentDeleteActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return commentDeleteAction;
}
