import { Group, Skeleton, Stack } from '@mantine/core';

/**
 * Skeleton for a Comment.
 */
export default function CommentLoader() {
  return (
    <Group align="flex-start" spacing={16}>
      <Skeleton h={40} w={40} radius={40} />
      <Stack w="calc(100% - 56px)" spacing={16}>
        <Group position="apart">
          <Skeleton h={18} w={200} />
          <Group spacing={16}>
            <Skeleton h={18} w={130} />
            <Skeleton h={24} w={24} />
          </Group>
        </Group>
        <Skeleton h={200} w="100%" />
        <Skeleton h={24} w={130} />
      </Stack>
    </Group>
  );
}
