import DataSelect from 'components/selects/DataSelect';

/**
 * Available currencies.
 */
const CURRENCIES = [
  { currency_code: 'EUR', currency_name: 'EUR' },
  { currency_code: 'CZK', currency_name: 'CZK' },
  { currency_code: 'HUF', currency_name: 'HUF' },
  { currency_code: 'PLN', currency_name: 'PLN' },
];

/**
 * Currency select.
 *
 * @param {import('react').RefAttributes<HTMLInputElement> & import('@mantine/core').SelectProps}
 */
export default function CurrencySelect({ ...props }) {
  return (
    <DataSelect
      {...props}
      action={() => Promise.resolve(CURRENCIES)}
      labelProperty="currency_name"
      valueProperty="currency_code"
    />
  );
}
