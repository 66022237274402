import { Button } from '@mantine/core';
import AddIcon from 'components/icons/AddIcon';
import EditIcon from 'components/icons/EditIcon';
import ExportPdfIcon from 'components/icons/ExportPdfIcon';
import { _t } from 'lang';
import { useCostEstimateDetail } from 'pages/finance/cost-estimate/detail/CostEstimateDetailContext';
import { Link } from 'react-router-dom';
import { FINANCE_PROJECT_OVERVIEW_PAGE_PATH } from 'routes/paths';

/**
 * The action buttons displayed in the header of all the tabs in the cost
 * estimate detail page adn the footer of the cost-estimate tab.
 *
 * @param {{
 *   tab: string;
 *   className?: string;
 * }}
 */
export default function CostEstimateActions({ tab, className = '' }) {
  const { isApproved, isClosed, links } = useCostEstimateDetail();

  const buttons = [];

  if (tab === 'cost-estimate' && !isApproved) {
    buttons.push(
      <Button key="edit" component={Link} to={links.edit} variant="secondary" leftIcon={<EditIcon stroke="#38298B" />}>
        {_t('Edit')}
      </Button>
    );

    buttons.push(
      <Button
        key="pdf"
        component={Link}
        to={links.pdf}
        target="_blank"
        variant="primary"
        leftIcon={<ExportPdfIcon stroke="#FFFFFF" />}
      >
        {_t('Download preview')}
      </Button>
    );
  } else if (!isClosed) {
    buttons.push(
      <Button
        key="add-external-cost"
        variant="secondary"
        leftIcon={<AddIcon stroke="#38298B" />}
        component={Link}
        to={links.externalCost.add}
      >
        {_t('External costs')}
      </Button>
    );
  }

  return (
    <div className={className}>
      <div className="flex flex-row flex-nowrap items-center justify-end gap-4">
        <Button
          key="back"
          w={76}
          h={36}
          variant="link"
          component={Link}
          to={FINANCE_PROJECT_OVERVIEW_PAGE_PATH.original}
        >
          {_t('Back')}
        </Button>
        {buttons}
      </div>
    </div>
  );
}
