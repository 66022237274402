/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-get-list-action-body').TaskGetListActionBody} TaskGetListActionBody
 */

/**
 * @typedef {import('./task-get-list-action-response').TaskGetListActionResponse} TaskGetListActionResponse
 */

/**
 * The path of the TaskGetListAction action.
 */
export const taskGetListActionPath = new SweetPath('/api/project/project/{project_id}/task', { path: '{param}' });

/**
 * Creates a contextualized TaskGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskGetListAction(axios) {
  /**
   * The taskGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<TaskGetListActionResponse>}
   */
  function taskGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = taskGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return taskGetListAction;
}
