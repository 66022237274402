import { NumberInput, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { nanoid } from 'nanoid';

/**
 * MoneyInput component. Displays the number as a value with specified currency and precision.
 * The value is formatted in the specified locale.
 * The currency is on the right side of the value.
 * The value with the currency is aligned to the right.
 *
 * @param {{
 *   value: number;
 *   onChange: (value: number) => void,
 *   currency: string;
 *   locale: string;
 *   precision: number;
 *   borderColor: string;
 *   textColor: string;
 *   disabled: boolean;
 *   step?: number;
 * } & import('@mantine/core').NumberInputProps}
 */
export default function MoneyInput({
  value,
  onChange,
  currency = 'EUR',
  locale = 'sk-SK',
  precision = 2,
  borderColor = '#B3B3B3',
  textColor = '#4D4D4D',
  disabled = false,
  step,
  ...otherProps
}) {
  const theme = useMantineTheme();

  const [uuid] = useState(nanoid);
  const [hasFocus, { open: onFocus, close: onBlur }] = useDisclosure(false);

  /**
   * Parses the value to number.
   *
   * @param {String} value
   */
  const parser = (value) => {
    return value.replace(/\s/g, '');
  };

  /**
   * Formats the value.
   *
   * @param {String} value
   */
  const formatter = (value) => {
    if (!value) {
      return value;
    }

    if (value.charAt(0) === '-') {
      return `-${formatter(value.slice(1))}`;
    }

    const val = String(value)
      .replace(/\./g, ',') // replace dot with comma
      .replace(/[^\d,]+/g, ''); // remove all non-digits and non-commas

    let [beforeComma, afterComma] = val.split(',', 2);
    const hasComma = afterComma !== undefined;
    beforeComma = new Intl.NumberFormat(locale).format(beforeComma);

    if (!hasComma) {
      return beforeComma;
    }

    afterComma = afterComma.replace(/,/g, ''); // remove all commas from the afterComma part

    return `${beforeComma},${afterComma}`;
  };

  borderColor = hasFocus ? theme.fn.primaryColor() : borderColor;

  const styles = useMemo(
    () => ({
      wrapper: {
        display: 'flex',
        alignItems: 'stretch',
        border: `1px solid ${borderColor}`,
        borderRadius: '8px',
      },
      input: {
        textAlign: 'right',
        border: 'none',
        paddingRight: '0',
        paddingLeft: '8px',
        color: textColor,
        '::placeholder': {
          textAlign: 'left',
        },
        '&:disabled': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: '#E6E6E6!important',
          opacity: '1!important',
          color: '#B3B3B3!important',
        },
      },
      rightSection: {
        position: 'static',
        width: 'auto',
        flexShrink: 0,
        backgroundColor: disabled ? '#E6E6E6' : 'transparent',
        color: disabled ? '#B3B3B3' : textColor,
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    }),
    [borderColor, textColor, disabled]
  );

  return (
    <NumberInput
      {...otherProps}
      value={value}
      disabled={disabled}
      onChange={onChange}
      decimalSeparator=","
      parser={parser}
      formatter={formatter}
      hideControls
      precision={precision}
      step={step || Number(`1e-${precision}`)}
      rightSection={
        <label htmlFor={uuid} className="block h-[36px] pl-[4px] pr-[8px] text-[12px] leading-[36px]">
          {currency}
        </label>
      }
      rightSectionWidth={40}
      id={uuid}
      styles={styles}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
