import { Box, Button, Center, Stack, Text } from '@mantine/core';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import { Link } from 'react-router-dom';
import { HOME_PAGE_PATH } from 'routes/paths';

/**
 * Not Found image.
 */
function NotFoundImage() {
  return (
    <Box pl={9} pr={11}>
      <svg width="202" height="157" viewBox="0 0 202 157" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M153.699 63.8311L154.142 60.6961C154.368 59.0901 153.841 57.4694 152.713 56.3045L129.75 32.601C128.937 31.7616 127.867 31.2171 126.71 31.0537L123.221 30.5612L89.069 25.7402C86.1948 25.3344 83.5358 27.3355 83.1301 30.2097L70.9679 116.366C70.5621 119.24 72.5632 121.899 75.4375 122.305L138.873 131.26C141.747 131.666 144.406 129.665 144.812 126.79L153.699 63.8311ZM153.699 63.8311C149.755 63.2742 139.499 61.8264 134.765 61.1582C130.031 60.49 125.966 55.0882 126.634 50.3546L128.858 34.0418"
          stroke="#908EFB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.358 75.4145L109.632 61.8063M94.1907 59.9736L107.799 77.2473"
          stroke="#908EFB"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M96.679 114.31C97.2962 113.677 103.469 108.739 109.333 108.137C118.242 107.223 120.753 112.149 120.136 118.631"
          stroke="#908EFB"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="135.387" cy="74.0403" r="4.32099" stroke="#908EFB" strokeWidth="2" />
        <path
          d="M115.078 91.9452L126.309 105.035M124.437 69.8139C124.437 85.4761 111.867 97.5553 96.3604 97.5553C80.8542 97.5553 68.2839 85.4761 68.2839 69.8139C68.2839 54.1517 80.8542 41.455 96.3604 41.455C111.867 41.455 124.437 54.1517 124.437 69.8139Z"
          stroke="#38298B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.5926 107.196V41.455H34.0247L1 81.8871V90.2205H60.2593"
          stroke="#38298B"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M184.333 107.196V41.455H174.765L141.741 81.8871V90.2205H201"
          stroke="#38298B"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );
}

/**
 * Content of the Not Found page.
 */
export function NotFoundPageContent() {
  return (
    <Center className="bg-neutral-50 px-8 pb-12 pt-4" style={{ minHeight: 'calc(100vh - 60px)' }}>
      <Stack spacing={16} align="center">
        <NotFoundImage />
        <Text fz={24} lh={32 / 24} c="#4D4D4D">
          {_t('Oops, the page you were looking for doesn’t exist.')}
        </Text>
        <Box>
          <Button component={Link} to={HOME_PAGE_PATH.original} variant="primary">
            {_t('Go to dashboard')}
          </Button>
        </Box>
      </Stack>
    </Center>
  );
}

/**
 * Not Found page.
 *
 * @see Figma {@link https://www.figma.com/design/CQQ1mMQRmo9H7dsJSejbRd/Toolio---Design?node-id=3371-14599&m=dev}
 */
export default function NotFoundPage() {
  return (
    <DashboardLayout>
      <NotFoundPageContent />
    </DashboardLayout>
  );
}
