/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./team-set-active-action-body').TeamSetActiveActionBody} TeamSetActiveActionBody
 */

/**
 * @typedef {import('./team-set-active-action-response').TeamSetActiveActionResponse} TeamSetActiveActionResponse
 */

/**
 * The path of the TeamSetActiveAction action.
 */
export const teamSetActiveActionPath = new SweetPath('/api/user/team/{team_id}/set_active', { path: '{param}' });

/**
 * Creates a contextualized TeamSetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTeamSetActiveAction(axios) {
  /**
   * The teamSetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TeamSetActiveActionBody } }
   *
   * @returns {Promise<TeamSetActiveActionResponse>}
   */
  function teamSetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = teamSetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return teamSetActiveAction;
}
