/** @typedef {import('api/actions/user-get-list-action/user-get-list-action-response').UserGetListActionResponse[number]} User */

import DataTable from 'components/tables/DataTable';
import Avatar from 'components/avatars/Avatar';
import { _t } from 'lang';
import { useApi } from 'api/ApiContext';
import { EDIT_USER_PAGE_PATH } from 'routes/paths';
import { useCallback, useEffect, useMemo, useState } from 'react';
import XIcon from 'components/icons/XIcon';
import { useConfirm } from 'providers/confirm/ConfirmProvider';
import CheckIcon from 'components/icons/CheckIcon';
import { useDeactivateUser } from 'providers/deactivate-user/DeactivateUserProvider';
import panic from 'errors/Panic';
import { nanoid } from 'nanoid';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import { Box, Group } from '@mantine/core';
import { Link } from 'react-router-dom';

/**
 * A row in the Users table.
 *
 * @param {{
 *   data: User;
 *   hover: boolean;
 * }}
 */
function UserTableRow({ data: user, hover }) {
  const { workspaceConfig } = useApi();
  const opacity = useMemo(() => (user.is_active ? 1 : 0.5), [user.is_active]);

  return (
    <>
      <Group noWrap>
        <Box w={60}>
          <Avatar style={{ opacity }} image={user.avatar} label={user.full_name} size={60} />
        </Box>
        <Link to={EDIT_USER_PAGE_PATH.insert({ userId: user.user_id })}>
          <div style={{ opacity }} className="flex items-center transition-all hover:text-main-primary">
            <span className="text-[20px] leading-[20px]">{user.full_name}</span>
            {hover && <small className="ml-[10px] text-[12px] text-grey-500">(ID: {user.user_id})</small>}
          </div>
        </Link>
      </Group>
      <span style={{ opacity }}>{user.role ? user.role.role_name : '-'}</span>
      {workspaceConfig.features.teams && <span style={{ opacity }}>{user.team ? user.team.name : '-'}</span>}
      {user.is_active ? <span></span> : <span className="font-medium text-status-red">{_t('Deleted')}</span>}
    </>
  );
}

/**
 * The table in the Users page.
 *
 * @param {{
 *   showDeleted?: boolean;
 * }}
 */
export default function UserTable({ showDeleted = false }) {
  const { getAction, workspaceConfig, userId } = useApi();
  const { refreshUsers } = useSettingsSidebar();
  const userGetListAction = getAction('UserGetListAction');
  const { confirm } = useConfirm();
  const { deactivate } = useDeactivateUser();
  const [refreshToken, setRefreshToken] = useState(nanoid);

  const columns = useMemo(() => {
    const columns = [
      { title: _t('Name'), width: 4 },
      { title: _t('Role'), width: 2 },
    ];

    if (workspaceConfig.features.teams) {
      columns.push({ title: _t('Team'), width: 3 });
    }

    columns.push({ title: _t('Status'), width: 1 });

    return columns;
  }, [workspaceConfig.features.teams]);

  const confirmDeleteUser = useCallback(
    /** @param {User} user */
    (user) => {
      const deactivateUserAction = getAction('UserDeactivateAction');

      deactivate({
        holdingProjects: user.holding_projects,
        leadingTeams: user.leading_teams,
        directingDepartments: user.directing_departments,
        userId: user.user_id,
        /**
         * @param {{
         *   projectHolders: Record<number, string>;
         *   teamLeaders: Record<number, string>;
         *   departmentDirectors: Record<number, string>;
         * }}
         */
        onDeactivate: ({ projectHolders, teamLeaders, departmentDirectors }) => {
          const mappedProjectHolders = Object.keys(projectHolders).map((projectId) => ({
            project_id: Number(projectId),
            user_id: Number(projectHolders[projectId]),
          }));
          const mappedTeamLeaders = Object.keys(teamLeaders).map((teamId) => ({
            team_id: Number(teamId),
            user_id: Number(teamLeaders[teamId]),
          }));
          const mappedDepartmentDirectors = Object.keys(departmentDirectors).map((departmentId) => ({
            department_id: Number(departmentId),
            user_id: Number(departmentDirectors[departmentId]),
          }));

          return deactivateUserAction({
            parameters: {
              user_id: user.user_id,
            },
            body: {
              project_holders: mappedProjectHolders,
              team_leaders: mappedTeamLeaders,
              department_directors: mappedDepartmentDirectors,
            },
          })
            .then(() => {
              setRefreshToken(nanoid());
              refreshUsers();
            })
            .catch(panic);
        },
      });
    },
    [deactivate]
  );

  const confirmActivateUser = useCallback(
    /** @param {User} user */
    (user) => {
      const userSetActiveAction = getAction('UserSetActiveAction');

      confirm({
        title: _t('Review and confirm user activation'),
        message: _t(
          'Once activated, user can be assigned tasks and projects and can receive notifications or access any files.'
        ),
        primaryButtonText: _t('Activate'),
        secondaryButtonText: _t('Cancel'),
        onConfirm: () =>
          userSetActiveAction({ body: { is_active: true }, parameters: { user_id: user.user_id } })
            .then(() => {
              setRefreshToken(nanoid());
              refreshUsers();
            })
            .catch(panic),
      });
    },
    [confirm]
  );

  const menuItems = useMemo(
    () => [
      {
        key: 'deactivate-user-item',
        icon: <XIcon stroke="#BF0D38" />,
        onClick: confirmDeleteUser,
        label: _t('Deactivate'),
        color: '#BF0D38',
        showIf: (user) => user.is_active && user.user_id !== userId,
      },
      {
        key: 'activate-user-item',
        icon: <CheckIcon stroke="#4D4D4D" />,
        onClick: confirmActivateUser,
        label: _t('Activate'),
        color: '#4D4D4D',
        showIf: (user) => !user.is_active,
      },
    ],
    [confirmDeleteUser, confirmActivateUser, userId]
  );

  const action = useCallback(() => {
    const filter = {
      'is_active.eq': showDeleted ? undefined : 1,
    };

    const sort = [{ by: 'user_id', order: 'desc' }];

    if (showDeleted) {
      sort.unshift({ by: 'is_active', order: 'desc' });
    }

    return userGetListAction({ query: { filter, sort } });
  }, [showDeleted]);

  useEffect(() => {
    setRefreshToken(nanoid());
  }, [showDeleted]);

  return (
    <DataTable
      action={action}
      columns={columns}
      rowKey={(user) => user.user_id}
      editLink={(user) => EDIT_USER_PAGE_PATH.insert({ userId: user.user_id })}
      RowComponent={UserTableRow}
      refreshToken={refreshToken}
      menuItems={menuItems}
    />
  );
}
