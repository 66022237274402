/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-delete-action-body').TaskDeleteActionBody} TaskDeleteActionBody
 */

/**
 * @typedef {import('./task-delete-action-response').TaskDeleteActionResponse} TaskDeleteActionResponse
 */

/**
 * The path of the TaskDeleteAction action.
 */
export const taskDeleteActionPath = new SweetPath('/api/project/project/task/{task_id}/delete', { path: '{param}' });

/**
 * Creates a contextualized TaskDeleteAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskDeleteAction(axios) {
  /**
   * The taskDeleteAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskDeleteActionBody } }
   *
   * @returns {Promise<TaskDeleteActionResponse>}
   */
  function taskDeleteAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskDeleteActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return taskDeleteAction;
}
