/** @typedef {{start: Date, end: Date, type: string}} DateRange */

import { noop } from 'lodash';
import { createDateRangeFromType, DATE_RANGE_TYPES } from '../date-range/date-range';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Group, Overlay, Popover, Stack, Text, TextInput } from '@mantine/core';
import { RangeCalendar } from '@mantine/dates';
import { endOfDay, isToday, nextDay, startOfDay } from 'date-fns';
import DatePicker, { todayStyle } from '../DatePicker';
import { _t } from 'lang';
import CalendarIcon from 'components/icons/CalendarIcon';

/**
 * Date range calendar input.
 *
 * @param {{
 *   initialDateRangeType?: string,
 *   value?: DateRange,
 *   onChange?: (value?: DateRange) => void,
 * }}
 *
 * @see Figma {@link https://www.figma.com/file/jZPNVAdhBmEl8qIOrILWmH/Toolio---component-library?node-id=3038-12577&mode=dev}
 */
export default function DateRangeCalendarInput({
  initialDateRangeType = 'this_week',
  value = createDateRangeFromType(initialDateRangeType),
  onChange = noop,
}) {
  const [dateRangeType, setDateRangeType] = useState(value.type);
  const [selectedDates, setSelectedDates] = useState([value.start, value.end]);
  const [opened, setOpened] = useState(false);
  const open = useCallback(() => setOpened(true), []);

  const displayValue = useMemo(() => {
    if (value.type === 'custom') {
      return `${value.start.toLocaleDateString('sk')} - ${value.end.toLocaleDateString('sk')}`;
    }

    return DATE_RANGE_TYPES.find((val) => val.value === value.type)?.label;
  }, [value]);

  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        setDateRangeType(value.type);
        setSelectedDates([value.start, value.end]);
      }, 200);
    }
  }, [opened, value]);

  const hasError = selectedDates[0] > selectedDates[1];

  return (
    <Popover position="bottom-end" shadow="md" width={508} opened={opened} onChange={setOpened} withinPortal>
      <Popover.Target>
        <Box pos="relative">
          <Overlay opacity={0} zIndex={10} style={{ cursor: 'pointer' }} onClick={open} />
          <CalendarIcon width={16} height={16} className="absolute right-2 top-1/2 z-[5] -translate-y-1/2 transform" />
          <TextInput readOnly value={displayValue} />
        </Box>
      </Popover.Target>
      <Popover.Dropdown pt={32} pl={24} pr={24} pb={24}>
        <Stack spacing={16}>
          <Flex justify="flex-start" noWrap gap={32}>
            <Stack spacing={0} style={{ borderRight: '1px solid #B3B3B3' }} w={175}>
              {DATE_RANGE_TYPES.map((type) => (
                <Box
                  key={type.value}
                  p={8}
                  className={`cursor-pointer ${
                    type.value === dateRangeType ? 'bg-main-primary text-white' : 'text-neutral-700'
                  } hover:bg-main-secondary hover:text-neutral-700`}
                  onClick={() => {
                    setDateRangeType(type.value);
                    const newRange = createDateRangeFromType(type.value);
                    setSelectedDates(
                      newRange
                        ? [newRange.start, newRange.end]
                        : [startOfDay(new Date()), endOfDay(nextDay(new Date(), 1))]
                    );
                  }}
                >
                  <Text fz={15} lh={18 / 15}>
                    {type.label}
                  </Text>
                </Box>
              ))}
            </Stack>
            <Stack spacing={16} w={320} justify="flex-start" h="100%">
              <Group noWrap spacing={32}>
                {/* TODO hide the icons in the DatePicker */}
                <DatePicker
                  styles={{ dropdown: { display: 'none !important' } }}
                  value={selectedDates[0]}
                  error={hasError}
                  onChange={(date) => {
                    setSelectedDates([date, selectedDates[1]]);
                    setDateRangeType('custom');
                  }}
                />
                <DatePicker
                  styles={{ dropdown: { display: 'none !important' } }}
                  value={selectedDates[1]}
                  error={hasError}
                  onChange={(date) => {
                    setSelectedDates([selectedDates[0], date]);
                    setDateRangeType('custom');
                  }}
                />
              </Group>
              <Group position="center">
                <RangeCalendar
                  key={selectedDates}
                  value={selectedDates}
                  dayStyle={(date, { selectedInRange, inRange }) =>
                    isToday(date) && !selectedInRange && !inRange ? todayStyle : null
                  }
                  onChange={([start, end]) => {
                    if (start && end) {
                      const startOfDayDate = startOfDay(start);
                      const endOfDayDate = endOfDay(end);

                      setDateRangeType('custom');
                      setSelectedDates([startOfDayDate, endOfDayDate]);
                    }
                  }}
                />
              </Group>
            </Stack>
          </Flex>
          <Group position="right" spacing={16} py={8}>
            <Button
              variant="link"
              onClick={() => {
                setSelectedDates([value.start, value.end]);
                setDateRangeType(value.type);
                setOpened(false);
              }}
            >
              {_t('Cancel')}
            </Button>
            <Button
              variant="primary"
              disabled={hasError}
              onClick={() => {
                onChange({ start: selectedDates[0], end: selectedDates[1], type: dateRangeType });
                setOpened(false);
              }}
            >
              {_t('Apply')}
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
