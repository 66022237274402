/** @typedef {import('components/inputs/contact-person/types').IContactPerson} IContactPerson */

import { Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import FormRow from 'components/forms/FormRow';
import { createValidator } from 'components/forms/validators/create-validator';
import { emailFormat } from 'components/forms/validators/rules/rule-email-format';
import { required } from 'components/forms/validators/rules/rule-required';
import { _t } from 'lang';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { useEffect, useRef } from 'react';

/**
 * Form used to create of edit a contact person.
 *
 * @param {{
 *   initialValues: IContactPerson;
 *   onSubmit: (data: IContactPerson) => void;
 *   onCancel: () => void;
 *   opened: boolean;
 * }}
 */
export default function ContactPersonForm({ initialValues, onSubmit, onCancel, opened }) {
  const nameInput = useRef(null);

  const form = useForm({
    initialValues,
    validate: {
      name: createValidator([required]),
      email: createValidator([required, emailFormat]),
      phone: createValidator([required]),
    },
  });

  useEffect(() => {
    if (opened) {
      nameInput.current?.focus();
    }
  }, [opened]);

  return (
    <Stack spacing={16} pt={8}>
      <FormRow
        labelWidth={80}
        label={_t('Name')}
        input={<TextInput ref={nameInput} placeholder={_t('John Doe')} {...form.getInputProps('name')} />}
      />
      <FormRow
        labelWidth={80}
        label={_t('Email')}
        input={<TextInput type="email" placeholder={_t('johndoe@example.com')} {...form.getInputProps('email')} />}
      />
      <FormRow
        labelWidth={80}
        label={_t('Phone')}
        input={
          <PhoneNumberInput
            placeholder={_t('Enter phone number')}
            defaultCountry="SK"
            {...form.getInputProps('phone')}
          />
        }
      />
      <Group position="right" pt={8} spacing={16}>
        <Button type="button" variant="link" onClick={onCancel} px={8}>
          {_t('Cancel')}
        </Button>
        <Button type="button" variant="primary" onClick={form.onSubmit(onSubmit)}>
          {_t('Save')}
        </Button>
      </Group>
    </Stack>
  );
}
