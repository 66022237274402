import { Stack } from '@mantine/core';
import Alert from 'components/Alert';
import { _t } from 'lang';

/**
 * The permission table in Role Form.
 *
 * @param {{
 *   title: string;
 *   description: string;
 *   children: React.ReactNode;
 * }}
 */
export default function PermissionTable({ title, description, children }) {
  return (
    <Stack spacing={16}>
      <div className="text-[20px] leading-[36px] text-neutral-700">{title}</div>
      <Stack py={8}>
        <Alert severity="notification" primaryText={description} />
      </Stack>
      <div>
        <div className="grid grid-cols-[500px_160px_160px] gap-2 px-2 text-[12px] leading-[16px] text-neutral-500">
          <span>{_t('Operation')}</span>
          <span>{_t('Global permission')}</span>
          <span>{_t('Only in assigned clients')}</span>
        </div>
        {children}
      </div>
    </Stack>
  );
}
