import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { showNotification } from '@mantine/notifications';
import { DEPARTMENTS_PAGE_PATH } from 'routes/paths';
import { _t } from 'lang';
import DepartmentForm from 'components/forms/department-form/DepartmentForm';
import { useApi } from 'api/ApiContext';
import { useSettingsSidebar } from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebarProvider';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import panic from 'errors/Panic';

/**
 * The Add Department page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=508%3A26731
 */
export default function AddDepartmentPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { refreshDepartments } = useSettingsSidebar();

  const initialValues = {
    departmentName: '',
    leaderId: '',
    color: '',
    avatar: '',
    teams: [],
  };

  /**
   * Makes the call to the API.
   *
   * @param {typeof initialValues}
   */
  const submit = ({ departmentName, leaderId, color, avatar, teams }) => {
    const departmentCreateAction = getAction('DepartmentCreateAction');
    const trimmedColor = color.replace(/^#/, '');

    return departmentCreateAction({
      body: {
        name: departmentName,
        leader_id: leaderId,
        color: trimmedColor,
        avatar,
        teams,
      },
    })
      .then(() => {
        showNotification({
          title: _t('Department created'),
          message: _t("Department '%s' has been created successfully.", departmentName),
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        refreshDepartments();

        navigate(DEPARTMENTS_PAGE_PATH.original);
      })
      .catch((e) => {
        panic(e);

        navigate(DEPARTMENTS_PAGE_PATH.original);
      });
  };

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="min-h-[calc(100vh-60px)] bg-legacy-elements-body p-8 pt-0">
        <PageHeading heading={_t('Add new department')} />

        <DepartmentForm initialValues={initialValues} onSubmit={submit} />
      </div>
    </DashboardLayout>
  );
}
