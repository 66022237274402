/** @typedef {import("components/comments/data/types").IComment} IComment */
/** @typedef {import("components/comments/data/types").CommentReactionType} CommentReactionType */

import { Box } from '@mantine/core';
import { useApi } from 'api/ApiContext';
import Comment from 'components/comments/Comment';
import panic from 'errors/Panic';
import { noop } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { COMMENTS_MAX_TIME_TO_EDIT } from 'utils/constants';
import sleep from 'utils/sleep';
import { useCommentData } from 'components/comments/providers/CommentDataProvider';

/**
 * Takes data from API and renders it as a comment.
 *
 * @param {{
 *   comment: IComment;
 *   beingDeleted?: boolean;
 *   hasFocus?: boolean;
 *   focusToken?: number;
 *   focusScrollBehavior?: ScrollBehavior;
 *   anchorOffset?: number;
 *   onFocus?: (commentId: string) => void;
 *   onReply?: (commentId: string) => void;
 *   onEdit?: (commentId: string) => void;
 *   onDelete?: (commentId: string) => void;
 * }}
 */
export default function CommentAdapter({
  comment,
  hasFocus,
  focusToken,
  focusScrollBehavior = 'smooth',
  anchorOffset = 100,
  beingDeleted,
  onFocus = noop,
  onReply = noop,
  onEdit = noop,
  onDelete = noop,
}) {
  const { getAction, userId } = useApi();
  const { removeAttachment, reactToComment } = useCommentData();
  const anchor = useRef();
  const [attachmentBeingDeleted, setAttachmentBeingDeleted] = useState(null);

  /**
   * Determines whether the comment can be modified.
   *
   * @type {() => boolean}
   */
  const canModify = useCallback(
    () => comment.author.userId === userId && Date.now() - comment.createdAt.getTime() <= COMMENTS_MAX_TIME_TO_EDIT,
    [comment, userId]
  );

  /**
   * Propagates the reaction to the API.
   *
   * @param {CommentReactionType | null} type
   */
  function onReact(type) {
    const commentReactAction = getAction('CommentReactAction');
    commentReactAction({ parameters: { comment_id: comment.commentId }, body: { reaction_type: type } }).catch(panic);

    reactToComment(comment.commentId, type);
  }

  /**
   * Handles attachment deletion.
   *
   * @param {string} fileId
   */
  function onAttachmentDelete(fileId) {
    const commentDeleteAttachmentAction = getAction('CommentDeleteAttachmentAction');

    setAttachmentBeingDeleted(fileId);

    const deleteAttachment = commentDeleteAttachmentAction({
      parameters: { comment_id: comment.commentId },
      body: { attachment_id: fileId },
    });

    // Wait for at least 600ms to make the UI feel more responsive.
    Promise.all([deleteAttachment, sleep(600)])
      .then(() => removeAttachment(comment.commentId, fileId))
      .catch(panic)
      .finally(() => setAttachmentBeingDeleted(null));
  }

  // Scroll to comment if it's focused.
  useEffect(() => {
    if (hasFocus) {
      anchor.current?.scrollIntoView({ behavior: focusScrollBehavior });
    }
  }, [focusToken, hasFocus]);

  return (
    <>
      <Box ref={anchor} h={0} w={0} style={{ transform: `translateY(-${anchorOffset}px)` }} />
      <Comment
        comment={comment}
        beingDeleted={beingDeleted}
        attachmentBeingDeleted={attachmentBeingDeleted}
        hasFocus={hasFocus}
        focusToken={focusToken}
        canModify={canModify}
        onReact={onReact}
        onFocus={onFocus}
        onReply={() => onReply(comment.commentId)}
        onEdit={() => onEdit(comment.commentId)}
        onDelete={() => onDelete(comment.commentId)}
        onAttachmentDelete={onAttachmentDelete}
      />
    </>
  );
}
