/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "AddReaction" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function AddReactionIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.26829 11.1H8.27707M13.5366 11.1H13.5454M8.70732 15.6C8.99345 15.8993 9.33499 16.1371 9.71192 16.2995C10.0888 16.4618 10.4936 16.5455 10.9024 16.5455C11.3113 16.5455 11.716 16.4618 12.093 16.2995C12.4699 16.1371 12.8114 15.8993 13.0976 15.6M21 4.8L17.4878 4.8M19.2439 3V6.6M3 12.9C3 13.9637 3.2044 15.017 3.60154 15.9997C3.99867 16.9825 4.58076 17.8754 5.31457 18.6276C6.04838 19.3797 6.91954 19.9764 7.87831 20.3834C8.83707 20.7905 9.86468 21 10.9024 21C11.9402 21 12.9678 20.7905 13.9266 20.3834C14.8853 19.9764 15.7565 19.3797 16.4903 18.6276C17.2241 17.8754 17.8062 16.9825 18.2033 15.9997C18.6005 15.017 18.8049 13.9637 18.8049 12.9C18.8049 11.8363 18.6005 10.783 18.2033 9.80026C17.8062 8.81753 17.2241 7.92459 16.4903 7.17243C15.7565 6.42028 14.8853 5.82364 13.9266 5.41658C12.9678 5.00951 11.9402 4.8 10.9024 4.8C9.86468 4.8 8.83707 5.00951 7.87831 5.41658C6.91954 5.82364 6.04838 6.42028 5.31457 7.17243C4.58076 7.92459 3.99867 8.81753 3.60154 9.80026C3.2044 10.783 3 11.8363 3 12.9ZM13.9756 11.1C13.9756 11.3485 13.7791 11.55 13.5366 11.55C13.2941 11.55 13.0976 11.3485 13.0976 11.1C13.0976 10.8515 13.2941 10.65 13.5366 10.65C13.7791 10.65 13.9756 10.8515 13.9756 11.1ZM8.70732 11.1C8.70732 11.3485 8.51076 11.55 8.26829 11.55C8.02583 11.55 7.82927 11.3485 7.82927 11.1C7.82927 10.8515 8.02583 10.65 8.26829 10.65C8.51076 10.65 8.70732 10.8515 8.70732 11.1Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
