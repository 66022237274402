/** @typedef {import('api/actions/task-get-action/task-get-action-response').TaskGetActionResponse} Task */
/** @typedef {import('api/actions/project-get-list-action/project-get-list-action-response').ProjectGetListActionResponse[number]} Project  */

import { createContext, useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';
import { OBJECT_DOES_NOT_EXIST_ERROR_CODE, USER_IS_NOT_ALLOWED_ERROR_CODE } from 'utils/constants';

/**
 * The task context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   taskId: number | null,
 *   setTaskId: (taskId: number) => void,
 *   task: Task | null,
 *   project: Project | null,
 *   setProjectId: (projectId: number) => void,
 *   loading: boolean,
 *   exists: boolean | undefined,
 *   refresh: () => Promise<void>,
 *   deleteTask: () => Promise<void>,
 *   closeTask: () => Promise<void>,
 *   reopenTask: () => Promise<void>,
 *   restoreTask: () => Promise<void>,
 *   deleteSubtask: (subtaskId: number) => void,
 *   closeSubtask: (subtaskId: number) => void,
 * }>}
 */
const TaskContext = createContext();

/**
 * Provides the task to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 *   initialTaskId: number | null,
 * }}
 */
export function TaskProvider({ children, initialTaskId }) {
  const { getAction } = useApi();
  const [taskId, setTaskId] = useState(initialTaskId);
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(!!initialTaskId);
  const [projectId, setProjectId] = useState(null);
  const [project, setProject] = useState(null);
  const [exists, setExists] = useState(undefined);

  // Fetch task when the taskId changes.
  useEffect(() => {
    if (taskId) {
      setLoading(true);

      const taskGetAction = getAction('TaskGetAction');
      const projectGetAction = getAction('ProjectGetAction');

      taskGetAction({ parameters: { task_id: taskId } })
        .then((response) => {
          setTask(response);
          setExists(true);

          projectGetAction({ parameters: { project_id: response.project_id } }).then(setProject);
        })
        .catch((e) => {
          if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
            setTask(null);
            setExists(false);
          } else if (e.code === USER_IS_NOT_ALLOWED_ERROR_CODE) {
            setTask(null);
            setExists(true);
          } else {
            panic(e);
            setExists(undefined);
          }
        })
        .finally(() => setLoading(false));
    } else if (projectId) {
      setLoading(true);
      const projectGetAction = getAction('ProjectGetAction');
      projectGetAction({ parameters: { project_id: projectId } })
        .then(setProject)
        .finally(() => setLoading(false));
    } else {
      setTask(null);
      setExists(undefined);
    }
  }, [taskId, projectId]);

  /**
   * Refreshes the task.
   */
  const refresh = useCallback(() => {
    const taskGetAction = getAction('TaskGetAction');
    const projectGetAction = getAction('ProjectGetAction');

    taskGetAction({ parameters: { task_id: taskId } })
      .then((response) => {
        setTask(response);
        projectGetAction({ parameters: { project_id: response.project_id } }).then(setProject);
      })
      .catch((e) => {
        if (e.code === OBJECT_DOES_NOT_EXIST_ERROR_CODE) {
          setTask(null);
          setExists(false);
        } else {
          panic(e);
        }
      });
  }, [taskId, projectId, setTask, setProject, getAction]);

  /** Performs a partial update on the task. */
  const updateTask = useCallback(
    /**
     * @param {Partial<Task>} update
     */
    (update) => setTask((task) => (task ? { ...task, ...update } : task)),
    [setTask]
  );

  /** Performs a partial update on the specified subtask. */
  const updateSubtask = useCallback(
    /**
     * @param {number} subtaskId
     * @param {Partial<Task>} update
     */
    (subtaskId, update) =>
      updateTask({
        subtasks: task.subtasks.map((subtask) => (subtask.task_id === subtaskId ? { ...subtask, ...update } : subtask)),
      }),
    [updateTask, task]
  );

  /** Closes the specified subtask. */
  const closeSubtask = useCallback(
    /**
     * @param {number} subtaskId
     */
    (subtaskId) => updateSubtask(subtaskId, { closed_at: new Date().toISOString() }),
    [updateSubtask]
  );

  /** Deletes the specified subtask. */
  const deleteSubtask = useCallback(
    /**
     * @param {number} subtaskId
     */
    (subtaskId) => updateSubtask(subtaskId, { deleted_at: new Date().toISOString() }),
    [updateSubtask]
  );

  /**
   * Closes the task.
   */
  const closeTask = useCallback(() => updateTask({ closed_at: new Date().toISOString() }), [updateTask]);

  /**
   * Deletes the task.
   */
  const deleteTask = useCallback(() => updateTask({ deleted_at: new Date().toISOString() }), [updateTask]);

  /**
   * Reopens the task.
   */
  const reopenTask = useCallback(() => updateTask({ closed_at: null }), [updateTask]);

  /**
   * Restores the task.
   */
  const restoreTask = useCallback(() => updateTask({ deleted_at: null }), [updateTask]);

  const value = useMemo(
    () => ({
      taskId,
      setTaskId,
      task,
      project,
      setProjectId,
      loading,
      exists,
      refresh,
      deleteTask,
      closeTask,
      reopenTask,
      restoreTask,
      closeSubtask,
      deleteSubtask,
    }),
    [
      taskId,
      setTaskId,
      task,
      project,
      setProjectId,
      loading,
      exists,
      refresh,
      deleteTask,
      closeTask,
      reopenTask,
      restoreTask,
      closeSubtask,
      deleteSubtask,
    ]
  );

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
}

/**
 * Uses the task context.
 */
export function useTask() {
  return useContext(TaskContext);
}
