import FinanceFormLayout from 'layouts/finance-form-layout/FinanceFormLayout';
import { _t } from 'lang';
import EditExternalCostsPageContent from './EditExternalCostsPageContent';

/**
 * Displays edit external cost page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=3128%3A41395&t=ErSfhDBFtNlFfoqq-0
 */
export default function EditExternalCostsPage() {
  return (
    <FinanceFormLayout heading={_t('Edit external costs')}>
      {(costEstimate) => <EditExternalCostsPageContent costEstimate={costEstimate} />}
    </FinanceFormLayout>
  );
}
