/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-decline-action-body').CostEstimateDeclineActionBody} CostEstimateDeclineActionBody
 */

/**
 * @typedef {import('./cost-estimate-decline-action-response').CostEstimateDeclineActionResponse} CostEstimateDeclineActionResponse
 */

/**
 * The path of the CostEstimateDeclineAction action.
 */
export const costEstimateDeclineActionPath = new SweetPath('/api/finance/cost_estimate/{cost_estimate_id}/decline', {
  path: '{param}',
});

/**
 * Creates a contextualized CostEstimateDeclineAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateDeclineAction(axios) {
  /**
   * The costEstimateDeclineAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CostEstimateDeclineActionBody } }
   *
   * @returns {Promise<CostEstimateDeclineActionResponse>}
   */
  function costEstimateDeclineAction({ headers = {}, parameters = {}, body } = {}) {
    const path = costEstimateDeclineActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return costEstimateDeclineAction;
}
