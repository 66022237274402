import { Button } from '@mantine/core';
import useFileDownload from 'api/file-manager/use-file-download';

/**
 * Link used to download a file.
 *
 * @param {{ fileId: string | string[] } & import('@mantine/core').ButtonProps}
 */
export default function FileDownloadLink({ fileId, children, ...props }) {
  const { loading, download } = useFileDownload();

  return (
    <Button
      {...props}
      style={{ userSelect: 'text' }}
      variant="link"
      loaderPosition="right"
      loading={loading}
      onClick={() => download([{ fileId }])}
    >
      {children}
    </Button>
  );
}
