/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./supplier-get-list-action-body').SupplierGetListActionBody} SupplierGetListActionBody
 */

/**
 * @typedef {import('./supplier-get-list-action-response').SupplierGetListActionResponse} SupplierGetListActionResponse
 */

/**
 * The path of the SupplierGetListAction action.
 */
export const supplierGetListActionPath = new SweetPath('/api/project/supplier', { path: '{param}' });

/**
 * Creates a contextualized SupplierGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createSupplierGetListAction(axios) {
  /**
   * The supplierGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<SupplierGetListActionResponse>}
   */
  function supplierGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = supplierGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return supplierGetListAction;
}
