import formatPrice from 'utils/format-price';

/**
 * Displays a price with currency in the Finance / Project overview table.
 */
export default function FinancialValueCell({ value, currency }) {
  return (
    <div className="pr-2 text-right text-[12px] leading-[16px] text-neutral-700">{formatPrice(value, currency)}</div>
  );
}
