/** @typedef {import('api/actions/price-list-get-action/price-list-get-action-response').PriceListGetActionResponse} PriceList */

import { _t } from 'lang';
import { createContext, useEffect, useState, useContext, useMemo } from 'react';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';

/**
 * The priceList context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   priceListId: number | null,
 *   setPriceListId: (priceListId: number) => void,
 *   priceList: PriceList | null,
 *   loading: boolean,
 * }>}
 */
const PriceListContext = createContext();

/**
 * Provides the priceList to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 *   initialPriceListId: number | null,
 * }}
 */
export function PriceListProvider({ children, initialPriceListId = null }) {
  const [priceListId, setPriceListId] = useState(initialPriceListId);
  const [priceList, setPriceList] = useState(null);
  const [loading, setLoading] = useState(!!initialPriceListId);

  const { getAction } = useApi();
  const priceListGetAction = getAction('PriceListGetAction');

  // Fetch priceList when the priceListId changes.
  useEffect(() => {
    if (priceListId) {
      setPriceList(null);
      setLoading(true);

      priceListGetAction({ parameters: { price_list_id: priceListId } })
        .then((priceList) => {
          if (!priceList) {
            throw new Error(_t('Unable to find the price list.')); // This should never happen.
          }

          setPriceList(priceList);
        })
        .catch((error) => panic(error.message))
        .finally(() => setLoading(false));
    } else {
      setPriceList(null);
    }
  }, [priceListId]);

  const value = useMemo(
    () => ({ priceListId, setPriceListId, priceList, loading }),
    [priceListId, setPriceListId, priceList, loading]
  );

  return <PriceListContext.Provider value={value}>{children}</PriceListContext.Provider>;
}

/**
 * Uses the priceList context.
 */
export function usePriceList() {
  return useContext(PriceListContext);
}
