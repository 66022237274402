/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./team-update-action-body').TeamUpdateActionBody} TeamUpdateActionBody
 */

/**
 * @typedef {import('./team-update-action-response').TeamUpdateActionResponse} TeamUpdateActionResponse
 */

/**
 * The path of the TeamUpdateAction action.
 */
export const teamUpdateActionPath = new SweetPath('/api/user/team/{team_id}', { path: '{param}' });

/**
 * Creates a contextualized TeamUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTeamUpdateAction(axios) {
  /**
   * The teamUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TeamUpdateActionBody } }
   *
   * @returns {Promise<TeamUpdateActionResponse>}
   */
  function teamUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = teamUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return teamUpdateAction;
}
