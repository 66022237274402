import { Box, Center, ScrollArea, Stack } from '@mantine/core';
import { SEARCH_MINIMAL_LENGTH } from 'environment';
import SearchResults from './SearchResults';
import SearchInput from './SearchInput';
import { _t } from 'lang';
import { useSearch } from './SearchProvider';
import { useEffect, useRef } from 'react';
import { APP_HEADER_HEIGHT, APP_SIDEBAR_WIDTH } from 'utils/constants';
import Preloader from 'components/Preloader';

/**
 * Allows the user to search the indexed data in elastic search
 */
export default function SearchBar() {
  const { loading, tooFewCharacters, displayedResults, setDisplayedResults } = useSearch();
  const ref = useRef();

  useEffect(() => {
    /**
     * Close the search results if the user clicks outside of the search bar.
     */
    const onClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setDisplayedResults(false);
      }
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <Stack ref={ref} my={-12} py={12} pr={24} ml={-28} pl={28} spacing={0}>
      <SearchInput />
      <Box
        display={displayedResults ? 'block' : 'none'}
        pos="fixed"
        top={APP_HEADER_HEIGHT}
        left={APP_SIDEBAR_WIDTH}
        style={{ zIndex: 10000 }}
        w={552}
      >
        <ScrollArea
          h="calc(100vh - 60px)"
          w={552}
          styles={{
            scrollbar: {
              backgroundColor: '#908EFB',
              width: '6px !important',
              borderRadius: '4px',
            },
            thumb: {
              backgroundColor: '#FFFFFF',
            },
          }}
          style={{
            backgroundColor: '#211C57',
            borderColor: '#211C57',
          }}
        >
          {loading ? (
            <Center h="calc(100vh - 59px)">
              <Preloader size={62} variant="inverse" />
            </Center>
          ) : tooFewCharacters ? (
            <Box p={32} pb={16} fz={15} lh={1.6} c="#fff">
              {_t('Type at least %d characters', SEARCH_MINIMAL_LENGTH)}
            </Box>
          ) : (
            <SearchResults />
          )}
        </ScrollArea>
      </Box>
    </Stack>
  );
}
