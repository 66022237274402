import { Box, Center, Group, Stack, Text } from '@mantine/core';
import ToolioLogo from 'components/ToolioLogo';
import { _t } from 'lang';
import SimpleLayout from 'layouts/simple-layout/SimpleLayout';

/**
 * System Outage image.
 */
function SystemOutageImage() {
  return (
    <svg width="220" height="135" viewBox="0 0 220 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M175.823 34.6877C175.823 30.5526 172.483 27.2023 168.36 27.2023H151.505H68.3004H51.6398C47.5172 27.2023 44.177 30.5526 44.177 34.6877V104.953C44.177 109.089 47.5172 112.439 51.6398 112.439H168.36C172.483 112.439 175.823 109.089 175.823 104.953V91M50.2852 97.0152H170.643M88.6384 129.95H131.464M97.7496 112.439H123.552V129.947H97.7496V112.439Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.536 48C167.075 45.3333 170.925 45.3333 172.464 48L188.919 76.5C190.458 79.1667 188.534 82.5 185.454 82.5H152.546C149.466 82.5 147.542 79.1667 149.081 76.5L165.536 48Z"
        fill="#BF0D38"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M169 56V69.5M169 75.5V76.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M116.283 72.1159C115.694 72.1159 115.217 71.6791 115.217 71.14V51.7875C115.217 51.2484 115.694 50.8116 116.283 50.8116C116.871 50.8116 117.348 51.2484 117.348 51.7875V71.14C117.348 71.6791 116.871 72.1159 116.283 72.1159Z"
        fill="white"
      />
      <path
        d="M120.065 69.2029C119.477 69.2029 119 68.7716 119 68.2393V55.9636C119 55.4313 119.477 55 120.065 55C120.654 55 121.13 55.4313 121.13 55.9636V68.2393C121.13 68.7716 120.654 69.2029 120.065 69.2029Z"
        fill="white"
      />
      <path
        d="M132.788 54.913C132.225 54.913 131.694 54.7443 131.237 54.4115C130.451 53.8386 130 52.8379 130 51.6666V45.1066C130 43.0059 131.419 40.85 133.231 40.1976C134.187 39.8535 135.123 39.9613 135.864 40.5011C136.65 41.074 137.101 42.0742 137.101 43.246V49.8065C137.101 51.9072 135.682 54.0631 133.87 54.7155C133.503 54.8478 133.139 54.913 132.788 54.913ZM134.308 41.9873C134.15 41.9873 133.996 42.026 133.87 42.0714C132.838 42.4429 131.933 43.861 131.933 45.1066V51.6671C131.933 52.1866 132.087 52.5968 132.356 52.7925C132.633 52.9944 132.984 52.9306 133.231 52.8422C134.263 52.4706 135.169 51.0526 135.169 49.807V43.246C135.169 42.7266 135.014 42.3163 134.746 42.1206C134.61 42.0222 134.458 41.9868 134.308 41.9868V41.9873Z"
        fill="white"
      />
      <path
        d="M89.689 79.5362C89.57 79.5362 89.4486 79.5145 89.3311 79.4691C88.0501 78.9722 86.9627 78.1093 86.1865 76.9745C85.4103 75.8398 85 74.5126 85 73.1367V49.9929C85 49.4444 85.4438 49 85.9915 49C86.5392 49 86.9831 49.4444 86.9831 49.9929V73.1367C86.9831 74.1102 87.2734 75.0492 87.8225 75.8521C88.3717 76.6549 89.1413 77.2653 90.0478 77.6171C90.5582 77.8152 90.8118 78.3901 90.6139 78.9017C90.4619 79.2951 90.0865 79.5362 89.689 79.5362Z"
        fill="white"
      />
      <path
        d="M94.7879 73.913C94.2246 73.913 93.6945 73.7443 93.2375 73.4115C92.451 72.8386 92 71.8385 92 70.6667V64.1064C92 62.0058 93.4193 59.8499 95.2311 59.1976C96.1874 58.8535 97.1226 58.9613 97.864 59.5011C98.6504 60.074 99.1014 61.0746 99.1014 62.2459V68.8062C99.1014 70.9068 97.6822 73.0627 95.8703 73.715C95.5031 73.8473 95.1391 73.9126 94.7879 73.9126V73.913ZM96.3075 60.9877C96.1501 60.9877 95.9964 61.0264 95.8703 61.0718C94.8381 61.4433 93.9329 62.8614 93.9329 64.1069V70.6672C93.9329 71.1866 94.087 71.5969 94.3558 71.7926C94.6328 71.9944 94.9844 71.9306 95.2311 71.8422C96.2634 71.4707 97.1686 70.0522 97.1686 68.8072V62.2469C97.1686 61.7274 97.0144 61.3171 96.7457 61.1214C96.6104 61.0231 96.4576 60.9877 96.3075 60.9877Z"
        fill="white"
      />
      <path
        d="M104.788 83.913C104.225 83.913 103.694 83.7443 103.237 83.4115C102.451 82.8386 102 81.8385 102 80.6667V74.1064C102 72.0058 103.419 69.8499 105.231 69.1976C106.187 68.8535 107.123 68.9613 107.864 69.5011C108.65 70.074 109.101 71.0746 109.101 72.2459V78.8062C109.101 80.9068 107.682 83.0627 105.87 83.715C105.503 83.8473 105.139 83.9126 104.788 83.9126V83.913ZM106.308 70.9877C106.15 70.9877 105.996 71.0264 105.87 71.0718C104.838 71.4433 103.933 72.8618 103.933 74.1069V80.6672C103.933 81.1866 104.087 81.5969 104.356 81.7926C104.633 81.9944 104.984 81.9306 105.231 81.8422C106.263 81.4707 107.169 80.0527 107.169 78.8072V72.2469C107.169 71.7274 107.014 71.3171 106.746 71.1214C106.61 71.0231 106.458 70.9877 106.308 70.9877Z"
        fill="white"
      />
      <path
        d="M77.0023 67.6812C76.4486 67.6812 76 67.2441 76 66.7046V66.6056C76 66.5772 76.0014 66.5493 76.0038 66.5214C76.0625 65.0576 76.8209 63.696 78.058 62.8516C79.3452 61.9727 80.9952 61.7593 82.4728 62.2806L92.3744 65.6844C92.8965 65.8639 93.17 66.4215 92.9863 66.9302C92.802 67.4389 92.2298 67.7058 91.7076 67.5263L81.8023 64.1211C81.7994 64.1201 81.7965 64.1192 81.7937 64.1183C80.9159 63.8076 79.9738 63.929 79.2087 64.4512C78.4437 64.9734 78.005 65.7946 78.005 66.7046C78.005 67.2441 77.5564 67.6812 77.0028 67.6812H77.0023Z"
        fill="white"
      />
      <path
        d="M98.8865 80.8406C98.5206 80.8406 98.1779 80.616 98.05 80.2576C97.8884 79.8039 98.1315 79.3076 98.5936 79.1489L101.792 78.0493C102.254 77.8902 102.759 78.1292 102.921 78.5829C103.083 79.0366 102.84 79.533 102.377 79.6916L99.1793 80.7913C99.0823 80.8244 98.9835 80.8406 98.8865 80.8406Z"
        fill="white"
      />
    </svg>
  );
}

/**
 * Content of the System outage page.
 */
function SystemOutageContent() {
  return (
    <Center className="bg-system-background-dark" h="100%">
      <Stack spacing={16} align="center">
        <SystemOutageImage />
        <Text fz={24} lh={32 / 24} c="#FFF">
          {_t('Toolio is is Experiencing Technical Difficulties')}
        </Text>
        <Stack spacing={32} maw={594} align="center">
          <Box>
            <Text fz={15} lh={18 / 15} c="#FFF" align="center">
              {_t("We're aware of technical difficulties impacting [Instance URL].")}
            </Text>
            <Text fz={15} lh={18 / 15} c="#FFF" align="center">
              {_t(
                "Our team is working on a fix, and we expect to have the service restored shortly. We'll keep you updated on the situation."
              )}
            </Text>
          </Box>
          <Group>
            <ToolioLogo width="112px" height="83px" variant="light" />
          </Group>
        </Stack>
      </Stack>
    </Center>
  );
}

/**
 * System outage page.
 *
 * @see Figma {@link https://www.figma.com/design/CQQ1mMQRmo9H7dsJSejbRd/Toolio---Design?node-id=3371-14656&m=dev}
 */
export default function SystemOutagePage() {
  return <SimpleLayout content={<SystemOutageContent />} />;
}
