/** @typedef {import('../row/CostEstimateRowData').CostEstimateRowData} CostEstimateRowData */
/** @typedef {import('./CostEstimateSectionData').CostEstimateSectionData} CostEstimateSectionData */

import { useEffect, useState } from 'react';
import { _t } from 'lang';
import { Button, Collapse, Menu } from '@mantine/core';
import { nanoid } from 'nanoid';
import CostEstimateRow from 'pages/finance/cost-estimate/add-cost-estimate/row/CostEstimateRow';
import CollapseArrow from 'components/CollapseArrow';
import { createCostEstimateSectionData } from 'pages/finance/cost-estimate/add-cost-estimate/section/CostEstimateSectionData';
import DiscountForm from 'pages/finance/cost-estimate/add-cost-estimate/discount/DiscountForm';
import DeleteIcon from 'components/icons/DeleteIcon';
import OptionsDotsIcon from 'components/icons/OptionsDotsIcon';
import DuplicateIcon from 'components/icons/DuplicateIcon';
import SectionName from 'pages/finance/cost-estimate/add-cost-estimate/section/SectionName';
import PriceDisplay from 'components/PriceDisplay';
import AgencyFeeForm from 'pages/finance/cost-estimate/add-cost-estimate/agency-fee/AgencyFeeForm';
import { useClient } from 'providers/client/ClientProvider';
import useImmutable from 'hooks/use-immutable';
import PlusSmallIcon from 'components/icons/PlusSmallIcon';
import { useDisclosure } from '@mantine/hooks';
import { useData } from '../providers/DataProvider';

/**
 * CostEstimateSection component
 *
 * @param {{
 *   id: string,
 *   isInHouse: boolean,
 *   onChange: (data: CostEstimateSectionData) => void,
 *   onDelete: () => void;
 *   onDuplicate: (value: CostEstimateSectionData) => void;
 *   initialData: Partial<CostEstimateSectionData>,
 *   autoFocus?: boolean,
 * }}
 */
export default function CostEstimateSection({
  id,
  isInHouse,
  onChange,
  onDelete,
  onDuplicate,
  initialData,
  autoFocus = false,
}) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [opened, { toggle: toggleOpened }] = useDisclosure(true);
  const [hasPriceModifier, setHasPriceModifier] = useState(!!initialData?.discount || !!initialData?.agencyFee);
  const [rows, setRows] = useState(initialData.rows.map((data) => createRow(data)));
  const [data, updateData] = useImmutable(initialData, { createFn: createCostEstimateSectionData });
  const { client } = useClient();
  const { data: costEstimateData } = useData();

  /**
   * Handles row change.
   *
   * @param {string} id
   * @param {CostEstimateRowData} data
   */
  function onRowChange(id, data) {
    setRows((rows) => rows.map((row) => (row.id === id ? { ...row, data } : row)));
  }

  /**
   * Deletes a row.
   *
   * @param {string} id
   */
  function deleteRow(id) {
    setRows((rows) => rows.filter((row) => row.id !== id));
  }

  /**
   * Swaps two rows.
   *
   * @param {string} id1
   * @param {string} id2
   */
  function swapRows(id1, id2) {
    setRows((rows) => {
      // Copy the rows to avoid mutating the state.
      const newRows = [...rows];

      const i = newRows.findIndex((row) => row.id === id1);
      const j = newRows.findIndex((row) => row.id === id2);

      // Swap the rows.
      [newRows[i], newRows[j]] = [newRows[j], newRows[i]];

      return newRows;
    });
  }

  /**
   * Creates a row and returns it.
   *
   * @param {CostEstimateRowData} [data]
   */
  function createRow(data) {
    const rowId = nanoid();

    const component = (
      <CostEstimateRow
        key={rowId}
        id={rowId}
        sectionId={id}
        initialData={data}
        isInHouse={isInHouse}
        onDelete={() => deleteRow(rowId)}
        onChange={(data) => onRowChange(rowId, data)}
        onDuplicate={(data) => addRow(data)} // The declaration is hoisted, so this is fine.
        swapRows={swapRows}
      />
    );

    return { id: rowId, component, data };
  }

  /**
   * Creates a row and adds it to the rows state.
   *
   * @param {CostEstimateRowData} [data]
   */
  function addRow(data) {
    setRows((rows) => [...rows, createRow(data)]);
  }

  /**
   * Removes the price modifier.
   */
  function removePriceModifier() {
    setHasPriceModifier(false);
    updateData({ discount: undefined, agencyFee: undefined });
  }

  // Compute data.
  useEffect(() => {
    updateData({
      rows: rows.map(({ data }) => data).filter(Boolean),
    });
  }, [rows]);

  // Propagate data.
  useEffect(() => {
    onChange(data);
  }, [data]);

  const hideIfOpened = opened ? 'invisible' : '';

  return (
    <div className="flex flex-col rounded-2xl bg-white">
      {/* Header */}
      <div className="group grid w-full grid-cols-[24px_24px_1fr_168px_128px_64px] items-center gap-x-2 px-2 py-4">
        <div></div> {/* Drag handle */}
        <div className="flex cursor-pointer items-center justify-center" onClick={toggleOpened}>
          <CollapseArrow opened={opened} />
        </div>
        <SectionName data={data} updateData={updateData} autoFocus={autoFocus} />
        <PriceDisplay
          value={data.total}
          currency={costEstimateData.currency}
          size="lg"
          className={`text-right ${hideIfOpened}`}
        />
        <PriceDisplay
          value={data.externalCosts}
          currency={costEstimateData.currency}
          className={`text-right text-complementary-african-violet ${hideIfOpened}`}
        />
        <div className="flex items-stretch justify-end pr-2">
          <div className={isMenuOpened ? 'flex' : 'hidden group-hover:flex'}>
            <Menu width={200} offset={15} onChange={setIsMenuOpened}>
              <Menu.Target>
                <div className="flex cursor-pointer items-center justify-center">
                  <OptionsDotsIcon />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={onDelete} icon={<DeleteIcon />}>
                  <span>{_t('Delete section')}</span>
                </Menu.Item>
                <Menu.Item onClick={() => onDuplicate(data)} icon={<DuplicateIcon />}>
                  <span>{_t('Duplicate section')}</span>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </div>

      <Collapse in={opened}>
        {/* Table */}
        {isInHouse ? (
          <div className="grid grid-cols-[264px_1fr_80px_80px_110px_140px_128px_24px_24px] gap-x-[8px] pl-[40px] pr-2 text-base text-grey-500">
            <span className="px-2 text-[12px] leading-[18px]">{_t('Description')}</span>
            <span className="px-2 text-[12px] leading-[18px]">{_t('Note')}</span>
            <span className="px-2 text-[12px] leading-[18px]">{_t('Unit')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('Number')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('Unit price')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('Price')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('External costs')}</span>
          </div>
        ) : (
          <div className="grid grid-cols-[1fr_140px_128px_24px_24px] gap-x-[8px] pl-[40px] pr-2 text-base text-grey-500">
            <span className="px-2 text-[12px] leading-[18px]">{_t('Description')}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t("Supplier's price")}</span>
            <span className="px-2 text-right text-[12px] leading-[18px]">{_t('External price')}</span>
          </div>
        )}
        {rows.map(({ component }) => component)}

        {/* Buttons */}
        <div className="flex gap-x-[32px] py-[16px] pl-[40px]">
          <Button
            className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
            onClick={addRow}
            variant="link"
          >
            <PlusSmallIcon width={24} height={24} stroke="#38298B" />
            <span className="ml-[4px] text-[15px] leading-[20px]">{_t('Add row')}</span>
          </Button>
          {!hasPriceModifier && (
            <Button
              className="flex h-[24px] items-center border-0 p-0 text-primary-dark-blue"
              onClick={() => setHasPriceModifier(true)}
              variant="link"
            >
              <PlusSmallIcon width={24} height={24} stroke="#38298B" />
              <span className="ml-[4px] text-[15px] leading-[20px]">
                {isInHouse ? _t('Add discount') : _t('Add agency fee')}
              </span>
            </Button>
          )}
        </div>

        {/* Total */}
        <div className="group flex flex-col items-end justify-center gap-y-4 border-t-[1px] border-neutral-300 px-2 py-6">
          {hasPriceModifier && (
            <>
              <div className="grid h-[24px] grid-cols-[auto_168px_128px_64px] items-center justify-end gap-x-[8px] text-right">
                <span className="text-[15px] leading-[18px]">{_t('Section subtotal:')}</span>
                <PriceDisplay currency={costEstimateData.currency} value={data.subtotal} />
              </div>

              {isInHouse ? (
                <DiscountForm
                  initialData={initialData?.discount}
                  onChange={(discount) => updateData({ discount })}
                  onDelete={removePriceModifier}
                  discountAmount={data.discountAmount}
                  currency={costEstimateData.currency}
                />
              ) : (
                <AgencyFeeForm
                  initialData={initialData?.agencyFee || { amount: client.increasing_price }}
                  onChange={(agencyFee) => updateData({ agencyFee })}
                  onDelete={removePriceModifier}
                  feeAmount={-data.discountAmount}
                  currency={costEstimateData.currency}
                />
              )}
            </>
          )}
          <div className="grid h-[24px] grid-cols-[auto_168px_128px_64px] items-center justify-end gap-x-[8px] text-right">
            <span className="text-[15px] leading-[18px]">{_t('Section total:')}</span>
            <PriceDisplay currency={costEstimateData.currency} size="lg" value={data.total} />
            <PriceDisplay
              currency={costEstimateData.currency}
              className="text-complementary-african-violet"
              value={data.externalCosts}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}
