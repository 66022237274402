/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./team-get-list-action-body').TeamGetListActionBody} TeamGetListActionBody
 */

/**
 * @typedef {import('./team-get-list-action-response').TeamGetListActionResponse} TeamGetListActionResponse
 */

/**
 * The path of the TeamGetListAction action.
 */
export const teamGetListActionPath = new SweetPath('/api/user/team/', { path: '{param}' });

/**
 * Creates a contextualized TeamGetListAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTeamGetListAction(axios) {
  /**
   * The teamGetListAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<TeamGetListActionResponse>}
   */
  function teamGetListAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = teamGetListActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return teamGetListAction;
}
