/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./supplier-set-active-action-body').SupplierSetActiveActionBody} SupplierSetActiveActionBody
 */

/**
 * @typedef {import('./supplier-set-active-action-response').SupplierSetActiveActionResponse} SupplierSetActiveActionResponse
 */

/**
 * The path of the SupplierSetActiveAction action.
 */
export const supplierSetActiveActionPath = new SweetPath('/api/project/supplier/{supplier_id}/set_active', {
  path: '{param}',
});

/**
 * Creates a contextualized SupplierSetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createSupplierSetActiveAction(axios) {
  /**
   * The supplierSetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: SupplierSetActiveActionBody } }
   *
   * @returns {Promise<SupplierSetActiveActionResponse>}
   */
  function supplierSetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = supplierSetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return supplierSetActiveAction;
}
