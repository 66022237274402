/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-price-get-action-body').CostEstimatePriceGetActionBody} CostEstimatePriceGetActionBody
 */

/**
 * @typedef {import('./cost-estimate-price-get-action-response').CostEstimatePriceGetActionResponse} CostEstimatePriceGetActionResponse
 */

/**
 * The path of the CostEstimatePriceGetAction action.
 */
export const costEstimatePriceGetActionPath = new SweetPath(
  '/api/finance/cost_estimate_price/{cost_estimate_price_id}',
  { path: '{param}' }
);

/**
 * Creates a contextualized CostEstimatePriceGetAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimatePriceGetAction(axios) {
  /**
   * The costEstimatePriceGetAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,query: {limit?: number; offset?: number; filter?: Object<string,string|number|boolean>;, sort?: {by: string; order: string;}} } }
   *
   * @returns {Promise<CostEstimatePriceGetActionResponse>}
   */
  function costEstimatePriceGetAction({ headers = {}, parameters = {}, query = {} } = {}) {
    const path = costEstimatePriceGetActionPath.insert(parameters);

    return axios.get(path, { params: query, headers });
  }

  return costEstimatePriceGetAction;
}
