import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import { _t } from 'lang';
import { ADD_USER_PAGE_PATH } from 'routes/paths';
import DashboardPageHeading from 'layouts/dashboard-layout/headers/DashboardPageHeading';
import UserTable from 'components/tables/UserTable';
import { useState } from 'react';
import { Switch } from '@mantine/core';

/**
 * Users page.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=233%3A5989
 */
export default function UsersPage() {
  const navigate = useNavigate();

  const [showDeleted, setShowDeleted] = useState(false);

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="relative relative h-full p-[20px] pt-0">
        <DashboardPageHeading
          heading={_t('Users')}
          buttonAction={() => navigate(ADD_USER_PAGE_PATH.original)}
          switchComponent={
            <Switch
              label={_t('Show deleted users')}
              styles={{ label: { marginTop: '1px' } }}
              checked={showDeleted}
              onChange={() => setShowDeleted((prev) => !prev)}
            />
          }
        />
        <UserTable showDeleted={showDeleted} />
      </div>
    </DashboardLayout>
  );
}
