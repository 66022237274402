import { ColorInput, Stack, Tabs, TextInput } from '@mantine/core';
import { _t } from 'lang';
import { useForm } from '@mantine/form';
import { createValidator } from 'components/forms/validators/create-validator';
import { required } from 'components/forms/validators/rules/rule-required';
import UserSelect from 'components/selects/UserSelect';
import DepartmentSelect from 'components/selects/DepartmentSelect';
import FormRow from 'components/forms/FormRow';
import { useSearchParams } from 'react-router-dom';
import useLoadingAction from 'hooks/use-loading-action';
import FormFooter from '../FormFooter';

/**
 * @typedef {{
 *   avatar: string;
 *   teamName: string;
 *   color: string;
 *   departmentId: string;
 *   teamLeaderId?: string;
 *   members: string[];
 * }} TeamFormData
 */

/**
 * Add / Edit team form.
 *
 * @param {{
 *   initialValues: TeamFormData,
 *   onSubmit: (values: TeamFormData) => void | Promise<void>,
 * }}
 */
export default function TeamForm({ initialValues, onSubmit }) {
  const [loading, submit] = useLoadingAction(onSubmit);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'team';

  const form = useForm({
    initialValues,

    validate: {
      teamName: createValidator([required]),
      departmentId: createValidator([required]),
      color: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Tabs defaultValue={activeTab}>
        <Tabs.List mx={-32} px={32}>
          <Tabs.Tab value="team">{_t('Team')}</Tabs.Tab>
          <Tabs.Tab value="members">{_t('Members')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="team">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Team name')}
              input={
                <TextInput type="text" placeholder={_t('Team name')} autoFocus {...form.getInputProps('teamName')} />
              }
            />
            <FormRow
              label={_t('Department')}
              input={<DepartmentSelect placeholder={_t('Departments')} {...form.getInputProps('departmentId')} />}
            />
            <FormRow
              label={_t('Team leader')}
              input={<UserSelect placeholder={_t('Team leader')} {...form.getInputProps('teamLeaderId')} clearable />}
            />
            <FormRow
              label={_t('Color')}
              input={<ColorInput placeholder={_t('Pick a color')} {...form.getInputProps('color')} />}
            />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="members">
          <Stack spacing={16} pt={16}>
            <FormRow
              label={_t('Members (readonly)')}
              tooltipText={_t('The team of a member can be changed in the user profile.')}
              input={
                <UserSelect
                  readOnly
                  disabled
                  multiple={true}
                  placeholder={_t('No members')}
                  {...form.getInputProps('members')}
                />
              }
            />
          </Stack>
        </Tabs.Panel>
      </Tabs>

      <FormFooter loading={loading} skipCancelConfirmation={() => !form.isDirty()} />
    </form>
  );
}
