/** @typedef {import('api/actions/cost-estimate-get-action/cost-estimate-get-action-response').CostEstimateGetActionResponse['sections']} CostEstimateSections */

import { Collapse } from '@mantine/core';
import { _t } from 'lang';
import GroupHeadline from 'components/GroupHeadline';
import { useState } from 'react';
import InHouseSectionItems from 'pages/finance/cost-estimate/detail/cost-estimate-tab/section/InHouseSectionItems';
import OutOfHouseSectionItems from 'pages/finance/cost-estimate/detail/cost-estimate-tab/section/OutOfHouseSectionItems';

/**
 * The part of the cost estimate that contains the sections.
 *
 * @param {{
 *   currency: string,
 *   sections: CostEstimateSections,
 *   isInHouse: boolean
 * }}
 */
export default function CostEstimatePart({ currency, sections, isInHouse }) {
  const [opened, setOpened] = useState(true);

  const heading = isInHouse ? _t('In house items') : _t('Out of house items');

  return (
    <div className="pt-4">
      <GroupHeadline heading={heading} opened={opened} setOpened={setOpened} />
      <Collapse in={opened}>
        <div className="flex flex-col gap-4 pt-4">
          {sections.map((section) =>
            isInHouse ? (
              <InHouseSectionItems key={section.cost_estimate_section_id} section={section} currency={currency} />
            ) : (
              <OutOfHouseSectionItems key={section.cost_estimate_section_id} section={section} currency={currency} />
            )
          )}
        </div>
      </Collapse>
    </div>
  );
}
