/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['InputWrapper']} InputWrapperTheme */

/** @type {InputWrapperTheme} */
export const InputWrapper = {
  styles: {
    label: {
      fontWeight: 400,
    },
  },
};
