/** @typedef {'96x96' | '800-max'} ThumbnailSize */

/**
 * @typedef {{
 *   basename: string;
 *   mimeType: string;
 *   size: number;
 *   created: {
 *     timestamp: string;
 *     author: {
 *       userId: string;
 *     };
 *   };
 * }} FileMetadata
 */

import { createContext, useContext } from 'react';

/**
 * Creates empty context.
 *
 * @type {import('react').Context<{
 *   uploadFile: ({fileName, contents, isPublic}: {fileName: string, contents: string, isPublic: boolean}) => Promise<string>,
 *   readFile: ({fileId} :{fileId: string}) => Promise<Blob>,
 *   getFileMetadata: ({fileId} :{fileId: string}) => Promise<FileMetadata>,
 *   zipFiles: ({fileIds} :{ fileIds: string[] }) => Promise<any>,
 *   getFileThumbnail: ({ fileId, size } :{ fileId: string; size?: ThumbnailSize }) => Promise<Blob>,
 *   initLargeFile: ({ fileName, isPublic }: { fileName: string, isPublic?: boolean }) => Promise<string>,
 *   uploadLargeFile: ({ fileId, contents }: { fileId: string, contents: string }) => Promise<void>,
 *   finalizeLargeFile: ({ fileId }: { fileId: string }) => Promise<void>,
 *   createAccessToken: ({ fileId }: { fileId: string }) => Promise<string>,
 * }>}
 */
export const FileManagerContext = createContext(undefined);

/**
 * Requires the API context.
 */
export const useFileManager = () => useContext(FileManagerContext);
