import useFileDownload from 'api/file-manager/use-file-download';
import { _t } from 'lang';
import { NotFoundPageContent } from 'pages/system/not-found/NotFoundPage';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

/**
 * Opens a context window prompting user to download the file.
 */
export default function FileDownloadPage() {
  const { fileId } = useParams();
  const { download, loading } = useFileDownload();

  // Download the file on mount
  useEffect(() => {
    // TODO handle the state when provided fileId does not represent valid file
    if (fileId) {
      download([{ fileId }]);
    }
  }, []);

  if (!fileId) {
    return <NotFoundPageContent />;
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <span>{_t('Downloading file...')}</span>
      <span>{loading ? _t('Loading...') : _t('Done!')}</span>
    </div>
  );
}
