/**
 * InvisibleTextInput component.
 *
 * @param {{
 *   required: boolean,
 *   onClick: () => void,
 *   placeholder?: string,
 *   className?: string,
 *   value?: string,
 *   onChange?: (value: string) => void,
 *   autoFocus?: boolean,
 * }}
 */
export default function InvisibleTextInput({
  required,
  onClick,
  placeholder = '',
  className = '',
  value,
  onChange,
  autoFocus = false,
}) {
  return (
    <input
      style={{ fontFamily: 'inherit' }}
      type="text"
      className={`bg-transparent border-none outline-none ${className}`}
      placeholder={placeholder}
      required={required}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.target.blur();
        }
      }}
      onClick={onClick}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      autoFocus={autoFocus}
    />
  );
}
