import { round } from 'lodash';

/** @typedef {'relative' | 'absolute'} DiscountType */

/**
 * Creates a new instance of DiscountData.
 *
 * @param {{
 *   amount: number,
 *   type: DiscountType,
 *   comment: string | null,
 * }}
 */
export default function createDiscountData({ amount = 0, type = 'absolute', comment = null } = {}) {
  /**
   * Applies the discount to the given price.
   *
   * @param {number} price
   *
   * @returns {number}
   */
  function apply(price) {
    let value;

    if (type === 'relative') {
      value = price * (1 - amount / 100);
    } else {
      value = price - amount;
    }

    return round(value, 2);
  }

  const res = {
    amount,
    type,
    comment,
  };

  Object.defineProperty(res, 'apply', {
    value: apply,
    enumerable: false,
    configurable: false,
    writable: false,
  });

  return Object.freeze(res);
}

/** @typedef {ReturnType<typeof createDiscountData>} DiscountData */
