import Alert from 'components/Alert';
import { _t } from 'lang';

/**
 * Displays an alert when the price list rates have changed.
 */
export default function RatesChangedAlert() {
  const ratesChanged = false; // TODO: implement

  if (!ratesChanged) {
    return <></>;
  }

  return (
    <Alert
      severity="info"
      primaryText={_t('Hour rates in this cost estimate are not matching current rates.')}
      // TODO specify the user who changed the rates
      // TODO specify the date when the rates were changed
      secondaryText={_t('Rates have been changed by %s at %s', 'John Doe', new Date().toLocaleDateString())}
    />
  );
}
