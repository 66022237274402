import { useMemo } from 'react';

/**
 * @typedef {{
 *   label: string;
 *   key: string;
 *   visible: boolean;
 *   width: string;
 *   align?: 'left' | 'right' | 'center';
 * }} IColumn
 */

/**
 * Hook to compute grid styles for a table from the columns configuration.
 *
 * @param {IColumn[]} columns
 */
export default function useGridStyles(columns) {
  return useMemo(() => {
    const gridTemplateColumns = columns
      .filter(({ visible }) => visible)
      .map(({ width }) => width)
      .join(' ');

    const row = {
      display: 'grid',
      gridTemplateColumns,
      gap: '8px',
      alignItems: 'start',
    };

    const children = columns.map(({ visible, align = 'left' }) => ({
      display: visible ? 'block !important' : 'none !important',
      textAlign: align,
    }));

    return { row, children };
  }, [columns]);
}

/** @typedef {ReturnType<typeof useGridStyles>} GridStyles */
