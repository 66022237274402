/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./cost-estimate-create-action-body').CostEstimateCreateActionBody} CostEstimateCreateActionBody
 */

/**
 * @typedef {import('./cost-estimate-create-action-response').CostEstimateCreateActionResponse} CostEstimateCreateActionResponse
 */

/**
 * The path of the CostEstimateCreateAction action.
 */
export const costEstimateCreateActionPath = new SweetPath('/api/finance/cost_estimate', { path: '{param}' });

/**
 * Creates a contextualized CostEstimateCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createCostEstimateCreateAction(axios) {
  /**
   * The costEstimateCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: CostEstimateCreateActionBody } }
   *
   * @returns {Promise<CostEstimateCreateActionResponse>}
   */
  function costEstimateCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = costEstimateCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return costEstimateCreateAction;
}
