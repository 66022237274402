/** @typedef {import('api/actions/search-documents-action/search-documents-action').SearchDocumentsActionResponse} SearchResults */

import { Box, Group, Stack } from '@mantine/core';
import { _t } from 'lang';
import NoResultsImage from 'layouts/dashboard-layout/headers/search/NoResultsImage';
import AdvancedSearchSection from './AdvancedSearchSection';
import { noop } from 'lodash';

/**
 * Displays the advanced search results.
 *
 * @param {{
 *   results: SearchResults;
 *   moreResults: SearchResults;
 *   search?: string;
 *   loadMore?: ({searchType: string, startFrom: number}) => void;
 * }}
 */
export default function AdvancedSearchResults({ results: { hits, groups }, search = '', loadMore = noop }) {
  return (
    <Stack spacing={16}>
      {hits > 0 ? (
        groups.map((group) => (
          <Stack spacing={8} key={group.name}>
            <AdvancedSearchSection group={group} search={search} loadMore={loadMore} />
          </Stack>
        ))
      ) : (
        <Stack spacing={32}>
          <Stack spacing={8} align="center">
            <Group position="center">
              <NoResultsImage stroke="#211C57" />
            </Group>
            <Box fz={15} lh={24 / 15} style={{ fontWeight: 500 }} c="#211C57">
              {_t('No results found.')}
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
