import { Flex, Spoiler, Stack } from '@mantine/core';
import UserView from 'components/avatars/UserView';
import PriceDisplay from 'components/PriceDisplay';
import ProjectInformationRow from 'components/project/ProjectInformationRow';
import { _t } from 'lang';
import TaskStatusBubble from 'pages/projects/project-overview/project-table/task-table/TaskStatusBubble';
import { useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';
import ArrowTopIcon from '../../../../components/icons/ArrowTopIcon';
import ArrowDownIcon from '../../../../components/icons/ArrowDownIcon';

/**
 * Displays the task overview.
 */
export default function TaskOverview() {
  const { task, project } = useTaskDetail();

  return (
    <Stack spacing={0}>
      <ProjectInformationRow label={_t('Status')} value={<TaskStatusBubble task={task} />} />
      <ProjectInformationRow
        label={_t('Start date')}
        value={task.start ? new Date(task.start).toLocaleDateString() : '-'}
      />
      <ProjectInformationRow
        label={_t('Deadline (Due date)')}
        value={task.end ? new Date(task.end).toLocaleDateString() : '-'}
      />
      <ProjectInformationRow label={_t('Assignees')} value={<UserView users={task.assignee ?? []} max={5} />} />
      <ProjectInformationRow
        label={_t('Estimated hours')}
        value={task.minutes_estimated ? <PriceDisplay value={task.minutes_estimated / 60} currency="hrs." /> : '-'}
      />
      <ProjectInformationRow
        label={_t('Logged hours')}
        value={task.minutes_total ? <PriceDisplay value={task.minutes_total / 60} currency="hrs." /> : '-'}
      />
      <Spoiler
        maxHeight={0}
        hideLabel={
          <Flex>
            <ArrowTopIcon stroke="#38298B" /> {_t(' Hide details')}
          </Flex>
        }
        showLabel={
          <Flex>
            <ArrowDownIcon stroke="#38298B" /> {_t('Show details')}
          </Flex>
        }
      >
        <ProjectInformationRow
          label={_t('Project holder')}
          value={
            <span className="flex items-center gap-2">
              <UserView users={project?.owner ? [project.owner] : []} />
              {project?.owner?.full_name}
            </span>
          }
        />
        <ProjectInformationRow label={_t('Project ID')} value={project?.project_id ?? ''} />
        <ProjectInformationRow label={_t('Client')} value={project?.client?.client_name ?? ''} />
        <ProjectInformationRow
          label={_t('Author')}
          value={
            <span className="flex items-center gap-2">
              <UserView users={[task.author]} />
              {task.author.full_name}
            </span>
          }
        />
        <ProjectInformationRow
          label={_t('Created')}
          value={
            task.created_at
              ? new Date(task.created_at).toLocaleDateString() + ', ' + new Date(task.created_at).toLocaleTimeString()
              : '-'
          }
        />
      </Spoiler>
    </Stack>
  );
}
