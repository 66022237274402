import { Box } from '@mantine/core';
import { _t } from 'lang';

/**
 * Icon for laugh reaction.
 *
 * @param {{
 *   size?: number
 *   useEmoji?: boolean
 * }}
 */
export default function LaughReactionIcon({ size = 16, useEmoji = false } = {}) {
  if (useEmoji) {
    const emojiSize = size * 0.8;

    return (
      <Box w={emojiSize} h={emojiSize} fz={emojiSize} lh={1} aria-label={_t('laugh')}>
        😂
      </Box>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#FFC700" />
      <path d="M17.5 14.5H6.5C7 15.8333 8.8 18.5 12 18.5C15.2 18.5 17 15.8333 17.5 14.5Z" fill="white" />
      <path d="M17.5 14.5H6.5C7 15.8333 8.8 18.5 12 18.5C15.2 18.5 17 15.8333 17.5 14.5Z" stroke="#4D4D4D" />
      <path d="M6 10.0001C6.5 8.50006 9 8.00002 10 10" stroke="#4D4D4D" />
      <path d="M14 10.0001C14.5 8.50006 17 8.00002 18 10" stroke="#4D4D4D" />
    </svg>
  );
}
