/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./purchase-order-invoice-request-create-action-body').PurchaseOrderInvoiceRequestCreateActionBody} PurchaseOrderInvoiceRequestCreateActionBody
 */

/**
 * @typedef {import('./purchase-order-invoice-request-create-action-response').PurchaseOrderInvoiceRequestCreateActionResponse} PurchaseOrderInvoiceRequestCreateActionResponse
 */

/**
 * The path of the PurchaseOrderInvoiceRequestCreateAction action.
 */
export const purchaseOrderInvoiceRequestCreateActionPath = new SweetPath('/api/finance/purchase_order', {
  path: '{param}',
});

/**
 * Creates a contextualized PurchaseOrderInvoiceRequestCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createPurchaseOrderInvoiceRequestCreateAction(axios) {
  /**
   * The purchaseOrderInvoiceRequestCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: PurchaseOrderInvoiceRequestCreateActionBody } }
   *
   * @returns {Promise<PurchaseOrderInvoiceRequestCreateActionResponse>}
   */
  function purchaseOrderInvoiceRequestCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = purchaseOrderInvoiceRequestCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return purchaseOrderInvoiceRequestCreateAction;
}
