/**
 * Icon used by the Image control component.
 */
export default function IconImage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.8332 9.16634L14.9998 13.333H4.99984L7.49984 10.083L9.24984 12.333L11.6665 9.16634H11.8332ZM7.08317 9.16634C7.74984 9.16634 8.33317 8.58301 8.33317 7.91634C8.33317 7.24967 7.74984 6.66634 7.08317 6.66634C6.4165 6.66634 5.83317 7.24967 5.83317 7.91634C5.83317 8.58301 6.4165 9.16634 7.08317 9.16634ZM18.3332 4.99967V14.9997C18.3332 15.9163 17.5832 16.6663 16.6665 16.6663H3.33317C2.4165 16.6663 1.6665 15.9163 1.6665 14.9997V4.99967C1.6665 4.08301 2.4165 3.33301 3.33317 3.33301H16.6665C17.5832 3.33301 18.3332 4.08301 18.3332 4.99967ZM16.6665 7.33301V4.99967H3.33317V14.9997H16.6665V7.33301Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
