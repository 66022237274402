/** @typedef {import('@mantine/core').MantineThemeOverride} MantineThemeOverride */
/** @typedef {NonNullable<MantineThemeOverride['components']>['Select']} SelectTheme */

import { ErrorStyles } from 'theme/components/Inputs/shared/Error';
import fullTextSearch from 'utils/full-text-search';

/** @type {SelectTheme} */
export const Select = {
  defaultProps: {
    /**
     * Filter function applied when using searchable prop.
     *
     * @param {string} search
     * @param {{ label: string }} item
     */
    filter: (search, item) => fullTextSearch(search, item.label),
  },
  styles: {
    item: {
      color: '#4D4D4D',
      fontSize: '15px',
      lineHeight: '20px',
    },
    error: ErrorStyles,
  },
};
