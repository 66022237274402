/** @generated */
/**
 * --- DO NOT MODIFY THIS FILE ---------------------------------------------------------------------
 *
 * This file is automatically generated using generator/icons/generate-icons.js. If you wish to
 * change the icon, update the new icon to spec/icons under the correct name and then run:
 *
 *     npm run generate:icons
 *
 * -------------------------------------------------------------------------------------------------
 */

/**
 * The "ActionRequired" icon.
 *
 * @param { { className?: string, height?: number, width?: number,  stroke?: string,   fill?: string,  } }
 */
export default function ActionRequiredIcon({
  className = '',
  height = 24,
  width = 24,
  stroke = '#4D4D4D',
  fill = 'none',
} = {}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Message type/action_required">
        <path
          id="Vector"
          d="M15 15L13 20L8.99995 9L20 13L15 15ZM15 15L20 20M7.18818 2.23853L7.96464 5.1363M5.13618 7.96472L2.2384 7.18826M13.9497 4.05029L11.8283 6.17161M6.17158 11.8284L4.05026 13.9497"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
