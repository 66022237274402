import { _t } from 'lang';
import DataTableRow from 'components/tables/DataTableRow';
import { useEffect, useMemo, useState } from 'react';
import panic from 'errors/Panic';

/**
 * Generic table that displays data fetched using the specified action in
 * a tabular format. The contents of the table must be rendered by the row
 * function.
 *
 * @template TRow
 *
 * @param {{
 *   action: () => Promise<Array<TRow>>,
 *   RowComponent?: (props: { data: TRow, hover: boolean }) => ReactNode,
 *   rowKey: (data: TRow) => string,
 *   editLink: (data: TRow) => string,
 *   columns: { title: string, width: string }[],
 *   refreshToken?: string,
 *   // TODO maybe use Menu.Items props
 *   menuItems?: {
 *    key: string,
 *    icon: React.ReactElement,
 *    onClick: () => void,
 *    color?: string,
 *    label: string,
 *    showIf: boolean
 *    }[],
 *   emptyMessage?: ReactNode,
 *   loadingMessage?: ReactNode,
 * }}
 */
export default function DataTable({
  action,
  RowComponent,
  rowKey,
  editLink,
  columns,
  refreshToken,
  menuItems = [],
  emptyMessage = <div>{_t('Nothing found ¯\\_(ツ)_/¯')}</div>,
  loadingMessage = <div>{_t('Loading...')}</div>,
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const gridLayoutCss = useMemo(() => {
    const columnWidths = columns.map(({ width }) => width);
    columnWidths.push(1); // The last column for three dots (edit).

    const gridLayoutCss = {
      gridTemplateColumns: columnWidths.map((width) => `${width}fr`).join(' '),
      gap: '16px',
    };

    return gridLayoutCss;
  }, [columns]);

  useEffect(() => {
    action()
      .then(setData)
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [refreshToken]);

  return (
    <div className="pt-4 text-[14px] leading-[20px] text-grey-500">
      {/* Table header */}
      <div style={gridLayoutCss} className="grid justify-between border-b border-b-grey-200 pl-[10px] pr-[10px]">
        {columns.map(({ title }) => (
          <span key={title}>{title}</span>
        ))}
        <span className="flex justify-end">{_t('Edit')}</span>
      </div>

      {loading && <div>{loadingMessage}</div>}
      {data && data.length === 0 && <div>{emptyMessage}</div>}

      {/* Table body */}
      {(data || []).map((dataRow) => (
        <DataTableRow
          key={rowKey(dataRow)}
          data={dataRow}
          gridLayoutCss={gridLayoutCss}
          editLink={editLink}
          RowComponent={RowComponent}
          menuItems={menuItems}
        />
      ))}
    </div>
  );
}
