import { identity } from 'lodash';
import { useState } from 'react';

/**
 * The update function of an immutable object.
 *
 * @template TParams
 * @typedef {(update: TParams, options: { immediate?: boolean }) => void} UpdateImmutable
 */

/**
 * Hook to store immutable state with partial update support and debounce
 * effect.
 *
 * todo: move this to a separate package.
 *
 * @template TParams
 * @template TObject
 *
 * @param {TParams} initialData
 *
 * @param {{
 *   minWait?: number;
 *   maxWait?: number;
 *   createObjectFn?: (data: TParams) => TObject;
 * }}
 *
 * @return {[TObject, UpdateImmutable<TParams>]}
 */
export default function useImmutableWithDebounce(
  initialData,
  { minWait = 50, maxWait = Infinity, createObjectFn = identity } = {}
) {
  const [data, setData] = useState(() => createObjectFn(initialData));

  const [updateData] = useState(() => {
    /** When the first update is performed, the start time is recorded. */
    let start = null;

    /** The timeout ID of the timeout which is used to perform the update. */
    let timeoutId = null;

    /** The cumulated update. */
    let cumulatedUpdate = null;

    /**
     * Performs the actual update.
     */
    function performUpdate() {
      timeoutId = null;
      setData((data) => createObjectFn({ ...data, ...cumulatedUpdate }));
    }

    /**
     * Updates one or more fields in the data. Debounced version.
     *
     * @type {UpdateImmutable<TParams>}
     */
    const updateData = (update, { immediate = false } = {}) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      } else {
        cumulatedUpdate = {};
        start = Date.now();
      }

      cumulatedUpdate = { ...cumulatedUpdate, ...update };

      const delta = Date.now() - start;

      if (immediate || delta > maxWait) {
        performUpdate();
      } else {
        timeoutId = setTimeout(performUpdate, minWait);
      }
    };

    return updateData;
  });

  return [data, updateData];
}
