/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./external-cost-create-action-body').ExternalCostCreateActionBody} ExternalCostCreateActionBody
 */

/**
 * @typedef {import('./external-cost-create-action-response').ExternalCostCreateActionResponse} ExternalCostCreateActionResponse
 */

/**
 * The path of the ExternalCostCreateAction action.
 */
export const externalCostCreateActionPath = new SweetPath('/api/finance/external_cost', { path: '{param}' });

/**
 * Creates a contextualized ExternalCostCreateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createExternalCostCreateAction(axios) {
  /**
   * The externalCostCreateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: ExternalCostCreateActionBody } }
   *
   * @returns {Promise<ExternalCostCreateActionResponse>}
   */
  function externalCostCreateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = externalCostCreateActionPath.insert(parameters);

    return axios.post(path, body, { headers });
  }

  return externalCostCreateAction;
}
