/** @typedef {import('api/actions/client-get-list-action/client-get-list-action-response').ClientGetListActionResponse[number]} Client */

import { _t } from 'lang';
import { createContext, useEffect, useState, useContext, useMemo } from 'react';
import { useApi } from 'api/ApiContext';
import panic from 'errors/Panic';

/**
 * The client context for the AddCostEstimate page.
 *
 * @type {React.Context<{
 *   clientId: number | null,
 *   setClientId: (clientId: number) => void,
 *   client: Client | null,
 *   loading: boolean,
 * }>}
 */
const ClientContext = createContext();

/**
 * Provides the client to the AddCostEstimate page.
 *
 * @param {{
 *   children: React.ReactNode,
 *   initialClientId: number | null,
 * }}
 */
export function ClientProvider({ children, initialClientId }) {
  const [clientId, setClientId] = useState(initialClientId);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getAction } = useApi();
  const clientGetListAction = getAction('ClientGetListAction');

  // Fetch client when the clientId changes.
  useEffect(() => {
    if (clientId) {
      setClient(null);
      setLoading(true);

      clientGetListAction({ query: { filter: { 'client_id.eq': clientId } } })
        .then(([client]) => {
          if (!client) {
            throw new Error(_t('Unable to find the client.')); // This should never happen.
          }

          setClient(client);
        })
        .catch((error) => panic(error.message))
        .finally(() => setLoading(false));
    } else {
      setClient(null);
    }
  }, [clientId]);

  const value = useMemo(() => ({ clientId, setClientId, client, loading }), [clientId, setClientId, client, loading]);

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
}

/**
 * Uses the client context.
 */
export function useClient() {
  return useContext(ClientContext);
}
