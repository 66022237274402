import { useCallback } from 'react';

/**
 * Turns a function into a promise.
 *
 * @template TReturn
 * @template TArgs
 *
 * @param {(...args: TArgs) => TReturn | Promise<TReturn>} action
 *
 * @returns {(...args: TArgs) => Promise<TReturn>}
 */
export default function useAsyncAction(action) {
  return useCallback(async (...args) => await action(...args), [action]);
}
