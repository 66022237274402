/**
 * Display a process message with an action button.
 *
 * @see {@link https://www.figma.com/file/jZPNVAdhBmEl8qIOrILWmH/Toolio---component-library?type=design&node-id=192-1873&mode=design&t=MPyT7Dk2jFeyR18P-0 Toolio Component Library}
 *
 * @param {{
 *   heading: string,
 *   message: string,
 *   buttons?: import("react").ReactNode,
 * }}
 */
export default function ProcessMessageWithAction({ heading, message, buttons }) {
  return (
    <div className="bg-main-accent pb-6 pl-8 pt-4">
      <div className="flex max-w-[500px] flex-col gap-y-4">
        <div className="text-2xl font-normal">{heading}</div>
        <div className="text-sm font-normal ">{message}</div>
        <div className="flex items-start gap-x-4 empty:hidden">{buttons}</div>
      </div>
    </div>
  );
}
