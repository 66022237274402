import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import SettingsSidebar from 'layouts/dashboard-layout/sidebars/settings-sidebar/SettingsSidebar';
import DashboardPageHeading from 'layouts/dashboard-layout/headers/DashboardPageHeading';
import { _t } from 'lang';
import { useNavigate } from 'react-router-dom';
import { ADD_CLIENT_PAGE_PATH } from 'routes/paths';
import ClientTable from 'components/tables/ClientTable';

/**
 * Displays a page with a list of clients.
 *
 * @see https://www.figma.com/file/X3Zklb3KjOZFu5wAHgGYoH/Toolio---web-Interface?node-id=302%3A11793
 */
export default function ClientsPage() {
  const navigate = useNavigate();

  return (
    <DashboardLayout sidebar={<SettingsSidebar />}>
      <div className="relative h-full p-[20px] pt-0">
        <DashboardPageHeading heading={_t('Clients')} buttonAction={() => navigate(ADD_CLIENT_PAGE_PATH.original)} />
        <ClientTable />
      </div>
    </DashboardLayout>
  );
}
