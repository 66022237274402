import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';

/**
 * Select input for teams.
 *
 * @param { import('react').RefAttributes<HTMLInputElement> &
 *   import('@mantine/core').SelectProps
 * }
 */
export default function TeamSelect({ ...props }) {
  const { getAction } = useApi();
  const teamGetListAction = getAction('TeamGetListAction');

  return (
    <DataSelect
      {...props}
      action={teamGetListAction}
      groupProperty={(row) => row.department.name}
      labelProperty="name"
      valueProperty="team_id"
    />
  );
}
