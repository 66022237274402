/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./task-update-action-body').TaskUpdateActionBody} TaskUpdateActionBody
 */

/**
 * @typedef {import('./task-update-action-response').TaskUpdateActionResponse} TaskUpdateActionResponse
 */

/**
 * The path of the TaskUpdateAction action.
 */
export const taskUpdateActionPath = new SweetPath('/api/project/project/task/{task_id}', { path: '{param}' });

/**
 * Creates a contextualized TaskUpdateAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createTaskUpdateAction(axios) {
  /**
   * The taskUpdateAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: TaskUpdateActionBody } }
   *
   * @returns {Promise<TaskUpdateActionResponse>}
   */
  function taskUpdateAction({ headers = {}, parameters = {}, body } = {}) {
    const path = taskUpdateActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return taskUpdateAction;
}
