/** @generated */
// cspell:disable

/** @typedef {import('axios').AxiosInstance} AxiosInstance */

import { SweetPath } from 'sweet-path';

/**
 * @typedef {import('./department-set-active-action-body').DepartmentSetActiveActionBody} DepartmentSetActiveActionBody
 */

/**
 * @typedef {import('./department-set-active-action-response').DepartmentSetActiveActionResponse} DepartmentSetActiveActionResponse
 */

/**
 * The path of the DepartmentSetActiveAction action.
 */
export const departmentSetActiveActionPath = new SweetPath('/api/user/department/{department_id}/set_active', {
  path: '{param}',
});

/**
 * Creates a contextualized DepartmentSetActiveAction action.
 *
 * @param {AxiosInstance} axios
 */
export default function createDepartmentSetActiveAction(axios) {
  /**
   * The departmentSetActiveAction action.
   *
   * @param { { headers: Object<string,string>,parameters: Object<string,string|number|boolean>,body: DepartmentSetActiveActionBody } }
   *
   * @returns {Promise<DepartmentSetActiveActionResponse>}
   */
  function departmentSetActiveAction({ headers = {}, parameters = {}, body } = {}) {
    const path = departmentSetActiveActionPath.insert(parameters);

    return axios.put(path, body, { headers });
  }

  return departmentSetActiveAction;
}
