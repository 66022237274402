import { useApi } from 'api/ApiContext';
import DataSelect from 'components/selects/DataSelect';
import { useMemo } from 'react';

/**
 * Select input for projects.
 *
 * @param { import('react').RefAttributes<HTMLInputElement>
 *   & import('@mantine/core').SelectProps
 * }
 */
export default function ProjectSelect({ actionFilter, ...props }) {
  const { getAction } = useApi();
  const projectGetListAction = getAction('ProjectGetListAction');

  const augmentedFilter = useMemo(() => ({ 'deleted.eq': 0, 'closed.eq': 0, ...actionFilter }), [actionFilter]);

  return (
    <DataSelect
      {...props}
      actionFilter={augmentedFilter}
      action={projectGetListAction}
      labelProperty="project_full_name"
      valueProperty="project_id"
    />
  );
}
