import { useData } from '../providers/DataProvider';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import FormRow from '../../../../../components/forms/FormRow';
import { _t } from '../../../../../lang';
import CurrencySelect from '../../../../../components/selects/CurrencySelect';

/**
 * Retrieves the cost estimate currency from the data and allows the user
 * to update it through a CurrencySelect input.
 *
 * @returns {ReactElement} - A form row component with a label and CurrencySelect input.
 */
export default function CostEstimateCurrency() {
  const { data, updateData } = useData();
  const [currency, setCurrency] = useState(data.currency);

  const [propagateChanges] = useState(() => debounce((currency) => updateData({ currency }), 100, { maxWait: 500 }));

  // Propagate changes when the currency changes.
  useEffect(() => {
    propagateChanges(currency);
  }, [currency]);

  return (
    <FormRow
      label={_t('Cost estimate currency')}
      input={
        <CurrencySelect
          value={currency}
          onChange={(currency) => setCurrency(currency)}
          disabled={!!data.costEstimateId}
        />
      }
    />
  );
}
