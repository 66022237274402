import { Box, Button, Group, Tabs } from '@mantine/core';
import EditIcon from 'components/icons/EditIcon';
import LogTimeIcon from 'components/icons/LogTimeIcon';
import { _t } from 'lang';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import PageHeading from 'layouts/dashboard-layout/headers/PageHeading';
import TaskDetailSidebar from 'pages/tasks/task-detail/sidebar/TaskDetailSidebar';
import { Link } from 'react-router-dom';
import { EDIT_TASK_PAGE_PATH } from 'routes/paths';
import TaskPathParamProvider from 'providers/task/TaskPathParamProvider';
import TaskActions from 'components/task/TaskActions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TaskOverviewTab from 'pages/tasks/task-detail/tabs/TaskOverviewTab';
import { TaskDetailProvider, useTaskDetail } from 'pages/tasks/task-detail/context/TaskDetailProvider';
import TaskTimeLogsTab from 'pages/tasks/task-detail/tabs/TaskTimeLogsTab';
import TaskCommentsTab from 'pages/tasks/task-detail/tabs/TaskCommentsTab';
import TaskSubtasksTab from 'pages/tasks/task-detail/tabs/TaskSubtasksTab';
import { useTimeLog } from 'pages/time-entries/time-log/TimeLogProvider';
import TaskFilesTab from './tabs/TaskFilesTab';
import { useTask } from 'providers/task/TaskProvider';
import panic from 'errors/Panic';
import { useApi } from 'api/ApiContext';
import useQueryState from 'hooks/use-query-state';
import NotFoundPage from 'pages/system/not-found/NotFoundPage';
import NoAccessPage from 'pages/system/no-access/NoAccessPage';
import { nanoid } from 'nanoid';

/**
 * Displays a task detail.
 */
function TaskDetailPageImpl() {
  const [activeTab, setTab] = useQueryState('tab', 'overview');
  const { getAction, hasPermission } = useApi();
  const { project, exists } = useTask();
  const { loading, task, taskId, isSubtask, refresh, deleteTask, closeTask, reopenTask, restoreTask } = useTaskDetail();
  const { openTimeLogModal } = useTimeLog();

  const [refreshToken, setRefreshToken] = useState(undefined);

  const editLink = useMemo(() => EDIT_TASK_PAGE_PATH.insert({ taskId }), [taskId]);

  const ready = !!task && !!project;

  const isDeleted = useMemo(() => task && task.deleted_at && task.deleted_by, [task]);
  const isClosed = useMemo(() => task && task.closed_at && task.closed_by, [task]);
  const clientId = useMemo(() => (project ? project.client.client_id : -1), [project]);
  const hasTaskUpdatePermission = useMemo(
    () => hasPermission('PROJECTS_MANAGE_PROJECT', clientId),
    [hasPermission, clientId]
  );
  const hasTimeLogCreatePermission = useMemo(
    () => hasPermission('TIME_LOGS_CREATE_TIME_LOG', clientId),
    [hasPermission, clientId]
  );
  const hasViewPermission = useMemo(() => hasPermission('PROJECTS_VIEW_PROJECT', clientId), [hasPermission, clientId]);

  /**
   * Opens the time log modal.
   */
  const logTime = useCallback(() => {
    if (ready) {
      openTimeLogModal({
        projectId: project.project_id,
        taskId,
        onTimeLogged: () => {
          refresh();
          setRefreshToken(nanoid());
        },
      });
    }
  }, [ready, project, taskId, openTimeLogModal, refresh]);

  useEffect(() => {
    if (hasViewPermission) {
      const action = getAction('UserTaskActivityCreateAction');

      action({
        body: {
          task_id: taskId,
          points: 0.25,
          note: 'Opened a task page',
        },
      }).catch(panic);
    }
  }, [taskId, hasViewPermission]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [taskId]);

  if (exists === false) {
    return <NotFoundPage />;
  }

  if (!task && exists) {
    return <NoAccessPage />;
  }

  return (
    <DashboardLayout
      contentClass="bg-neutral-50 pb-8"
      sidebar={project && <TaskDetailSidebar projectId={project.project_id} />}
    >
      {ready && !loading && (
        <Box key={taskId} style={{ position: 'relative' }}>
          <PageHeading
            className="sticky top-[36px] z-[100] bg-neutral-50 px-4 md:px-8"
            heading={task.task_full_name}
            actionButton={
              <Group noWrap spacing={16} className="hidden md:flex">
                {hasTaskUpdatePermission && (
                  <Button component={Link} to={editLink} variant="secondary" leftIcon={<EditIcon />}>
                    {_t('Edit')}
                  </Button>
                )}
                {!isDeleted && !isClosed && hasTimeLogCreatePermission && (
                  <Button variant="primary" leftIcon={<LogTimeIcon />} onClick={logTime}>
                    {_t('Log time')}
                  </Button>
                )}
              </Group>
            }
            menu={
              <Box className="hidden md:block">
                <TaskActions
                  projectId={project.project_id}
                  task={task}
                  onTaskClose={closeTask}
                  onTaskDelete={deleteTask}
                  onTaskReopen={reopenTask}
                  onTaskRestore={restoreTask}
                  hideLogTime
                  clientId={clientId}
                />
              </Box>
            }
          />

          <Tabs value={activeTab} onTabChange={setTab}>
            <Tabs.List
              className="flex-nowrap gap-4 overflow-x-auto px-4 md:gap-6 md:px-8"
              pos="sticky"
              top={82}
              style={{ zIndex: 95 }}
            >
              <Tabs.Tab value="overview">{_t('Overview')}</Tabs.Tab>
              <Tabs.Tab value="comments">{_t('Comments')}</Tabs.Tab>
              <Tabs.Tab value="files">{_t('Files')}</Tabs.Tab>
              <Tabs.Tab value="time-log">{_t('Time log')}</Tabs.Tab>
              {!isSubtask && <Tabs.Tab value="subtasks">{_t('Subtasks')}</Tabs.Tab>}
            </Tabs.List>

            <Tabs.Panel value="overview" className="px-4 md:px-8">
              {activeTab === 'overview' && <TaskOverviewTab />}
            </Tabs.Panel>
            <Tabs.Panel value="comments" className="px-4 md:px-8">
              {activeTab === 'comments' && <TaskCommentsTab />}
            </Tabs.Panel>
            <Tabs.Panel value="files" className="px-4 md:px-8">
              {activeTab === 'files' && <TaskFilesTab />}
            </Tabs.Panel>
            <Tabs.Panel value="time-log" className="px-4 md:px-8">
              {activeTab === 'time-log' && <TaskTimeLogsTab refreshToken={refreshToken} />}
            </Tabs.Panel>
            {!isSubtask && (
              <Tabs.Panel value="subtasks" className="px-4 md:px-8">
                {activeTab === 'subtasks' && <TaskSubtasksTab />}
              </Tabs.Panel>
            )}
          </Tabs>
        </Box>
      )}
    </DashboardLayout>
  );
}

/**
 * Displays a task detail.
 */
export default function TaskDetailPage() {
  return (
    <TaskPathParamProvider>
      <TaskDetailProvider>
        <TaskDetailPageImpl />
      </TaskDetailProvider>
    </TaskPathParamProvider>
  );
}
